import { NavLink } from 'react-router-dom';
import { userLoginSessionApi } from "../../../services/Geolocation-API";

import AuthContext from '../../../redux/contexts/auth-context';
import { Fragment, useContext, useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { checkSecurityDetails } from '../../../utils/RoleUtils'
import { getCustomizeDetails } from '../../../utils/CustomizeUtils'
import { useLocation } from 'react-router-dom';
import UseIdle from '../../../redux/contexts/useIdle'
import { Tooltip as ReactTooltip } from "react-tooltip";
import { getConfigurationByBoolen, getConfigurationByValue } from '../../../utils/ConfigurationsFunction'
import secureLocalStorage from 'react-secure-storage';
import {
    LOGIN, MYTRIPS, MYQUEUE,
    //CONTINENT,ALLCOUNTRY,
    FLIGHTSEARCH, HOME, HOTELSEARCH, HOTELCONFIRMATION, HOTELBOOKING, ALLCITY, ALLAIRPORT, ALLUSER,
    ALLAIRLINE, SEARCHOFFICE, SEARCHFEE, SEARCHROLE, UPDATECUSTOMIZE,
    SEARCHREGION,
    HOMEPAGE,
    SEARCHCONTENT,
    SEARCHCONFIGURATION,
    UPDATEEMAILSERVER,
    SEARCHTRAVELAGENT,
    SEARCHHOTEL,
    CRYPTIC, DASHBOARD, SEARCHPNR, SEARCHFEES, SEARCHUSERFRIENDLY, SEARCHWALLET, SEARCHRATEPLANALLOWED, SEARCHRATEPLAN,
    SEARCHSIGHTSEEING,
    AUTHENTICATION, TRAVELREQUEST, SEARCHTRIPREASON, SEARCHRULES, SEARCHCOSTCENTER, SEARCHDEPARTMENT, SEARCHPOSITION, SEARCHCORPORATE, SEARCHCORPORATEMASTER,
    SEARCHAPPROVER,
    MASTERMENU,
    SETTINGMENU,
    USERMENU,
    SIGNUP, SEARCHVISA, HIGHRISKDESTINATION

} from '../../../navigation/CONSTANTS.js'
import useHttp from "../../../services/use-http";
import { closeCrypticCommand } from "../../../services/CrypticCommand-API";
import { searchhWallet } from "../../../services/Wallet-Api";
// import SearchCorparate from '../../../pages/Masters/Corporate/SearchCorporate.js';

const MainNavigation = (props) => {
    const history = useHistory();
    const authCtx = useContext(AuthContext);
    const { detect } = require('detect-browser');
    const browser = detect();
    const { sendRequest: sendRequestExit, data: loadedExitOutput, status: exitStatus, } = useHttp(closeCrypticCommand);
    const { sendRequest: sendWallet, status, data: loadedWalletData, error: walletError } = useHttp(searchhWallet);
    const { sendRequest: sendLoginUserSession, status: statusLoginUserSession, data: loadedLoginUserSession, error: loginUserSessionError } = useHttp(userLoginSessionApi);
    const logoutHandler = () => {
        authCtx.logout();
        window.location.href = LOGIN;
    };
    const logoutCustomerHandler = () => {
        secureLocalStorage.removeItem("CustomerLoginEmailID");
        secureLocalStorage.removeItem("isCustomerAuthentication");
    };

    const location = useLocation();

    if (window.location.href.includes("KANOO") && !authCtx.isLoggedIn) {
        window.location.pathname = '/kanoo'
    }
    if (window.location.href.toUpperCase().includes("MOBILE")) {
        localStorage.setItem("isMobile", JSON.stringify(true));
    } else {
        localStorage.setItem("isMobile", JSON.stringify(false));
    }
    useEffect(() => {
        if (location.pathname !== "/SabreCommand" && sessionStorage.getItem("SabreSessionToken") !== undefined && sessionStorage.getItem("SabreSessionToken") !== null) {
            sendRequestExit({
                "officeID": JSON.parse(localStorage.getItem('officeID')),
                "data": {
                    "UserID": JSON.parse(localStorage.getItem('userID')),
                    "TraceId": JSON.parse(localStorage.getItem('traceID')),
                    "echoToken": sessionStorage.getItem("SabreSessionToken") !== undefined ? sessionStorage.getItem("SabreSessionToken") : null

                }
            });
            sessionStorage.removeItem("SabreSessionToken");
        }
        if (location.pathname !== "/FlightSearch") {
            document.getElementById('emailRecommendation').style.display = 'none';
        }
        if (location.pathname !== "/HotelDetails") {
            document.getElementById('HotelEmailRecommendation').style.display = 'none';
        }
    }, [location, sendRequestExit]);



    const [walletAmount, setWalletAmount] = useState(0);
    const [walletCurrencyCode, setWalletCurrencyCode] = useState('USD');
    const [walletActive, setWalletActive] = useState(false);

    useEffect(() => {
        const onSerachWallet = ({
            officeID: JSON.parse(localStorage.getItem('officeID')),
            parentOfficeID: JSON.parse(localStorage.getItem('parentOfficeID')),
        });
        authCtx.isLoggedIn && sendWallet(onSerachWallet);
    }, [location, sendWallet]);

    useEffect(() => {
        localStorage.setItem("LoginDateTime", (new Date()).toJSON())
    }, []);


    useEffect(() => {
        if (status === 'completed') {
            if (loadedWalletData !== null && loadedWalletData.errors.status === "FALSE") {
                setWalletAmount(loadedWalletData.data[0].amount);
                setWalletCurrencyCode(loadedWalletData.data[0].currencyCode);
                setWalletActive(loadedWalletData.data[0].isActive);
                sessionStorage.setItem("currentWalletAmount", parseFloat(loadedWalletData.data[0].amount).toFixed(parseInt(getConfigurationByValue("DECIMAL_PLACES") !== null && getConfigurationByValue("DECIMAL_PLACES") !== '' ? getConfigurationByValue("DECIMAL_PLACES") : '2')));
                //sessionStorage.setItem("currentWalletAmount", 100);
                sessionStorage.setItem("currentWalletCurrency", loadedWalletData.data[0].currencyCode);
                sessionStorage.setItem("activeWallet", loadedWalletData.data[0].isActive);
            }
        }

    }, [status])

    var geoLocation = JSON.parse(localStorage.getItem('GeoLocation'));
    useEffect(() => {
        if (localStorage.getItem('loginEmailIDLogin') !== null) {
            location.pathname === "/MFA" && setTimeout(() => {

                sendLoginUserSession(
                    {
                        "loginEmailID": localStorage.getItem('loginEmailIDLogin'),
                        "countryCode": geoLocation?.country_code,
                        "countryName": geoLocation?.country_name,
                        "latitude": geoLocation?.latitude.toString(),
                        "longitude": geoLocation?.longitude.toString(),
                        "ipAddress": geoLocation?.IPv4,
                        "BrowserInfo": browser
                    })

            }, 2000);
        }

    }, [])
    useEffect(() => {
        if (statusLoginUserSession === 'completed') {
            if (loadedLoginUserSession !== null && loadedLoginUserSession.errors.status === "TRUE") {
                //localStorage.clear();
                //logoutHandler();
                //userIsLoggedIn = false;
            }
        }
    }, [statusLoginUserSession])

    const HomePage1 = () => {

        //getConfigurationByBoolen("ALLOW_B2B") ? history.push(FLIGHTSEARCH) : window.location.pathname = HOMEPAGE;
    };

    //Check if the logged-in user is an approver
    const isApproverLogin = useMemo(() => {
        let isApprover = false;
        const loginData = JSON.parse(localStorage.getItem("loginData"));

        if (loginData && loginData.approvalType && loginData.approvalType.length) {
            isApprover = true;
        }

        return isApprover;
    }, []);

    return (
        <Fragment>
            {authCtx.isLoggedIn && JSON.parse(localStorage.getItem("isMobile")) &&
                <ul className="bottom-menu-bar hide-from-tablet-landscape">

                    <li className="button-plain text-center my-auto">

                        {(authCtx.isLoggedIn && checkSecurityDetails("Home")) &&
                            (getConfigurationByBoolen("ALLOW_OBT") && <a href='/TravelRequest' className="button-plain text-center">
                                <i class="fas fa-home"></i>
                                <br />
                                Home
                            </a>

                            ) || ((checkSecurityDetails("Book", "Flight") && checkSecurityDetails("Book", "Flight", "Book_Flight_Search") === true && getConfigurationByBoolen("ALLOW_FLIGHT") === true) &&
                                <NavLink to={FLIGHTSEARCH} className="button-plain text-center">
                                    <i class="fas fa-home"></i>
                                    <br />
                                    Home
                                </NavLink>

                            ) || ((checkSecurityDetails("Book", "Hotel") && checkSecurityDetails("Book", "Hotel", "Book_Hotel_Search") && getConfigurationByBoolen("ALLOW_HOTEL") === true) &&
                                <NavLink to={SEARCHHOTEL} className="button-plain text-center">
                                    <i class="fas fa-home"></i>
                                    <br />
                                    Home
                                </NavLink>
                            ) || ((checkSecurityDetails("Book", "Sightseeing") && getConfigurationByBoolen("ALLOW_SIGHTSEEING") === true) &&
                                <NavLink to={SEARCHSIGHTSEEING} className="button-plain text-center">
                                    <i class="fas fa-home"></i>
                                    <br />
                                    Home
                                </NavLink>
                            )}
                    </li>

                    <li>
                        <button className="button-plain text-center">

                            {(authCtx.isLoggedIn && checkSecurityDetails("My Trips")) &&
                                <li className="button-plain text-center">
                                    <NavLink to={getConfigurationByBoolen("ALLOW_LOGIN") ? MYTRIPS : secureLocalStorage.getItem("isCustomerAuthentication") && !getConfigurationByBoolen("ALLOW_LOGIN") ? MYTRIPS : AUTHENTICATION} className="button-plain text-center">
                                        <i class="fas fa-suitcase"></i>
                                        <br />
                                        My Trips
                                    </NavLink>
                                </li>}
                        </button>
                    </li>

                    <li>
                        <button className="button-plain text-center">

                            {(authCtx.isLoggedIn) && <li className="button-plain text-center">
                                <NavLink to={USERMENU} className="button-plain text-center">
                                    <i class="fas fa-user-cog"></i>
                                    <br />
                                    User
                                </NavLink>
                            </li>}
                        </button>
                    </li>
                    <li>
                        <button className="button-plain text-center">

                            {(authCtx.isLoggedIn) && <li class="button-plain text-center">
                                <NavLink to={MASTERMENU} className="button-plain text-center">
                                    <i class="fas fa-eye"></i>
                                    <br />
                                    Master
                                </NavLink>
                            </li>}
                        </button>
                    </li>
                    <li>
                        <button className="button-plain text-center">

                            {(authCtx.isLoggedIn) && <li className="button-plain text-center">
                                <NavLink to={SETTINGMENU} className="button-plain text-center">
                                    <i class="fas fa-cogs"></i>
                                    <br />
                                    Settings
                                </NavLink>
                            </li>}
                        </button>
                    </li>
                </ul>}

            {(authCtx.isLoggedIn && <UseIdle />)}

            {/*       <!-- Header Area --> */}
            {authCtx.isLoggedIn && <header className="main_header_arae" style={{ display: sessionStorage.getItem("LoaderHeader") === "1" ? 'none' : 'block' }}>
                {/*        <!-- Top Bar --> */}

                {(authCtx.isLoggedIn && !JSON.parse(localStorage.getItem("isMobile"))) && <div className="topbar-area" >
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-1 col-md-1 col-sm-1 position-relative">
                                {/* <div className='mobile-no '>
                                    <img src="assets/img/Fareladder-Call-Center.png" alt='Mobile_No' />
                                </div>
                                <div className='mobile-no-text'>
                                    <div className='h5 text-white mb-0'>+1 516 822 8000</div>
                                    <div classN
                                    ame='text-14px text-white'>Speak to a Luxury Travel Advisor</div>
                                </div> */}
                                {/* <span className=''>
                                    <img src="assets/img/contact-us.png" alt='Contact us' className='head-contact-us' />
                                </span> */}
                            </div>

                            <div className="col-lg-12 col-md-12 col-sm-12" >
                                <ul className="topbar-others-options">
                                    <li>

                                        <div className='head-contact-us1'>

                                            <a href={"mailto:" + getConfigurationByValue("SUPPORT_EMAIL_ID")} className='d-flex'>
                                                {/* <img src="assets/img/contact-us-email.png" alt='Contact us' className='head-contact-us' /> */}
                                                <span className='email-icon my-auto'>
                                                    <i className="fas fa-envelope"></i>
                                                </span>
                                                <span className='text-start'>
                                                    <div className='fw-bold text-support'>{getConfigurationByValue("SUPPORT_TITLE")}</div>
                                                    <div className='text-white text-12px head-contact-support-text' id="contact-email" ><u>{getConfigurationByValue("SUPPORT_EMAIL_ID")}</u></div>
                                                    <ReactTooltip
                                                        anchorId={"contact-email"}
                                                        place="bottom"
                                                        variant="info"
                                                        multiline={true}
                                                        className="tooltip"
                                                        html={getConfigurationByValue("SUPPORT_EMAIL_ID")}
                                                    />

                                                </span>
                                            </a>
                                        </div>

                                    </li>
                                    <li>
                                        <div className="controls">
                                            <button id='zoom-out' className='zoom-btn btn-sm' onClick={props.handleZoomOut}>A- </button>
                                            <button id='reset' className='zoom-btn btn-sm' onClick={props.setToDefault}>&nbsp;A&nbsp;</button>
                                            <button id='zoom-in' className='zoom-btn btn-sm' onClick={props.handleZoomIn}>A+</button>
                                            <ReactTooltip
                                                anchorId={"zoom-out"}
                                                place="bottom"
                                                variant="info"
                                                multiline={true}
                                                className="tooltip"
                                                html={"Zoom Out"}
                                            />
                                            <ReactTooltip
                                                anchorId={"reset"}
                                                place="bottom"
                                                variant="info"
                                                multiline={true}
                                                className="tooltip"
                                                html={"Original"}
                                            />
                                            <ReactTooltip
                                                anchorId={"zoom-in"}
                                                place="bottom"
                                                variant="info"
                                                multiline={true}
                                                className="tooltip"
                                                html={"Zoom In"}
                                            />
                                        </div>
                                    </li>
                                    {/* <li>
                                        <span className='text-capitalize text-white btn-bg-none' title="Company Name"><i className="fas fa-globe-asia"></i> {localStorage.getItem('companyName') !== 'undefined' ? JSON.parse(localStorage.getItem('companyName')) : ''}</span>
                                    </li> */}



                                    {/* <li className="nav-item">
                                        {checkSecurityDetails("Master", "Switch") && <NavLink to={SEARCHTRAVELAGENT} className="nav-link">
                                            <i className="fas fa-people-arrows"></i> Switch
                                        </NavLink>}
                                    </li> */}
                                    {(getConfigurationByBoolen("ALLOW_SWITCH") || localStorage.getItem("officeID") === "63c13710ef3bc8b73fdb5c2e" || localStorage.getItem("RootLoginEmailID") === "pm@fareladder.us" || localStorage.getItem("RootLoginEmailID") === "cto@fareladder.us") && checkSecurityDetails("Master", "Switch") &&
                                        <li className="nav-item">
                                            <NavLink to={SEARCHTRAVELAGENT} className="nav-link">

                                                <button className="btn btn_theme btn_sm"><span className=''><img src="assets/img/switch-icon.png" alt='switch user' className='gt-switch-btn' /></span> Workspaces</button>
                                            </NavLink>
                                        </li>}

                                    {getConfigurationByBoolen("ALLOW_LOGIN") && <li><ul className="navbar-nav ms-auto mb-2 mb-lg-0 profile-menu">
                                        <li className="dropdown">
                                            <button to="" className="dropdown-toggle text-white btn-bg-none" id="navbarDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                                <span className='profile-pic position-relative'>
                                                    <img src="assets/img/user-profile.png" alt='profile' />
                                                    {/* <i className="fas fa-check user-drop-icon"></i> */}

                                                </span>

                                                <span className='text-capitalize'>{localStorage.getItem('displayName') !== 'undefined' ? JSON.parse(localStorage.getItem('displayName')) : ''}</span>
                                            </button>
                                            <ul className="dropdown-menu profile-menu1 p-3" aria-labelledby="navbarDropdown">
                                                <div className='profile-pic-inner text-center'>
                                                    <img src="assets/img/user-profile.png" alt="" />
                                                    <div className='text-primary mt-2 fw-bold mb-1'><span className=''>{localStorage.getItem('displayName') !== 'undefined' ? JSON.parse(localStorage.getItem('displayName')) : ''}</span></div>
                                                </div>
                                                <div className='text-capitalize text-primary text-center btn-bg-none mb-2' title="Company Name"><span className='text-14px badge bg-main-color'><i className="fas fa-globe-asia"></i> {localStorage.getItem('companyName') !== 'undefined' ? JSON.parse(localStorage.getItem('companyName')) : ''}</span></div>
                                                <hr className='my-1'></hr>
                                                {((getConfigurationByBoolen("FLIGHT_WALLET_PAYMENT") || getConfigurationByBoolen("HOTEL_WALLET_PAYMENT") || getConfigurationByBoolen("SINGLE_PNR_WALLET_PAYMENT"))) ?
                                                    status === 'pending' ? <li><a className="dropdown-item" >Loading Wallet</a></li> : walletActive && <li><a className="dropdown-item" ><i className="fa-solid fa-wallet text-primary"></i> <span className='text-primary'>Wallet {new Intl.NumberFormat('en-US', {
                                                        minimumFractionDigits: parseInt(getConfigurationByValue("DECIMAL_PLACES") !== null && getConfigurationByValue("DECIMAL_PLACES") !== '' ? getConfigurationByValue("DECIMAL_PLACES") : '2'),
                                                        style: 'currency',
                                                        currency: walletCurrencyCode,
                                                    }).format(walletAmount)}</span></a></li> : ''}
                                                <li><a className="dropdown-item" href="/change-password"><i className="fas fa-key"></i> Change Password</a></li>

                                                <li><span className="" ><NavLink to="" onClick={logoutHandler} className="dropdown-item gt-transperant-bg" ><i className="fas fa-sign-out-alt fa-fw"></i>  Logout</NavLink></span></li>

                                            </ul>
                                        </li>
                                    </ul></li>}
                                    {!getConfigurationByBoolen("ALLOW_LOGIN") && !secureLocalStorage.getItem("isCustomerAuthentication") && <li className="nav-item">
                                        <NavLink to={AUTHENTICATION} className="nav-link">
                                            <span> Login</span>
                                        </NavLink>
                                    </li>}



                                    {!getConfigurationByBoolen("ALLOW_LOGIN") && secureLocalStorage.getItem("isCustomerAuthentication") && <li> <ul className="navbar-nav ms-auto mb-2 mb-lg-0 profile-menu">
                                        <li className="dropdown">
                                            <button to="" className="dropdown-toggle text-white btn-bg-none" id="navbarDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                                <span className='profile-pic position-relative'>
                                                    <img src="assets/img/user-profile.png" alt='profile' />
                                                    {/* <i className="fas fa-check user-drop-icon"></i> */}

                                                </span>

                                                <span className='text-capitalize'>{localStorage.getItem('displayName') !== 'undefined' ? "Hi, " + localStorage.getItem("displayName") : ''}</span>
                                            </button>
                                            <ul className="dropdown-menu profile-menu1 p-3" aria-labelledby="navbarDropdown">
                                                <div className='profile-pic-inner text-center'>
                                                    <img src="assets/img/user-profile.png" alt="" />
                                                    <div className='text-primary mt-2 fw-bold mb-1'><span className=''>{localStorage.getItem('displayName') !== 'undefined' ? localStorage.getItem("displayName") : ''}</span></div>
                                                </div>

                                                <hr className='my-1'></hr>

                                                <li className="dropdown-item"></li>

                                                <li><span className="" ><NavLink to="" onClick={logoutCustomerHandler} className="dropdown-item gt-transperant-bg" ><i className="fas fa-sign-out-alt fa-fw"></i>  Logout</NavLink></span></li>

                                            </ul>
                                        </li>
                                    </ul></li>}

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>}
                {/*  <!-- Navbar Bar --> */}
                <div className="navbar-area">
                    <div className="main-responsive-nav">
                        <div className="container">
                            <div className="main-responsive-menu">
                                <div className="logo">
                                    <NavLink to={FLIGHTSEARCH} className="nav-link p-0">
                                        <img src={getCustomizeDetails("companyLogo")} alt="globetrotter" className='mini-head-logo-bg' />
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="main-navbar">
                        <div className="container">
                            <nav className="navbar navbar-expand-md navbar-light">
                                <NavLink to={FLIGHTSEARCH} className="nav-link">
                                    <img src={getCustomizeDetails("companyLogo")} alt="globetrotter" className='head-logo-bg' />
                                </NavLink>
                                {(authCtx.isLoggedIn && !JSON.parse(localStorage.getItem("isMobile"))) && <div className="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                                    <ul className="navbar-nav">
                                        <li className="nav-item">
                                            {(authCtx.isLoggedIn && checkSecurityDetails("Home")) &&
                                                (
                                                    getConfigurationByBoolen("ALLOW_OBT") && <a href='/TravelRequest' className="nav-link">Home</a>

                                                ) || ((checkSecurityDetails("Book", "Flight") && checkSecurityDetails("Book", "Flight", "Book_Flight_Search") === true && getConfigurationByBoolen("ALLOW_FLIGHT") === true) &&
                                                    <NavLink to={FLIGHTSEARCH} className="nav-link">
                                                        Home
                                                    </NavLink>

                                                ) || ((checkSecurityDetails("Book", "Hotel") && checkSecurityDetails("Book", "Hotel", "Book_Hotel_Search") && getConfigurationByBoolen("ALLOW_HOTEL") === true) &&
                                                    <NavLink to={SEARCHHOTEL} className="nav-link">
                                                        Home
                                                    </NavLink>
                                                ) || ((checkSecurityDetails("Book", "Sightseeing") && getConfigurationByBoolen("ALLOW_SIGHTSEEING") === true) &&

                                                    <NavLink to={SEARCHSIGHTSEEING} className="nav-link">
                                                        Home
                                                    </NavLink>
                                                )}
                                        </li>

                                        {(authCtx.isLoggedIn && checkSecurityDetails("My Trips")) &&
                                            <li className="nav-item">
                                                <NavLink to={getConfigurationByBoolen("ALLOW_LOGIN") ? MYTRIPS : secureLocalStorage.getItem("isCustomerAuthentication") && !getConfigurationByBoolen("ALLOW_LOGIN") ? MYTRIPS : AUTHENTICATION} className="nav-link">
                                                    My Trips
                                                </NavLink>
                                            </li>
                                        }

                                        {(authCtx.isLoggedIn && checkSecurityDetails("My Queue") && isApproverLogin) &&
                                            <li className="nav-item">
                                                <NavLink to={MYQUEUE} className="nav-link">
                                                    My Queue
                                                </NavLink>
                                            </li>
                                        }

                                        {(authCtx.isLoggedIn && checkSecurityDetails("Book")) && <li className="nav-item">
                                            <NavLink to={0} className="nav-link">
                                                Book<i className="fas fa-angle-down"></i>
                                            </NavLink>
                                            <ul className="dropdown-menu">
                                                {JSON.parse(localStorage.getItem("isTravelAgent")) !== "1" && !JSON.parse(localStorage.getItem("isCorporate")) && (checkSecurityDetails("Book", "Flight") && checkSecurityDetails("Book", "Flight", "Book_Flight_Search") === true && getConfigurationByBoolen("ALLOW_FLIGHT") === true) && <li className="nav-item">
                                                    <NavLink to={FLIGHTSEARCH} className="nav-link">
                                                        Flight
                                                    </NavLink>
                                                </li>}
                                                {JSON.parse(localStorage.getItem("isTravelAgent")) !== "1" && !JSON.parse(localStorage.getItem("isCorporate")) && (checkSecurityDetails("Book", "Hotel") && checkSecurityDetails("Book", "Hotel", "Book_Hotel_Search") && getConfigurationByBoolen("ALLOW_HOTEL") === true) && <li className="nav-item">
                                                    <NavLink to={SEARCHHOTEL} className="nav-link">Hotel</NavLink>
                                                </li>}
                                                {JSON.parse(localStorage.getItem("isCorporate")) && <li className="nav-item">
                                                    <NavLink to={TRAVELREQUEST} className="nav-link">Travel Request</NavLink>
                                                </li>}
                                                {!JSON.parse(localStorage.getItem("isCorporate")) && (checkSecurityDetails("Book", "ImportPNR") && getConfigurationByBoolen("ALLOW_IMPORT_PNR") === true) && <li className="nav-item">
                                                    <NavLink to={SEARCHPNR} className="nav-link">Import Flight</NavLink>
                                                </li>}
                                                {!JSON.parse(localStorage.getItem("isCorporate")) && (checkSecurityDetails("Book", "SabreHost") && getConfigurationByBoolen("ALLOW_SABRE_HOST") === true) && <li className="nav-item">
                                                    <NavLink to={CRYPTIC} className="nav-link">Sabre Host</NavLink>
                                                </li>}
                                                {JSON.parse(localStorage.getItem("isTravelAgent")) !== "1" && !JSON.parse(localStorage.getItem("isCorporate")) && (checkSecurityDetails("Book", "Sightseeing") && getConfigurationByBoolen("ALLOW_SIGHTSEEING") === true) && <li className="nav-item">
                                                    <NavLink to={SEARCHSIGHTSEEING} className="nav-link">Sightseeing</NavLink>
                                                </li>}
                                                {JSON.parse(localStorage.getItem("isTravelAgent")) !== "1" && !JSON.parse(localStorage.getItem("isCorporate")) && (getConfigurationByBoolen("ALLOW_VISA") === true) && <li className="nav-item">
                                                    <NavLink to={SEARCHVISA} className="nav-link">Visa</NavLink>
                                                </li>}

                                            </ul>
                                        </li>}

                                        {(authCtx.isLoggedIn && checkSecurityDetails("Settings")) && <li className="nav-item">
                                            <NavLink to={0} className="nav-link">
                                                Settings<i className="fas fa-angle-down"></i>
                                            </NavLink>
                                            <ul className="dropdown-menu">

                                                {!JSON.parse(localStorage.getItem("isCorporate")) && checkSecurityDetails("Settings", "Configuration") && <li className="nav-item">
                                                    <NavLink to={SEARCHCONFIGURATION} className="nav-link">Configuration</NavLink>
                                                </li>}
                                                {!JSON.parse(localStorage.getItem("isCorporate")) && getConfigurationByBoolen("ALLOW_CONTENT") && checkSecurityDetails("Settings", "Content") && <li className="nav-item">
                                                    <NavLink to={SEARCHCONTENT} className="nav-link">Content</NavLink>
                                                </li>}
                                                {getConfigurationByBoolen("ALLOW_CUSTOMIZE") && checkSecurityDetails("Settings", "Customize") && <li className="nav-item">
                                                    <NavLink to={UPDATECUSTOMIZE} className="nav-link">Customize</NavLink>
                                                </li>}
                                                {getConfigurationByBoolen("ALLOW_EMAIL_SERVER") && checkSecurityDetails("Settings", "Email Server") && <li className="nav-item">
                                                    <NavLink to={UPDATEEMAILSERVER} className="nav-link">Email Server</NavLink>
                                                </li>}
                                                {JSON.parse(localStorage.getItem("isCorporate")) && checkSecurityDetails("Settings", "Policy") && < li className="nav-item">
                                                    <NavLink to={SEARCHRULES} className="nav-link">Policy</NavLink>
                                                </li>}
                                                {!JSON.parse(localStorage.getItem("isCorporate")) && getConfigurationByBoolen("ALLOW_RATE_PLAN_ALLOWED") && checkSecurityDetails("Settings", "RatePlanAllowed") && < li className="nav-item">
                                                    <NavLink to={SEARCHRATEPLANALLOWED} className="nav-link">Rate Plan Allowed</NavLink>
                                                </li>}

                                                {!JSON.parse(localStorage.getItem("isCorporate")) && (getConfigurationByBoolen("FLIGHT_WALLET_PAYMENT") || getConfigurationByBoolen("HOTEL_WALLET_PAYMENT") || getConfigurationByBoolen("SINGLE_PNR_WALLET_PAYMENT")) && checkSecurityDetails("Settings", "Wallet") && <li className="nav-item">
                                                    <NavLink to={SEARCHWALLET} className="nav-link">Wallet</NavLink>
                                                </li>}
                                                {JSON.parse(localStorage.getItem("isCorporate")) && checkSecurityDetails("Settings", "Workflow") && <li className="nav-item">
                                                    <NavLink to={SEARCHAPPROVER} className="nav-link">Workflows</NavLink>
                                                </li>}
                                                {/*      {checkSecurityDetails("Settings", "Fee") && <li className="nav-item">
                                                    <NavLink to={SEARCHFEE} className="nav-link">Fee</NavLink>
                                                </li>} */}



                                            </ul>
                                        </li>
                                        }

                                        {(authCtx.isLoggedIn && checkSecurityDetails("User Management")) && <li className="nav-item">
                                            <NavLink to={0} className="nav-link">
                                                User Management<i className="fas fa-angle-down"></i>
                                            </NavLink>
                                            <ul className="dropdown-menu">
                                                {!JSON.parse(localStorage.getItem("isCorporate")) && getConfigurationByBoolen("ALLOW_OFFICE") && checkSecurityDetails("User Management", "Office") && <li className="nav-item">
                                                    <NavLink to={SEARCHOFFICE} className="nav-link">Office</NavLink>
                                                </li>}
                                                {checkSecurityDetails("User Management", "Role") && <li className="nav-item">
                                                    <NavLink to={SEARCHROLE} className="nav-link">Role</NavLink>
                                                </li>}
                                                {checkSecurityDetails("User Management", "User") && <li className="nav-item">
                                                    <NavLink to={ALLUSER} className="nav-link">User</NavLink>
                                                </li>}

                                            </ul>
                                        </li>}

                                        {(authCtx.isLoggedIn && getConfigurationByBoolen("ALLOW_REGION") && checkSecurityDetails("Master")) && <li className="nav-item">
                                            <NavLink to={0} className="nav-link">
                                                Masters<i className="fas fa-angle-down"></i>
                                            </NavLink>
                                            <ul className="dropdown-menu">
                                                {getConfigurationByBoolen("ALLOW_AIRLINE") && checkSecurityDetails("Master", "Airline") && <li className="nav-item">
                                                    <NavLink to={ALLAIRLINE} className="nav-link">Airline</NavLink>
                                                </li>}
                                                {getConfigurationByBoolen("ALLOW_AIRPORT") && checkSecurityDetails("Master", "Airport") && <li className="nav-item">
                                                    <NavLink to={ALLAIRPORT} className="nav-link">Airport</NavLink>
                                                </li>}

                                                {getConfigurationByBoolen("ALLOW_CITY") && checkSecurityDetails("Master", "City") && <li className="nav-item">
                                                    <NavLink to={ALLCITY} className="nav-link">City</NavLink>
                                                </li>}
                                                {/* {getConfigurationByBoolen("ALLOW_CITY") && checkSecurityDetails("Master", "City") && <li className="nav-item">
                                                    <NavLink to={SEARCHCORPORATE} className="nav-link">Corporate</NavLink>
                                                </li>} */}

                                                {JSON.parse(localStorage.getItem("isCorporate")) && checkSecurityDetails("Master", "FIRST") && < li className="nav-item">
                                                    <NavLink to={{ pathname: `/SearchCorporateMaster/FIRST`, MasterSequence: 1 }} className="nav-link">{getConfigurationByValue("FIRST")}</NavLink>
                                                </li>}

                                                {JSON.parse(localStorage.getItem("isCorporate")) && checkSecurityDetails("Master", "SECOND") && < li className="nav-item">
                                                    <NavLink to={{ pathname: `/SearchCorporateMaster/SECOND`, MasterSequence: 2 }} className="nav-link">{getConfigurationByValue("SECOND")}</NavLink>
                                                </li>}
                                                {JSON.parse(localStorage.getItem("isCorporate")) && checkSecurityDetails("Master", "FIFTH") && < li className="nav-item">
                                                    <NavLink to={{ pathname: `/SearchCorporateMaster/FIFTH`, MasterSequence: 5 }} className="nav-link">{getConfigurationByValue("FIFTH")}</NavLink>
                                                </li>}
                                                {JSON.parse(localStorage.getItem("isCorporate")) && checkSecurityDetails("Master", "HighRiskDestination") &&
                                                    < li className="nav-item">
                                                        <NavLink to={HIGHRISKDESTINATION} className="nav-link">High Risk Destination</NavLink>
                                                    </li>
                                                }
                                                {JSON.parse(localStorage.getItem("isCorporate")) && checkSecurityDetails("Master", "FIFTH") && < li className="nav-item">
                                                    <NavLink to={{ pathname: `/SearchCorporateMaster/SEVEN`, MasterSequence: 5 }} className="nav-link">{getConfigurationByValue("SEVEN")}</NavLink>
                                                </li>}

                                                {JSON.parse(localStorage.getItem("isCorporate")) && checkSecurityDetails("Master", "THIRD") && < li className="nav-item">
                                                    <NavLink to={{ pathname: `/SearchCorporateMaster/THIRD`, MasterSequence: 3 }} className="nav-link">{getConfigurationByValue("THIRD")}</NavLink>
                                                </li>}


                                                {getConfigurationByBoolen("ALLOW_REGION") && checkSecurityDetails("Master", "Region") && <li className="nav-item">
                                                    <NavLink to={SEARCHREGION} className="nav-link">Region</NavLink>
                                                </li>}
                                                {JSON.parse(localStorage.getItem("isCorporate")) && checkSecurityDetails("Master", "SIX") && < li className="nav-item">
                                                    <NavLink to={{ pathname: `/SearchCorporateMaster/SIX`, MasterSequence: 6 }} className="nav-link">{getConfigurationByValue("SIX")}</NavLink>
                                                </li>}
                                                {!JSON.parse(localStorage.getItem("isCorporate")) && checkSecurityDetails("Master", "Fee") && <li className="nav-item">
                                                    <NavLink to={SEARCHFEES} className="nav-link">Fee</NavLink>
                                                </li>}

                                                {/* {<li className="nav-item">
                                                    <NavLink to={SEARCHCREDITLIMIT} className="nav-link">Credit Limit</NavLink>
                                                </li>} */}

                                                {/* <li className="nav-item">
                                                    <NavLink to={ALLCOUNTRY} className="nav-link">Country</NavLink>
                                                </li> */}
                                                {getConfigurationByBoolen("ALLOW_USER_FRIENDLY") && checkSecurityDetails("Master", "Message") && <li className="nav-item">
                                                    <NavLink to={SEARCHUSERFRIENDLY} className="nav-link">Message</NavLink>
                                                </li>}
                                                {getConfigurationByBoolen("ALLOW_RATE_PLAN_MASTER") && checkSecurityDetails("Master", "RatePlan") && <li className="nav-item">
                                                    <NavLink to={SEARCHRATEPLAN} className="nav-link">Rate Plan</NavLink>
                                                </li>}

                                                {JSON.parse(localStorage.getItem("isCorporate")) && checkSecurityDetails("Master", "FOURTH") && < li className="nav-item">
                                                    <NavLink to={{ pathname: `/SearchCorporateMaster/FOURTH`, MasterSequence: 4 }} className="nav-link">{getConfigurationByValue("FOURTH")}</NavLink>
                                                </li>}
                                                {JSON.parse(localStorage.getItem("isCorporate")) && checkSecurityDetails("Master", "EIGHT") && < li className="nav-item">
                                                    <NavLink to={{ pathname: `/SearchCorporateMaster/EIGHT`, MasterSequence: 4 }} className="nav-link">{getConfigurationByValue("EIGHT")}</NavLink>
                                                </li>}
                                                {JSON.parse(localStorage.getItem("isCorporate")) && checkSecurityDetails("Master", "TripReason") && < li className="nav-item">
                                                    <NavLink to={SEARCHTRIPREASON} className="nav-link">Trip Type</NavLink>
                                                </li>}


                                            </ul>
                                        </li>}

                                        {(authCtx.isLoggedIn && checkSecurityDetails("Reports")) && <li className="nav-item">
                                            <NavLink to={0} className="nav-link">
                                                Reports<i className="fas fa-angle-down"></i>

                                            </NavLink>
                                            <ul className="dropdown-menu">
                                                {checkSecurityDetails("Reports", "DashBoard") && <li className="nav-item">
                                                    <NavLink to={'/SearchSales'} className="nav-link">Dashboard</NavLink>
                                                </li>}
                                                {checkSecurityDetails("Reports", "FlightBooking") && getConfigurationByBoolen("ALLOW_FLIGHT") && <li className="nav-item">
                                                    <NavLink to={'/SearchFlightBooking'} className="nav-link">Flight Booking</NavLink>
                                                </li>}
                                                {checkSecurityDetails("Reports", "HotelBooking") && getConfigurationByBoolen("ALLOW_HOTEL") && <li className="nav-item">
                                                    <NavLink to={'/SearchHotelBooking'} className="nav-link">Hotel Booking</NavLink>
                                                </li>}

                                                {checkSecurityDetails("Reports", "HotelCommission") && getConfigurationByBoolen("ALLOW_HOTEL") && getConfigurationByBoolen("ALLOW_COMISSION") && <li className="nav-item">
                                                    <NavLink to={'/SearchHotelCommission'} className="nav-link">Hotel Commission</NavLink>
                                                </li>}

                                                {(getConfigurationByBoolen("FLIGHT_WALLET_PAYMENT") || getConfigurationByBoolen("HOTEL_WALLET_PAYMENT") || getConfigurationByBoolen("SINGLE_PNR_WALLET_PAYMENT")) && checkSecurityDetails("Reports", "WalletUtilization") && <li className="nav-item">
                                                    <NavLink to={'/SearchWalletUtilization'} className="nav-link">Wallet Utilization</NavLink>
                                                </li>}

                                            </ul>
                                        </li>}

                                    </ul>
                                </div>}
                            </nav>
                        </div>
                    </div>

                </div>

            </header >}

            {/*    <!-- search --> */}
            <div className="search-overlay">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="search-overlay-layer"></div>
                        <div className="search-overlay-layer"></div>
                        <div className="search-overlay-layer"></div>
                        <div className="search-overlay-close">
                            <span className="search-overlay-close-line"></span>
                            <span className="search-overlay-close-line"></span>
                        </div>
                        <div className="search-overlay-form">
                            <form>
                                <input type="text" className="input-search" placeholder="Search here..." />
                                <button type="button"><i className="fas fa-search"></i></button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment >
    )
}

export default MainNavigation;
