import { Fragment, useState, useRef, useContext, useEffect } from 'react';
import AuthContext from '../../../../redux/contexts/auth-context';
import { useHistory } from 'react-router-dom';
import LoadingSpinner from '../../../../components/Common/Loading/LoadingSpinner'
import { regEmailID } from '../../../../config/RegularExpression'
import {
    LOGIN, VALIDATE
} from '../../../../navigation/CONSTANTS.js'
const AuthenticationForm = (props) => {
    const loginEmailIDRef = useRef('');
  
    const [loginEmailIDHasError, setloginEmailIDHasError] = useState(false);
    useEffect(() => {
        loginEmailIDRef.current.focus();
    }, [])
    function submitFormHandler(event) {
        event.preventDefault();
        const enteredloginEmailID = loginEmailIDRef.current.value;

        // optional: Could validate here
        if (enteredloginEmailID === '') {
            loginEmailIDRef.current.focus();
            setloginEmailIDHasError(true);
            return;
        }
        if (!enteredloginEmailID.match(regEmailID)) {
            loginEmailIDRef.current.focus();
            setloginEmailIDHasError(true);
            return;
        }
        props.onSendOTP({
            id: "string",
            userID: JSON.parse(localStorage.getItem('userID')),
            iPAddress: localStorage.getItem("IPAddress"),
            emailLoginID: enteredloginEmailID,
            officeID: JSON.parse(localStorage.getItem('officeID')),
        });
     
    }
    
    function onBlurCheck(event) {
        const enteredInputsecuritycode = loginEmailIDRef.current.value;
        // optional: Could validate here
        if (enteredInputsecuritycode === '') {
            setloginEmailIDHasError(true); return;
        }
        else {
            setloginEmailIDHasError(false);
        }

        if (enteredInputsecuritycode.length === 6)
            submitFormHandler(event);
    }

    return (
        <Fragment>
            <form className='form'>
                <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-2"></div>
                            <div className="col-lg-8 mb-2">
                                <div className="card card-primary card-outline">
                                    <div className="card-body">

                                        <div className="col-md-9 mx-auto gt-country-details-form my-5 signin-log">
                                          
                                            <div className="form-group col-md-12 position-relative">
                                                {props.isLoading && (
                                                    <div className='loading'>
                                                        <LoadingSpinner />
                                                    </div>
                                                )}
                                                <label className="mb-2  h5 text-primary mb-3 col-lg-12"><i className="fas fa-globe-asia"></i> Sign in </label>
                                                <div className="control">
                                                    <label className="h6 text-primary col-lg-12" htmlFor='country'>Email ID</label>

                                                </div>


                                                <div className="control">

                                                    <input
                                                        maxLength="50" type='text'
                                                        id='loginEmailID'
                                                        ref={loginEmailIDRef}
                                                        onChange={onBlurCheck}
                                                        className="mb-1 form-control form-control-sm col-lg-12"
                                                    />
                                                    {loginEmailIDHasError && <p className="error-text">Please specify your email ID.</p>}

                                                </div>
                                                <div className='mt-3 actions row'>
                                                    <div className="col-lg-3 col-md-3 col-sm-3 my-auto">

                                                    </div>
                                                    <div className="col-lg-9 col-md-9 col-sm-9">
                                                        <div className="float-md-end float-lg-end">

                                                            <button type="button" className="btn main-btn-width-auto text-white mt-1 gt-main-btn-WithoutAuth-MFA ms-1" tabIndex={3} onClick={submitFormHandler}>Continue with Email</button>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="mt-3 col-md-12">

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2"></div>
                        </div>
                    </div>
                </section>
            </form>
        </Fragment >
    );
};
export default AuthenticationForm;