import {
    GET_COMMONLIST_BY_NAME,
    UPDATE_CONTENT,
    ADD_CONTENT,
    SEARCH_CONTENT,
    GET_CONTENT,
    SEARCH_REGION,
}
    from './CONSTANTS'

export async function geteCommonListtByName(name) {
    const response = await fetch(GET_COMMONLIST_BY_NAME(name), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch common list.');
    }
    return data;
}
export async function serachContent(searchData) {
    const response = await fetch(SEARCH_CONTENT(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {

            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },

    });
    const data = await response.json()
    
    if (!response.ok) {
        throw new Error(data.message || 'could not fetch content');
    }
  
//    console.log(data)
    return data;


}
export async function serachRegion(searchData) {
     const response = await fetch(SEARCH_REGION(), {
         method: 'POST',
         body: JSON.stringify(searchData),
         headers: {
 
             'Content-Type': 'application/json',
             'Authorization': 'Bearer ' + localStorage.getItem('token'),
         },
 
     });
     const data = await response.json()
     
     if (!response.ok) {
         throw new Error(data.message || 'could not fetch content');
     }
 
     return data;
 
 
 }
export async function updateContent(updateData) {
    const response = await fetch(UPDATE_CONTENT(),
        {
            method: "PUT",
            body: JSON.stringify(updateData),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }

        });

    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'could not update content');
    }
    return data;

}

export async function addContent(addData){
    const response =await fetch(ADD_CONTENT(),{
        method:"POST",
        body:JSON.stringify(addData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        }
    });

    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'could not add content');
    }
    return data;

}
export async function getSingleContent(ID) {
    const response = await fetch(GET_CONTENT(ID), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'could not fetch content');
    }
    return data;
}
