import { useState, useEffect } from 'react';
import Dropdown from 'react-select';
import { CustomToFixed } from '../../../../utils/CommonFunction';

const AddMarkup = (props) => {
    const [markupLabel, setMarkupLabel] = useState('');
    const [intialBookingJSON, setIntialBookingJSON] = useState([]);

    const prefernce_list = [
        { value: '1', label: 'Amount' },
        { value: '2', label: 'Percentage' }
    ];

    function assignMarkup() {
        // if (document.querySelector('#markup').value === '') {
        //     document.querySelector('#markupValue').innerHTML = "Service Fee is required.";
        //     document.querySelector('#markup').focus();
        //     return;
        // }

        if (document.querySelector('#markup').value !== '' && !document.querySelector('#markup').value.match('^[0-9.]+$')) {
            document.querySelector('#markupValue').innerHTML = "Service Fee is not valid.";
            document.querySelector('#markup').focus();
            return;
        }

        if (document.querySelector('#markup').value !== "" && markupLabel === '') {
            document.querySelector('#markupPrefernce').innerHTML = "Service Fee preference is required.";
            document.querySelector('#markup').focus();
            return;
        }
        else if (document.querySelector('#markup').value === "" && markupLabel !== '') {
            document.querySelector('#markupValue').innerHTML = "Service Fee is required.";
            document.querySelector('#markup').focus();
            return;
        }
        else if (markupLabel === 'Percentage') {
            if (document.querySelector('#markup').value > 100) {
                document.querySelector('#markupValue').innerHTML = "Service Fee cannot exceed 100%.";
                document.querySelector('#markup').focus();
                return;
            }
            else {
                document.querySelector('#markupValue').innerHTML = "";
                document.querySelector('#markupPrefernce').innerHTML = "";
            }
        }
        else if (markupLabel === 'Amount') {
            let totalFare = 0;
            let bookingJSON = JSON.parse(sessionStorage.getItem("BookingData"));

            if (bookingJSON !== undefined && bookingJSON !== null) {
                if (bookingJSON.data.airItinerary !== undefined && bookingJSON.data.airItinerary !== null) {
                    if (bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare !== null) {
                        totalFare = bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.amount;
                    }
                }
            }

            let totalAmount = 0;
            if (sessionStorage.getItem("FlightIntialTotalAmountImport") === null) {
                totalAmount = document.querySelector('#totalFare').value !== '' ? document.querySelector('#totalFare').value : totalFare;
                sessionStorage.setItem("FlightIntialTotalAmountImport", totalAmount);
            }
            else {
                totalAmount = sessionStorage.getItem("FlightIntialTotalAmountImport");
            }

            if (document.querySelector('#markup').value !== '') {
                if (parseFloat(document.querySelector('#markup').value) > parseFloat(totalAmount)) {
                    document.querySelector('#markupValue').innerHTML = "Service Fee cannot exceed Total Amount.";
                    document.querySelector('#markup').focus();
                    return;
                }
                else {
                    document.querySelector('#markupValue').innerHTML = "";
                    document.querySelector('#markupPrefernce').innerHTML = "";
                }
            }

        }
        else {
            document.querySelector('#markupValue').innerHTML = "";
            document.querySelector('#markupPrefernce').innerHTML = "";
        }

        if (document.querySelector('#markup').value !== "" && markupLabel !== '' && parseFloat(document.querySelector('#markup').value) > 0) {
            if (!document.querySelector('#chkTandcZeroDispute:checked')) {
                document.querySelector('#chkTandcZeroDispute').focus();
                document.querySelector('#spnTandCMessage').innerHTML = "Please agree to the terms and conditions for adding service fees and markups.";
                return;
            }
            else if (!document.querySelector('#chkTandcChargeName:checked')) {
                document.querySelector('#chkTandcChargeName').focus();
                document.querySelector('#spnTandCMessage').innerHTML = "Please agree to the terms and conditions for adding service fees and markups.";
                return;
            }
            else {
                document.querySelector('#spnTandCMessage').innerHTML = "";
            }
        }

        if (document.querySelector('#markup').value !== "" && markupLabel !== '' && parseFloat(document.querySelector('#markup').value) > 0) {
            let bookingJSON = JSON.parse(sessionStorage.getItem("BookingData"));
            var totalFareAmount = 0;
            let calculatedAmount = 0;

            if (intialBookingJSON.length === 0) {
                setIntialBookingJSON(JSON.parse(sessionStorage.getItem("BookingData")));
            }

            if (intialBookingJSON.length === 0) {
                if (bookingJSON.data.airItinerary !== undefined) {
                    bookingJSON.data.airItinerary.forEach(elementAirItinerary => {
                        if (markupLabel === 'Amount') {
                            elementAirItinerary.airItineraryPricingInfo.totalFare.amount = sessionStorage.getItem("finalTotalFare"); //parseFloat(elementAirItinerary.airItineraryPricingInfo.totalFare.amount) - parseFloat(elementAirItinerary.airItineraryPricingInfo.passengerTypeQuantity !== undefined && elementAirItinerary.airItineraryPricingInfo.passengerTypeQuantity[0] !== undefined && elementAirItinerary.airItineraryPricingInfo.passengerTypeQuantity[0].fees !== undefined && elementAirItinerary.airItineraryPricingInfo.passengerTypeQuantity[0].fees.fee !== undefined ? elementAirItinerary.airItineraryPricingInfo.passengerTypeQuantity[0].fees.fee.filter(a => a.text === 'ServiceFee-Percentage' || a.text === 'ServiceFee-Amount').length > 0 ? elementAirItinerary.airItineraryPricingInfo.passengerTypeQuantity[0].fees.fee.filter(a => a.text === 'ServiceFee-Percentage' || a.text === 'ServiceFee-Amount')[0].amount : 0 : 0);
                            elementAirItinerary.airItineraryPricingInfo.totalFare.amount = parseFloat(elementAirItinerary.airItineraryPricingInfo.totalFare.amount) + parseFloat(document.querySelector('#markup').value);
                            elementAirItinerary.airItineraryPricingInfo.totalFare.amount = elementAirItinerary.airItineraryPricingInfo.totalFare.amount.toFixed(2);
                            totalFareAmount = elementAirItinerary.airItineraryPricingInfo.totalFare.amount;
                        }
                        else if (markupLabel === 'Percentage') {
                            elementAirItinerary.airItineraryPricingInfo.totalFare.amount = sessionStorage.getItem("finalTotalFare"); //parseFloat(elementAirItinerary.airItineraryPricingInfo.totalFare.amount) - parseFloat(elementAirItinerary.airItineraryPricingInfo.passengerTypeQuantity !== undefined && elementAirItinerary.airItineraryPricingInfo.passengerTypeQuantity[0] !== undefined && elementAirItinerary.airItineraryPricingInfo.passengerTypeQuantity[0].fees !== undefined && elementAirItinerary.airItineraryPricingInfo.passengerTypeQuantity[0].fees.fee !== undefined ? elementAirItinerary.airItineraryPricingInfo.passengerTypeQuantity[0].fees.fee.filter(a => a.text === 'ServiceFee-Percentage' || a.text === 'ServiceFee-Amount').length > 0 ? elementAirItinerary.airItineraryPricingInfo.passengerTypeQuantity[0].fees.fee.filter(a => a.text === 'ServiceFee-Percentage' || a.text === 'ServiceFee-Amount')[0].amount : 0 : 0);
                            calculatedAmount = (parseFloat(elementAirItinerary.airItineraryPricingInfo.totalFare.amount) * parseFloat(document.querySelector('#markup').value)) / 100;
                            elementAirItinerary.airItineraryPricingInfo.totalFare.amount = parseFloat(elementAirItinerary.airItineraryPricingInfo.totalFare.amount) + parseFloat(calculatedAmount);
                            elementAirItinerary.airItineraryPricingInfo.totalFare.amount = CustomToFixed(elementAirItinerary.airItineraryPricingInfo.totalFare.amount, 2);
                            totalFareAmount = elementAirItinerary.airItineraryPricingInfo.totalFare.amount;
                        }

                        if (elementAirItinerary.airItineraryPricingInfo.passengerTypeQuantity !== undefined) {
                            elementAirItinerary.airItineraryPricingInfo.passengerTypeQuantity.forEach(elementPassengerTypeQuantity => {
                                if (elementPassengerTypeQuantity.fees !== undefined && elementPassengerTypeQuantity.fees.fee !== undefined && elementPassengerTypeQuantity.fees.fee.length > 0) {
                                    if (elementPassengerTypeQuantity.fees.fee.filter(a => a.text !== 'ServiceFee-Percentage' || a.text !== 'ServiceFee-Amount').length > 0) {
                                        let excludeFeePercentage = elementPassengerTypeQuantity.fees.fee.filter(a => a.text !== 'ServiceFee-Percentage');
                                        elementPassengerTypeQuantity.fees.fee = excludeFeePercentage;
                                        let excludeFeeAmount = elementPassengerTypeQuantity.fees.fee.filter(a => a.text !== 'ServiceFee-Amount');
                                        elementPassengerTypeQuantity.fees.fee = excludeFeeAmount;
                                    }
                                }
                            });
                        }

                        elementAirItinerary.airItineraryPricingInfo.passengerTypeQuantity.forEach(elementPassengerTypeQuantity => {
                            if (elementPassengerTypeQuantity.fees !== undefined) {
                                let maxKey = Math.max(...elementPassengerTypeQuantity.fees.fee.map(o => o.key));
                                maxKey = maxKey + 1;
                                elementPassengerTypeQuantity.fees.fee.push({
                                    "key": maxKey.toString(),
                                    "currencyCode": elementPassengerTypeQuantity.totalFare.currencyCode,
                                    "amount": markupLabel === 'Percentage' ? parseFloat(CustomToFixed(calculatedAmount, 2)).toString() : parseFloat(document.querySelector('#markup').value).toFixed(2).toString(),
                                    "text": "ServiceFee-" + markupLabel
                                });
                            }
                            else {
                                let fee = [];
                                fee.push({
                                    "key": "1",
                                    "currencyCode": elementPassengerTypeQuantity.totalFare.currencyCode,
                                    "amount": markupLabel === 'Percentage' ? parseFloat(CustomToFixed(calculatedAmount, 2)).toString() : parseFloat(document.querySelector('#markup').value).toFixed(2).toString(),
                                    "text": "ServiceFee-" + markupLabel
                                })
                                elementPassengerTypeQuantity.fees = { fee };
                            }
                        });
                    });
                }
            }
            else {
                bookingJSON = JSON.parse(JSON.stringify(intialBookingJSON));
                if (bookingJSON.data.airItinerary !== undefined) {
                    bookingJSON.data.airItinerary.forEach(elementAirItinerary => {
                        if (markupLabel === 'Amount') {
                            elementAirItinerary.airItineraryPricingInfo.totalFare.amount = sessionStorage.getItem("finalTotalFare"); //parseFloat(elementAirItinerary.airItineraryPricingInfo.totalFare.amount) - parseFloat(elementAirItinerary.airItineraryPricingInfo.passengerTypeQuantity !== undefined && elementAirItinerary.airItineraryPricingInfo.passengerTypeQuantity[0] !== undefined && elementAirItinerary.airItineraryPricingInfo.passengerTypeQuantity[0].fees !== undefined && elementAirItinerary.airItineraryPricingInfo.passengerTypeQuantity[0].fees.fee !== undefined ? elementAirItinerary.airItineraryPricingInfo.passengerTypeQuantity[0].fees.fee.filter(a => a.text === 'ServiceFee-Percentage' || a.text === 'ServiceFee-Amount').length > 0 ? elementAirItinerary.airItineraryPricingInfo.passengerTypeQuantity[0].fees.fee.filter(a => a.text === 'ServiceFee-Percentage' || a.text === 'ServiceFee-Amount')[0].amount : 0 : 0);
                            elementAirItinerary.airItineraryPricingInfo.totalFare.amount = parseFloat(elementAirItinerary.airItineraryPricingInfo.totalFare.amount) + parseFloat(document.querySelector('#markup').value);
                            elementAirItinerary.airItineraryPricingInfo.totalFare.amount = elementAirItinerary.airItineraryPricingInfo.totalFare.amount.toFixed(2);
                            totalFareAmount = elementAirItinerary.airItineraryPricingInfo.totalFare.amount;
                        }
                        else if (markupLabel === 'Percentage') {
                            elementAirItinerary.airItineraryPricingInfo.totalFare.amount = sessionStorage.getItem("finalTotalFare"); //parseFloat(elementAirItinerary.airItineraryPricingInfo.totalFare.amount) - parseFloat(elementAirItinerary.airItineraryPricingInfo.passengerTypeQuantity !== undefined && elementAirItinerary.airItineraryPricingInfo.passengerTypeQuantity[0] !== undefined && elementAirItinerary.airItineraryPricingInfo.passengerTypeQuantity[0].fees !== undefined && elementAirItinerary.airItineraryPricingInfo.passengerTypeQuantity[0].fees.fee !== undefined ? elementAirItinerary.airItineraryPricingInfo.passengerTypeQuantity[0].fees.fee.filter(a => a.text === 'ServiceFee-Percentage' || a.text === 'ServiceFee-Amount').length > 0 ? elementAirItinerary.airItineraryPricingInfo.passengerTypeQuantity[0].fees.fee.filter(a => a.text === 'ServiceFee-Percentage' || a.text === 'ServiceFee-Amount')[0].amount : 0 : 0);
                            calculatedAmount = (parseFloat(elementAirItinerary.airItineraryPricingInfo.totalFare.amount) * parseFloat(document.querySelector('#markup').value)) / 100;
                            elementAirItinerary.airItineraryPricingInfo.totalFare.amount = parseFloat(elementAirItinerary.airItineraryPricingInfo.totalFare.amount) + parseFloat(calculatedAmount);
                            elementAirItinerary.airItineraryPricingInfo.totalFare.amount = CustomToFixed(elementAirItinerary.airItineraryPricingInfo.totalFare.amount, 2);
                            totalFareAmount = elementAirItinerary.airItineraryPricingInfo.totalFare.amount;
                        }

                        if (elementAirItinerary.airItineraryPricingInfo.passengerTypeQuantity !== undefined) {
                            elementAirItinerary.airItineraryPricingInfo.passengerTypeQuantity.forEach(elementPassengerTypeQuantity => {
                                if (elementPassengerTypeQuantity.fees !== undefined && elementPassengerTypeQuantity.fees.fee !== undefined && elementPassengerTypeQuantity.fees.fee.length > 0) {
                                    if (elementPassengerTypeQuantity.fees.fee.filter(a => a.text !== 'ServiceFee-Percentage' || a.text !== 'ServiceFee-Amount').length > 0) {
                                        let excludeFeePercentage = elementPassengerTypeQuantity.fees.fee.filter(a => a.text !== 'ServiceFee-Percentage');
                                        elementPassengerTypeQuantity.fees.fee = excludeFeePercentage;
                                        let excludeFeeAmount = elementPassengerTypeQuantity.fees.fee.filter(a => a.text !== 'ServiceFee-Amount');
                                        elementPassengerTypeQuantity.fees.fee = excludeFeeAmount;
                                    }
                                }
                            });
                        }

                        elementAirItinerary.airItineraryPricingInfo.passengerTypeQuantity.forEach(elementPassengerTypeQuantity => {
                            if (elementPassengerTypeQuantity.fees !== undefined) {
                                let maxKey = Math.max(...elementPassengerTypeQuantity.fees.fee.map(o => o.key));
                                maxKey = maxKey + 1;
                                elementPassengerTypeQuantity.fees.fee.push({
                                    "key": maxKey.toString(),
                                    "currencyCode": elementPassengerTypeQuantity.totalFare.currencyCode,
                                    "amount": markupLabel === 'Percentage' ? parseFloat(CustomToFixed(calculatedAmount, 2)).toString() : parseFloat(document.querySelector('#markup').value).toFixed(2).toString(),
                                    "text": "ServiceFee-" + markupLabel
                                });
                            }
                            else {
                                let fee = [];
                                fee.push({
                                    "key": "1",
                                    "currencyCode": elementPassengerTypeQuantity.totalFare.currencyCode,
                                    "amount": markupLabel === 'Percentage' ? parseFloat(CustomToFixed(calculatedAmount, 2)).toString() : parseFloat(document.querySelector('#markup').value).toFixed(2).toString(),
                                    "text": "ServiceFee-" + markupLabel
                                })
                                elementPassengerTypeQuantity.fees = { fee };
                            }
                        });
                    });
                }
            }

            sessionStorage.setItem("BookingData", JSON.stringify(bookingJSON));
        }
        else {
            let bookingJSON = [];
            if (Object.keys(intialBookingJSON).length > 0) {
                bookingJSON = JSON.parse(JSON.stringify(intialBookingJSON));
            }
            else {
                bookingJSON = JSON.parse(sessionStorage.getItem("BookingData"));
                if ((document.querySelector('#markup').value === "" || parseFloat(document.querySelector('#markup').value) === '0') || document.querySelector('#markup').value === '0') {
                    if (bookingJSON !== null) {
                        if (bookingJSON.data.airItinerary !== undefined) {
                            bookingJSON.data.airItinerary.forEach(elementAirItinerary => {

                                elementAirItinerary.airItineraryPricingInfo.totalFare.amount = sessionStorage.getItem("finalTotalFare"); //parseFloat(elementAirItinerary.airItineraryPricingInfo.totalFare.amount) - parseFloat(elementAirItinerary.airItineraryPricingInfo.passengerTypeQuantity !== undefined && elementAirItinerary.airItineraryPricingInfo.passengerTypeQuantity[0] !== undefined && elementAirItinerary.airItineraryPricingInfo.passengerTypeQuantity[0].fees !== undefined && elementAirItinerary.airItineraryPricingInfo.passengerTypeQuantity[0].fees.fee !== undefined && elementAirItinerary.airItineraryPricingInfo.passengerTypeQuantity[0].fees.fee.filter(a => a.text !== 'ServiceFee-Percentage' || a.text !== 'ServiceFee-Amount').length > 0 ? elementAirItinerary.airItineraryPricingInfo.passengerTypeQuantity[0].fees.fee.filter(a => a.text !== 'ServiceFee-Percentage' || a.text !== 'ServiceFee-Amount')[0].amount : 0);

                                if (elementAirItinerary.airItineraryPricingInfo.passengerTypeQuantity !== undefined) {
                                    elementAirItinerary.airItineraryPricingInfo.passengerTypeQuantity.forEach(elementPassengerTypeQuantity => {
                                        if (elementPassengerTypeQuantity.fees !== undefined && elementPassengerTypeQuantity.fees.fee !== undefined && elementPassengerTypeQuantity.fees.fee.length > 0) {
                                            if (elementPassengerTypeQuantity.fees.fee.filter(a => a.text !== 'ServiceFee-Percentage' || a.text !== 'ServiceFee-Amount').length > 0) {
                                                let excludeFeePercentage = elementPassengerTypeQuantity.fees.fee.filter(a => a.text !== 'ServiceFee-Percentage');
                                                elementPassengerTypeQuantity.fees.fee = excludeFeePercentage;
                                                let excludeFeeAmount = elementPassengerTypeQuantity.fees.fee.filter(a => a.text !== 'ServiceFee-Amount');
                                                elementPassengerTypeQuantity.fees.fee = excludeFeeAmount;
                                            }
                                        }
                                    });
                                }
                            });
                        }
                        sessionStorage.setItem("BookingData", JSON.stringify(bookingJSON));
                    }
                }
            }
        }

        props.onContinueButton("7");
        window.scrollTo({
            top: 500,
            behavior: "smooth",
        });
    }

    function skipMarkup() {
        props.onContinueButton("7");
        window.scrollTo({
            top: 500,
            behavior: "smooth",
        });
    }

    const onMarkupChangeHandler = (event) => {
        setMarkupLabel(event.label);
        document.querySelector('#markupPrefernce').innerHTML = "";
    }

    const preventActions = (e) => {
        if ((e.code === 'Minus' || e.target.value.length >= 10 || (e.code !== undefined && e.code.substring(0, e.code.length - 1) !== 'Digit')) && (e.code !== 'Backspace' && e.code !== 'ArrowLeft' && e.code !== 'ArrowRight' && e.code !== 'Delete' && e.code !== 'ArrowUp' && e.code !== 'ArrowDown' && e.code !== 'Tab')) {
            e.preventDefault();
        }
    };

    const preventPasteNegative = (e) => {
        const clipboardData = e.clipboardData || window.clipboardData;
        const pastedData = parseFloat(clipboardData.getData('text'));

        if (pastedData < 0) {
            e.preventDefault();
        }
    };

    const onTandcChargeNameChangeHandler = (event) => {
        var inputs = document.querySelectorAll('.tandc-options:checked');
        if (inputs.length === 2) {
            document.querySelector('#spnTandCMessage').innerHTML = "";
        }
    }

    const onTandcZeroDisputeChangeHandler = (event) => {
        var inputs = document.querySelectorAll('.tandc-options:checked');
        if (inputs.length === 2) {
            document.querySelector('#spnTandCMessage').innerHTML = "";
        }
    }

    return (<div className="accordion_flex_area">
        <div className="accordion_left_side position-relative text-muted">
            <h5>7</h5>
            <span id="completeSeven" style={{ "display": "none" }} className='position-absolute booking-check-icon text-success'><i className="fa-solid fa-circle-check"></i></span>
        </div>

        <div className="accordion-item">
            <h2 className="accordion-header">
                <button id="headingSeven" className="text-muted accordion-button collapsed" type="button"
                    data-bs-target="#collapseSeven" aria-expanded="false"
                    aria-controls="collapseSeven">
                    Add Service Fee (Optional)
                </button>
            </h2>
            <div id="collapseSeven" className="accordion-collapse collapse"
                aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                <div className='row'>
                    <div className='col-lg-4 col-md-6 col-sm-12'>
                        <div className='control'>
                            <input type="text" className="mb-0 form-control" id='markup' min="0" onPaste={preventPasteNegative} maxLength="10"></input>
                            <span id="markupValue" className="error-text"></span>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6 col-sm-12'>
                        <div className='control'>
                            <Dropdown onChange={onMarkupChangeHandler}
                                value={prefernce_list.filter(option => option.label === markupLabel)}
                                options={prefernce_list}
                                arrowClosed={<span className="arrow-closed" />}
                                arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0'
                                placeholder="Select"
                            />
                            <span id="markupPrefernce" className="error-text"></span>
                        </div>
                    </div>
                </div>
                <div className='text-black my-3 import-servicefee'>
                    <input className="form-check-input tandc-options" type="checkbox" id="chkTandcZeroDispute" onChange={(e) => onTandcZeroDisputeChangeHandler(e)}></input>&nbsp;
                    I agree that it is the agent's responsibility to ensure zero disputes on service fees and markups.</div>
                <div className='text-black my-3 import-servicefee'>
                    <input className="form-check-input tandc-options" type="checkbox" id="chkTandcChargeName" onChange={(e) => onTandcChargeNameChangeHandler(e)}></input>&nbsp;
                    I agree that this booking platform will not handle payments for service fees and markups. It is the agent's responsibility to charge and collect the payment.</div>
                <span id='spnTandCMessage' className="error-text"></span>
                <div className='row'>
                    <div className="col-md-12 my-2">
                        <div className="float-end text-end">
                            <button type="button" onClick={skipMarkup} className="btn margin-top-15 text-white btn-secondary ms-1 mb-2 btn-sm">Skip to Payment</button>
                            <button type="button" onClick={assignMarkup} className="btn margin-top-15 text-white gt-main-btn ms-1 mb-2 btn-sm">Continue</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}
export default AddMarkup