import { Fragment, useState, useRef, useContext, useEffect } from 'react';
import AuthContext from '../../../../redux/contexts/auth-context';
import { useHistory } from 'react-router-dom';
import LoadingSpinner from '../../../../components/Common/Loading/LoadingSpinner'
import { regNumber } from '../../../../config/RegularExpression'
import {
    LOGIN
} from '../../../../navigation/CONSTANTS.js'
import secureLocalStorage from 'react-secure-storage';
const ValidateForm = (props) => {
    const InputOTPRef = useRef('');
    const authCtx = useContext(AuthContext);
    const history = useHistory();
    const [oTPInputHasError, setOTPInputHasError] = useState(false);
    useEffect(() => {
        InputOTPRef.current.focus();
    }, [])
    function submitFormHandler(event) {
        event.preventDefault();
        const enteredInputOTP = InputOTPRef.current.value;

        // optional: Could validate here
        if (enteredInputOTP === '') {
            InputOTPRef.current.focus();
            setOTPInputHasError(true);
            return;
        }

        props.checkOTP({
            id: "string",
            userID: JSON.parse(localStorage.getItem('userID')),
            iPAddress: localStorage.getItem("IPAddress"),
            emailLoginID: secureLocalStorage.getItem("CustomerLoginEmailID"),
            oTP: enteredInputOTP,
            officeID: JSON.parse(localStorage.getItem('officeID')),
        });
    }
    function onBlurCheck(event) {
        const enteredInputsecuritycode = InputOTPRef.current.value;
        // optional: Could validate here
        if (enteredInputsecuritycode === '') {
            setOTPInputHasError(true); return;
        }
        else {
            setOTPInputHasError(false);
        }
        // if value is not blank, then test the regex        
        if (!event.target.value.match(regNumber)) {
            event.target.value = '';
            return;
        }

        if (enteredInputsecuritycode.length === 6)
            submitFormHandler(event);
    }
  
    return (
        <Fragment>
            <form className='form'>
                <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-2"></div>
                            <div className="col-lg-8 mb-2">
                                <div className="card card-primary card-outline">
                                    <div className="card-body my-3">
                                        <div className="col-md-9 mx-auto gt-country-details-form my-5 signin-log">
                                            <div className="form-group col-md-12 position-relative">

                                                {props.isLoading && (
                                                    <div className='loading'>
                                                        <LoadingSpinner />
                                                    </div>
                                                )}
                                                <label className="mb-4  h5 text-primary col-lg-12"><i className="fas fa-globe-asia"></i> Enter your OTP</label>
                                                <div className="">
                                                    <label className="h6 text-primary col-lg-12" htmlFor='country'> Enter your OTP password for<b> {secureLocalStorage.getItem("CustomerLoginEmailID")}</b>.</label>
                                                    {/*  {props.loadedProfileDetails.setupCode} */}
                                                </div>
                                                <div className="">
                                                    <label className="h6 text-primary col-lg-12" htmlFor='country'>OTP</label>
                                                    {/*  {props.loadedProfileDetails.setupCode} */}
                                                </div>


                                                <div className="control">

                                                    <input
                                                        maxLength="6" type='text'
                                                        id='InputOTP'
                                                        ref={InputOTPRef}
                                                        onChange={onBlurCheck}
                                                        className="mb-2 form-control form-control-sm col-lg-12"
                                                    />
                                                    {oTPInputHasError && <p className="error-text">Please specify your OTP.</p>}

                                                </div>
                                                <div className='mt-3 actions row'>
                                                    <div className="col-lg-3 col-md-3 col-sm-3 my-auto">
                                                        <a href="/Authentication" className='btn gt-btn_md text-white mt-1 gt-main-btn-WithoutAuth-MFA d-none d-sm-block d-sm-none d-md-block'><span><i className="fa fa-arrow-left"></i></span></a>
                                                    </div>
                                                    <div className="col-lg-9 col-md-9 col-sm-9">
                                                        <div className="float-md-end float-lg-end">
                                                            <a href="/Authentication" className='btn back-btn-sm text-white mt-1 gt-main-btn-WithoutAuth-MFA ms-1 d-md-none d-lg-none d-xl-none'><span><i className="fa fa-arrow-left"></i></span></a>

                                                            <button type="button" className="btn main-btn-width-auto text-white mt-1 gt-main-btn-WithoutAuth-MFA ms-1" tabIndex={3} onClick={submitFormHandler}>Login</button>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="mt-3 col-md-12">

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2"></div>
                        </div>
                    </div>
                </section>
            </form>
        </Fragment >
    );
};
export default ValidateForm;