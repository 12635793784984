import { getConfigurationByBoolen, getConfigurationByValue } from '../../../utils/ConfigurationsFunction';
import { calculateRoeAmountMain, calculateRoeAmountAdditional } from '../../../utils/CommonFunction';

const FareSummery = (props) => {
    //console.log(props.selectedFlight);
    let currentWalletCurrency = getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== null && getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== '' ? getConfigurationByValue("BASE_CURRENCY_IATA_CODE") : 'USD'; //sessionStorage.getItem("currentWalletCurrency");
    let equivalentAmountValue = calculateRoeAmountAdditional(props.selectedFlight.totalFare.currencyCode, props.selectedFlight.totalFare.amount, false);
    let dataPaxWise = [];
    let taxes = [];

    props.selectedFlight.passengerTypeQuantity.forEach(element => {
        if (element.code == 'ADT') {
            dataPaxWise.push(<label className="form-check-label text-12px " for="flexCheckDefaultf1">
                <span className="area_flex_one text-muted">
                    <span>Adult({element.quantity} * {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: element.baseFare.currencyCode
                    }).format(element.baseFare.amount)})</span>
                    <span> {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: element.baseFare.currencyCode
                    }).format(element.baseFare.amount * element.quantity)}</span>
                </span>
            </label>)
            element.taxes !== undefined && element.taxes !== '' && taxes.push(
                <label className="form-check-label text-12px " for="flexCheckDefaultf1">
                    <span className="area_flex_one text-muted">
                        <span>Adult({element.quantity} * {new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: element.taxes.currencyCode
                        }).format(element.taxes.amount)})</span>
                        <span>
                            {new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: props.selectedFlight.totalTax.currencyCode
                            }).format(element.taxes.amount * element.quantity)}
                        </span>
                    </span>
                </label>
            );
        } else if (element.code == 'CNN') {
            dataPaxWise.push(<label className="form-check-label text-12px " for="flexCheckDefaultf1">
                <span className="area_flex_one text-muted">
                    <span>Child({element.quantity} * {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: element.baseFare.currencyCode
                    }).format(element.baseFare.amount)})</span>
                    <span> {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: element.baseFare.currencyCode
                    }).format(element.baseFare.amount * element.quantity)}</span>
                </span>
            </label>)
            element.taxes !== undefined && element.taxes !== '' && taxes.push(
                <label className="form-check-label text-12px " for="flexCheckDefaultf1">
                    <span className="area_flex_one text-muted">
                        <span>Child({element.quantity} * {new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: element.taxes.currencyCode
                        }).format(element.taxes.amount)})</span>
                        <span>
                            {new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: props.selectedFlight.totalTax.currencyCode
                            }).format(element.taxes.amount * element.quantity)}
                        </span>
                    </span>
                </label>
            );
        } else if (element.code == 'INS') {
            dataPaxWise.push(<label className="form-check-label text-12px " for="flexCheckDefaultf1">
                <span className="area_flex_one text-muted">
                    <span>Seat Infant({element.quantity} * {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: element.baseFare.currencyCode
                    }).format(element.baseFare.amount)})</span>
                    <span> {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: element.baseFare.currencyCode
                    }).format(element.baseFare.amount * element.quantity)}</span>
                </span>
            </label>)
            element.taxes !== undefined && element.taxes !== '' && taxes.push(
                <label className="form-check-label text-12px " for="flexCheckDefaultf1">
                    {element.taxes !== undefined && <span className="area_flex_one text-muted">
                        <span> Seat Infant({element.quantity} * {new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: element.taxes.currencyCode
                        }).format(element.taxes.amount)})</span>
                        <span>
                            {new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: props.selectedFlight.totalTax.currencyCode
                            }).format(element.taxes.amount * element.quantity)}
                        </span>
                    </span>}
                </label>
            );
        } else {
            dataPaxWise.push(<label className="form-check-label text-12px " for="flexCheckDefaultf1">
                <span className="area_flex_one text-muted">
                    <span>Lap Infant({element.quantity} * {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: element.baseFare.currencyCode
                    }).format(element.baseFare.amount)})</span>
                    <span> {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: element.baseFare.currencyCode
                    }).format(element.baseFare.amount * element.quantity)}</span>
                </span>
            </label>)
            element.taxes !== undefined && element.taxes !== '' && taxes.push(
                <label className="form-check-label text-12px " for="flexCheckDefaultf1">
                    {element.taxes !== undefined && <span className="area_flex_one text-muted">
                        <span>Lap Infant({element.quantity} * {new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: element.taxes.currencyCode
                        }).format(element.taxes.amount)})</span>
                        <span>
                            {new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: props.selectedFlight.totalTax.currencyCode
                            }).format(element.taxes.amount * element.quantity)}
                        </span>
                    </span>}
                </label>
            );

        }

    });

    return (<div className="left_side_search_area">
        <div className="left_side_search_boxed">
            {/* <div className="left_side_search_heading mb-3">
              
            </div> */}
            <div className="tour_details_boxed_inner fare-summary">
                <div className="accordion" id="accordionExampleOne11">
                    <div className="accordion_flex_area ">
                        <div className="accordion-item fare-toggle-btn ">
                            <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button text-12px" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne111" aria-expanded="true" aria-controls="collapseOne111">

                                    <div className='ms-4 col-md-7'>Base Fare</div>
                                    <div className='right-0 position-absolute'>
                                        {new Intl.NumberFormat('en-US', {
                                            style: 'currency',
                                            currency: props.selectedFlight.totalBaseFare.currencyCode
                                        }).format(props.selectedFlight.totalBaseFare.amount)}
                                    </div>
                                </button>
                            </h2>
                            <div id="collapseOne111" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExampleOne11">
                                <div className="accordion-body">

                                    <div className="accordion_itinerary_list">
                                        {dataPaxWise}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="accordion_flex_area">
                        <div className="accordion-item fare-toggle-btn">
                            <h2 className="accordion-header" id="headingtwo">
                                <button className="accordion-button collapsed text-12px" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo22" aria-expanded="false" aria-controls="collapseTwo22">
                                    <div className='ms-4 col-md-7'> Taxes</div>
                                    <div className='right-0 position-absolute'>
                                        {new Intl.NumberFormat('en-US', {
                                            style: 'currency',
                                            currency: props.selectedFlight.totalTax.currencyCode
                                        }).format(props.selectedFlight.totalTax.amount)}
                                    </div>
                                </button>

                            </h2>
                            <div id="collapseTwo22" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExampleOne11">
                                <div className="accordion-body">

                                    <div className="accordion_itinerary_list">
                                        {taxes}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* User Story 1073: Booking Summary for Single PNR */}
                    <div className="accordion_flex_area" id="dvOtherServicesFlight">
                        <div className="accordion-item fare-toggle-btn">
                            <h2 className="accordion-header" id="headingthreee">
                                <button className="accordion-button collapsed text-12px" type="button" data-bs-toggle="collapse" data-bs-target="#collapsethree3" aria-expanded="false" aria-controls="collapsethree3">
                                    <div className='ms-4 col-md-7'> Others  </div>
                                    <div className='right-0 position-absolute' id="dvOtherServicesAmountFlight">{new Intl.NumberFormat('en-US', {
                                        style: 'currency',
                                        currency: props.selectedFlight.totalFare.currencyCode
                                    }).format(0.00)}</div>
                                </button>

                            </h2>
                            <div id="collapsethree3" className="accordion-collapse collapse"
                                aria-labelledby="headingTwo" data-bs-parent="#accordionExampleOne11">
                                <div className="accordion-body">
                                    <div className="accordion_itinerary_list">
                                        <label className="form-check-label text-12px d-none" for="flexCheckDefaultf1">
                                            <span className="area_flex_one text-muted">
                                                <span>Seats</span>
                                                <span id="spnSeatsAmount">{new Intl.NumberFormat('en-US', {
                                                    style: 'currency',
                                                    currency: props.selectedFlight.totalFare.currencyCode
                                                }).format(0.00)}</span>
                                            </span>
                                        </label>
                                        {/* User Story 1073: Booking Summary for Single PNR */}
                                        {
                                            getConfigurationByBoolen("ALLOW_SERVICE_FEE") && localStorage.getItem('isCorporate') !== 'true' &&
                                            <label className="form-check-label text-12px " for="flexCheckDefaultf1">
                                                <span className="area_flex_one text-muted">
                                                    <span>Service Fee</span>
                                                    <span id="spnMarkupAmountFlight">{new Intl.NumberFormat('en-US', {
                                                        style: 'currency',
                                                        currency: props.selectedFlight.totalFare.currencyCode
                                                    }).format(0.00)}</span>
                                                </span>
                                            </label>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr className='my-2'></hr>
            <div className="">
                {/* User Story 1073: Booking Summary for Single PNR */}
                <label className="form-check-label" for="flexCheckDefaultf1">
                    <span className="area_flex_one">
                        <span className='fw-bold text-primary'>Total Amount</span>
                        <span id="spnTotalAmountFlight" className='fw-bold ms-2 text-break'> {new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: props.selectedFlight.totalFare.currencyCode
                        }).format(props.selectedFlight.totalFare.amount)}</span>
                    </span>
                </label>
                {
                    props.selectedFlight.totalFare.currencyCode !== undefined && props.selectedFlight.totalFare.currencyCode !== '' && props.selectedFlight.totalFare.currencyCode.toString().toUpperCase() !== currentWalletCurrency.toString().toUpperCase() &&
                    <label className="form-check-label" for="flexCheckDefaultf2">
                        <span className="area_flex_one">
                            <span className='fw-bold text-primary'>Eq. Amount</span>
                            <span id="spnEquivalentAmountFlight" className='fw-bold ms-2 text-break'> {new Intl.NumberFormat('en-US', {
                                minimumFractionDigits: parseInt(getConfigurationByValue("DECIMAL_PLACES") !== null && getConfigurationByValue("DECIMAL_PLACES") !== '' ? getConfigurationByValue("DECIMAL_PLACES") : '2'),
                                style: 'currency',
                                currency: currentWalletCurrency !== undefined && currentWalletCurrency !== null && currentWalletCurrency !== '' ? currentWalletCurrency : 'USD',
                            }).format(parseFloat(equivalentAmountValue))}</span>
                        </span>
                    </label>
                }
            </div>

        </div>
    </div>)
}
export default FareSummery