import secureLocalStorage from 'react-secure-storage';
export const getCustomizeDetails = (keyName) => {
    const loadedCustomizeDetails = localStorage.getItem("loginData") === null ? null : JSON.parse(localStorage.getItem("loginData")).customizeDetails;
    let returnCustomizeValue = "";
    if (loadedCustomizeDetails !== null) {
        returnCustomizeValue = loadedCustomizeDetails[keyName] !== null ? loadedCustomizeDetails[keyName] : '';
    }
    return returnCustomizeValue;
}


export const getSocialMediaIcon = (strSocialMedia) => {
    const socialMediaIconLinkdIn = "fab fa-linkedin";
    const socialMediaIconFaceBook = "fab fa-facebook";
    const socialMediaIconInstagram = "fab fa-instagram";
    const socialMediaIconYouTube = "fab fa-youtube";
    const socialMediaIconTwitter = "fab fa-twitter";
    const socialMediaIconTiktok = "fab fa-tiktok";
    if (strSocialMedia.toLowerCase().includes("facebook")) {
        return socialMediaIconFaceBook;
    } else if (strSocialMedia.toLowerCase().includes("linkedin")) {
        return socialMediaIconLinkdIn;
    } else if (strSocialMedia.toLowerCase().includes("instagram")) {
        return socialMediaIconInstagram;
    } else if (strSocialMedia.toLowerCase().includes("youtube")) {
        return socialMediaIconYouTube;
    } else if (strSocialMedia.toLowerCase().includes("x.com")) {
        return socialMediaIconTwitter;
    } else if (strSocialMedia.toLowerCase().includes("tiktok")) {
        return socialMediaIconTiktok;
    } else {
        return "";
    }

}
