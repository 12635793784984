import { Fragment, useCallback, useState, useEffect, useRef } from 'react';
import useDocumentTitle from '../../components/Common/layout/useDocumentTitle';
import { useTranslation } from 'react-i18next';
import { searchFlight } from '../../services/Flight-API';
import useHttp from '../../services/use-http'
import LoadingFlight from './Components/LoadingFlight';
import FlightList from './Components/FlightList';
import ErrorModal from "../../components/Common/Error/ErrorModal";
import FlightSearchControlMainPanel from './Components/FlightSearchControlMainPanel';
import FlightSearchControlAdditionalPanel from './Components/FlightSearchControlAdditionalPanel';
import { v4 as uuidv4 } from 'uuid';
import { getConfigurationByBoolen, getConfigurationByValue } from '../../utils/ConfigurationsFunction';
import { GET_ALL_AIRPORT_LIST, GET_BLOCK_COUNTRY_CITY_LIST } from '../../services/CONSTANTS';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import { checkSecurityDetails } from '../../utils/RoleUtils';
import { getPolicyID } from '../../utils/OBTFunction';
import secureLocalStorage from 'react-secure-storage';
import { searchRoe } from '../../services/ROE-API';

const SearchFlight = () => {

    const [onSearchFlight, setOnSearchFlight] = useState();
    useDocumentTitle('Flight Search');
    const { t } = useTranslation(['translation.SearchFlight']);
    const { sendRequest, status, data: loadedFlightSearch, error } = useHttp(searchFlight);
    const { sendRequest: sendRequestRoe, status: statusRoe, data: loadedRoe, error: errorRoe } = useHttp(searchRoe);

    const [isSubmitClicked, setIsSubmitClicked] = useState(false);
    const [isFirtTime, setFirstTime] = useState("");
    const [tripType, setTripType] = useState('');
    const [isAddedThree, setIsAddedThree] = useState(false);
    const [isAddedFour, setIsAddedFour] = useState(false);
    const [isAddedFive, setIsAddedFive] = useState(false);
    const [error1, setError] = useState();
    const [pageFocus, setPageFocus] = useState(true);
    const location = useLocation();
    const history = useHistory();
    const valueParam = queryString.parse(location.search);
    const [contentAreaUpdated, setContentAreaUpdated] = useState(false);

    localStorage.setItem("tabActiveStatusSet", JSON.stringify([{ "Traveller": true },
    { "ContactPerson": false },
    { "SeatMap": false },
    { "AdditionalService": false },
    { "AddMarkUp": false },
    { "PaymentInformation": false }]))

    //User Story 1070: Change in Search for Single PNR
    const onAddhotel = (event) => {
        if (event.target.checked) {
            localStorage.setItem("AddHotel", "1");
            sessionStorage.setItem("AddHotelCheck", 1);
        }
        else {
            localStorage.removeItem("AddHotel");
            sessionStorage.removeItem("AddHotelCheck");
        }
    }

    const onSearchHandler = useCallback((event) => {
        document.querySelector('#explore_area').classList.remove('d-none');
        localStorage.setItem('traceID', JSON.stringify(uuidv4()));
        let blockedCountryCity = [];
        blockedCountryCity = localStorage.getItem("blockedCountryCity") !== null ? JSON.parse(localStorage.getItem("blockedCountryCity")) : '';
        if (sessionStorage.getItem("flightListJson") !== null) {
            sessionStorage.removeItem("flightListJson");

        }
        if (document.getElementById('emailRecommendation') !== null) {
            document.getElementById('emailRecommendation').style.display = 'none'
        }


        //User Story 1070: Change in Search for Single PNR
        if (document.querySelector('#addHotel') !== null) {
            if (!document.querySelector('#addHotel').checked) {
                localStorage.removeItem("AddHotel");
                sessionStorage.removeItem("AddHotelCheck");
            }
            else {
                localStorage.setItem("AddHotel", "1");
                sessionStorage.setItem("AddHotelCheck", 1);
            }
        }
        else {
            localStorage.removeItem("AddHotel");
            sessionStorage.removeItem("AddHotelCheck");
        }


        if (sessionStorage.getItem("tripType") !== null) {
            if (sessionStorage.getItem("tripType") === "multi_city") {
                window.scrollTo({
                    top: 300,
                    behavior: "smooth",
                });
            }
            else {
                window.scrollTo({
                    top: 50,
                    behavior: "smooth",
                });
            }
        }
        else {
            window.scrollTo({
                top: 50,
                behavior: "smooth",
            });
        }

        event.preventDefault();
        setFirstTime(true);
        setIsSubmitClicked(true);
        setPageFocus(true);

        if (sessionStorage.getItem("tripType") === "oneway") {
            if (sessionStorage.getItem('txtFrom1') !== null) {
                let txtFrom = sessionStorage.getItem('txtFrom1');
                let txtTo = sessionStorage.getItem('txtTo1');
                if (txtFrom === txtTo) {
                    setError({
                        title: "Message",
                        message: `${t('Origin and Destination cannot be same.')}`,
                    });
                    return;
                }
            }

            if (blockedCountryCity.length !== 0) {
                let selectedFromCity = sessionStorage.getItem("spnFromAirportDetails1").split('-')[0].trim();
                let selectedFromCountry = sessionStorage.getItem("spnFromAirportDetails1").split(',')[sessionStorage.getItem("spnFromAirportDetails1").split(',').length - 1].trim();

                let selectedToCity = sessionStorage.getItem("spnToAirportDetails1").split('-')[0].trim();
                let selectedToCountry = sessionStorage.getItem("spnToAirportDetails1").split(',')[sessionStorage.getItem("spnToAirportDetails1").split(',').length - 1].trim();

                const found = blockedCountryCity.find(el => el.code === selectedFromCity || el.code === selectedFromCountry || el.code === selectedToCity || el.code === selectedToCountry);
                if (found) {
                    if (found.service === 'FL' || found.service === 'BOTH') {
                        setError({
                            title: "Message",
                            message: `${t('Sorry, search for this city is not allowed. Please contact the Support desk.')}`,
                        });
                        return;
                    }
                }
            }
        }
        else if (sessionStorage.getItem("tripType") === "roundtrip") {
            let calFromDate = sessionStorage.getItem('calFromDateDB1');
            let calToDate = sessionStorage.getItem('calToDateDB1');
            if (parseInt(calToDate) < parseInt(calFromDate)) {
                setError({
                    title: "Message",
                    message: `${t('Return Date cannot be less than Departure Date.')}`,
                });
                return;
            }

            if (sessionStorage.getItem('txtFrom1') !== null) {
                let txtFrom = sessionStorage.getItem('txtFrom1');
                let txtTo = sessionStorage.getItem('txtTo1');
                if (txtFrom === txtTo) {
                    setError({
                        title: "Message",
                        message: `${t('Origin and Destination cannot be same.')}`,
                    });
                    return;
                }
            }

            if (blockedCountryCity.length !== 0) {
                let selectedFromCity = sessionStorage.getItem("spnFromAirportDetails1").split('-')[0].trim();
                let selectedFromCountry = sessionStorage.getItem("spnFromAirportDetails1").split(',')[sessionStorage.getItem("spnFromAirportDetails1").split(',').length - 1].trim();

                let selectedToCity = sessionStorage.getItem("spnToAirportDetails1").split('-')[0].trim();
                let selectedToCountry = sessionStorage.getItem("spnToAirportDetails1").split(',')[sessionStorage.getItem("spnToAirportDetails1").split(',').length - 1].trim();

                const found = blockedCountryCity.find(el => el.code === selectedFromCity || el.code === selectedFromCountry || el.code === selectedToCity || el.code === selectedToCountry);
                if (found) {
                    if (found.service === 'FL' || found.service === 'BOTH') {
                        setError({
                            title: "Message",
                            message: `${t('Sorry, search for this city is not allowed. Please contact the Support desk.')}`,
                        });
                        return;
                    }
                }
            }
        }
        else if (sessionStorage.getItem("tripType") === "multi_city") {
            for (let index = 1; index <= 4; index++) {
                if (sessionStorage.getItem('txtFrom' + index) !== null) {
                    let txtFromCurrent = sessionStorage.getItem('txtFrom' + index);
                    let txtToCurrent = sessionStorage.getItem('txtTo' + index);
                    let txtFromNext = sessionStorage.getItem('txtFrom' + (index + 1));
                    let txtToNext = sessionStorage.getItem('txtTo' + (index + 1));
                    if (txtFromCurrent === txtToCurrent) {
                        setError({
                            title: "Message",
                            //message: `${t(`From(${txtFromCurrent}) & To(${txtToCurrent}) airports cannot be the same.`)}`,
                            message: `${t(`Origin and Destination cannot be same.`)}`,
                        });
                        return;
                    }
                    // else if (txtFromCurrent === txtFromNext) {
                    //     setError({
                    //         title: "Message",
                    //         //message: `${t(`From(${txtFromCurrent}) & To(${txtFromNext}) airports cannot be the same.`)}`,
                    //         message: `${t(`Origin and Destination cannot be same.`)}`,
                    //     });
                    //     return;
                    // }
                    // else if (txtToCurrent === txtToNext) {
                    //     setError({
                    //         title: "Message",
                    //         //message: `${t(`From(${txtToCurrent}) & To(${txtToNext}) airports cannot be the same.`)}`,
                    //         message: `${t(`Origin and Destination cannot be same.`)}`,
                    //     });
                    //     return;
                    // }
                }

                if (sessionStorage.getItem('txtFrom' + index) !== null && sessionStorage.getItem('txtFrom' + (index + 1)) !== null) {
                    let calFromDateCurrent = sessionStorage.getItem('calFromDateDB' + index);
                    let calFromDateNext = sessionStorage.getItem('calFromDateDB' + (index + 1));

                    if (parseInt(calFromDateNext) < parseInt(calFromDateCurrent)) {
                        setError({
                            title: "Message",
                            //message: `${t(`Departure Date for ${sessionStorage.getItem('txtFrom' + index)} To ${sessionStorage.getItem('txtTo' + index)} cannot be less than ${sessionStorage.getItem('txtFrom' + (index + 1))} To ${sessionStorage.getItem('txtTo' + (index + 1))}.`)}`,
                            message: `${t(`Segment${index} (${sessionStorage.getItem('txtFrom' + index)}-${sessionStorage.getItem('txtTo' + index)}) Departure Date cannot be greater than segment${(index + 1)} (${sessionStorage.getItem('txtFrom' + (index + 1))}-${sessionStorage.getItem('txtTo' + (index + 1))}) Departure Date.`)}`,
                        });
                        return;
                    }
                }

                if (sessionStorage.getItem('txtFrom' + index) !== null && blockedCountryCity.length !== 0) {
                    let selectedFromCity = sessionStorage.getItem("spnFromAirportDetails" + index).split('-')[0].trim();
                    let selectedFromCountry = sessionStorage.getItem("spnFromAirportDetails" + index).split(',')[sessionStorage.getItem("spnFromAirportDetails" + index).split(',').length - 1].trim();

                    let selectedToCity = sessionStorage.getItem("spnToAirportDetails" + index).split('-')[0].trim();
                    let selectedToCountry = sessionStorage.getItem("spnToAirportDetails" + index).split(',')[sessionStorage.getItem("spnToAirportDetails" + index).split(',').length - 1].trim();

                    const found = blockedCountryCity.find(el => el.code === selectedFromCity || el.code === selectedFromCountry || el.code === selectedToCity || el.code === selectedToCountry);
                    if (found) {
                        if (found.service === 'FL' || found.service === 'BOTH') {
                            setError({
                                title: "Message",
                                message: `${t('Sorry, search for this city is not allowed. Please contact the Support desk.')}`,
                            });
                            return;
                        }
                    }
                }
            }
        }

        let tripTypeTemp = "";
        let OriginDestinationInformationTemp = [];
        let CabinPrefTemp = [];
        let PassengerTypeQuantityTemp = [];
        let timeSlotFromWindowBefore = "";
        let timeSlotFromWindowAfter = "";
        let timeSlotToWindowBefore = "";
        let timeSlotToWindowAfter = "";
        let isNearByAirportSearch = false;
        let isIncludeBagOnlyFares = false;
        let VendorPrefTemp = [];
        let isFlexibleFares = false;
        let directFlightsValue = 5;
        let isShowMixedCabins = true;
        let isIncludeCabinBagOnlyFares = false;

        if (sessionStorage.getItem('preferenceOptions') !== null) {
            JSON.parse(sessionStorage.getItem('preferenceOptions')).forEach(element => {
                if (element.preferenceOptionId === 'nearByAirportSearch') {
                    isNearByAirportSearch = true;
                }
                if (element.preferenceOptionId === 'includeBagOnlyFares') {
                    isIncludeBagOnlyFares = true;
                }
                if (element.preferenceOptionId === 'flexibleFares') {
                    isFlexibleFares = true;
                }
                if (element.preferenceOptionId === 'directFlights') {
                    directFlightsValue = 3;
                }
                if (element.preferenceOptionId === 'showMixedCabins') {
                    isShowMixedCabins = false;
                }
                if (element.preferenceOptionId === 'includeCabinBagOnlyFares') {
                    isIncludeCabinBagOnlyFares = true;
                }
            });
        }

        if (sessionStorage.getItem('tripType') === "oneway") {
            tripTypeTemp = "Oneway";

            if (sessionStorage.getItem("timeSlotFrom1").split('-').length > 1) {
                timeSlotFromWindowBefore = "-" + sessionStorage.getItem("timeSlotFrom1").split('-')[1];
                timeSlotFromWindowAfter = "-" + sessionStorage.getItem("timeSlotFrom1").split('-')[0];
            }

            OriginDestinationInformationTemp.push({
                "DepartureDateTime": {
                    "WindowBefore": sessionStorage.getItem("calFromDateDB1") + timeSlotFromWindowBefore,
                    "WindowAfter": sessionStorage.getItem("calFromDateDB1") + timeSlotFromWindowAfter,
                    "CrossDateAllowedIndicator": "",
                    "Text": ""
                },
                "OriginLocation": {
                    "AirportOnly": "",
                    "AlternateLocationInd": "",
                    "LocationCode": sessionStorage.getItem("spnFromAirportDetails1").split('-')[0].trim().toUpperCase(),
                    "CityCode": sessionStorage.getItem("fromCityCode1").trim().toUpperCase(),
                    "Location": `${sessionStorage.getItem("txtFrom1").trim()}, ${sessionStorage.getItem("spnFromAirportDetails1").split('-')[1].split(',')[0].trim()}(${sessionStorage.getItem("spnFromAirportDetails1").split('-')[0].trim()})`,
                    "Radius": isNearByAirportSearch === true ? "99" : ""
                },
                "DestinationLocation": {
                    "LocationCode": sessionStorage.getItem("spnToAirportDetails1").split('-')[0].trim().toUpperCase(),
                    "CityCode": sessionStorage.getItem("toCityCode1").trim().toUpperCase(),
                    "Location": `${sessionStorage.getItem("txtTo1").trim()}, ${sessionStorage.getItem("spnToAirportDetails1").split('-')[1].split(',')[0].trim()}(${sessionStorage.getItem("spnToAirportDetails1").split('-')[0].trim()})`,
                    "AirportOnly": "",
                    "AlternateLocationInd": "",
                    "Radius": isNearByAirportSearch === true ? "99" : ""
                },
                "ConnectionLocations": null,
                "AlternateLocationInfo": null,
                "FareCalenderInfo": null,
                "RPH": null,
                "RefNumber": null,
                "TPA_Extensions": null
            });

            CabinPrefTemp.push({
                "Cabin": sessionStorage.getItem('spnClass1') === 'Premium Eco' ? 'Premium Economy' : sessionStorage.getItem('spnClass1'),
                "KeepSameCabin": isShowMixedCabins
            });

            for (let i = 1; i <= 3; i++) {
                if (sessionStorage.getItem("preferredAirline" + i) !== null && sessionStorage.getItem("preferredAirline" + i) !== '') {
                    VendorPrefTemp.push({
                        "Code": sessionStorage.getItem("preferredAirline" + i).split('-')[1],
                        "PreferLevel": "Preferred"
                    });
                }
            }

            let PassengerTypeAdt = sessionStorage.getItem('spnPassengerAdt1')
            let PassengerTypeChd = sessionStorage.getItem('spnPassengerChd1')
            let PassengerTypeLapInf = sessionStorage.getItem('spnPassengerLapInf1')
            let PassengerTypeSeatInf = sessionStorage.getItem('spnPassengerSeatInf1')
            let passengers = [];

            if (PassengerTypeAdt > 0) {
                passengers.push({
                    "Code": sessionStorage.getItem('fareType') === "seniorCitizenFares" ? "SNN" : "ADT",
                    "Quantity": PassengerTypeAdt,
                    "Age": ""
                });
            }
            if (PassengerTypeChd > 0) {
                passengers.push({
                    "Code": "CHD",
                    "Quantity": PassengerTypeChd,
                    "Age": ""
                });
            }
            if (PassengerTypeLapInf > 0) {
                passengers.push({
                    "Code": "INF",
                    "Quantity": PassengerTypeLapInf,
                    "Age": ""
                });
            }
            if (PassengerTypeSeatInf > 0) {
                passengers.push({
                    "Code": "INS",
                    "Quantity": PassengerTypeSeatInf,
                    "Age": ""
                });
            }

            PassengerTypeQuantityTemp = passengers;
        }
        else if (sessionStorage.getItem('tripType') === null || sessionStorage.getItem('tripType') === "roundtrip") {
            tripTypeTemp = "RoundTrip";

            if (sessionStorage.getItem("timeSlotFrom1").split('-').length > 1) {
                timeSlotFromWindowBefore = "-" + sessionStorage.getItem("timeSlotFrom1").split('-')[1];
                timeSlotFromWindowAfter = "-" + sessionStorage.getItem("timeSlotFrom1").split('-')[0];
            }

            if (sessionStorage.getItem("timeSlotTo1").split('-').length > 1) {
                timeSlotToWindowBefore = "-" + sessionStorage.getItem("timeSlotTo1").split('-')[1];
                timeSlotToWindowAfter = "-" + sessionStorage.getItem("timeSlotTo1").split('-')[0];
            }

            OriginDestinationInformationTemp.push({
                "DepartureDateTime": {
                    "WindowBefore": sessionStorage.getItem("calFromDateDB1") + timeSlotFromWindowBefore,
                    "WindowAfter": sessionStorage.getItem("calFromDateDB1") + timeSlotFromWindowAfter,
                    "CrossDateAllowedIndicator": "",
                    "Text": ""
                },
                "OriginLocation": {
                    "AirportOnly": "",
                    "AlternateLocationInd": "",
                    "LocationCode": sessionStorage.getItem("spnFromAirportDetails1").split('-')[0].trim().toUpperCase(),
                    "CityCode": sessionStorage.getItem("fromCityCode1").trim().toUpperCase(),
                    "Location": `${sessionStorage.getItem("txtFrom1").trim()}, ${sessionStorage.getItem("spnFromAirportDetails1").split('-')[1].split(',')[0].trim()}(${sessionStorage.getItem("spnFromAirportDetails1").split('-')[0].trim()})`,
                    "Radius": isNearByAirportSearch === true ? "99" : ""
                },
                "DestinationLocation": {
                    "LocationCode": sessionStorage.getItem("spnToAirportDetails1").split('-')[0].trim().toUpperCase(),
                    "CityCode": sessionStorage.getItem("toCityCode1").trim().toUpperCase(),
                    "Location": `${sessionStorage.getItem("txtTo1").trim()}, ${sessionStorage.getItem("spnToAirportDetails1").split('-')[1].split(',')[0].trim()}(${sessionStorage.getItem("spnToAirportDetails1").split('-')[0].trim()})`,
                    "AirportOnly": "",
                    "AlternateLocationInd": "",
                    "Radius": isNearByAirportSearch === true ? "99" : ""
                },
                "ConnectionLocations": null,
                "AlternateLocationInfo": null,
                "FareCalenderInfo": null,
                "RPH": null,
                "RefNumber": null,
                "TPA_Extensions": null
            },
                {
                    "DepartureDateTime": {
                        "WindowBefore": sessionStorage.getItem("calToDateDB1") + timeSlotToWindowBefore,
                        "WindowAfter": sessionStorage.getItem("calToDateDB1") + timeSlotToWindowAfter,
                        "CrossDateAllowedIndicator": "",
                        "Text": ""
                    },
                    "OriginLocation": {
                        "AirportOnly": "",
                        "AlternateLocationInd": "",
                        "LocationCode": sessionStorage.getItem("spnToAirportDetails1").split('-')[0].trim().toUpperCase(),
                        "CityCode": sessionStorage.getItem("toCityCode1").trim().toUpperCase(),
                        "Location": `${sessionStorage.getItem("txtTo1").trim()}, ${sessionStorage.getItem("spnToAirportDetails1").split('-')[1].split(',')[0].trim()}(${sessionStorage.getItem("spnToAirportDetails1").split('-')[0].trim()})`,
                        "Radius": isNearByAirportSearch === true ? "99" : ""
                    },
                    "DestinationLocation": {
                        "LocationCode": sessionStorage.getItem("spnFromAirportDetails1").split('-')[0].trim().toUpperCase(),
                        "CityCode": sessionStorage.getItem("fromCityCode1").trim().toUpperCase(),
                        "Location": `${sessionStorage.getItem("txtFrom1").trim()}, ${sessionStorage.getItem("spnFromAirportDetails1").split('-')[1].split(',')[0].trim()}(${sessionStorage.getItem("spnFromAirportDetails1").split('-')[0].trim()})`,
                        "AirportOnly": "",
                        "AlternateLocationInd": "",
                        "Radius": isNearByAirportSearch === true ? "99" : ""
                    },
                    "ConnectionLocations": null,
                    "AlternateLocationInfo": null,
                    "FareCalenderInfo": null,
                    "RPH": null,
                    "RefNumber": null,
                    "TPA_Extensions": null
                });

            CabinPrefTemp.push({
                "Cabin": sessionStorage.getItem('spnClass1') === 'Premium Eco' ? 'Premium Economy' : sessionStorage.getItem('spnClass1'),
                "KeepSameCabin": isShowMixedCabins
            });

            for (let i = 1; i <= 3; i++) {
                if (sessionStorage.getItem("preferredAirline" + i) !== null && sessionStorage.getItem("preferredAirline" + i) !== '') {
                    VendorPrefTemp.push({
                        "Code": sessionStorage.getItem("preferredAirline" + i).split('-')[1],
                        "PreferLevel": "Preferred"
                    });
                }
            }

            let PassengerTypeAdt = sessionStorage.getItem('spnPassengerAdt1')
            let PassengerTypeChd = sessionStorage.getItem('spnPassengerChd1')
            let PassengerTypeLapInf = sessionStorage.getItem('spnPassengerLapInf1')
            let PassengerTypeSeatInf = sessionStorage.getItem('spnPassengerSeatInf1')
            let passengers = [];

            if (PassengerTypeAdt > 0) {
                passengers.push({
                    "Code": sessionStorage.getItem('fareType') === "seniorCitizenFares" ? "SNN" : "ADT",
                    "Quantity": PassengerTypeAdt,
                    "Age": ""
                });
            }
            if (PassengerTypeChd > 0) {
                passengers.push({
                    "Code": "CHD",
                    "Quantity": PassengerTypeChd,
                    "Age": ""
                });
            }
            if (PassengerTypeLapInf > 0) {
                passengers.push({
                    "Code": "INF",
                    "Quantity": PassengerTypeLapInf,
                    "Age": ""
                });
            }
            if (PassengerTypeSeatInf > 0) {
                passengers.push({
                    "Code": "INS",
                    "Quantity": PassengerTypeSeatInf,
                    "Age": ""
                });
            }

            PassengerTypeQuantityTemp = passengers;
        }
        else if (sessionStorage.getItem('tripType') === "multi_city") {
            tripTypeTemp = "MultiCity";
            let PassengerTypeAdt = 0;
            let PassengerTypeChd = 0;
            let PassengerTypeLapInf = 0;
            let PassengerTypeSeatInf = 0;

            for (let i = 1; i <= 5; i++) {
                if (sessionStorage.getItem('txtFrom' + i) !== null) {
                    if (sessionStorage.getItem("timeSlotFrom" + i).split('-').length > 1) {
                        timeSlotFromWindowBefore = "-" + sessionStorage.getItem("timeSlotFrom" + i).split('-')[1];
                        timeSlotFromWindowAfter = "-" + sessionStorage.getItem("timeSlotFrom" + i).split('-')[0];
                    }

                    OriginDestinationInformationTemp.push({
                        "DepartureDateTime": {
                            "WindowBefore": sessionStorage.getItem("calFromDateDB" + i) + timeSlotFromWindowBefore,
                            "WindowAfter": sessionStorage.getItem("calFromDateDB" + i) + timeSlotFromWindowAfter,
                            "CrossDateAllowedIndicator": "",
                            "Text": ""
                        },
                        "OriginLocation": {
                            "AirportOnly": "",
                            "AlternateLocationInd": "",
                            "LocationCode": sessionStorage.getItem("spnFromAirportDetails" + i).split('-')[0].trim().toUpperCase(),
                            "CityCode": sessionStorage.getItem("fromCityCode" + i).trim().toUpperCase(),
                            "Location": `${sessionStorage.getItem("txtFrom" + i).trim()}, ${sessionStorage.getItem("spnFromAirportDetails" + i).split('-')[1].split(',')[0].trim()}(${sessionStorage.getItem("spnFromAirportDetails" + i).split('-')[0].trim()})`,
                            "Radius": isNearByAirportSearch === true ? "99" : ""
                        },
                        "DestinationLocation": {
                            "LocationCode": sessionStorage.getItem("spnToAirportDetails" + i).split('-')[0].trim().toUpperCase(),
                            "CityCode": sessionStorage.getItem("toCityCode" + i).trim().toUpperCase(),
                            "Location": `${sessionStorage.getItem("txtTo" + i).trim()}, ${sessionStorage.getItem("spnToAirportDetails" + i).split('-')[1].split(',')[0].trim()}(${sessionStorage.getItem("spnToAirportDetails" + i).split('-')[0].trim()})`,
                            "AirportOnly": "",
                            "AlternateLocationInd": "",
                            "Radius": isNearByAirportSearch === true ? "99" : ""
                        },
                        "ConnectionLocations": null,
                        "AlternateLocationInfo": null,
                        "FareCalenderInfo": null,
                        "RPH": null,
                        "RefNumber": null,
                        "TPA_Extensions": null
                    });

                    const found = CabinPrefTemp.find(el => el.Cabin === sessionStorage.getItem('spnClass' + i));
                    if (!found) {
                        CabinPrefTemp.push({
                            "Cabin": sessionStorage.getItem('spnClass1') === 'Premium Eco' ? 'Premium Economy' : sessionStorage.getItem('spnClass1'),
                            "KeepSameCabin": isShowMixedCabins
                        });
                    }

                    PassengerTypeAdt = sessionStorage.getItem('spnPassengerAdt1')
                    PassengerTypeChd = sessionStorage.getItem('spnPassengerChd1')
                    PassengerTypeLapInf = sessionStorage.getItem('spnPassengerLapInf1')
                    PassengerTypeSeatInf = sessionStorage.getItem('spnPassengerSeatInf1')
                }
            }

            for (let i = 1; i <= 3; i++) {
                if (sessionStorage.getItem("preferredAirline" + i) !== null && sessionStorage.getItem("preferredAirline" + i) !== '') {
                    VendorPrefTemp.push({
                        "Code": sessionStorage.getItem("preferredAirline" + i).split('-')[1],
                        "PreferLevel": "Preferred"
                    });
                }
            }

            let passengers = [];

            if (PassengerTypeAdt > 0) {
                passengers.push({
                    "Code": sessionStorage.getItem('fareType') === "seniorCitizenFares" ? "SNN" : "ADT",
                    "Quantity": PassengerTypeAdt.toString(),
                    "Age": ""
                });
            }
            if (PassengerTypeChd > 0) {
                passengers.push({
                    "Code": "CHD",
                    "Quantity": PassengerTypeChd.toString(),
                    "Age": ""
                });
            }
            if (PassengerTypeLapInf > 0) {
                passengers.push({
                    "Code": "INF",
                    "Quantity": PassengerTypeLapInf.toString(),
                    "Age": ""
                });
            }
            if (PassengerTypeSeatInf > 0) {
                passengers.push({
                    "Code": "INS",
                    "Quantity": PassengerTypeSeatInf.toString(),
                    "Age": ""
                });
            }

            PassengerTypeQuantityTemp = passengers;
        }

        let travelRequestFieldsDataJson = "";
        if (sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
            travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
        }

        const onSearchFlightInitilal = (
            {
                "officeID": JSON.parse(localStorage.getItem('officeID')),
                "Data": {
                    "ProcessingInfo": {
                        "SectorInd": "I",
                        "SearchType": tripTypeTemp,
                        "AvailabilityIndicator": null,
                        "IsSpecialFare": "FALSE"
                    },
                    "POS": null,
                    "OriginDestinationInformation": OriginDestinationInformationTemp,
                    "TravelPreferences": [
                        {
                            "CabinPref": CabinPrefTemp,
                            "VendorPref": VendorPrefTemp,
                            "FarePref": [
                                {
                                    "RefundableFareOnly": isFlexibleFares
                                }
                            ],
                            "FlightTypePref": [
                                {
                                    "FlightType": directFlightsValue
                                }
                            ],
                            "DesiredResult": {
                                //"MaxCount": "200ITINS",
                                //"RTSFOnly": null
                            },
                            //User Story 2450: Display Cabin baggage implemented
                            "BaggageOnly": false,
                            "cabinBaggageOnly": isIncludeCabinBagOnlyFares,
                            "checkInBaggageOnly": isIncludeBagOnlyFares,
                            "SourceId": sessionStorage.getItem("flightContent") !== null && sessionStorage.getItem("flightContent") !== '' ? sessionStorage.getItem("flightContent") === '4' ? 'TJ' : '1W' : "1W",
                            "FlightContent": sessionStorage.getItem("flightContent") !== null && sessionStorage.getItem("flightContent") !== '' ? sessionStorage.getItem("flightContent") : '2'
                        }
                    ],
                    "TravelerInfoSummary": {
                        "Nationality": "IN",
                        "PassengerTypeQuantity": PassengerTypeQuantityTemp
                    },
                    "TraceId": JSON.parse(localStorage.getItem('traceID')),
                    "TimeStamp": null,
                    "Version": null,
                    "IPAddress": null,
                    "SequenceNmbr": null,
                    "meetingTime": document.querySelector('#meetingTime') !== undefined && document.querySelector('#meetingTime') !== null && document.querySelector('#meetingTime') !== '' ? document.querySelector('#meetingTime').value.replace(':', '') : '',
                    "policyApplyID": getPolicyID(),
                    "tripReason": localStorage.getItem('isCorporate') !== null && localStorage.getItem('isCorporate') === 'true' ? travelRequestFieldsDataJson.tripReason !== null && travelRequestFieldsDataJson.tripReason.length > 0 ? travelRequestFieldsDataJson.tripReason : '' : '',
                    "IsUpgrade": valueParam.isUpgrade !== undefined && valueParam.isUpgrade !== null && valueParam.isUpgrade !== 'null' && valueParam.isUpgrade === 'true' ? true : false
                }
            }
        );

        sessionStorage.setItem("meetingTime", document.querySelector('#meetingTime') !== undefined && document.querySelector('#meetingTime') !== null && document.querySelector('#meetingTime') !== '' ? document.querySelector('#meetingTime').value.replace(':', '') : '');
        localStorage.removeItem("FlightIntialTotalAmount");
        setOnSearchFlight(onSearchFlightInitilal);
        sendRequest(onSearchFlightInitilal);

    }, [sendRequest]);

    let loadedData = [];
    for (let i in loadedFlightSearch) {
        if (loadedFlightSearch.data.errors.status === 'FALSE') {
            loadedData = loadedFlightSearch.data.pricedItineraries;
            sessionStorage.setItem("upgradeCabinClass", loadedFlightSearch.data.upgradeCabinClass !== undefined && loadedFlightSearch.data.upgradeCabinClass !== null ? loadedFlightSearch.data.upgradeCabinClass : '');
        }
    }

    const errorHandler = () => {
        if (valueParam.travelreq !== undefined && valueParam.travelreq !== null && valueParam.travelreq === 'true') {
            setError(null);
            setPageFocus(false);
            document.querySelector('#explore_area').classList.add('d-none');
            history.push('/TravelRequest?return=1&service=flight');
        }
        else {
            setError(null);
            setPageFocus(false);
            document.querySelector('#explore_area').classList.add('d-none');

            window.scrollTo({
                top: 50,
                behavior: "smooth",
            });
        }
    };

    const tripTypeHandler = (event) => {
        setTripType(event.target.id.split('-')[0]);
        sessionStorage.setItem('tripType', event.target.id.split('-')[0]);
    }

    const showMultiCityRow = (event) => {
        if (isAddedThree === false) {
            setIsAddedThree(true);
            if (isAddedFour && isAddedFive) {
                document.querySelector('#showMultiCityRow').style.display = "none";
            }
        }
        else if (isAddedFour === false) {
            setIsAddedFour(true);
            if (isAddedThree && isAddedFive) {
                document.querySelector('#showMultiCityRow').style.display = "none";
            }
        }
        else if (isAddedFive === false) {
            setIsAddedFive(true);
            if (isAddedThree && isAddedFour) {
                document.querySelector('#showMultiCityRow').style.display = "none";
            }
        }

        if (document.querySelector('#removeMultiCityRowThree') !== null && document.querySelector('#removeMultiCityRowFour') !== null && document.querySelector('#removeMultiCityRowFive') !== null) {
            document.querySelector('#showMultiCityRow').style.display = "none";
        }
    }

    const hideMultiCityRow = (event) => {
        if (event.target.id === 'removeMultiCityRowThree') {
            setIsAddedThree(false);
            sessionStorage.removeItem('txtFrom3');
            sessionStorage.removeItem('spnFromAirportDetails3');
        }
        else if (event.target.id === 'removeMultiCityRowFour') {
            setIsAddedFour(false);
            sessionStorage.removeItem('txtFrom4');
            sessionStorage.removeItem('spnFromAirportDetails4');
        }
        else if (event.target.id === 'removeMultiCityRowFive') {
            setIsAddedFive(false);
            sessionStorage.removeItem('txtFrom5');
            sessionStorage.removeItem('spnFromAirportDetails5');
        }
        document.querySelector('#showMultiCityRow').style.display = "";
    }

    useEffect(() => {
        if (localStorage.getItem("airportSuggestion") === null || localStorage.getItem("airportSuggestion").length < 3) {
            let airportSuggestionResutTemp = [];
            fetch(GET_ALL_AIRPORT_LIST(), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('idToken'),
                },
            }).then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson['data'] !== null) {
                        responseJson['data'].forEach(element2 => {
                            airportSuggestionResutTemp.push(element2);
                        });
                    }
                    localStorage.setItem("airportSuggestion", JSON.stringify(airportSuggestionResutTemp));
                });
        }

        if (localStorage.getItem("blockedCountryCity") === null) {
            let blockedCountryCityListTemp = [];
            fetch(GET_BLOCK_COUNTRY_CITY_LIST(), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('idToken'),
                },
            }).then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson['data'] !== null) {
                        responseJson['data'].forEach(element2 => {
                            blockedCountryCityListTemp.push(element2);
                        });
                    }
                    localStorage.setItem("blockedCountryCity", JSON.stringify(blockedCountryCityListTemp));
                });
        }

        localStorage.removeItem("AddHotel");
        sessionStorage.removeItem("singlePnrCheckOutMinvalue");

        if (localStorage.getItem('isCorporate') !== null && localStorage.getItem('isCorporate') === 'true' && (valueParam.travelreq === undefined || valueParam.travelreq === null)) {
            history.push('/TravelRequest')
        }

    }, [])

    useEffect(() => {
        sessionStorage.setItem("myTripIsCanceled", '');
        sessionStorage.setItem("myTripIsHotelCancelled", '');

        if (sessionStorage.getItem("serviceType") !== null && sessionStorage.getItem("serviceType") !== '') {
            if (sessionStorage.getItem("tripType") !== null && sessionStorage.getItem("tripType") !== '') {
                if (sessionStorage.getItem("tripType") === 'oneway') {
                    sessionStorage.setItem("serviceType", "lblFlights");
                }
                else if (sessionStorage.getItem("AddHotelCheck") === null && sessionStorage.getItem("tripType") === 'roundtrip') {
                    sessionStorage.setItem("serviceType", "lblFlights");
                }
                else if (sessionStorage.getItem("AddHotelCheck") !== null && sessionStorage.getItem("tripType") === 'roundtrip') {
                    sessionStorage.setItem("serviceType", "lblFlightPlusHotel");
                }
                else if (sessionStorage.getItem("tripType") === 'multi_city') {
                    sessionStorage.setItem("serviceType", "lblFlights");
                }
            }
        }

        window.scrollTo({
            top: 50,
            behavior: "smooth",
        });

        if (valueParam.Flights === "1") {
            document.querySelector("#lblFlights").classList.remove("text-primary");
            document.querySelector("#lblFlights").classList.add("flight-active-tab");
            document.querySelector('#lblFlights').click();

            if (document.querySelector("#lblFlightPlusHotel") !== null) {
                document.querySelector("#lblFlightPlusHotel").classList.remove("flight-active-tab");
                document.querySelector("#lblFlightPlusHotel").classList.add("text-primary");
            }

            document.querySelector('#divTripType').classList.remove("d-none");

            if (sessionStorage.getItem("tripType") !== null) {
                if (sessionStorage.getItem("tripType") === 'oneway') {
                    document.querySelector('#oneway-tab').click();
                }
                else if (sessionStorage.getItem("tripType") === 'roundtrip') {
                    document.querySelector('#roundtrip-tab').click();
                }
                else if (sessionStorage.getItem("tripType") === 'multi_city') {
                    document.querySelector('#multi_city-tab').click();
                }
                else {
                    document.querySelector('#roundtrip-tab').click();
                }
            }
        }
        else if (valueParam.FlightPlusHotel === "1") {
            if (document.querySelector("#lblFlights") !== null) {
                document.querySelector("#lblFlights").classList.remove("flight-active-tab");
                document.querySelector("#lblFlights").classList.add("text-primary");
            }

            document.querySelector("#lblFlightPlusHotel").classList.remove("text-primary");
            document.querySelector("#lblFlightPlusHotel").classList.add("flight-active-tab");
            document.querySelector('#lblFlightPlusHotel').click();

            document.querySelector('#divTripType').classList.add("d-none");

            if (document.querySelector('#addHotel') !== null) {
                document.querySelector('#addHotel').checked = true;
            }
            localStorage.setItem("AddHotel", "1");
            sessionStorage.setItem("AddHotelCheck", 1);
        }
        else {
            if (document.querySelector("#lblFlights") !== null) {
                if (document.querySelector("#lblFlightPlusHotel") !== null) {
                    document.querySelector("#lblFlightPlusHotel").classList.remove("flight-active-tab");
                    document.querySelector("#lblFlightPlusHotel").classList.add("text-primary");
                }

                document.querySelector("#lblFlights").classList.remove("text-primary");
                document.querySelector("#lblFlights").classList.add("flight-active-tab");

                document.querySelector('#divTripType').classList.remove("d-none");

                if (document.querySelector('#addHotel') !== null) {
                    document.querySelector('#addHotel').checked = false;
                }
                localStorage.removeItem("AddHotel");
                sessionStorage.removeItem("AddHotelCheck");
            }
            else if (document.querySelector("#lblFlightPlusHotel") !== null) {
                if (document.querySelector("#lblFlights") !== null) {
                    document.querySelector("#lblFlights").classList.remove("flight-active-tab");
                    document.querySelector("#lblFlights").classList.add("text-primary");
                }

                document.querySelector("#lblFlightPlusHotel").classList.remove("text-primary");
                document.querySelector("#lblFlightPlusHotel").classList.add("flight-active-tab");

                document.querySelector('#divTripType').classList.add("d-none");

                if (document.querySelector('#addHotel') !== null) {
                    document.querySelector('#addHotel').checked = true;
                }
                localStorage.setItem("AddHotel", "1");
                sessionStorage.setItem("AddHotelCheck", 1);
            }
            else {
                if (document.querySelector("#lblFlights") !== null) {
                    document.querySelector("#lblFlights").classList.remove("text-primary");
                    document.querySelector("#lblFlights").classList.add("flight-active-tab");
                }

                document.querySelector('#divTripType').classList.remove("d-none");

                if (document.querySelector('#addHotel') !== null) {
                    document.querySelector('#addHotel').checked = false;
                }
                localStorage.removeItem("AddHotel");
                sessionStorage.removeItem("AddHotelCheck");
            }
        }

        if (document.querySelector("#lblHotel") !== null) {
            document.querySelector("#lblHotel").classList.remove("flight-active-tab");
            document.querySelector("#lblHotel").classList.add("text-primary");
        }

        if (document.querySelector("#lblSightseeing") !== null) {
            document.querySelector("#lblSightseeing").classList.remove("flight-active-tab");
            document.querySelector("#lblSightseeing").classList.add("text-primary");
        }

        if (document.querySelector("#lblHolidays") !== null) {
            document.querySelector("#lblHolidays").classList.remove("flight-active-tab");
            document.querySelector("#lblHolidays").classList.add("text-primary");
        }
        if (document.querySelector("#lblVisa") !== null) {
            document.querySelector("#lblVisa").classList.add("text-primary");
            document.querySelector("#lblVisa").classList.remove("flight-active-tab");
        }

        //put another condition to avoid rehit for search on page reload for CBT
        if (valueParam.flight === "partial" && sessionStorage.getItem("isFlightPartialBlockExecuted") === null) {
            sessionStorage.setItem("isFlightPartialBlockExecuted", 1);
            setIsSubmitClicked(true);
            let tripTypeTemp = "";
            let OriginDestinationInformationTemp = [];
            let CabinPrefTemp = [];
            let PassengerTypeQuantityTemp = [];
            let timeSlotFromWindowBefore = "";
            let timeSlotFromWindowAfter = "";
            let timeSlotToWindowBefore = "";
            let timeSlotToWindowAfter = "";
            let isNearByAirportSearch = false;
            let isIncludeBagOnlyFares = false;
            let VendorPrefTemp = [];
            let isFlexibleFares = false;
            let directFlightsValue = 5;
            let isShowMixedCabins = true;
            let isIncludeCabinBagOnlyFares = false;

            if (sessionStorage.getItem('preferenceOptions') !== null) {
                JSON.parse(sessionStorage.getItem('preferenceOptions')).forEach(element => {
                    if (element.preferenceOptionId === 'nearByAirportSearch') {
                        isNearByAirportSearch = true;
                    }
                    if (element.preferenceOptionId === 'includeBagOnlyFares') {
                        isIncludeBagOnlyFares = true;
                    }
                    if (element.preferenceOptionId === 'flexibleFares') {
                        isFlexibleFares = true;
                    }
                    if (element.preferenceOptionId === 'directFlights') {
                        directFlightsValue = 3;
                    }
                    if (element.preferenceOptionId === 'showMixedCabins') {
                        isShowMixedCabins = false;
                    }
                    if (element.preferenceOptionId === 'includeCabinBagOnlyFares') {
                        isIncludeCabinBagOnlyFares = true;
                    }
                });
            }

            if (sessionStorage.getItem('tripType') === "oneway") {
                tripTypeTemp = "Oneway";

                if (sessionStorage.getItem("timeSlotFrom1").split('-').length > 1) {
                    timeSlotFromWindowBefore = "-" + sessionStorage.getItem("timeSlotFrom1").split('-')[1];
                    timeSlotFromWindowAfter = "-" + sessionStorage.getItem("timeSlotFrom1").split('-')[0];
                }

                OriginDestinationInformationTemp.push({
                    "DepartureDateTime": {
                        "WindowBefore": sessionStorage.getItem("calFromDateDB1") + timeSlotFromWindowBefore,
                        "WindowAfter": sessionStorage.getItem("calFromDateDB1") + timeSlotFromWindowAfter,
                        "CrossDateAllowedIndicator": "",
                        "Text": ""
                    },
                    "OriginLocation": {
                        "AirportOnly": "",
                        "AlternateLocationInd": "",
                        "LocationCode": sessionStorage.getItem("spnFromAirportDetails1").split('-')[0].trim().toUpperCase(),
                        "CityCode": sessionStorage.getItem("fromCityCode1").trim().toUpperCase(),
                        "Location": `${sessionStorage.getItem("txtFrom1").trim()}, ${sessionStorage.getItem("spnFromAirportDetails1").split('-')[1].split(',')[0].trim()}(${sessionStorage.getItem("spnFromAirportDetails1").split('-')[0].trim()})`,
                        "Radius": isNearByAirportSearch === true ? "99" : ""
                    },
                    "DestinationLocation": {
                        "LocationCode": sessionStorage.getItem("spnToAirportDetails1").split('-')[0].trim().toUpperCase(),
                        "CityCode": sessionStorage.getItem("toCityCode1").trim().toUpperCase(),
                        "Location": `${sessionStorage.getItem("txtTo1").trim()}, ${sessionStorage.getItem("spnToAirportDetails1").split('-')[1].split(',')[0].trim()}(${sessionStorage.getItem("spnToAirportDetails1").split('-')[0].trim()})`,
                        "AirportOnly": "",
                        "AlternateLocationInd": "",
                        "Radius": isNearByAirportSearch === true ? "99" : ""
                    },
                    "ConnectionLocations": null,
                    "AlternateLocationInfo": null,
                    "FareCalenderInfo": null,
                    "RPH": null,
                    "RefNumber": null,
                    "TPA_Extensions": null
                });

                CabinPrefTemp.push({
                    "Cabin": valueParam.isUpgrade !== undefined && valueParam.isUpgrade !== null && valueParam.isUpgrade !== 'null' && valueParam.isUpgrade === 'true' && sessionStorage.getItem("upgradeCabinClass") !== null ? sessionStorage.getItem("upgradeCabinClass") : sessionStorage.getItem('spnClass1') === 'Premium Eco' ? 'Premium Economy' : sessionStorage.getItem('spnClass1'),
                    "KeepSameCabin": isShowMixedCabins
                });

                for (let i = 1; i <= 3; i++) {
                    if (sessionStorage.getItem("preferredAirline" + i) !== null && sessionStorage.getItem("preferredAirline" + i) !== '') {
                        VendorPrefTemp.push({
                            "Code": sessionStorage.getItem("preferredAirline" + i).split('-')[1],
                            "PreferLevel": "Preferred"
                        });
                    }
                }

                let PassengerTypeAdt = sessionStorage.getItem('spnPassengerAdt1')
                let PassengerTypeChd = sessionStorage.getItem('spnPassengerChd1')
                let PassengerTypeLapInf = sessionStorage.getItem('spnPassengerLapInf1')
                let PassengerTypeSeatInf = sessionStorage.getItem('spnPassengerSeatInf1')
                let passengers = [];

                if (PassengerTypeAdt > 0) {
                    passengers.push({
                        "Code": sessionStorage.getItem('fareType') === "seniorCitizenFares" ? "SNN" : "ADT",
                        "Quantity": PassengerTypeAdt,
                        "Age": ""
                    });
                }
                if (PassengerTypeChd > 0) {
                    passengers.push({
                        "Code": "CHD",
                        "Quantity": PassengerTypeChd,
                        "Age": ""
                    });
                }
                if (PassengerTypeLapInf > 0) {
                    passengers.push({
                        "Code": "INF",
                        "Quantity": PassengerTypeLapInf,
                        "Age": ""
                    });
                }
                if (PassengerTypeSeatInf > 0) {
                    passengers.push({
                        "Code": "INS",
                        "Quantity": PassengerTypeSeatInf,
                        "Age": ""
                    });
                }

                PassengerTypeQuantityTemp = passengers;
            }
            else if (sessionStorage.getItem('tripType') === null || sessionStorage.getItem('tripType') === "roundtrip") {
                tripTypeTemp = "RoundTrip";

                if (sessionStorage.getItem("timeSlotFrom1").split('-').length > 1) {
                    timeSlotFromWindowBefore = "-" + sessionStorage.getItem("timeSlotFrom1").split('-')[1];
                    timeSlotFromWindowAfter = "-" + sessionStorage.getItem("timeSlotFrom1").split('-')[0];
                }

                if (sessionStorage.getItem("timeSlotTo1").split('-').length > 1) {
                    timeSlotToWindowBefore = "-" + sessionStorage.getItem("timeSlotTo1").split('-')[1];
                    timeSlotToWindowAfter = "-" + sessionStorage.getItem("timeSlotTo1").split('-')[0];
                }

                OriginDestinationInformationTemp.push({
                    "DepartureDateTime": {
                        "WindowBefore": sessionStorage.getItem("calFromDateDB1") + timeSlotFromWindowBefore,
                        "WindowAfter": sessionStorage.getItem("calFromDateDB1") + timeSlotFromWindowAfter,
                        "CrossDateAllowedIndicator": "",
                        "Text": ""
                    },
                    "OriginLocation": {
                        "AirportOnly": "",
                        "AlternateLocationInd": "",
                        "LocationCode": sessionStorage.getItem("spnFromAirportDetails1").split('-')[0].trim().toUpperCase(),
                        "CityCode": sessionStorage.getItem("fromCityCode1").trim().toUpperCase(),
                        "Location": `${sessionStorage.getItem("txtFrom1").trim()}, ${sessionStorage.getItem("spnFromAirportDetails1").split('-')[1].split(',')[0].trim()}(${sessionStorage.getItem("spnFromAirportDetails1").split('-')[0].trim()})`,
                        "Radius": isNearByAirportSearch === true ? "99" : ""
                    },
                    "DestinationLocation": {
                        "LocationCode": sessionStorage.getItem("spnToAirportDetails1").split('-')[0].trim().toUpperCase(),
                        "CityCode": sessionStorage.getItem("toCityCode1").trim().toUpperCase(),
                        "Location": `${sessionStorage.getItem("txtTo1").trim()}, ${sessionStorage.getItem("spnToAirportDetails1").split('-')[1].split(',')[0].trim()}(${sessionStorage.getItem("spnToAirportDetails1").split('-')[0].trim()})`,
                        "AirportOnly": "",
                        "AlternateLocationInd": "",
                        "Radius": isNearByAirportSearch === true ? "99" : ""
                    },
                    "ConnectionLocations": null,
                    "AlternateLocationInfo": null,
                    "FareCalenderInfo": null,
                    "RPH": null,
                    "RefNumber": null,
                    "TPA_Extensions": null
                },
                    {
                        "DepartureDateTime": {
                            "WindowBefore": sessionStorage.getItem("calToDateDB1") + timeSlotToWindowBefore,
                            "WindowAfter": sessionStorage.getItem("calToDateDB1") + timeSlotToWindowAfter,
                            "CrossDateAllowedIndicator": "",
                            "Text": ""
                        },
                        "OriginLocation": {
                            "AirportOnly": "",
                            "AlternateLocationInd": "",
                            "LocationCode": sessionStorage.getItem("spnToAirportDetails1").split('-')[0].trim().toUpperCase(),
                            "CityCode": sessionStorage.getItem("toCityCode1").trim().toUpperCase(),
                            "Location": `${sessionStorage.getItem("txtTo1").trim()}, ${sessionStorage.getItem("spnToAirportDetails1").split('-')[1].split(',')[0].trim()}(${sessionStorage.getItem("spnToAirportDetails1").split('-')[0].trim()})`,
                            "Radius": isNearByAirportSearch === true ? "99" : ""
                        },
                        "DestinationLocation": {
                            "LocationCode": sessionStorage.getItem("spnFromAirportDetails1").split('-')[0].trim().toUpperCase(),
                            "CityCode": sessionStorage.getItem("fromCityCode1").trim().toUpperCase(),
                            "Location": `${sessionStorage.getItem("txtFrom1").trim()}, ${sessionStorage.getItem("spnFromAirportDetails1").split('-')[1].split(',')[0].trim()}(${sessionStorage.getItem("spnFromAirportDetails1").split('-')[0].trim()})`,
                            "AirportOnly": "",
                            "AlternateLocationInd": "",
                            "Radius": isNearByAirportSearch === true ? "99" : ""
                        },
                        "ConnectionLocations": null,
                        "AlternateLocationInfo": null,
                        "FareCalenderInfo": null,
                        "RPH": null,
                        "RefNumber": null,
                        "TPA_Extensions": null
                    });

                CabinPrefTemp.push({
                    "Cabin": valueParam.isUpgrade !== undefined && valueParam.isUpgrade !== null && valueParam.isUpgrade !== 'null' && valueParam.isUpgrade === 'true' && sessionStorage.getItem("upgradeCabinClass") !== null ? sessionStorage.getItem("upgradeCabinClass") : sessionStorage.getItem('spnClass1') === 'Premium Eco' ? 'Premium Economy' : sessionStorage.getItem('spnClass1'),
                    "KeepSameCabin": isShowMixedCabins
                });

                for (let i = 1; i <= 3; i++) {
                    if (sessionStorage.getItem("preferredAirline" + i) !== null && sessionStorage.getItem("preferredAirline" + i) !== '') {
                        VendorPrefTemp.push({
                            "Code": sessionStorage.getItem("preferredAirline" + i).split('-')[1],
                            "PreferLevel": "Preferred"
                        });
                    }
                }

                let PassengerTypeAdt = sessionStorage.getItem('spnPassengerAdt1')
                let PassengerTypeChd = sessionStorage.getItem('spnPassengerChd1')
                let PassengerTypeLapInf = sessionStorage.getItem('spnPassengerLapInf1')
                let PassengerTypeSeatInf = sessionStorage.getItem('spnPassengerSeatInf1')
                let passengers = [];

                if (PassengerTypeAdt > 0) {
                    passengers.push({
                        "Code": sessionStorage.getItem('fareType') === "seniorCitizenFares" ? "SNN" : "ADT",
                        "Quantity": PassengerTypeAdt,
                        "Age": ""
                    });
                }
                if (PassengerTypeChd > 0) {
                    passengers.push({
                        "Code": "CHD",
                        "Quantity": PassengerTypeChd,
                        "Age": ""
                    });
                }
                if (PassengerTypeLapInf > 0) {
                    passengers.push({
                        "Code": "INF",
                        "Quantity": PassengerTypeLapInf,
                        "Age": ""
                    });
                }
                if (PassengerTypeSeatInf > 0) {
                    passengers.push({
                        "Code": "INS",
                        "Quantity": PassengerTypeSeatInf,
                        "Age": ""
                    });
                }

                PassengerTypeQuantityTemp = passengers;
            }
            else if (sessionStorage.getItem('tripType') === "multi_city") {
                tripTypeTemp = "MultiCity";
                let PassengerTypeAdt = 0;
                let PassengerTypeChd = 0;
                let PassengerTypeLapInf = 0;
                let PassengerTypeSeatInf = 0;

                for (let i = 1; i <= 5; i++) {
                    if (sessionStorage.getItem('txtFrom' + i) !== null) {
                        if (sessionStorage.getItem("timeSlotFrom" + i).split('-').length > 1) {
                            timeSlotFromWindowBefore = "-" + sessionStorage.getItem("timeSlotFrom" + i).split('-')[1];
                            timeSlotFromWindowAfter = "-" + sessionStorage.getItem("timeSlotFrom" + i).split('-')[0];
                        }

                        OriginDestinationInformationTemp.push({
                            "DepartureDateTime": {
                                "WindowBefore": sessionStorage.getItem("calFromDateDB" + i) + timeSlotFromWindowBefore,
                                "WindowAfter": sessionStorage.getItem("calFromDateDB" + i) + timeSlotFromWindowAfter,
                                "CrossDateAllowedIndicator": "",
                                "Text": ""
                            },
                            "OriginLocation": {
                                "AirportOnly": "",
                                "AlternateLocationInd": "",
                                "LocationCode": sessionStorage.getItem("spnFromAirportDetails" + i).split('-')[0].trim().toUpperCase(),
                                "CityCode": sessionStorage.getItem("fromCityCode" + i).trim().toUpperCase(),
                                "Location": `${sessionStorage.getItem("txtFrom" + i).trim()}, ${sessionStorage.getItem("spnFromAirportDetails" + i).split('-')[1].split(',')[0].trim()}(${sessionStorage.getItem("spnFromAirportDetails" + i).split('-')[0].trim()})`,
                                "Radius": isNearByAirportSearch === true ? "99" : ""
                            },
                            "DestinationLocation": {
                                "LocationCode": sessionStorage.getItem("spnToAirportDetails" + i).split('-')[0].trim().toUpperCase(),
                                "CityCode": sessionStorage.getItem("toCityCode" + i).trim().toUpperCase(),
                                "Location": `${sessionStorage.getItem("txtTo" + i).trim()}, ${sessionStorage.getItem("spnToAirportDetails" + i).split('-')[1].split(',')[0].trim()}(${sessionStorage.getItem("spnToAirportDetails" + i).split('-')[0].trim()})`,
                                "AirportOnly": "",
                                "AlternateLocationInd": "",
                                "Radius": isNearByAirportSearch === true ? "99" : ""
                            },
                            "ConnectionLocations": null,
                            "AlternateLocationInfo": null,
                            "FareCalenderInfo": null,
                            "RPH": null,
                            "RefNumber": null,
                            "TPA_Extensions": null
                        });

                        const found = CabinPrefTemp.find(el => el.Cabin === sessionStorage.getItem('spnClass' + i));
                        if (!found) {
                            CabinPrefTemp.push({
                                "Cabin": valueParam.isUpgrade !== undefined && valueParam.isUpgrade !== null && valueParam.isUpgrade !== 'null' && valueParam.isUpgrade === 'true' && sessionStorage.getItem("upgradeCabinClass") !== null ? sessionStorage.getItem("upgradeCabinClass") : sessionStorage.getItem('spnClass1') === 'Premium Eco' ? 'Premium Economy' : sessionStorage.getItem('spnClass1'),
                                "KeepSameCabin": isShowMixedCabins
                            });
                        }

                        PassengerTypeAdt = sessionStorage.getItem('spnPassengerAdt1')
                        PassengerTypeChd = sessionStorage.getItem('spnPassengerChd1')
                        PassengerTypeLapInf = sessionStorage.getItem('spnPassengerLapInf1')
                        PassengerTypeSeatInf = sessionStorage.getItem('spnPassengerSeatInf1')
                    }
                }

                for (let i = 1; i <= 3; i++) {
                    if (sessionStorage.getItem("preferredAirline" + i) !== null && sessionStorage.getItem("preferredAirline" + i) !== '') {
                        VendorPrefTemp.push({
                            "Code": sessionStorage.getItem("preferredAirline" + i).split('-')[1],
                            "PreferLevel": "Preferred"
                        });
                    }
                }

                let passengers = [];

                if (PassengerTypeAdt > 0) {
                    passengers.push({
                        "Code": sessionStorage.getItem('fareType') === "seniorCitizenFares" ? "SNN" : "ADT",
                        "Quantity": PassengerTypeAdt.toString(),
                        "Age": ""
                    });
                }
                if (PassengerTypeChd > 0) {
                    passengers.push({
                        "Code": "CHD",
                        "Quantity": PassengerTypeChd.toString(),
                        "Age": ""
                    });
                }
                if (PassengerTypeLapInf > 0) {
                    passengers.push({
                        "Code": "INF",
                        "Quantity": PassengerTypeLapInf.toString(),
                        "Age": ""
                    });
                }
                if (PassengerTypeSeatInf > 0) {
                    passengers.push({
                        "Code": "INS",
                        "Quantity": PassengerTypeSeatInf.toString(),
                        "Age": ""
                    });
                }

                PassengerTypeQuantityTemp = passengers;
            }

            let travelRequestFieldsDataJson = "";
            if (sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
                travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
            }

            const onSearchFlightInitilal = (
                {
                    "officeID": JSON.parse(localStorage.getItem('officeID')),
                    "Data": {
                        "ProcessingInfo": {
                            "SectorInd": "I",
                            "SearchType": tripTypeTemp,
                            "AvailabilityIndicator": null,
                            "IsSpecialFare": "FALSE"
                        },
                        "POS": null,
                        "OriginDestinationInformation": OriginDestinationInformationTemp,
                        "TravelPreferences": [
                            {
                                "CabinPref": CabinPrefTemp,
                                "VendorPref": VendorPrefTemp,
                                "FarePref": [
                                    {
                                        "RefundableFareOnly": isFlexibleFares
                                    }
                                ],
                                "FlightTypePref": [
                                    {
                                        "FlightType": directFlightsValue
                                    }
                                ],
                                "DesiredResult": {
                                    //"MaxCount": "200ITINS",
                                    //"RTSFOnly": null
                                },
                                //User Story 2450: Display Cabin baggage implemented
                                "BaggageOnly": false,
                                "cabinBaggageOnly": isIncludeCabinBagOnlyFares,
                                "checkInBaggageOnly": isIncludeBagOnlyFares,
                                "SourceId": sessionStorage.getItem("flightContent") !== null && sessionStorage.getItem("flightContent") !== '' ? sessionStorage.getItem("flightContent") === '4' ? 'TJ' : '1W' : "1W",
                                "FlightContent": sessionStorage.getItem("flightContent") !== null && sessionStorage.getItem("flightContent") !== '' ? sessionStorage.getItem("flightContent") : '2'
                            }
                        ],
                        "TravelerInfoSummary": {
                            "Nationality": "IN",
                            "PassengerTypeQuantity": PassengerTypeQuantityTemp
                        },
                        "TraceId": JSON.parse(localStorage.getItem('traceID')),
                        "TimeStamp": null,
                        "Version": null,
                        "IPAddress": null,
                        "SequenceNmbr": null,
                        "meetingTime": sessionStorage.getItem("meetingTime") !== null ? sessionStorage.getItem("meetingTime").replace(':', '') : '',
                        "policyApplyID": getPolicyID(),
                        "tripReason": localStorage.getItem('isCorporate') !== null && localStorage.getItem('isCorporate') === 'true' ? travelRequestFieldsDataJson.tripReason !== null && travelRequestFieldsDataJson.tripReason.length > 0 ? travelRequestFieldsDataJson.tripReason : '' : '',
                        "IsUpgrade": valueParam.isUpgrade !== undefined && valueParam.isUpgrade !== null && valueParam.isUpgrade !== 'null' && valueParam.isUpgrade === 'true' ? true : false
                    }
                }
            );

            localStorage.removeItem("FlightIntialTotalAmount");
            setOnSearchFlight(onSearchFlightInitilal);
            sendRequest(onSearchFlightInitilal);
        }

    }, [valueParam.isUpgrade])

    useEffect(() => {

        if (sessionStorage.getItem('txtFrom3') !== null) {
            setIsAddedThree(true)
        }
        if (sessionStorage.getItem('txtFrom4') !== null) {
            setIsAddedFour(true)
        }
        if (sessionStorage.getItem('txtFrom5') !== null) {
            setIsAddedFive(true)
        }

        if (tripType === "") {
            if (sessionStorage.getItem("tripType") !== null && sessionStorage.getItem("tripType") !== '') {
                setTripType(sessionStorage.getItem("tripType"));

                if (sessionStorage.getItem("serviceType") !== null && sessionStorage.getItem("serviceType") !== '') {
                    if (sessionStorage.getItem("serviceType") === 'lblFlights') {
                        if (document.querySelector("#lblFlights") !== null) {
                            document.querySelector("#lblFlights").classList.add("text-primary");
                            document.querySelector("#lblFlights").classList.remove("flight-active-tab");
                            document.querySelector('#lblFlights').click();

                            if (document.querySelector('#roundtrip-tab').classList.contains("active")) {
                                document.querySelector('#roundtrip').classList.add("active");
                                document.querySelector('#roundtrip').classList.add("show");
                                document.querySelector('#roundtrip-tab').click();
                            }
                            else {
                                if (sessionStorage.getItem("tripType") === 'oneway') {
                                    document.querySelector('#oneway_flight').classList.add("active");
                                    document.querySelector('#oneway_flight').classList.add("show");
                                    document.querySelector('#oneway-tab').click();
                                }
                                else if (sessionStorage.getItem("tripType") === 'roundtrip') {
                                    document.querySelector('#roundtrip').classList.add("active");
                                    document.querySelector('#roundtrip').classList.add("show");
                                    document.querySelector('#roundtrip-tab').click();
                                }
                                else if (sessionStorage.getItem("tripType") === 'multi_city') {
                                    document.querySelector('#multi_city').classList.add("active");
                                    document.querySelector('#multi_city').classList.add("show");
                                    document.querySelector('#multi_city-tab').click();
                                }
                                else {
                                    document.querySelector('#roundtrip').classList.add("active");
                                    document.querySelector('#roundtrip').classList.add("show");
                                    document.querySelector('#roundtrip-tab').click();
                                }
                            }
                        }
                    }
                    else if (sessionStorage.getItem("serviceType") === 'lblFlightPlusHotel') {
                        if (document.querySelector("#lblFlightPlusHotel") !== null) {
                            document.querySelector("#lblFlightPlusHotel").classList.add("text-primary");
                            document.querySelector("#lblFlightPlusHotel").classList.remove("flight-active-tab");
                            document.querySelector('#lblFlightPlusHotel').click();

                            document.querySelector('#roundtrip').classList.add("active");
                            document.querySelector('#roundtrip').classList.add("show");
                            document.querySelector('#roundtrip-tab').click();
                        }
                    }
                }
                else {
                    // if (document.querySelector("#lblFlightPlusHotel") !== null) {
                    //     document.querySelector("#lblFlightPlusHotel").classList.add("text-primary");
                    //     document.querySelector("#lblFlightPlusHotel").classList.remove("flight-active-tab");
                    //     document.querySelector('#lblFlightPlusHotel').click();

                    //     document.querySelector('#roundtrip').classList.add("active");
                    //     document.querySelector('#roundtrip').classList.add("show");
                    //     document.querySelector('#roundtrip-tab').click();
                    // }

                    if (document.querySelector("#lblFlights") !== null) {
                        if (document.querySelector("#divAddHotel") !== null) {
                            document.querySelector("#divAddHotel").style.display = "none";
                        }
                        document.querySelector("#lblFlights").classList.add("text-primary");
                        document.querySelector("#lblFlights").classList.remove("flight-active-tab");
                        document.querySelector('#lblFlights').click();

                        if (document.querySelector('#roundtrip-tab').classList.contains("active")) {
                            document.querySelector('#roundtrip').classList.add("active");
                            document.querySelector('#roundtrip').classList.add("show");
                            document.querySelector('#roundtrip-tab').click();
                        }
                        else {
                            if (sessionStorage.getItem("tripType") === 'oneway') {
                                document.querySelector('#oneway_flight').classList.add("active");
                                document.querySelector('#oneway_flight').classList.add("show");
                                document.querySelector('#oneway-tab').click();
                            }
                            else if (sessionStorage.getItem("tripType") === 'roundtrip') {
                                document.querySelector('#roundtrip').classList.add("active");
                                document.querySelector('#roundtrip').classList.add("show");
                                document.querySelector('#roundtrip-tab').click();
                            }
                            else if (sessionStorage.getItem("tripType") === 'multi_city') {
                                document.querySelector('#multi_city').classList.add("active");
                                document.querySelector('#multi_city').classList.add("show");
                                document.querySelector('#multi_city-tab').click();
                            }
                            else {
                                document.querySelector('#roundtrip').classList.add("active");
                                document.querySelector('#roundtrip').classList.add("show");
                                document.querySelector('#roundtrip-tab').click();
                            }
                        }
                    }
                }
            }
            else {
                if (document.querySelector("#divAddHotel") !== null) {
                    document.querySelector("#divAddHotel").style.display = "none";
                }
                setTripType("roundtrip");
                sessionStorage.setItem('tripType', "roundtrip");
                document.querySelector('#roundtrip').classList.add("active");
                document.querySelector('#roundtrip').classList.add("show");
                document.querySelector('#roundtrip-tab').click();

                // setTripType("oneway");
                // sessionStorage.setItem('tripType', "oneway");
                // document.querySelector('#oneway_flight').classList.add("active");
                // document.querySelector('#oneway_flight').classList.add("show");
                // document.querySelector('#oneway-tab').click();
            }
        }

        if (status === 'completed') {
            if (loadedFlightSearch !== null && loadedFlightSearch.data !== null && loadedFlightSearch.data.errors.status === 'TRUE') {
                setError({
                    title: "Message",
                    message: `${t('ErrorMessage')}`,
                });
            }
        }

    }, [status, loadedFlightSearch])

    const controlHandlerFightSearch = (event) => {
        sessionStorage.setItem("serviceType", event.target.id);
        if (document.querySelector('#advance') !== null && document.querySelector('#advance').checked === true && sessionStorage.getItem("showAdance") === "true") {
            document.querySelector('#advance').click();
        }

        if (event.target.id === 'lblFlights') {
            if (document.querySelector("#divAddHotel") !== null) {
                document.querySelector("#divAddHotel").style.display = "none";
            }

            document.querySelector("#lblFlights").classList.add("flight-active-tab");
            document.querySelector("#lblFlights").classList.remove("text-primary");
            document.querySelector('#divTripType').classList.remove("d-none");

            if (document.querySelector("#lblHotel") !== null) {
                document.querySelector("#lblHotel").classList.add("text-primary");
                document.querySelector("#lblHotel").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblFlightPlusHotel") !== null) {
                document.querySelector("#lblFlightPlusHotel").classList.add("text-primary");
                document.querySelector("#lblFlightPlusHotel").classList.remove("flight-active-tab");
            }

            document.querySelector('#oneway-tab').classList.remove("disabled");
            document.querySelector('#multi_city-tab').classList.remove("disabled");

            if (document.querySelector('#addHotel') !== null) {
                document.querySelector('#addHotel').checked = false;
            }
            localStorage.removeItem("AddHotel");
            sessionStorage.removeItem("AddHotelCheck");

            if (sessionStorage.getItem("tripType") !== null) {
                if (sessionStorage.getItem("tripType") === 'oneway') {
                    document.querySelector('#oneway-tab').click();
                }
                else if (sessionStorage.getItem("tripType") === 'roundtrip') {
                    document.querySelector('#roundtrip-tab').click();
                }
                else if (sessionStorage.getItem("tripType") === 'multi_city') {
                    document.querySelector('#multi_city-tab').click();
                }
                else {
                    document.querySelector('#roundtrip-tab').click();
                }
            }
        }
        else if (event.target.id === 'lblFlightPlusHotel') {
            if (document.querySelector("#divAddHotel") !== null) {
                document.querySelector("#divAddHotel").style.display = "";
            }

            if (document.querySelector("#lblFlights") !== null) {
                document.querySelector("#lblFlights").classList.add("text-primary");
                document.querySelector("#lblFlights").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblHotel") !== null) {
                document.querySelector("#lblHotel").classList.add("text-primary");
                document.querySelector("#lblHotel").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblFlightPlusHotel") !== null) {
                document.querySelector("#lblFlightPlusHotel").classList.remove("text-primary");
                document.querySelector("#lblFlightPlusHotel").classList.add("flight-active-tab");
            }

            document.querySelector('#divTripType').classList.add("d-none");
            document.querySelector('#roundtrip-tab').click();

            if (document.querySelector('#addHotel') !== null) {
                document.querySelector('#addHotel').checked = true;
            }
            localStorage.setItem("AddHotel", "1");
            sessionStorage.setItem("AddHotelCheck", 1);
        }
        else if (event.target.id === 'lblHotel') {
            history.push('/SearchHotel');
        }
        else if (event.target.id === 'lblSightseeing') {
            history.push('/SearchSightseeing');
        }
        else if (event.target.id === 'lblHolidays') {
            //history.push('/SearchHolidays');
            window.open(getConfigurationByValue('HOLIDAYS_URL'))
        }
        else if (event.target.id === 'lblVisa') {
            history.push('/SearchVisa')
        }

        // handle content dropdown for NDC single pnr on Flights, Flights and Hotels link click        
        if (sessionStorage.getItem("serviceType") === 'lblFlightPlusHotel') {
            sessionStorage.setItem("flightContent", "2");
            if (document.querySelector('.contentArea') !== null) {
                document.querySelector('.contentArea').classList.add('d-none');
            }
        } else {
            sessionStorage.setItem("flightContent", sessionStorage.getItem("oldFlightContent") !== null ? sessionStorage.getItem("oldFlightContent") : '');
            if (document.querySelector('.contentArea') !== null) {
                document.querySelector('.contentArea').classList.remove('d-none');
            }
        }
    }

    // handle content dropdown for NDC single pnr on component/page load of search flight
    useEffect(() => {
        setContentAreaUpdated(true);
        if (sessionStorage.getItem("serviceType") === 'lblFlightPlusHotel' && document.querySelector('.contentArea') !== null) {
            document.querySelector('.contentArea').classList.add('d-none');
            sessionStorage.setItem("flightContent", "2");
        }

    }, [contentAreaUpdated])

    useEffect(() => {
        setTimeout(() => {
            if (document.querySelector('#showMultiCityRow') !== null) {
                if (isAddedThree && isAddedFour && isAddedFive) {
                    document.querySelector('#showMultiCityRow').style.display = "none";
                }
                if (document.querySelector('#removeMultiCityRowThree') !== null && document.querySelector('#removeMultiCityRowFour') !== null && document.querySelector('#removeMultiCityRowFive') !== null) {
                    document.querySelector('#showMultiCityRow').style.display = "none";
                }
            }
        }, 1000);

        if (secureLocalStorage.getItem("searchRoeResult") === null) {
            const onSearchRoe = ({
                fromCurrency: "",
                toCurrency: ""
            });

            sendRequestRoe(onSearchRoe);
        }

    }, [])

    for (let index in loadedRoe) {
        if (loadedRoe['errors'].status === 'FALSE') {
            if (index === 'data') {
                secureLocalStorage.setItem('searchRoeResult', JSON.stringify(loadedRoe[index]));
            }
        }
    }

    const ErrorShow = (isSubmitClicked && status !== 'pending' && error1) ?
        <ErrorModal
            title={error1.title}
            message={t(error1.message)}
            onConfirm={errorHandler}
        ></ErrorModal> : ''

    const searchFlightList = !isFirtTime && valueParam.flight !== "partial" ? '' : (loadedData && loadedData.length !== 0 && !error1) ?
        <FlightList searchFlight={loadedData} pageFocus={pageFocus} travelReq={valueParam.travelreq} /> : ErrorShow

    return (<Fragment>
        {/*     <!-- Common Banner Area --> */}
        <section id="common_banner">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">

                    </div>
                </div>
            </div>
        </section>
        {/*   <!-- Form Area --> */}
        <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="theme_search_form_area position-relative mb-2">

                            <div className="row position-relative">
                                {status === 'pending' &&
                                    // <div className=''>
                                    //     <div className=''>
                                    <LoadingFlight searchFlight={onSearchFlight}></LoadingFlight>
                                    //     </div>
                                    // </div>
                                }
                                <div className="col-lg-12">
                                    <div className="row">
                                        <label for="" className="h5 mb-3 me-3 text-primary" ><i className="fas fa-globe-asia"></i> Search</label>
                                        <div className="col-lg-12 flight-search-tabs">
                                            <div className='text-sm-center1'>
                                                <span>
                                                    {
                                                        (checkSecurityDetails("Book", "Flight") && checkSecurityDetails("Book", "Flight", "Book_Flight_Search") && getConfigurationByBoolen("ALLOW_FLIGHT") === true) &&
                                                        <label for="" className="h6 mb-2 me-3" role="button" id='lblFlights' onClick={controlHandlerFightSearch}><i className="fas fa-plane-departure"></i> {t('flights')}</label>
                                                    }
                                                    {
                                                        (getConfigurationByBoolen('ALLOW_SINGLE_PNR') !== null &&
                                                            getConfigurationByBoolen("ALLOW_HOTEL") && getConfigurationByBoolen('ALLOW_SINGLE_PNR')
                                                            && getConfigurationByBoolen("ALLOW_FLIGHT") && checkSecurityDetails("Book", "Flight") && checkSecurityDetails("Book", "Flight", "Book_Flight_Search") && checkSecurityDetails("Book", "Hotel") && checkSecurityDetails("Book", "Hotel", "Book_Hotel_Search")) ?
                                                            <label for="" className="h6 mb-2 mx-1" role="button" id='lblFlightPlusHotel' onClick={controlHandlerFightSearch}><i className="fas fa-globe-asia"></i>  {t('Flights and Hotels')}</label> :
                                                            <label id='lblFlightPlusHotel' ></label>
                                                    }
                                                    {
                                                        (checkSecurityDetails("Book", "Hotel") && checkSecurityDetails("Book", "Hotel", "Book_Hotel_Search") && getConfigurationByBoolen("ALLOW_HOTEL") === true) && (valueParam.src === undefined || valueParam.src === '' || valueParam.src === 'hotel') &&
                                                        <label for="" className="h6 mb-2 ms-3" role="button" id='lblHotel' onClick={controlHandlerFightSearch}><i className="fas fa-hotel"></i> Hotels</label>
                                                    }
                                                    {
                                                        (checkSecurityDetails("Book", "Sightseeing") && getConfigurationByBoolen("ALLOW_SIGHTSEEING") === true) &&
                                                        <label for="" className="h6 mb-2 ms-3" role="button" id='lblSightseeing' onClick={controlHandlerFightSearch}><i className="fas fa-hiking"></i> Sightseeing</label>
                                                    }
                                                    {
                                                        getConfigurationByBoolen("ALLOW_HOLIDAYS") === true && getConfigurationByValue('HOLIDAYS_URL') !== null && getConfigurationByValue('HOLIDAYS_URL') !== '' &&
                                                        <label for="" className="h6 mb-2 ms-3" role="button" id='lblHolidays' onClick={controlHandlerFightSearch}><i className="fas fa-umbrella-beach"></i> Holidays</label>
                                                    }
                                                    {
                                                        getConfigurationByBoolen("ALLOW_VISA") === true &&
                                                        <label for="" className="h6 mb-2 ms-3" role="button" id='lblVisa' onClick={controlHandlerFightSearch}><i class="fas fa-passport"></i> Visa</label>
                                                    }
                                                </span>
                                            </div>
                                            <div className="flight_categories_search" id='divTripType'>
                                                <ul className="nav nav-tabs" role="tablist">
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link" id="oneway-tab" data-bs-toggle="tab"
                                                            data-bs-target="#oneway_flight" type="button" role="tab"
                                                            aria-controls="oneway_flight" aria-selected="false" onClick={tripTypeHandler}>
                                                            {t('oneway')}</button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link active" id="roundtrip-tab" data-bs-toggle="tab"
                                                            data-bs-target="#roundtrip" type="button" role="tab"
                                                            aria-controls="roundtrip"
                                                            aria-selected="true" onClick={tripTypeHandler}>{t('roundtrip')}</button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link" id="multi_city-tab" data-bs-toggle="tab"
                                                            data-bs-target="#multi_city" type="button" role="tab"
                                                            aria-controls="multi_city" aria-selected="false" onClick={tripTypeHandler}>
                                                            {t('multicity')}</button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-content" id="myTabContent1">
                                        <div className="tab-pane fade " id="oneway_flight" role="tabpanel"
                                            aria-labelledby="oneway-tab">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="oneway_search_form">
                                                        <div className="row">
                                                            {
                                                                (tripType === 'oneway') &&
                                                                <FlightSearchControlMainPanel controlId={1} tripType={tripType} />
                                                            }

                                                        </div>
                                                        {
                                                            (tripType === 'oneway' && localStorage.getItem('isCorporate') !== null && localStorage.getItem('isCorporate') === 'false') &&
                                                            <FlightSearchControlAdditionalPanel tripType={tripType} />
                                                        }

                                                        <div className="top_form_search_button mt-3">
                                                            <button className='btn text-white mt-3 gt-main-btn btn_md' onClick={onSearchHandler}>
                                                                {t('search')}
                                                            </button>
                                                            {/* <button  className="btn gt-main-btn gt-btn_md text-white btn_md ms-1">New</button> */}
                                                            {/* <button  className="btn gt-main-btn gt-btn_md text-white btn_md ms-1">Reset</button> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="roundtrip" role="tabpanel"
                                            aria-labelledby="roundtrip-tab">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="oneway_search_form">
                                                        <div className="row">
                                                            {
                                                                (tripType === 'roundtrip') &&
                                                                <FlightSearchControlMainPanel controlId={1} tripType={tripType} />
                                                            }

                                                        </div>
                                                        {
                                                            (tripType === 'roundtrip' && localStorage.getItem('isCorporate') !== null && localStorage.getItem('isCorporate') === 'false') &&
                                                            <FlightSearchControlAdditionalPanel tripType={tripType} />
                                                        }
                                                        {/* User Story 1070: Change in Search for Single PNR */}
                                                        <div className="top_form_search_butto mx-auto text-center pt-1">
                                                            <div className='row mt-2'>
                                                                <div className=' mx-auto col-lg-2 col-md-4 col-sm-8 col-8'>
                                                                    <div className='row mx-auto'>
                                                                        {
                                                                            getConfigurationByBoolen('ALLOW_SINGLE_PNR') !== null && getConfigurationByBoolen('ALLOW_SINGLE_PNR') === true &&
                                                                            <div id='divAddHotel' className='text-start me-1 col align-self-center search-add-hotel-text mt-2'>
                                                                                {
                                                                                    (tripType === 'roundtrip') &&
                                                                                    <span>
                                                                                        {/* <span className='me-2'><i className="fas fa-certificate"></i></span> */}
                                                                                        <a href='javascript:void(0)' className='form-check green-check-opacity'>
                                                                                            <span>
                                                                                                <input className="form-check-input" type="checkbox" id="addHotel"
                                                                                                    onChange={(e) => onAddhotel(e)} disabled="true"></input>
                                                                                                <label className="form-check-label mb-0 text-success fw-bold" for="addHotel">
                                                                                                    {t('addhotel')}
                                                                                                </label>
                                                                                            </span>
                                                                                        </a>
                                                                                    </span>
                                                                                }
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-1 col-sm-12'></div>
                                                                {
                                                                    getConfigurationByBoolen('ALLOW_SINGLE_PNR') !== null && getConfigurationByBoolen('ALLOW_SINGLE_PNR') === true &&
                                                                    <div className='col-lg-6 col-md-12 col-sm-12 col-12 mx-auto'>
                                                                        <button className='btn text-white mt-2 gt-main-btn btn_md aaaa' onClick={onSearchHandler}>
                                                                            {t('search')}
                                                                        </button>
                                                                    </div>
                                                                }
                                                                <div className='col-lg-3 col-md-6 col-sm-12 col-12'></div>

                                                                {
                                                                    getConfigurationByBoolen('ALLOW_SINGLE_PNR') === null || getConfigurationByBoolen('ALLOW_SINGLE_PNR') === false &&
                                                                    <div className='mx-auto'>
                                                                        <button className='btn text-white mt-3 gt-main-btn btn_md' onClick={onSearchHandler}>
                                                                            {t('search')}
                                                                        </button>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade " id="multi_city" role="tabpanel"
                                            aria-labelledby="multi_city-tab">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="oneway_search_form">
                                                        <div className="multi_city_form_wrapper">
                                                            <div className="multi_city_form">
                                                                <div className="row">
                                                                    {
                                                                        (tripType === 'multi_city') &&
                                                                        <FlightSearchControlMainPanel controlId={1} tripType={tripType} />
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="multi_city_form">
                                                                <div className="row">
                                                                    {
                                                                        (tripType === 'multi_city') &&
                                                                        <FlightSearchControlMainPanel controlId={2} tripType={tripType} />
                                                                    }
                                                                    {
                                                                        (isAddedThree) &&
                                                                        <div className="col-lg-12">
                                                                            <div className="multi_form_remove text-gt">
                                                                                <button type="button" id='removeMultiCityRowThree'
                                                                                    onClick={hideMultiCityRow}> {t('remove')}</button>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        (tripType === 'multi_city' && isAddedThree === true) &&
                                                                        <FlightSearchControlMainPanel controlId={3} tripType={tripType} />
                                                                    }
                                                                    {
                                                                        (isAddedFour) &&
                                                                        <div className="col-lg-12">
                                                                            <div className="multi_form_remove">
                                                                                <button type="button" id='removeMultiCityRowFour'
                                                                                    onClick={hideMultiCityRow}> {t('remove')}</button>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        (tripType === 'multi_city' && isAddedFour === true) &&
                                                                        <FlightSearchControlMainPanel controlId={4} tripType={tripType} />
                                                                    }
                                                                    {
                                                                        (isAddedFive) &&
                                                                        <div className="col-lg-12">
                                                                            <div className="multi_form_remove">
                                                                                <button type="button" id='removeMultiCityRowFive'
                                                                                    onClick={hideMultiCityRow}> {t('remove')}</button>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        (tripType === 'multi_city' && isAddedFive === true) &&
                                                                        <FlightSearchControlMainPanel controlId={5} tripType={tripType} />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="add_multy_form">
                                                                    <button id='showMultiCityRow' type="button" onClick={showMultiCityRow}>{t('addanotherflight')}</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {
                                                            (tripType === 'multi_city' && localStorage.getItem('isCorporate') !== null && localStorage.getItem('isCorporate') === 'false') &&
                                                            <FlightSearchControlAdditionalPanel tripType={tripType} />
                                                        }
                                                        <div className="top_form_search_button">
                                                            <button className='btn text-white mt-4 gt-main-btn btn_md bbb' onClick={onSearchHandler}>
                                                                {t('search')}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- Flight Search Areas --> */}
        <section id="explore_area" className="section_padding py-5 aaaaaaa position-relative">
            {/* <div className=' email-pro'>
                <button id="btn" className="eemail text-end">
               <i className="fas fa-envelope fs-1"></i> <i className='badge bg-danger ft-style'>3</i>
                    <span>&nbsp; Email  Proposal</span>
                </button>
            </div> */}
            <div className="container-fluid">
                {searchFlightList}
            </div>
        </section>
    </Fragment>)
};

export default SearchFlight;
