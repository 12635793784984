import { Fragment, useEffect } from 'react';
import LoginForm from '../Components/LoginForm';
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle';
import { getIPData } from "../../../services/Geolocation-API";
import { SIGNUP } from '../../../navigation/CONSTANTS';
import { Link } from 'react-router-dom';
import { GET_ALL_AIRPORT_LIST, GET_BLOCK_COUNTRY_CITY_LIST, GET_ALL_SIGHTSEEING_LIST } from '../../../services/CONSTANTS';
const Login = () => {
    useDocumentTitle('Login');
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        getIPData();
    },)
    sessionStorage.setItem("LoaderHeader", "1");
    useEffect(() => {
        if (localStorage.getItem("airportSuggestion") === null) {
            let airportSuggestionResutTemp = [];
            fetch(GET_ALL_AIRPORT_LIST(), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('idToken'),
                },
            }).then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson['data'] !== null) {
                        responseJson['data'].forEach(element2 => {
                            airportSuggestionResutTemp.push(element2);
                        });
                    }
                    localStorage.setItem("airportSuggestion", JSON.stringify(airportSuggestionResutTemp));
                });
        }
        if (localStorage.getItem("blockedCountryCityListTemp") === null) {
            let blockedCountryCityListTemp = [];
            fetch(GET_BLOCK_COUNTRY_CITY_LIST(), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('idToken'),
                },
            }).then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson['data'] !== null) {
                        responseJson['data'].forEach(element2 => {
                            blockedCountryCityListTemp.push(element2);
                        });
                    }
                    localStorage.setItem("blockedCountryCity", JSON.stringify(blockedCountryCityListTemp));
                });
        }
        if (localStorage.getItem("sightseeingSuggestionResutTemp") === null) {
            let sightseeingSuggestionResutTemp = [];
            fetch(GET_ALL_SIGHTSEEING_LIST(), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('idToken'),
                },
            }).then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson['data'] !== null) {
                        responseJson['data'].forEach(element2 => {
                            sightseeingSuggestionResutTemp.push(element2);
                        });
                    }
                    localStorage.setItem("sightseeingSuggestion", JSON.stringify(sightseeingSuggestionResutTemp));
                });
        }
    }, [])


    document.documentElement.style.setProperty('--btn-bg-color', `radial-gradient(circle at 4% 2%, #328fc7 0%, rgba(87, 29, 127, 0) 97%), #3ac9fd;`);
    return (
        // <LoginForm /> 
        <Fragment>
            {/*     <!-- Common Banner Area --> */}

            <section className='gt_login_banner'>
                <div className='position-relative'>
                    <span className="logohide">
                        <img src="assets/img/login_Globetrotter_logo.png" alt="" className='log-in-head-logo-bg position-absolute' />
                    </span>
                    <a className="fw-bold text-white forgot-pass-bg h5 float-lg-end sign-up-btn" href="/SignUp"><span className='badge bg-primary'>Sign Up</span></a>
                </div>
            </section>
            {/* <section className=''>
                <div>
                    <video className='videoTag w-100' autoPlay loop muted controls="controls">
                    <source src="assets\img\banner\Cozy-Video.mp4" type='video/mp4' /></video>
                    <div className='position-relative'>
                    <span className="logohide">
                        <img src="assets/img/login_Globetrotter_logo.png" alt="" className='log-in-head-logo-bg position-absolute' />
                    </span>
                    <a className="fw-bold text-white forgot-pass-bg h5 float-lg-end sign-up-btn" href="/SignUp"><span className='badge bg-primary'>Sign Up</span></a>
                </div>
                </div>
            </section> */}
      
            {/* <div className="navbar-area navbar-area-login hide-lg-space">
        <div className="main-navbar">
            <div className="container-fluid">
                <nav className="navbar navbar-expand-md navbar-light"> */}
            {/* <a href='' className="nav-link  hide-lg-space position-relative"> */}
            <img src="assets/img/globetrotterdark.png" alt="globetrotter" className='loginscroll-logo' />
            {/* </a> */}
            {/* </nav>
            </div>
        </div>
    </div> */}
            <div className="container">
                <LoginForm />
            </div>




        </Fragment>)

};



export default Login;
