const locaterNumber = localStorage.getItem("profileLocaterNumber") !== 'undefined' ? localStorage.getItem("profileLocaterNumber") : "\"\""
const dkNumber = localStorage.getItem("dkNumber") !== 'undefined' ? localStorage.getItem("dkNumber") : "\"\""

let paxInformationFlight = JSON.stringify({
    "ProfileRef": {
        "UniqueID": {
            "CompanyName": {
                "LocaterNumber": JSON.parse(locaterNumber),
            }
        }
    },
    "GuestTypeCode": "ADT",
    "GuestRefNumber": "1",
    "RoomIndex": "1",
    "Age": null,
    "IsHolder": false,
    "CustomerIdentifierNumber": JSON.parse(dkNumber),
    "PersonName": {
        "NamePrefix": "",
        "GivenName": "",
        "Surname": "",
        "MiddleName": ""
    },
    "Gender": "",
    "telephone": [
        {
            "phoneNumber": "",
            "mobile": "",
            "fax": ""
        }
    ],
    "email": [
        {
            "emailId": "",
            "IsPrimary": "TRUE"
        }
    ],
    "dateOfBirth": {
        "date": "00000000"
    },
    "CustLoyalty": [
        {
            "MembershipID": "",
            "ProgramID": ""
        }
    ],
    "Document": [
        {
            "DocID": "",
            "DocType": "Passport",
            "DocHolderName": "",
            "DocIssueAuthority": "",
            "DocIssueLocation": "",
            "Gender": "M",
            "BirthDate": "",
            "EffectiveDate": "",
            "ExpireDate": "00000000",
            "DocIssueCountry": "",
            "DocHolderNationality": ""

        },
        {
            "DocID": "",
            "DocType": "",
            "DocIssueCountry": ""
        }
    ],
    "PaxConvenienceCharges": "",
    "PassengerTypeCode": "",
    "TravelerRefNumber": "",
    "AccompaniedByInfantInd": null,
    "Operation": null,
    "CouponCode": null
})

let paxInformationHotel = JSON.stringify({
    "PersonName": {
        "NamePrefix": "",
        "NameTitle": "",
        "GivenName": "",
        "Surname": "",
        "MiddleName": "",
        "NameSuffix": null,
        "NameType": null
    },
    "Gender": "M",
    "Telephone": [
        {
            "PhoneNumber": "",
            "Mobile": ""
        }
    ],
    "Email": [
        {
            "EmailId": ""
        }
    ],
    "GuestTypeCode": "ADT",
    "GuestRefNumber": "1",
    "RoomIndex": "1",
    "Age": null,
    "IsHolder": false,
    "CustomerIdentifierNumber": JSON.parse(dkNumber)
})

export const CreateBookingJSONShoppingCart = (selectDetails, serviceType) => {
    let createPaxInformation = [];
    let createPaxInformationHotel = [];
    let hotelSearchRequestJson = [];
    let hotelDetailsResponse = [];
    let selectDetailsFlight = [];

    if (serviceType === "FL") {
        let intTravelRefNumber = 0;

        selectDetails.airItineraryPricingInfo.passengerTypeQuantity.forEach(element => {
            for (var i = 0; i < parseInt(element.quantity); i++) {
                let paxInfromationDetails = JSON.parse(paxInformationFlight);
                intTravelRefNumber = intTravelRefNumber + 1;

                paxInfromationDetails.TravelerRefNumber = "" + intTravelRefNumber + "";
                paxInfromationDetails.PassengerTypeCode = element.code;
                createPaxInformation.push(paxInfromationDetails);
            }
        });

        const BookingDataTemp = ({
            "selectDetailsFlight": selectDetails,
            "createPaxInformation": createPaxInformation
        });
        localStorage.setItem("BookingDataTemp", JSON.stringify(BookingDataTemp));
    }

    if (serviceType === "HT") {
        hotelDetailsResponse = JSON.parse(sessionStorage.getItem('HotelDetailsResponse'));
        let hotelDetailsResponsePrice = hotelDetailsResponse.data.hotelResults[0].price.filter(a => a.rateKey === selectDetails.rateKey);
        hotelDetailsResponse.data.hotelResults[0].price = hotelDetailsResponsePrice;
        let hotelDetailsResponseRoomRateDetails = hotelDetailsResponse.data.hotelResults[0].roomRateDetails.filter(a => a.rateKey === selectDetails.rateKey);
        hotelDetailsResponse.data.hotelResults[0].roomRateDetails = hotelDetailsResponseRoomRateDetails;

        hotelSearchRequestJson = JSON.parse(sessionStorage.getItem("hotelSearchRequestJson"));
        hotelSearchRequestJson.Data.RoomGuests.forEach(element => {
            let paxInfromationDetails = JSON.parse(paxInformationHotel);
            paxInfromationDetails.GuestRefNumber = element.RoomIndex.toString()
            paxInfromationDetails.RoomIndex = element.RoomIndex.toString()
            paxInfromationDetails.NoOfAdults = element.NoOfAdults.toString()
            createPaxInformationHotel.push(paxInfromationDetails);
        });
    }

    if (localStorage.getItem("BookingDataTemp") !== null) {
        selectDetailsFlight = JSON.parse(localStorage.getItem("BookingDataTemp")).selectDetailsFlight;
        createPaxInformation = JSON.parse(localStorage.getItem("BookingDataTemp")).createPaxInformation;
    }

    const BookingJSON = ({
        "OfficeID": JSON.parse(localStorage.getItem('officeID')),
        "data": {
            "RequestType": "OF",
            "bookingReferenceNumber": "",
            "travelAgentName": (localStorage.getItem('displayName') !== 'undefined' && localStorage.getItem('displayName') !== null) ? JSON.parse(localStorage.getItem('displayName')) : "",
            "ShoppingID": null,
            "POS": null,
            "RequestDetails": {
                "Environment": "PRODUCTION",
                "echoToken": JSON.parse(sessionStorage.getItem('HotelEchoToken')),
                "traceId": JSON.parse(localStorage.getItem('traceID')),
                "UserID": JSON.parse(localStorage.getItem('userID')),
                "SwitchLoginID": localStorage.getItem('RootLoginEmailID') !== undefined ? localStorage.getItem('RootLoginEmailID') : '',
                "IPAddress": localStorage.getItem("IPAddress"),
                "primaryLanguageId": "EN"
            },
            "travelerInfo": {
                "SpecialReqDetails": [
                    {
                        "SeatRequests": null,
                        "SpecialServiceRequests": null,
                        "OtherServiceInformations": null
                    }
                ],
                "traveler": createPaxInformation
                //"GuestInfo": createPaxInformationHotel
            },
            "airItinerary": [
                {
                    "originDestinationOptions": selectDetailsFlight.airItinerary.originDestinationOptions,
                    "airItineraryPricingInfo": selectDetailsFlight.airItineraryPricingInfo,
                    "AirItineraryRemarks": null,
                    "SequenceNumber": "1",
                    "SourceId": selectDetailsFlight.sourceId,
                    "SourceName": selectDetailsFlight.sourceName,
                    "DirectionInd": "O",
                    "SectorInd": "I",
                    "SecurityKey": {
                        "securityGUID": selectDetailsFlight.securityKey !== undefined && selectDetailsFlight.securityKey !== '' ? selectDetailsFlight.securityKey.securityGUID : '',
                        "key": selectDetailsFlight.securityKey !== undefined && selectDetailsFlight.securityKey !== '' ? selectDetailsFlight.securityKey.key : ''
                    },
                    "ResultIndex": "",
                    "CouponFare": "0",
                    "ChannelType": null,
                    "ChannelCode": "",
                    "IsLCC": "0",
                    "IsAPISMandatory": null,
                    "IsGSTMandatory": null,
                    "SelectedPricingSource": "Published",
                    "ReturnGroupId": "1",
                    "IsReturn": "",
                    "PaymentInfo": {
                        "PaymentMode": [
                            {
                                "Name": "CREDITCARD",
                                "Amount": "0",
                                "HotelAmount": "0",
                                "Type": null,
                                "CardIsEncrypt": true,
                                "PaymentModeInfo": [
                                    {
                                        "FieldName": "CARDTYPE",
                                        "FieldValue": ""
                                    },
                                    {
                                        "FieldName": "CARDNO",
                                        "FieldValue": ""
                                    },
                                    {
                                        "FieldName": "FIRSTNAME",
                                        "FieldValue": ""
                                    },
                                    {
                                        "FieldName": "LASTNAME",
                                        "FieldValue": ""
                                    },
                                    {
                                        "FieldName": "EXPIRYEAR",
                                        "FieldValue": ""
                                    },
                                    {
                                        "FieldName": "EXPIRMONTH",
                                        "FieldValue": ""
                                    },
                                    {
                                        "FieldName": "ADDRESS",
                                        "FieldValue": ""
                                    },
                                    {
                                        "FieldName": "COUNTRY",
                                        "FieldValue": ""
                                    },
                                    {
                                        "FieldName": "STATE",
                                        "FieldValue": ""
                                    },
                                    {
                                        "FieldName": "CITY",
                                        "FieldValue": ""
                                    },
                                    {
                                        "FieldName": "POSTALCODE",
                                        "FieldValue": ""
                                    },
                                ]
                            }
                        ],
                        "TicketingInfo": {
                            "TicketType": "E",
                            "TicketTimeLimit": "",
                            "IssueTicket": "N",
                            "DirectionInd": null,
                            "IssueTicketWithUpdateFare": null,
                            "TktArrangement": "TKTL",
                            "Operation": null
                        }
                    },
                    "PNRRetention": null,
                    "TPA_Extensions": null,
                    "PNRAmount": null,
                    "PriceType": null,
                    "NUC_Rate": null,
                    "ExchangeRate": null,
                    "BookingReferenceID": null,
                    "UpdatedAirItineraryPricingInfo": null,
                    "ValidatingCarrier": "UA",
                    "ValidatingCarrier": selectDetailsFlight.airItinerary.validatingCarrier,
                    "totalOutBoundDuration": selectDetailsFlight.airItinerary.totalOutBoundDuration,
                    "totalInBoundDuration": selectDetailsFlight.airItinerary.totalInBoundDuration,
                    "totalItineraryDuration": selectDetailsFlight.airItinerary.totalItineraryDuration,
                    "groupDurations": selectDetailsFlight.airItinerary.groupDurations
                }
            ],
            "hotelResults": serviceType === "HT" ? hotelDetailsResponse.data.hotelResults : [],
            "RateKey": serviceType === "HT" ? selectDetails.rateKey : '',
            "GSTDetails": null,
            "MiscellaneousRemarkInfo": {
                "MiscellaneousInfo": [
                    {
                        "Code": "",
                        "Text": "",
                        "Operation": null,
                        "Id": null,
                        "Category": null,
                        "RemarkType": "General",
                        "TravelerRefNumber": null,
                        "FlightRefNumber": null,
                        "TPA_Extensions": null
                    },
                    {
                        "Code": "",
                        "Text": "",
                        "Operation": null,
                        "Id": null,
                        "Category": null,
                        "RemarkType": "InternalRemarks",
                        "TravelerRefNumber": null,
                        "FlightRefNumber": null,
                        "TPA_Extensions": null
                    }
                ]
            },
            "AdditionalInfo": null,
            "TPA_Extensions": null,
            "UserID": JSON.parse(localStorage.getItem('userID')),
            "TraceId": JSON.parse(localStorage.getItem('traceID')),
            "TimeStamp": null,
            "Environment": "PRODUCTION",
            "Version": "PRODUCTION",
            "IPAddress": localStorage.getItem("IPAddress"),
            "SequenceNmbr": null,
            "primaryLanguageId": "EN",
            "AltLangID": null,
            "SwitchLoginID": localStorage.getItem('RootLoginEmailID') !== undefined ? localStorage.getItem('RootLoginEmailID') : '',
        }
    })
    return BookingJSON;
}