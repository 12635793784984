import { Fragment, useState, useEffect, useRef } from 'react';
import useDocumentTitle from '../../components/Common/layout/useDocumentTitle';
import MdModal from '../Flights/MdModal';
import Dropdown from 'react-select';
import GuestDetails from './Components/GuestDetails';
import HotelItem from './Components/HotelItem';
import ContactPerson from './Components/ContactPerson';
import AdditionalServices from './Components/AdditionalServices';
import AddMarkup from './Components/AddMarkup';
import { HOTELCONFIRMATIONS } from '../../navigation/CONSTANTS';
import { useHistory } from 'react-router-dom';
import BookingDetails from './Components/BookingDetails';
import Pricesummary from './Components/Pricesummary';
import CountryDropDownList from '../../components/Common/DropDownList/CountryDropDownList';
import StateDropDownList from '../../components/Common/DropDownList/StateDropDownList';
import { useTranslation } from 'react-i18next';
import { hotelBooking, hotelPriceCheck } from '../../services/Hotel-API';
import { checkSecurityDetails } from '../../utils/RoleUtils';

//import BookingLoadingSpinner from '../../pages/Booking/Components/BookingLoadingSpinner'
import ErrorModal from "../../components/Common/Error/ErrorModal";
import useHttp from '../../services/use-http';
import { getCreditCardDetails } from '../../services/CommonListData-API';
import { dateList, monthList, expiryYearList } from '../../utils/DropdownData';
import { validateByLuhn, calculateRoeAmountAdditional } from '../../utils/CommonFunction';
import { getConfigurationByBoolen, getConfigurationByValue } from '../../utils/ConfigurationsFunction';
import ConfirmationModal from "../../components/Common/Confirmation/ConfirmationModal";
import LoadingHotel from '../Hotels/Components/LoadingHotel';
import TermsConditionsPopup from '../CompanyPolicy/TermsConditionsPopup';
import { encrypted } from '../../utils';
import { searchRoe } from '../../services/ROE-API';
import secureLocalStorage from 'react-secure-storage';

const Booking = () => {
    const { t } = useTranslation(['translation.HotelBooking']);
    useDocumentTitle('Hotel Book');
    const { sendRequest, status, data: loadedBookingResponse } = useHttp(hotelBooking);
    const { sendRequest: sendRequestPriceCheck, status: statusPriceCheck, data: loadedHotelPriceCheck, error } = useHttp(hotelPriceCheck);
    const { sendRequest: sendRequestRoe, status: statusRoe, data: loadedRoe, error: errorRoe } = useHttp(searchRoe);

    const [confirm, setConfirm] = useState('');
    const [termsCondition, setTermsCondition] = useState('');
    const history = useHistory();
    const [cardType, setCardType] = useState('');
    const [expiryMonth, setExpiryMonth] = useState('');
    const [expiryYear, setExpiryYear] = useState('');
    const postalCodeInputRef = useRef('');
    const cardNumberInputRef = useRef('');
    const cardHolderFirstNameInputRef = useRef('');
    const cardHolderLastNameInputRef = useRef('');
    const cityNameInputRef = useRef('');
    const dateOfExpiryInputRef = useRef('');
    const addressInputRef = useRef('');
    const [countryCodeInputHasError, setCountryCodeInputHasError] = useState(false);
    const [cardTypeInputHasError, setCardTypeInputHasError] = useState(false);
    const [expiryMonthInputHasError, setExpiryMonthInputHasError] = useState(false);
    const [expiryYearInputHasError, setExpiryYearInputHasError] = useState(false);
    const [stateNameInputHasError, setStateNameInputHasError] = useState(false);
    const [postalCodeInputHasError, setPostalCodeInputHasError] = useState(false);
    const [postalCodeInvalidInputError, setPostalCodeInvalidInputError] = useState(false);

    const [dateofExpiryInputHasError, setDateofExpiryInputHasError] = useState(false);
    const [dateofExpiryInvalidInputError, setDateofExpiryInvalidInputError] = useState(false);
    const [cardHolderFirstNameInputHasError, setCardHolderFirstNameInputHasError] = useState(false);
    const [cardHolderFirstNameInvalidInputError, setCardHolderFirstNameInvalidInputError] = useState(false);
    const [cardHolderLastNameInputHasError, setCardHolderLastNameInputHasError] = useState(false);
    const [cardHolderLastNameInvalidInputError, setCardHolderLastNameInvalidInputError] = useState(false);
    const [cardNumberInputHasError, setCardNumberInputHasError] = useState(false);
    const [cardNumberInvalidInputError, setCardNumberInvalidInputError] = useState(false);
    const [cityNameInputHasError, setCityNameInputHasError] = useState(false);
    const [cityNameInvalidInputError, setCityNameInvalidInputError] = useState(false);

    const [countryCode, setCountryCode] = useState('');
    const [stateCode, setStateCode] = useState('');
    const [stateName, setStateName] = useState('');
    const [cityCode, setCityCode] = useState('');
    const [cityName, setCityName] = useState('');
    const [cityList, setCityList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [isCountryChanged, setIsCountryChanged] = useState(false);
    const [isStateChanged, setIsStateChanged] = useState(false);
    const [stateCodeInputHasError, setStateCodeInputHasError] = useState(false);
    const [stateNameInvalidInputError, setStateNameInvalidInputError] = useState(false);
    const [controlType, setControlType] = useState('');
    const [addressInvalidInputError, setAddressInvalidInputError] = useState(false);
    const [addressInputHasError, setAddressInputHasError] = useState(false);
    const [address, setAddress] = useState('');
    const [profileLocaterNumber, setProfileLocaterNumber] = useState(null);
    const [confirmModel, setConfirmModel] = useState();
    const [cardName, setCardName] = useState('');
    const [error1, setError] = useState();

    let hotelBookingJSON = JSON.parse(sessionStorage.getItem("HotelBookingData"));
    //USER STORY 2334: Wallet changes
    let currentWalletCurrency = getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== null && getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== '' ? getConfigurationByValue("BASE_CURRENCY_IATA_CODE") : 'USD'; //sessionStorage.getItem("currentWalletCurrency");
    let currentWalletAmount = sessionStorage.getItem("currentWalletAmount");
    let isWalletActive = sessionStorage.getItem("activeWallet") !== null ? "" + sessionStorage.getItem("activeWallet") : 'false';
    let totalPrice = hotelBookingJSON.data.hotelResults[0].price[0].offeredPrice.priceOffered;
    let isWalletAmountIsSufficient = 'false';
    let isWalletRadiobuttonChecked = 'false';
    let fareCurrency = hotelBookingJSON.data.hotelResults[0].price[0].currencyCode;
    var valid = require("card-validator");

    let totalTax = 0;
    hotelBookingJSON.data.hotelResults[0].roomRateDetails.forEach(item => {
        if (item.price !== undefined && item.price !== null && item.price.fees !== undefined && item.price.fees !== null) {
            totalTax = totalTax + item.price.taxes.totalAmount + item.price.fees.reduce((n, { amount }) => n + amount, 0);
        }
        else {
            totalTax = totalTax + item.price !== undefined && item.price !== null ? item.price.taxes.totalAmount : 0;
        }
    });

    if ((parseFloat(currentWalletAmount) < parseFloat(calculateRoeAmountAdditional(fareCurrency, totalPrice, false)))) {
        isWalletAmountIsSufficient = 'true';
    }

    let RemainingWalletAmount = currentWalletAmount - calculateRoeAmountAdditional(fareCurrency, totalPrice, false);
    const countryCodeList = ['PR', 'US', 'VI'];

    const countryCodeValue = (countryCode) => {
        setCountryCode(countryCode);
        //setRefresh(true);
        setIsCountryChanged(true);
        setCityCode('');
        setCityName('');
        setCityList(cityList);
        setStateCode('');
        setStateList(stateList);
        setStateName('');
        setCountryCodeInputHasError(false);
    };
    //Code for Bind Airline Program Data
    const { sendRequest: sendRequestCreditCardData, data: loadedCreditCardData } = useHttp(getCreditCardDetails);



    useEffect(() => {
        sendRequestCreditCardData();
    }, [sendRequestCreditCardData])

    let creditCardOptions = []
    let creditCardArrayData = [];

    if (loadedCreditCardData !== null && loadedCreditCardData.errors.status === 'FALSE') {
        creditCardArrayData = loadedCreditCardData.data;
        for (let index = 0; index < creditCardArrayData.length; index++) {
            creditCardOptions = [...creditCardOptions, { value: creditCardArrayData[index].code, label: creditCardArrayData[index].name }]
        }
    }

    const stateCodeValue = (stateCode) => {
        setStateCode(stateCode);
        setIsCountryChanged(false);
        setStateCodeInputHasError(false);
        setStateNameInputHasError(false);
        setIsStateChanged(true);
    };

    const stateNameText = (stateName) => {
        if (stateName.length > 0) {
            if (!stateName.match('^[a-zA-Z ]+$')) {
                setStateNameInvalidInputError(true);
                //return;
            }
            else {
                setStateNameInvalidInputError(false);
            }
        }
        setStateName(stateName);
        setIsCountryChanged(false);
        setStateCodeInputHasError(false);
        if (stateName.trim() === '') {
            //setStateNameInputHasError(true);
        }
        else {
            setStateNameInputHasError(false);
        }
        setIsStateChanged(true);
    };
    const countryNameText = (country_Name) => {
        //setCountry_Name(country_Name);
    };


    const cityCodeValue = (cityCode) => {
        setCityCode(cityCode);
        setIsCountryChanged(false);
        // setCityCodeInputHasError(false);
        if (stateCode === '' && stateName === '') {
            setIsStateChanged(true);
        }
        else {
            setIsStateChanged(false);
        }

        if (postalCodeInputRef.current.value.trim() === '') {
            setPostalCodeInputHasError(true);
            setPostalCodeInvalidInputError(false);
            return;
        }
        else {
            setPostalCodeInputHasError(false);
        }
    };

    useEffect(() => {
        setProfileLocaterNumber(JSON.parse(localStorage.getItem("profileLocaterNumber")));

        if (getConfigurationByBoolen("HOTEL_CREDIT_CARD") === true) {
            onChangeCardType("1");
        }
        else if (profileLocaterNumber !== null && profileLocaterNumber !== '' && getConfigurationByBoolen("HOTEL_SABRE_PROFILE_CREDIT_CARD") === true) {
            onChangeCardType("2");
        }
        else if (getConfigurationByBoolen("HOTEL_WALLET_PAYMENT") === true) {
            onChangeCardType("3");
        }

        if (secureLocalStorage.getItem("searchRoeResult") === null) {
            const onSearchRoe = ({
                fromCurrency: "",
                toCurrency: ""
            });

            sendRequestRoe(onSearchRoe);
        }
    }, [])

    const errorHandler = () => {
        setError(null);
        setTermsCondition(null);
    };

    function onContinueButton(tabID) {

        if (tabID === "3" && !getConfigurationByBoolen("ALLOW_SERVICE_FEE")) {
            tabID = "4";
        }

        // code to hide rate summary on hotel booking page
        if (document.querySelector('#PriceSummaryCollapse') !== null) {
            if (document.querySelector('#PriceSummaryCollapse').classList.contains('show')) {
                document.querySelector('#btnPriceSummaryCollapse').click();
            }
        }

        if (tabID === "1") {
            document.getElementById("completeOne").style.display = 'block';
            document.getElementById("headingOne").setAttribute("class", "accordion-button collapsed");
            document.getElementById("collapseOne").setAttribute("class", "accordion-collapse collapse ");
            document.getElementById("headingTwo").setAttribute("class", "accordion-button");
            document.getElementById("headingTwo").setAttribute("data-bs-toggle", "collapse");
            document.getElementById("collapseTwo").setAttribute("class", "accordion-collapse collapse show");
            document.getElementById("headingThree").setAttribute("class", "text-muted accordion-button collapsed");
            document.getElementById("collapseThree").setAttribute("class", "accordion-collapse collapse");
            if (document.getElementById("headingFour") !== null) {
                document.getElementById("headingFour").setAttribute("class", "text-muted accordion-button collapsed");
                document.getElementById("collapseFour").setAttribute("class", "accordion-collapse collapse");
            }
            document.getElementById("headingFive").setAttribute("class", "text-muted accordion-button collapsed");
            document.getElementById("collapseFive").setAttribute("class", "accordion-collapse collapse");
        } else if (tabID === "2") {
            document.getElementById("completeTwo").style.display = 'block';
            document.getElementById("headingOne").setAttribute("class", "accordion-button collapsed");
            document.getElementById("collapseOne").setAttribute("class", "accordion-collapse collapse ");
            document.getElementById("headingTwo").setAttribute("class", "accordion-button collapsed");
            document.getElementById("collapseTwo").setAttribute("class", "accordion-collapse collapse");
            document.getElementById("headingThree").setAttribute("class", "accordion-button ");
            document.getElementById("headingThree").setAttribute("data-bs-toggle", "collapse");
            document.getElementById("collapseThree").setAttribute("class", "accordion-collapse collapse show");
            if (document.getElementById("headingFour") !== null) {
                document.getElementById("headingFour").setAttribute("class", "text-muted accordion-button collapsed");
                document.getElementById("collapseFour").setAttribute("class", "accordion-collapse collapse");
            }
            document.getElementById("headingFive").setAttribute("class", "text-muted accordion-button collapsed");
            document.getElementById("collapseFive").setAttribute("class", "accordion-collapse collapse");
        } else if (tabID === "3") {
            document.getElementById("completeThree").style.display = 'block';
            document.getElementById("headingOne").setAttribute("class", "accordion-button collapsed");
            document.getElementById("collapseOne").setAttribute("class", "accordion-collapse collapse ");
            document.getElementById("headingTwo").setAttribute("class", "accordion-button collapsed");
            document.getElementById("collapseTwo").setAttribute("class", "accordion-collapse collapse");
            document.getElementById("headingThree").setAttribute("class", "accordion-button collapsed");
            document.getElementById("collapseThree").setAttribute("class", "accordion-collapse collapse");
            if (document.getElementById("headingFour") !== null) {
                document.getElementById("headingFour").setAttribute("class", "accordion-button ");
                document.getElementById("headingFour").setAttribute("data-bs-toggle", "collapse");
                document.getElementById("collapseFour").setAttribute("class", "accordion-collapse collapse show");
            }
            document.getElementById("headingFive").setAttribute("class", "text-muted accordion-button collapsed");
            document.getElementById("collapseFive").setAttribute("class", "accordion-collapse collapse");
        } else if (tabID === "4") {
            if (document.getElementById("completeThree") !== null && !getConfigurationByBoolen("ALLOW_SERVICE_FEE")) {
                document.getElementById("completeThree").style.display = 'block';
            }

            if (document.getElementById("completeFour") !== null) {
                document.getElementById("completeFour").style.display = 'block';
            }
            document.getElementById("headingOne").setAttribute("class", "accordion-button collapsed");
            document.getElementById("collapseOne").setAttribute("class", "accordion-collapse collapse ");
            document.getElementById("headingTwo").setAttribute("class", "accordion-button collapsed");
            document.getElementById("collapseTwo").setAttribute("class", "accordion-collapse collapse");
            document.getElementById("headingThree").setAttribute("class", "accordion-button collapsed");
            document.getElementById("collapseThree").setAttribute("class", "accordion-collapse collapse");
            if (document.getElementById("headingFour") !== null) {
                document.getElementById("headingFour").setAttribute("class", "accordion-button collapsed");
                document.getElementById("collapseFour").setAttribute("class", "accordion-collapse collapse");
            }
            document.getElementById("headingFive").setAttribute("class", "accordion-button ");
            document.getElementById("headingFive").setAttribute("data-bs-toggle", "collapse");
            document.getElementById("collapseFive").setAttribute("class", "accordion-collapse collapse show");
        }
    }

    function SendRequest(event) {
        event.preventDefault();
        onBookPayment("1")
    }
    function OnBook(event) {
        event.preventDefault();
        onBookPayment("2")
    }
    //   User Story 2338: Add remarks to the Sabre PNR
    function passHiddenRemarksToBookingJson(text) {
        const hiddenRemarks = ({

            "code": "",
            "text": text,
            "remarkType": "Hidden"

        });
        return hiddenRemarks;
    }

    let allowBooking = (hotelBookingJSON.data?.hotelResults[0]?.roomRateDetails[0]?.rateType === "113" &&
        getConfigurationByBoolen("HOTEL_CGN_ALLOW_BOOKING") === false)
        ? false : true;
    async function onBookPayment(id) {

        if (document.getElementById("checkCreditCard") !== null && document.getElementById("checkCreditCard").checked) {
            if (cardType === '') {
                setCardTypeInputHasError(true);
                return;
            } else {
                setCardTypeInputHasError(false);
            }

            if (cardNumberInputRef.current.value.trim() === '') {
                cardNumberInputRef.current.focus();
                setCardNumberInputHasError(true);
                setCardNumberInvalidInputError(false);
                return;
            }
            else {
                setCardNumberInputHasError(false);
            }

            // let isValid =
            //     (validateByLuhn(cardNumberInputRef.current.value.trim()) &&
            //         cardNumberInputRef.current.value.trim().length == 15 &&
            //         (cardNumberInputRef.current.value.trim().indexOf("34") == 0 || cardNumberInputRef.current.value.trim().indexOf("37") == 0)) ||
            //     (cardNumberInputRef.current.value.trim().length == 13 && cardNumberInputRef.current.value.trim()[0] == 4) ||
            //     (cardNumberInputRef.current.value.trim().length == 16 &&
            //         (cardNumberInputRef.current.value.trim()[0] == 4 ||
            //             (cardNumberInputRef.current.value.trim()[0] == 5 && cardNumberInputRef.current.value.trim()[1] >= 1 && cardNumberInputRef.current.value.trim()[1] <= 5)));

            // if (!isValid) {
            //     cardNumberInputRef.current.focus();
            //     setCardNumberInputHasError(false);
            //     setCardNumberInvalidInputError(true);
            //     return;
            // }
            // else {
            //     setCardNumberInputHasError(false);
            //     setCardNumberInvalidInputError(false);
            // }

            var numberValidation = valid.number(cardNumberInputRef.current.value.trim());
            if (!numberValidation.isValid) {
                cardNumberInputRef.current.focus();
                setCardNumberInputHasError(false);
                setCardNumberInvalidInputError(true);
                return;
            }
            else {
                setCardNumberInputHasError(false);
                setCardNumberInvalidInputError(false);
            }

            if (numberValidation.isValid) {
                if (numberValidation.card.niceType !== cardName) {
                    cardNumberInputRef.current.focus();
                    setCardNumberInputHasError(false);
                    setCardNumberInvalidInputError(true);
                    return;
                }
                else {
                    setCardNumberInputHasError(false);
                    setCardNumberInvalidInputError(false);
                }
            }

            if (cardHolderFirstNameInputRef.current.value.trim() === '') {
                cardHolderFirstNameInputRef.current.focus();
                setCardHolderFirstNameInputHasError(true);
                setCardHolderFirstNameInvalidInputError(false);
                return;
            }
            else {
                setCardHolderFirstNameInputHasError(false);
            }

            if (cardHolderFirstNameInputRef.current.value.trim() !== '') {
                if (!cardHolderFirstNameInputRef.current.value.match('^[a-zA-Z ]+$')) {
                    cardHolderFirstNameInputRef.current.focus();
                    setCardHolderFirstNameInvalidInputError(true);
                    setCardHolderFirstNameInputHasError(false);
                    return;
                }
                else {
                    setCardHolderFirstNameInvalidInputError(false);
                }
            }

            if (cardHolderLastNameInputRef.current.value.trim() === '') {
                cardHolderLastNameInputRef.current.focus();
                setCardHolderLastNameInputHasError(true);
                setCardHolderLastNameInvalidInputError(false);
                return;
            }
            else {
                setCardHolderLastNameInputHasError(false);
            }

            if (cardHolderLastNameInputRef.current.value.trim() !== '') {
                if (!cardHolderLastNameInputRef.current.value.match('^[a-zA-Z ]+$')) {
                    cardHolderLastNameInputRef.current.focus();
                    setCardHolderLastNameInvalidInputError(true);
                    setCardHolderLastNameInputHasError(false);
                    return;
                }
                else {
                    setCardHolderLastNameInvalidInputError(false);
                }
            }

            if (expiryMonth === '') {
                setExpiryMonthInputHasError(true);
                return;
            }
            else {
                setExpiryMonthInputHasError(false);
            }

            if (expiryYear === '') {
                setExpiryYearInputHasError(true);
                return;
            }
            else {
                setExpiryYearInputHasError(false);
            }

            if (address.trim() === '') {
                addressInputRef.current.focus();
                setAddressInputHasError(true);
                setAddressInvalidInputError(false);
                return;
            }
            else {
                setAddressInputHasError(false);
            }

            if (!address.match('^[a-zA-Z 0-9/,-/\n/]+$')) {
                addressInputRef.current.focus();
                setAddressInvalidInputError(true);
                setAddressInputHasError(false);
                return;
            }
            else {
                setAddressInvalidInputError(false);
            }

            if (countryCode === '') {
                setCountryCodeInputHasError(true);
                return;
            }
            else {
                setCountryCodeInputHasError(false);
            }

            if (controlType !== '') {
                if (controlType === 'txt') {
                    setStateCode('');
                    setStateCodeInputHasError(false);
                    if (stateName.trim() === '') {
                        setStateNameInputHasError(true);
                        return;
                    }
                    else {
                        setStateNameInputHasError(false);
                    }
                }
                else {
                    setStateName('');
                    setStateNameInputHasError(false);
                    if (stateCode === '') {
                        setStateCodeInputHasError(true);
                        return;
                    }
                    else {
                        setStateCodeInputHasError(false);
                    }
                }
            }

            if (isCountryChanged === true || (stateCode === '' && stateName.trim() === '')) {
                setStateCodeInputHasError(true);
                return
            }

            // if (isCountryChanged === true || (isCountryChanged === false && address.trim() !== '')) {
            //     if (cityCode === '' && stateCodeInputHasError === false && stateNameInputHasError === false && stateNameInvalidInputError === false) {
            //         setCityCodeInputHasError(true);
            //         return
            //     }
            // }

            if (stateName.length > 0) {
                if (!stateName.match('^[a-zA-Z ]+$')) {
                    setStateNameInvalidInputError(true);
                    return;
                }
                else {
                    setStateNameInvalidInputError(false);
                }
            }

            const enteredCityName = cityNameInputRef.current.value;
            if (enteredCityName.trim() === '') {
                cityNameInputRef.current.focus();
                setCityNameInputHasError(true);
                setCityNameInvalidInputError(false);
                return;
            }
            else {
                setCityNameInputHasError(false);
            }

            if (!enteredCityName.match('^[a-zA-Z. ]+$')) {
                cityNameInputRef.current.focus();
                setCityNameInvalidInputError(true);
                setCityNameInputHasError(false);
                return;
            }
            else {
                setCityNameInvalidInputError(false);
            }

            if (postalCodeInputRef.current.value.trim() === '') {
                postalCodeInputRef.current.focus();
                setPostalCodeInputHasError(true);
                setPostalCodeInvalidInputError(false);
                return;
            }
            else {
                setPostalCodeInputHasError(false);
            }

            if (!postalCodeInputRef.current.value.match('^[a-zA-Z0-9 -]+$') || postalCodeInputRef.current.value.length < 5) {
                postalCodeInputRef.current.focus();
                setPostalCodeInvalidInputError(true);
                setPostalCodeInputHasError(false);
                return;
            }
            else {
                if (countryCodeList.includes(countryCode)) {
                    if (postalCodeInputRef.current.value.length === 5 || postalCodeInputRef.current.value.length === 9) {
                        setPostalCodeInvalidInputError(false);
                    }
                    else {
                        postalCodeInputRef.current.focus();
                        setPostalCodeInvalidInputError(true);
                        setPostalCodeInputHasError(false);
                        return;
                    }
                }
                else {
                    setPostalCodeInvalidInputError(false);
                }
            }

            let newStateCode = '';
            let newStateName = '';

            if (controlType === '') {
                if (stateCode !== '') {
                    newStateCode = stateCode;
                    newStateName = '';
                }
                else {
                    newStateCode = '';
                    newStateName = stateName;
                }
            }
            else {
                if (controlType === 'ddl') {
                    newStateCode = stateCode;
                    newStateName = '';
                }
                else {
                    newStateCode = '';
                    newStateName = stateName;
                }
            }

            if (postalCodeInputRef.current.value !== '') {
                var inputs = document.querySelectorAll('.tandc-payment-option');
                for (var i = 0; i < inputs.length; i++) {
                    if (!inputs[i].checked) {
                        inputs[i].focus();
                        document.querySelector('#spnTandCPaymentMessage').innerHTML = "Please read and agree to the terms and conditions to proceed.";
                        return;
                    }
                }
            }
        }
        else if ((document.querySelector('#checkSabreCreditCard') !== null && document.querySelector('#checkSabreCreditCard').checked) || (document.querySelector('#checkWallet') !== null && document.querySelector('#checkWallet').checked)) {
            var inputs = document.querySelectorAll('.tandc-payment-option');
            for (var i = 0; i < inputs.length; i++) {
                if (!inputs[i].checked) {
                    inputs[i].focus();
                    document.querySelector('#spnTandCPaymentMessage').innerHTML = "Please read and agree to the terms and conditions to proceed.";
                    return;
                }
            }
        }

        if (document.querySelector('#btnSendRequest') !== null) {
            document.querySelector('#btnSendRequest').style.display = "none";
        }
        if (document.querySelector('#btnOnBook') !== null) {
            document.querySelector('#btnOnBook').style.display = "none";
        }

        //User Story 1073: Booking Summary for Single PNR
        let currencySymbolLength = new Intl.NumberFormat('en-US', { style: 'currency', currency: fareCurrency }).format(0).toString().split('.')[0].length <= 2 ? 1 : 3;        
        let markupAmount = document.querySelector('#spnMarkupAmountHotel') !== null ? parseFloat(document.querySelector('#spnMarkupAmountHotel').innerHTML.length > 0 ? document.querySelector('#spnMarkupAmountHotel').innerHTML.replaceAll('&nbsp;','').replaceAll(' ','').substring(currencySymbolLength, document.querySelector('#spnMarkupAmountHotel').innerHTML.length) : 0) : 0;        
        let hotelBookingJSON = JSON.parse(sessionStorage.getItem("HotelBookingData"));

        let cardNumber = "";
        if (cardNumberInputRef.current.value.trim() !== '') {
            cardNumber = await encrypted(cardNumberInputRef.current.value.trim());
        }

        hotelBookingJSON.data.BookingType = id === '1' ? 'OF' : 'ON';
        hotelBookingJSON.data.PaymentInfo.PaymentMode.forEach(elementPaymentMode => {
            if (document.getElementById("checkSabreCreditCard") !== null && document.getElementById("checkSabreCreditCard").checked === true) {
                elementPaymentMode.Name = "PROFILE";
            }
            else if (document.getElementById("checkWallet") !== null && document.getElementById("checkWallet").checked) {
                elementPaymentMode.Name = "WALLET";
            }
            else {
                elementPaymentMode.Name = "CREDITCARD";
            }

            elementPaymentMode.Amount = hotelBookingJSON.data.hotelResults[0].price[0].offeredPrice.priceOffered.toString().length > 0 ? parseFloat(hotelBookingJSON.data.hotelResults[0].price[0].offeredPrice.priceOffered) > 0 ? parseFloat(hotelBookingJSON.data.hotelResults[0].price[0].offeredPrice.priceOffered - markupAmount).toFixed(2) : parseFloat(hotelBookingJSON.data.hotelResults[0].price[0].offeredPrice.priceOffered).toFixed(2) : parseFloat(0);

            elementPaymentMode.PaymentModeInfo.forEach(elementPaymentModeInfo => {
                if (elementPaymentModeInfo.FieldName === "CARDTYPE")
                    elementPaymentModeInfo.FieldValue = cardType;
                if (elementPaymentModeInfo.FieldName === "CARDNO")
                    elementPaymentModeInfo.FieldValue = cardNumber;
                if (elementPaymentModeInfo.FieldName === "FIRSTNAME")
                    elementPaymentModeInfo.FieldValue = cardHolderFirstNameInputRef.current.value.trim();
                if (elementPaymentModeInfo.FieldName === "LASTNAME")
                    elementPaymentModeInfo.FieldValue = cardHolderLastNameInputRef.current.value.trim();
                if (elementPaymentModeInfo.FieldName === "EXPIRYEAR") {
                    elementPaymentModeInfo.FieldValue = expiryYear;
                }
                if (elementPaymentModeInfo.FieldName === "EXPIRMONTH") {
                    elementPaymentModeInfo.FieldValue = expiryMonth;
                }
                if (elementPaymentModeInfo.FieldName === "ADDRESS")
                    elementPaymentModeInfo.FieldValue = address.trim();
                if (elementPaymentModeInfo.FieldName === "COUNTRY")
                    elementPaymentModeInfo.FieldValue = countryCode;
                if (elementPaymentModeInfo.FieldName === "STATE")
                    elementPaymentModeInfo.FieldValue = stateCode !== '' ? stateCode : stateName.trim();
                if (elementPaymentModeInfo.FieldName === "CITY")
                    elementPaymentModeInfo.FieldValue = cityNameInputRef.current.value.trim();
                if (elementPaymentModeInfo.FieldName === "POSTALCODE")
                    elementPaymentModeInfo.FieldValue = postalCodeInputRef.current.value.trim();
            });
        });
        //User Story 2338: Add remarks to the Sabre PNR
        if (localStorage.getItem('profileLocaterNumber') !== null && JSON.parse(localStorage.getItem('profileLocaterNumber')) !== '') {
            let text = "Sabre Profile Locator- " + JSON.parse(localStorage.getItem('profileLocaterNumber'))
            hotelBookingJSON.data.HotelMiscellaneousInfo.MiscellaneousInfo.push(passHiddenRemarksToBookingJson(text));
        }
        if (localStorage.getItem('companyName') !== null && localStorage.getItem('companyName') !== '') {
            let text = "Office- " + JSON.parse(localStorage.getItem('companyName'));
            hotelBookingJSON.data.HotelMiscellaneousInfo.MiscellaneousInfo.push(passHiddenRemarksToBookingJson(text));
        }
        if (localStorage.getItem('loginEmailID') !== null && localStorage.getItem('loginEmailID') !== '') {
            let text = "Login Email ID- " + JSON.parse(localStorage.getItem('loginEmailID'))
            hotelBookingJSON.data.HotelMiscellaneousInfo.MiscellaneousInfo.push(passHiddenRemarksToBookingJson(text));
        }
        if (localStorage.getItem('displayName') !== null && localStorage.getItem('displayName') !== '') {
            let text = "Created By- " + JSON.parse(localStorage.getItem('displayName'))
            hotelBookingJSON.data.HotelMiscellaneousInfo.MiscellaneousInfo.push(passHiddenRemarksToBookingJson(text));
        }
        let text = hotelBookingJSON.data.PaymentInfo.PaymentMode[0].Name;
        if (text !== '' || text !== undefined) {
            hotelBookingJSON.data.HotelMiscellaneousInfo.MiscellaneousInfo.push(passHiddenRemarksToBookingJson("FOP- " + text));
        }

        hotelBookingJSON.data.roundingValue = document.querySelector('#spnTotalRateTop') !== null ? document.querySelector('#spnTotalRateTop').innerHTML.includes('.') ? document.querySelector('#spnTotalRateTop').innerHTML.split('.')[1].length.toString() : "2" : "2";

        sessionStorage.setItem("HotelBookingData", JSON.stringify(hotelBookingJSON));

        if (id === '1') {
            implementRoeFunction();
            let hotelBookingJsonModified = JSON.parse(sessionStorage.getItem("HotelBookingData"));
            sendRequest(hotelBookingJsonModified);
        }
        else {
            sendRequestPriceCheck(hotelBookingJSON);
        }

        sessionStorage.setItem("HotelBookingData", JSON.stringify(hotelBookingJSON));
        //document.querySelector('#theme_search_form_tour').style.display = "none";
    }

    useEffect(() => {
        window.scrollTo({
            top: 50,
            behavior: "smooth",
        });
        if (status === 'completed') {
            if (loadedBookingResponse !== null && loadedBookingResponse.data !== null) {

                if (loadedBookingResponse.data.errors.status === 'TRUE') {
                    if (document.querySelector('#btnSendRequest') !== null) {
                        document.querySelector('#btnSendRequest').style.display = "";
                    }
                    if (document.querySelector('#btnOnBook') !== null) {
                        document.querySelector('#btnOnBook').style.display = "";
                    }

                    if (getConfigurationByBoolen("HOTEL_CREDIT_CARD") === true) {
                        onChangeCardType("1");
                    }
                    else if (profileLocaterNumber !== null && profileLocaterNumber !== '' && getConfigurationByBoolen("HOTEL_SABRE_PROFILE_CREDIT_CARD") === true) {
                        onChangeCardType("2");
                    }
                    else if (getConfigurationByBoolen("HOTEL_WALLET_PAYMENT") === true) {
                        onChangeCardType("3");
                    }

                    setConfirm({
                        title: "Message",
                        message: `Sorry, your hotel booking was unsuccessful since it could not be confirmed with the hotel.`,
                        //details: loadedBookingResponse.data.errors.error.description
                    });
                }
                else {
                    sessionStorage.setItem("HotelBookingData", JSON.stringify(loadedBookingResponse));
                    sessionStorage.setItem("myTripIsHotelCancelled", '');
                    sessionStorage.removeItem("HotelIntialTotalAmount");
                    //history.push(HOTELCONFIRMATIONS);
                    // history.go();
                    window.location.pathname = `${HOTELCONFIRMATIONS}`;
                }
            }
            else {
                if (document.querySelector('#btnSendRequest') !== null) {
                    document.querySelector('#btnSendRequest').style.display = "";
                }
                if (document.querySelector('#btnOnBook') !== null) {
                    document.querySelector('#btnOnBook').style.display = "";
                }

                if (getConfigurationByBoolen("HOTEL_CREDIT_CARD") === true) {
                    onChangeCardType("1");
                }
                else if (profileLocaterNumber !== null && profileLocaterNumber !== '' && getConfigurationByBoolen("HOTEL_SABRE_PROFILE_CREDIT_CARD") === true) {
                    onChangeCardType("2");
                }
                else if (getConfigurationByBoolen("HOTEL_WALLET_PAYMENT") === true) {
                    onChangeCardType("3");
                }

                setConfirm({
                    title: "Message",
                    message: `Sorry, your booking was unsuccessful since it could not be confirmed with the hotel.`,
                });
            }
            return;
        }
    }, [status, history, loadedBookingResponse])

    useEffect(() => {
        window.scrollTo({
            top: 50,
            behavior: "smooth",
        });
        if (statusPriceCheck === 'completed') {
            if (loadedHotelPriceCheck !== null && loadedHotelPriceCheck.data !== null) {

                if (loadedHotelPriceCheck.data.errors.status === 'TRUE') {
                    if (document.querySelector('#btnSendRequest') !== null) {
                        document.querySelector('#btnSendRequest').style.display = "";
                    }
                    if (document.querySelector('#btnOnBook') !== null) {
                        document.querySelector('#btnOnBook').style.display = "";
                    }

                    if (getConfigurationByBoolen("HOTEL_CREDIT_CARD") === true) {
                        onChangeCardType("1");
                    }
                    else if (profileLocaterNumber !== null && profileLocaterNumber !== '' && getConfigurationByBoolen("HOTEL_SABRE_PROFILE_CREDIT_CARD") === true) {
                        onChangeCardType("2");
                    }
                    else if (getConfigurationByBoolen("HOTEL_WALLET_PAYMENT") === true) {
                        onChangeCardType("3");
                    }

                    setConfirm({
                        title: "Message",
                        message: "Some Error is occured. Please try later.",//loadedHotelPriceCheck.data.errors.error.description,
                    });
                }
                else {
                    let hotelBookingJSON = JSON.parse(sessionStorage.getItem("HotelBookingData"));
                    hotelBookingJSON.data.bookingKey = loadedHotelPriceCheck.data.bookingKey;

                    if (loadedHotelPriceCheck.data.hotelResults[0].isPriceChange) {
                        hotelBookingJSON.data.hotelResults.forEach(elementHotelResults => {
                            elementHotelResults.price.forEach(elementPrice => {
                                elementPrice.roomPrice = loadedHotelPriceCheck.data.hotelResults[0].updatedPrice.roomPrice;
                                elementPrice.publishedPrice.pricePublished = loadedHotelPriceCheck.data.hotelResults[0].updatedPrice.publishedPrice.pricePublished;
                                elementPrice.publishedPrice.perNight = loadedHotelPriceCheck.data.hotelResults[0].updatedPrice.publishedPrice.perNight;
                                elementPrice.offeredPrice.priceOffered = loadedHotelPriceCheck.data.hotelResults[0].updatedPrice.offeredPrice.priceOffered;
                                elementPrice.offeredPrice.perNight = loadedHotelPriceCheck.data.hotelResults[0].updatedPrice.offeredPrice.perNight;
                            });
                        });

                        sessionStorage.setItem("HotelBookingData", JSON.stringify(hotelBookingJSON));

                        if (getConfigurationByBoolen("HOTEL_CREDIT_CARD") === true) {
                            onChangeCardType("1");
                        }
                        else if (profileLocaterNumber !== null && profileLocaterNumber !== '' && getConfigurationByBoolen("HOTEL_SABRE_PROFILE_CREDIT_CARD") === true) {
                            onChangeCardType("2");
                        }
                        else if (getConfigurationByBoolen("HOTEL_WALLET_PAYMENT") === true) {
                            onChangeCardType("3");
                        }

                        setConfirmModel({
                            title: "Message",
                            message: `The room price has changed from ${new Intl.NumberFormat('en-US', { style: 'currency', currency: fareCurrency }).format(loadedHotelPriceCheck.data.hotelResults[0].price[0].publishedPrice.pricePublished)} to ${new Intl.NumberFormat('en-US', { style: 'currency', currency: fareCurrency }).format(loadedHotelPriceCheck.data.hotelResults[0].updatedPrice.publishedPrice.pricePublished)} Do you want to proceed with the new price?`,
                        });
                    } else {
                        sessionStorage.setItem("HotelBookingData", JSON.stringify(hotelBookingJSON));
                        implementRoeFunction();
                        let hotelBookingJsonModified = JSON.parse(sessionStorage.getItem("HotelBookingData"));
                        sendRequest(hotelBookingJsonModified);
                    }
                }
            }
            else {
                if (document.querySelector('#btnSendRequest') !== null) {
                    document.querySelector('#btnSendRequest').style.display = "";
                }
                if (document.querySelector('#btnOnBook') !== null) {
                    document.querySelector('#btnOnBook').style.display = "";
                }

                if (getConfigurationByBoolen("HOTEL_CREDIT_CARD") === true) {
                    onChangeCardType("1");
                }
                else if (profileLocaterNumber !== null && profileLocaterNumber !== '' && getConfigurationByBoolen("HOTEL_SABRE_PROFILE_CREDIT_CARD") === true) {
                    onChangeCardType("2");
                }
                else if (getConfigurationByBoolen("HOTEL_WALLET_PAYMENT") === true) {
                    onChangeCardType("3");
                }

                setConfirm({
                    title: "Message",
                    message: `Some Error is occured. Please try later.`,
                });
            }
            return;
        }
    }, [statusPriceCheck, history, loadedHotelPriceCheck])

    function okConfirmHandler() {
        setConfirmModel('');
        implementRoeFunction();
        let hotelBookingJsonModified = JSON.parse(sessionStorage.getItem("HotelBookingData"));
        sendRequest(hotelBookingJsonModified);
    };

    function cancelHandler() {
        setConfirm(false);
        setConfirmModel(false);
    };

    function onBlurCheck() {
        if (cardType === '') {
            setCardTypeInputHasError(true);
            return;
        } else {
            setCardTypeInputHasError(false);
        }

        if (cardNumberInputRef.current.value.trim() === '') {
            //cardNumberInputRef.current.focus();
            setCardNumberInputHasError(true);
            setCardNumberInvalidInputError(false);
            return;
        }
        else {
            setCardNumberInputHasError(false);
        }

        // let isValid =
        //     (validateByLuhn(cardNumberInputRef.current.value.trim()) &&
        //         cardNumberInputRef.current.value.trim().length == 15 &&
        //         (cardNumberInputRef.current.value.trim().indexOf("34") == 0 || cardNumberInputRef.current.value.trim().indexOf("37") == 0)) ||
        //     (cardNumberInputRef.current.value.trim().length == 13 && cardNumberInputRef.current.value.trim()[0] == 4) ||
        //     (cardNumberInputRef.current.value.trim().length == 16 &&
        //         (cardNumberInputRef.current.value.trim()[0] == 4 ||
        //             (cardNumberInputRef.current.value.trim()[0] == 5 && cardNumberInputRef.current.value.trim()[1] >= 1 && cardNumberInputRef.current.value.trim()[1] <= 5)));

        // if (!isValid) {
        //     //cardNumberInputRef.current.focus();
        //     setCardNumberInputHasError(false);
        //     setCardNumberInvalidInputError(true);
        //     return;
        // }
        // else {
        //     setCardNumberInputHasError(false);
        //     setCardNumberInvalidInputError(false);
        // }

        var numberValidation = valid.number(cardNumberInputRef.current.value.trim());
        if (!numberValidation.isValid) {
            //cardNumberInputRef.current.focus();
            setCardNumberInputHasError(false);
            setCardNumberInvalidInputError(true);
            return;
        }
        else {
            setCardNumberInputHasError(false);
            setCardNumberInvalidInputError(false);
        }

        if (numberValidation.isValid) {
            if (numberValidation.card.niceType !== cardName) {
                //cardNumberInputRef.current.focus();
                setCardNumberInputHasError(false);
                setCardNumberInvalidInputError(true);
                return;
            }
            else {
                setCardNumberInputHasError(false);
                setCardNumberInvalidInputError(false);
            }
        }

        if (cardHolderFirstNameInputRef.current.value.trim() === '') {
            //cardHolderFirstNameInputRef.current.focus();
            setCardHolderFirstNameInputHasError(true);
            setCardHolderFirstNameInvalidInputError(false);
            return;
        }
        else {
            setCardHolderFirstNameInputHasError(false);
        }

        if (cardHolderFirstNameInputRef.current.value.trim() !== '') {
            if (!cardHolderFirstNameInputRef.current.value.match('^[a-zA-Z ]+$')) {
                //cardHolderFirstNameInputRef.current.focus();
                setCardHolderFirstNameInvalidInputError(true);
                setCardHolderFirstNameInputHasError(false);
                return;
            }
            else {
                setCardHolderFirstNameInvalidInputError(false);
            }
        }

        if (cardHolderLastNameInputRef.current.value.trim() === '') {
            //cardHolderLastNameInputRef.current.focus();
            setCardHolderLastNameInputHasError(true);
            setCardHolderLastNameInvalidInputError(false);
            return;
        }
        else {
            setCardHolderLastNameInputHasError(false);
        }

        if (cardHolderLastNameInputRef.current.value.trim() !== '') {
            if (!cardHolderLastNameInputRef.current.value.match('^[a-zA-Z ]+$')) {
                //cardHolderLastNameInputRef.current.focus();
                setCardHolderLastNameInvalidInputError(true);
                setCardHolderLastNameInputHasError(false);
                return;
            }
            else {
                setCardHolderLastNameInvalidInputError(false);
            }
        }

        if (expiryMonth === '') {
            setExpiryMonthInputHasError(true);
            return;
        }
        else {
            setExpiryMonthInputHasError(false);
        }

        if (expiryYear === '') {
            setExpiryYearInputHasError(true);
            return;
        }
        else {
            setExpiryYearInputHasError(false);
        }

        if (address.trim() === '') {
            setAddressInputHasError(true);
            setAddressInvalidInputError(false);
            return;
        }
        else {
            setAddressInputHasError(false);
        }

        if (!address.match('^[a-zA-Z 0-9/,-/\n/]+$')) {
            setAddressInvalidInputError(true);
            setAddressInputHasError(false);
            return;
        }
        else {
            setAddressInvalidInputError(false);
        }

        if (countryCode === '') {
            setCountryCodeInputHasError(true);
            return;
        }
        else {
            setCountryCodeInputHasError(false);
        }

        if (controlType !== '') {
            if (controlType === 'txt') {
                setStateCode('');
                setStateCodeInputHasError(false);
                if (stateName.trim() === '') {
                    //setStateNameInputHasError(true);
                    //return;
                }
                else {
                    setStateNameInputHasError(false);
                }
            }
            else {
                setStateName('');
                setStateNameInputHasError(false);
                if (stateCode === '') {
                    //setStateCodeInputHasError(true);
                    //return;
                }
                else {
                    setStateCodeInputHasError(false);
                }
            }
        }
        const enteredCityName = cityNameInputRef.current.value;
        if (enteredCityName.trim() === '') {
            setCityNameInputHasError(true);
            setCityNameInvalidInputError(false);
            return;
        }
        else {
            setCityNameInputHasError(false);
        }

        if (!enteredCityName.match('^[a-zA-Z. ]+$')) {
            setCityNameInvalidInputError(true);
            setCityNameInputHasError(false);
            return;
        }
        else {
            setCityNameInvalidInputError(false);
        }

        if (postalCodeInputRef.current.value.trim() === '') {
            //postalCodeInputRef.current.focus();
            setPostalCodeInputHasError(true);
            setPostalCodeInvalidInputError(false);
            return;
        }
        else {
            setPostalCodeInputHasError(false);
        }

        if (!postalCodeInputRef.current.value.match('^[a-zA-Z0-9 -]+$') || postalCodeInputRef.current.value.length < 5) {
            //postalCodeInputRef.current.focus();
            setPostalCodeInvalidInputError(true);
            setPostalCodeInputHasError(false);
            return;
        }
        else {
            if (countryCodeList.includes(countryCode)) {
                if (postalCodeInputRef.current.value.length === 5 || postalCodeInputRef.current.value.length === 9) {
                    setPostalCodeInvalidInputError(false);
                }
                else {
                    //postalCodeInputRef.current.focus();
                    setPostalCodeInvalidInputError(true);
                    setPostalCodeInputHasError(false);
                    return;
                }
            }
            else {
                setPostalCodeInvalidInputError(false);
            }
        }
    }

    function onChangeCardType(id) {
        if (document.getElementById("dvTotalPriceTop") !== null) {
            document.getElementById("dvTotalPriceTop").style.display = "block";
        }

        if (id === "2") {
            if (document.getElementById("checkCreditCard") !== null) {
                document.getElementById("checkCreditCard").checked = false;
            }
            if (document.getElementById("checkSabreCreditCard") !== null) {
                document.getElementById("checkSabreCreditCard").checked = true;
            }
            if (document.getElementById("checkWallet") !== null) {
                document.getElementById("checkWallet").checked = false;
            }
            if (document.querySelector('#btnSendRequest') !== null) {
                document.querySelector('#btnSendRequest').style.display = "";
            }
            if (document.querySelector('#btnOnBook') !== null) {
                document.querySelector('#btnOnBook').style.display = "";
            }
            if (document.getElementById("divCreditCard") !== null) {
                document.getElementById("divCreditCard").style.display = "none";
            }
            if (document.getElementById("requiredIndicatorPaymentSection") !== null) {
                document.querySelector('#requiredIndicatorPaymentSection').style.display = "none";
            }
            //USER STORY 2334: Wallet changes
            if (document.getElementById("divWallet") !== null) {
                document.getElementById("divWallet").style.display = "none";
            }
            if (document.getElementById("divWalletEror") !== null) {
                document.getElementById("divWalletEror").style.display = "none";
            }


        }
        else if (id === "3") {
            if (document.getElementById("checkCreditCard") !== null) {
                document.getElementById("checkCreditCard").checked = false;
            }
            if (document.getElementById("checkSabreCreditCard") !== null) {
                document.getElementById("checkSabreCreditCard").checked = false;
            }
            if (document.getElementById("checkWallet") !== null) {
                document.getElementById("checkWallet").checked = true;
            }
            if (document.getElementById("divCreditCard") !== null) {
                document.getElementById("divCreditCard").style.display = "none";
            }
            if (document.querySelector('#requiredIndicatorPaymentSection') !== null) {
                document.querySelector('#requiredIndicatorPaymentSection').style.display = "none";
            }
            //USER STORY 2334: Wallet changes
            if (document.getElementById("divWalletEror") !== null) {

                if (isWalletAmountIsSufficient === 'true') {
                    document.getElementById("divWalletEror").style.display = "block";
                    isWalletRadiobuttonChecked = true;
                    if (document.querySelector('#btnSendRequest') !== null) {
                        document.querySelector('#btnSendRequest').style.display = "none";
                    }
                    if (document.querySelector('#btnOnBook') !== null) {
                        document.querySelector('#btnOnBook').style.display = "none";
                    }
                } else {
                    document.getElementById("divWalletEror").style.display = "none";
                    if (document.querySelector('#btnSendRequest') !== null) {
                        document.querySelector('#btnSendRequest').style.display = "";
                    }
                    if (document.querySelector('#btnOnBook') !== null) {
                        document.querySelector('#btnOnBook').style.display = "";
                    }
                }
            }
            if (document.getElementById("divWallet") !== null) {
                if (isWalletAmountIsSufficient === 'true') {
                    document.getElementById("divWallet").style.display = "none";
                } else {
                    document.getElementById("divWallet").style.display = "block";
                    if (document.getElementById("dvTotalPriceTop") !== null) {
                        document.getElementById("dvTotalPriceTop").style.display = "none";
                    }
                }


            }


        }
        else {
            if (document.getElementById("divCreditCard") !== null) {
                document.getElementById("divCreditCard").style.display = "block";
            }
            if (document.getElementById("checkCreditCard") !== null) {
                document.getElementById("checkCreditCard").checked = true;
            }
            if (document.getElementById("checkSabreCreditCard") !== null) {
                document.getElementById("checkSabreCreditCard").checked = false;
            }
            if (document.getElementById("checkWallet") !== null) {
                document.getElementById("checkWallet").checked = false;
            }
            if (document.querySelector('#btnSendRequest') !== null) {
                document.querySelector('#btnSendRequest').style.display = "";
            }
            if (document.querySelector('#btnOnBook') !== null) {
                document.querySelector('#btnOnBook').style.display = "";
            }
            if (document.querySelector('#requiredIndicatorPaymentSection') !== null) {
                document.querySelector('#requiredIndicatorPaymentSection').style.display = "";
            }
            if (document.getElementById("divWallet") !== null) {
                document.getElementById("divWallet").style.display = "none";
            }
            if (document.getElementById("divWalletEror") !== null) {
                document.getElementById("divWalletEror").style.display = "none";
            }
        }
    }

    const exposedControlType = (controlType) => {
        setControlType(controlType);
    };

    // const cityNameText = (cityName) => {
    //     setCityName(cityName.split('(')[0].trim());
    // };

    const cardTypeChangeHandler = (event) => {
        setCardType(event.value);
        setCardName(event.label);
        setCardTypeInputHasError(false);
    };

    const expireYearChangeHandler = (event) => {
        setExpiryYear(event.value);
        setExpiryYearInputHasError(false);
    };

    const expireMonthChangeHandler = (event) => {
        setExpiryMonth(event.value);
        setExpiryMonthInputHasError(false);
    };

    function okHandler() {
        setConfirm('');
    };

    useEffect(() => {
        window.scrollTo({
            top: 880,
            behavior: "smooth",
        });
    }, [cardType, expiryMonth,
        cardNumberInputHasError, cardNumberInvalidInputError,
        cardHolderFirstNameInputHasError, cardHolderFirstNameInvalidInputError, cardHolderLastNameInputHasError,
        cardHolderLastNameInvalidInputError, expiryMonthInputHasError, expiryYearInputHasError])

    useEffect(() => {
        window.scrollTo({
            top: 1170,
            behavior: "smooth",
        });
    }, [countryCode, countryCodeInputHasError, stateCode, cityCode, cityNameInputRef,
        postalCodeInputHasError, postalCodeInvalidInputError,
        stateCodeInputHasError, stateNameInputHasError, stateName,
        stateNameInvalidInputError, cityNameInputHasError, cityNameInvalidInputError,
        address, addressInputHasError, addressInvalidInputError, expiryYear])

    const onTandcPaymentChangeHandler = (event) => {
        var inputs = document.querySelectorAll('.tandc-payment-option:checked');
        if (inputs.length === 1) {
            document.querySelector('#spnTandCPaymentMessage').innerHTML = "";
        }
    }

    function openTermAndCondtion() {
        setTermsCondition({
            title: "Message",
            message: <TermsConditionsPopup></TermsConditionsPopup>
        });
    };

    function implementRoeFunction() {
        let hotelBookingJsonRoe = JSON.parse(sessionStorage.getItem("HotelBookingData"));
        if (document.getElementById("checkWallet") !== null && document.getElementById("checkWallet").checked === true) {
            let walletCurrency = getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== null && getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== '' ? getConfigurationByValue("BASE_CURRENCY_IATA_CODE") : 'USD'; //sessionStorage.getItem("currentWalletCurrency") !== null ? sessionStorage.getItem("currentWalletCurrency") : 'USD';

            hotelBookingJsonRoe.data.hotelResults.forEach(elementHotelResults => {
                elementHotelResults.price.forEach(elementPrice => {
                    elementPrice.equivalentCurrencyCode = walletCurrency;
                    elementPrice.offeredPrice.equivalentPriceOffered = calculateRoeAmountAdditional(fareCurrency, elementPrice.offeredPrice.priceOffered, false);
                    elementPrice.equivalentAgentMarkUp = calculateRoeAmountAdditional(fareCurrency, elementPrice.agentMarkUp !== undefined && elementPrice.agentMarkUp !== null && elementPrice.agentMarkUp !== '' ? elementPrice.agentMarkUp : '0', false);
                    elementPrice.equivalentMarkUp = calculateRoeAmountAdditional(fareCurrency, elementPrice.markUp !== undefined && elementPrice.markUp !== null && elementPrice.markUp !== '' ? elementPrice.markUp : '0', false);
                });

                elementHotelResults.roomRateDetails.forEach(elementRoomRateDetails => {
                    elementRoomRateDetails.price.equivalentCurrencyCode = walletCurrency;
                    elementRoomRateDetails.price.offeredPrice.equivalentPriceOffered = calculateRoeAmountAdditional(fareCurrency, elementRoomRateDetails.price.offeredPrice.priceOffered, false);

                    elementRoomRateDetails.price.taxes !== undefined && elementRoomRateDetails.price.taxes.tax !== undefined && elementRoomRateDetails.price.taxes.tax.forEach(elementTax => {
                        elementTax.equivalentCurrencyCode = walletCurrency;
                        elementTax.equivalentAmount = parseFloat(calculateRoeAmountAdditional(fareCurrency, elementTax.amount, false));
                    });

                    elementRoomRateDetails.price.fees !== undefined && elementRoomRateDetails.price.fees.forEach(elementFees => {
                        elementFees.equivalentCurrencyCode = walletCurrency;
                        elementFees.equivalentAmount = parseFloat(calculateRoeAmountAdditional(fareCurrency, elementFees.amount, false));
                    });
                });
            });

            hotelBookingJsonRoe.data.PaymentInfo.PaymentMode[0].EquivalentAmount = calculateRoeAmountAdditional(fareCurrency, hotelBookingJsonRoe.data.PaymentInfo.PaymentMode[0].Amount, false);
            hotelBookingJsonRoe.data.PaymentInfo.PaymentMode[0].ConversionRate = calculateRoeAmountAdditional(fareCurrency, hotelBookingJsonRoe.data.PaymentInfo.PaymentMode[0].Amount, true).toString();
        }
        else {
            let walletCurrency = getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== null && getConfigurationByValue("BASE_CURRENCY_IATA_CODE") !== '' ? getConfigurationByValue("BASE_CURRENCY_IATA_CODE") : 'USD'; //sessionStorage.getItem("currentWalletCurrency") !== null ? sessionStorage.getItem("currentWalletCurrency") : 'USD';

            hotelBookingJsonRoe.data.hotelResults.forEach(elementHotelResults => {
                elementHotelResults.price.forEach(elementPrice => {
                    elementPrice.equivalentCurrencyCode = walletCurrency;
                    elementPrice.offeredPrice.equivalentPriceOffered = calculateRoeAmountAdditional(fareCurrency, elementPrice.offeredPrice.priceOffered, false);
                    elementPrice.equivalentAgentMarkUp = calculateRoeAmountAdditional(fareCurrency, elementPrice.agentMarkUp !== undefined && elementPrice.agentMarkUp !== null && elementPrice.agentMarkUp !== '' ? elementPrice.agentMarkUp : '0', false);
                    elementPrice.equivalentMarkUp = calculateRoeAmountAdditional(fareCurrency, elementPrice.markUp !== undefined && elementPrice.markUp !== null && elementPrice.markUp !== '' ? elementPrice.markUp : '0', false);
                });

                elementHotelResults.roomRateDetails.forEach(elementRoomRateDetails => {
                    elementRoomRateDetails.price.equivalentCurrencyCode = walletCurrency;
                    elementRoomRateDetails.price.offeredPrice.equivalentPriceOffered = calculateRoeAmountAdditional(fareCurrency, elementRoomRateDetails.price.offeredPrice.priceOffered, false);

                    elementRoomRateDetails.price.taxes !== undefined && elementRoomRateDetails.price.taxes.tax !== undefined && elementRoomRateDetails.price.taxes.tax.forEach(elementTax => {
                        elementTax.equivalentCurrencyCode = walletCurrency;
                        elementTax.equivalentAmount = parseFloat(calculateRoeAmountAdditional(fareCurrency, elementTax.amount, false));
                    });

                    elementRoomRateDetails.price.fees !== undefined && elementRoomRateDetails.price.fees.forEach(elementFees => {
                        elementFees.equivalentCurrencyCode = walletCurrency;
                        elementFees.equivalentAmount = parseFloat(calculateRoeAmountAdditional(fareCurrency, elementFees.amount, false));
                    });
                });
            });

            hotelBookingJsonRoe.data.PaymentInfo.PaymentMode[0].EquivalentAmount = calculateRoeAmountAdditional(fareCurrency, hotelBookingJsonRoe.data.PaymentInfo.PaymentMode[0].Amount, false);
            hotelBookingJsonRoe.data.PaymentInfo.PaymentMode[0].ConversionRate = calculateRoeAmountAdditional(fareCurrency, hotelBookingJsonRoe.data.PaymentInfo.PaymentMode[0].Amount, true).toString();
        }
        sessionStorage.setItem("HotelBookingData", JSON.stringify(hotelBookingJsonRoe));
    }

    for (let index in loadedRoe) {
        if (loadedRoe['errors'].status === 'FALSE') {
            if (index === 'data') {
                secureLocalStorage.setItem('searchRoeResult', JSON.stringify(loadedRoe[index]));
            }
        }
    }

    return <Fragment>
        {error1 && (
            <MdModal
                title={error1.title}
                message={error1.message}
                onConfirm={errorHandler}
            ></MdModal>
        )}
        {confirm && (
            <ErrorModal
                title={confirm.title}
                message={confirm.message}
                onConfirm={okHandler}
                details={confirm.details}

            ></ErrorModal>
        )}
        {confirmModel && (
            <ConfirmationModal
                title={confirmModel.title}
                message={confirmModel.message}
                onOk={okConfirmHandler}
                onCancel={cancelHandler}
            ></ConfirmationModal>
        )}
        {termsCondition && (
            <TermsConditionsPopup
                title={termsCondition.title}
                message={termsCondition.message}
                onConfirm={errorHandler}

            ></TermsConditionsPopup>
        )}
        <section id="common_banner">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                    </div>
                </div>
            </div>
        </section>
        {(status === 'pending' || statusPriceCheck === 'pending') &&
            <section id="theme_search_form_tour1" className="fligth_top_search_main_form_wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="theme_search_form_area mt-5">
                                <div className="row  position-relative gt-h-255 my-auto">
                                    {
                                        hotelBookingJSON.data.BookingType === "OF" &&
                                        <LoadingHotel action='requestTypeOf' ></LoadingHotel>
                                    }
                                    {
                                        hotelBookingJSON.data.BookingType === "ON" &&
                                        <LoadingHotel action='booking' ></LoadingHotel>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        }
        {
            (status !== 'pending' && statusPriceCheck !== 'pending') &&
            <section id="theme_search_form_tour" className='mb-2'>
                <div className="container">
                    <div className="theme_search_form_area mb-2">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="col-md-12"><label for="" className="h5 text-primary mb-4"><i className="fas fa-globe-asia"></i> Hotel Summary</label></div>
                                <div className="tour_search_form">
                                    <div className="row">
                                        <div className="col-lg-12 position-relative">
                                            <div className="row div-reverse bbb">
                                                <div className="col-lg-9 col-md-12 col-sm-12 col-12">
                                                    <div className="col-md-12 col-md-push-3 tour_details_boxed_inner">
                                                        <HotelItem
                                                            key={1}
                                                            id={1}
                                                            echoToken={hotelBookingJSON.data.RequestDetails.echoToken}
                                                            hotelResults={hotelBookingJSON.data.hotelResults[0]}
                                                        />
                                                        <hr className=''></hr>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                                                    <BookingDetails guestInfo={hotelBookingJSON.data}></BookingDetails>

                                                </div>
                                                <div className='col-md-12'>
                                                    <div class="tour_details_boxed_inner mobile-toggle-btn">
                                                        <div className="accordion" id="accordionExampleNine">
                                                            <div className="accordion_flex_area  mt-2  ">

                                                                <div className="accordion-item col-md-12">
                                                                    <h2 className="accordion-header" id="headingNine">
                                                                        <button id='btnPriceSummaryCollapse' className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                                            data-bs-target="#PriceSummaryCollapse" aria-expanded="true"
                                                                            aria-controls="PriceSummaryCollapse"> <span><i class="fas fa-money-check-alt me-2"></i>
                                                                                {
                                                                                    localStorage.getItem("AddHotel") !== null && localStorage.getItem("AddHotel") === "1" &&
                                                                                    <span>Hotel Price Summary</span>
                                                                                }
                                                                                {
                                                                                    localStorage.getItem("AddHotel") === null &&
                                                                                    <span>Price Summary</span>
                                                                                }
                                                                            </span>
                                                                        </button>
                                                                    </h2>
                                                                    <div id="PriceSummaryCollapse" className="accordion-collapse collapse "
                                                                        aria-labelledby="headingNine" data-bs-parent="#accordionExampleNine">
                                                                        <div className="col-md-12">
                                                                            <Pricesummary guestInfo={hotelBookingJSON.data}></Pricesummary>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                                <div className='col-md-12'>
                                                    <div className="tour_details_boxed_inner">
                                                        <div className="accordion" id="accordionExample">
                                                            <GuestDetails guestInfo={hotelBookingJSON.data} onContinueButton={onContinueButton}></GuestDetails>
                                                            <ContactPerson guestInfo={hotelBookingJSON.data} onContinueButton={onContinueButton}></ContactPerson>
                                                            <AdditionalServices guestInfo={hotelBookingJSON.data} onContinueButton={onContinueButton}></AdditionalServices>
                                                            {
                                                                getConfigurationByBoolen("ALLOW_SERVICE_FEE") &&
                                                                <AddMarkup onContinueButton={onContinueButton}></AddMarkup>
                                                            }
                                                        </div>
                                                        <div className="accordion_flex_area">
                                                            <div className="accordion_left_side text-muted">
                                                                <h5>{getConfigurationByBoolen("ALLOW_SERVICE_FEE") === true ? '5' : '4'}</h5>
                                                            </div>
                                                            <div className="accordion-item">
                                                                <h2 className="accordion-header">
                                                                    <button id="headingFive" className="text-muted accordion-button collapsed" type="button"
                                                                        data-bs-target="#collapseFive"
                                                                        aria-expanded="false" aria-controls="collapseFive">
                                                                        Payment
                                                                    </button>
                                                                </h2>

                                                                <div id="collapseFive" className="accordion-collapse collapse"
                                                                    aria-labelledby="headingfour" data-bs-parent="#accordionExample">

                                                                    <div className="payment_filed_wrapper">
                                                                        <div className='row my-2'>
                                                                            {getConfigurationByBoolen("HOTEL_CREDIT_CARD") === true &&
                                                                                <div className="col-lg-4 col-md-6 col-sm-6  text-muted">
                                                                                    <div className="form-check" onClick={() =>
                                                                                        onChangeCardType("1")}>
                                                                                        <input className="form-check-input" type="radio" onClick={() =>
                                                                                            onChangeCardType("1")} name="flexRadioDefault" id="checkCreditCard" value="white" />
                                                                                        <label className="form-check-label" for="checkCreditCard">
                                                                                            Specify Credit Card
                                                                                        </label>
                                                                                    </div>
                                                                                </div>}
                                                                            {profileLocaterNumber !== null && profileLocaterNumber !== '' && getConfigurationByBoolen("HOTEL_SABRE_PROFILE_CREDIT_CARD") === true ? <div className="col-lg-5 col-md-6 col-sm-6  text-muted" >
                                                                                <div className="form-check" onClick={() =>
                                                                                    onChangeCardType("2")}>
                                                                                    <input className="form-check-input" type="radio" onClick={() =>
                                                                                        onChangeCardType("2")} name="flexRadioDefault" id="checkSabreCreditCard" value="white" />
                                                                                    <label className="form-check-label" for="checkSabreCreditCard">
                                                                                        Sabre Profile Credit Card
                                                                                    </label>
                                                                                </div>
                                                                            </div> : <span id="checkSabreCreditCard"></span>}
                                                                            {getConfigurationByBoolen("HOTEL_WALLET_PAYMENT") && isWalletActive === 'true' ? <div className="col-lg-2 col-md-6 col-sm-6  text-muted" >
                                                                                <div className="form-check" onClick={() =>
                                                                                    onChangeCardType("3")}>
                                                                                    <input className="form-check-input" type="radio" onClick={() =>
                                                                                        onChangeCardType("3")} name="flexRadioDefault" id="checkWallet" value="white" />
                                                                                    <label className="form-check-label" for="checkWallet">
                                                                                        Wallet
                                                                                    </label>
                                                                                </div>
                                                                            </div> : <span id="checkWallet"></span>}
                                                                        </div>
                                                                        <div id='dvTotalPriceTop' className="col-lg-12 my-3" >
                                                                            <div className="col-md-12 h5 text-muted"> Price Summary</div>
                                                                            <div className="row">
                                                                                <div className="h6 text-primary mb-2 col-md-3">  Base Rate:</div>
                                                                                <div className="col-md-4 text-end h6"><span id="spnBaseRateTop" className="text-black">
                                                                                    {new Intl.NumberFormat('en-US', {
                                                                                        style: 'currency',
                                                                                        currency: hotelBookingJSON.data.hotelResults[0].price[0].currencyCode,
                                                                                    }).format(hotelBookingJSON.data.hotelResults[0].price[0].publishedPrice.pricePublished)}
                                                                                </span></div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="h6 text-primary mb-2 col-md-3">  Taxes & Fees: </div>
                                                                                <div className="col-md-4 text-end h6"><span id="spnTaxesTop" className="text-black">
                                                                                    {new Intl.NumberFormat('en-US', {
                                                                                        style: 'currency',
                                                                                        currency: hotelBookingJSON.data.hotelResults[0].price[0].currencyCode,
                                                                                    }).format(totalTax)}
                                                                                </span></div>
                                                                            </div>
                                                                            {
                                                                                getConfigurationByBoolen("ALLOW_SERVICE_FEE") && localStorage.getItem('isCorporate') !== 'true' &&
                                                                                <div className="row">
                                                                                    <div className="h6 text-primary mb-2 col-md-3">  Service Fee: </div>
                                                                                    <div className="col-md-4 text-end h6"><span id="spnServiceFeeHotelTop" className="text-black">
                                                                                        {new Intl.NumberFormat('en-US', {
                                                                                            style: 'currency',
                                                                                            currency: hotelBookingJSON.data.hotelResults[0].price[0].currencyCode,
                                                                                        }).format(0.00)}
                                                                                    </span></div>
                                                                                </div>
                                                                            }

                                                                            <div className="row">
                                                                                <div className="col-md-7">
                                                                                    <hr className="my-1">
                                                                                    </hr>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="h6 text-primary mb-2 col-md-3">  Total Rate: </div>
                                                                                <div className="col-md-4 text-end h6"><span id="spnTotalRateTop" className="text-black">
                                                                                    {new Intl.NumberFormat('en-US', {
                                                                                        style: 'currency',
                                                                                        currency: hotelBookingJSON.data.hotelResults[0].price[0].currencyCode,
                                                                                    }).format(hotelBookingJSON.data.hotelResults[0].price[0].offeredPrice.priceOffered)}
                                                                                </span></div>
                                                                            </div>
                                                                            {
                                                                                hotelBookingJSON.data.hotelResults[0].price[0].currencyCode !== undefined && hotelBookingJSON.data.hotelResults[0].price[0].currencyCode !== '' && hotelBookingJSON.data.hotelResults[0].price[0].currencyCode.toString().toUpperCase() !== currentWalletCurrency.toString().toUpperCase() &&
                                                                                <div className="row">
                                                                                    <div className="h6 text-primary mb-2 col-md-3">  Eq. Rate: </div>
                                                                                    <div className="col-md-4 text-end h6"><span id="spnEqRateTop" className="text-black">
                                                                                        {new Intl.NumberFormat('en-US', {
                                                                                            minimumFractionDigits: parseInt(getConfigurationByValue("DECIMAL_PLACES") !== null && getConfigurationByValue("DECIMAL_PLACES") !== '' ? getConfigurationByValue("DECIMAL_PLACES") : '2'),
                                                                                            style: 'currency',
                                                                                            currency: currentWalletCurrency !== undefined && currentWalletCurrency !== null && currentWalletCurrency !== '' ? currentWalletCurrency : 'USD',
                                                                                        }).format(calculateRoeAmountAdditional(fareCurrency, totalPrice, false))}
                                                                                    </span></div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        <div style={{ display: "block" }} id="divCreditCard">
                                                                            <div className="row"  >
                                                                                <div className="col-lg-6">
                                                                                    <div className='control'>
                                                                                        <div className="form-group">
                                                                                            <label className='h6 text-primary mb-2' htmlFor='cardType'> Card Type<span className="error-icon"> *</span></label>
                                                                                            <Dropdown
                                                                                                placeholder="Please Select"
                                                                                                onChange={cardTypeChangeHandler}
                                                                                                options={creditCardOptions}
                                                                                                //value={cardType!==""?card_list.filter(option => option.value === cardType):''}

                                                                                                arrowClosed={<span className="arrow-closed" />}
                                                                                                arrowOpen={<span className="arrow-open" />}
                                                                                                controlClassName='form-control control form-select bg_input mb-0'
                                                                                            />
                                                                                            {cardTypeInputHasError &&
                                                                                                <p className="error-text">{t('validationCardType')}</p>
                                                                                            }

                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                                <div className="col-lg-6">
                                                                                    <div className='control'>
                                                                                        <div className="form-group">
                                                                                            <label className='h6 text-primary mb-2' htmlFor='Card Number'> Card Number<span className="error-icon"> *</span></label>

                                                                                            <input maxLength={16} className="form-control bg_input" type='password' autoComplete='new-password' required
                                                                                                id='cardNumber'
                                                                                                ref={cardNumberInputRef}
                                                                                                onBlur={onBlurCheck}
                                                                                            />
                                                                                            {cardNumberInputHasError &&
                                                                                                <p className="error-text">{t('validationCardNumber')}</p>
                                                                                            }
                                                                                            {cardNumberInvalidInputError &&
                                                                                                <p className="error-text">{t('validationCardNumberInvalidData')}</p>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                                                                                    <div className='control'>
                                                                                        <div className="form-group">
                                                                                            <label className='h6 text-primary mb-2' htmlFor='cardHolderFirstName'>First Name<span className="error-icon"> *</span></label>
                                                                                            <input maxLength={30} className="form-control bg_input" type='text' required
                                                                                                id='cardHolderFirstName'
                                                                                                ref={cardHolderFirstNameInputRef}
                                                                                                onBlur={onBlurCheck}
                                                                                            />
                                                                                            {cardHolderFirstNameInputHasError &&
                                                                                                <p className="error-text">{t('validationCardHolderFirstName')}</p>
                                                                                            }
                                                                                            {cardHolderFirstNameInvalidInputError &&
                                                                                                <p className="error-text">{t('validationCardHolderFirstNameInvalidData')}</p>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                                                                                    <div className='control'>
                                                                                        <div className="form-group">
                                                                                            <label className='h6 text-primary mb-2' htmlFor='cardHolderName'>Last Name<span className="error-icon"> *</span></label>
                                                                                            <input maxLength={30} className="form-control bg_input" type='text' required
                                                                                                id='cardHolderLastName'
                                                                                                ref={cardHolderLastNameInputRef}
                                                                                                onBlur={onBlurCheck}
                                                                                            />
                                                                                            {cardHolderLastNameInputHasError &&
                                                                                                <p className="error-text">{t('validationCardHolderLastName')}</p>
                                                                                            }
                                                                                            {cardHolderLastNameInvalidInputError &&
                                                                                                <p className="error-text">{t('validationCardHolderLastNameInvalidData')}</p>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-6">
                                                                                    <div className='control'>
                                                                                        <div className="form-group">
                                                                                            <label className='h6 text-primary mb-2' htmlFor='dateOfExpiry'> Date of Expiry<span className="error-icon"> *</span></label>
                                                                                            <div className='row'>

                                                                                                <div className='col-md-6'>
                                                                                                    <Dropdown
                                                                                                        placeholder="Month"
                                                                                                        onChange={expireMonthChangeHandler}
                                                                                                        options={monthList}
                                                                                                        //value={expiryMonth!==''?monthList.filter(option => option.value === expiryMonth):''}

                                                                                                        arrowClosed={<span className="arrow-closed" />}
                                                                                                        arrowOpen={<span className="arrow-open" />}
                                                                                                        controlClassName='form-control control form-select bg_input mb-0'
                                                                                                    />
                                                                                                    {expiryMonthInputHasError &&
                                                                                                        <p className="error-text">{t('validationDateofExpiry')}</p>
                                                                                                    }
                                                                                                </div>
                                                                                                <div className='col-md-6 margin-top-15'>
                                                                                                    <Dropdown
                                                                                                        placeholder="Year"
                                                                                                        onChange={expireYearChangeHandler}
                                                                                                        options={expiryYearList}
                                                                                                        //value={expiryYearList.filter(option => option.value === expiryYear)}

                                                                                                        arrowClosed={<span className="arrow-closed" />}
                                                                                                        arrowOpen={<span className="arrow-open" />}
                                                                                                        controlClassName='form-control control form-select bg_input mb-0'
                                                                                                    />
                                                                                                    {expiryYearInputHasError &&
                                                                                                        <p className="error-text">{t('validationDateofExpiry')}</p>
                                                                                                    }
                                                                                                </div>

                                                                                            </div>

                                                                                            {dateofExpiryInputHasError &&
                                                                                                <p className="error-text">{t('validationDateofExpiry')}</p>
                                                                                            }
                                                                                            {dateofExpiryInvalidInputError &&
                                                                                                <p className="error-text">{t('validationDateofExpiryInvalidData')}</p>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                            <div className='row'>
                                                                                <div className='control input-placeholder'>
                                                                                    <label htmlFor='address' className='h6 text-primary mb-2'>Street Address<span className="error-icon"> *</span></label>
                                                                                    <textarea id='address'
                                                                                        value={address}
                                                                                        className="form-control form-control-sm"
                                                                                        onChange={(e) => setAddress(e.target.value)}
                                                                                        rows={2} cols={100} maxLength={300}
                                                                                        onBlur={onBlurCheck}
                                                                                        ref={addressInputRef}
                                                                                    />
                                                                                </div>
                                                                                {addressInputHasError &&
                                                                                    <span className="error-text col-lg-12 mt-3">{t('validationAddress')}</span>
                                                                                }
                                                                                {/* {addressInvalidInputError && address.substring(0, 1).match('[0-9]') &&
                                                                                <span className="error-text col-lg-12 mt-3">{t('validationAddressStartWithNumberInvalidData')}</span>
                                                                            } */}
                                                                                {addressInvalidInputError &&
                                                                                    <span className="error-text col-lg-12 mt-3">{t('validationAddressInvalidData')}</span>
                                                                                }
                                                                            </div>
                                                                            <div className='row mt-4'>
                                                                                <div className='col-md-6'>
                                                                                    <div className='control'>
                                                                                        <label id='lblCountry ' className='h6 text-primary mb-2' htmlFor='Country'>{t('country')}<span className="error-icon"> *</span></label>
                                                                                        <CountryDropDownList countryCodeValue={countryCodeValue}
                                                                                            countryNameText={countryNameText}
                                                                                            exposedControlType={exposedControlType}
                                                                                            currentInputValue={countryCode} />
                                                                                        {countryCodeInputHasError &&
                                                                                            <p className="error-text">{t('validationCountryCode')}</p>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-md-6'>
                                                                                    <div className='control'>
                                                                                        <label className="h6 text-primary mb-2 col-lg-12" htmlFor='state'>{t('state')}<span className="error-icon"> *</span></label>
                                                                                        <StateDropDownList stateCodeValue={stateCodeValue}
                                                                                            stateNameText={stateNameText}
                                                                                            currentInputValue={stateCode}
                                                                                            currentInputText={stateName}
                                                                                            exposedControlType={exposedControlType}
                                                                                            countryCode={countryCode} />
                                                                                        {stateCodeInputHasError &&
                                                                                            <p className="error-text">{t('validationStateCode')}</p>
                                                                                        }
                                                                                        {stateNameInputHasError &&
                                                                                            <p className="error-text">{t('validationStateName')}</p>
                                                                                        }
                                                                                        {stateNameInvalidInputError &&
                                                                                            <p className="error-text">{t('validationStateNameInvalidData')}</p>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                {/*Task 4949: Change city dropdown and textbox on flight, single PNR and hotel booking.*/}
                                                                                <div className='col-md-6'>
                                                                                    <div className='control'>
                                                                                        <label className="h6 text-primary mb-2 col-lg-12" htmlFor='city'>{t('city')}<span className="error-icon"> *</span></label>
                                                                                        <input maxLength={100} className="form-control form-control-sm" type='text'
                                                                                            id='cityName'
                                                                                            ref={cityNameInputRef}
                                                                                            onBlur={onBlurCheck}

                                                                                        />
                                                                                        {cityNameInputHasError &&
                                                                                            <p className="error-text">{t('validationCityName')}</p>
                                                                                        }
                                                                                        {cityNameInvalidInputError &&
                                                                                            <p className="error-text">{t('validationCityNameInvalidData')}</p>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-md-6 col-sm-12'>
                                                                                    <div className='control input-placeholder'>
                                                                                        <label htmlFor='postalCode' className='h6 text-primary mb-2'>{t('postalCode')}<span className="error-icon"> *</span></label>
                                                                                        <input maxLength={12} className="form-control form-control-sm" type='text' required
                                                                                            id='postalCode'
                                                                                            ref={postalCodeInputRef}
                                                                                            onChange={onBlurCheck}
                                                                                        />
                                                                                        {postalCodeInputHasError &&
                                                                                            <p className="error-text">{t('validationPostalCode')}</p>
                                                                                        }
                                                                                        {postalCodeInvalidInputError &&
                                                                                            <p className="error-text">{t('validationPostalCodeInvalidData')}</p>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div style={{ display: "block" }} id="divWallet" className='mt-4'>
                                                                            <div className="col-lg-12">
                                                                                <div className='h6 text-primary ' ><i className="fa-solid fa-circle text-12px"></i> Current Wallet Amount: <span className="text-black">{new Intl.NumberFormat('en-US', {
                                                                                    minimumFractionDigits: parseInt(getConfigurationByValue("DECIMAL_PLACES") !== null && getConfigurationByValue("DECIMAL_PLACES") !== '' ? getConfigurationByValue("DECIMAL_PLACES") : '2'),
                                                                                    style: 'currency',
                                                                                    currency: currentWalletCurrency !== undefined && currentWalletCurrency !== null && currentWalletCurrency !== '' ? currentWalletCurrency : 'USD',
                                                                                }).format(currentWalletAmount !== undefined && currentWalletAmount !== null && currentWalletAmount !== '' ? currentWalletAmount : '0')}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-12">
                                                                                <div className='h6 text-primary mb-2' > <i className="fa-solid fa-circle text-12px"></i> Total Price: <span className="text-black">{new Intl.NumberFormat('en-US', {
                                                                                    minimumFractionDigits: parseInt(getConfigurationByValue("DECIMAL_PLACES") !== null && getConfigurationByValue("DECIMAL_PLACES") !== '' ? getConfigurationByValue("DECIMAL_PLACES") : '2'),
                                                                                    style: 'currency',
                                                                                    currency: currentWalletCurrency !== undefined && currentWalletCurrency !== null && currentWalletCurrency !== '' ? currentWalletCurrency : 'USD',
                                                                                }).format(calculateRoeAmountAdditional(fareCurrency, totalPrice, false))}</span></div>
                                                                            </div>
                                                                            <div className="col-lg-12">
                                                                                <div className='h6 text-primary mb-2' ><i className="fa-solid fa-circle text-12px"></i>  Remaining Wallet Amount: <span className="text-black">{new Intl.NumberFormat('en-US', {
                                                                                    minimumFractionDigits: parseInt(getConfigurationByValue("DECIMAL_PLACES") !== null && getConfigurationByValue("DECIMAL_PLACES") !== '' ? getConfigurationByValue("DECIMAL_PLACES") : '2'),
                                                                                    style: 'currency',
                                                                                    currency: currentWalletCurrency !== undefined && currentWalletCurrency !== null && currentWalletCurrency !== '' ? currentWalletCurrency : 'USD',
                                                                                }).format(parseFloat(RemainingWalletAmount))}</span></div>
                                                                            </div>
                                                                        </div>
                                                                        <div style={{ display: "block" }} id="divWalletEror" className='mt-4'>
                                                                            <div className="col-lg-12">
                                                                                <p className="error-text">{t('inSufficientWalletBalance')}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className='row'>
                                                                            <div className="col-lg-5 col-md-8 col-sm-8 my-auto">
                                                                                <br></br>
                                                                                <p id='requiredIndicatorPaymentSection'><span className="error-icon">*</span><span id="requiredIndicator">Indicates required fields.</span></p>
                                                                            </div>
                                                                            <div className='d-inline-flex'>
                                                                                <div className="me-3" style={{ width: "2%" }}> <input className="form-check-input tandc-payment-option" type="checkbox" id="chkTandcPayment" onChange={(e) => onTandcPaymentChangeHandler(e)}></input></div>
                                                                                <div className="" style={{ width: "98%" }}>  <span>I have read and agree to the fare rules and the</span>
                                                                                    <span><a className="footer_link_area cursor-pointer" onClick={openTermAndCondtion}> terms and conditions.</a></span>
                                                                                </div>
                                                                            </div>
                                                                            <span className='error-text mt-2' id='spnTandCPaymentMessage'></span>
                                                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                                                <div className="float-end text-end">

                                                                                    <br></br>
                                                                                    {checkSecurityDetails("Book", "Hotel", "Book_Hotel_Book") && getConfigurationByBoolen("HOTEL_ALLOW_BOOKING_ITINERARY") ? <button type="button" className="btn  text-white gt-main-btn ms-1 btn-sm margin-top-15" id='btnSendRequest' onClick={SendRequest}>
                                                                                        Send Request
                                                                                    </button> : <span id='btnSendRequest'></span>}


                                                                                    {checkSecurityDetails("Book", "Hotel", "Book_Hotel_Book") && (getConfigurationByBoolen("HOTEL_ALLOW_BOOKING") === true
                                                                                        && allowBooking === true)
                                                                                        ? <button type="button" className="btn  text-white gt-main-btn ms-1 btn-sm margin-top-15" id='btnOnBook' onClick={OnBook}>

                                                                                            Book
                                                                                        </button> : <sapn id='btnOnBook'></sapn>}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        }

    </Fragment>
};

export default Booking;