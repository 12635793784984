import { useState, Fragment, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import LoadingSpinner from '../../../../components/Common/Loading/LoadingSpinner'
import ErrorModal from "../../../../components/Common/Error/ErrorModal";
import useHttp from "../../../../services/use-http";
import { deleteSingleApprover } from '../../../../services//Approver-API'
import ApproverItem from "./ApproverItem";
import { useTranslation } from "react-i18next";
import { sortData } from '../../../../utils';


const ApproverList = (props) => {
    const { t } = useTranslation(['translation.Approver']);
    const history = useHistory();
    const location = useLocation();

    const [error1, setError] = useState();

    const queryParams = new URLSearchParams(location.search);

    const isSortingAscending = queryParams.get('sort') === 'asc';

    const sortedApprover = sortData([...props.approver], 'corporateMasterName', isSortingAscending);

    const changeSortingHandler = () => {
        history.push({
            pathname: location.pathname,
            search: `?sort=${(isSortingAscending ? 'desc' : 'asc')}`
        })
    };

    //Code for delete
    const { sendRequest, status, data: loadedQuote, error } = useHttp(deleteSingleApprover, true);
    const [isErrorOnDelete, setIsErrorOnDelete] = useState(false);

    useEffect(() => {
        //labelName= getConfigurationByValue(getConfigurationByValue("APPROVER_TYPE"))
        window.scrollTo({
            top: 200,
            behavior: "smooth",
        });

        history.push({
            pathname: '/SearchApprover',
            search: `?sort=${('asc')}`
        })

        if (status === 'pending') {
            <Fragment>
                <section id='common_banner'><div className='container'></div></section>
                <div className='centered'>
                    <LoadingSpinner></LoadingSpinner>
                </div>
            </Fragment>
        }
        if (status === 'completed') {
            if (loadedQuote['errors'].status === 'TRUE') {
                if (loadedQuote['errors'].error['code'] === '1010') {
                    setError({
                        title: "Message",
                        message: `${t('errorRecordInUseDelete')}`,
                    });

                }
                else {
                    setError({
                        title: "Message",
                        message: `${t('errorRecordDelete')}`,
                    });
                }
                setIsErrorOnDelete(true);
            } else {
                setError({
                    title: "Message",
                    message: `${t('recordDelete')}`,
                });


            }
        }

    }, [status, history, t, loadedQuote])

    const deleteItemsHandler = (props) => {
        const onDeleteApprover = ({
            "approvalMasterID": props.approvalMasterID,
            "serviceTypeID": props.serviceTypeID
        });
        sendRequest(onDeleteApprover);

        if (error) {
            return <p className='centered focused'>{error}</p>
        }
        if ((!loadedQuote || loadedQuote.length === 0)) {
            return <p>{t('errorRecordDelete')}</p>
        }
    };
    //End code for delete


    const errorHandler = () => {
        setError(null);
        if (!isErrorOnDelete) {
            console.log("deleted");
            history.go({
                pathname: '/SearchApprover'
            })
        }
        else {
            history.push({
                pathname: '/SearchApprover'
            })
        }
    };

    function getKey(approver) {
        let key = approver.corporateMasterID;
        if (approver.serviceTypeID && approver.serviceTypeID.length) {
            key += approver.serviceTypeID;
        }

        return key;
    }

    return <Fragment>
        {error1 && (
            <ErrorModal
                title={error1.title}
                message={error1.message}
                onConfirm={errorHandler}
            ></ErrorModal>
        )}

        <div className="container-fluid mb-3 list-view">
            <div className="row">
                <div className="col-lg-2"></div>
                <div className="col-lg-8 mb-2" style={{ margintop: "20px" }}>
                    <div className='row mb-3'>
                        <div className="col-md-6" >

                        </div>
                        <div className='col-md-2 my-auto text-muted text-left'>
                            <div className="float-lg-end float-md-end">Sort By</div>
                        </div>
                        <div className="col-md-4 form-group">
                            {/* <span className="text-muted">Sort By</span> */}
                            <select id="sortBy" onChange={changeSortingHandler} className="form-control form-select bg_input">
                                <option>{props.labelNameDetails + " (A to Z)"}</option>
                                <option>{props.labelNameDetails + " (Z to A)"}</option>

                            </select>
                        </div>
                    </div>
                </div>
            </div>
            {sortedApprover.map((approver) => (
                <ApproverItem
                    onDelete={deleteItemsHandler}
                    key={getKey(approver)}
                    approvalMasterID={approver.approvalMasterID}
                    id={approver.corporateMasterID}
                    corporateMasterID={approver.corporateMasterID}
                    corporateMasterName={approver.corporateMasterName}
                    approvalMasterName={approver.approvalMasterName}
                    serviceTypeID={approver.serviceTypeID}
                    serviceTypeName={approver.serviceTypeName}
                    keyCode={approver.keyCode}

                />
            ))}
        </div>
    </Fragment>
};

export default ApproverList;