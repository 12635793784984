import React from "react";
import { Fragment ,useEffect} from "react";

const Unauthorized = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
     
    },)
  return (   

     <Fragment>
        <section className="authorized-bg">
            <div className="container py-5">
                <div className="row">
                    <div className="col-lg-6">
                        <div className=" mt-5 gt-h-530">
                            <img src="assets/img/unauthorized.png" alt="img"  className="img-responsive p-5"/>
                        </div>
                    </div>
                    <div className="col-lg-6 my-auto">
                        <div className=" text-end ">
                            <div className="title text-center">
                                <h1 className="text-white h1 my-3"><i className="fas fa- fa-times-circle border-radius50 bg-white text-danger"></i> Unauthorized Access</h1>
                                <div className="h6 text-white"></div>
                                <div className="text-white">
                                    {/* You are not authorized to access this page. */}
                                    You are not authorized to view this page. Please contact your administrator to allow access.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>      
       
    </Fragment>

  );
};
export default Unauthorized;
