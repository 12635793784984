

import { Fragment, useState } from 'react';
import { HotelDetailsJSON } from './../../../utils/HotelDetails'
import { useHistory } from 'react-router-dom';
import { Tooltip as ReactTooltip } from "react-tooltip";
import MdModal from '../MdModal';
import { getConfigurationByBoolen } from '../../../utils/ConfigurationsFunction';
import ErrorModal from '../../../components/Common/Error/ErrorModal';
import { checkSecurityDetails } from '../../../utils/RoleUtils';

const HotelItem = (props) => {
    const [error, setError] = useState();
    const hotelLogoLink = process.env.REACT_APP_HOTEL_LOG_URL + "defaultHotel.png"
    const history = useHistory();
    const [hotelError, setHotelError] = useState();

    const onBookNow = (id) => {
        sessionStorage.setItem("HotelEchoToken", JSON.stringify(props.echoToken))
        let detailsJSON = HotelDetailsJSON(props.hotelResults)
        sessionStorage.setItem("HotelDetails", JSON.stringify(detailsJSON))
        sessionStorage.removeItem("HotelIntialTotalAmount");

        if (props.travelReq !== undefined && props.travelReq !== '' && props.travelReq === 'true') {
            openInNewTab('HotelDetails?travelreq=true');
        }
        else {
            openInNewTab('HotelDetails');
        }
    }

    const openInNewTab = url => {
        window.open(url);
    };

    function htmlDecode(input) {
        var doc = new DOMParser().parseFromString(input, "text/html");
        return doc.documentElement.textContent;
    }

    const errorHandler = () => {
        setError(null);
    };

    const hotelErrorHandler = () => {
        setError(null);
        setHotelError(null);
    };
    const dummyFunction = (id) => {
    }

    const privileges = () => {
        setError({
            title: "Exclusive Privileges",
            message:
                <div className='col-md-12'>
                    <div className=''>
                        <div className='col-md-12 privillege-icons-box'>
                            <div className='row'>
                                <div className='col-md-6 mt-3'>
                                    <div className='row'>
                                        <div className='col-md-3 my-auto'>
                                            <div className='privillege-icons'><i className="fas fa-sun"></i></div>
                                        </div>
                                        <div className='col-md-9 my-auto text-white text-center-sm'>
                                            Guaranteed Early  <br></br> Check-in.
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6 mt-3'>
                                    <div className='row'>
                                        <div className='col-md-3 my-auto'>
                                            <div className='privillege-icons'><i className="fas fa-moon"></i></div>
                                        </div>
                                        <div className='col-md-9 my-auto text-white text-center-sm'>
                                            Guaranteed Late <br></br>Check-out.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr></hr>
                            <div className='row'>
                                <div className='col-md-6 mt-3'>
                                    <div className='row'>
                                        <div className='col-md-3 my-auto'>
                                            <div className='privillege-icons'><i className="fas fa-utensils"></i></div>
                                        </div>
                                        <div className='col-md-9 my-auto text-white text-center-sm'>
                                            Breakfast <br></br>Included.
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6 mt-3'>
                                    <div className='row'>
                                        <div className='col-md-3 my-auto'>
                                            <div className='privillege-icons'><i className="fas fa-wallet"></i></div>
                                        </div>
                                        <div className='col-md-9 my-auto text-white text-center-sm'>
                                            Free hotel <br></br> credits.
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <hr></hr>
                            <div className='row'>
                                <div className='col-md-6 mt-3'>
                                    <div className='row'>
                                        <div className='col-md-3 my-auto'>
                                            <div className='privillege-icons'><i className='fa fa-hotel'></i></div>
                                        </div>
                                        <div className='col-md-9 my-auto text-white text-center-sm'>
                                            Free room upgrade,<br></br> based on availability.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <div className='text-black'>*Please refer to the hotel policies for more details.</div>
                        </div>
                    </div>
                </div>
            ,
            // onConfirm={errorHandler}
        });
    }
    return <Fragment>
        <ReactTooltip
            anchorId={`PolicyIn${props.id}`}
            place="bottom"
            variant="info"
            multiline={true}
            className="tooltip"
            html={"In Policy"}
        />
        <ReactTooltip
            anchorId={`PolicyOut${props.id}`}
            place="bottom"
            variant="info"
            multiline={true}
            className="tooltip"
            html={"Out of Policy"}
        />
        {error && (
            <MdModal
                title={error.title}
                message={error.message}
                onConfirm={errorHandler}
            ></MdModal>
        )}
        {hotelError && (
            <ErrorModal
                title={hotelError.title}
                message={hotelError.message}
                onConfirm={hotelErrorHandler}
            ></ErrorModal>
        )}
        <div className="hotel-list-cn clearfix">
            <div className="hotel-list-item position-relative perk-border light-yellow-bg">
                <figure className="hotel-img float-left">
                    {
                        props.hotelResults.roomRateDetails[0].infoSource !== undefined &&
                        <a className='cursor-pointer' onClick={privileges} title="">
                            <img src={props.hotelResults.hotelPicture !== undefined ? props.hotelResults.hotelPicture : hotelLogoLink} alt="" />
                        </a>
                    }
                    {
                        props.hotelResults.roomRateDetails[0].infoSource === undefined &&
                        <a title="">
                            <img src={props.hotelResults.hotelPicture !== undefined ? props.hotelResults.hotelPicture : hotelLogoLink} alt="" />
                        </a>
                    }
                    {
                        props.hotelResults.roomRateDetails[0].infoSource !== undefined &&
                        <span><span className="badge bg-primary gt-exclusive-text">Exclusive</span> <span className="gt-exclusive-text d-none"><a href='javascript:void(0);' onClick={privileges}  >Privileges</a></span></span>
                        // <span><span className="badge bg-primary gt-exclusive-text">GT Exclusive</span> | <span className="gt-exclusive-text"><a  href='javascript:void(0);'  >Privileges</a></span></span>
                    }
                </figure>
                {
                    props.hotelResults.roomRateDetails[0].infoSource !== undefined &&
                    <span onClick={privileges} className='hotel-flag cursor-pointer' id={'gtExclusive' + props.id} >
                        <img src="assets/img/gt-blue.png" />
                        <ReactTooltip
                            anchorId={'gtExclusive' + props.id}
                            place="bottom"
                            variant="info"
                            className="tooltip"
                            html={'Exclusive'}
                        />
                    </span>
                }
                <div className="hotel-text">
                    <div className="hotel-name">
                        <a id={'hotelName' + props.id} className='cursor-pointer' onClick={checkSecurityDetails("Book", "Hotel", "Book_Hotel_Details") ? () =>
                            onBookNow(props.id) : dummyFunction(props.id)} >{htmlDecode(props.hotelResults.hotelName)}
                        </a>
                        <ReactTooltip
                            anchorId={'hotelName' + props.id}
                            place="bottom"
                            variant="info"
                            className="tooltip"
                            html={props.hotelResults.hotelName}
                        />
                    </div>

                    <div className=' text-muted text-14px'>
                        {props.hotelResults.reviewRating !== undefined && props.hotelResults.reviewRating !== '' && props.hotelResults.reviewRating !== "NA" &&
                            <span>  Rating:
                                <span className='text-primary fw-bold mt-2'> {parseFloat(props.hotelResults.reviewRating)}/5</span></span>
                        } &nbsp; &nbsp;
                    </div>

                    <div className="hotel-star-address">
                        <address className="hotel-address">
                            {props.hotelResults.address.addressLine}<br></br>
                            {props.hotelResults.address.city.cityName},&nbsp;
                            {props.hotelResults.address.country.countryName}
                        </address>


                    </div>
                    <div className='row'>
                        <div className='col-md-7 col-sm-12 my-auto'>
                            {props.hotelResults.propertyTypeInfo !== undefined && props.hotelResults.propertyTypeInfo !== null ? <span className='text-muted '>Property Type</span> : ''}
                            <br></br><div className='text-primary fw-bold'>{props.hotelResults.propertyTypeInfo !== undefined && props.hotelResults.propertyTypeInfo !== null ? props.hotelResults.propertyTypeInfo.map(a => a.description).join(", ") : ''}</div>
                        </div>
                        {props.showData === '1' && props.travelReq !== undefined && props.travelReq === 'true' ? props.inPolicy !== undefined ? props.inPolicy === true ? <div className='hotel-service text-end policy-in-out h3'><i id={`PolicyIn${props.id}`} className="fas fa-check-circle text-success cursor-pointer"></i></div> : <div className='hotel-service text-end policy-in-out h3'><i id={`PolicyOut${props.id}`} className="fas fa-times-circle text-danger cursor-pointer"></i></div> : <div className='hotel-service text-end policy-in-out h3'><i id={`PolicyOut${props.id}`} vclassName="fas fa-times-circle text-danger cursor-pointer"></i></div> : ''}
                        {
                            props.showData === '1' &&
                            <div className="col-md-5  col-sm-12 hotel-service text-end">
                                <div className="price special-price text-primary fw-bold h3 mb-0">{new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: props.hotelResults.price[0].currencyCode
                                }).format(props.hotelResults.price[0].offeredPrice.perNight)}</div>
                                <div className='text-muted text-14px'>Avg. Per Night <br></br>Including Taxes</div>
                            </div>
                        }


                        <hr className='my-1'></hr>
                        <div className='col-md-9  col-sm-12 my-auto'>

                            <div className="col-md-12 text-14px text-muted pb-1">
                                {getConfigurationByBoolen("ALLOW_SOURCE_PCC_FARE") && <span>Source: <span className="text-primary fw-bold">{props.hotelResults.sourceName} </span> | PCC: <span className="text-primary fw-bold">{props.hotelResults.price[0].pcc}</span></span>}
                                {getConfigurationByBoolen("ALLOW_SOURCE_PCC_FARE") && getConfigurationByBoolen("ALLOW_COMISSION") && <span>{props.hotelResults.providerSourceCode !== '113' && props.hotelResults.price[0].agentCommissionType !== undefined && (props.hotelResults.price[0].agentCommissionType === "Variable" || props.hotelResults.price[0].agentCommissionType === "Amount") ? ' | Comm.:  ' : props.hotelResults.price[0].agentCommission !== undefined && props.hotelResults.price[0].agentCommission !== '' ? '| Comm.:  ' : ''}</span>}
                                {getConfigurationByBoolen("ALLOW_SOURCE_PCC_FARE") === false && getConfigurationByBoolen("ALLOW_COMISSION") && <span>{props.hotelResults.providerSourceCode !== '113' && props.hotelResults.price[0].agentCommissionType !== undefined && (props.hotelResults.price[0].agentCommissionType === "Variable" || props.hotelResults.price[0].agentCommissionType === "Amount") ? 'Comm.:  ' : props.hotelResults.price[0].agentCommission !== undefined && props.hotelResults.price[0].agentCommission !== '' ? 'Comm.:  ' : ''}</span>}

                                {getConfigurationByBoolen("ALLOW_COMISSION") && <span className="text-primary fw-bold">{props.hotelResults.providerSourceCode !== '113' && props.hotelResults.price[0].agentCommissionType !== undefined ? props.hotelResults.price[0].agentCommissionType === 'Percentage' ? props.hotelResults.price[0].agentCommission !== '' && props.hotelResults.price[0].agentCommission !== undefined ? Math.abs(props.hotelResults.price[0].agentCommission) + "%" : '' : props.hotelResults.price[0].agentCommissionType === 'Variable' ? 'Variable' :
                                    new Intl.NumberFormat('en-US', {
                                        style: 'currency',
                                        currency: props.hotelResults.price[0].currencyCode
                                    }).format(props.hotelResults.price[0].agentCommissionAmount !== undefined ? props.hotelResults.price[0].agentCommissionAmount : props.hotelResults.price[0].agentCommission) : ''}
                                </span>}
                                {getConfigurationByBoolen("ALLOW_SOURCE_PCC_FARE") && <span>{props.hotelResults.providerSourceCode === '113' ? '| Rate Plan:' : props.hotelResults.roomRateDetails[0].infoSource !== undefined ? '| Rate Plan:' : ''} <span className="text-primary fw-bold">{props.hotelResults.providerSourceCode === '113' ? 'Negotiated Net' : (props.hotelResults.roomRateDetails[0].ratePlanName !== undefined && props.hotelResults.roomRateDetails[0].infoSource !== undefined) ? props.hotelResults.roomRateDetails[0].ratePlanName : ''}</span></span>}
                                {/* | Content:  <span className="text-primary fw-bold">Virtuoso</span> */}
                                {getConfigurationByBoolen("ALLOW_B2B") && getConfigurationByBoolen("ALLOW_COMISSION") && <br></br>}
                                {getConfigurationByBoolen("ALLOW_B2B") && !getConfigurationByBoolen("ALLOW_COMISSION") && <br></br>}
                                {!getConfigurationByBoolen("ALLOW_B2B") && getConfigurationByBoolen("ALLOW_COMISSION") && <br></br>}
                                {props.hotelResults.roomRateDetails[0].cancellationPolicies[0].nonRefundable === 'true' ? <div className="text-14px text-danger mt-2"><i className="fas fa-money-check-alt"></i>  Non Refundable</div> : <div className="text-14px text-sky-blue mt-2"><i className="fas fa-money-check-alt"></i>  Refundable</div>}
                            </div>

                        </div>
                        <div className='col-md-3  col-sm-12 text-end'>
                            {
                                props.showData === '1' && checkSecurityDetails("Book", "Hotel", "Book_Hotel_Details") &&
                                <button className="my-2 btn btn_theme btn_sm" onClick={() =>
                                    onBookNow(props.id)}>
                                    Details</button>
                            }
                        </div>
                    </div>

                </div>

                <div className='row'>

                </div>
            </div>
        </div>

    </Fragment>
};
export default HotelItem;
