import { Fragment, useEffect, useState } from 'react';

const Approver = () => {
    let profileDataJson = localStorage.getItem("loginData") !== null ? JSON.parse(localStorage.getItem("loginData")) : '';


    return (
        <Fragment>
            <div className="row position-relative">
                <div className="col-lg-12">
                    <div className="tour_search_form">
                        <form action="!#">
                            <div className='row mb-4'>
                                <div className='col-md-6 dropdown-search-text'>
                                    <label class="h6 text-primary mb-2 col-lg-12" for="employeeName">Employee Name</label>
                                    <input maxLength={30} className="form-control bg_input" type='text'
                                        id='employeeName' value={profileDataJson !== '' && profileDataJson.firstName !== undefined && profileDataJson.firstName !== null ? profileDataJson.firstName.trim() + " " + profileDataJson.lastName.trim() + " " + profileDataJson.middleName.trim() : ''} disabled />
                                </div>
                                <div className='col-md-6 dropdown-search-text'>
                                    <label class="h6 text-primary mb-2 col-lg-12" for="employeeCode">Employee Code</label>
                                    <input maxLength={30} className="form-control bg_input" type='text'
                                        id='employeeCode' value={profileDataJson !== '' && profileDataJson.employeeCode !== undefined && profileDataJson.employeeCode !== null ? profileDataJson.employeeCode.trim() : ''} disabled />
                                </div>
                            </div>
                            <div className='row mb-4 d-none'>
                                <div className='col-md-6 dropdown-search-text'>
                                    <label class="h6 text-primary mb-2 col-lg-12" for="mobileNumber">Mobile Number</label>
                                    <input maxLength={30} className="form-control bg_input" type='text'
                                        id='mobileNumber' value={'9810688007'} disabled />
                                </div>
                                <div className='col-md-6 dropdown-search-text'>
                                    <label class="h6 text-primary mb-2 col-lg-12" for="emailID">Email ID</label>
                                    <input maxLength={30} className="form-control bg_input" type='text'
                                        id='emailID' value={profileDataJson !== '' && profileDataJson.email !== undefined && profileDataJson.email !== null ? profileDataJson.email.trim() : ''} disabled />
                                </div>
                            </div>
                            <div className='row mb-4'>
                                <div className='col-md-6 dropdown-search-text'>
                                    <label class="h6 text-primary mb-2 col-lg-12" for="firstApproverEmployeeName">First Approver Name</label>
                                    <input maxLength={30} className="form-control bg_input" type='text'
                                        id='firstApproverEmployeeName' value={profileDataJson !== '' && profileDataJson.firstApprover !== undefined && profileDataJson.firstApprover !== null && profileDataJson.firstApprover !== '' ? profileDataJson.firstApprover.trim() : ''} disabled />
                                </div>
                                <div className='col-md-6 dropdown-search-text'>
                                    <label class="h6 text-primary mb-2 col-lg-12" for="firstApproverEmployeeCode">First Approver Code</label>
                                    <input maxLength={30} className="form-control bg_input" type='text'
                                        id='firstApproverEmployeeCode' value={profileDataJson !== '' && profileDataJson.firstApproverEmployeeCode !== undefined && profileDataJson.firstApproverEmployeeCode !== null && profileDataJson.firstApproverEmployeeCode !== '' ? profileDataJson.firstApproverEmployeeCode.trim() : ''} disabled />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Fragment>)
};

export default Approver;

