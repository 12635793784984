// any app specific constants that you don't want to keep in config can go here.
export const SYSTEM_ERROR = "System error. Please try again later!";
export const SYSTEM_PAGE_LOST = "You have not saved your changes. Are you sure you want to leave this page?";

export const SYSTEM_KEY = "12269adb-1cf8-4d50-9237-98b9f6a7bc7e";

export const DEFAULT_TITLE = process.env.REACT_APP_DEFAULT_TITLE;

export const PAGE_MODE = {
    ADD: 'ADD',
    UPDATE: 'UPDATE',
    VIEW: 'VIEW'
};