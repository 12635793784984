import {
    ROOT, LOGIN, MYTRIPS, MYQUEUE, CHANGEPASSWORD,
    REGISTRATION, ALLPROFILEDETAILS, NEWPROFILEDETAILS, CONTINENT,
    NEWCONTINENT, NEWCONTINENTWITHQUERYSTRING, NEWPROFILEDETAILSWITHQUERYSTRING,
    NEWCOUNTRY, ALLCOUNTRY, NEWCOUNTRYWITHQUERYSTRING,
    FORGOTPASSWORD, FLIGHTSEARCH, FLIGHTBOOK, FLIGHTDETAILS, BOOKINGCONFIRMATION, MYTRIP, HOME, HOTELSEARCH, HOTELBOOKING, ALLCITY, NEWCITY, NEWCITYWITHQUERYSTRING,
    ALLAIRPORT, NEWAIRPORT, NEWAIRPORTWITHQUERYSTRING, ALLUSER, NEWUSER, NEWUSERWITHQUERYSTRING,
    ALLAIRLINE, NEWAIRLINE, NEWAIRLINEWITHQUERYSTRING, SEARCHOFFICE, UPDATEOFFICE, UPDATEOFFICEWITHQUERYSTRING,
    NEWBLANK,
    SEARCHFEE,
    UPDATEFEE,
    UPDATEFEEWITHQUERYSTRING,
    ENABLETWOFACTOR, AUTHORIZETWOFACTOR,
    SEARCHROLE, UPDATEROLE, UPDATEROLEWITHQUERYSTRING, UPDATECUSTOMIZEWITHQUERYSTRING, UPDATECUSTOMIZE,
    SEARCHREGION, UPDATEREGION, UPDATEREGIONWITHQUERYSTRING,
    SEARCHCREDITLIMIT, UPDATECREDITLIMIT, UPDATECREDITLIMITWITHQUERYSTRING,
    SESSION, ERROR,
    UPDATECONFIGURATION,
    SEARCHCONTENT, UPDATECONTENT, UPDATECONTENTWITHQUERYSTRING,
    SEARCHCONFIGURATION, UPDATECONFIGURATIONWITHQUERYSTRING, UPDATEPASSWORD, UPDATEEMAILSERVER,
    UPDATEEMAILSERVERWITHQUERYSTRING, UNAUTHORIZED, BOOKING, SEARCHTRAVELAGENT, CONFIRMATION,

    PRINTCONFIRMATION, HOTELDETAIL, RETRIEVETRIP, SEARCHHOTEL, HOTELDETAILS, HOTELCONFIRMATION, HOTELBOOKINGS,
    SEARCHFLIGHTBOOKING, FLIGHTRECOMMENDATION, HOTELCONFIRMATIONS, RETRIEVEHOTEL, SEARCHSALES, DISPLAYSALES, PRIVACYPOLICY, TERMSCONDITIONS,
    SEARCHHOTELBOOKING, DISPLAYHOTELBOOKING, CRYPTIC, DASHBOARD, IMPORTPNR, SEARCHPNR,
    SEARCHFEES, UPDATEFEESWITHQUERYSTRING, UPDATEFEES,
    SEARCHHOTELCOMMISSION, DISPLAYHOTELCOMMISSION, SINGLEPNRBOOKING, SEARCHUSERFRIENDLY, UPDATEUSERFRIENDLY, UPDATEUSERFRIENDLYWITHQUERYSTRING,
    RETRIEVESHOPPINGCART, SINGLEPNRCONFIRMATION, SEARCHWALLET, UPDATEWALLETWITHQUERYSTRING,
    UPDATEWALLET, SEARCHWALLETUTILIZATION, SEARCHRATEPLANALLOWED, UPDATERATEPLANALLOWEDWITHQUERYSTRING,
    UPDATERATEPLANALLOWED, LOGINWEB, WEBSTAUS, SEARCHRATEPLAN, UPDATERATEPLAN, UPDATERATEPLANWITHQUERYSTRING, RETRIEVEGDSTRIP, FLIGHTRECOMMENDATIONS,
    SEARCHSIGHTSEEING, SIGHTSEEINGDETAILS, HOTELRECOMMENDATION, SIGHTSEEINGDETAILSTAB, ROOMRECOMMENDATION, HOMEPAGE,
    KANOO,
    AUTHENTICATION,
    VALIDATE, HOMEPAGETEMP, SEARCHHOLIDAYS, SEARCHTRIPREASON, TRAVELREQUEST,
    UPDATETRIPREASON,
    SEARCHRULES,
    UPDATERULES,
    SEARCHDEPARTMENT,
    UPDATEDEPARTMENT,
    SEARCHCOSTCENTER,
    UPDATECOSTCENTER,
    SEARCHPOSITION,
    UPDATEPOSITION,
    RETRIEVETRAVELREQUEST,
    PAYMENT,
    TRAVELREQUESTEMAIL,
    UPDATECORPORATEWITHQUERYSTRING,
    UPDATECORPORATE,
    PASSWORDCHANGE,
    SEARCHCORPORATEMASTER,
    UPDATECORPORATEMASTER,
    UPDATECORPORATEMASTERWITHQUERYSTRING,
    UPDATETRIPREASONWITHQUERYSTRING,
    SEARCHAPPROVER, UPDATEAPPROVER, UPDATEAPPROVERWITHQUERYSTRING,
    MASTERMENU,
    USERMENU,
    SETTINGMENU, SIGNUP, SEARCHVISA,
    UPDATERULESWITHQUERYSTRING,
    HIGHRISKDESTINATION, HIGHRISKDESTINATION_ADD, HIGHRISKDESTINATION_UPDATE, HIGHRISKDESTINATION_VIEW

} from './CONSTANTS'

//import NotFound from './NotFound'
import { Route, Switch } from 'react-router-dom';
import MyTrips from '../pages/MyTrips/MyTrips';
import MyQueue from '../pages/OBT/MyQueue/MyQueue';
import Layout from '../components/Common/layout/Layout';
import Registration from '../pages/MyAgents/Registration';
import Continent from '../pages/Masters/Continent/AllContinent';
import NewContinent from '../pages/Masters/Continent/NewContinent';



//Country
import AllCountry from '../pages/Masters/Country/AllCountry'
import NewCountry from '../pages/Masters/Country/NewCountry';

import ChangePassword from '../pages/Auth/ChangePassword/ChangePassword'
import ForgetPassword from '../pages/Auth/ForgotPassword/ForgotPassword'
import UpdatePassword from '../pages/Auth/ForgotPassword/UpdatePassword'

import FlightSearch from '../pages/Flights/SearchFlight';
import FlightBook from '../pages/Flights/FlightBook';
// import FlightRecommendation from '../pages/Flights/FlightRecommendation';
import Booking from '../pages/Booking/Booking';
import BookingConfirmation from '../pages/Flights/BookingConfirmation';
import FlightDetails from '../pages/Flights/FlightDetails';
import HotelSearch from '../pages/Hotels/HotelSearch'
import HotelBooking from '../pages/Hotels/HotelBooking'
import SearchHotel from '../pages/Hotels/SearchHotel'
import HotelDetail from '../pages/Hotels/HotelDetail'
import HotelDetails from '../pages/HotelDetails/HotelDetails'
import HotelConfirmation from '../pages/Hotels/HotelConfirmation'
import HotelConfirmations from '../pages/HotelBookingConfirmation/HotelConfirmations'
import HotelBookings from '../pages/HotelBooking/HotelBookings'
import RetrieveHotel from '../pages/MyTrips/RetrieveHotel'

import MyTrip from '../pages/Flights/MyTrip';
/*
import HotelSearch from '../pages/Hotels/HotelSearch'

import Home from '../pages/Home/Home' */

import Login from '../pages/Auth/Login/Login'
import AuthContext from '../redux/contexts/auth-context';
import React, { useContext, useState, useEffect, useMemo } from 'react';
import '../i18n/i18n.js'

import AllCity from '../pages/Masters/City/AllCity'
import NewCity from '../pages/Masters/City/NewCity';

import AllAirport from '../pages/Masters/Airport/AllAirport'
import NewAirport from '../pages/Masters/Airport/NewAirport';


//MANAGE USER
import AllUser from '../pages/UserManagement/AllUser';
//import AllUser from './Blank';
import NewUser from '../pages/UserManagement/NewUser';

import AllAirline from '../pages/Masters/Airline/AllAirline'
import NewAirline from '../pages/Masters/Airline/NewAirline';

//Content
import SearchContent from '../pages/Masters/Content/SearchContent';
import UpdateContent from '../pages/Masters/Content/UpdateContent';

//Fees
import SearchFees from '../pages/Masters/Fees/SearchFees';
import UpdateFees from '../pages/Masters/Fees/UpdateFees';
//import SearchFees from './Blank';


//Manage Customize

//import CustomizeForm from './Blank';

import SearchOffice from '../pages/Masters/Office/SearchOffice'
//import SearchOffice from './Blank'
import UpdateOffice from '../pages/Masters/Office/UpdateOffice';

import SearchFee from '../pages/Masters/Fee/SearchFee'

import UpdateFee from '../pages/Masters/Fee/UpdateFee';

import Blank from './Blank';
import Session from './Session';
import Error from './Error';
//import NotFound from './NotFound'
//2FA
import EnableTwoFactor from '../pages/Auth/Login/EnableMFA'
import AuthorizeTwoFactor from '../pages/Auth/Login/MFA';

import SearchRole from '../pages/Masters/Role/SearchRole'
import UpdateRole from '../pages/Masters/Role/UpdateRole';
import NewCustomize from '../pages/Masters/Customize/NewCustomize';

import SearchRegion from '../pages/Masters/Region/SearchRegion';
import UpdateRegion from '../pages/Masters/Region/UpdateRegion';

import SearchCreditLimit from '../pages/Masters/CreditLimit/SearchCreditLimit';
import UpdateCreditLimit from '../pages/Masters/CreditLimit/UpdateCreditLimit';
import UpdateConfiguration from '../pages/Masters/Configuration/UpdateConfiguration';
//import SearchConfiguration from './Blank';
import Unauthorized from './Unauthorized';
import SearchConfiguration from '../pages/Masters/Configuration/SearchConfiguration';

import { checkSecurityDetails } from '../utils/RoleUtils'
import UpdateEmailServer from '../pages/Masters/EmailServer/UpdateEmailServer';
import Confirmation from '../pages/BookingConfirmation/Confirmation'
import PrintConfirmation from '../pages/BookingConfirmation/PrintConfrimation'

//Wallet
import SearchWallet from '../pages/Masters/Wallet/SearchWallet';
import UpdateWallet from '../pages/Masters/Wallet/UpdateWallet';

import NotFound from './NotFound';
//import FlightBook from '../pages/Flights/FlightBook';
import FlightRecommendation from '../pages/Flights/FlightRecommendation';

import SearchTravelAgent from './../pages/Switch/SearchTravelAgent'

import RetrieveTrip from '../pages/MyTrips/RetrieveTrip';
import RetrieveGdsTrip from '../pages/MyTrips/RetrieveGdsTrip';

import SearchFlightBooking from '../pages/Reports/FlightBooking/SearchFlightBooking'

import SearchSales from '../pages/Reports/Sales/SearchSales';
import DisplaySales from '../pages/Reports/Sales/DisplaySales';
import PrivacyPolicy from '../pages/CompanyPolicy/PrivacyPolicy';
import TermsConditions from '../pages/CompanyPolicy/TermsConditions';
import SearchHotelBooking from '../pages/Reports/HotelBooking/SearchHotelBooking'
import DisplayHotelBooking from '../pages/Reports/HotelBooking/DisplayHotelBooking';
import Cryptic from '../pages/CrypticCommand/Cryptic'
import DashBoard from '../pages/Reports/DashBoard/DashBoard';
import ImportPNR from '../pages/ImportPNR/Flight/ImportPNR';
import SearchPNR from '../pages/ImportPNR/Flight/SearchPNR';
import SearchHotelCommission from '../pages/Reports/HotelCommission/SearchHotelCommission';
import DisplayHotelCommission from '../pages/Reports/HotelCommission/DisplayHotelCommission';
import { getConfigurationByBoolen } from '../utils/ConfigurationsFunction'
import SearchFlight from '../pages/Flights/SearchFlight';
import SinglePnrBooking from '../pages/ShoppingCart/Booking/Booking';
import SinglePnrConfirmation from '../pages/ShoppingCart/Confirmation/Confirmation';
import SearchUserFriendly from '../pages/Masters/UserFriendly/SearchUserFriendly';
import UpdateUserFriendly from '../pages/Masters/UserFriendly/UpdateUserFriendly';
import RetrieveFlightPlusHotel from '../pages/MyTrips/RetrieveFlightPlusHotel';
import Loading from './Loading';
import SearchWalletUtilization from '../pages/Reports/WalletUtilization/SearchWalletUtilization';
import SearchRatePlanAllowed from '../pages/Masters/ManageRatePlanAllowed/SearchRatePlan';
import UpdateRatePlanAllowed from '../pages/Masters/ManageRatePlanAllowed/UpdateRatePlan';
import SearchRatePlan from '../pages/Masters/RatePlan/SearchRatePlan';
import UpdateRatePlan from '../pages/Masters/RatePlan/UpdateRatePlan';
import LoginWeb from '../pages/WebCheck/Login/LoginWeb';
import WebStatus from '../pages/WebCheck/WebStatus/WebStatus';
import FlightRecommendations from '../pages/Flights/FlightRecommendations';

import SearchSightseeing from '../pages/Sightseeing/SearchSightseeing';
import SightseeingDetails from '../pages/SightseeingDetails/SightseeingDetails';
import Recommendation from '../pages/HotelRecommendation/Recommendation.js';
import SightseeingDetailsTab from '../pages/SightseeingDetails/SightseeingDetailsTab';
import RoomRecommendation from '../pages/HotelRecommendation/RoomRecommendation.js';
import HomePage from '../pages/B2C/Home/HomePage.js';
import Kanoo from '../pages/B2C/Home/Kanoo.js';
import Authentication from '../pages/B2C/Authentication/Authentication.js';
import Validate from '../pages/B2C/Authentication/Validate.js';
import HomePageTemp from '../pages/Home/HomePageTemp.js';
import SearchHolidays from '../pages/Holidays/SearchHolidays';
import TravelRequest from '../pages/OBT/TravelRequest/TravelRequest.js';
import SearchTripReason from '../pages/Masters/TripReason/SearchTripReason.js'
import UpdateTripReason from '../pages/Masters/TripReason/UpdateTripReason.js'


import SearchRules from '../pages/Masters/Rules/SearchRules.js';
import UpdateRules from '../pages/Masters/Rules/UpdateRules.js';

import PositionSearch from '../pages/Masters/Position/PositionSearch.js';
import PositionNew from '../pages/Masters/Position/PositionNew.js';
import DepartmentSearch from '../pages/Masters/Department/DepartmentSearch.js';
import DepartmentNew from '../pages/Masters/Department/DepartmentNew.js';
import CostCenterSearch from '../pages/Masters/CostCenter/CostCenterSearch.js';
import CostCenterNew from '../pages/Masters/CostCenter/CostCenterNew.js';
import RetrieveTravelRequest from '../pages/MyTrips/Components/RetrieveTravelRequest.js';

import Payment from '../pages/MyTrips/Components/Payment';
// import SearchCorparate from '../pages/Masters/Corporate/SearchCorporate.js';
// import UpdateCorporate from '../pages/Masters/Corporate/UpdateCorpate.js';
import PasswordChnage from '../pages/Auth/Login/PasswordChange.js';
import SearchCorporateMaster from '../pages/Masters/CorporateMaster/SearchCorporateMaster.js';
import UpdateCorporateMaster from '../pages/Masters/CorporateMaster/UpdateCorporateMaster.js';
import SearchApprover from '../pages/OBT/ApprovalWorkflow/SearchApprover.js';
import UpdateApprover from '../pages/OBT/ApprovalWorkflow/UpdateApprover.js';
import MasterMenu from '../pages/MobileMenu/MasterMenu.js';
import UserMenu from '../pages/MobileMenu/UserMenu.js';
import SettingMenu from '../pages/MobileMenu/SettingMenu.js';
import SignUp from '../pages/Masters/SignUp/SignUp';
import SearchVisa from '../pages/Visa/SearchVisa.js';
import TravelRequestEmail from '../pages/OBT/EmailDetails/TravelRequestEmail.js';
import SearchHighRiskDestination from '../pages/Masters/HighRiskDestination/SearchHighRiskDestination.js';
import UpdateHighRiskDestination from '../pages/Masters/HighRiskDestination/UpdateHighRiskDestination.js';

function RouterConfig() {
    const authCtx = useContext(AuthContext);
    const [enable2FAAuth, setEnable2FAAuth] = useState(false);

    const isApproverLogin = useMemo(() => {
        let isApprover = false;
        const loginData = JSON.parse(localStorage.getItem("loginData"));

        if (loginData && loginData.approvalType && loginData.approvalType.length) {
            isApprover = true;
        }

        return isApprover;
    }, []);

    useEffect(() => {
        if (localStorage.getItem('enable2FA') === "true" && !authCtx.isLoggedIn) {
            setEnable2FAAuth(true);
        } else {
            setEnable2FAAuth(false);
        }
    }, [authCtx.isLoggedIn])

    return (
        <Layout>
            <Switch>
                {/*     <Route  path={PRIVACYPOLICY} component={PrivacyPolicy} />
        <Route  path={TERMSCONDITIONS} component={TermsConditions} /> */}
                <Route exact path={KANOO} component={Kanoo} />
                <Route exact path={PRIVACYPOLICY} component={PrivacyPolicy} />
                <Route exact path={TERMSCONDITIONS} component={TermsConditions} />
                <Route exact path={PASSWORDCHANGE} component={PasswordChnage} />

                {authCtx.isLoggedIn && <Route exact path={AUTHENTICATION} component={Authentication} />}
                {authCtx.isLoggedIn && <Route exact path={VALIDATE} component={Validate} />}
                {authCtx.isLoggedIn && <Route exact path={SEARCHPNR} component={(checkSecurityDetails("Book", "ImportPNR") && getConfigurationByBoolen("ALLOW_IMPORT_PNR") === true) ? SearchPNR : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={IMPORTPNR} component={(checkSecurityDetails("Book", "ImportPNR") && getConfigurationByBoolen("ALLOW_IMPORT_PNR") === true) ? ImportPNR : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={DASHBOARD} component={checkSecurityDetails("Home") ? DashBoard : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={CRYPTIC} component={(checkSecurityDetails("Book", "SabreHost") && getConfigurationByBoolen("ALLOW_SABRE_HOST") === true) ? Cryptic : Unauthorized} />}
                {/* {authCtx.isLoggedIn && <Route exact path={HOME} component={Home} />} */}
                {authCtx.isLoggedIn && <Route exact path={CONTINENT} component={Continent} />}
                {authCtx.isLoggedIn && <Route exact path={NEWCONTINENT} component={NewContinent} />}
                {authCtx.isLoggedIn && <Route exact path={NEWCONTINENTWITHQUERYSTRING} component={NewContinent} />}
                {authCtx.isLoggedIn && <Route exact path={REGISTRATION} component={Registration} />}
                {authCtx.isLoggedIn && <Route exact path={CHANGEPASSWORD} component={ChangePassword} />}


                {/* Country Details */}
                {authCtx.isLoggedIn && <Route exact path={ALLCOUNTRY} component={AllCountry} />}
                {authCtx.isLoggedIn && <Route exact path={NEWCOUNTRY} component={NewCountry} />}
                {authCtx.isLoggedIn && <Route exact path={NEWCOUNTRYWITHQUERYSTRING} component={NewCountry} />}

                {/* Country Details */}
                {authCtx.isLoggedIn && <Route exact path={SEARCHUSERFRIENDLY} component={getConfigurationByBoolen("ALLOW_USER_FRIENDLY") && checkSecurityDetails("Master", "Message") && checkSecurityDetails("Master", "Message", "Master_Message_Search") ? SearchUserFriendly : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={UPDATEUSERFRIENDLY} component={checkSecurityDetails("Master", "Message", "Master_Message_Insert") ? UpdateUserFriendly : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={UPDATEUSERFRIENDLYWITHQUERYSTRING} component={(checkSecurityDetails("Master", "Message", "Master_Message_View") || checkSecurityDetails("Master", "Message", "Master_Message_Update")) ? UpdateUserFriendly : Unauthorized} />}

                {!authCtx.isLoggedIn && <Route exact path={FORGOTPASSWORD} component={ForgetPassword} />}
                {!authCtx.isLoggedIn && <Route exact path={UPDATEPASSWORD} component={UpdatePassword} />}
                {/* Flight search  */}
                {authCtx.isLoggedIn && <Route exact path={FLIGHTSEARCH} component={checkSecurityDetails("Book", "Flight") && checkSecurityDetails("Book", "Flight", "Book_Flight_Search") ? FlightSearch : Unauthorized} />}

                {/* Flight Book  */}
                {authCtx.isLoggedIn && <Route exact path={FLIGHTBOOK} component={checkSecurityDetails("Book", "Flight") ? FlightBook : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={FLIGHTRECOMMENDATION} component={checkSecurityDetails("Book", "Flight") ? FlightRecommendation : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={FLIGHTRECOMMENDATIONS} component={checkSecurityDetails("Book", "Flight") ? FlightRecommendations : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={BOOKING} component={Booking} />}

                {/* Single pnr Book  */}
                {authCtx.isLoggedIn && <Route exact path={SINGLEPNRBOOKING} component={SinglePnrBooking} />}
                {authCtx.isLoggedIn && <Route exact path={SINGLEPNRCONFIRMATION} component={SinglePnrConfirmation} />}

                {/* Booking Confirmation  */}
                {authCtx.isLoggedIn && <Route exact path={FLIGHTDETAILS} component={checkSecurityDetails("Book", "Flight") ? FlightDetails : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={BOOKINGCONFIRMATION} component={checkSecurityDetails("Book", "Flight") ? BookingConfirmation : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={PRINTCONFIRMATION} component={checkSecurityDetails("Book", "Flight") ? PrintConfirmation : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={MYTRIP} component={checkSecurityDetails("Book", "Flight") ? MyTrips : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={RETRIEVETRIP} component={checkSecurityDetails("Book", "Flight") ? RetrieveTrip : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={RETRIEVEGDSTRIP} component={checkSecurityDetails("Book", "Flight") ? RetrieveGdsTrip : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={RETRIEVETRAVELREQUEST} component={checkSecurityDetails("Book", "Flight") ? RetrieveTravelRequest : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={CONFIRMATION} component={checkSecurityDetails("Book", "Flight") ? Confirmation : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={PAYMENT} component={checkSecurityDetails("Book", "Flight") ? Payment : Unauthorized} />}

                {/* Hotel search  */}
                {authCtx.isLoggedIn && <Route exact path={HOTELSEARCH} component={checkSecurityDetails("Book", "Hotel") && checkSecurityDetails("Book", "Hotel", "Book_Hotel_Search") ? HotelSearch : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={HOTELBOOKING} component={checkSecurityDetails("Book", "Hotel") ? HotelBooking : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={SEARCHHOTEL} component={checkSecurityDetails("Book", "Hotel") ? SearchHotel : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={HOTELDETAIL} component={checkSecurityDetails("Book", "Hotel") ? HotelDetail : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={HOTELDETAILS} component={checkSecurityDetails("Book", "Hotel") ? HotelDetails : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={HOTELCONFIRMATION} component={checkSecurityDetails("Book", "Hotel") ? HotelConfirmation : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={HOTELCONFIRMATIONS} component={checkSecurityDetails("Book", "Hotel") ? HotelConfirmations : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={HOTELBOOKINGS} component={checkSecurityDetails("Book", "Hotel") ? HotelBookings : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={RETRIEVEHOTEL} component={checkSecurityDetails("Book", "Hotel") ? RetrieveHotel : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={HOTELRECOMMENDATION} component={checkSecurityDetails("Book", "Hotel") ? Recommendation : Unauthorized} />}

                {/* Home PAGE */}
                {authCtx.isLoggedIn && <Route exact path={HOMEPAGE} component={HomePage} />}
                {authCtx.isLoggedIn && <Route exact path={ROOMRECOMMENDATION} component={checkSecurityDetails("Book", "Hotel") ? RoomRecommendation : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={HOMEPAGETEMP} component={HomePageTemp} />}

                {/* Travel Request */}
                {authCtx.isLoggedIn && <Route exact path={TRAVELREQUEST} component={checkSecurityDetails("Book", "Flight") ? TravelRequest : Unauthorized} />}

                {/* Home  */}
                {/* {authCtx.isLoggedIn && <Route exact path={HOME} component={checkSecurityDetails("Master", "City") ? AllCity : Unauthorized} />} */}
                {/*  {authCtx.isLoggedIn && <Route exact path={HOME} component={checkSecurityDetails("Master", "Flight") ? FlightSearch : Unauthorized} />} */}
                {/* City Details */}
                {authCtx.isLoggedIn && <Route exact path={ALLCITY} component={checkSecurityDetails("Master", "City") ? AllCity : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={NEWCITY} component={checkSecurityDetails("Master", "City", "Master_City_Insert") ? NewCity : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={NEWCITYWITHQUERYSTRING} component={(checkSecurityDetails("Master", "City", "Master_City_Update") || checkSecurityDetails("Master", "City", "Master_City_View")) ? NewCity : Unauthorized} />}
                {/* Airport Details */}
                {authCtx.isLoggedIn && <Route exact path={ALLAIRPORT} component={checkSecurityDetails("Master", "Airport") ? AllAirport : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={NEWAIRPORT} component={checkSecurityDetails("Master", "Airport", "Master_Airport_Insert") ? NewAirport : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={NEWAIRPORTWITHQUERYSTRING} component={(checkSecurityDetails("Master", "Airport", "Master_Airport_Update") || checkSecurityDetails("Master", "Airport", "Master_Airport_View")) ? NewAirport : Unauthorized} />}

                {/*Manage User Details*/}
                {authCtx.isLoggedIn && <Route exact path={ALLUSER} component={checkSecurityDetails("User Management", "User") ? AllUser : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={NEWUSER} component={checkSecurityDetails("User Management", "User", "User Management_User_Insert") ? NewUser : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={NEWUSERWITHQUERYSTRING} component={(checkSecurityDetails("User Management", "User", "User Management_User_Update") || checkSecurityDetails("User Management", "User", "User Management_User_View")) ? NewUser : Unauthorized} />}

                {/* Airline Details */}
                {authCtx.isLoggedIn && <Route exact path={ALLAIRLINE} component={checkSecurityDetails("Master", "Airline") ? AllAirline : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={NEWAIRLINE} component={checkSecurityDetails("Master", "Airline", "Master_Airline_Insert") ? NewAirline : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={NEWAIRLINEWITHQUERYSTRING} component={(checkSecurityDetails("Master", "Airline", "Master_Airline_Update") || checkSecurityDetails("Master", "Airline", "Master_Airline_View")) ? NewAirline : Unauthorized} />}

                {/*Manage Customize*/}

                {authCtx.isLoggedIn && <Route exact path={UPDATECUSTOMIZE} component={checkSecurityDetails("Settings", "Customize", "Settings_Customize_View") ? NewCustomize : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={UPDATECUSTOMIZEWITHQUERYSTRING} component={checkSecurityDetails("Settings", "Customize", "Settings_Customize_Update") ? NewCustomize : Unauthorized} />}

                {/*Manage Email Server*/}
                {authCtx.isLoggedIn && <Route exact path={UPDATEEMAILSERVER} component={checkSecurityDetails("Settings", "Email Server", "Settings_Email Server_View") ? UpdateEmailServer : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={UPDATEEMAILSERVERWITHQUERYSTRING} component={checkSecurityDetails("Settings", "Email Server", "Settings_Customize_Update") ? UpdateEmailServer : Unauthorized} />}


                {/*Office Details */}
                {authCtx.isLoggedIn && <Route exact path={SEARCHOFFICE} component={checkSecurityDetails("User Management", "Office") ? SearchOffice : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={UPDATEOFFICE} component={checkSecurityDetails("User Management", "Office", "User Management_Office_Insert") ? UpdateOffice : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={UPDATEOFFICEWITHQUERYSTRING} component={(checkSecurityDetails("User Management", "Office", "User Management_Office_Update") || checkSecurityDetails("Master", "Office", "User Management_Office_View")) ? UpdateOffice : Unauthorized} />}

                {/*Fee Details */}
                {authCtx.isLoggedIn && <Route exact path={SEARCHFEE} component={checkSecurityDetails("Master", "Fee") ? SearchFee : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={UPDATEFEE} component={checkSecurityDetails("Master", "Fee") ? UpdateFee : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={UPDATEFEEWITHQUERYSTRING} component={checkSecurityDetails("Master", "Fee") ? UpdateFee : Unauthorized} />}


                {/* List a generic 404-Not Found route here */}
                {authCtx.isLoggedIn && <Route exact path={MYTRIPS} component={checkSecurityDetails("My Trips") ? MyTrips : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={MYQUEUE} component={(checkSecurityDetails("My Queue") && isApproverLogin) ? MyQueue : Unauthorized} />}

                {/* Role */}
                {authCtx.isLoggedIn && <Route exact path={SEARCHROLE} component={checkSecurityDetails("Master", "Role") ? SearchRole : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={UPDATEROLE} component={checkSecurityDetails("Master", "Role", "User Management_Role_Insert") ? UpdateRole : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={UPDATEROLEWITHQUERYSTRING} component={(checkSecurityDetails("Master", "Role", "User Management_Role_Update") || checkSecurityDetails("Master", "Role", "User Management_Role_View")) ? UpdateRole : Unauthorized} />}

                {/* Region */}
                {authCtx.isLoggedIn && <Route exact path={SEARCHREGION} component={checkSecurityDetails("Master", "Region") ? SearchRegion : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={UPDATEREGION} component={checkSecurityDetails("Master", "Region", "Master_Region_Insert") ? UpdateRegion : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={UPDATEREGIONWITHQUERYSTRING} component={(checkSecurityDetails("Master", "Region", "Master_Region_Update") || checkSecurityDetails("Master", "Region", "Master_Region_View")) ? UpdateRegion : Unauthorized} />}

                {/*Content*/}
                {authCtx.isLoggedIn && <Route exact path={SEARCHCONTENT} component={checkSecurityDetails("Settings", "Content") ? SearchContent : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={UPDATECONTENT} component={checkSecurityDetails("Settings", "Content", "Settings_Content_Insert") ? UpdateContent : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={UPDATECONTENTWITHQUERYSTRING} component={(checkSecurityDetails("Settings", "Content", "Settings_Content_Update") || checkSecurityDetails("Settings", "Content", "Settings_Content_View")) ? UpdateContent : Unauthorized} />}

                {/*Trip Reason */}
                {authCtx.isLoggedIn && <Route exact path={SEARCHTRIPREASON} component={checkSecurityDetails("Master", "TripReason") ? SearchTripReason : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={UPDATETRIPREASON} component={checkSecurityDetails("Master", "TripReason", "Master_TripReason_Insert") ? UpdateTripReason : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={UPDATETRIPREASONWITHQUERYSTRING} component={(checkSecurityDetails("Master", "TripReason", "Master_TripReason_Update") || checkSecurityDetails("Master", "TripReason", "Master_TripReason_View")) ? UpdateTripReason : Unauthorized} />}

                {/*Approver */}
                {authCtx.isLoggedIn && <Route exact path={SEARCHAPPROVER} component={checkSecurityDetails("Master", "FIRST") ? SearchApprover : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={UPDATEAPPROVER} component={checkSecurityDetails("Master", "FIRST", "Master_FIRST_Insert") ? UpdateApprover : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={UPDATEAPPROVERWITHQUERYSTRING} component={(checkSecurityDetails("Master", "FIRST", "Master_FIRST_Update") || checkSecurityDetails("Master", "FIRST", "Master_FIRST_View")) ? UpdateApprover : Unauthorized} />}


                {/*Policy*/}
                {authCtx.isLoggedIn && <Route exact path={SEARCHRULES} component={checkSecurityDetails("Settings", "Policy") ? SearchRules : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={UPDATERULES} component={checkSecurityDetails("Settings", "Policy", "Settings_Policy_Insert") ? UpdateRules : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={UPDATERULESWITHQUERYSTRING} component={(checkSecurityDetails("Settings", "Policy", "Settings_Policy_Insert") || checkSecurityDetails("Settings", "Policy", "Master_Policy_Update")) ? UpdateRules : Unauthorized} />}

                {/*Department*/}

                {authCtx.isLoggedIn && <Route exact path={SEARCHDEPARTMENT} component={DepartmentSearch} />}
                {authCtx.isLoggedIn && <Route exact path={UPDATEDEPARTMENT} component={DepartmentNew} />}

                {/*Cost Center*/}
                {authCtx.isLoggedIn && <Route exact path={SEARCHCOSTCENTER} component={CostCenterSearch} />}
                {authCtx.isLoggedIn && <Route exact path={UPDATECOSTCENTER} component={CostCenterNew} />}

                {/*Position*/}
                {authCtx.isLoggedIn && <Route exact path={SEARCHPOSITION} component={PositionSearch} />}
                {authCtx.isLoggedIn && <Route exact path={UPDATEPOSITION} component={PositionNew} />}

                {authCtx.isLoggedIn && <Route exact path={TRAVELREQUESTEMAIL} component={TravelRequestEmail} />}
                {/* Corporate */}
                {authCtx.isLoggedIn && <Route exact path={SEARCHCORPORATEMASTER} component={SearchCorporateMaster} />}
                {authCtx.isLoggedIn && <Route exact path={UPDATECORPORATEMASTER} component={UpdateCorporateMaster} />}
                {authCtx.isLoggedIn && <Route exact path={UPDATECORPORATEMASTERWITHQUERYSTRING} component={UpdateCorporateMaster} />}

                {/*Fees*/}
                {authCtx.isLoggedIn && <Route exact path={SEARCHFEES} component={checkSecurityDetails("Master", "Fee") ? SearchFees : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={UPDATEFEES} component={checkSecurityDetails("Master", "Fee", "Master_Fee_Insert") ? UpdateFees : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={UPDATEFEESWITHQUERYSTRING} component={(checkSecurityDetails("Master", "Fee", "Master_Fee_Update") || checkSecurityDetails("Master", "Fee", "Master_Fee_View")) ? UpdateFees : Unauthorized} />}


                {/*Configuration*/}
                {authCtx.isLoggedIn && <Route exact path={SEARCHCONFIGURATION} component={checkSecurityDetails("Settings", "Configuration") ? SearchConfiguration : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={UPDATECONFIGURATIONWITHQUERYSTRING} component={(checkSecurityDetails("Settings", "Configuration", "Settings_Configuration_Update") || checkSecurityDetails("Settings", "Configuration", "Settings_Configuration_View")) ? UpdateConfiguration : Unauthorized} />}

                {/*Manage Rate Plan Allowed*/}
                {authCtx.isLoggedIn && <Route exact path={SEARCHRATEPLANALLOWED} component={checkSecurityDetails("Settings", "RatePlanAllowed") ? SearchRatePlanAllowed : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={UPDATERATEPLANALLOWEDWITHQUERYSTRING} component={checkSecurityDetails("Settings", "RatePlanAllowed", "Settings_RatePlanAllowed_Update") || checkSecurityDetails("Settings", "RatePlanAllowed", "Settings_RatePlanAllowed_View") ? UpdateRatePlanAllowed : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={UPDATERATEPLANALLOWED} component={checkSecurityDetails("Settings", "RatePlanAllowed", "Settings_RatePlanAllowed_Update") ? UpdateRatePlanAllowed : Unauthorized} />}


                {/*Manage Rate Plan */}
                {authCtx.isLoggedIn && <Route exact path={SEARCHRATEPLAN} component={checkSecurityDetails("Master", "RatePlan") ? SearchRatePlan : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={UPDATERATEPLANWITHQUERYSTRING} component={checkSecurityDetails("Master", "RatePlan", "Master_RatePlan_Update") ? UpdateRatePlan : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={UPDATERATEPLAN} component={checkSecurityDetails("Master", "RatePlan", "Master_RatePlan_Update") || checkSecurityDetails("Master", "RatePlan", "Master_RatePlan_View") ? UpdateRatePlan : Unauthorized} />}

                {/*High Risk Destination */}
                {authCtx.isLoggedIn && <Route exact path={HIGHRISKDESTINATION} component={checkSecurityDetails("Master", "HighRiskDestination") ? SearchHighRiskDestination : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={HIGHRISKDESTINATION_ADD} component={checkSecurityDetails("Master", "HighRiskDestination", "Master_HighRiskDestination_Insert") ? UpdateHighRiskDestination : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={HIGHRISKDESTINATION_UPDATE} component={checkSecurityDetails("Master", "HighRiskDestination", "Master_HighRiskDestination_Update") ? UpdateHighRiskDestination : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={HIGHRISKDESTINATION_VIEW} component={checkSecurityDetails("Master", "HighRiskDestination", "Master_HighRiskDestination_View") ? UpdateHighRiskDestination : Unauthorized} />}

                {/*Mobile Menu*/}
                {authCtx.isLoggedIn && <Route exact path={MASTERMENU} component={MasterMenu} />}
                {authCtx.isLoggedIn && <Route exact path={USERMENU} component={UserMenu} />}
                {authCtx.isLoggedIn && <Route exact path={SETTINGMENU} component={SettingMenu} />}

                {/*Wallet*/}
                {authCtx.isLoggedIn && <Route exact path={SEARCHWALLET} component={checkSecurityDetails("Settings", "Wallet") ? SearchWallet : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={UPDATEWALLET} component={checkSecurityDetails("Settings", "Wallet", "Settings_Wallet_Update") ? UpdateWallet : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={UPDATEWALLETWITHQUERYSTRING} component={(checkSecurityDetails("Settings", "Wallet", "Settings_Wallet_Update") || checkSecurityDetails("Settings", "Wallet", "Settings_Wallet_View")) ? UpdateWallet : Unauthorized} />}

                {/* Credit Limit */}

                {authCtx.isLoggedIn && <Route exact path={SEARCHCREDITLIMIT} component={SearchCreditLimit} />}
                {authCtx.isLoggedIn && <Route exact path={UPDATECREDITLIMIT} component={UpdateCreditLimit} />}
                {authCtx.isLoggedIn && <Route exact path={UPDATECREDITLIMITWITHQUERYSTRING} component={UpdateCreditLimit} />}

                {authCtx.isLoggedIn && <Route exact path={SEARCHTRAVELAGENT} component={checkSecurityDetails("Master", "Switch") ? SearchTravelAgent : Unauthorized} />}

                {/* Reports */}
                {authCtx.isLoggedIn && <Route exact path={SEARCHFLIGHTBOOKING} component={checkSecurityDetails("Reports", "FlightBooking") ? SearchFlightBooking : Unauthorized} />}

                {/*Wallet Utilization*/}
                {authCtx.isLoggedIn && <Route exact path={SEARCHWALLETUTILIZATION} component={checkSecurityDetails("Reports", "WalletUtilization") ? SearchWalletUtilization : Unauthorized} />}
                {/*Single Pnr My Trip*/}
                {authCtx.isLoggedIn && <Route exact path={RETRIEVESHOPPINGCART} component={checkSecurityDetails("Book", "Flight") ? RetrieveFlightPlusHotel : Unauthorized} />}

                {authCtx.isLoggedIn && <Route exact path={SEARCHSALES} component={checkSecurityDetails("Reports", "DashBoard") ? SearchSales : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={DISPLAYSALES} component={checkSecurityDetails("Reports", "DashBoard") ? DisplaySales : Unauthorized} />}

                {authCtx.isLoggedIn && <Route exact path={SEARCHHOTELBOOKING} component={checkSecurityDetails("Reports", "HotelBooking") ? SearchHotelBooking : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={DISPLAYHOTELBOOKING} component={checkSecurityDetails("Reports", "HotelBooking") ? DisplayHotelBooking : Unauthorized} />}

                {authCtx.isLoggedIn && <Route exact path={SEARCHHOTELCOMMISSION} component={checkSecurityDetails("Reports", "HotelCommission") ? SearchHotelCommission : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={DISPLAYHOTELCOMMISSION} component={checkSecurityDetails("Reports", "HotelCommission") ? DisplayHotelCommission : Unauthorized} />}

                {/*SignUp Details */}
                {<Route exact path={SIGNUP} component={SignUp} />}

                {/*Blank Interface Details */}
                {authCtx.isLoggedIn && <Route exact path={UNAUTHORIZED} component={Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={NEWBLANK} component={Blank} />}
                {!authCtx.isLoggedIn && <Route exact path={SESSION} component={Session} />}
                {authCtx.isLoggedIn && <Route exact path={ERROR} component={Error} />}

                {authCtx.isLoggedIn && <Route exact path={SEARCHSIGHTSEEING} component={checkSecurityDetails("Book", "Sightseeing") ? SearchSightseeing : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={SIGHTSEEINGDETAILS} component={checkSecurityDetails("Book", "Sightseeing") ? SightseeingDetails : Unauthorized} />}
                {authCtx.isLoggedIn && <Route exact path={SIGHTSEEINGDETAILSTAB} component={checkSecurityDetails("Book", "Sightseeing") ? SightseeingDetailsTab : Unauthorized} />}

                {authCtx.isLoggedIn && <Route exact path={SEARCHHOLIDAYS} component={checkSecurityDetails("Book", "Sightseeing") ? SearchHolidays : Unauthorized} />}
                {/*Visa*/}
                {authCtx.isLoggedIn && <Route exact path={SEARCHVISA} component={checkSecurityDetails("Book", "Sightseeing") ? SearchVisa : Unauthorized} />}

                {/* {authCtx.isLoggedIn && <Route exact path="*" component={NotFound} />} */}
                {/* {authCtx.isLoggedIn && !getConfigurationByBoolen("ALLOW_TRAVEL_REQUEST") && <Route exact path="*" component={TravelRequest} />} */}
                {authCtx.isLoggedIn && getConfigurationByBoolen("ALLOW_FLIGHT") && <Route exact path="*" component={Loading} />}
                {authCtx.isLoggedIn && getConfigurationByBoolen("ALLOW_HOTEL") && <Route exact path="*" component={Loading} />}
                {authCtx.isLoggedIn && getConfigurationByBoolen("ALLOW_IMPORT_PNR") && <Route exact path="*" component={SearchPNR} />}

                {/*2FA Route */}
                {enable2FAAuth && <Route exact path={AUTHORIZETWOFACTOR} component={AuthorizeTwoFactor} />}
                {!enable2FAAuth && <Route exact path={ENABLETWOFACTOR} component={EnableTwoFactor} />}
                {<Route exact path={LOGINWEB} component={LoginWeb} />}
                {localStorage.getItem("LoginWebStatus") && <Route exact path={WEBSTAUS} component={WebStatus} />}
                {!authCtx.isLoggedIn && <Route exact path={LOGIN} component={Login} />}
                {!authCtx.isLoggedIn && <Route exact path={ROOT} component={Login} />}
                {!authCtx.isLoggedIn && <Route exact path="*" component={Login} />}
            </Switch>
        </Layout>
    );
}

export default RouterConfig;
