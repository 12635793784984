import Dropdown from 'react-select';
import { useState, useEffect } from 'react';
import { searchCorporateMaster } from '../../../services/CorporateMaster-API';
import useHttp from '../../../services/use-http';
const ServiceRequest = (props) => {
    const [service, setService] = useState('');
    const [remarks, setRemarks] = useState('')
    const [existingConfirmationNumber, setExistingConfirmationNumber] = useState('')
    const [totalRateOfService, setTotalRateOfService] = useState('')
    const { sendRequest: sendRequestCorproateMaster, status: statusCorproateMaster, data: loadedCorproateMaster, error } = useHttp(searchCorporateMaster);

    useEffect(() => {
        const onSearchCorporateMaster = ({
            "id": '',
            userID: JSON.parse(localStorage.getItem('userID')),
            "name": "",
            "code": "",
            "keyCode": "SIX",
            "status": '2',
            "officeID": JSON.parse(localStorage.getItem("officeID"))
        });
        sendRequestCorproateMaster(onSearchCorporateMaster);
    }, [])
    let serviceRequestOptions = [];
    let loadedData = [];
    for (let i in loadedCorproateMaster) {
        if (loadedCorproateMaster['errors'].status === 'FALSE') {
            if (i === 'data') {
                loadedData = loadedCorproateMaster[i];
            }
        }
    }

    loadedData.forEach(element => {
        serviceRequestOptions.push({ value: element.id, label: element.name });
    });
    useEffect(() => {
        if (props.serviceRequest !== undefined) {
            if (props.serviceRequest[0].serviceID !== undefined) {
                setService(props.serviceRequest[0].serviceID)
            }
            if (props.serviceRequest[0].serviceName !== undefined) {

            }
            if (props.serviceRequest[0].remarks !== undefined) {
                setRemarks(props.serviceRequest[0].remarks)
            }
            if (props.serviceRequest[0].existingConfirmationNumber !== undefined) {
                setExistingConfirmationNumber(props.serviceRequest[0].existingConfirmationNumber)

            }
            if (props.serviceRequest[0].totalRateOfService !== undefined) {
                setTotalRateOfService(props.serviceRequest[0].totalRateOfService)

            }
        }
    }, [])

    const serviceChangeHandler = (event) => {
        setService(event.value);
    };
    return <>
        <hr className="my-2"></hr>
        <p className="h5 mb-3 text-black fw-bold mt-3">Service Request </p>
        <div className="row">
            <div className='control col-lg-12'>
                <label htmlFor='name' className="h6 text-primary mb-2 col-lg-12">Service </label>
                <Dropdown
                    onChange={serviceChangeHandler}
                    value={serviceRequestOptions.filter(option => option.value === service)}
                    options={serviceRequestOptions}
                    textField="listValue"
                    dataItemKey="listID"
                    arrowClosed={<span className="arrow-closed" />}
                    arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                    isDisabled={true}

                />
            </div>
        </div>
        <div className="control ">
            <label className="h6 text-primary mb-2 col-lg-12" for="category"> Remarks
            </label>
            <div className='service-box-editor'>
                <p dangerouslySetInnerHTML={{ __html: remarks }} />
            </div>

        </div>
        <div className='row mb-4'>
            {existingConfirmationNumber !== '' && < div className='col-md-6 dropdown-search-text'>
                <label class="h6 text-primary mb-2 col-lg-12" for="existingConfirmationNumber">Existing Confirmation Number</label>
                <input className="form-control bg_input" type='text'
                    id='existingConfirmationNumber' value={existingConfirmationNumber} readOnly />
            </div>}
            {totalRateOfService !== '' && <div className='col-md-6 dropdown-search-text'>
                <label class="h6 text-primary mb-2 col-lg-12" for="totalRateOfService">Total Rate of service (if available)</label>
                <input className="form-control bg_input" type='text'
                    id='totalRateOfService' value={totalRateOfService} readOnly />
            </div>}
        </div >
    </>
}

export default ServiceRequest;