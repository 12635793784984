import { Fragment, useState, useRef, useEffect } from "react";
import Dropdown from 'react-select';

import useHttp from '../../../../services/use-http';
import { searchWorkflowStatus, updateTravelRequestApproval } from '../../../../services/Approver-API';
import { searchCurrency } from '../../../../services/Currency-API';

const ApproverDetails = (props) => {
    const isTravelBookerApproval = (props.approvalTypeID === '6');

    const [statusOptions, setStatusOptions] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState('');
    const remarksRef = useRef(null);

    const [isStatusSelected, setIsStatusSelected] = useState(true);
    const [isRemarksValid, setIsRemarksValid] = useState(true);

    const { sendRequest: sendSearchWorkflowStatus, data: workflowStatusResponse, status: searchWorkflowRequestStatus } = useHttp(searchWorkflowStatus);
    const { sendRequest: sendUpdateTravelRequestApproval, data: updateTravelRequestApprovalResponse, status: sendUpdateTravelRequestApprovalStatus } = useHttp(updateTravelRequestApproval);

    const [isRemarksMandatory, setIsRemarksMandatory] = useState(false);
    const [showMandatoryRemarksError, setShowMandatoryRemarksError] = useState(false);

    const { sendRequest: sendSearchCurrency, data: currencyResponse, status: searchCurrencyStatus } = useHttp(searchCurrency);
    const [currencyOptions, setCurrencyOptions] = useState([]);
    const [selectedCurrency, setSelectedCurrency] = useState('');

    const [isAmountValid, setIsAmountValid] = useState(true);
    const [isAmountEntered, setIsAmountEntered] = useState(true);
    const [isCurrencySelected, setIsCurrencySelected] = useState(true);

    const amountRef = useRef(null);

    const stausChangeHandler = (event) => {
        setSelectedStatus(event.value);
        if (event.value === 'RJ') {
            setIsRemarksMandatory(true);
        }
        else {
            setIsRemarksMandatory(false);
            setShowMandatoryRemarksError(false);
        }
        setIsStatusSelected(true);
    };

    const currencyChangeHandler = (event) => {
        setSelectedCurrency(event.value);
        setIsCurrencySelected(true);
    };

    const submitFormHandler = () => {
        if (validateInputs()) {

            const updateStatusInput = {
                shoppingID: props.shoppingID,
                approvalMasterID: props.approvalMasterID,
                approvalUserID: JSON.parse(localStorage.getItem('userID')),
                approvalStatusCode: selectedStatus,
                currencyCode: selectedCurrency,
                amount: amountRef.current ? amountRef.current.value : '',
                remarks: remarksRef.current.value.trim()
            };

            sendUpdateTravelRequestApproval(updateStatusInput);
        }
    }

    function functionExit() {
        props.errorHandler();
    }

    function validateInputs() {
        let isValid = true;

        if (!selectedStatus) {
            setIsStatusSelected(false);
            isValid = false;
        }
        else {
            setIsStatusSelected(true);
        }

        if (isAmountRequired()) {
            if (!selectedCurrency) {
                setIsCurrencySelected(false);
                isValid = false;
            }
            else {
                setIsCurrencySelected(true);
            }

            if (!validateAmount()) {
                isValid = false;
            }
        }
        else {
            setIsCurrencySelected(true);
            setIsAmountEntered(true);
        }

        if (!validateRemarks()) {
            isValid = false;
        }

        return isValid;
    }

    function validateRemarks() {
        let isValid = true;
        const remarksText = remarksRef.current.value.trim();

        if (isRemarksMandatory && remarksText.length === 0) {
            setShowMandatoryRemarksError(true);
            isValid = false;
        }
        else {
            setShowMandatoryRemarksError(false);
        }

        if (remarksText.length && !remarksText.match("^[a-zA-Z .()0-9,\\'\\-\\n]+$")) {
            setIsRemarksValid(false);
            isValid = false;
        }
        else {
            setIsRemarksValid(true);
        }

        return isValid;
    }

    function isAmountRequired() {
        return isTravelBookerApproval && selectedStatus === 'AP';
    }

    function validateAmount() {
        let isValid = true;
        const amount = amountRef.current.value.trim();

        if (amount.length === 0) {
            setIsAmountEntered(false);
            isValid = false;
        }
        else {
            setIsAmountEntered(true);
        }

        if (isNaN(amount)) {
            setIsAmountValid(false);
            isValid = false;
        }
        else {
            setIsAmountValid(true);
        }

        return isValid;
    }

    useEffect(() => {

        sendSearchWorkflowStatus({ workflowTypeID: '1' });

        if (isTravelBookerApproval) {
            sendSearchCurrency({
                currCode: '',
                currName: ''
            });
        }

    }, []);

    useEffect(() => {
        if (workflowStatusResponse) {

            if (workflowStatusResponse.errors.status === 'FALSE') {
                setStatusOptions(() => workflowStatusResponse.data.map((wfStatus) => {
                    return {
                        label: wfStatus.statusName,
                        value: wfStatus.statusCode
                    }
                }));
            }

        }
    }, [workflowStatusResponse]);

    useEffect(() => {
        if (updateTravelRequestApprovalResponse) {
            if (updateTravelRequestApprovalResponse.errors.status === 'FALSE') {
                props.onApprovalStatusUpdate(true);
            }
            else {
                props.onApprovalStatusUpdate(false);
            }
        }
    }, [updateTravelRequestApprovalResponse]);

    useEffect(() => {
        if (currencyResponse) {
            if (currencyResponse.errors.status === 'FALSE' && currencyResponse.data.length) {
                setCurrencyOptions(() =>
                    currencyResponse.data.map((currency) => {
                        return {
                            label: `${currency.currName} (${currency.currCode})`,
                            value: currency.currCode
                        }
                    })
                );
            }
        }
    }, [currencyResponse]);

    return <Fragment>
        <div className="row">
            <div className="col-md-12 gt-h-340px">
                <div className="control">
                    <div className='col-md-12 col-sm-12 control'>
                        <label className="h6 text-primary mb-2 col-lg-12" for="category"> Status <span className="error-icon">*</span>  </label>
                        <Dropdown
                            onChange={stausChangeHandler}
                            value={statusOptions.filter(option => option.value === selectedStatus)}
                            options={statusOptions}
                            textField="listValue"
                            dataItemKey="listID"
                            arrowClosed={<span className="arrow-closed" />}
                            arrowOpen={<span className="arrow-open" />}
                            controlClassName='form-control control form-select bg_input mb-0'
                            placeholder="Please Select"
                            isDisabled={false}
                            isLoading={searchWorkflowRequestStatus === 'pending'}
                        />
                        {!isStatusSelected &&
                            <p className="error-text">{'Status is required.'}</p>
                        }
                    </div>

                    {
                        isAmountRequired() &&
                        <Fragment>
                            <div className='col-md-12 col-sm-12 control'>
                                <label className="h6 text-primary mb-2 col-lg-12" for="category"> Currency <span className="error-icon">*</span>  </label>
                                <Dropdown
                                    onChange={currencyChangeHandler}
                                    value={currencyOptions.filter(option => option.value === selectedCurrency)}
                                    options={currencyOptions}
                                    textField="listValue"
                                    dataItemKey="listID"
                                    arrowClosed={<span className="arrow-closed" />}
                                    arrowOpen={<span className="arrow-open" />}
                                    controlClassName='form-control control form-select bg_input mb-0'
                                    placeholder="Please Select"
                                    isDisabled={false}
                                    isLoading={searchCurrencyStatus === 'pending'}
                                />

                                {!isCurrencySelected &&
                                    <p className="error-text">{'Currency is required.'}</p>
                                }
                            </div>

                            <div className='col-md-12 col-sm-12 control'>
                                <label className="h6 text-primary mb-2 col-lg-12" for="amount"> Amount <span className="error-icon">*</span>  </label>
                                <input type='text'
                                    id="amount"
                                    className="form-control form-control-sm"
                                    ref={amountRef}
                                    onBlur={validateAmount}
                                    maxLength={20} />

                                {
                                    !isAmountValid &&
                                    <p className="error-text">{'Please specify valid data.'}</p>
                                }
                                {
                                    !isAmountEntered &&
                                    <p className="error-text">{'Amount is required.'}</p>
                                }
                            </div>
                        </Fragment>
                    }

                    <div className='col-md-12 col-sm-12 control'>
                        <label className="h6 text-primary mb-2 col-lg-12" htmlFor='thresholdAlertEmail'>
                            Remarks{' '}
                            {
                                isRemarksMandatory &&
                                <span className="error-icon">*</span>
                            }
                        </label>
                        <div>
                            <textarea id="thresholdAlertEmail"
                                type="text" rows="2"
                                className="mb-2 form-control form-control-sm col-lg-12"
                                maxLength={300}
                                ref={remarksRef}
                                onBlur={validateRemarks}>
                            </textarea>
                        </div>
                        {
                            !isRemarksValid &&
                            <p className="error-text">{'Please specify valid data.'}</p>
                        }
                        {
                            showMandatoryRemarksError &&
                            <p className="error-text">{'Remarks is required.'}</p>
                        }
                    </div>

                </div>
            </div>
        </div>
        <p><span className="error-icon">*</span><span>Indicates required fields.</span></p>
        <div className="col-md-12 my-auto align-right">
            <button type="button" className='btn gt-btn_md text-white mt-2 gt-main-btn ms-1' id='btnSend' onClick={submitFormHandler} >
                {sendUpdateTravelRequestApprovalStatus === 'pending' ?
                    <i className="fa fa-spinner fa-spin" ></i> : 'Confirm'
                }
            </button>
            <button type="button" className='btn gt-btn_md text-white mt-2 gt-main-btn ms-1' id='btnSend' onClick={functionExit} >
                Cancel
            </button>
        </div>


    </Fragment>
};
export default ApproverDetails;