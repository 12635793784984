import SignUpForm from './Components/SignUpForm'
import { useHistory, useParams } from 'react-router-dom';
import useHttp from '../../../services/use-http'
import useHttpGet from '../../../services/use-http'
import { addOffice, getSingleOffice } from '../../../services/Office-API'
import { Fragment, useEffect, useState } from 'react';
import ErrorModal from "../../../components/Common/Error/WithoutAuthErrorModal";
import { useTranslation } from 'react-i18next';
import LoadingSpinnerWithCard from '../../../components/Common/Loading/LoadingSpinnerWithCard';
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle';

const SignUp = () => {
    useDocumentTitle('Sign Up')
    const { t } = useTranslation(['translation.Office']);
    const parms = useParams();
    const [error1, setError] = useState();
    const { Id, Action } = parms;

    const { sendRequest: sendRequest1, data: loadedOfficeDetails, error } = useHttpGet(getSingleOffice);
    const { sendRequest, status, data: loadedQuote } = useHttp(addOffice);
    const history = useHistory();

    useEffect(() => {
        window.scrollTo({
            top: 600,
            behavior: "smooth",
        });
        if (status === 'completed') {
            if (loadedQuote['errors'].status === 'TRUE') {
                if (loadedQuote['errors'].error['code'] === '1009') {
                    let loadedDataSave = [];
                    for (let index in loadedQuote) {
                        if (index === 'data') {
                            loadedDataSave = loadedQuote[index];
                        }
                    }
                    setError({
                        title: "Message",
                        message: loadedDataSave.userDetails.loginEmailID + `${t('errorDuplicateRecordSave')}`,
                    });
                }
                else {
                    setError({
                        title: "Message",
                        message: loadedQuote['errors'].error.description,
                    });
                }

                return;
            }
            else {
                setError({
                    title: "Message",
                    message: loadedQuote['errors'].error.description,
                });
            }
        }
    }, [status, loadedQuote])

    useEffect(() => {

        if (Id !== undefined) {
            sendRequest1(Id)
        }
    }, [sendRequest1, Id])

    useEffect(() => {
        window.scrollTo({
            top: 100,
            behavior: "smooth",
        });
    }, []);

    if (error) {
        return <div className='centered focused  col-lg-5 col-md-8 col-sm-12 col-12'>{t('errorRecordSave')}</div>
    }

    let loadedData = [];
    for (let index in loadedOfficeDetails) {
        if (loadedOfficeDetails['errors'].status === 'FALSE') {
            if (index === 'data') {
                loadedData = loadedOfficeDetails[index];
            }
        }
    }

    const addOfficeHandler = (requestData) => {        
        sendRequest(requestData);
    };

    const errorHandler = () => {
        setError(null);
        if (loadedQuote['errors'].status !== 'TRUE') {
            history.push('/Login');
        }
    };

    if (Id !== undefined && (!loadedData || loadedData.length === 0)) {
        return (
            <Fragment>
                <LoadingSpinnerWithCard></LoadingSpinnerWithCard>
            </Fragment>
        )
    }

    const SignUpFormContent = (Id !== undefined && (!loadedData || loadedData.length === 0)) ?
        <div className="text-center p-5 h4 text-primary"></div> :
        <SignUpForm loadedProfileDetailsUser={loadedData.userDetails === undefined ? loadedData : loadedData.userDetails} loadedProfileDetailsOffice={loadedData.officeDetails === undefined ? loadedData : loadedData.officeDetails} isLoading={status === 'pending'} onAddOffice={addOfficeHandler} IsEditMode={parseInt(sessionStorage.getItem('isEditable'))} />

    return <Fragment>
        {error1 && (
            <ErrorModal
                title={error1.title}
                message={error1.message}
                onConfirm={errorHandler}
            ></ErrorModal>
        )}
        <div className='position-relative bg-sky-blue'>
            <img src="assets/img/globetrotterdark.png" alt="" className='log-in-head-logo-bg position-absolute' />
        </div>
        <section id="common_banner-WithoutAuth1">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="common_bannner_text">
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {SignUpFormContent}
    </Fragment>
};

export default SignUp;