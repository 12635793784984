import { Fragment, useCallback, useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { TRAVELREQUEST, FLIGHTSEARCH, SEARCHHOTEL, SEARCHPNR } from '../../navigation/CONSTANTS';
import { useTranslation } from "react-i18next";
import useHttp from "../../services/use-http";
import { getAllUser, searchUser } from "../../services/User-API";
import TravelAgentList from './Components/TravelAgentList';
import LoadingSpinner from "../../components/Common/Loading/LoadingSpinner";
import ErrorModal from "../../components/Common/Error/ErrorModal";
import useDocumentTitle from '../../components/Common/layout/useDocumentTitle';
import { checkSecurityDetails } from '../../utils/RoleUtils'
import { searchOfficeList } from '../../services/Office-API'
import { AuthenticationWithLogin } from '../../services/Authentication-API'
import Dropdown from 'react-select';
import AuthContext from '../../redux/contexts/auth-context'
import { getConfigurationByBoolen } from '../../utils/ConfigurationsFunction'
import secureLocalStorage from 'react-secure-storage';
const AllUser = () => {
    useDocumentTitle('Search User');
    const [errors, setErrors] = useState();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const { t } = useTranslation(['translation.TravelAgent']);
    const [isFirstTime, setFirstTime] = useState("");
    const [error1, setError1] = useState(false);
    const { sendRequest: sendCompanyList, data: loadedCompanyList } = useHttp(searchOfficeList);
    const { sendRequest: sendRequestAuthentication, status: statusAuthentication, data: loadedAuthenticationDetails } = useHttp(AuthenticationWithLogin);
    const { sendRequest, status, data: loadedUser, error } = useHttp(searchUser);
    const history = useHistory();
    const [companyNameInput, setCompanyNameInput] = useState();
    const [companyNameHasError, setCompanyNameHasError] = useState(false);
    const authCtx = useContext(AuthContext);
    //Change Handlers
    function comapnyNameChaneHandler(event) {
        setCompanyNameInput(event.value)
        setCompanyNameHasError(false);
    }
    const firstNamechangehandler = (event) => {
        setFirstName(event.target.value);
    }
    const lastNamechangehandler = (event) => {
        setLastName(event.target.value)
    }
    useEffect(() => {

        if (localStorage.getItem("RootLoginEmailID") === null)
            localStorage.setItem("RootLoginEmailID", JSON.parse(localStorage.getItem("loginEmailID")))
    }, [localStorage.getItem("RootLoginEmailID")])
    function onAuthenticationWith(loginID) {
        sessionStorage.clear();
        const rootLoginID = localStorage.getItem("RootLoginEmailID");
        const strInput = ({
            "userName": rootLoginID,
            "password": "",
            "rootUserName": rootLoginID
        });
        // sendRequest(strInput);

        sendRequestAuthentication(strInput);
    }

    useEffect(() => {
        window.scrollTo({
            top: 100,
            behavior: "smooth",
        });
        let loadedData = [];

        if (statusAuthentication === 'completed') {
            for (let i in loadedAuthenticationDetails) {
                if (loadedAuthenticationDetails['errors'].status === 'FALSE') {
                    if (i === 'data') {
                        loadedData = loadedAuthenticationDetails[i];

                        const expirationTime = new Date(
                            new Date().getTime() + +loadedData.expireIn * 1000
                        );
                        sessionStorage.removeItem("walletAmount");

                        localStorage.setItem('loginData', JSON.stringify(loadedData));

                        authCtx.login(loadedData.idToken, expirationTime.toISOString(),
                            loadedData.loginEmailID, loadedData.userID, loadedData.officeID,
                            loadedData.displayName, loadedData.roleDetails, loadedData.customizeDetails,
                            loadedData.companyName, loadedData.traceID, loadedData.profileLocaterNumber,
                            loadedData.configurationsDetails, loadedData.dkNumber, loadedData.parentOfficeID,
                            loadedData.defaultCity, loadedData.isCorporate, loadedData.isTravelAgent,
                            loadedData.email, loadedData.phoneCode, loadedData.phoneNumber, loadedData);


                    }
                }
                else if (loadedAuthenticationDetails['errors'].status === 'TRUE') {
                    setErrors({
                        title: "Message",
                        message: 'Please enter the correct authenticator code.',
                    });
                    document.getElementById('Inputsecuritycode').value = '';
                }
            }
            if (loadedData.isCorporate)
                window.location.pathname = `${TRAVELREQUEST}`;
            else if (loadedData.isTravelAgent === "1")
                window.location.pathname = `${SEARCHPNR}`;
            else if (getConfigurationByBoolen("ALLOW_FLIGHT") && checkSecurityDetails("Book", "Flight") && checkSecurityDetails("Book", "Flight", "Book_Flight_Search"))
                window.location.pathname = `${FLIGHTSEARCH}`;
            else if (getConfigurationByBoolen("ALLOW_HOTEL"))
                window.location.pathname = `${SEARCHHOTEL}`;
            else
                window.location.pathname = `${SEARCHPNR}`;
        }



    }, [status, loadedAuthenticationDetails, authCtx, history])
    const onSearchHandler = useCallback((event) => {
        event.preventDefault();

        if (companyNameInput === undefined) {
            setCompanyNameHasError(true);
            return;
        }
        else {
            setCompanyNameHasError(false);
        }
        setFirstTime(true);
        const onSearchUser = (
            {
                firstName: firstName,
                lastName: lastName,
                roleID: "",
                status: "1",
                isActive: true,
                officeID: companyNameInput,
            });

        sendRequest(onSearchUser);

    }, [sendRequest, firstName, lastName, companyNameInput])
    useEffect(() => {
        const onSearchCompanyName = ({
            companyName: "",
            categoryID: "",
            countryCode: "",
            cityCode: "",
            loginEmailID: "",
            email: "",
            parentOfficeID: JSON.parse(localStorage.getItem('officeID')),
            configration: true,
        });
        sendCompanyList(onSearchCompanyName);
    }, [])
    let companyOptions = []
    let companyArrayData = [];
    for (let i in loadedCompanyList) {
        if (loadedCompanyList['errors'].status === 'FALSE') {
            if (i === 'data') {
                companyArrayData = loadedCompanyList[i];
            }
        }
    }
    for (let index = 0; index < companyArrayData.length; index++) {
        companyOptions = [...companyOptions, { value: companyArrayData[index].id, label: companyArrayData[index].companyName }]
    }



    function onResetHandler(event) {
        history.go('/SearchTravelAgent');
    }

    useEffect(() => {
        sessionStorage.setItem('isEditable', '');
        if (status === 'completed') {
            if (loadedUser['errors'].error['code'] === '1008' || loadedUser['errors'].error['code'] === '9001') {
                setError1(true);
                return;
            }
            window.scrollTo({
                top: 300,
                behavior: "smooth",
            });
        }

    }, [status, loadedUser])

    useEffect((sendRequest) => {
        window.scrollTo({
            top: 100,
            behavior: "smooth",
        });
        const keyDownHandler = event => {
            if (event.key === 'Enter') {
                event.preventDefault();
                // 👇️ call submit function here
                onSearchHandler(event);
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [onSearchHandler]);

    if (error) {
        return <div className='centered focused  col-lg-5 col-md-8 col-sm-12 col-12'>{t('ErrorMessage')}</div>
    }

    let loadedData = [];
    for (let i in loadedUser) {
        if (loadedUser['errors'].status === 'FALSE') {
            if (i === 'data') {
                loadedData = loadedUser[i];
            }
        }
    }
    function okayHandler() {
        setError1(false);
        history.go('/SearchTravelAgent');
    }
    const userList = !isFirstTime ? '' : (loadedData && loadedData.length !== 0) ?
        <TravelAgentList user={loadedData} /> : ""

    return (

        <Fragment>
            {error1 && <ErrorModal
                title="Message"
                message={`${t('ErrorMessage')}`}
                onConfirm={okayHandler}
            />}
            <section id="common_banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">

                        </div>
                    </div>
                </div>
            </section>

            <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="">
                                <div className="row">
                                    <div className="col-lg-2"></div>
                                    <div className="col-lg-8 mb-30">
                                        <div className="card card-primary card-outline">
                                            {status === 'pending' &&
                                                <div className=''>
                                                    {/* <section id='common_banner'><div className='container'></div></section> */}
                                                    <div className=''>
                                                        <LoadingSpinner></LoadingSpinner>
                                                    </div>
                                                </div>
                                            }
                                            <div className="card-body">

                                                <div className="col-md-12 gt-country-details-form">
                                                    <div className="form-group col-md-12">
                                                        <label htmlFor="" className="mb-2 h5 text-primary mb-3 col-lg-12"><i className="fas fa-globe-asia"></i> {t('searchUser')}</label>
                                                        <div className='row'>
                                                            <div className='col-md-12 col-sm-12 control'>
                                                                <label htmlFor='name' className="h6 text-primary mb-2 col-lg-12"> {t('companyName')}<span className="error-icon"> *</span></label>
                                                                <Dropdown onChange={comapnyNameChaneHandler}
                                                                    value={companyOptions.filter(option => option.value === companyNameInput)}
                                                                    options={companyOptions}
                                                                    textField="label"
                                                                    dataItemKey="value"
                                                                    arrowClosed={<span className="arrow-closed" />}
                                                                    arrowOpen={<span className="arrow-open" />}
                                                                    controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                                    autoFocus
                                                                />
                                                                {companyNameHasError &&
                                                                    <p className="error-text">{t('selectCompany')}</p>
                                                                }

                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <label htmlFor="firstName" className="mb-2 h6 text-primary col-lg-12"> {t('firstName')}</label>

                                                                <input type='text' id='firstname' maxLength={50} className="mb-2 form-control form-control-sm col-lg-12" value={firstName} onChange={firstNamechangehandler} placeholder='First Name' autoFocus />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label htmlFor="lastName" className="mb-2 h6 text-primary col-lg-12"> {t('lastName')}</label>

                                                                <input type='text' id='lastname' maxLength={50} className="mb-2 form-control form-control-sm col-lg-12" value={lastName} onChange={lastNamechangehandler} placeholder='Last Name' />
                                                            </div>
                                                            <p className='mt-3'><span className="error-icon">*</span><span id='requiredIndicator'>{t('requiredFieldsIndicator')}</span></p>
                                                        </div>


                                                        <div className="mt-3 col-md-12">
                                                            <div className="float-end text-end">

                                                                {localStorage.getItem("RootLoginEmailID") !== JSON.parse(localStorage.getItem("loginEmailID")) ?
                                                                    <button className='btn main-btn-width-auto text-white mt-1 gt-main-btn ms-1'
                                                                        onClick={onAuthenticationWith}>   {(statusAuthentication === 'pending') ? <i className="fa fa-spinner fa-spin" ></i> : t('backtoRoot')}
                                                                    </button> : ''}

                                                                {checkSecurityDetails("Master", "Switch", "Master_Switch_Search") &&
                                                                    <button className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1' onClick={onSearchHandler}>
                                                                        {t('search')}
                                                                    </button>}

                                                                <button className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1' onClick={onResetHandler}>
                                                                    {t('reset')}
                                                                </button>


                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-2"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="container-fluid my-3">
            </div>
            {userList}
        </Fragment>)

};

export default AllUser;