
import { Fragment } from 'react';

const LoadingSpinnerWithHeight = () => {
  return (
    <Fragment>
        <div className='loading' >

          <div className="loader flight-loader-width">
            <div className="plane">
              <img src="assets/img/loader5.gif" className="plane-img" />
            </div>
            <div className='postion-relative flight-loaders'>
              <div className="earth-wrapper">
                <div className="earth"></div>
              </div>
            </div>
            <div className="iata_code arrival_city">Globetrotter</div>
            <div className='position-absolute bottom-0 text-center w-100'>
            
              <div >
                {/* We are searching for <i>live</i> flights and fares<i> in real-time.</i>
                <br></br> */}
                {/* Please wait for a few seconds… */}
              </div>
            </div>
          </div>
          </div>
    </Fragment>)
}

export default LoadingSpinnerWithHeight;
