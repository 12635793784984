import { Fragment, useEffect, useState, React } from 'react';
import 'react-quill/dist/quill.snow.css';
import { useTranslation } from 'react-i18next';
import Files from 'react-files';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { generateUUID } from '../../../../utils';

const Document = () => {
    const { t } = useTranslation(['translation.TravelRequest']);
    const [files, setFiles] = useState([]);
    const [remarksInvalidInputError, setRemarksInvalidInputError] = useState(false);
    let size = 0;
    let travelRequestFieldsDataJson = "";

    useEffect(() => {
        if (sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
            travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
            if (travelRequestFieldsDataJson.travelRequestDocuments.length !== undefined && travelRequestFieldsDataJson.travelRequestDocuments.length !== 0) {
                // if (travelRequestFieldsDataJson.travelRequestDocuments.length > 0 && files.length === 0) {
                //     //sessionStorage.removeItem("travelRequestFieldsDataJson");                    
                //     setFiles(travelRequestFieldsDataJson.travelRequestDocuments);
                // }
                setFiles(travelRequestFieldsDataJson.travelRequestDocuments);
            }
        }
    }, [])

    if (sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
        travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
    }

    const handleChange = (newFiles) => {        

        if (document.querySelector("#documentName").value === '') {
            document.querySelector('#documentNameErrorMsg').innerHTML = "Name is required.";
            return;
        }
        else {
            if (document.querySelector("#loadingSpin") !== null) {
                document.querySelector('#loadingSpin').classList.remove('d-none');
            }

            if (sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
                travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));

                if (travelRequestFieldsDataJson.travelRequestDocuments.length === undefined) {
                    travelRequestFieldsDataJson.travelRequestDocuments = [];
                }

                //removed compress for uploaded file
                newFiles.forEach(newFile => {
                    blobToBase64(new Blob([newFile])).then(res => {
                        travelRequestFieldsDataJson.travelRequestDocuments.push({
                            "documentID": generateUUID(10),
                            "documentType": newFile.type,
                            "documentOriginalName": newFile.name,
                            "documentName": document.querySelector("#documentName").value,
                            "documentBinaryContent": res,
                            "remarks": document.querySelector("#remarks").value
                        });
                    });
                });

                setTimeout(function () {
                    sessionStorage.setItem("travelRequestFieldsDataJson", JSON.stringify(travelRequestFieldsDataJson));
                    setFiles(travelRequestFieldsDataJson.travelRequestDocuments)
                    document.querySelector("#documentName").value = '';
                    if (document.querySelector("#loadingSpin") !== null) {
                        document.querySelector('#loadingSpin').classList.add('d-none');
                    }
                }, 500);
            }

            document.querySelector('#documentNameErrorMsg').innerHTML = "";
            document.querySelector('#documentErrorMsg').innerHTML = "";
        }
    }

    const handleFileRemove = (fileId) => {
        setFiles(prevFiles => prevFiles.filter(prevFile => prevFile.documentID !== fileId))

        let travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));

        if (travelRequestFieldsDataJson.travelRequestDocuments.length !== undefined && travelRequestFieldsDataJson.travelRequestDocuments.length !== 0) {
            let travelRequestDocumentsTemp = travelRequestFieldsDataJson.travelRequestDocuments.filter(a => a.documentID !== fileId);
            travelRequestFieldsDataJson.travelRequestDocuments = travelRequestDocumentsTemp;
            sessionStorage.setItem("travelRequestFieldsDataJson", JSON.stringify(travelRequestFieldsDataJson))
        }
    }

    const handleClearFiles = () => {
        setFiles([])
    }

    const handleUploadFiles = () => {
        // let travelRequestDocumentsTemp = [];

        // files.forEach((file) => {
        //     //uploadedFile(file);

        //     blobToBase64(new Blob([file])).then(res => {
        //         travelRequestDocumentsTemp.push(
        //             {
        //                 "documentID": file.id,
        //                 "documentType": file.type,
        //                 "documentOriginalName": file.name,
        //                 "documentName": file.name,
        //                 "documentBinaryContent": res,
        //                 "remarks": document.querySelector("#remarks").value
        //             }
        //         );

        //         if (sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
        //             let travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
        //             travelRequestFieldsDataJson.travelRequestDocuments = travelRequestDocumentsTemp;
        //             sessionStorage.setItem("travelRequestFieldsDataJson", JSON.stringify(travelRequestFieldsDataJson));
        //         }
        //     });
        // })
    }

    async function uploadedFile(file) {
        // let blob = new Blob([file]);
        // console.log(await blob.text())        
    }

    function onBlurCheck() {
        if (document.querySelector('#remarks').value !== '') {
            if (!document.querySelector('#remarks').value.match('^[a-zA-Z . ( ) ! 0-9/,-/<>\n]+$')) {
                setRemarksInvalidInputError(true);
                return;
            }
            else {
                setRemarksInvalidInputError(false);
            }
        }

        if (sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
            let travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));

            if (travelRequestFieldsDataJson.travelRequestDocuments !== undefined && travelRequestFieldsDataJson.travelRequestDocuments !== null && travelRequestFieldsDataJson.travelRequestDocuments.length > 0) {
                travelRequestFieldsDataJson.travelRequestDocuments.forEach(element => {
                    element.remarks = document.querySelector("#remarks").value;
                });
                sessionStorage.setItem("travelRequestFieldsDataJson", JSON.stringify(travelRequestFieldsDataJson));
            }
            else {
                let travelRequestDocuments = [{
                    "documentID": "",
                    "documentType": "",
                    "documentOriginalName": "",
                    "documentName": "",
                    "documentBinaryContent": "",
                    "remarks": document.querySelector("#remarks").value
                }];
                travelRequestFieldsDataJson.travelRequestDocuments = travelRequestDocuments;
                sessionStorage.setItem("travelRequestFieldsDataJson", JSON.stringify(travelRequestFieldsDataJson));
            }
        }
    }

    const blobToBase64 = blob => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise(resolve => {
            reader.onloadend = () => {
                resolve(reader.result);
            };
        });
    };

    return (
        <Fragment>
            <ReactTooltip
                anchorId='Upload'
                place="bottom"
                variant="info"
                multiline={true}
                className="tooltip"
                html={"Upload File"}
            />
            <ReactTooltip
                anchorId='Remove'
                place="bottom"
                variant="info"
                multiline={true}
                className="tooltip"
                html={"Remove File"}
            />
            <div className="row position-relative">
                <div className="col-lg-12">
                    <div className="tour_search_form">
                        <form action="!#">
                            <div className='row mb-4'>
                                <div className='col-md-12'>
                                    <label class="h6 text-primary mb-2 col-lg-12" for="Service"><i class="fas fa-file"></i> Documents</label>
                                    <div className='col-md-12 mt-2'>
                                        <label class="h6 text-primary mb-2 col-lg-12" for="division">Name<span className="error-icon"> *</span></label>
                                        <input maxLength={100} className="form-control bg_input col-md-6" type='text'
                                            id='documentName' />
                                        <p className="error-text" id='documentNameErrorMsg'></p>
                                    </div>
                                    <div className="dropdown pb-2 col-md-12 mt-4">
                                        <div className="files position-relative">
                                            <Files
                                                className='files-dropzone text-center gt-file-dropzone col-md-12 col-sm-12 col-12'
                                                onChange={handleChange}
                                                //onError={handleError}
                                                accepts={['image/png', 'image/jpg', 'image/jpeg', '.pdf']}
                                                multiple={false}
                                                maxFileSize={10000000}
                                                minFileSize={0}
                                                clickable>
                                                <span id='Upload' className='file-upload text-primary'><i class="fas fa-plus-circle"></i><span> Drop files here or click to upload</span></span>
                                                <span id='loadingSpin' className='d-none'><i className="fa fa-spinner fa-spin text-primary ms-3" ></i></span>
                                            </Files>
                                            <p className="error-text" id='documentErrorMsg'></p>

                                            {files.length > 0 && (
                                                <div className="files-list mt-4">
                                                    <ul>
                                                        {files.map((file, index) => (
                                                            <li key={file.documentID} className="files-list-item">
                                                                {/* <div className="files-list-item-preview">
                                                                    {file.preview.type === 'image'
                                                                        ? <img className="files-list-item-preview-image" src={file.preview.url} />
                                                                        : <div className="files-list-item-preview-extension">{file.extension}</div>}
                                                                </div> */}
                                                                <div className="files-list-item-content">
                                                                    {
                                                                        file.documentOriginalName !== null && file.documentOriginalName !== '' &&
                                                                        <strong>File: </strong>
                                                                    }
                                                                    <span className="files-list-item-content-item files-list-item-content-item-1">{file.documentOriginalName !== null && file.documentOriginalName !== '' ? file.documentOriginalName : ''}</span>
                                                                    {
                                                                        file.documentName !== null && file.documentName !== '' &&
                                                                        <><br /><strong>Name: </strong></>
                                                                    }
                                                                    <span className="files-list-item-content-item files-list-item-content-item-1">{file.documentName !== null && file.documentName !== '' ? file.documentName : ''}</span>
                                                                    {/* <span className="files-list-item-content-item files-list-item-content-item-2">{file.sizeReadable}</span> */}
                                                                    <span id='Remove' className="files-list-item-remove mx-4 cursor-pointer" onClick={() => handleFileRemove(file.documentID)}>
                                                                        <i class="fas fa-times-circle h5 text-danger"></i>
                                                                    </span>
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )}
                                            {/* <button onClick={handleClearFiles}>Remove All Files</button> */}
                                            <button id='btnUpload' onClick={handleUploadFiles} className="btn btn_theme btn_md mt-2 mx-2 d-none" type="button" >Upload</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-4'>
                                <div className='col-md-12 dropdown-search-text'>
                                    <label class="h6 text-primary mb-2 col-lg-12" for="Remarks">Remarks<span className="error-icon"> *</span></label>
                                    <div className="dropdown pb-2 col-md-12">
                                        <textarea id='remarks'
                                            className="form-control form-control-sm"
                                            rows={3} cols={100} maxLength={300}
                                            onBlur={onBlurCheck}
                                        />
                                    </div>
                                    {remarksInvalidInputError &&
                                        <span className="error-text col-lg-12 mt-3">{('Please specify valid data.')}</span>
                                    }
                                    <p className="error-text" id='docRemarksErrorMsg'></p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Fragment>)

};

export default Document;

