import { Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SEARCH_ALL_AIRLINE } from '../../../services/CONSTANTS';
//import './App.css';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import Dropdown from 'react-select';
import { searchAllAirline } from '../../../services/Airline-API';
import useHttp from '../../../services/use-http';
import { getConfigurationByBoolen } from '../../../utils/ConfigurationsFunction';

const FlightSearchControlAdditionalPanel = ({ tripType, travelReq }) => {
    const { t } = useTranslation(['translation.SearchFlight']);
    const [preferredAirlineOneValue, setPreferredAirlineOneValue] = useState('');
    const [preferredAirlineTwoValue, setPreferredAirlineTwoValue] = useState('');
    const [preferredAirlineThreeValue, setPreferredAirlineThreeValue] = useState('');
    const [isadvance, setIsAdvance] = useState(false);
    const [checkMixedCabin, setCheckMixedCabin] = useState(false);
    const [flightContentDropdown, setFlightContentDropdown] = useState('');

    const { sendRequest: sendRequestAirline, statusAirline, data: loadedAirline } = useHttp(searchAllAirline);

    useEffect(() => {
        const onSearchAirline = ({
            airlineCode: "",
            airlineName: ""
        });

        sendRequestAirline(onSearchAirline);

        document.querySelector('#dvFareTypes').style.display = "none";
        sessionStorage.setItem('fareType', "regularFares");

        if (sessionStorage.getItem('preferenceOptions') !== null) {
            JSON.parse(sessionStorage.getItem('preferenceOptions')).forEach(element => {
                if (document.querySelector('#' + element.preferenceOptionId) !== null) {
                    document.querySelector('#' + element.preferenceOptionId).checked = true;
                }
            });
        }

        if (sessionStorage.getItem('fareType') !== null) {
            document.querySelector('#' + sessionStorage.getItem('fareType')).checked = true;
        }
        else {
            document.querySelector('#regularFares').checked = true;
            sessionStorage.setItem('fareType', "regularFares");
        }

        if (sessionStorage.getItem("preferredAirline1") !== null && sessionStorage.getItem("preferredAirline1") !== '') {
            setPreferredAirlineOneValue(sessionStorage.getItem("preferredAirline1").split('-')[1]);
        }
        else {
            sessionStorage.setItem("preferredAirline1", "");
            setPreferredAirlineOneValue('');
        }

        if (sessionStorage.getItem("preferredAirline2") !== null && sessionStorage.getItem("preferredAirline2") !== '') {
            setPreferredAirlineTwoValue(sessionStorage.getItem("preferredAirline2").split('-')[1]);
        }
        else {
            sessionStorage.setItem("preferredAirline2", "");
            setPreferredAirlineTwoValue('');
            //document.querySelector("#txtPreferredAirline2").placeholder = t('airline2');
        }

        if (sessionStorage.getItem("preferredAirline3") !== null && sessionStorage.getItem("preferredAirline3") !== '') {
            setPreferredAirlineThreeValue(sessionStorage.getItem("preferredAirline3").split('-')[1]);
        }
        else {
            sessionStorage.setItem("preferredAirline3", "");
            setPreferredAirlineThreeValue('');
            //document.querySelector("#txtPreferredAirline3").placeholder = t('airline3');
        }

        //User Story 1070: Change in Search for Single PNR
        if (sessionStorage.getItem('AddHotelCheck') !== null) {
            if (document.querySelector('#addHotel') !== null) {
                document.querySelector('#addHotel').checked = true;
            }
        }
        else {
            if (document.querySelector('#addHotel') !== null) {
                document.querySelector('#addHotel').checked = false;
            }
        }

        if (sessionStorage.getItem("flightContent") !== null && sessionStorage.getItem("flightContent") !== '') {
            setFlightContentDropdown(sessionStorage.getItem("flightContent"));
        }
        else {
            // handle content dropdown previous data, when move back from NDC single pnr
            if (getConfigurationByBoolen("ALLOW_NDC") && getConfigurationByBoolen("ALLOW_SOURCE_PCC_FARE")) {
                sessionStorage.setItem("flightContent", "1");
                sessionStorage.setItem("oldFlightContent", "1");
                setFlightContentDropdown('1');
            }
            else {
                sessionStorage.setItem("flightContent", "");
                sessionStorage.setItem("oldFlightContent", "");
                setFlightContentDropdown('');
            }
        }

    }, [tripType])

    useEffect(() => {
        if (sessionStorage.getItem('showAdance') === null && getConfigurationByBoolen('SHOW_ADVANCED_TAB') !== null && getConfigurationByBoolen('SHOW_ADVANCED_TAB') === true) {
            setIsAdvance(true);
            sessionStorage.setItem("showAdance", "true");
        }
        else {
            if (sessionStorage.getItem('showAdance') !== null) {
                if (sessionStorage.getItem('showAdance') === 'true') {
                    setIsAdvance(true);
                } else {
                    setIsAdvance(false);
                }
            }
        }

        if (getConfigurationByBoolen('CHECKED_MIXED_CABIN') !== null && getConfigurationByBoolen('CHECKED_MIXED_CABIN') === true) {
            setCheckMixedCabin(true);

            if (sessionStorage.getItem('preferenceOptions') === null) {
                let preferenceOptionsTemp = [];
                preferenceOptionsTemp.push({
                    preferenceOptionId: "showMixedCabins",
                    preferenceOptionValue: true
                });

                sessionStorage.setItem('preferenceOptions', JSON.stringify(preferenceOptionsTemp));
            }
            else {
                let preferenceOptions_ = JSON.parse(sessionStorage.getItem('preferenceOptions'));
                let showMixedCabins_ = preferenceOptions_.filter(a => a.preferenceOptionId === 'showMixedCabins' && a.preferenceOptionValue === true);

                if (showMixedCabins_.length <= 0) {
                    preferenceOptions_.push({
                        preferenceOptionId: "showMixedCabins",
                        preferenceOptionValue: true
                    })
                }
            }
        }
        else {
            if (sessionStorage.getItem('preferenceOptions') !== null) {
                let preferenceOptions_ = JSON.parse(sessionStorage.getItem('preferenceOptions'));
                let showMixedCabins_ = preferenceOptions_.filter(a => a.preferenceOptionId === 'showMixedCabins' && a.preferenceOptionValue === true);

                if (showMixedCabins_.length > 0) {
                    setCheckMixedCabin(true);
                }
            }
        }

    }, [])

    if (sessionStorage.getItem('preferenceOptions') !== null) {
        let preferenceOptions_ = JSON.parse(sessionStorage.getItem('preferenceOptions'));
        preferenceOptions_.forEach(elementPreferenceOptions_ => {
            if (document.querySelector('#' + elementPreferenceOptions_.preferenceOptionId) !== null) {
                document.querySelector('#' + elementPreferenceOptions_.preferenceOptionId).checked = elementPreferenceOptions_.preferenceOptionValue !== undefined && elementPreferenceOptions_.preferenceOptionValue !== null && elementPreferenceOptions_.preferenceOptionValue !== '' ? elementPreferenceOptions_.preferenceOptionValue : false;
            }
        });
    }

    let airlineArrayData = [];
    for (let i in loadedAirline) {
        if (loadedAirline['errors'].status === 'FALSE') {
            if (i === 'data') {
                airlineArrayData = loadedAirline[i];
            }
        }
    }

    let airlineOptions = [];
    for (let index = 0; index < airlineArrayData.length; index++) {
        airlineOptions = [...airlineOptions, { value: airlineArrayData[index].airlineCode, label: airlineArrayData[index].airlineName + " (" + airlineArrayData[index].airlineCode + ")" }]
    }

    airlineOptions = [{ value: '', label: t('Please Select') }, ...airlineOptions]

    const advanceChangeHandler = () => {
        let show;
        if (isadvance) {
            setIsAdvance(false)
            show = "false"
        } else {
            setIsAdvance(true)
            show = "true"
        }

        sessionStorage.setItem("showAdance", show);
    }

    const onPreferenceOptionsChangeHandler = (event) => {
        let preferenceOptionsTemp = [];
        if (document.querySelector('#showMixedCabins').checked) {
            setCheckMixedCabin(true);
        }
        else {
            setCheckMixedCabin(false);
        }

        // if (sessionStorage.getItem('preferenceOptions') !== null) {
        //     preferenceOptionsTemp = JSON.parse(sessionStorage.getItem('preferenceOptions'));
        // }

        var inputs = document.querySelectorAll('.preference-options');
        for (var i = 0; i < inputs.length; i++) {
            if (inputs[i].checked) {
                preferenceOptionsTemp.push({
                    preferenceOptionId: inputs[i].getAttribute("id"),
                    preferenceOptionValue: true
                });
            }
        }

        sessionStorage.setItem('preferenceOptions', JSON.stringify(preferenceOptionsTemp));
    }

    const onFareTypeChangeHandler = (event) => {
        sessionStorage.setItem('fareType', event.target.id);
    }

    const preferredAirlineOneChangeHandler = (event) => {
        if (event.value !== '') {
            sessionStorage.setItem("preferredAirline1", `${event.label}-${event.value}`);
            setPreferredAirlineOneValue(event.value);
        }
        else {
            sessionStorage.setItem("preferredAirline1", '');
            setPreferredAirlineOneValue('');
        }
    };

    const preferredAirlineTwoChangeHandler = (event) => {
        if (event.value !== '') {
            sessionStorage.setItem("preferredAirline2", `${event.label}-${event.value}`);
            setPreferredAirlineTwoValue(event.value);
        }
        else {
            sessionStorage.setItem("preferredAirline2", '');
            setPreferredAirlineTwoValue('');
        }
    };

    const preferredAirlineThreeChangeHandler = (event) => {
        if (event.value !== '') {
            sessionStorage.setItem("preferredAirline3", `${event.label}-${event.value}`);
            setPreferredAirlineThreeValue(event.value);
        }
        else {
            sessionStorage.setItem("preferredAirline3", '');
            setPreferredAirlineThreeValue('');
        }
    };

    let flightContentOptions = getConfigurationByBoolen("ALLOW_LCC") ? [
        { value: '1', label: 'Show All' },
        { value: '2', label: 'Show Sabre Only' },
        { value: '3', label: 'Show NDC Only' },
        { value: '4', label: 'Show LCC Only' }
    ] : [
        { value: '1', label: 'Show All' },
        { value: '2', label: 'Show Sabre Only' },
        { value: '3', label: 'Show NDC Only' }
    ];

    const flightContentChangeHandler = (event) => {
        if (event.value !== '') {
            sessionStorage.setItem("flightContent", event.value);
            sessionStorage.setItem("oldFlightContent", event.value);
            setFlightContentDropdown(event.value);
        }
        else {
            sessionStorage.setItem("flightContent", '');
            sessionStorage.setItem("oldFlightContent", "");
            setFlightContentDropdown('');
        }
    };

    return <Fragment>
        <div className='row'>
            <div className="control col-md-3 mb-0 mt-2">
                <div className="form-switch form-switch-sm padding-left-0">
                    <label for="advance" className="mb-2 h6 text-primary me-2">Advanced</label>
                    <input id="advance" className="form-check-input ms-1" type="checkbox" checked={isadvance} onChange={advanceChangeHandler} />
                </div>
            </div>

        </div>
        {isadvance && <div className="row">
            <div className='col-lg-2 col-md-2 my-auto col-sm-12 dropdown-search-text'>
                <div className='col-lg-12 text-muted my-1 fw-bold text-16px'>{t('preferredairlines')} </div>
            </div>
            <div className='col-lg-10 col-md-10 col-sm-12 search-input-tour'>
                <div className='row'>
                    <div className='col-lg-4 col-sm-12 dropdown-search-text dropdown-mt-5px mt-2'>
                        <Dropdown onChange={preferredAirlineOneChangeHandler}
                            value={airlineOptions.filter(option => option.value === preferredAirlineOneValue)}
                            options={airlineOptions}
                            textField="label"
                            dataItemKey="value"
                            arrowClosed={<span className="arrow-closed" />}
                            arrowOpen={<span className="arrow-open" />} controlClassName='mt-1 form-control control dropdown-search form-select bg_input mb-0'
                            placeholder={t('airline1')}
                        />
                    </div>
                    <div className='col-lg-4 col-sm-12 dropdown-search-text dropdown-mt-5px mt-2'>
                        <Dropdown onChange={preferredAirlineTwoChangeHandler}
                            value={airlineOptions.filter(option => option.value === preferredAirlineTwoValue)}
                            options={airlineOptions}
                            textField="label"
                            dataItemKey="value"
                            arrowClosed={<span className="arrow-closed" />}
                            arrowOpen={<span className="arrow-open" />} controlClassName='mt-1 form-control control dropdown-search form-select bg_input mb-0'
                            placeholder={t('airline2')}
                        />
                    </div>
                    <div className='col-lg-4 col-sm-12 dropdown-search-text dropdown-mt-5px mt-2'>
                        <Dropdown onChange={preferredAirlineThreeChangeHandler}
                            value={airlineOptions.filter(option => option.value === preferredAirlineThreeValue)}
                            options={airlineOptions}
                            textField="label"
                            dataItemKey="value"
                            arrowClosed={<span className="arrow-closed" />}
                            arrowOpen={<span className="arrow-open" />} controlClassName='mt-1 form-control control dropdown-search form-select bg_input mb-0'
                            placeholder={t('airline3')}
                        />
                    </div>
                </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt-2">
                <div className='row'>
                    {/* <div className='col-lg-1 text-muted mb-1 fw-bold text-12px'></div> */}
                    <div className='col-lg-12'>
                        <div className='row'>
                            {/* <div className="col-lg-2 col-md-2 col-sm-6 text-12px text-muted">
                                <div className='form-check'>
                                    <input className="form-check-input preference-options" type="checkbox" id="flexibleDates"
                                        onChange={(e) => onPreferenceOptionsChangeHandler(e)}></input>
                                    <label className="form-check-label mb-0" for="flexibleDates">
                                        {t('flexibledates')}
                                    </label>
                                </div>
                            </div> */}
                            <div className='d-lg-flex d-md-flex  search-flight-checkbox'>
                                <span className="text-16px">
                                    <div className='form-check'>
                                        <input className="form-check-input preference-options" type="checkbox" id="directFlights"
                                            onChange={(e) => onPreferenceOptionsChangeHandler(e)}></input>
                                        <label className={travelReq !== undefined && travelReq === true ? "form-check-label-policy-in-out mb-0" : "form-check-label mb-0"} for="directFlights">
                                            {t('directflights')}
                                        </label>
                                    </div>
                                </span>
                                <span className="ms-3 text-16px">
                                    <div className='form-check'>
                                        <input className="form-check-input preference-options" type="checkbox" id="flexibleFares"
                                            onChange={(e) => onPreferenceOptionsChangeHandler(e)}></input>
                                        <label className={travelReq !== undefined && travelReq === true ? "form-check-label-policy-in-out mb-0" : "form-check-label mb-0"} for="flexibleFares">
                                            {t('flexiblefares')}
                                        </label>
                                    </div>
                                </span>
                                <span className="ms-3 text-16px">
                                    <div className='form-check'>
                                        <input className="form-check-input preference-options" type="checkbox" id="nearByAirportSearch"
                                            onChange={(e) => onPreferenceOptionsChangeHandler(e)}></input>
                                        <label className={travelReq !== undefined && travelReq === true ? "form-check-label-policy-in-out mb-0" : "form-check-label mb-0"} for="nearByAirportSearch">
                                            {t('nearbyairportsearch')}
                                        </label>
                                    </div>
                                </span>
                                <span className="ms-3 text-16px">
                                    <div className='form-check'>
                                        <input className="form-check-input preference-options" type="checkbox" id="showMixedCabins"
                                            onChange={(e) => onPreferenceOptionsChangeHandler(e)} checked={checkMixedCabin}></input>
                                        <label className={travelReq !== undefined && travelReq === true ? "form-check-label-policy-in-out mb-0" : "form-check-label mb-0"} for="showMixedCabins">
                                            {t('showmixedcabins')}
                                        </label>
                                    </div>
                                </span>
                                <span className="ms-3 text-16px ">
                                    <div className='form-check'>
                                        <input className="form-check-input preference-options" type="checkbox" id="includeBagOnlyFares"
                                            onChange={(e) => onPreferenceOptionsChangeHandler(e)}></input>
                                        <label className={travelReq !== undefined && travelReq === true ? "form-check-label-policy-in-out mb-0" : "form-check-label mb-0"} for="includeBagOnlyFares">
                                            {t('includebagonlyfares')}
                                        </label>
                                    </div>
                                </span>
                                <span className="ms-3 text-16px ">
                                    <div className='form-check'>
                                        <input className="form-check-input preference-options" type="checkbox" id="includeCabinBagOnlyFares"
                                            onChange={(e) => onPreferenceOptionsChangeHandler(e)}></input>
                                        <label className={travelReq !== undefined && travelReq === true ? "form-check-label-policy-in-out mb-0" : "form-check-label mb-0"} for="includeCabinBagOnlyFares">
                                            {t('includecabinbagonlyfares')}
                                        </label>
                                    </div>
                                </span>
                                <span className="ms-3 text-16px d-none">
                                    <div className='form-check'>
                                        <input className="form-check-input preference-options" type="checkbox" id="excludeBasicEconomy"
                                            onChange={(e) => onPreferenceOptionsChangeHandler(e)}></input>
                                        <label className={travelReq !== undefined && travelReq === true ? "form-check-label-policy-in-out mb-0" : "form-check-label mb-0"} for="excludeBasicEconomy">
                                            {t('excludebasiceconomy')}
                                        </label>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                getConfigurationByBoolen("ALLOW_NDC") && getConfigurationByBoolen("ALLOW_SOURCE_PCC_FARE") && (sessionStorage.getItem("serviceType") !== 'lblFlightPlusHotel') &&
                <div className='col-lg-12 col-md-12 col-sm-12 search-input-tour mt-3 contentArea'>
                    <div className='row'>
                        <div className='col-lg-4 col-md-4 col-sm-12 dropdown-search-text dropdown-mt-5px margin-top-15 mt-2'>
                            <div className=''>
                                <label className="text-16px fw-500 text-primary col-lg-4">Content</label>
                                <Dropdown onChange={flightContentChangeHandler}
                                    value={flightContentOptions.filter(option => option.value === sessionStorage.getItem("flightContent"))}
                                    options={flightContentOptions}
                                    textField="label"
                                    dataItemKey="value"
                                    arrowClosed={<span className="arrow-closed" />}
                                    arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }

        </div>}

        {<div className='row mt-2'>

        </div>}
        <div className='row' id='dvFareTypes'>
            <div className='col-lg-2 text-muted mb-1 fw-bold text-12px'>{t('faretype')} </div>
            <div className="col-lg-2 col-md-2 col-sm-6 text-12px text-muted">
                <div className="form-check">
                    <input className="form-check-input" type="radio" name="fareType" id="regularFares"
                        onChange={(e) => onFareTypeChangeHandler(e)} />
                    <label className="form-check-label" for="regularFares">
                        {t('regularfares')}
                    </label>
                </div>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-6 text-12px text-muted">
                <div className="form-check">
                    <input className="form-check-input" type="radio" name="fareType" id="seniorCitizenFares"
                        onChange={(e) => onFareTypeChangeHandler(e)} />
                    <label className="form-check-label" for="seniorCitizenFares">
                        {t('seniorcitizenfares')}
                    </label>
                </div>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-6 text-12px text-muted">
                <div className="form-check">
                    <input className="form-check-input" type="radio" name="fareType" id="doubleSeatFares"
                        onChange={(e) => onFareTypeChangeHandler(e)} />
                    <label className="form-check-label" for="doubleSeatFares">
                        {t('doubleseatfares')}
                    </label>
                </div>
            </div>
        </div>
    </Fragment>
};

export default FlightSearchControlAdditionalPanel;