import { useHistory, useLocation } from 'react-router-dom';
import { Fragment, useState, useRef, useEffect } from 'react';
import ConfirmationModal from "../../../components/Common/Confirmation/ConfirmationModal";
import { useTranslation } from 'react-i18next';
import * as React from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";

import { BOOKING } from '../../../navigation/CONSTANTS';
import FlightDetails from './FlightDetails';
import FareSummary from './FareSummary';
import PopupModal from './PopupModal';
import PrintPopupModal from './PrintPopupModal';
import SendEmail from '../../../pages/BookingConfirmation/SendEmail';
import PrintItiniary from './PrintItiniary';
import { getMinutetoHrsMin, getFormatDate, getFormatTime } from './../../../utils/CommonFunction';
import { CreateBookingJSON } from './../../../utils/BookingFunction';
import SeatMap from './SeatMap';
import FareRule from './FareRule.';
import FareRulePopupModal from './FareRulePopupModal';
import { CreateBookingJSONShoppingCart } from './../../../utils/ShoppingCartFunction';
import { insertItem, removeItem } from '../../../utils/SendRecommendation';
import { getConfigurationByBoolen, getConfigurationByValue } from '../../../utils/ConfigurationsFunction';
import ErrorModal from '../Error/ErrorModal';
import moment from 'moment';
import OutPolicy from '../../../pages/OBT/TravelRequest/Components/OutPolicy';
import { priceCheck } from '../../../services/Flight-API';
import useHttp from '../../../services/use-http';
import queryString from 'query-string';
import { decompress } from '@amoutonbrady/lz-string';
import { checkSecurityDetails } from '../../../utils/RoleUtils';
import OutPolicyPopupModal from './OutPolicyPopupModal';


const FlightItem = (props) => {
    const { sendRequest, status, data: loadedPriceCheckResponse } = useHttp(priceCheck);
    let hidePrint = props.showData === '1' ? 'position-relative' : 'position-relative print-d-none';
    const airlineLogoLink = process.env.REACT_APP_FLIGHT_LOG_URL
    const [error1, setError] = useState();
    const [errorPrint, setErrorPrint] = useState();
    const [errorMail, setErrorMail] = useState();
    const [errorFareSummary, setFareSummaryError] = useState();
    const [flightLimitError, setFlightLimitError] = useState();
    const [errorOutPolicy, setErrorOutPolicy] = useState();
    const [justificationTextData, setJustificationTextData] = useState();
    const [upgradeCbainConfirm, setUpgradeCbainConfirm] = useState();
    const [isInPolicy, setIsInPolicy] = useState();

    const history = useHistory();
    const location = useLocation();
    const valueParam = queryString.parse(location.search);

    let result = props.airItinerary.originDestinationOptions.originDestinationOption;
    let sourceId = props.searchFlight.sourceId === undefined ? props.sourceId : props.searchFlight.sourceId;
    let source = props.searchFlight.sourceName !== undefined ? props.searchFlight.sourceName : props.searchFlight.SourceName;// sourceId === undefined ? "Sabre" : sourceId === "1S" ? "Sabre" : "Amadeus";
    let fareType = props.searchFlight.airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo[0].fareType;
    let pcc = props.searchFlight.airItineraryPricingInfo.passengerTypeQuantity[0].commisionInfo === undefined ? "" : props.searchFlight.airItineraryPricingInfo.passengerTypeQuantity[0].commisionInfo[0].sourcePCC;
    let totalFare = props.searchFlight.airItineraryPricingInfo.totalFare.amount;
    let currency = props.searchFlight.airItineraryPricingInfo.totalFare.currencyCode;
    let markupValue = 0;
    let markupUnit = "";

    props.searchFlight.airItineraryPricingInfo.passengerTypeQuantity.forEach(elementPassengerTypeQuantity => {
        if (elementPassengerTypeQuantity.commisionInfo !== undefined) {
            elementPassengerTypeQuantity.commisionInfo.forEach(elementCommisionInfo => {
                if (elementCommisionInfo.earnedCommissionAmount !== undefined && elementCommisionInfo.earnedCommissionAmount !== '' && elementCommisionInfo.earnedCommissionAmount !== null) {
                    markupValue = markupValue + (parseFloat(elementCommisionInfo.earnedCommissionAmount) * parseInt(elementPassengerTypeQuantity.quantity))
                    markupUnit = elementCommisionInfo.currency
                }
            });
        }
    });
    let seatAvailabe = parseInt(props.searchFlight.airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo[0].segments !== undefined ? props.searchFlight.airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo[0].segments.segment[0].seatAvailable : 0);
    let seat = seatAvailabe > 1 ? "seats" : "seat";

    let rbdValue;
    let outBound_departure_details = [];
    let inBound_departure_details = [];
    let outBound_arrival_details = [];
    let inBound_arrival_details = [];
    let outBound_operating_details = [];
    let multicity_dep_details_map = new Map([]);
    let multicity_arr_details_map = new Map([]);
    let inbound_operating_details = [];
    let multicityFlightCount;
    let fareArray = [];

    let classNameUpdated = "";
    let classNameUpdatedList = "";
    if (location.pathname === '/RetrieveTrip' || location.pathname === '/Confirmation') {
        classNameUpdated = "flight_mytrip_items";
        classNameUpdatedList = "multi_trip_flight_lists w-100";
    }
    else if (location.pathname === '/TravelRequest') {
        classNameUpdated = "flight_search_items";
        classNameUpdatedList = "multi_city_flight_lists w-100  flight-full-border";
    }
    else {
        classNameUpdated = "flight_search_items";
        classNameUpdatedList = "multi_city_flight_lists w-100";
    }

    const getBaggageUnitTextData = (baggageUnit, item) => {
        //let data = (baggageUnit.toLowerCase().includes('p') || baggageUnit.toLowerCase().includes('k')) && (!baggageUnit.includes('(')) ? '' : item.unit === undefined ? item.maxWeight === undefined ? baggageUnit === '1' ? "piece" : 'pieces' : baggageUnit === '1' ? "kg" : 'kg' : item.unit;
        let data = baggageUnit.includes('(') ? '' : baggageUnit.toLowerCase().includes('p') || baggageUnit.toLowerCase().includes('k') ? '' : item.unit === undefined ? item.maxWeight === undefined ? baggageUnit === '1' ? "piece" : 'pieces' : baggageUnit === '1' ? "kg" : 'kg' : item.unit;
        return data;
    }

    const getRefundType = (list) => {
        let refundType = [];
        if (list.airItineraryPricingInfo.passengerTypeQuantity !== undefined && list.airItineraryPricingInfo.passengerTypeQuantity[0] !== undefined && list.airItineraryPricingInfo.passengerTypeQuantity[0].refundableType !== undefined) {
            if (list.airItineraryPricingInfo.passengerTypeQuantity[0].refundableType === 0 || list.airItineraryPricingInfo.passengerTypeQuantity[0].refundableType === "0") {
                refundType.push(<div className="text-14px text-danger px-3"><i className="fas fa-money-check-alt"></i> Non Refundable</div>);
            }
            else if (list.airItineraryPricingInfo.passengerTypeQuantity[0].refundableType === 1 || list.airItineraryPricingInfo.passengerTypeQuantity[0].refundableType === "1") {
                refundType.push(<div className="text-14px text-light-green px-3"><i className="fas fa-money-check-alt"></i> Fully Refundable</div>);
            }
            else if (list.airItineraryPricingInfo.passengerTypeQuantity[0].refundableType === 2 || list.airItineraryPricingInfo.passengerTypeQuantity[0].refundableType === "2") {
                refundType.push(<div className="text-14px text-sky-blue px-3"><i className="fas fa-money-check-alt"></i> Partially Refundable</div>);
            }
            return refundType;
        }
        else if (list.airItineraryPricingInfo.passengerTypeQuantity !== undefined && list.airItineraryPricingInfo.passengerTypeQuantity[0] !== undefined && list.airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos !== undefined && list.airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo !== undefined) {
            list.airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo.forEach(elementFareInfo => {
                if (elementFareInfo.ruleInfo !== undefined && Object.keys(elementFareInfo.ruleInfo).length > 0) {
                    elementFareInfo.ruleInfo !== undefined && elementFareInfo.ruleInfo.chargesRules !== undefined && elementFareInfo.ruleInfo.chargesRules.voluntaryRefunds !== undefined && elementFareInfo.ruleInfo.chargesRules.voluntaryRefunds.penalty !== undefined &&
                        elementFareInfo.ruleInfo.chargesRules.voluntaryRefunds.penalty.length !== 0
                        && elementFareInfo.ruleInfo.chargesRules.voluntaryRefunds.penalty.forEach(element => {
                            if (element.conditions !== undefined) {
                                if (element.amount === undefined && element.departureStatus === 'Before' && element.conditions === 'NONREFUNDABLE') {
                                    refundType.push(<div className="text-14px text-danger px-3"><i className="fas fa-money-check-alt"></i> Non
                                        Refundable</div>);
                                }
                                else if (element.amount !== undefined && parseInt(element.amount) === 0 && element.departureStatus === 'Before' && element.conditions === 'FULL') {
                                    refundType.push(<div className="text-14px text-light-green px-3"><i className="fas fa-money-check-alt"></i> Fully Refundable</div>)
                                } else if (element.amount !== undefined && parseInt(element.amount) > 0 && element.departureStatus === 'Before' && element.conditions === 'PARTIAL') {
                                    refundType.push(<div className="text-14px text-sky-blue px-3"><i className="fas fa-money-check-alt"></i> Partially Refundable</div>)
                                }
                            }

                        });
                }
            });
        }

        return refundType.length > 1 ? refundType[0] : refundType;

    }

    const onBookNow = (input) => {
        if (props.travelReq !== undefined && props.travelReq === 'true') {
            let upgradeCabinClass = "";
            let currentCabinClass = "";
            if (sessionStorage.getItem('initialFlightResults') !== null && sessionStorage.getItem('initialFlightResults') !== '') {
                upgradeCabinClass = sessionStorage.getItem("upgradeCabinClass") !== null && sessionStorage.getItem("upgradeCabinClass") !== undefined ? sessionStorage.getItem("upgradeCabinClass") : '';
                let flightResults = JSON.parse(decompress(sessionStorage.getItem('initialFlightResults')));
                currentCabinClass = flightResults[0].airItinerary.originDestinationOptions.originDestinationOption[0].flightSegment[0].bookingClassAvails !== undefined && flightResults[0].airItinerary.originDestinationOptions.originDestinationOption[0].flightSegment[0].bookingClassAvails[0] !== undefined && flightResults[0].airItinerary.originDestinationOptions.originDestinationOption[0].flightSegment[0].bookingClassAvails[0].cabinName !== undefined ? flightResults[0].airItinerary.originDestinationOptions.originDestinationOption[0].flightSegment[0].bookingClassAvails[0].cabinName : '';
            }

            if ((upgradeCabinClass !== '' && currentCabinClass !== '') && (currentCabinClass !== upgradeCabinClass)) {
                setIsInPolicy(input !== undefined && input !== '' && input === true ? true : false);
                setUpgradeCbainConfirm({
                    title: "Message",
                    message: `You are eligible to upgrade the cabin class ${currentCabinClass} to ${upgradeCabinClass}. Do you want to process with upgrade cabin?`,
                });
            }
            else {
                if (sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
                    let travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
                    travelRequestFieldsDataJson.justificationCodeFlight = {};
                    travelRequestFieldsDataJson.justificationDetailsFlight = {};
                    sessionStorage.setItem("travelRequestFieldsDataJson", JSON.stringify(travelRequestFieldsDataJson));
                }

                if (input !== undefined && input !== '' && input === true) {
                    localStorage.removeItem("SeatMapData");
                    localStorage.removeItem("AdditionalServicesData");
                    sessionStorage.removeItem("hotelSessionDataJson");
                    sessionStorage.removeItem("singlePnrCheckOutMinvalue");

                    let bookingJSON = CreateBookingJSON(props.searchFlight)
                    //bookingJSON.data.airItinerary[0].SourceName = props.searchFlight.sourceName;
                    localStorage.setItem("BookingDataTravelRequest", JSON.stringify(bookingJSON))

                    history.push('/TravelRequest?return=1&service=flight');
                }
                else if (input !== undefined && input !== '' && input === false) {
                    OutPolicyPopup();
                }
                else if (input === undefined || input === '') {
                    OutPolicyPopup();
                }
            }
        }
        else {
            localStorage.removeItem("SeatMapData");
            localStorage.removeItem("AdditionalServicesData");
            sessionStorage.removeItem("hotelSessionDataJson");

            //User Story 1070: Change in Search for Single PNR
            let bookingJSON = "";
            if (localStorage.getItem("AddHotel") !== null && localStorage.getItem("AddHotel") === "1") {
                bookingJSON = CreateBookingJSONShoppingCart(props.searchFlight, "FL")
                localStorage.setItem("BookingData", JSON.stringify(bookingJSON))

                let minToDateChangeTemp = new Date(sessionStorage.getItem("calFrom1").replace("'", ""));
                minToDateChangeTemp.setDate(minToDateChangeTemp.getDate() + 1);
                sessionStorage.setItem("singlePnrCheckOutMinvalue", moment(new Date(minToDateChangeTemp)).format("ddd, MMM DD, 'YY"));

                let soureceForPriceCheck = false;

                if (getConfigurationByBoolen("ALLOW_NDC_PRICE_REVALIDATE") && source.trim().toUpperCase() === "NDC") {
                    soureceForPriceCheck = true;
                } else if (getConfigurationByBoolen("ALLOW_SABRE_PRICE_REVALIDATE") && source.trim().toUpperCase() === "SABRE") {
                    soureceForPriceCheck = true;
                } else if (source.trim().toUpperCase() === "LCC" || source.trim().toUpperCase() === "TJ") {
                    soureceForPriceCheck = true;
                }

                if (soureceForPriceCheck) {
                    sendRequest(bookingJSON);
                }
                else {
                    history.push('/SearchHotel?addhotel=1');
                }
            }
            else {
                bookingJSON = CreateBookingJSON(props.searchFlight)
                localStorage.setItem("BookingData", JSON.stringify(bookingJSON))
                sessionStorage.removeItem("singlePnrCheckOutMinvalue");

                let soureceForPriceCheck = false;

                if (getConfigurationByBoolen("ALLOW_NDC_PRICE_REVALIDATE") && source.trim().toUpperCase() === "NDC") {
                    soureceForPriceCheck = true;
                } else if (getConfigurationByBoolen("ALLOW_SABRE_PRICE_REVALIDATE") && source.trim().toUpperCase() === "SABRE") {
                    soureceForPriceCheck = true;
                } else if (source.trim().toUpperCase() === "LCC" || source.trim().toUpperCase() === "TJ") {
                    soureceForPriceCheck = true;
                }

                if (soureceForPriceCheck) {
                    sendRequest(bookingJSON);
                }
                else {
                    history.push(BOOKING);
                }
            }
        }
    }

    useEffect(() => {
        if (status === 'completed') {
            if (loadedPriceCheckResponse !== null && loadedPriceCheckResponse.data !== null) {

                if (loadedPriceCheckResponse.data.errors.status === 'TRUE') {
                    setError({
                        title: "Message",
                        message: sourceId === 'TJ' ? "The airline is unable to confirm the price. Please select another flight." : loadedPriceCheckResponse.data.errors.error.description,
                    });
                }
                else {
                    if (loadedPriceCheckResponse.data.airItinerary[0].airItineraryPricingInfo.priceChange) {
                        let bookingJSON = JSON.parse(localStorage.getItem("BookingData"));

                        setConfirm({
                            title: "Message",
                            message: `Airline has changed the price from ${new Intl.NumberFormat('en-US', { style: 'currency', currency: currency }).format(bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.amount)} to ${new Intl.NumberFormat('en-US', { style: 'currency', currency: currency }).format(loadedPriceCheckResponse.data.airItinerary[0].airItineraryPricingInfo.totalFare.amount)} Do you want to proceed with the new price?`,
                        });
                    } else {
                        //no need to update booking json if no price change. so commented the code

                        let bookingJSON = JSON.parse(localStorage.getItem("BookingData"));
                        bookingJSON.data.airItinerary[0].airItineraryPricingInfo = loadedPriceCheckResponse.data.airItinerary[0].airItineraryPricingInfo;
                        bookingJSON.data.airItinerary[0].conditions = loadedPriceCheckResponse.data.airItinerary[0].conditions !== undefined ? loadedPriceCheckResponse.data.airItinerary[0].conditions : null;
                        localStorage.setItem("BookingData", JSON.stringify(bookingJSON))

                        if (localStorage.getItem("AddHotel") !== null && localStorage.getItem("AddHotel") === "1") {
                            history.push('/SearchHotel?addhotel=1');
                        }
                        else {
                            history.push(BOOKING);
                        }
                    }
                }
            }
            else {
                setError({
                    title: "Message",
                    message: `Sorry, we have an encountered an issue. Please try again.`,
                });
            }
            return;
        }
    }, [status, history, loadedPriceCheckResponse])

    useEffect(() => {
        let flightList = [];
        if (sessionStorage.getItem("flightListJson") !== null) {
            flightList = JSON.parse(sessionStorage.getItem("flightListJson"));
            flightList.length !== 0 && flightList.forEach(element => {
                let el = document.getElementById('addToProposal' + element.sequenceNumber)
                if (el !== null) {
                    el.checked = true;
                }
            });

        }
    }, [])

    //User Story 2797: Send Email Recommendation - Multiple flights
    const addFlightChangeHandler = (event, key, flightData) => {
        let flightList = [];
        if (event.target.checked === true) {
            let box = null;
            // if (document.getElementById('animated-email-div' + key)) {

            //     box = document.getElementById('animated-email-div' + key);
            //     box.style.display = 'block';
            // }
            // setTimeout(() => {
            //     if (box) {
            //         box.style.display = 'none';
            //     }
            //     // 👇️ removes element from DOM


            //     // 👇️ hides element (still takes up space on page)
            //     // box.style.visibility = 'hidden';
            // }, 3000); // 👈️ time in milliseconds


            if (sessionStorage.getItem("flightListJson") !== null) {
                flightList = JSON.parse(sessionStorage.getItem("flightListJson"));

            }
            if (flightList.length < getConfigurationByValue('NUMBER_OF_FLIGHT_PROPOSAL')) {
                insertItem(key, flightData);
            } else {
                setFlightLimitError(true);
                document.getElementById('addToProposal' + key).checked = false
            }


        } else {
            removeItem(key, flightData)
        }
    }

    const errorHandler = () => {
        setError(null);
        setErrorPrint(null);
        setErrorMail(null);
        setFareSummaryError(null);
        setFlightLimitError(null);
    };

    const errorHandlerOutPolicy = () => {
        let travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
        if ((justificationTextData === undefined || justificationTextData === '') && travelRequestFieldsDataJson.justificationDetailsFlight.length === undefined) {
            document.querySelector('#policyReasonErrorMsg').innerHTML = "Reason is required.";
            return;
        }
        else {
            document.querySelector('#policyReasonErrorMsg').innerHTML = "";
        }

        if (justificationTextData === 'No other suitable option available' && travelRequestFieldsDataJson.justificationDetailsFlight.length === undefined) {
            document.querySelector('#policyRemarksErrorMsg').innerHTML = "Remarks is required.";
            return;
        }
        else {
            document.querySelector('#policyRemarksErrorMsg').innerHTML = "";
        }

        setErrorOutPolicy(null);
        let bookingJSON = CreateBookingJSON(props.searchFlight)
        localStorage.setItem("BookingDataTravelRequest", JSON.stringify(bookingJSON))
        history.push('/TravelRequest?return=1&service=flight');
    };

    const errorHandlerOutPolicyClose = () => {
        setErrorOutPolicy(null);

        // let bookingJSON = CreateBookingJSON(props.searchFlight)
        // localStorage.setItem("BookingDataTravelRequest", JSON.stringify(bookingJSON))

        // history.push('/TravelRequest?return=1&service=flight');

    };

    let baggageAllowance = "";
    let baggageAllowanceUnit = "";
    rbdValue = result[0].flightSegment[0].resBookDesigCode;
    for (let i in result) {
        baggageAllowance = result[i].segmentRefDetail[0].pax[0] === undefined ? "" : result[i].segmentRefDetail[0].pax[0].baggageAllowance === undefined || result[i].segmentRefDetail[0].pax[0].baggageAllowance[0] === undefined ? "" : result[i].segmentRefDetail[0].pax[0].baggageAllowance[0].maxWeight === undefined ? result[i].segmentRefDetail[0].pax[0].baggageAllowance[0].numberOfPieces === undefined ? "" : result[i].segmentRefDetail[0].pax[0].baggageAllowance[0].numberOfPieces === "1" ? result[i].segmentRefDetail[0].pax[0].baggageAllowance[0].numberOfPieces + " piece" : result[i].segmentRefDetail[0].pax[0].baggageAllowance[0].numberOfPieces + " pieces" : result[i].segmentRefDetail[0].pax[0].baggageAllowance[0].maxWeight;
        baggageAllowanceUnit = result[i].segmentRefDetail[0].pax[0] === undefined ? "" : result[i].segmentRefDetail[0].pax[0].baggageAllowance === undefined || result[i].segmentRefDetail[0].pax[0].baggageAllowance[0] === undefined ? "" : result[i].segmentRefDetail[0].pax[0].baggageAllowance[0].unit === undefined ? "" : result[i].segmentRefDetail[0].pax[0].baggageAllowance[0].unit;
        if (result[i].directionInd === 'I') {
            inBound_departure_details.push({
                departure: result[i].flightSegment[0].departureAirport, date: result[i].flightSegment[0].departureDate, time: result[i].flightSegment[0].departureTime, rph: result[i].rph, cabinName: result[i].flightSegment[0].bookingClassAvails[0].cabinName,
                flownMileageQty: result[i].flightSegment[0].flownMileageQty, duration: result[i].flightSegment[0].duration, dateChangeNbr: result[i].flightSegment[0].dateChangeNbr,
                equipmentName: result[i].flightSegment[0].equipment !== undefined ? result[i].flightSegment[0].equipment[0].name : '', elapsedLayoverTime: result[i].flightSegment[0].elapsedLayoverTime !== undefined ? result[i].flightSegment[0].elapsedLayoverTime : '',
                noOfStops: result[i].flightSegment[0].technicalStops !== undefined && result[i].flightSegment[0].technicalStops !== null ? result[i].flightSegment[0].technicalStops.noOfStops : 0,
                bag: result[i].segmentRefDetail[0].pax !== undefined ? result[i].segmentRefDetail[0].pax : "",
                flightRefNumber: result[i].flightSegment[0].flightPnrNumber,
                marketingAirline: result[i].flightSegment[0].marketingAirline,
                technicalStops: result[i].flightSegment[0].technicalStops !== undefined && result[i].flightSegment[0].technicalStops !== null ? result[i].flightSegment[0].technicalStops : "",
                operatingAirline: result[i].flightSegment[0].operatingAirline,
            })
            inBound_arrival_details.push({ arrival: result[i].flightSegment[0].arrivalAirport, date: result[i].flightSegment[0].arrivalDate, time: result[i].flightSegment[0].arrivalTime })
            inbound_operating_details.push(result[i].flightSegment[0].marketingAirline);
        } else if (result[i].directionInd === 'O') {
            outBound_departure_details.push({
                departure: result[i].flightSegment[0].departureAirport, date: result[i].flightSegment[0].departureDate, time: result[i].flightSegment[0].departureTime, rph: result[i].rph, cabinName: result[i].flightSegment[0].bookingClassAvails[0].cabinName,
                flownMileageQty: result[i].flightSegment[0].flownMileageQty, duration: result[i].flightSegment[0].duration, dateChangeNbr: result[i].flightSegment[0].dateChangeNbr,
                equipmentName: result[i].flightSegment[0].equipment !== undefined ? result[i].flightSegment[0].equipment[0].name : '', elapsedLayoverTime: result[i].flightSegment[0].elapsedLayoverTime !== undefined ? result[i].flightSegment[0].elapsedLayoverTime : '',
                noOfStops: result[i].flightSegment[0].technicalStops !== undefined && result[i].flightSegment[0].technicalStops !== null ? result[i].flightSegment[0].technicalStops.noOfStops : 0,
                bag: result[i].segmentRefDetail[0].pax !== undefined ? result[i].segmentRefDetail[0].pax : "",
                flightRefNumber: result[i].flightSegment[0].flightPnrNumber,
                equipment: result[i].flightSegment[0].equipment !== undefined ? result[i].flightSegment[0].equipment[0] : '',
                resBookDesigCode: result[i].flightSegment[0].resBookDesigCode,
                marketingAirline: result[i].flightSegment[0].marketingAirline,
                operatingAirline: result[i].flightSegment[0].operatingAirline,
                flightID: result[i].flightSegment[0].flightID,
                technicalStops: result[i].flightSegment[0].technicalStops !== undefined && result[i].flightSegment[0].technicalStops !== null ? result[i].flightSegment[0].technicalStops : "",


            })
            outBound_arrival_details.push({ arrival: result[i].flightSegment[0].arrivalAirport, date: result[i].flightSegment[0].arrivalDate, time: result[i].flightSegment[0].arrivalTime })
            outBound_operating_details.push(result[i].flightSegment[0].marketingAirline);
        }
        else {
            //Multicity Case
            multicityFlightCount = result[i].group;
            if (!multicity_dep_details_map.has(result[i].group)) {
                let multicity_dep_Details = [];
                multicity_dep_Details.push(
                    {
                        departure: result[i].flightSegment[0].departureAirport,
                        date: result[i].flightSegment[0].departureDate, time: result[i].flightSegment[0].departureTime,
                        marketingAirline: result[i].flightSegment[0].marketingAirline,
                        duration: result[i].flightSegment[0].duration,
                        cabinName: result[i].flightSegment[0].bookingClassAvails[0].cabinName,
                        flownMileageQty: result[i].flightSegment[0].flownMileageQty,
                        dateChangeNbr: result[i].flightSegment[0].dateChangeNbr,
                        equipmentName: result[i].flightSegment[0].equipment !== undefined ? result[i].flightSegment[0].equipment[0].name : '',
                        rph: result[i].rph,
                        elapsedLayoverTime: result[i].flightSegment[0].elapsedLayoverTime,
                        noOfStops: result[i].flightSegment[0].technicalStops !== undefined && result[i].flightSegment[0].technicalStops !== null ? result[i].flightSegment[0].technicalStops.noOfStops : 0,
                        bag: result[i].segmentRefDetail[0].pax !== undefined ? result[i].segmentRefDetail[0].pax : "",
                        flightRefNumber: result[i].flightSegment[0].flightPnrNumber,
                        technicalStops: result[i].flightSegment[0].technicalStops !== undefined && result[i].flightSegment[0].technicalStops !== null ? result[i].flightSegment[0].technicalStops : "",
                        operatingAirline: result[i].flightSegment[0].operatingAirline,
                        flightID: result[i].flightSegment[0].flightID,
                    });

                multicity_dep_details_map.set(result[i].group, multicity_dep_Details);
            }
            else {
                let temp = [];
                temp = multicity_dep_details_map.get(result[i].group);
                temp.push(
                    {
                        departure: result[i].flightSegment[0].departureAirport,
                        date: result[i].flightSegment[0].departureDate, time: result[i].flightSegment[0].departureTime,
                        marketingAirline: result[i].flightSegment[0].marketingAirline,
                        duration: result[i].flightSegment[0].duration,
                        cabinName: result[i].flightSegment[0].bookingClassAvails[0].cabinName,
                        flownMileageQty: result[i].flightSegment[0].flownMileageQty,
                        dateChangeNbr: result[i].flightSegment[0].dateChangeNbr,
                        equipmentName: result[i].flightSegment[0].equipment !== undefined ? result[i].flightSegment[0].equipment[0].name : '',
                        rph: result[i].rph,
                        elapsedLayoverTime: result[i].flightSegment[0].elapsedLayoverTime,
                        noOfStops: result[i].flightSegment[0].technicalStops !== undefined && result[i].flightSegment[0].technicalStops !== null ? result[i].flightSegment[0].technicalStops.noOfStops : 0,
                        bag: result[i].segmentRefDetail[0].pax !== undefined ? result[i].segmentRefDetail[0].pax : "",
                        flightRefNumber: result[i].flightSegment[0].flightPnrNumber,
                        technicalStops: result[i].flightSegment[0].technicalStops !== undefined && result[i].flightSegment[0].technicalStops !== null ? result[i].flightSegment[0].technicalStops : "",
                        operatingAirline: result[i].flightSegment[0].operatingAirline,
                        flightID: result[i].flightSegment[0].flightID,
                    }
                );
                multicity_dep_details_map.set(result[i].group, temp);

            }
            if (!multicity_arr_details_map.has(result[i].group)) {

                let multicity_arr_details = [];
                multicity_arr_details.push(
                    {
                        arrival: result[i].flightSegment[0].arrivalAirport,
                        date: result[i].flightSegment[0].arrivalDate,
                        time: result[i].flightSegment[0].arrivalTime,
                        marketingAirline: result[i].flightSegment[0].marketingAirline,
                        operatingAirline: result[i].flightSegment[0].operatingAirline,
                    }
                );
                multicity_arr_details_map.set(result[i].group, multicity_arr_details);

            } else {
                let temp = [];
                temp = multicity_arr_details_map.get(result[i].group);
                temp.push(
                    {
                        arrival: result[i].flightSegment[0].arrivalAirport,
                        date: result[i].flightSegment[0].arrivalDate,
                        time: result[i].flightSegment[0].arrivalTime,
                        marketingAirline: result[i].flightSegment[0].marketingAirline,
                        operatingAirline: result[i].flightSegment[0].operatingAirline,
                    }
                );
                multicity_arr_details_map.set(result[i].group, temp);

            }

        }


    }

    const { t } = useTranslation(['translation.SearchFlight']);
    const [confirm, setConfirm] = useState();

    function onConfirm() {
        setConfirm(true);
    }
    function okHandler() {
        setConfirm(false);

        let bookingJSON = JSON.parse(localStorage.getItem("BookingData"));
        bookingJSON.data.airItinerary[0].airItineraryPricingInfo = loadedPriceCheckResponse.data.airItinerary[0].airItineraryPricingInfo;
        bookingJSON.data.airItinerary[0].conditions = loadedPriceCheckResponse.data.airItinerary[0].conditions !== undefined ? loadedPriceCheckResponse.data.airItinerary[0].conditions : null;
        localStorage.setItem("BookingData", JSON.stringify(bookingJSON))

        if (localStorage.getItem("AddHotel") !== null && localStorage.getItem("AddHotel") === "1") {
            history.push('/SearchHotel?addhotel=1');
        }
        else {
            history.push(BOOKING);
        }
    };
    function cancelHandler() {
        setConfirm(false);
    };

    function okUpgradeCbainHandler() {
        setUpgradeCbainConfirm(false);
        sessionStorage.removeItem("isFlightPartialBlockExecuted");
        history.push('FlightSearch?flight=partial&travelreq=true&src=flight&isUpgrade=true');
    };
    function cancelUpgradeCbainHandler() {
        setUpgradeCbainConfirm(false);
        if (sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
            let travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
            travelRequestFieldsDataJson.justificationCodeFlight = {};
            travelRequestFieldsDataJson.justificationDetailsFlight = {};
            sessionStorage.setItem("travelRequestFieldsDataJson", JSON.stringify(travelRequestFieldsDataJson));
        }

        if (isInPolicy === true) {
            localStorage.removeItem("SeatMapData");
            localStorage.removeItem("AdditionalServicesData");
            sessionStorage.removeItem("hotelSessionDataJson");
            sessionStorage.removeItem("singlePnrCheckOutMinvalue");

            let bookingJSON = CreateBookingJSON(props.searchFlight)
            //bookingJSON.data.airItinerary[0].SourceName = props.searchFlight.sourceName;
            localStorage.setItem("BookingDataTravelRequest", JSON.stringify(bookingJSON))

            history.push('/TravelRequest?return=1&service=flight');
        }
        else if (isInPolicy === false) {
            OutPolicyPopup();
        }
        else if (isInPolicy === undefined || isInPolicy === '') {
            OutPolicyPopup();
        }
    };

    function closeShowDetailSection(id) {
        if (id === props.id) {
            if (document.querySelector('#collapseExample' + id) !== null) {
                var controls = document.querySelectorAll('[data="collapseExampleCommon"]');
                if (controls[id].className === 'flight_policy_refund collapse show') {


                    controls[id].className = 'flight_policy_refund collapse'
                }
            }
        }

    }
    const getPageMargins = () => {
        return `@page { size: auto;  margin: 0mm; } @media print { body { -webkit-print-color-adjust: exact; } }`
    };
    const ComponentToPrint = React.forwardRef((props, ref) => {
        const { value } = props;

        return (
            <div style={{ display: "none" }}>
                <style>{getPageMargins()}</style>
                <div className="print-source" ref={ref}>
                    <PrintItiniary
                        key={props.key}
                        id={props.id}
                        airItinerary={props.airItinerary}
                        searchFlight={props.searchFlight}
                        showData="2">

                    </PrintItiniary>

                </div> </div>
        );
    });






    const fareRule = (data, fareinfo, type, flightID) => {
        if (type === 'multicity') {
            setFareSummaryError({
                title: "Fare Rules",
                message:
                    <div className='col-md-12 position-relative'>
                        <FareRule inputData={data} type={type} fareInfo={fareinfo} sourceId={sourceId} sourceName={source} flightID={flightID}></FareRule>
                    </div>,
            });
        } else {
            setFareSummaryError({
                title: "Fare Rules",
                message:
                    <div className='col-md-12 position-relative'>
                        <FareRule inputData={data} type={type} sourceId={sourceId} sourceName={source} ></FareRule>
                    </div>,
            });
        }

    }

    const fareSummery = () => {
        setError({
            title: "Fare Summary",
            message: <FareSummary FlightData={props.searchFlight} bookingData={props.bookingData} hideOtherSevices={props.showData} hideCommision={props.hideCommision} hideIcon={props.hideIcon}></FareSummary>,
        });
    }

    const onSendEmail = () => {
        let bookingJSON = CreateBookingJSON(props.searchFlight)
        localStorage.setItem("BookingData", JSON.stringify(bookingJSON))
        setErrorPrint({
            title: "Send Recommended Flights",
            message: <div className='col-md-12'>
                <div className='gt-h-400px'>
                    <SendEmail mailData={bookingJSON} hideData="1" errorHandler={errorHandler}></SendEmail>
                </div></div>,
        })


    }


    const FlightDetailPopup = (rph) => {
        setError({
            title: "Flight Details",
            message: <FlightDetails FlightData={props.searchFlight} rph={rph} ></FlightDetails>,
        });
    }

    const fareRules = (<div className={`${props.showData === '3' ? "flight_search_right col-lg-3 col-md-12 col-sm-12 d-none" : "flight_search_right col-lg-3 col-md-12 col-sm-12 "}`}>
        {props.showData === '1' && props.travelReq !== undefined && props.travelReq === 'true' ? props.inPolicy !== undefined ? props.inPolicy === true ? <div className='h3 mb-0 mt-2'><i id={`PolicyIn${props.id}`} className="fas fa-check-circle text-success cursor-pointer"></i></div> : <div className='h3 mb-0 mt-2'><i id={`PolicyOut${props.id}`} className="fas fa-times-circle text-danger cursor-pointer"></i></div> : <div className='h3 mb-0 mt-2'><i id={`PolicyOut${props.id}`} className="fas fa-times-circle text-danger cursor-pointer"></i></div> : ''}
        {props.showData === '1' && <h2 className='pt-0'>{new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency
        }).format(totalFare)}</h2>}
        {checkSecurityDetails("Book", "Flight", "Book_Flight_Select") && props.showData === '1' && <button className="btn btn_theme btn_sm" onClick={() =>
            onBookNow(props.travelReq !== undefined && props.travelReq === 'true' ? props.inPolicy : props.id)}>
            {props.travelReq !== undefined && props.travelReq === 'true' ? 'Select' : (status === 'pending') ? <i className="fa fa-spinner fa-spin" ></i> : 'Book Now'}

        </button>}

        {props.showData === '1' && seatAvailabe < 5 && props.searchFlight.airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo[0].segments !== undefined && <p className='text-primary fw-bold gt-lh-20'>
            <span><i
                className="fas fa-walking"></i> Only {seatAvailabe} {seat} available.</span>
        </p>}

        <h6 id='itineraryDetails1' className='text-primary' data-bs-toggle="collapse" data-bs-target={"#collapseExample" + props.id} aria-controls={"collapseExample" + props.id}>Show Details <i className="fas fa-chevron-down"></i>
        </h6>
    </div>);

    // put additional conditions to handle '|' sign display with source, pcc, fare, rbd, commission
    const fareDetails = (<div>
        <hr className='my-1'></hr>
        <div className='px-3 py-2'>
            {props.showData !== '3' && getConfigurationByBoolen("ALLOW_SOURCE_PCC_FARE") && pcc !== '' && <span className='col-md-12 text-14px text-muted pb-2 xyz'>Source: <span className='text-primary fw-bold'>{source}</span> | PCC: <span className='text-primary fw-bold'>{pcc + " "}</span>| Fare Type: <span className='text-primary fw-bold'>{fareType + " "}</span>
            </span>}
            {props.showData !== '3' && getConfigurationByBoolen("ALLOW_SOURCE_PCC_FARE") && pcc === '' && <span className='col-md-12 text-14px text-muted pb-2 xyz'>Source: <span className='text-primary fw-bold'>{source}</span> | Fare Type: <span className='text-primary fw-bold'>{fareType + " "}</span>
            </span>}
            {props.showData !== '3' && getConfigurationByBoolen("ALLOW_SOURCE_PCC_FARE") && getConfigurationByBoolen("ALLOW_RBD") && <span className='col-md-12 text-14px text-muted pb-2 xyz'> | RBD: <span className='text-primary fw-bold'>{rbdValue + " "}</span>
            </span>}
            {props.showData !== '3' && getConfigurationByBoolen("ALLOW_SOURCE_PCC_FARE") === false && getConfigurationByBoolen("ALLOW_RBD") && <span className='col-md-12 text-14px text-muted pb-2 xyz'> RBD: <span className='text-primary fw-bold'>{rbdValue + " "}</span>
            </span>}
            {props.showData !== '3' && getConfigurationByBoolen("ALLOW_SOURCE_PCC_FARE") && getConfigurationByBoolen("ALLOW_RBD") && getConfigurationByBoolen("ALLOW_COMISSION") && markupValue !== undefined && markupValue !== '' && markupValue !== 0 && markupUnit !== undefined && markupUnit !== '' && <span className='col-md-12 text-14px text-muted pb-2'> {getConfigurationByBoolen("ALLOW_B2B") === true ? '| Commission:' : 'Commission:'} <span className='text-primary fw-bold'>{new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: markupUnit
            }).format(markupValue)}</span></span>}
            {props.showData !== '3' && getConfigurationByBoolen("ALLOW_SOURCE_PCC_FARE") && getConfigurationByBoolen("ALLOW_RBD") === false && getConfigurationByBoolen("ALLOW_COMISSION") && markupValue !== undefined && markupValue !== '' && markupValue !== 0 && markupUnit !== undefined && markupUnit !== '' && <span className='col-md-12 text-14px text-muted pb-2'> {getConfigurationByBoolen("ALLOW_B2B") === true ? '| Commission:' : 'Commission:'} <span className='text-primary fw-bold'>{new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: markupUnit
            }).format(markupValue)}</span></span>}
            {props.showData !== '3' && getConfigurationByBoolen("ALLOW_SOURCE_PCC_FARE") === false && getConfigurationByBoolen("ALLOW_RBD") && getConfigurationByBoolen("ALLOW_COMISSION") && markupValue !== undefined && markupValue !== '' && markupValue !== 0 && markupUnit !== undefined && markupUnit !== '' && <span className='col-md-12 text-14px text-muted pb-2'> {getConfigurationByBoolen("ALLOW_B2B") === true ? '| Commission:' : 'Commission:'} <span className='text-primary fw-bold'>{new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: markupUnit
            }).format(markupValue)}</span></span>}
            {props.showData !== '3' && getConfigurationByBoolen("ALLOW_SOURCE_PCC_FARE") === false && getConfigurationByBoolen("ALLOW_RBD") === false && getConfigurationByBoolen("ALLOW_COMISSION") && markupValue !== undefined && markupValue !== '' && markupValue !== 0 && markupUnit !== undefined && markupUnit !== '' && <span className='col-md-12 text-14px text-muted pb-2'> {getConfigurationByBoolen("ALLOW_B2B") === true ? 'Commission:' : 'Commission:'} <span className='text-primary fw-bold'>{new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: markupUnit
            }).format(markupValue)}</span></span>}

        </div>
        <div className='row'>
            <div className='col-md-6'>
                {getRefundType(props.searchFlight)}
            </div>

            <div className='col-md-6'>
                <div className={hidePrint} >
                    <span>

                        <div>

                            {props.searchFlight.directionInd === 'M' && getConfigurationByBoolen("ALLOW_FLIGHT_PROPOSAL") && <div className='btn btn-primary btn-sm px-2 text-14px email-btn text-white abx' id={`sendMail${props.id}`} onClick={() => onSendEmail()}><i className="fas fa-envelope"
                            ></i> Email Proposal</div>}
                            {/* {props.searchFlight.directionInd !== 'M' && !getConfigurationByBoolen("ALLOW_FLIGHT_PROPOSAL") && <div className='btn btn-primary btn-sm px-2 text-14px email-btn text-white abx' id={`sendMail${props.id}`} onClick={() => onSendEmail()}><i className="fas fa-envelope"
                            ></i> Email Proposal</div>} */}
                            {props.searchFlight.directionInd !== 'M' && getConfigurationByBoolen("ALLOW_FLIGHT_PROPOSAL") && <div className='text-end  '>
                                <div className="fw-bold text-primary add-to-proposal">Add to Proposal &nbsp;<input class="form-check-input tandc-options hideproposal" type="checkbox" id={`addToProposal${props.id}`} onChange={(e) => addFlightChangeHandler(e, props.id, props.searchFlight)} />&nbsp; </div>
                            </div>}

                        </div>
                    </span>


                </div>

            </div>
        </div>
    </div>);

    const viewSeat = () => {
        let bookingJSON = CreateBookingJSON(props.searchFlight)
        setError({
            title: "View Seats",
            message: <div className='col-md-12'>
                <SeatMap seatMap={bookingJSON.data} isReadOnlyMode={true}></SeatMap>
            </div>,
        });
    }

    const justificationCodeValue = (justificationCode) => {
        if (sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
            let travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
            travelRequestFieldsDataJson.justificationCodeFlight = justificationCode;
            sessionStorage.setItem("travelRequestFieldsDataJson", JSON.stringify(travelRequestFieldsDataJson));
        }
    };

    const justificationTextValue = (justificationText) => {
        // if (sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
        //     let travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
        //     travelRequestFieldsDataJson.justificationCodeFlight = justificationText;
        //     sessionStorage.setItem("travelRequestFieldsDataJson", JSON.stringify(travelRequestFieldsDataJson));
        // }
        setJustificationTextData(justificationText);
    };

    const justificationDetailsValue = (justificationDetails) => {
        if (sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
            let travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
            travelRequestFieldsDataJson.justificationDetailsFlight = justificationDetails;
            sessionStorage.setItem("travelRequestFieldsDataJson", JSON.stringify(travelRequestFieldsDataJson));
        }
    };

    const OutPolicyPopup = () => {
        setErrorOutPolicy({
            title: "Reason",
            message: <OutPolicy
                justificationCode={justificationCodeValue}
                justificationText={justificationTextValue}
                justificationDetails={justificationDetailsValue}
                keyCode={'FIFTH'}>
            </OutPolicy>,
        });
    }

    function isMultiAirlines(Airline, Ismulticity) {

        let multiAirline = false;
        for (let i in Airline) {
            if (Ismulticity) {
                if (Airline[0].marketingAirline.code !== Airline[i].marketingAirline.code) {
                    multiAirline = true;
                    break;
                }

            } else {
                if (Airline[0].code !== Airline[i].code) {
                    multiAirline = true;
                    break;
                }
            }


        }
        return multiAirline;
    }

    function showAirlineDetails() {
        var show = [];
        let totalOutboundDuration = props.airItinerary.totalOutBoundDuration;

        //one Way Trip
        if (inBound_departure_details.length === 0 && multicity_dep_details_map.size === 0) {
            let dep_cityName = outBound_departure_details[0].departure.cityName;
            let depTime = outBound_departure_details[0].time;
            let depDate = outBound_departure_details[0].date;
            let arrTime = outBound_arrival_details[outBound_arrival_details.length - 1].time;
            let arrDate = outBound_arrival_details[outBound_arrival_details.length - 1].date;
            let NoOfStops = outBound_arrival_details.length - 1;
            const totalStops = outBound_departure_details.reduce((total, flight) => total + parseInt(flight.noOfStops || 0, 10), 0);
            let NoOftechStop = totalStops + NoOfStops;
            let stop = parseInt(NoOftechStop) === 0 || parseInt(NoOftechStop) > 1 ? "stops" : "stop";
            let arr_cityName = outBound_arrival_details[outBound_arrival_details.length - 1].arrival.cityName;
            let flightNumber = outBound_operating_details[0].code + " " + outBound_operating_details[0].flightNumber
            let airlineName = isMultiAirlines(outBound_operating_details, false) === true ? "Multi Airlines" : outBound_operating_details[0].name;
            let logo = airlineLogoLink + outBound_operating_details[0].airlineLogo;
            let arrAirportCode = outBound_arrival_details[NoOfStops].arrival.locationCode === undefined ? "" : outBound_arrival_details[NoOfStops].arrival.locationCode;
            let depAirportCode = outBound_departure_details[0].departure.locationCode === undefined ? "" : outBound_departure_details[0].departure.locationCode;
            let fromAirpotDetails; let fromAirport; let toAirportDetails; let toAirport; let arrInfo; let depInfo;
            if (sessionStorage.getItem('spnFromAirportDetails1') !== null) {
                fromAirpotDetails = sessionStorage.getItem('spnFromAirportDetails1');
                fromAirport = fromAirpotDetails.split('-');
                depInfo = !fromAirport[0].match(depAirportCode) ? "Your depature airport " + `${depAirportCode}` + " is different from your choosen destination " + `${fromAirport[0]}` + "." : "";
            }
            if (sessionStorage.getItem('spnToAirportDetails1') !== null) {
                toAirportDetails = sessionStorage.getItem('spnToAirportDetails1');
                toAirport = toAirportDetails.split('-');
                arrInfo = !toAirport[0].match(arrAirportCode) ? "Your arrival airport " + `${arrAirportCode}` + " is different  from your choosen destination " + `${toAirport[0]}` + "." : "";
            }
            let isAirportChange = (arrInfo !== '' || depInfo !== '') ? false : true;

            const fareRuleInput = (
                {

                    "officeID": JSON.parse(localStorage.getItem('officeID')),
                    "Data": {
                        "FareRuleSourceInfo": [
                            {
                                "FlightSegmentRuleInfo": [
                                    {
                                        "DepartureAirport": {
                                            // "LocationCode1": depAirportCode,
                                            "LocationCode": props.searchFlight.airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo[0].operation !== undefined ? props.searchFlight.airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo[0].operation === 'FROM' ? props.searchFlight.airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo[0].segments.beginAirport : props.searchFlight.airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo[0].segments.endAirport : depAirportCode,

                                        },
                                        "ArrivalAirport": {
                                            //"LocationCode": arrAirportCode,
                                            "LocationCode": props.searchFlight.airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo[0].operation !== undefined ? props.searchFlight.airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo[0].operation === 'FROM' ? props.searchFlight.airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo[0].segments.endAirport : props.searchFlight.airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo[0].segments.beginAirport : arrAirportCode,

                                        },
                                        "MarketingAirline": {
                                            "Code": outBound_departure_details[0].marketingAirline.code,
                                            "FlightNumber": outBound_departure_details[0].marketingAirline.flightNumber,
                                        },

                                        "FareRules": {
                                            "FareRule": [{

                                                "FareBasisCode": props.searchFlight.airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo[0].fareBasisCode,
                                                "FareRuleKey": props.searchFlight.airItineraryPricingInfo.key !== undefined ? props.searchFlight.airItineraryPricingInfo.key : "",

                                            }],
                                        },
                                        "DepartureDate": depDate,
                                        "DepartureTime": depTime,
                                        "ArrivalDate": arrDate,
                                        "ArrivalTime": arrTime,


                                    }
                                ],
                                "SourceId": sourceId,
                                "SourceName": source,
                                "SectorInd": props.searchFlight.sectorInd
                            }
                        ],
                        "TraceId": JSON.parse(localStorage.getItem('traceID')),

                    }
                }
            );


            fareArray.push(fareRuleInput);
            show.push(
                <div className={classNameUpdated}>
                    <div className={classNameUpdatedList}>
                        <div className="flight_multis_area_wrapper">
                            <div className={'flight_search_left col-md-5'}>
                                <div className="flight_logo">
                                    {!isMultiAirlines(outBound_operating_details, false) && <img src={logo} alt="img" className=' p-1 gt-h40' />}
                                    <div className="text-14px text-black fw-bold gt-w-75px">{airlineName}</div>
                                    <div className="text-muted text-12px">{flightNumber}</div>
                                </div>
                                <div className="flight_search_destination">

                                    <p>From</p>
                                    <h3 className='text-black'>{dep_cityName} </h3>
                                    <h6>{getFormatDate(outBound_departure_details[0].date)}{getFormatTime(outBound_departure_details[0].time) === '' ? '' : ', '} {getFormatTime(outBound_departure_details[0].time)}</h6>

                                </div>
                            </div>
                            <div className="flight_search_middel col-md-7">
                                <div className="flight_right_arrow col-md-5">
                                    <img src="assets/img/icon/right_arrow.png" alt="icon" />
                                    <h6>{getMinutetoHrsMin(totalOutboundDuration)}</h6>
                                    {sessionStorage.setItem("noOfStopsOwDep", NoOfStops)}
                                    <p>{NoOftechStop + " "}{stop} </p>
                                </div>
                                <div className="flight_search_destination col-md-7">
                                    <p>To</p>
                                    <h3 className='text-black'>{arr_cityName}</h3>
                                    <h6>{getFormatDate(outBound_arrival_details[NoOfStops].date)}{getFormatTime(outBound_arrival_details[NoOfStops].time) !== '' ? ', ' : ''} {getFormatTime(outBound_arrival_details[NoOfStops].time)}</h6>
                                </div>
                            </div>

                        </div>
                        {fareDetails}
                    </div>
                    <div className={`${props.showData === '3' ? "flight_search_right col-lg-3 col-md-12 col-sm-12 d-none" : "flight_search_right col-lg-3 col-md-12 col-sm-12 "}`}>
                        {props.showData === '1' && props.travelReq !== undefined && props.travelReq === 'true' ? props.inPolicy !== undefined ? props.inPolicy === true ? <div className='h3 mb-0 mt-2'><i id={`PolicyIn${props.id}`} className="fas fa-check-circle text-success cursor-pointer"></i></div> : <div className='h3 mb-0 mt-2'><i id={`PolicyOut${props.id}`} className="fas fa-times-circle text-danger cursor-pointer"></i></div> : <div className='h3 mb-0 mt-2'><i id={`PolicyOut${props.id}`} className="fas fa-times-circle text-danger cursor-pointer"></i></div> : ''}
                        {props.showData === '1' && <h2 className='pt-0'>{new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: currency
                        }).format(totalFare)}</h2>}
                        {checkSecurityDetails("Book", "Flight", "Book_Flight_Select") && props.showData === '1' && <button className="btn btn_theme btn_sm" onClick={() =>
                            onBookNow(props.travelReq !== undefined && props.travelReq === 'true' ? props.inPolicy : props.id)}>
                            {props.travelReq !== undefined && props.travelReq === 'true' ? 'Select' : (status === 'pending') ? <i className="fa fa-spinner fa-spin" ></i> : 'Book Now'}
                        </button>}
                        {props.showData === '1' && seatAvailabe < 5 && props.searchFlight.airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo[0].segments !== undefined && <p className='text-primary fw-bold gt-lh-20'><span><i className="fas fa-walking"></i> Only {seatAvailabe} {seat} available.</span>
                        </p>}
                        <h6 id='itineraryDetails2' className='text-primary' data-bs-toggle="collapse" data-bs-target={"#collapseExample" + props.id} aria-controls={"collapseExample" + props.id}>Show Details <i className="fas fa-chevron-down"></i></h6>
                        <div id={'animated-email-div' + props.id} style={{ display: 'none' }} className='animated-email-div'>
                            <div className='d-flex'><span class="arrow-1"></span><span className='email-floating'><i class="fas fa-envelope text-light h4 ghgf"></i></span></div>
                        </div>
                    </div>
                </div>

            );

            return show;
        }
        else if (multicity_dep_details_map.size !== 0) {
            let totalItineraryDuration = props.airItinerary.groupDurations;
            //Multicity Case
            for (let i = 1; i <= parseInt(multicityFlightCount); i++) {
                let dep = multicity_dep_details_map.get("" + i);
                let arr = multicity_arr_details_map.get("" + i);
                let flightNumber = (multicity_dep_details_map.get("" + i)[0].marketingAirline.code + " " + multicity_dep_details_map.get("" + i)[0].marketingAirline.flightNumber);
                let airlineName = isMultiAirlines(multicity_dep_details_map.get("" + i), true) === true ? "Multi Airlines" : multicity_dep_details_map.get("" + i)[0].marketingAirline.name;
                let logoM = airlineLogoLink + dep[0].marketingAirline.airlineLogo;
                let totalDuration = totalItineraryDuration[i - 1].duration;
                let NoOfStops = dep.length - 1;
                const totalStops = dep.reduce((total, flight) => total + parseInt(flight.noOfStops || 0, 10), 0);
                let NoOftechStop = totalStops + NoOfStops;
                let stop = parseInt(NoOftechStop) === 0 || parseInt(NoOftechStop) > 1 ? "stops" : "stop";
                let cityNameM = dep[0].departure.cityName;
                let dateM = getFormatDate(dep[0].date);
                let timeM = getFormatTime(dep[0].time);
                let cityNameA = arr[arr.length - 1].arrival.cityName;
                let dateA = getFormatDate(arr[arr.length - 1].date);
                let timeA = getFormatTime(arr[arr.length - 1].time);
                let arrAirportCode = arr[arr.length - 1].arrival.locationCode
                let depAirportCode = dep[0].departure.locationCode
                let fareBasisCode = props.searchFlight.airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos !== undefined ? props.searchFlight.airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo[i - 1] != undefined ? props.searchFlight.airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo[i - 1].fareBasisCode : "" : ""

                show.push(
                    <div>
                        {i !== 1 && <hr className='my-1'></hr>}
                        <div className="flight_multis_area_wrapper">

                            <div className="flight_search_left col-md-5">
                                <div className="flight_logo">
                                    {!isMultiAirlines(multicity_dep_details_map.get("" + i), true) && <img src={logoM} alt="img" className=' p-1 gt-h40' />}
                                    <div className="text-14px text-black fw-bold gt-w-75px">{airlineName}</div>
                                    <div className="text-muted text-12px">{flightNumber}</div>
                                </div>
                                <div className="flight_search_destination">
                                    <p>From </p>
                                    <h3 className='text-black'>{cityNameM}</h3>
                                    <h6>{dateM}{timeM === '' ? '' : ','} {timeM}</h6>

                                </div>
                            </div>
                            <div className="flight_search_middel col-md-7">
                                <div className="flight_right_arrow col-md-5">
                                    <img src="assets/img/icon/right_arrow.png" alt="icon" />
                                    <h6>{getMinutetoHrsMin(totalDuration)}</h6>
                                    <p>{NoOftechStop + " "}{stop} </p>
                                </div>
                                <div className="flight_search_destination col-md-7">
                                    <p>To</p>
                                    <h3 className='text-black'>{cityNameA} </h3>
                                    <h6>{dateA}{timeA === '' ? '' : ', '} {timeA}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                )

            }



            return show;

        }
        else {
            //round Trip Case
            let depTime = outBound_departure_details[0].time;
            let depDate = outBound_departure_details[0].date;
            let arrTime = outBound_arrival_details[outBound_arrival_details.length - 1].time;
            let arrDate = outBound_arrival_details[outBound_arrival_details.length - 1].date;
            let dep_cityName = outBound_departure_details[0].departure.cityName;
            let arr_cityName = outBound_arrival_details[outBound_arrival_details.length - 1].arrival.cityName;
            let dep_cityName_r = inBound_departure_details[0].departure.cityName;
            let arr_cityName_r = inBound_arrival_details[inBound_arrival_details.length - 1].arrival.cityName;
            let inArrAirportCode = inBound_arrival_details[inBound_arrival_details.length - 1].arrival.locationCode === undefined ? "" : inBound_arrival_details[inBound_arrival_details.length - 1].arrival.locationCode
            let NoOfStops = outBound_departure_details.length - 1;
            let NoOfstops_r = inBound_departure_details.length - 1;
            const totalStops_r = inBound_departure_details.reduce((total, flight) => total + parseInt(flight.noOfStops || 0, 10), 0);
            let NoOftechStop_r = totalStops_r + NoOfstops_r;
            const totalStops = outBound_departure_details.reduce((total, flight) => total + parseInt(flight.noOfStops || 0, 10), 0);
            let NoOftechStop = totalStops + NoOfStops;
            let stop = parseInt(NoOftechStop) === 0 || parseInt(NoOftechStop) > 1 ? "stops" : "stop";
            let stop_r = parseInt(NoOftechStop_r) === 0 || parseInt(NoOftechStop_r) > 1 ? "stops" : "stop";
            let airlineName = isMultiAirlines(outBound_operating_details, false) === true ? "Multi Airlines" : outBound_operating_details[0].name;
            let airlineName_r = isMultiAirlines(inbound_operating_details, false) === true ? "Multi Airlines" : inbound_operating_details[0].name;
            let flightNumber = outBound_operating_details[0].code + " " + outBound_operating_details[0].flightNumber
            let flightNumber_r = inbound_operating_details[0].code + " " + inbound_operating_details[0].flightNumber
            let logo1 = airlineLogoLink + outBound_operating_details[0].airlineLogo;
            let logo2 = airlineLogoLink + inbound_operating_details[0].airlineLogo;
            let totalInBoundDuration = props.airItinerary.totalInBoundDuration;
            let arrAirportCode = outBound_arrival_details[NoOfStops].arrival.locationCode === undefined ? "" : outBound_arrival_details[NoOfStops].arrival.locationCode;
            let depAirportCode = outBound_departure_details[0].departure.locationCode === undefined ? "" : outBound_departure_details[0].departure.locationCode;
            let fromAirpotDetails; let fromAirport; let toAirportDetails; let toAirport; let arrInfo; let depInfo;
            if (sessionStorage.getItem('spnFromAirportDetails1') !== null) {
                fromAirpotDetails = sessionStorage.getItem('spnFromAirportDetails1');
                fromAirport = fromAirpotDetails.split('-');
                depInfo = !fromAirport[0].match(depAirportCode) ? "Your depature airport " + `${depAirportCode}` + " is different from your choosen destination " + `${fromAirport[0]}` + "." : "";
            }
            if (sessionStorage.getItem('spnToAirportDetails1')) {
                toAirportDetails = sessionStorage.getItem('spnToAirportDetails1');
                toAirport = toAirportDetails.split('-');
                arrInfo = !toAirport[0].match(arrAirportCode) ? "Your arrival airport " + `${arrAirportCode}` + " is different  from your choosen destination " + `${toAirport[0]}` + "." : "";

            }
            let isAirportChange = (arrInfo !== '' || depInfo !== '') ? false : true;

            const fareRuleInput = (
                {

                    "officeID": JSON.parse(localStorage.getItem('officeID')),
                    "Data": {
                        "FareRuleSourceInfo": [
                            {
                                "FlightSegmentRuleInfo": [
                                    {
                                        "DepartureAirport": {
                                            "LocationCode1": depAirportCode,
                                            "LocationCode": props.searchFlight.airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo[0].operation !== undefined ? props.searchFlight.airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo[0].operation === 'FROM' ? props.searchFlight.airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo[0].segments.beginAirport : props.searchFlight.airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo[0].segments.endAirport : depAirportCode,

                                        },
                                        "ArrivalAirport": {
                                            "LocationCode1": arrAirportCode,
                                            "LocationCode": props.searchFlight.airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo[0].operation !== undefined ? props.searchFlight.airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo[0].operation === 'FROM' ? props.searchFlight.airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo[0].segments.endAirport : props.searchFlight.airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo[0].segments.beginAirport : arrAirportCode,

                                        },
                                        "MarketingAirline": {
                                            "Code": outBound_departure_details[0].marketingAirline.code,
                                            "FlightNumber": outBound_departure_details[0].marketingAirline.flightNumber,
                                        },

                                        "FareRules": {
                                            "FareRule": [{

                                                "FareBasisCode": props.searchFlight.airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo[0].fareBasisCode,
                                                "FareRuleKey": props.searchFlight.airItineraryPricingInfo.key !== undefined ? props.searchFlight.airItineraryPricingInfo.key : "",

                                            }],
                                        },
                                        "DepartureDate": depDate,
                                        "DepartureTime": depTime,
                                        "ArrivalDate": arrDate,
                                        "ArrivalTime": arrTime,


                                    }
                                ],
                                "SourceId": sourceId,
                                "SourceName": source,
                                "SectorInd": props.searchFlight.sectorInd
                            }
                        ],
                        "TraceId": JSON.parse(localStorage.getItem('traceID')),

                    }
                }
            );


            fareArray.push(fareRuleInput);

            const fareRuleInput_r = (
                {

                    "officeID": JSON.parse(localStorage.getItem('officeID')),
                    "Data": {
                        "FareRuleSourceInfo": [
                            {

                                "FlightSegmentRuleInfo": [
                                    {
                                        "DepartureAirport": {
                                            "LocationCode1": inBound_departure_details[0].departure.locationCode,
                                            //"LocationCode": depAirportCode,
                                            "LocationCode": props.searchFlight.airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo[1] !== undefined && props.searchFlight.airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo[1].operation !== undefined ? props.searchFlight.airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo[1].operation === 'FROM' ? props.searchFlight.airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo[1].segments.beginAirport : props.searchFlight.airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo[1].segments.endAirport : inBound_departure_details[0].departure.locationCode,

                                        },
                                        "ArrivalAirport": {
                                            "LocationCode1": inArrAirportCode,
                                            //"LocationCode": arrAirportCode,
                                            "LocationCode": props.searchFlight.airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo[1] !== undefined && props.searchFlight.airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo[1].operation !== undefined ? props.searchFlight.airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo[1].operation === 'FROM' ? props.searchFlight.airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo[1].segments.endAirport : props.searchFlight.airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo[1].segments.beginAirport : inArrAirportCode,

                                        },
                                        "MarketingAirline": {
                                            "Code": inBound_departure_details[0].marketingAirline.code,
                                            "FlightNumber": inBound_departure_details[0].marketingAirline.flightNumber,
                                        },

                                        "FareRules": {
                                            "FareRule": [{

                                                "FareBasisCode": sourceId === 'TJ' ? props.searchFlight.airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo[0].fareBasisCode : props.searchFlight.airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo[1] !== undefined ? props.searchFlight.airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo[1].fareBasisCode : '',
                                                "FareRuleKey": props.searchFlight.airItineraryPricingInfo.key !== undefined ? props.searchFlight.airItineraryPricingInfo.key : "",

                                            }],
                                        },
                                        "DepartureDate": inBound_departure_details[0].date,
                                        "DepartureTime": inBound_departure_details[0].time,
                                        "ArrivalDate": inBound_arrival_details[inBound_arrival_details.length - 1].date !== undefined ? inBound_arrival_details[inBound_arrival_details.length - 1].date : "",
                                        "ArrivalTime": inBound_arrival_details[inBound_arrival_details.length - 1].time,


                                    }
                                ],
                                "SourceId": sourceId,
                                "SourceName": source,
                                "SectorInd": props.searchFlight.sectorInd
                            }
                        ],
                        "TraceId": JSON.parse(localStorage.getItem('traceID')),

                    }
                }
            );

            fareArray.push(fareRuleInput_r)



            show.push(
                <div className={classNameUpdated}>
                    <div className={classNameUpdatedList}><div className="flight_multis_area_wrapper">

                        <div className="flight_search_left col-md-5">
                            <div className="flight_logo">
                                {!isMultiAirlines(outBound_operating_details, false) && <img src={logo1} alt="img" className=' p-1 gt-h40' />}
                                <div className="text-14px text-black fw-bold gt-w-75px">{airlineName}</div>
                                <div className="text-muted text-12px">{flightNumber}</div>
                            </div>
                            <div className="flight_search_destination">

                                <p>From</p>
                                <h3 className='text-black'>{dep_cityName} </h3>
                                <h6>{getFormatDate(outBound_departure_details[0].date)}{getFormatTime(outBound_departure_details[0].time) === '' ? '' : ', '} {getFormatTime(outBound_departure_details[0].time)}</h6>

                            </div>
                        </div>
                        <div className="flight_search_middel col-md-7">
                            <div className="flight_right_arrow col-md-5">
                                <img src="assets/img/icon/right_arrow.png" alt="icon" />
                                <h6>{getMinutetoHrsMin(totalOutboundDuration)}</h6>
                                <p>{NoOftechStop + " "}{stop} </p>
                            </div>
                            <div className="flight_search_destination col-md-7">
                                <p>To</p>
                                <h3 className='text-black'>{arr_cityName}</h3>
                                <h6>{getFormatDate(outBound_arrival_details[outBound_arrival_details.length - 1].date)}
                                    {getFormatTime(outBound_arrival_details[outBound_arrival_details.length - 1].time) === '' ? '' : ', '}
                                    {getFormatTime(outBound_arrival_details[outBound_arrival_details.length - 1].time)}</h6>
                            </div>
                        </div>
                    </div>
                        <hr className='my-1'></hr>
                        <div className="flight_multis_area_wrapper">

                            <div className="flight_search_left col-md-5">
                                <div className="flight_logo">

                                    {!isMultiAirlines(inbound_operating_details, false) && <img src={logo2} alt="img" className=' p-1 gt-h40' />}
                                    <div className="text-14px text-black fw-bold gt-w-75px">{airlineName_r}</div>
                                    <div className="text-muted text-12px">{flightNumber_r}</div>
                                </div>
                                <div className="flight_search_destination">
                                    <p>From</p>
                                    <h3 className='text-black'>{dep_cityName_r}</h3>
                                    <h6>{getFormatDate(inBound_departure_details[0].date)}{getFormatTime(inBound_departure_details[0].time) === '' ? '' : ', '} {getFormatTime(inBound_departure_details[0].time)}</h6>
                                </div>
                            </div>
                            <div className="flight_search_middel col-md-7">
                                <div className="flight_right_arrow col-md-5">
                                    <img src="assets/img/icon/right_arrow.png" alt="icon" />
                                    <h6>{getMinutetoHrsMin(totalInBoundDuration)}</h6>
                                    <p>{NoOftechStop_r + " "}{stop_r} </p>
                                </div>
                                <div className="flight_search_destination col-md-7">
                                    <p>To</p>
                                    <h3 className='text-black'>{arr_cityName_r} </h3>
                                    <h6>{getFormatDate(inBound_arrival_details[inBound_arrival_details.length - 1].date)}
                                        {getFormatTime(inBound_arrival_details[inBound_arrival_details.length - 1].time) === '' ? '' : ', '}
                                        {getFormatTime(inBound_arrival_details[inBound_arrival_details.length - 1].time)}</h6>
                                </div>
                            </div>
                        </div>
                        {fareDetails}
                    </div>
                    <div className={`${props.showData === '3' ? "flight_search_right col-lg-3 col-md-12 col-sm-12 d-none" : "flight_search_right col-lg-3 col-md-12 col-sm-12  position-relative"}`}>
                        {props.showData === '1' && props.travelReq !== undefined && props.travelReq === 'true' ? props.inPolicy !== undefined ? props.inPolicy === true ? <div className='h3 mb-0 mt-2'><i id={`PolicyIn${props.id}`} className="fas fa-check-circle text-success cursor-pointer"></i></div> : <div className='h3 mb-0 mt-2'><i id={`PolicyOut${props.id}`} className="fas fa-times-circle text-danger cursor-pointer"></i></div> : <div className='h3 mb-0 mt-2'><i id={`PolicyOut${props.id}`} className="fas fa-times-circle text-danger cursor-pointer"></i></div> : ''}
                        {props.showData === '1' && <h2 className='pt-0'>{new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: currency
                        }).format(totalFare)}</h2>}
                        {checkSecurityDetails("Book", "Flight", "Book_Flight_Select") && props.showData === '1' && <button className="btn btn_theme btn_sm" onClick={() =>
                            onBookNow(props.travelReq !== undefined && props.travelReq === 'true' ? props.inPolicy : props.id)}>
                            {props.travelReq !== undefined && props.travelReq === 'true' ? 'Select' : (status === 'pending') ? <i className="fa fa-spinner fa-spin" ></i> : 'Book Now'}
                        </button>}

                        {props.showData === '1' && seatAvailabe < 5 && props.searchFlight.airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo[0].segments !== undefined && <p className='text-primary fw-bold gt-lh-20'> <span><i className="fas fa-walking"></i> Only {seatAvailabe} {seat} available.</span>
                        </p>}
                        <h6 id='itineraryDetails3' className='text-primary' data-bs-toggle="collapse" data-bs-target={"#collapseExample" + props.id} aria-controls={"collapseExample" + props.id}>Show Details <i className="fas fa-chevron-down"></i></h6>
                        <div id={'animated-email-div' + props.id} style={{ display: 'none' }} className='animated-email-div'>
                            <div className='d-flex'><span class="arrow-1"></span><span className='email-floating'><i class="fas fa-envelope text-light h4 ghgf"></i></span></div>
                        </div>
                    </div>
                </div>
            );

            return show;
        }
    }

    function showDetails() {
        var show = [];
        if (multicity_dep_details_map.size === 0) {
            //one Way 
            for (let i in outBound_departure_details) {
                let depTime = outBound_departure_details[i].time;
                let depDate = outBound_departure_details[i].date;
                let arrTime = outBound_arrival_details[i].time;
                let arrDate = outBound_arrival_details[i].date;
                let depCityCode = outBound_departure_details[i].departure.cityCode;
                let depCityName = outBound_departure_details[i].departure.cityName;
                let depCountryName = outBound_departure_details[i].departure.countryName;
                let depCountryCode = outBound_departure_details[i].departure.countryCode;
                let depAirportName = outBound_departure_details[i].departure.locationName === undefined ? "" : outBound_departure_details[i].departure.locationName;
                let depAirportCode = outBound_departure_details[i].departure.locationCode === undefined ? "" : outBound_departure_details[i].departure.locationCode;
                let depTerminal = outBound_departure_details[i].departure.terminal === undefined ? "" : "Terminal " + outBound_departure_details[i].departure.terminal;
                let flightArrive = outBound_departure_details[i].dateChangeNbr === "1" && outBound_departure_details[i].dateChangeNbr !== undefined ? "This flight arrives the next day." : "";
                let arrCityCode = outBound_arrival_details[i].arrival.cityCode;
                let arrCityName = outBound_arrival_details[i].arrival.cityName;
                let arrCountryName = outBound_arrival_details[i].arrival.countryName;
                let arrCountryCode = outBound_arrival_details[i].arrival.countryCode;
                let arrTerminal = outBound_arrival_details[i].arrival.terminal === undefined ? "" : "Terminal " + outBound_arrival_details[i].arrival.terminal;
                let NoOftechStop = parseInt(outBound_departure_details[i].noOfStops !== undefined && outBound_departure_details[i].noOfStops !== '' ? outBound_departure_details[i].noOfStops : 0);
                let stop = parseInt(NoOftechStop) === 0 || parseInt(NoOftechStop) > 1 ? "stops" : "stop";
                let equipmentName = outBound_departure_details[i].equipmentName === undefined ? "" : outBound_departure_details[i].equipmentName;
                let arrAirportName = outBound_arrival_details[i].arrival.locationName === undefined ? "" : outBound_arrival_details[i].arrival.locationName;
                let arrAirportCode = outBound_arrival_details[i].arrival.locationCode === undefined ? "" : outBound_arrival_details[i].arrival.locationCode;
                let logo = airlineLogoLink + outBound_operating_details[i].airlineLogo;
                let timeBetweenFlight = outBound_departure_details[i].elapsedLayoverTime !== undefined ? outBound_departure_details[i].elapsedLayoverTime : "";
                let rph = outBound_departure_details[i].rph;
                let type = inBound_departure_details.length === 0 ? 'oneWay' : 'roundTrip';
                let depAirPortInfo = depAirportName + "(" + depAirportCode + ")" + ", " + depCountryName + "(" + depCountryCode + ")";
                let arrAirPortInfo = arrAirportName + "(" + arrAirportCode + ")" + ", " + arrCountryName + "(" + arrCountryCode + ")";
                let operatingAirline = outBound_departure_details[i].operatingAirline;

                //User Story 2450: Cabin Bag Changes
                let adultBag; let childBag; let lapInfantBag; let adultBagUnit; let childBagUnit; let lapInfantBagUnit; let seatInfantBag; let seatInfantBagUnit;
                let cabinAdultBag; let cabinAdultBagUnit; let cabinChildBag; let cabinChildBagUnit; let cabinLapInfantBag; let cabinLapInfantBagUnit; let cabinSeatInfantBag; let cabinSeatInfantBagUnit;

                if (outBound_departure_details[i].bag !== '' || outBound_departure_details[i].bag !== undefined) {
                    outBound_departure_details[i].bag.forEach(element => {
                        if (element.baggageAllowance !== undefined) {
                            if (element.type === 'ADT') {
                                element.baggageAllowance.forEach(item => {
                                    //check for check-in bag
                                    if (item.type === 'Check-in Allowance') {
                                        adultBag = item.maxWeight === undefined ? item.numberOfPieces : item.maxWeight
                                        adultBagUnit = getBaggageUnitTextData(adultBag, item);
                                    }
                                    //check for cabin bag
                                    if (item.type === "for Carry-On Allowances") {
                                        cabinAdultBag = item.maxWeight === undefined ? item.numberOfPieces : item.maxWeight
                                        cabinAdultBagUnit = getBaggageUnitTextData(cabinAdultBag, item);
                                    }
                                });

                            }
                            else if (element.type === 'CNN') {
                                element.baggageAllowance.forEach(item => {
                                    //check for check-in bag
                                    if (item.type === 'Check-in Allowance') {
                                        childBag = item.maxWeight === undefined ? item.numberOfPieces : item.maxWeight
                                        childBagUnit = getBaggageUnitTextData(childBag, item);
                                    }
                                    //check for cabin bag
                                    if (item.type === "for Carry-On Allowances") {
                                        cabinChildBag = item.maxWeight === undefined ? item.numberOfPieces : item.maxWeight
                                        cabinChildBagUnit = getBaggageUnitTextData(cabinChildBag, item);
                                    }
                                });
                            }
                            else if (element.type === 'INF') {
                                element.baggageAllowance.forEach(item => {
                                    //check for check-in bag
                                    if (item.type === 'Check-in Allowance') {
                                        lapInfantBag = item.maxWeight === undefined ? item.numberOfPieces : item.maxWeight
                                        lapInfantBagUnit = getBaggageUnitTextData(lapInfantBag, item);
                                    }
                                    //check for cabin bag
                                    if (item.type === "for Carry-On Allowances") {
                                        cabinLapInfantBag = item.maxWeight === undefined ? item.numberOfPieces : item.maxWeight
                                        cabinLapInfantBagUnit = getBaggageUnitTextData(cabinLapInfantBag, item);
                                    }
                                });
                            }
                            else if (element.type === 'INS') {
                                element.baggageAllowance.forEach(item => {
                                    //check for check-in bag
                                    if (item.type === 'Check-in Allowance') {
                                        seatInfantBag = item.maxWeight === undefined ? item.numberOfPieces : item.maxWeight
                                        seatInfantBagUnit = getBaggageUnitTextData(seatInfantBag, item);
                                    }
                                    //check for cabin bag
                                    if (item.type === "for Carry-On Allowances") {
                                        cabinSeatInfantBag = item.maxWeight === undefined ? item.numberOfPieces : item.maxWeight
                                        cabinSeatInfantBagUnit = getBaggageUnitTextData(cabinSeatInfantBag, item);
                                    }
                                });

                            }
                        }

                    });
                }


                show.push(
                    <div>
                        {timeBetweenFlight !== "" &&
                            <div className='row'>
                                <div className='col-md-12 text-center gt-time-btn-flight' >Time between flights:{" " + getMinutetoHrsMin(timeBetweenFlight)}</div>
                            </div>
                        }
                        <div className='row'>
                            <div className='col-md-4'>
                                <div className='departure-text'>

                                    <span><i className="fas fa-plane-departure"></i></span> Departure {outBound_departure_details[i].flightRefNumber !== undefined && <span className='text-primary airlineName fw-500'> Airline PNR:  {outBound_departure_details[i].flightRefNumber}</span>}
                                </div>
                            </div>
                            {
                                (valueParam.return === undefined || valueParam.return === '' || valueParam.return === '0') &&
                                <div className='col-md-8'>

                                    <div className='pt-10 float-md-end float-lg-end'>
                                        {props.showData !== '3' && <span className='btn gt-seat-btn'><a onClick={() =>
                                            FlightDetailPopup(rph)
                                        }>
                                            <i className="fas fa-plane"  ></i> <span className="mouseHover">Flight Details</span></a></span>
                                        }

                                        {props.showData !== '3' && <span className='btn gt-seat-btn ms-1 mouseHover'>
                                            <a onClick={() => fareSummery(rph)}>
                                                <i className="fas fa-money-bill"></i> Fare Summary</a></span>}

                                        {props.showData !== '3' &&
                                            <span className='btn gt-seat-btn ms-1 mouseHover'>
                                                <a href='javascript:void(0);' className='text-14px' onClick={() => fareRule(fareArray, type)}><i className="fas fa-clipboard"></i> Fare Rules</a></span>
                                        }

                                        {checkSecurityDetails("Book", "Flight", "Book_Flight_View Seats") && props.showData === '1' && <span className='btn gt-seat-btn ms-1'>
                                            <a href='javascript:void(0);' onClick={viewSeat}>
                                                <i className="fas fa-chair"></i>
                                                <span className="mouseHover"> View Seats</span></a></span>}


                                    </div>
                                </div>
                            }
                        </div>
                        <div>

                            <div className="d-lg-flex flight_show_down_wrapper w-100">

                                <div className="flight-shoe_dow_item col-md-9 ccc">
                                    <div className="airline-details ">
                                        <div className="img"><img src={logo} alt="img" className='details-flight-img' /></div>
                                        <span className="airlineName fw-500">{outBound_operating_details[i].name} &nbsp;
                                            {outBound_operating_details[i].code + " " + outBound_operating_details[i].flightNumber}
                                        </span> <span className=' airlineName fw-500 text-white badge rounded-pill class-purple-bg'> {outBound_departure_details[i].cabinName}</span>
                                        <span className="flightNumber">{equipmentName + " "}</span>
                                        <span className="flightNumber fw-bold ml-8px">Operated by</span><span className="flightNumber ml-8px">{operatingAirline.name + " "}</span>
                                    </div>
                                    <div className="flight_inner_show_component">
                                        <div className="flight_det_wrapper">
                                            <div className="flight_det">
                                                <div className="code_time">
                                                    <span className="code">{depCityName}</span>
                                                </div>
                                                <div className="code_time">
                                                    <span className="time">{getFormatTime(depTime)}</span>
                                                </div>
                                                <p className="date">{getFormatDate(outBound_departure_details[i].date)}</p>
                                                <p className="airport">{depAirPortInfo}
                                                </p>
                                                <p className="airport mb-2">{depTerminal}</p>

                                            </div>
                                        </div>
                                        <div className="flight_duration">
                                            <div className="arrow_right"></div>
                                            <span>{getMinutetoHrsMin(outBound_departure_details[i].duration)}</span>
                                            <p> {NoOftechStop + " "} {stop}</p>
                                        </div>
                                        <div className="flight_det_wrapper">
                                            <div className="flight_det">
                                                <div className="code_time">
                                                    <span className="code">{arrCityName}</span>
                                                </div>
                                                <div className="code_time">
                                                    <span className="time">{getFormatTime(arrTime)}</span>
                                                </div>
                                                <p className="date">{getFormatDate(outBound_arrival_details[i].date)}</p>
                                                <p className="airport">{arrAirPortInfo}
                                                </p>
                                                <p className="airport mb-2">{arrTerminal}</p>

                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mb-2'>
                                        {flightArrive !== '' && <div className='col-md-6'>
                                            <div className='text-primary fw-bold text-14px'><i className="fas fa-info-circle"></i>{" " + flightArrive} </div>
                                        </div>}
                                        {
                                            outBound_departure_details[i].flownMileageQty !== undefined && outBound_departure_details[i].flownMileageQty !== '' && outBound_departure_details[i].flownMileageQty !== '0' &&
                                            <div className='col-md-6'>
                                                <div className='text-primary text-14px fw-bold'><i className="fas fa-tachometer-alt"></i> {outBound_departure_details[i].flownMileageQty} Air miles</div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="flight_refund_policy col-md-3">
                                    <div className="TabPanelInner col-sm-12">
                                        <h4 className='col-md-12'><span className='gt-border-bottom'>Check-in Baggage </span></h4>
                                        <div className="flight_info_taable">

                                            {adultBag !== undefined && <p>Per Adult &nbsp;  &nbsp;<span>{adultBag + " " + adultBagUnit} &nbsp; </span></p>}
                                            {childBag !== undefined && <p>Per Child &nbsp;  &nbsp;<span>{childBag + " " + childBagUnit} &nbsp; </span></p>}
                                            {lapInfantBag !== undefined && <p>Per Lap Infant &nbsp; <span>{lapInfantBag + " " + lapInfantBagUnit} &nbsp; </span></p>}
                                            {seatInfantBag !== undefined && <p>Per Seat Infant &nbsp; <span>{seatInfantBag + " " + seatInfantBagUnit} &nbsp; </span></p>}
                                            {adultBag === undefined && childBag === undefined && lapInfantBag ===
                                                undefined && seatInfantBag === undefined && <p>Information
                                                    unavailable for this flight.</p>}
                                        </div>
                                        <h4 className='col-md-12'><span className='gt-border-bottom'> Cabin Baggage</span></h4>
                                        <div className="flight_info_taable">

                                            {cabinAdultBag !== undefined && <p>Per Adult &nbsp;  &nbsp;<span>{cabinAdultBag + " " + cabinAdultBagUnit} &nbsp; </span></p>}
                                            {cabinChildBag !== undefined && <p>Per Child &nbsp;  &nbsp;<span>{cabinChildBag + " " + cabinChildBagUnit} &nbsp; </span></p>}
                                            {cabinLapInfantBag !== undefined && <p>Per Lap Infant &nbsp; <span>{cabinLapInfantBag + " " + cabinLapInfantBagUnit} &nbsp; </span></p>}
                                            {cabinSeatInfantBag !== undefined && <p>Per Seat Infant &nbsp; <span>{cabinSeatInfantBag + " " + cabinSeatInfantBagUnit} &nbsp; </span></p>}
                                            {cabinAdultBag === undefined &&
                                                cabinLapInfantBag === undefined && cabinSeatInfantBag === undefined
                                                && <p>Information unavailable for this flight.</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='row mb-2'>
                                {NoOftechStop >= 1 && <div className='col-md-12 text-center gt-time-btn-flight' >Technical Stop: {getMinutetoHrsMin(outBound_departure_details[i].technicalStops.stopDetail[0].elapsedLayoverTime)} Layover in {outBound_departure_details[i].technicalStops.stopDetail[0].locationName}, Deboarding may not be allowed</div>}

                            </div>
                        </div>

                    </div>


                );
            }
            if (inBound_departure_details.length !== 0) {
                //round trip
                for (let i in inBound_departure_details) {
                    let flightArrive = inBound_departure_details[i].dateChangeNbr === '1' && inBound_departure_details[i].dateChangeNbr !== undefined ? "This flight arrives the next day." : "";
                    let inDepTime = inBound_departure_details[i].time;
                    let inArrTime = inBound_arrival_details[i].time;
                    let inDepCityCode = inBound_departure_details[i].departure.cityCode;
                    let inDepCityName = inBound_departure_details[i].departure.cityName;
                    let inDepCountryName = inBound_departure_details[i].departure.countryName;
                    let inDepCountryCode = inBound_departure_details[i].departure.countryCode;
                    let inDepAirportName = inBound_departure_details[i].departure.locationName === undefined ? "" : inBound_departure_details[i].departure.locationName;
                    let inDepAirportCode = inBound_departure_details[i].departure.locationCode;
                    let inDepTerminal = inBound_departure_details[i].departure.terminal === undefined ? "" : "Terminal " + inBound_departure_details[i].departure.terminal;
                    let len = inBound_departure_details.length - 1;
                    let inArrCityCode = inBound_arrival_details[i].arrival.cityCode;
                    let inArrCityName = inBound_arrival_details[i].arrival.cityName;
                    let inArrCountryName = inBound_arrival_details[i].arrival.countryName;
                    let inArrCountryCode = inBound_arrival_details[i].arrival.countryCode;
                    let inArrAirportName = inBound_arrival_details[i].arrival.locationName === undefined ? "" : inBound_arrival_details[i].arrival.locationName;
                    let inArrAirportCode = inBound_arrival_details[i].arrival.locationCode === undefined ? "" : inBound_arrival_details[i].arrival.locationCode;
                    let inArrTerminal = inBound_arrival_details[i].arrival.terminal === undefined ? "" : "Terminal " + inBound_arrival_details[i].arrival.terminal;
                    let equipmentName = inBound_departure_details[i].equipmentName === undefined ? "" : inBound_departure_details[i].equipmentName;
                    let logo = airlineLogoLink + inbound_operating_details[i].airlineLogo;
                    let timeBetweenFlight = inBound_departure_details[i].elapsedLayoverTime !== undefined ? inBound_departure_details[i].elapsedLayoverTime : "";
                    let rph = inBound_departure_details[i].rph;
                    let inDepAirPortInfo = inDepAirportName + "(" + inDepAirportCode + ")" + ", " + inDepCountryName + "(" + inDepCountryCode + ")";
                    let inArrAirPortInfo = inArrAirportName + "(" + inArrAirportCode + ")" + ", " + inArrCountryName + "(" + inArrCountryCode + ")";
                    let NoOftechStop = parseInt(inBound_departure_details[i].noOfStops !== undefined && inBound_departure_details[i].noOfStops !== '' ? inBound_departure_details[i].noOfStops : 0);

                    let stop = NoOftechStop === 0 || NoOftechStop > 1 ? "stops" : "stop";
                    let operatingAirline = inBound_departure_details[i].operatingAirline;
                    //User Story 2450: Cabin Bag Chnages
                    let cabinAdultBag; let cabinAdultBagUnit; let cabinChildBag; let cabinChildBagUnit;
                    let adultBag; let childBag; let lapInfantBag; let adultBagUnit; let childBagUnit; let lapInfantBagUnit;
                    let cabinLapInfantBag; let cabinLapInfantBagUnit;
                    let seatInfantBag; let seatInfantBagUnit; let cabinSeatInfantBag; let cabinSeatInfantBagUnit;
                    if (inBound_departure_details[i].bag !== '' || inBound_departure_details[i].bag !== undefined) {
                        inBound_departure_details[i].bag.forEach(element => {
                            if (element.baggageAllowance !== undefined) {
                                if (element.type === 'ADT') {
                                    element.baggageAllowance.forEach(item => {
                                        //check for check-in bag
                                        if (item.type === 'Check-in Allowance') {
                                            adultBag = item.maxWeight === undefined ? item.numberOfPieces : item.maxWeight;
                                            adultBagUnit = getBaggageUnitTextData(adultBag, item);
                                        }
                                        //check for cabin bag
                                        if (item.type === "for Carry-On Allowances") {
                                            cabinAdultBag = item.maxWeight === undefined ? item.numberOfPieces : item.maxWeight
                                            cabinAdultBagUnit = getBaggageUnitTextData(cabinAdultBag, item);
                                        }
                                    });
                                }
                                else if (element.type === 'CNN') {
                                    element.baggageAllowance.forEach(item => {
                                        //check for check-in bag
                                        if (item.type === 'Check-in Allowance') {
                                            childBag = item.maxWeight === undefined ? item.numberOfPieces : item.maxWeight
                                            childBagUnit = getBaggageUnitTextData(childBag, item);
                                        }
                                        //check for cabin bag
                                        if (item.type === "for Carry-On Allowances") {
                                            cabinChildBag = item.maxWeight === undefined ? item.numberOfPieces : item.maxWeight
                                            cabinChildBagUnit = getBaggageUnitTextData(cabinChildBag, item);
                                        }
                                    });
                                }
                                else if (element.type === 'INF') {
                                    element.baggageAllowance.forEach(item => {
                                        //check for check-in bag
                                        if (item.type === 'Check-in Allowance') {
                                            lapInfantBag = item.maxWeight === undefined ? item.numberOfPieces : item.maxWeight
                                            lapInfantBagUnit = getBaggageUnitTextData(lapInfantBag, item);
                                        }
                                        //check for cabin bag
                                        if (item.type === "for Carry-On Allowances") {
                                            cabinLapInfantBag = item.maxWeight === undefined ? item.numberOfPieces : item.maxWeight
                                            cabinLapInfantBagUnit = getBaggageUnitTextData(cabinLapInfantBag, item);
                                        }
                                    });
                                } else if (element.type === 'INS') {
                                    element.baggageAllowance.forEach(item => {
                                        //check for check-in bag
                                        if (item.type === 'Check-in Allowance') {
                                            seatInfantBag = item.maxWeight === undefined ? item.numberOfPieces : item.maxWeight
                                            seatInfantBagUnit = getBaggageUnitTextData(seatInfantBag, item);
                                        }
                                        //check for cabin bag
                                        if (item.type === "for Carry-On Allowances") {
                                            cabinSeatInfantBag = item.maxWeight === undefined ? item.numberOfPieces : item.maxWeight
                                            cabinSeatInfantBagUnit = getBaggageUnitTextData(cabinSeatInfantBag, item);
                                        }
                                    });

                                }
                            }

                        });
                    }
                    show.push(
                        <div>
                            {timeBetweenFlight === '' && <hr className='my-1'></hr>}
                            {timeBetweenFlight !== "" &&
                                <div className='row'>
                                    <div className='col-md-12 text-center gt-time-btn-flight' >Time between flights:{" " + getMinutetoHrsMin(timeBetweenFlight)}</div>
                                </div>
                            }
                            <div className='row'>
                                <div className='col-md-4'>
                                    <div className='departure-text'>
                                        <span><i className="fas fa-plane-departure"></i></span> Return {inBound_departure_details[i].flightRefNumber !== undefined &&
                                            // <span>Airline PNR Number {inBound_departure_details[i].flightRefNumber}</span>
                                            <span className='text-primary airlineName fw-500'> Airline PNR:  {inBound_departure_details[i].flightRefNumber}</span>
                                        }
                                    </div>
                                </div>
                                {
                                    (valueParam.return === undefined || valueParam.return === '' || valueParam.return === '0') &&
                                    <div className='col-md-8'>

                                        <div className='pt-10 float-md-end float-lg-end'>
                                            {props.showData !== '3' && <span className='btn gt-seat-btn'><a onClick={() =>
                                                FlightDetailPopup(rph)
                                            }><i className="fas fa-plane"></i><span className="mouseHover"> Flight Details</span></a>
                                            </span>}

                                            {props.showData !== '3' && <span className='btn gt-seat-btn ms-1'><a onClick={() =>




                                                fareSummery(rph)
                                            }>
                                                <i className="fas fa-money-bill"></i> Fare Summary</a></span>
                                            }
                                            {props.showData !== '3' &&
                                                <span className='btn gt-seat-btn ms-1 mouseHover'>
                                                    <a href='javascript:void(0);' className='text-14px' onClick={() => fareRule(fareArray, 'roundTrip')}><i className="fas fa-clipboard"></i> Fare Rules</a></span>
                                            }

                                            {checkSecurityDetails("Book", "Flight", "Book_Flight_View Seats") && props.showData === '1' && <span className='btn gt-seat-btn ms-1'><a href='javascript:void(0);' onClick={viewSeat}><i className="fas fa-chair"></i><span className="mouseHover"> View Seats</span></a></span>}

                                        </div>
                                    </div>
                                }

                            </div>
                            <div>
                                <div className="d-lg-flex flight_show_down_wrapper w-100">

                                    <div className="flight-shoe_dow_item col-md-9 aaa">
                                        <div className="airline-details">
                                            <div className="img"><img src={logo} alt="img" className='details-flight-img' /></div>
                                            <span className="airlineName fw-500">{inbound_operating_details[i].name} &nbsp;
                                                {inbound_operating_details[i].code + " " + inbound_operating_details[i].flightNumber}
                                            </span> <span className='text-white badge rounded-pill class-purple-bg airlineName fw-500 ms-1'> {inBound_departure_details[i].cabinName}</span>
                                            <span className="flightNumber">{equipmentName}</span>
                                            <span className="flightNumber ml-8px fw-bold">Operated by</span><span className="flightNumber ml-8px">{operatingAirline.name}</span>
                                        </div>
                                        <div className="flight_inner_show_component">
                                            <div className="flight_det_wrapper">
                                                <div className="flight_det">
                                                    <div className="code_time">
                                                        <span className="code">{inDepCityName}</span>
                                                    </div>
                                                    <div className="code_time">
                                                        <span className="time">{getFormatTime(inDepTime)}</span>
                                                    </div>
                                                    <p className="date">{getFormatDate(inBound_departure_details[i].date)}</p>
                                                    <p className="airport">{inDepAirPortInfo}
                                                    </p>
                                                    <p className="airport mb-2">{inDepTerminal}</p>

                                                </div>
                                            </div>
                                            <div className="flight_duration">
                                                <div className="arrow_right"></div>
                                                <span>{getMinutetoHrsMin(inBound_departure_details[i].duration)}</span>
                                                <p> {NoOftechStop + " "}{stop}</p>
                                            </div>
                                            <div className="flight_det_wrapper">
                                                <div className="flight_det">
                                                    <div className="code_time">
                                                        <span className="code">{inArrCityName}</span>
                                                    </div>
                                                    <div className="code_time">
                                                        <span className="time">{getFormatTime(inArrTime)}</span>
                                                    </div>
                                                    <p className="date">{getFormatDate(inBound_arrival_details[i].date)}</p>
                                                    <p className="airport">{inArrAirPortInfo}
                                                    </p>
                                                    <p className="airport mb-2">{inArrTerminal}</p>

                                                </div>
                                            </div>
                                        </div>
                                        <div className='row mb-2'>
                                            {
                                                flightArrive !== '' && <div className='col-md-6'>
                                                    <div className='text-primary fw-bold text-14px'><i className="fas fa-info-circle"></i>{" " + flightArrive} </div>
                                                </div>
                                            }
                                            {
                                                inBound_departure_details[i].flownMileageQty !== undefined && inBound_departure_details[i].flownMileageQty !== '' && inBound_departure_details[i].flownMileageQty !== '0' &&
                                                <div className='col-md-6'>
                                                    <div className='text-primary text-14px fw-bold'><i className="fas fa-tachometer-alt"></i> {inBound_departure_details[i].flownMileageQty} Air miles</div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="flight_refund_policy col-md-3">
                                        <div className="TabPanelInner col-sm-12">
                                            <h4 className='col-md-12'><span className='gt-border-bottom'>Check-in Baggage </span></h4>
                                            <div className="flight_info_taable">
                                                {adultBag !== undefined && <p>Per Adult &nbsp;  &nbsp;<span>{adultBag + " " + adultBagUnit} &nbsp; </span></p>}
                                                {childBag !== undefined && <p>Per Child &nbsp;  &nbsp;<span>{childBag + " " + childBagUnit} &nbsp; </span></p>}
                                                {lapInfantBag !== undefined && <p>Per Lap Infant &nbsp; <span>{lapInfantBag + " " + lapInfantBagUnit} &nbsp; </span></p>}
                                                {seatInfantBag !== undefined && <p>Per Seat Infant &nbsp; <span>{seatInfantBag + " " + seatInfantBagUnit} &nbsp; </span></p>}
                                                {adultBag === undefined && childBag === undefined && lapInfantBag ===
                                                    undefined && seatInfantBag === undefined && <p>Information
                                                        unavailable for this flight.</p>}
                                            </div>
                                            <h4 className='col-md-12'><span className='gt-border-bottom'> Cabin Baggage</span></h4>
                                            <div className="flight_info_taable">

                                                {cabinAdultBag !== undefined && <p>Per Adult &nbsp;  &nbsp;<span>{cabinAdultBag + " " + cabinAdultBagUnit} &nbsp; </span></p>}
                                                {cabinChildBag !== undefined && <p>Per Child &nbsp;  &nbsp;<span>{cabinChildBag + " " + cabinChildBagUnit} &nbsp; </span></p>}
                                                {cabinLapInfantBag !== undefined && <p>Per Lap Infant &nbsp; <span>{cabinLapInfantBag + " " + cabinLapInfantBagUnit} &nbsp; </span></p>}
                                                {cabinSeatInfantBag !== undefined && <p>Per Seat Infant &nbsp; <span>{cabinSeatInfantBag + " " + cabinSeatInfantBagUnit} &nbsp; </span></p>}
                                                {cabinAdultBag === undefined &&
                                                    cabinLapInfantBag === undefined && cabinSeatInfantBag === undefined
                                                    && <p>Information unavailable for this flight.</p>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                {NoOftechStop >= 1 && <div className='col-md-12 text-center gt-time-btn-flight' >Technical Stop: {getMinutetoHrsMin(inBound_departure_details[i].technicalStops.stopDetail[0].elapsedLayoverTime)} Layover in {inBound_departure_details[i].technicalStops.stopDetail[0].locationName}, Deboarding may not be allowed</div>}
                            </div>
                        </div>
                    )
                }
            }
            return show;

        }
        else {
            let fareInfo = props.searchFlight.airItineraryPricingInfo;
            for (let i = 1; i <= parseInt(multicityFlightCount); i++) {
                let temp = multicity_dep_details_map.get("" + i);

                let arr = multicity_arr_details_map.get("" + i);

                for (let j = 0; j < temp.length; j++) {
                    let cabinName = temp[j].cabinName;
                    let duration = temp[j].duration;
                    let depCityName = temp[j].departure.cityName;
                    let depTime = temp[j].time
                    let depDate = temp[j].date;
                    let depCityCode = temp[j].departure.cityCode;
                    let depCountryName = temp[j].departure.countryName;
                    let depCountryCode = temp[j].departure.countryCode;
                    let depAirportName = temp[j].departure.locationName === undefined ? "" : temp[j].departure.locationName;
                    let depAirportCode = temp[j].departure.locationCode === undefined ? "" : temp[j].departure.locationCode;
                    let depTerminal = temp[j].departure.terminal === undefined ? "" : "Terminal " + temp[j].departure.terminal;
                    let flightArrive = temp[j].dateChangeNbr === '1' && temp[j].dateChangeNbr !== undefined ? "This flight arrives the next day." : "";
                    let equipmentName = temp[j].equipmentName === undefined ? "" : temp[j].equipmentName;
                    let logo = airlineLogoLink + temp[j].marketingAirline.airlineLogo;
                    let timeBetweenFlight = temp[j].elapsedLayoverTime !== undefined ? temp[j].elapsedLayoverTime : "";
                    let rph = temp[j].rph;
                    let NoOftechStop = parseInt(temp[j].noOfStops !== undefined && temp[j].noOfStops !== '' ? temp[j].noOfStops : 0);
                    let stop = NoOftechStop === 0 || NoOftechStop > 1 ? "stops" : "stop";
                    let operatingAirline = temp[j].operatingAirline;

                    //User Story 2450: Cabin Bag Chnages
                    let cabinAdultBag; let cabinAdultBagUnit; let cabinChildBag; let cabinChildBagUnit; let cabinLapInfantBag; let cabinLapInfantBagUnit; let seatInfantBag; let seatInfantBagUnit; let cabinSeatInfantBag; let cabinSeatInfantBagUnit;
                    let adultBag; let childBag; let lapInfantBag; let adultBagUnit; let childBagUnit; let lapInfantBagUnit;
                    if (temp[j].bag !== '' || temp[j].bag !== undefined) {
                        temp[j].bag.forEach(element => {
                            if (element.baggageAllowance !== undefined) {
                                if (element.type === 'ADT') {
                                    element.baggageAllowance.forEach(item => {
                                        //check for check-in bag
                                        if (item.type === 'Check-in Allowance') {
                                            adultBag = item.maxWeight === undefined ? item.numberOfPieces : item.maxWeight;
                                            adultBagUnit = getBaggageUnitTextData(adultBag, item);
                                        }
                                        //check for cabin bag
                                        if (item.type === "for Carry-On Allowances") {
                                            cabinAdultBag = item.maxWeight === undefined ? item.numberOfPieces : item.maxWeight
                                            cabinAdultBagUnit = getBaggageUnitTextData(cabinAdultBag, item);
                                        }
                                    });
                                }
                                else if (element.type === 'CNN') {
                                    element.baggageAllowance.forEach(item => {
                                        //check for check-in bag
                                        if (item.type === 'Check-in Allowance') {
                                            childBag = item.maxWeight === undefined ? item.numberOfPieces : item.maxWeight
                                            childBagUnit = getBaggageUnitTextData(childBag, item);
                                        }
                                        //check for cabin bag
                                        if (item.type === "for Carry-On Allowances") {
                                            cabinChildBag = item.maxWeight === undefined ? item.numberOfPieces : item.maxWeight
                                            cabinChildBagUnit = getBaggageUnitTextData(cabinChildBag, item);
                                        }
                                    });
                                }
                                else if (element.type === 'INF') {
                                    element.baggageAllowance.forEach(item => {
                                        //check for check-in bag
                                        if (item.type === 'Check-in Allowance') {
                                            lapInfantBag = item.maxWeight === undefined ? item.numberOfPieces : item.maxWeight
                                            lapInfantBagUnit = getBaggageUnitTextData(lapInfantBag, item);
                                        }
                                        //check for cabin bag
                                        if (item.type === "for Carry-On Allowances") {
                                            cabinLapInfantBag = item.maxWeight === undefined ? item.numberOfPieces : item.maxWeight
                                            cabinLapInfantBagUnit = getBaggageUnitTextData(cabinLapInfantBag, item);
                                        }
                                    });
                                }
                                else if (element.type === 'INS') {
                                    element.baggageAllowance.forEach(item => {
                                        //check for check-in bag
                                        if (item.type === 'Check-in Allowance') {
                                            seatInfantBag = item.maxWeight === undefined ? item.numberOfPieces : item.maxWeight
                                            seatInfantBagUnit = getBaggageUnitTextData(seatInfantBag, item);
                                        }
                                        //check for cabin bag
                                        if (item.type === "for Carry-On Allowances") {
                                            cabinSeatInfantBag = item.maxWeight === undefined ? item.numberOfPieces : item.maxWeight
                                            cabinSeatInfantBagUnit = getBaggageUnitTextData(cabinSeatInfantBag, item);
                                        }
                                    });

                                }
                            }
                        });
                    }


                    let arrCityName = arr[j].arrival.cityName;
                    let arrTime = arr[j].time;
                    let arrDate = arr[j].date;
                    let arrCityCode = arr[j].arrival.cityCode;
                    let arrCountryName = arr[j].arrival.countryName;
                    let arrCountryCode = arr[j].arrival.countryCode;
                    let arrTerminal = arr[j].arrival.terminal === undefined ? "" : "Terminal " + arr[j].arrival.terminal;
                    let arrAirportName = arr[j].arrival.locationName;
                    let arrAirportCode = arr[j].arrival.locationCode;
                    let depAirPortInfo = depAirportName + "(" + depAirportCode + ")" + ", " + depCountryName + "(" + depCountryCode + ")";
                    let arrAirPortInfo = arrAirportName + "(" + arrAirportCode + ")" + ", " + arrCountryName + "(" + arrCountryCode + ")";



                    show.push(
                        <div>
                            {(i !== 1 && j === 0) && <hr className='my-1'></hr>}
                            {timeBetweenFlight !== '' &&
                                <div className='row'>
                                    <div className='col-md-12 text-center gt-time-btn-flight' >Time between flights:{" " + getMinutetoHrsMin(timeBetweenFlight)}</div>
                                </div>
                            }
                            <div className='row'>
                                <div className='col-md-4'>
                                    <div className='departure-text'>
                                        <span><i className="fas fa-plane-departure"></i></span> Departure {temp[j].flightRefNumber !== undefined &&
                                            <span className='text-primary airlineName fw-500'> Airline PNR: {temp[j].flightRefNumber}</span>
                                        }
                                    </div>
                                </div>
                                {
                                    (valueParam.return === undefined || valueParam.return === '' || valueParam.return === '0') &&
                                    <div className='col-md-8'>

                                        <div className='pt-10 float-md-end float-lg-end'>
                                            {props.showData !== '3' && <span className='btn gt-seat-btn'><a onClick={() =>
                                                FlightDetailPopup(rph)
                                            }><i className="fas fa-plane"></i><span className="mouseHover"> Flight Details</span></a>
                                            </span>}
                                            {props.showData !== '3' && <span className='btn gt-seat-btn ms-1 mouseHover'><a onClick={() => fareSummery(rph)}>
                                                <i className="fas fa-money-bill"></i> Fare Summary</a></span>
                                            }
                                            {(i === 1 && j == 0) && props.showData !== '3' &&
                                                <span className='btn gt-seat-btn ms-1 mouseHover'>
                                                    <a href='javascript:void(0);' className='text-14px' onClick={() => fareRule(props.fareRuleData, fareInfo, "multicity", temp[j].flightID)}><i className="fas fa-clipboard"></i> Fare Rules</a></span>
                                            }
                                            {checkSecurityDetails("Book", "Flight", "Book_Flight_View Seats") && props.showData === '1' && <span className='btn gt-seat-btn ms-1'><a href='javascript:void(0);' onClick={viewSeat}><i className="fas fa-chair"></i> <span className="mouseHover">View Seats</span></a></span>}

                                        </div>
                                    </div>
                                }

                            </div>
                            <div>
                                <div className="d-lg-flex flight_show_down_wrapper w-100">

                                    <div className="flight-shoe_dow_item col-md-9 bbb">
                                        <div className="airline-details">
                                            <div className="img"><img src={logo} alt="img" className='details-flight-img' /></div>
                                            <span className="airlineName fw-500">{temp[j].marketingAirline.name} &nbsp;
                                                {temp[j].marketingAirline.code + " " + temp[j].marketingAirline.flightNumber}
                                            </span> <span className='text-white badge rounded-pill class-purple-bg airlineName fw-500 ms-1'> {cabinName}</span>
                                            <span className="flightNumber">{equipmentName}</span>
                                            <span className="flightNumber ml-8px fw-bold">Operated by</span><span className="flightNumber ml-8px">{operatingAirline.name}</span>
                                        </div>
                                        <div className="flight_inner_show_component">
                                            <div className="flight_det_wrapper">
                                                <div className="flight_det">
                                                    <div className="code_time">
                                                        <span className="code">{depCityName}</span>

                                                    </div>
                                                    <div className="code_time">
                                                        <span className="time">{getFormatTime(depTime)}</span>
                                                    </div>
                                                    <p className="date">{getFormatDate(depDate)}</p>
                                                    <p className="airport">{depAirPortInfo}
                                                    </p>
                                                    <p className="airport mb-2">{depTerminal}</p>

                                                </div>
                                            </div>
                                            <div className="flight_duration">
                                                <div className="arrow_right"></div>
                                                <span>{getMinutetoHrsMin(duration)}</span>
                                                <p> {NoOftechStop + " "}{stop}</p>
                                            </div>
                                            <div className="flight_det_wrapper">
                                                <div className="flight_det">
                                                    <div className="code_time">
                                                        <span className="code">{arrCityName}</span>
                                                    </div>
                                                    <div className="code_time">
                                                        <span className="time">{getFormatTime(arrTime)}</span>
                                                    </div>
                                                    <p className="date">{getFormatDate(arrDate)}</p>
                                                    <p className="airport">{arrAirPortInfo}
                                                    </p>
                                                    <p className="airport mb-2">{arrTerminal}</p>

                                                </div>
                                            </div>
                                        </div>
                                        <div className='row mb-2'>
                                            {flightArrive !== '' && <div className='col-md-6'>
                                                <div className='text-primary fw-bold text-14px'><i className="fas fa-info-circle"></i>{" " + flightArrive} </div>
                                            </div>}
                                            {
                                                temp[j].flownMileageQty !== undefined && temp[j].flownMileageQty !== '' && temp[j].flownMileageQty !== '0' &&
                                                <div className='col-md-6'>
                                                    <div className='text-primary text-14px fw-bold'><i className="fas fa-tachometer-alt"></i> {temp[j].flownMileageQty} Air miles</div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="flight_refund_policy col-md-3">
                                        <div className="TabPanelInner col-sm-12">
                                            <h4 className='col-md-12'><span className='gt-border-bottom'>Check-in Baggage </span></h4>
                                            <div className="flight_info_taable">

                                                {adultBag !== undefined && <p>Per Adult &nbsp;  &nbsp;<span>{adultBag + " " + adultBagUnit} &nbsp; </span></p>}
                                                {childBag !== undefined && <p>Per Child &nbsp;  &nbsp;<span>{childBag + " " + childBagUnit} &nbsp; </span></p>}
                                                {lapInfantBag !== undefined && <p>Per Lap Infant &nbsp; <span>{lapInfantBag + " " + lapInfantBagUnit} &nbsp; </span></p>}
                                                {seatInfantBag !== undefined && <p>Per Seat Infant &nbsp; <span>{seatInfantBag + " " + seatInfantBagUnit} &nbsp; </span></p>}
                                                {adultBag === undefined && childBag === undefined && lapInfantBag ===
                                                    undefined && seatInfantBag === undefined && <p>Information
                                                        unavailable for this flight.</p>}
                                            </div>
                                            <h4 className='col-md-12'><span className='gt-border-bottom'> Cabin Baggage</span></h4>
                                            <div className="flight_info_taable">

                                                {cabinAdultBag !== undefined && <p>Per Adult &nbsp;  &nbsp;<span>{cabinAdultBag + " " + cabinAdultBagUnit} &nbsp; </span></p>}
                                                {cabinChildBag !== undefined && <p>Per Child &nbsp;  &nbsp;<span>{cabinChildBag + " " + cabinChildBagUnit} &nbsp; </span></p>}
                                                {cabinLapInfantBag !== undefined && <p>Per Lap Infant &nbsp; <span>{cabinLapInfantBag + " " + cabinLapInfantBagUnit} &nbsp; </span></p>}
                                                {cabinSeatInfantBag !== undefined && <p>Per Seat Infant &nbsp; <span>{cabinSeatInfantBag + " " + cabinSeatInfantBagUnit} &nbsp; </span></p>}
                                                {cabinAdultBag === undefined &&
                                                    cabinLapInfantBag === undefined && cabinSeatInfantBag === undefined
                                                    && <p>Information unavailable for this flight.</p>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                {NoOftechStop >= 1 && <div className='col-md-12 text-center gt-time-btn-flight' >Technical Stop: {getMinutetoHrsMin(temp[j].technicalStops.stopDetail[0].elapsedLayoverTime)} Layover in {temp[j].technicalStops.stopDetail[0].locationName}, Deboarding may not be allowed</div>}
                            </div>
                        </div>
                    );
                }


            }
            return show;
        }

        return show;
    }
    return (
        <Fragment>
            <ReactTooltip
                anchorId={`sendMail${props.id}`}
                place="bottom"
                variant="info"
                multiline={true}
                className="tooltip"
                html={"Email Proposal"}
            />
            <ReactTooltip
                anchorId={`Print${props.id}`}
                place="bottom"
                variant="info"
                multiline={true}
                className="tooltip"
                html={"Print Flights"}
            />
            <ReactTooltip
                anchorId={`PolicyIn${props.id}`}
                place="bottom"
                variant="info"
                multiline={true}
                className="tooltip"
                html={"In Policy"}
            />
            <ReactTooltip
                anchorId={`PolicyOut${props.id}`}
                place="bottom"
                variant="info"
                multiline={true}
                className="tooltip"
                html={"Out of Policy"}
            />
            {confirm && (
                <ConfirmationModal
                    title={confirm.title}
                    message={confirm.message}
                    onOk={okHandler}
                    onCancel={cancelHandler}
                ></ConfirmationModal>
            )}
            {error1 && (
                <PopupModal
                    title={error1.title}
                    message={error1.message}
                    onConfirm={errorHandler}
                ></PopupModal>
            )}
            {errorFareSummary && (
                <FareRulePopupModal
                    title={errorFareSummary.title}
                    message={errorFareSummary.message}
                    onConfirm={errorHandler}
                ></FareRulePopupModal>
            )}

            {errorPrint && (
                <PrintPopupModal
                    title={errorPrint.title}
                    message={errorPrint.message}
                    onConfirm={errorHandler}
                ></PrintPopupModal>
            )}
            {
                errorMail && (
                    <PopupModal
                        title={errorMail.title}
                        message={errorMail.message}
                        onConfirm={errorHandler}
                    >
                    </PopupModal>
                )
            }
            {errorOutPolicy && (
                <OutPolicyPopupModal
                    title={errorOutPolicy.title}
                    message={errorOutPolicy.message}
                    onConfirm={errorHandlerOutPolicy}
                    onClose={errorHandlerOutPolicyClose}
                ></OutPolicyPopupModal>
            )}
            {flightLimitError && <ErrorModal
                title="Message"
                message='You have added the maximum number of flights allowed in the proposal.'
                onConfirm={errorHandler}
            />}
            {upgradeCbainConfirm && (
                <ConfirmationModal
                    title={upgradeCbainConfirm.title}
                    message={upgradeCbainConfirm.message}
                    onOk={okUpgradeCbainHandler}
                    onCancel={cancelUpgradeCbainHandler}
                ></ConfirmationModal>
            )}

            {multicity_dep_details_map.size !== 0 &&
                <>
                    <div className="flight_search_item_wrappper xyz">
                        <div className={classNameUpdated}>
                            <div className={classNameUpdatedList}>
                                {showAirlineDetails()}
                                {fareDetails}
                            </div>
                            {fareRules}
                        </div>
                        <div data="collapseExampleCommon" className={`${props.showData === '3' ? "flight_policy_refund collapse show" : " flight_policy_refund collapse"}`} id={"collapseExample" + props.id}>
                            {showDetails()}
                        </div>

                    </div>
                </>
            }
            {multicity_dep_details_map.size === 0 && <div className="flight_search_item_wrappper xyz">
                {showAirlineDetails()}
                <div data="collapseExampleCommon" className={`${props.showData === '3' ? "flight_policy_refund collapse show" : " flight_policy_refund collapse"}`} id={"collapseExample" + props.id}>
                    {showDetails()}
                </div>

            </div>
            }
        </Fragment>
    );
};

export default FlightItem
