import { Fragment, useCallback, useState, useEffect, useRef } from 'react';
import Dropdown from 'react-select';
import { useTranslation } from 'react-i18next';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { SearchHotelChain, SearchHotelExclusiveDeals, SearchHotelName } from '../../../../services/Fee-API';
import { getPccList, getRatePlanList, getRatePlanPCCWiseList } from '../../../../services/RatePlanAllowed-API';
import { GET_ALL_AIRPORT_LIST, GET_BLOCK_COUNTRY_CITY_LIST } from '../../../../services/CONSTANTS';
import { getConfigurationByValue, getConfigurationByBoolen } from '../../../../utils/ConfigurationsFunction';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import { checkSecurityDetails } from '../../../../utils/RoleUtils';
import useHttp from '../../../../services/use-http';
import { getFormatDate } from '.././../../../utils/CommonFunction';
import ErrorModal from "../../../../components/Common/Error/ErrorModal";

//User Story 1070: Change in Search for Single PNR.
import queryString from 'query-string';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import sorter from "sort-nested-json";

let SourceOptions = [{ value: '100', label: 'Show Sabre Only' },
{ value: '113', label: 'Show CGN Only' }, { value: '000', label: 'Show All' }];

const SearchHotel = () => {
    const { t } = useTranslation(['translation.SearchHotel']);

    const [branDisabled, setBranDisabled] = useState(false);
    const radiusInputRef = useRef('');
    const [cityCode, setCityCode] = useState();
    const [selectedHotel, setSelectedHotel] = useState();
    const [searchString, setSearchString] = useState('');
    const [hotelNameList, setHotelNameList] = useState([]);
    const [hotelName, setHotelName] = useState();
    const [inputErrorHotelName, setinputHotelNameError] = useState(false);
    const [radius, setRadius] = useState();
    const history = useHistory();
    const [statusMoveDownClick, setStatusMoveDownClick] = useState(false);
    const [isadvanced, setIsAdvanced] = useState(false);
    const [userChangedExclDeals, setUserChangedExclDeals] = useState(false);
    const [hoteNameDisable, setHotelNameDisable] = useState(true);
    const [minToDateChange, setMinToDateChange] = useState('');

    const [isFirtTime, setFirstTime] = useState("");
    const [error1, setError] = useState('');
    const [hotelNameError, setHotelNameError] = useState();
    const [pageFocus, setPageFocus] = useState(true);
    const [isSubmitClicked, setIsSubmitClicked] = useState(false);
    const [items, setItems] = useState([]);
    const [guestsValue, setGuestsValue] = useState('');
    const [roomsValue, setRoomsValue] = useState('');
    const [chainCode, setChainCode] = useState('');
    const [providerCode, setProviderCode] = useState('');
    const [sourceValue, setSourceValue] = useState('');
    const [officeWisePCCCode, setOfficeWisePCCCode] = useState('');
    const [ratePlanCode, setRatePlanCode] = useState('');
    const [exclusiveDealsChecked, setExclusiveDealsChecked] = useState(false)
    const [exclusiveDealsDisable, setExclusiveDealsDisable] = useState(true);
    const [isexclusiveDealsDisable, setIsExclusiveDealsDisable] = useState(false);
    const [loadComponent, setLoadComponent] = useState('');
    const [ratingValue, setRatingValue] = useState('');
    const [checkInDateSelected, setCheckInDateSelected] = useState(false);
    const [checkOutDateSelected, setCheckOutDateSelected] = useState(false);

    const location = useLocation();
    const valueParam = queryString.parse(location.search);

    let ratingList = [];
    let defaultRating = "";
    let travelRequestFieldsDataJson = "";

    if (localStorage.getItem("loginData") !== null && localStorage.getItem('isCorporate') !== null && localStorage.getItem('isCorporate') === 'true') {
        if (localStorage.getItem("loginData") !== null && sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
            let searchPolicyResponseTemp = JSON.parse(localStorage.getItem("loginData"));
            travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));

            let searchPolicyResponse = searchPolicyResponseTemp.policy === null ? null : searchPolicyResponseTemp.policy.filter(a => a.tripReasonID === travelRequestFieldsDataJson.tripReason && a.isHotel === true);

            if (searchPolicyResponseTemp?.policy !== null && searchPolicyResponse[0] !== undefined && searchPolicyResponse[0].rating !== undefined && searchPolicyResponse[0].rating !== null && searchPolicyResponse[0].rating !== '') {
                let ratingListTemp = [];
                ratingListTemp.push(
                    { value: 6, label: 'All' },
                    { value: 5, label: '5' },
                    { value: 4, label: '4' },
                    { value: 3, label: '3' },
                    { value: 2, label: '2' },
                    { value: 1, label: '1' }
                );

                let ratingTemp = searchPolicyResponse[0].rating === "All" ? 6 : parseInt(searchPolicyResponse[0].rating);
                let highestRatingText = ratingListTemp.filter(a => a.value === ratingTemp)[0].value;
                let highestRatingId = ratingTemp;

                for (let index = highestRatingId; index > 0; index--) {
                    ratingList.push(
                        { value: ratingListTemp.filter(a => a.value === index)[0].value, label: index === 6 ? 'All' : index.toString() }
                    );
                }

                if (valueParam.return !== undefined && valueParam.return !== '' && valueParam.return === '1' && sessionStorage.getItem("hotelSessionDataJson") !== null) {
                    let hotelSessionDataJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
                    defaultRating = hotelSessionDataJson.rating;
                }
                else {
                    let hotelSessionDataJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
                    defaultRating = sessionStorage.getItem('hotelSessionDataJson') !== null && sessionStorage.getItem('hotelSessionDataJson') !== '' && hotelSessionDataJson.rating !== '' ? hotelSessionDataJson.rating : highestRatingText;
                }
            }
            else {
                ratingList.push(
                    { value: 6, label: 'All' },
                    { value: 5, label: '5' },
                    { value: 4, label: '4' },
                    { value: 3, label: '3' },
                    { value: 2, label: '2' },
                    { value: 1, label: '1' }
                );

                if (valueParam.return !== undefined && valueParam.return !== '' && valueParam.return === '1' && sessionStorage.getItem("hotelSessionDataJson") !== null) {
                    let hotelSessionDataJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
                    defaultRating = hotelSessionDataJson.rating;
                }
                else {
                    let hotelSessionDataJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
                    //defaultRating = sessionStorage.getItem('hotelSessionDataJson') !== null && sessionStorage.getItem('hotelSessionDataJson') !== '' && hotelSessionDataJson.rating !== '' ? hotelSessionDataJson.rating : 6;
                    if (sessionStorage.getItem('hotelSessionDataJson') !== null && sessionStorage.getItem('hotelSessionDataJson') !== '' && hotelSessionDataJson.rating !== '') {
                        defaultRating = hotelSessionDataJson.rating;
                    }
                }
            }
        }
        else {
            ratingList.push(
                { value: 6, label: 'All' },
                { value: 5, label: '5' },
                { value: 4, label: '4' },
                { value: 3, label: '3' },
                { value: 2, label: '2' },
                { value: 1, label: '1' }
            );

            if (valueParam.return !== undefined && valueParam.return !== '' && valueParam.return === '1' && sessionStorage.getItem("hotelSessionDataJson") !== null) {
                let hotelSessionDataJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
                defaultRating = hotelSessionDataJson.rating;
            }
            else {
                let hotelSessionDataJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
                defaultRating = sessionStorage.getItem('hotelSessionDataJson') !== null && sessionStorage.getItem('hotelSessionDataJson') !== '' && hotelSessionDataJson.rating !== '' ? hotelSessionDataJson.rating : 6;
            }
        }
    }

    const refDestination = useRef(null);
    useOutsideAlerter(refDestination);

    var fromTodayDate = new Date();
    fromTodayDate.setDate(fromTodayDate.getDate() + 14);

    var toTodayDate = new Date();
    toTodayDate.setDate(toTodayDate.getDate() + 15);

    const { sendRequest: sendRequestChainCode, statusChainCode, data: loadedChainCode } = useHttp(SearchHotelChain);
    const { sendRequest: sendRequestOfficeWisePCC, status: statusOfficeWisePCC, data: loadedOfficeWisePCC } = useHttp(getPccList);
    const { sendRequest: sendRequestGetRatePlan, statusRequestGetRatePlan, data: loadedRequestGetRatePlan } = useHttp(getRatePlanList);
    const { sendRequest: sendRequestRequestGetRatePlanPCCWise, statusGetRatePlanPCCWise, data: loadedGetRatePlanPCCWise } = useHttp(getRatePlanPCCWiseList);
    const { sendRequest: sendRequestExclusiveDeals, statusExclusiveDeals, data: loadedExclusiveDeals } = useHttp(SearchHotelExclusiveDeals);
    const { sendRequest: sendHotelName, status: statusHotelName, data: loadedHotelName } = useHttp(SearchHotelName);

    if (sessionStorage.getItem("hotelSessionDataJson") === null) {
        const hotelSessionDataJson = ({
            "txtDestination": {},
            "spnDestinationAirportDetails": {},
            "calCheckInDateDB": {},
            "calCheckOutDateDB": {},
            "calCheckIn": {},
            "calCheckOut": {},
            "guests": {},
            "rooms": {},
            "brands": {},
            "preferenceOptions": {},
            "roomGuests": {},
            "brandsData": {},
            "providerCode": {},
            "HotelName": {},
            "providerCodeData": {},
            "Radius": {},
            "Source": {},
            "ratePlanCode": {},
            "ratePlanCodeData": {},
            "pccOfficeWiseCodeData": {},

        });
        sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionDataJson));
    }


    useEffect(() => {
        if (localStorage.getItem("airportSuggestion") === null || localStorage.getItem("airportSuggestion").length < 3) {
            let airportSuggestionResutTemp = [];
            fetch(GET_ALL_AIRPORT_LIST(), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('idToken'),
                },
            }).then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson['data'] !== null) {
                        responseJson['data'].forEach(element2 => {
                            airportSuggestionResutTemp.push(element2);
                        });
                    }
                    localStorage.setItem("airportSuggestion", JSON.stringify(airportSuggestionResutTemp));
                });
        }

        if (localStorage.getItem("blockedCountryCity") === null) {
            let blockedCountryCityListTemp = [];
            fetch(GET_BLOCK_COUNTRY_CITY_LIST(), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('idToken'),
                },
            }).then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson['data'] !== null) {
                        responseJson['data'].forEach(element2 => {
                            blockedCountryCityListTemp.push(element2);
                        });
                    }
                    localStorage.setItem("blockedCountryCity", JSON.stringify(blockedCountryCityListTemp));
                });
        }

        if (sessionStorage.getItem('showAdanced') !== null) {
            if (sessionStorage.getItem('showAdanced') === 'true') {
                setIsAdvanced(true);
            } else {
                setIsAdvanced(false);
            }
        }

        localStorage.removeItem("AddHotel");

        if (sessionStorage.getItem('serviceType') === null || sessionStorage.getItem('serviceType') === '') {
            if (checkSecurityDetails("Book", "Flight") && checkSecurityDetails("Book", "Flight", "Book_Flight_Search") && getConfigurationByBoolen("ALLOW_FLIGHT") === true) {
                sessionStorage.setItem("serviceType", "lblFlights");
            }
            else if (getConfigurationByBoolen('ALLOW_SINGLE_PNR') !== null && getConfigurationByBoolen("ALLOW_HOTEL") && getConfigurationByBoolen('ALLOW_SINGLE_PNR') && getConfigurationByBoolen("ALLOW_FLIGHT") && checkSecurityDetails("Book", "Flight", "Book_Flight_Search") && checkSecurityDetails("Book", "Hotel") && checkSecurityDetails("Book", "Hotel", "Book_Hotel_Search")) {
                sessionStorage.setItem("serviceType", "lblFlightPlusHotel");
            }
            else if (checkSecurityDetails("Book", "Hotel") && checkSecurityDetails("Book", "Hotel", "Book_Hotel_Search") && getConfigurationByBoolen("ALLOW_HOTEL") === true) {
                sessionStorage.setItem("serviceType", "lblHotel");
            }
            else if (checkSecurityDetails("Book", "Sightseeing") && getConfigurationByBoolen("ALLOW_SIGHTSEEING") === true) {
                sessionStorage.setItem("serviceType", "lblSightseeing");
            }
            else {
                sessionStorage.setItem("serviceType", "lblFlights");
            }
        }
    }, [])

    useEffect(() => {
        if (sessionStorage.getItem("hotelSessionDataJson") !== null) {
            let hotelJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
            if (Object.keys(hotelJson.HotelName).length !== 0) {
                setSearchString(hotelJson.HotelName)
            }
            if (Object.keys(hotelJson.HotelName).length == 0) {
                setSearchString('')
            }
        }
    }, [])

    //changes for Exclusive Deals to get only exclusive deals
    // useEffect(() => {
    //     if (ratePlanCode.length === 0 && userChangedExclDeals === false && ratePlan.length !== 0) {
    //         setExclusiveDealsDisable(false)

    //         //removed "XGZ,ZGK" in case of "TQV" selection
    //         let temp = [];
    //         ratePlan.forEach(element => {
    //             temp.push(element.value);
    //         });

    //         hotelSessionJson.ratePlanCode = temp;
    //         hotelSessionJson.ratePlanCodeData = ratePlan;
    //         sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
    //         setRatePlanCode(ratePlan);
    //     }
    // })

    function useOutsideAlerter(ref) {
        useEffect(() => {
            if (document.querySelector('[class="rmdp-input"]') !== null) {
                var controlCount = document.querySelectorAll('[class="rmdp-input"]');
                controlCount.forEach(element => {
                    element.style.display = "none";
                });
            }

            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    if (ref.current.innerText === "Destination") {
                        document.querySelector('#dvDestination_hotel').style.display = "none";
                        document.querySelector('#txtDestination_hotel').style.display = "";
                        document.querySelector('#spnDestinationAirportDetails_hotel').style.display = "";
                    }
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }


    const handleOnClickDestination = () => {
        document.querySelector("#txtDestination_hotel").style.display = "none";
        document.querySelector("#dvDestination_hotel").style.display = "";
        document.querySelector('#spnDestinationAirportDetails_hotel').style.display = "none";
        if (document.querySelector('[data-test="search-input"]') !== null) {
            var controlCount = document.querySelectorAll('[data-test="search-input"]');
            if (controlCount[2] !== undefined) {
                controlCount[2].focus();
            }
        }
    }

    const handleOnDestinationSearch = (input) => {
        if (input.length > 0) {
            if (localStorage.getItem("airportSuggestion") != null) {
                setItems(JSON.parse(localStorage.getItem("airportSuggestion")));
            }
        }
    }

    const formatResult = (item) => {
        return (
            <>
                <span>{item.cityName}, {item.countryName} <br /> {item.airportCode} - {item.airportName}</span>
            </>
        )
    }

    function allowOnlyNumbers(event) {
        var input = event.target.value;
        var pattern = /^[1-9]\d{0,2}$/;
        if (!pattern.test(input)) {
            event.target.value = input.slice(0, 3);
        }

    }

    const handleOnSelectDestination = (item) => {
        // User Story 3395: New search criteria - "Hotel Name"
        // setCityCode(item.airportCode);
        //hotelSessionJson.HotelName = {};
        let hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
        hotelSessionJson.txtDestination = item.cityName;
        hotelSessionJson.spnDestinationAirportDetails = item.airportCode + " - " + item.airportName + ", " + item.countryName + ", " + item.countryCode;

        sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));


        document.querySelector("#txtDestination_hotel").style.display = "";
        document.querySelector('#txtDestination_hotel').value = item.cityName;
        document.querySelector("#dvDestination_hotel").style.display = "none";
        document.querySelector('#spnDestinationAirportDetails_hotel').style.display = "";
        document.querySelector('#spnDestinationAirportDetails_hotel').innerHTML = item.airportCode + " - " + item.airportName + ", " + item.countryName + ", " + item.countryCode;
        // User Story 3395: New search criteria - "Hotel Name"
        // if (document.querySelector('.clear-icon') !== null) {
        //     var controlCount = document.querySelectorAll('.clear-icon');
        //     if (controlCount[1] !== undefined) {
        //         controlCount[1].click();
        //         // ✅ Remove focus from the currently active element
        //         document.activeElement.blur();

        //     }

        // }

    }
    // User Story 3395: New search criteria - "Hotel Name"
    //     useEffect(() => {
    //         if (sessionStorage.getItem('hotelSessionDataJson') !== null) {
    //             let hotelJson = JSON.parse(sessionStorage.getItem('hotelSessionDataJson'));
    //             if (Object.keys(hotelJson.spnDestinationAirportDetails).length !== 0) {
    //                 let cityCode = hotelJson.spnDestinationAirportDetails.split('-')[0];
    //                 if (cityCode.trim !== '') {
    //                     setCityCode(cityCode.trim())
    //                     sendHotelName({
    //                         "code": cityCode.trim(),
    //                     })
    //                 }
    //             }
    //         }



    //     }, [cityCode])
    //  // User Story 3395: New search criteria - "Hotel Name"
    //     useEffect(() => {
    //         let hotelName = []
    //         if (statusHotelName === 'completed') {
    //             for (let i in loadedHotelName) {
    //                 if (loadedHotelName['errors'].status === 'FALSE') {
    //                     if (i === 'data') {
    //                         for (let index = 0; index < loadedHotelName[i].length; index++) {
    //                             hotelName = [...hotelName, { HotelName: loadedHotelName[i][index].hotelName }]
    //                         }
    //                     }
    //                 }
    //             }
    //             setHotelNameList(hotelName)
    //         }
    //     }, [statusHotelName])

    function onSearchHandler() {
        //window.open('http://localhost:3000/FlightSearch?flight=partial');
        //window.location.pathname = 'FlightSearch?flight=partial';

        let blockedCountryCity = [];
        blockedCountryCity = localStorage.getItem("blockedCountryCity") !== null ? JSON.parse(localStorage.getItem("blockedCountryCity")) : '';

        window.scrollTo({
            top: 50,
            behavior: "smooth",
        });

        setFirstTime(true);
        setIsSubmitClicked(true);
        setPageFocus(true);

        let hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));

        let checkInDate = hotelSessionJson.calCheckInDateDB;
        let checkOutDate = hotelSessionJson.calCheckOutDateDB;

        if (parseInt(checkOutDate) < parseInt(checkInDate)) {
            setError({
                title: "Message",
                message: `${t('Check-out cannot be less than Check-in.')}`,
            });
            return;
        }

        if (blockedCountryCity.length !== 0) {
            let selectedDestinationCity = hotelSessionJson.spnDestinationAirportDetails.split('-')[0].trim();
            let selectedDestinationCountry = hotelSessionJson.spnDestinationAirportDetails.split(',')[2].trim();

            const found = blockedCountryCity.find(el => el.code === selectedDestinationCity || el.code === selectedDestinationCountry);
            if (found) {
                if (found.service === 'HT' || found.service === 'BOTH') {
                    setError({
                        title: "Message",
                        message: `${t('Sorry, search for this destination is not allowed. Please contact the Support desk.')}`,
                    });
                    return;
                }
            }
        }

        let roomGuests = [];
        for (let index = 1; index <= parseInt(roomsValue); index++) {
            roomGuests.push({
                "RoomIndex": parseInt(index),
                "NoOfAdults": parseInt(guestsValue),
                "NoOfChild": 0,
                "ChildAge": [
                    0
                ]
            });
        }
        let enteredRadius = parseInt(hotelSessionJson.Radius);
        if (enteredRadius === 0 || enteredRadius > 200) {
            setHotelNameError({
                title: "Message",
                message: "Please enter Radius (Miles) between 1 and 200."
            });
            return;

        }
        let tempSource = [];
        if (Object.keys(hotelSessionJson.Source).length > 0) {
            if (hotelSessionJson.Source === '000') {
                if (getConfigurationByBoolen('ALLOW_TAAP') && getConfigurationByBoolen('ALLOW_GTB')) {
                    tempSource.push('100', '113', '110');
                } else if (getConfigurationByBoolen('ALLOW_TAAP') && !getConfigurationByBoolen('ALLOW_GTB')) {
                    tempSource.push('100', '110');
                } else {
                    tempSource.push('100', '113');
                }
            }
            else {
                tempSource.push(hotelSessionJson.Source)
            }
        }

        let enteredHotelName = hotelSessionJson.HotelName;
        // Regular expression for alphanumeric characters, comma, space, /, and -
        const regex = /^[a-zA-Z0-9,\/ \-]*$/;
        if (enteredHotelName !== '' && Object.keys(enteredHotelName).length !== 0 && !regex.test(enteredHotelName)) {
            setHotelNameError({
                title: "Message",
                message: "Please enter valid Hotel Name."
            });
            return;
        }

        if (document.querySelector('#calCheckIn').value !== document.querySelector('#calCheckOut').value) {
            sessionStorage.removeItem("isHotelPartialBlockExecuted");
            localStorage.removeItem("AddHotel");
            sessionStorage.removeItem("AddHotelCheck");
            sessionStorage.setItem("travelreqhotelsearch", 'true');
            history.push('SearchHotel?hotel=partial&travelreq=true&src=hotel');
        }
        else {
            setError({
                title: "Message",
                message: `${t('Check-in and Check-out cannot be same.')}`,
            });
            return;
        }
    }

    const errorHandler = () => {
        setError(false);
        setHotelNameError(null);
        setPageFocus(false);

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    let hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
    //Code for find List PCC, Rate Plan or Rate Plan code PCC wise
    //Call PCC
    // useEffect(() => {
    //     const onSearchPCC = ({
    //         "id": "",
    //         "userID": "",
    //         "serviceID": "21",
    //         "officeID": JSON.parse(localStorage.getItem('officeID')),
    //         "status": 2
    //     });

    //     getConfigurationByBoolen("ALLOW_EXCLUSIVE_DEALS_PCC_WISE") && sendRequestOfficeWisePCC(onSearchPCC);
    // }, [sendRequestOfficeWisePCC])


    // let officeWisePCC = [];
    // for (let i in loadedOfficeWisePCC) {
    //     if (loadedOfficeWisePCC['errors'].status === 'FALSE') {
    //         if (i === 'data') {
    //             for (let index = 0; index < loadedOfficeWisePCC[i].length; index++) {
    //                 officeWisePCC = [...officeWisePCC, { value: loadedOfficeWisePCC[i][index].pcc, label: loadedOfficeWisePCC[i][index].pcc }]
    //             }
    //             hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
    //         }
    //     }
    // }

    // useEffect(() => {
    //     if (isFirtTime === "" && officeWisePCC.length > 0) {
    //         setOfficeWisePCCCode(officeWisePCC[0].value)
    //         searchRatePlanPCCWise(officeWisePCC[0].value);
    //         setFirstTime(true);
    //     }
    // }, [officeWisePCC, isFirtTime])


    // //Call RatePlan
    // useEffect(() => {
    //     const onSearchRatePlan = ({
    //         "default": true
    //     });

    //     !getConfigurationByBoolen("ALLOW_EXCLUSIVE_DEALS_PCC_WISE") && sendRequestGetRatePlan(onSearchRatePlan);
    // }, [statusRequestGetRatePlan])

    // let ratePlan = [];
    // for (let i in loadedRequestGetRatePlan) {
    //     if (loadedRequestGetRatePlan['errors'].status === 'FALSE') {
    //         if (i === 'data') {
    //             for (let index = 0; index < loadedRequestGetRatePlan[i].length; index++) {
    //                 ratePlan = [...ratePlan, { value: loadedRequestGetRatePlan[i][index].gtCode, label: loadedRequestGetRatePlan[i][index].gtName }]
    //             }
    //         }
    //     }
    // }

    // //Call RatePlan PCC Wise
    // const searchRatePlanPCCWise = (id) => {
    //     const onSearchRatePlanPCCWise = ({
    //         "officeID": JSON.parse(localStorage.getItem('officeID')),
    //         "pcc": id
    //     });

    //     sendRequestRequestGetRatePlanPCCWise(onSearchRatePlanPCCWise);
    // }


    // for (let i in loadedGetRatePlanPCCWise) {
    //     if (loadedGetRatePlanPCCWise['errors'].status === 'FALSE') {
    //         if (i === 'data') {
    //             for (let index = 0; index < loadedGetRatePlanPCCWise[i].length; index++) {
    //                 ratePlan = [...ratePlan, { value: loadedGetRatePlanPCCWise[i][index].gtCode, label: loadedGetRatePlanPCCWise[i][index].gtName }]
    //             }
    //         }
    //     }
    // }

    // useEffect(() => {
    //     if (exclusiveDealsChecked === true && loadedGetRatePlanPCCWise !== null && loadedGetRatePlanPCCWise['errors'] !== null && loadedGetRatePlanPCCWise['errors'].status === 'FALSE') {
    //         hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
    //         // hotelSessionJson.ratePlanCodeData = ratePlanCode;            
    //         hotelSessionJson.ratePlanCodeData = ratePlan;

    //         //removed "XGZ,ZGK" in case of "TQV" selection
    //         let temp = [];
    //         ratePlan.forEach(element => {
    //             temp.push(element.value);
    //         });

    //         hotelSessionJson.ratePlanCode = temp;
    //         sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
    //         setRatePlanCode(ratePlan);
    //     }
    // }, [loadedGetRatePlanPCCWise, statusGetRatePlanPCCWise])

    let bookingJSON = JSON.parse(localStorage.getItem("BookingData"));

    useEffect(() => {
        sessionStorage.setItem("myTripIsCanceled", '');
        sessionStorage.setItem("myTripIsHotelCancelled", '');
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });

        if (document.querySelector('#allHotels') !== null) {
            document.querySelector('#allHotels').checked = true;
            setBranDisabled(false);
        }

        if (document.querySelector("#lblFlights_hotel") !== null) {
            document.querySelector("#lblFlights_hotel").classList.remove("flight-active-tab");
            document.querySelector("#lblFlights_hotel").classList.add("text-primary");
        }

        if (document.querySelector("#lblHotel_hotel") !== null) {
            document.querySelector("#lblHotel_hotel").classList.remove("text-primary");
            document.querySelector("#lblHotel_hotel").classList.add("flight-active-tab");
        }

        if (document.querySelector("#lblFlightPlusHotel_hotel") !== null) {
            document.querySelector("#lblFlightPlusHotel_hotel").classList.remove("flight-active-tab");
            document.querySelector("#lblFlightPlusHotel_hotel").classList.add("text-primary");
        }
        else {
            if (document.querySelector("#lblFlights_hotel") !== null) {
                document.querySelector("#lblFlights_hotel").classList.remove("flight-active-tab");
                document.querySelector("#lblFlights_hotel").classList.add("text-primary");
            }

            if (document.querySelector("#lblHotel_hotel") !== null) {
                document.querySelector("#lblHotel_hotel").classList.remove("text-primary");
                document.querySelector("#lblHotel_hotel").classList.add("flight-active-tab");
            }
        }

        if (document.querySelector("#lblSightseeing_hotel") !== null) {
            document.querySelector("#lblSightseeing_hotel").classList.remove("flight-active-tab");
            document.querySelector("#lblSightseeing_hotel").classList.add("text-primary");
        }

        if (document.querySelector("#lblHolidays_hotel") !== null) {
            document.querySelector("#lblHolidays_hotel").classList.remove("flight-active-tab");
            document.querySelector("#lblHolidays_hotel").classList.add("text-primary");
        }

        if (valueParam.addhotel !== "1") {
            localStorage.removeItem("AddHotel");
        }

        //User Story 1070: Change in Search for Single PNR
        if (localStorage.getItem("AddHotel") !== null && localStorage.getItem("AddHotel") === "1") {

            if (document.querySelector('#lblFlights_hotel') !== null) {
                document.querySelector('#lblFlights_hotel').classList.add("d-none");
            }
            if (document.querySelector('#lblFlightPlusHotel_hotel') !== null) {
                document.querySelector('#lblFlightPlusHotel_hotel').classList.add("d-none");
            }
            if (document.querySelector('#lblSightseeing_hotel') !== null) {
                document.querySelector('#lblSightseeing_hotel').classList.add("d-none");
            }
            // // User Story 3395: New search criteria - "Hotel Name"
            // if (Object.keys(hotelSessionJson.spnDestinationAirportDetails).length !== 0) {
            //     let cityCode = hotelSessionJson.spnDestinationAirportDetails.split('-')[0];
            //     if (cityCode.trim !== '') {
            //         sendHotelName({
            //             "code": cityCode.trim(),
            //         })
            //     }

            // }
            setFirstTime(true);
            setIsSubmitClicked(true);
            setPageFocus(true);

            let guestCount = parseInt(bookingJSON.data.travelerInfo.traveler.filter(a => a.PassengerTypeCode === 'ADT' || a.PassengerTypeCode === 'CNN').length);
            let roomGuests = [];
            roomGuests.push({
                "RoomIndex": parseInt(1),
                "NoOfAdults": guestCount,
                "NoOfChild": 0,
                "ChildAge": [
                    0
                ]
            });

            let tempSource = [];
            tempSource.push(bookingJSON.data.airItinerary[0].SourceId === '1W' ? '100' : '113');
        }

    }, [statusOfficeWisePCC, loadedOfficeWisePCC])

    useEffect(() => {
        //User Story 1070: Change in Search for Single PNR
        if (localStorage.getItem("AddHotel") === null) {
            hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
            if (guestsValue === '' && (hotelSessionJson.guests === undefined || hotelSessionJson.guests.length === undefined)) {
                setGuestsValue('1');
                hotelSessionJson.guests = '1';
                sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
            }
            else {
                if (document.querySelector('#dvGuests') !== null && !document.querySelector('#dvGuests').classList.contains('d-none')) {
                    setGuestsValue(hotelSessionJson.guests);
                }
                else {
                    setGuestsValue('1');
                    hotelSessionJson.guests = '1';
                    sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
                }
            }
        }
        else {
            let guestCount = bookingJSON.data.travelerInfo.traveler.filter(a => a.PassengerTypeCode === 'ADT' || a.PassengerTypeCode === 'CNN').length.toString();
            setGuestsValue(guestsValue === '' ? guestCount.toString() : guestsValue.toString());
            hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
            hotelSessionJson.guests = guestsValue === '' ? guestCount.toString() : guestsValue.toString();
            sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
        }

        if (roomsValue === '') {
            setRoomsValue('1');
            hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
            hotelSessionJson.rooms = '1';
            sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
        }

        if (radius === undefined || radius === '') {
            hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"))
            if (Object.keys(hotelSessionJson.Radius).length > 0) {
                let r = hotelSessionJson.Radius;
                setRadius(r);
            } else {
                if (getConfigurationByValue('SABRE_HOTEL_RADIUS') !== null) {
                    setRadius(getConfigurationByValue('SABRE_HOTEL_RADIUS'))
                    hotelSessionJson.Radius = getConfigurationByValue('SABRE_HOTEL_RADIUS');
                    sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
                }
            }
        }

        if (hotelName === undefined || hotelName === '') {
            hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"))
            if (Object.keys(hotelSessionJson.HotelName).length > 0) {
                let hotelName = hotelSessionJson.HotelName;
                setHotelName(hotelName);
            }
        }

        if (ratingValue === '') {
            setRatingValue(defaultRating);
            hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
            hotelSessionJson.rating = defaultRating;
            sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
        }

        document.querySelector('#dvDestinationSpinner_hotel').style.display = "none";

        if (localStorage.getItem("airportSuggestion") != null) {
            setItems(JSON.parse(localStorage.getItem("airportSuggestion")));
        }

        document.querySelector("#dvDestination_hotel").style.display = "none";

        if (Object.keys(hotelSessionJson.brandsData).length > 0) {
            setChainCode(hotelSessionJson.brandsData);
        }
        else {
            hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
            hotelSessionJson.brandsData = chainCode;
            sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
        }

        if (Object.keys(hotelSessionJson.Source).length > 0) {
            setSourceValue(hotelSessionJson.Source);
        }
        else {
            if (getConfigurationByBoolen('ALLOW_GTB') === true) {
                hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
                setSourceValue(SourceOptions[2].value);
                hotelSessionJson.Source = SourceOptions[2].value;
                sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
            }
        }

        if (Object.keys(hotelSessionJson.preferenceOptions).length > 0 && hotelSessionJson.preferenceOptions.filter(a => a.preferenceOptionId === 'refundableOnly').length > 0) {
            if (document.querySelector('#refundableOnly') !== null) {
                document.querySelector('#refundableOnly').checked = true;
            }
        }
        else {
            if (document.querySelector('#refundableOnly') !== null) {
                document.querySelector('#refundableOnly').checked = false;
            }
        }

        if (Object.keys(hotelSessionJson.preferenceOptions).length > 0 && hotelSessionJson.preferenceOptions.filter(a => a.preferenceOptionId === 'allHotels').length > 0) {
            document.querySelector('#allHotels').checked = true;
            setBranDisabled(false);
        }
        else if (Object.keys(hotelSessionJson.preferenceOptions).length > 0 && hotelSessionJson.preferenceOptions.filter(a => a.preferenceOptionId === 'luxuryHotelsOnly').length > 0) {
            document.querySelector('#luxuryHotelsOnly').checked = true;
            setBranDisabled(true);
        }
        else {
            document.querySelector('#allHotels').checked = true;
            setBranDisabled(false);
        }

        const onSearchChainCode = ({
            hotelCode: "",
            hotelName: ""
        });

        sendRequestChainCode(onSearchChainCode);


        if (minToDateChange !== undefined && minToDateChange !== '' && document.querySelector('#calCheckOut') !== null) {
            document.querySelector('#calCheckOut').focus();
        }

    }, [statusChainCode, guestsValue, roomsValue, statusOfficeWisePCC, officeWisePCCCode,
        loadedRequestGetRatePlan, minToDateChange, ratingValue, defaultRating])

    useEffect(() => {
        if (sessionStorage.getItem("HotelCBT") === null) {
            if (localStorage.getItem("BookingDataTravelRequest") !== null && localStorage.getItem("BookingDataTravelRequest") !== '') {
                let bookingDataTravelRequestJson = JSON.parse(localStorage.getItem("BookingDataTravelRequest"));
                let originDestinationOptionLengthOutbound = bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption.filter(a => a.directionInd === 'O').length;
                let originDestinationOptionLengthInbound = bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption.filter(a => a.directionInd === 'I').length;
                let originDestinationOptionLengthMulticity = bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption.filter(a => a.directionInd === 'M').length;

                let selectedToCountry = originDestinationOptionLengthMulticity <= 0 ? bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption[originDestinationOptionLengthOutbound - 1].flightSegment[0].arrivalAirport.countryCode : bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption[originDestinationOptionLengthMulticity - 1].flightSegment[0].arrivalAirport.countryCode;
                let selectedToCity = originDestinationOptionLengthMulticity <= 0 ? bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption[originDestinationOptionLengthOutbound - 1].flightSegment[0].arrivalAirport.cityName : bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption[originDestinationOptionLengthMulticity - 1].flightSegment[0].arrivalAirport.cityName;
                let selectedToLocationCode = originDestinationOptionLengthMulticity <= 0 ? bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption[originDestinationOptionLengthOutbound - 1].flightSegment[0].arrivalAirport.locationCode : bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption[originDestinationOptionLengthMulticity - 1].flightSegment[0].arrivalAirport.locationCode;
                let selectedToLocationName = originDestinationOptionLengthMulticity <= 0 ? bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption[originDestinationOptionLengthOutbound - 1].flightSegment[0].arrivalAirport.locationName : bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption[originDestinationOptionLengthMulticity - 1].flightSegment[0].arrivalAirport.locationName;
                let selectedToCountryName = originDestinationOptionLengthMulticity <= 0 ? bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption[originDestinationOptionLengthOutbound - 1].flightSegment[0].arrivalAirport.countryName : bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption[originDestinationOptionLengthMulticity - 1].flightSegment[0].arrivalAirport.countryName;

                document.querySelector("#txtDestination_hotel").value = selectedToCity;
                hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
                hotelSessionJson.txtDestination = selectedToCity;
                sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));

                document.querySelector('#spnDestinationAirportDetails_hotel').innerHTML = selectedToLocationCode + " - " + selectedToLocationName + ", " + selectedToCountryName + ", " + selectedToCountry;
                hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
                hotelSessionJson.spnDestinationAirportDetails = selectedToLocationCode + " - " + selectedToLocationName + ", " + selectedToCountryName + ", " + selectedToCountry;
                sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));

                hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
                var departureDate2 = new Date(getFormatDate(bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption[0].flightSegment[0].departureDate));
                var departureDate3 = departureDate2.toLocaleDateString('en-us', { weekday: "short", year: "2-digit", month: "short", day: "numeric" });
                var departureDate4 = departureDate3.split(',')[2];
                hotelSessionJson.calCheckIn = Object.keys(hotelSessionJson.calCheckIn).length > 0 ? new Date(hotelSessionJson.calCheckIn.replace("'", "")) : departureDate3.substring(0, departureDate3.length - 2) + "'" + departureDate4.trim();
                document.querySelector('#calCheckIn').value = Object.keys(hotelSessionJson.calCheckIn).length > 0 ? new Date(hotelSessionJson.calCheckIn.replace("'", "")) : departureDate3.substring(0, departureDate3.length - 2) + "'" + departureDate4.trim();
                var checkInDateDB = departureDate2.toLocaleDateString('en-gb').split('/').reverse().join('');
                hotelSessionJson.calCheckInDateDB = checkInDateDB;
                sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));

                hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
                var departureDate2 = new Date(getFormatDate(originDestinationOptionLengthInbound <= 0 ? originDestinationOptionLengthMulticity <= 0 ? bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption[originDestinationOptionLengthOutbound - 1].flightSegment[0].arrivalDate : bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption[originDestinationOptionLengthMulticity - 1].flightSegment[0].departureDate : bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption.filter(a => a.directionInd === 'I')[0].flightSegment[0].departureDate));
                var departureDate3 = Object.keys(hotelSessionJson.calCheckOut).length > 0 ? new Date(hotelSessionJson.calCheckOut.replace("'", "")).toLocaleDateString('en-us', { weekday: "short", year: "2-digit", month: "short", day: "numeric" }) : departureDate2.toLocaleDateString('en-us', { weekday: "short", year: "2-digit", month: "short", day: "numeric" });
                var departureDate4 = departureDate3.split(',')[2];
                hotelSessionJson.calCheckOut = departureDate3.substring(0, departureDate3.length - 2) + "'" + departureDate4.trim();
                document.querySelector('#calCheckOut').value = departureDate3.substring(0, departureDate3.length - 2) + "'" + departureDate4.trim();
                var checkOutDateDB = departureDate2.toLocaleDateString('en-gb').split('/').reverse().join('');
                hotelSessionJson.calCheckOutDateDB = checkOutDateDB;
                sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
            }
            else {
                if (Object.keys(hotelSessionJson.txtDestination).length > 0) {
                    document.querySelector("#txtDestination_hotel").value = hotelSessionJson.txtDestination;
                }
                else {
                    var strSight = JSON.parse(localStorage.getItem("fromToListResponse"));
                    var strSightData = strSight.fromToListResponse.find(x => x.service == "HT");
                    document.querySelector("#txtDestination_hotel").value = strSightData.cityName;//"New York";
                    hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
                    hotelSessionJson.txtDestination = strSightData.cityName;//"New York";
                    sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
                }

                if (Object.keys(hotelSessionJson.spnDestinationAirportDetails).length > 0) {
                    document.querySelector('#spnDestinationAirportDetails_hotel').innerHTML = hotelSessionJson.spnDestinationAirportDetails;
                }
                else {
                    var strSight = JSON.parse(localStorage.getItem("fromToListResponse"));
                    var strSightData = strSight.fromToListResponse.find(x => x.service == "HT");
                    document.querySelector('#spnDestinationAirportDetails_hotel').innerHTML = strSightData.airportCode + " - " + strSightData.airportName + ", " + strSightData.countryName + ", " + strSightData.countryCode;// "NYC - All Airports, United States of America, US";
                    hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
                    hotelSessionJson.spnDestinationAirportDetails = strSightData.airportCode + " - " + strSightData.airportName + ", " + strSightData.countryName + ", " + strSightData.countryCode;//"NYC - All Airports, United States of America, US";
                    sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
                    // User Story 3395: New search criteria - "Hotel Name"
                    // sendHotelName({
                    //     "code": 'NYC',
                    // })
                }

                if (Object.keys(hotelSessionJson.calCheckIn).length > 0) {
                    document.querySelector('#calCheckIn').value = hotelSessionJson.calCheckIn;
                }
                else {
                    hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
                    hotelSessionJson.calCheckIn = moment(new Date(fromTodayDate)).format("ddd, MMM DD, 'YY");
                    document.querySelector('#calCheckIn').value = moment(new Date(fromTodayDate)).format("ddd, MMM DD, 'YY");
                    const checkInDate = new Date(fromTodayDate.toLocaleDateString('en-us', { weekday: "short", year: "numeric", month: "short", day: "numeric" }));
                    var checkInDateDB = checkInDate.toLocaleDateString('en-gb').split('/').reverse().join('');
                    hotelSessionJson.calCheckInDateDB = checkInDateDB;
                    sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
                }

                if (Object.keys(hotelSessionJson.calCheckOut).length > 0) {
                    document.querySelector('#calCheckOut').value = hotelSessionJson.calCheckOut;

                    if ((minToDateChange === undefined || minToDateChange == '') && Object.keys(hotelSessionJson.calCheckIn).length > 0) {
                        let minToDateChangeTemp = new Date(hotelSessionJson.calCheckIn.replace("'", ""));
                        minToDateChangeTemp.setDate(minToDateChangeTemp.getDate() + 1);
                        sessionStorage.setItem("InitialLoadCheckOutMinvalue", moment(new Date(minToDateChangeTemp)).format("ddd, MMM DD, 'YY"));
                    }
                }
                else {
                    hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
                    hotelSessionJson.calCheckOut = moment(new Date(toTodayDate)).format("ddd, MMM DD, 'YY");
                    document.querySelector('#calCheckOut').value = moment(new Date(toTodayDate)).format("ddd, MMM DD, 'YY");
                    const checkOutDate = new Date(toTodayDate.toLocaleDateString('en-us', { weekday: "short", year: "numeric", month: "short", day: "numeric" }));
                    var checkOutDateDB = checkOutDate.toLocaleDateString('en-gb').split('/').reverse().join('');
                    hotelSessionJson.calCheckOutDateDB = checkOutDateDB;
                    sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
                }
            }
        }
        else {
            if (localStorage.getItem("BookingDataTravelRequest") !== null && localStorage.getItem("BookingDataTravelRequest") !== '') {
                let bookingDataTravelRequestJson = JSON.parse(localStorage.getItem("BookingDataTravelRequest"));
                let originDestinationOptionLengthOutbound = bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption.filter(a => a.directionInd === 'O').length;
                let originDestinationOptionLengthInbound = bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption.filter(a => a.directionInd === 'I').length;
                let originDestinationOptionLengthMulticity = bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption.filter(a => a.directionInd === 'M').length;

                let selectedToCountry = originDestinationOptionLengthMulticity <= 0 ? bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption[originDestinationOptionLengthOutbound - 1].flightSegment[0].arrivalAirport.countryCode : bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption[originDestinationOptionLengthMulticity - 1].flightSegment[0].arrivalAirport.countryCode;
                let selectedToCity = originDestinationOptionLengthMulticity <= 0 ? bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption[originDestinationOptionLengthOutbound - 1].flightSegment[0].arrivalAirport.cityName : bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption[originDestinationOptionLengthMulticity - 1].flightSegment[0].arrivalAirport.cityName;
                let selectedToLocationCode = originDestinationOptionLengthMulticity <= 0 ? bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption[originDestinationOptionLengthOutbound - 1].flightSegment[0].arrivalAirport.locationCode : bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption[originDestinationOptionLengthMulticity - 1].flightSegment[0].arrivalAirport.locationCode;
                let selectedToLocationName = originDestinationOptionLengthMulticity <= 0 ? bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption[originDestinationOptionLengthOutbound - 1].flightSegment[0].arrivalAirport.locationName : bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption[originDestinationOptionLengthMulticity - 1].flightSegment[0].arrivalAirport.locationName;
                let selectedToCountryName = originDestinationOptionLengthMulticity <= 0 ? bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption[originDestinationOptionLengthOutbound - 1].flightSegment[0].arrivalAirport.countryName : bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption[originDestinationOptionLengthMulticity - 1].flightSegment[0].arrivalAirport.countryName;

                document.querySelector("#txtDestination_hotel").value = selectedToCity;
                hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
                hotelSessionJson.txtDestination = selectedToCity;
                sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));

                document.querySelector('#spnDestinationAirportDetails_hotel').innerHTML = selectedToLocationCode + " - " + selectedToLocationName + ", " + selectedToCountryName + ", " + selectedToCountry;
                hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
                hotelSessionJson.spnDestinationAirportDetails = selectedToLocationCode + " - " + selectedToLocationName + ", " + selectedToCountryName + ", " + selectedToCountry;
                sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));

                let hotelSessionJson2 = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
                var departureDate2 = new Date(getFormatDate(bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption[0].flightSegment[0].departureDate));
                var departureDate3 = departureDate2.toLocaleDateString('en-us', { weekday: "short", year: "2-digit", month: "short", day: "numeric" });
                var departureDate4 = departureDate3.split(',')[2];

                hotelSessionJson2.calCheckIn = Object.keys(hotelSessionJson2.calCheckIn).length > 0 ? moment(new Date(hotelSessionJson2.calCheckIn.replace("'", ""))).format("ddd, MMM DD, 'YY") : departureDate3.substring(0, departureDate3.length - 2) + "'" + departureDate4.trim();
                document.querySelector('#calCheckIn').value = Object.keys(hotelSessionJson2.calCheckIn).length > 0 ? moment(new Date(hotelSessionJson2.calCheckIn.replace("'", ""))).format("ddd, MMM DD, 'YY") : departureDate3.substring(0, departureDate3.length - 2) + "'" + departureDate4.trim();
                var checkInDateDB = departureDate2.toLocaleDateString('en-gb').split('/').reverse().join('');
                hotelSessionJson2.calCheckInDateDB = checkInDateDB;

                var arrivalDate2 = new Date(getFormatDate(originDestinationOptionLengthInbound <= 0 ? originDestinationOptionLengthMulticity <= 0 ? bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption[originDestinationOptionLengthOutbound - 1].flightSegment[0].arrivalDate : bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption[originDestinationOptionLengthMulticity - 1].flightSegment[0].departureDate : bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption.filter(a => a.directionInd === 'I')[0].flightSegment[0].departureDate));
                var arrivalDate3 = Object.keys(hotelSessionJson2.calCheckOut).length > 0 ? new Date(hotelSessionJson2.calCheckOut.replace("'", "")).toLocaleDateString('en-us', { weekday: "short", year: "2-digit", month: "short", day: "numeric" }) : arrivalDate2.toLocaleDateString('en-us', { weekday: "short", year: "2-digit", month: "short", day: "numeric" });
                var arrivalDate4 = arrivalDate3.split(',')[2];

                hotelSessionJson2.calCheckOut = Object.keys(hotelSessionJson2.calCheckOut).length > 0 ? moment(new Date(hotelSessionJson2.calCheckOut.replace("'", ""))).format("ddd, MMM DD, 'YY") : arrivalDate3.substring(0, arrivalDate3.length - 2) + "'" + arrivalDate4.trim();
                document.querySelector('#calCheckOut').value = Object.keys(hotelSessionJson2.calCheckOut).length > 0 ? moment(new Date(hotelSessionJson2.calCheckOut.replace("'", ""))).format("ddd, MMM DD, 'YY") : arrivalDate3.substring(0, arrivalDate3.length - 2) + "'" + arrivalDate4.trim();
                var checkOutDateDB = arrivalDate2.toLocaleDateString('en-gb').split('/').reverse().join('');
                hotelSessionJson2.calCheckOutDateDB = checkOutDateDB;

                sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson2));
            }
            else {
                if (document.querySelector("#txtDestination_hotel").value === '') {
                    document.querySelector("#txtDestination_hotel").value = sessionStorage.getItem("txtTo1");
                    hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
                    hotelSessionJson.txtDestination = sessionStorage.getItem("txtTo1");
                    sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
                }

                if (document.querySelector('#spnDestinationAirportDetails_hotel').innerHTML === '') {
                    document.querySelector('#spnDestinationAirportDetails_hotel').innerHTML = sessionStorage.getItem("spnToAirportDetails1");
                    hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
                    hotelSessionJson.spnDestinationAirportDetails = sessionStorage.getItem("spnToAirportDetails1");
                    sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
                }

                if (!checkInDateSelected) {
                    var todayDate = new Date(sessionStorage.getItem("calFrom1") !== null ? sessionStorage.getItem("calFrom1").replace("'", "") : new Date());
                    // if (sessionStorage.getItem("HotelCBT") !== null && sessionStorage.getItem("HotelCBT") === '2' && Object.keys(hotelSessionJson.calCheckIn).length > 0) {
                    //     todayDate = new Date(hotelSessionJson.calCheckIn.replace("'", ""));
                    // }            

                    hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
                    hotelSessionJson.calCheckIn = moment(new Date(todayDate)).format("ddd, MMM DD, 'YY");
                    document.querySelector('#calCheckIn').value = moment(new Date(todayDate)).format("ddd, MMM DD, 'YY");
                    const checkInDate = new Date(todayDate.toLocaleDateString('en-us', { weekday: "short", year: "numeric", month: "short", day: "numeric" }));
                    var checkInDateDB = checkInDate.toLocaleDateString('en-gb').split('/').reverse().join('');
                    hotelSessionJson.calCheckInDateDB = checkInDateDB;
                    sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
                }

                if (!checkOutDateSelected) {
                    var todayDate = '';
                    if (sessionStorage.getItem("tripType") === 'roundtrip') {
                        todayDate = new Date(sessionStorage.getItem("calTo1") !== null ? sessionStorage.getItem("calTo1").replace("'", "") : new Date());
                        // if (sessionStorage.getItem("HotelCBT") !== null && sessionStorage.getItem("HotelCBT") === '2' && Object.keys(hotelSessionJson.calCheckOut).length > 0) {
                        //     todayDate = new Date(hotelSessionJson.calCheckOut.replace("'", ""));
                        // }                
                    }
                    else {
                        let todayDateTemp = new Date(sessionStorage.getItem("calFrom1").replace("'", ""));
                        todayDateTemp.setDate(todayDateTemp.getDate() + 1);
                        todayDate = todayDateTemp;
                        // if (sessionStorage.getItem("HotelCBT") !== null && sessionStorage.getItem("HotelCBT") === '2' && Object.keys(hotelSessionJson.calCheckOut).length > 0) {
                        //     todayDate = new Date(hotelSessionJson.calCheckOut.replace("'", ""));
                        // }                
                    }

                    hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
                    hotelSessionJson.calCheckOut = moment(new Date(todayDate)).format("ddd, MMM DD, 'YY");
                    document.querySelector('#calCheckOut').value = moment(new Date(todayDate)).format("ddd, MMM DD, 'YY");
                    const checkOutDate = new Date(todayDate.toLocaleDateString('en-us', { weekday: "short", year: "numeric", month: "short", day: "numeric" }));
                    var checkOutDateDB = checkOutDate.toLocaleDateString('en-gb').split('/').reverse().join('');
                    hotelSessionJson.calCheckOutDateDB = checkOutDateDB;
                    sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
                }
            }
        }
    }, [localStorage.getItem("BookingDataTravelRequest"), sessionStorage.getItem("spnToAirportDetails1"),
    sessionStorage.getItem("calFrom1"), sessionStorage.getItem("calTo1"), sessionStorage.getItem("HotelCBT"),
        minToDateChange])

    useEffect(() => {
        const onSearchExclusiveDeals = ({
            officeID: JSON.parse(localStorage.getItem('officeID')),
            parentOfficeID: JSON.parse(localStorage.getItem('parentOfficeID'))
        });

        sendRequestExclusiveDeals(onSearchExclusiveDeals);
    }, [statusExclusiveDeals])


    const calGetDateCheckIn = (inputDate) => {
        setCheckInDateSelected(true);
        sessionStorage.setItem("HotelCBT", "2")
        if (sessionStorage.getItem("tripType") !== 'roundtrip') {
            let todayDateTemp = new Date(sessionStorage.getItem("calFrom1").replace("'", ""));
            todayDateTemp.setDate(todayDateTemp.getDate() + 1);
            sessionStorage.setItem("calTo1", moment(new Date(todayDateTemp)).format("ddd, MMM DD, 'YY"));
        }

        let sourceDate = inputDate.target.value.trim();
        let destinationDate = inputDate.target.value.trim();
        let hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));

        if (Object.keys(hotelSessionJson.calCheckOut).length > 0) {
            destinationDate = new Date(hotelSessionJson.calCheckOut.replace("'", ""))
        }
        else {
            let destinationDateTemp = new Date(inputDate.target.value.trim());
            destinationDateTemp.setDate(destinationDateTemp.getDate() + 1);
            destinationDate = destinationDateTemp;
        }

        hotelSessionJson.calCheckIn = moment(new Date(sourceDate)).format("ddd, MMM DD, 'YY");
        document.querySelector('#calCheckIn').value = moment(new Date(sourceDate)).format("ddd, MMM DD, 'YY");
        const checkInDate = new Date(sourceDate);
        var checkInDateDB = checkInDate.toLocaleDateString('en-gb').split('/').reverse().join('');
        hotelSessionJson.calCheckInDateDB = checkInDateDB;
        sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));

        hotelSessionJson.calCheckOut = moment(new Date(destinationDate)).format("ddd, MMM DD, 'YY");
        document.querySelector('#calCheckOut').value = moment(new Date(destinationDate)).format("ddd, MMM DD, 'YY");
        const checkOutDate = new Date(destinationDate);
        var checkOutDateDB = checkOutDate.toLocaleDateString('en-gb').split('/').reverse().join('');
        hotelSessionJson.calCheckOutDateDB = checkOutDateDB;
        sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));

        let minToDateChangeTemp = new Date(hotelSessionJson.calCheckIn.replace("'", ""));
        minToDateChangeTemp.setDate(minToDateChangeTemp.getDate() + 1);
        setMinToDateChange(new Date(minToDateChangeTemp));
    }

    const calGetDateCheckOut = (inputDate) => {
        sessionStorage.setItem("HotelCBT", "2")

        let sourceDate = inputDate.target.value.trim();
        let destinationDate = inputDate.target.value.trim();

        let hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));

        // hotelSessionJson.calCheckIn = moment(new Date(sourceDate)).format("ddd, MMM DD, 'YY");
        // document.querySelector('#calCheckIn').value = moment(new Date(sourceDate)).format("ddd, MMM DD, 'YY");
        // const checkInDate = new Date(sourceDate);
        // var checkInDateDB = checkInDate.toLocaleDateString('en-gb').split('/').reverse().join('');
        // hotelSessionJson.calCheckInDateDB = checkInDateDB;
        // sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));

        hotelSessionJson.calCheckOut = moment(new Date(destinationDate)).format("ddd, MMM DD, 'YY");
        document.querySelector('#calCheckOut').value = moment(new Date(destinationDate)).format("ddd, MMM DD, 'YY");
        const checkOutDate = new Date(destinationDate);
        var checkOutDateDB = checkOutDate.toLocaleDateString('en-gb').split('/').reverse().join('');
        hotelSessionJson.calCheckOutDateDB = checkOutDateDB;
        sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
    }

    let guestsOptions = [
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5' },
        { value: '6', label: '6' },
        { value: '7', label: '7' },
        { value: '8', label: '8' },
        { value: '9', label: '9' }
    ];

    let roomsOptions = [
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' }
    ];

    const guestsChangeHandler = (event) => {
        let hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
        hotelSessionJson.guests = event.value;
        sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
        setGuestsValue(event.value);
    };

    const roomsChangeHandler = (event) => {
        let hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
        hotelSessionJson.rooms = event.value;
        sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
        setRoomsValue(event.value);
    };
    const radiusChangeHandler = (event) => {
        let hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
        hotelSessionJson.Radius = event.target.value.trim();
        sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
        setRadius(event.target.value);
    }
    const hotelNameChangeHandler = (event) => {
        const newInputValue = event.target.value.trim();
        let hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
        hotelSessionJson.HotelName = newInputValue
        sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
        setHotelName(event.target.value)
    }

    let chainCodeOptions = [];
    let chainCodeArrayData = [];
    for (let i in loadedChainCode) {
        if (loadedChainCode['errors'].status === 'FALSE') {
            if (i === 'data') {
                chainCodeArrayData = loadedChainCode[i];
            }
        }
    }

    for (let index = 0; index < chainCodeArrayData.length; index++) {
        chainCodeOptions = [...chainCodeOptions, { value: chainCodeArrayData[index].hotelCode, label: chainCodeArrayData[index].hotelName + " (" + chainCodeArrayData[index].hotelCode + ")" }]
    }

    let providerCodeOptions = [];

    for (let i in loadedExclusiveDeals) {
        if (loadedExclusiveDeals['errors'].status === 'FALSE') {
            if (i === 'data') {
                for (let index = 0; index < loadedExclusiveDeals[i].length; index++) {
                    providerCodeOptions = [...providerCodeOptions, { value: loadedExclusiveDeals[i][index].value, label: loadedExclusiveDeals[i][index].name }]
                }
            }
        }
    }

    /* let providerCodeOptions = [
        { value: 'API', label: 'Virtuoso' },
        { value: 'OTG', label: 'Ovation' },
        { value: 'TQV', label: 'Amex GBT' }
    ]; */

    const brandsChangeHandler = (event) => {
        if (event.length > 0) {
            if (document.querySelector('#luxuryHotelsOnly') !== null) {
                document.querySelector('#luxuryHotelsOnly').checked = false;
                document.querySelector('#luxuryHotelsOnly').disabled = true;
            }
        }
        else {
            if (document.querySelector('#luxuryHotelsOnly') !== null) {
                document.querySelector('#luxuryHotelsOnly').disabled = false;
            }
        }

        let hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
        let brandsTemp = [];
        event.forEach(element => {
            brandsTemp.push(element.value);
        });
        hotelSessionJson.brands = brandsTemp;
        hotelSessionJson.brandsData = event;
        sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
        setChainCode(event);
    };

    let preferenceOptionsTemp = [];

    const onAllHotelsChangeHandler = (event) => {
        if (event.target.checked) {
            setBranDisabled(false);
        }

        var inputs = document.querySelectorAll('.preference-options');
        preferenceOptionsTemp = [];
        for (var i = 0; i < inputs.length; i++) {
            if (inputs[i].checked) {
                preferenceOptionsTemp.push({
                    preferenceOptionId: inputs[i].getAttribute("id"),
                    preferenceOptionValue: true
                });
            }
        }

        let hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
        hotelSessionJson.preferenceOptions = [];
        hotelSessionJson.preferenceOptions = preferenceOptionsTemp;
        sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
    }

    const onLuxuryHotelsOnlyChangeHandler = (event) => {
        if (event.target.checked) {
            setBranDisabled(true);
        }

        var inputs = document.querySelectorAll('.preference-options');
        preferenceOptionsTemp = [];
        for (var i = 0; i < inputs.length; i++) {
            if (inputs[i].checked) {
                preferenceOptionsTemp.push({
                    preferenceOptionId: inputs[i].getAttribute("id"),
                    preferenceOptionValue: true
                });
            }
        }

        let hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
        hotelSessionJson.preferenceOptions = [];
        hotelSessionJson.preferenceOptions = preferenceOptionsTemp;
        sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
    }

    const onRefundableOnlyChangeHandler = (event) => {
        var inputs = document.querySelectorAll('.preference-options');
        preferenceOptionsTemp = [];
        for (var i = 0; i < inputs.length; i++) {
            if (inputs[i].checked) {
                preferenceOptionsTemp.push({
                    preferenceOptionId: inputs[i].getAttribute("id"),
                    preferenceOptionValue: true
                });
            }
        }

        let hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
        hotelSessionJson.preferenceOptions = [];
        hotelSessionJson.preferenceOptions = preferenceOptionsTemp;
        sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
    }

    window.addEventListener('click', function (e) {
        if (document.querySelector('#dvPassengers1') !== null) {
            if (document.querySelector('#dvPassengers1').contains(e.target)) {
                if (e.target.id === "ancHidePassengerDropDown") {
                    document.querySelector('#btnPassengerDropDown1').className = "dropdown-toggle final-count";
                    document.querySelector('#btnPassengerDropDown1').setAttribute("aria-expanded", "false");
                    document.querySelector('#dvPassengers1').className = "dropdown-menu dropdown_passenger_info";
                }
                else {
                    document.querySelector('#btnPassengerDropDown1').className = "dropdown-toggle final-count show";
                    document.querySelector('#btnPassengerDropDown1').setAttribute("aria-expanded", "true");
                    document.querySelector('#dvPassengers1').className = "dropdown-menu dropdown_passenger_info show";
                    document.querySelector('#dvPassengers1').style = "position: absolute; inset: 0px auto auto 0px; margin: 0px;";
                }
            } else {
                if (e.target.parentNode.id === "btnPassengerDropDown1" || e.target.id === "btnPassengerDropDown1") {
                    document.querySelector('#btnPassengerDropDown1').className = "dropdown-toggle final-count show";
                    document.querySelector('#btnPassengerDropDown1').setAttribute("aria-expanded", "true");
                    document.querySelector('#dvPassengers1').className = "dropdown-menu dropdown_passenger_info show";
                    document.querySelector('#dvPassengers1').style = "position: absolute; inset: 0px auto auto 0px; margin: 0px;";
                }
                else {
                    document.querySelector('#btnPassengerDropDown1').className = "dropdown-toggle final-count";
                    document.querySelector('#btnPassengerDropDown1').setAttribute("aria-expanded", "false");
                    document.querySelector('#dvPassengers1').className = "dropdown-menu dropdown_passenger_info";
                }
            }
        }
    });


    const controlHandlerFightSearch = (event) => {
        if (event.target.id === 'lblFlights_hotel') {
            sessionStorage.setItem("serviceType", "lblFlights");
            setLoadComponent('Flight');

            if (document.querySelector("#divAddHotel") !== null) {
                document.querySelector("#divAddHotel").style.display = "none";
            }

            if (document.querySelector("#lblFlights_flight") !== null) {
                document.querySelector("#lblFlights_flight").classList.add("flight-active-tab");
                document.querySelector("#lblFlights_flight").classList.remove("text-primary");
                document.querySelector('#divTripType').classList.remove("d-none");
            }

            if (document.querySelector("#lblHotel_hotel") !== null) {
                document.querySelector("#lblHotel_hotel").classList.add("text-primary");
                document.querySelector("#lblHotel_hotel").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblFlightPlusHotel_flight") !== null) {
                document.querySelector("#lblFlightPlusHotel_flight").classList.add("text-primary");
                document.querySelector("#lblFlightPlusHotel_flight").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblSightseeing_flight") !== null) {
                document.querySelector("#lblSightseeing_flight").classList.add("text-primary");
                document.querySelector("#lblSightseeing_flight").classList.remove("flight-active-tab");
            }

            document.querySelector('#oneway-tab').classList.remove("disabled");
            document.querySelector('#multi_city-tab').classList.remove("disabled");

            if (document.querySelector('#addHotel') !== null) {
                document.querySelector('#addHotel').checked = false;
            }
            localStorage.removeItem("AddHotel");
            sessionStorage.removeItem("AddHotelCheck");

            if (sessionStorage.getItem("tripType") !== null) {
                if (sessionStorage.getItem("tripType") === 'oneway') {
                    document.querySelector('#oneway-tab').click();
                }
                else if (sessionStorage.getItem("tripType") === 'roundtrip') {
                    document.querySelector('#roundtrip-tab').click();
                }
                else if (sessionStorage.getItem("tripType") === 'multi_city') {
                    document.querySelector('#multi_city-tab').click();
                }
                else {
                    document.querySelector('#roundtrip-tab').click();
                }
            }

            if (document.querySelector('.show_flight') !== null) {
                document.querySelector('.show_flight').classList.remove('d-none');
            }
            if (document.querySelector('.show_hotel') !== null) {
                document.querySelector('.show_hotel').classList.add('d-none');
            }
            if (document.querySelector('.show_sightseeing') !== null) {
                document.querySelector('.show_sightseeing').classList.add('d-none');
            }
            if (document.querySelector('.show_holidays') !== null) {
                document.querySelector('.show_holidays').classList.add('d-none');
            }
        }
        else if (event.target.id === 'lblHotel_hotel') {
            sessionStorage.setItem("serviceType", "lblHotel");
            localStorage.removeItem("AddHotel");
            setLoadComponent('Hotel');

            if (document.querySelector("#lblFlights_hotel") !== null) {
                document.querySelector("#lblFlights_hotel").classList.add("text-primary");
                document.querySelector("#lblFlights_hotel").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblHotel_hotel") !== null) {
                document.querySelector("#lblHotel_hotel").classList.add("flight-active-tab");
                document.querySelector("#lblHotel_hotel").classList.remove("text-primary");
            }

            if (document.querySelector("#lblFlightPlusHotel_hotel") !== null) {
                document.querySelector("#lblFlightPlusHotel_hotel").classList.add("text-primary");
                document.querySelector("#lblFlightPlusHotel_hotel").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblSightseeing_hotel") !== null) {
                document.querySelector("#lblSightseeing_hotel").classList.add("text-primary");
                document.querySelector("#lblSightseeing_hotel").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblHolidays_hotel") !== null) {
                document.querySelector("#lblHolidays_hotel").classList.add("text-primary");
                document.querySelector("#lblHolidays_hotel").classList.remove("flight-active-tab");
            }

            if (document.querySelector('.show_flight') !== null) {
                document.querySelector('.show_flight').classList.add('d-none');
            }
            if (document.querySelector('.show_hotel') !== null) {
                document.querySelector('.show_hotel').classList.remove('d-none');
            }
            if (document.querySelector('.show_sightseeing') !== null) {
                document.querySelector('.show_sightseeing').classList.add('d-none');
            }
            if (document.querySelector('.show_holidays') !== null) {
                document.querySelector('.show_holidays').classList.add('d-none');
            }
        }
        else if (event.target.id === 'lblFlightPlusHotel_hotel') {
            sessionStorage.setItem("serviceType", "lblFlightPlusHotel");
            setLoadComponent('Flight');

            if (document.querySelector("#divAddHotel") !== null) {
                document.querySelector("#divAddHotel").style.display = "";
            }

            if (document.querySelector("#lblFlights_flight") !== null) {
                document.querySelector("#lblFlights_flight").classList.add("text-primary");
                document.querySelector("#lblFlights_flight").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblHotel_hotel") !== null) {
                document.querySelector("#lblHotel_hotel").classList.add("text-primary");
                document.querySelector("#lblHotel_hotel").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblFlightPlusHotel_flight") !== null) {
                document.querySelector("#lblFlightPlusHotel_flight").classList.add("flight-active-tab");
                document.querySelector("#lblFlightPlusHotel_flight").classList.remove("text-primary");
            }

            if (document.querySelector("#lblSightseeing_flight") !== null) {
                document.querySelector("#lblSightseeing_flight").classList.remove("flight-active-tab");
                document.querySelector("#lblSightseeing_flight").classList.add("text-primary");
            }

            if (document.querySelector("#lblHolidays_flight") !== null) {
                document.querySelector("#lblHolidays_flight").classList.remove("flight-active-tab");
                document.querySelector("#lblHolidays_flight").classList.add("text-primary");
            }

            document.querySelector('#divTripType').classList.add("d-none");
            document.querySelector('#roundtrip-tab').click();

            if (document.querySelector('#addHotel') !== null) {
                document.querySelector('#addHotel').checked = true;
            }
            localStorage.setItem("AddHotel", "1");
            sessionStorage.setItem("AddHotelCheck", 1);

            if (document.querySelector('.show_flight') !== null) {
                document.querySelector('.show_flight').classList.remove('d-none');
            }
            if (document.querySelector('.show_hotel') !== null) {
                document.querySelector('.show_hotel').classList.add('d-none');
            }
            if (document.querySelector('.show_sightseeing') !== null) {
                document.querySelector('.show_sightseeing').classList.add('d-none');
            }
            if (document.querySelector('.show_holidays') !== null) {
                document.querySelector('.show_holidays').classList.add('d-none');
            }
        }
        else if (event.target.id === 'lblSightseeing_hotel') {
            sessionStorage.setItem("serviceType", "lblSightseeing");
            setLoadComponent('Sightseeing');

            if (document.querySelector("#lblFlights_hotel") !== null) {
                document.querySelector("#lblFlights_hotel").classList.add("text-primary");
                document.querySelector("#lblFlights_hotel").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblHotel_hotel") !== null) {
                document.querySelector("#lblHotel_hotel").classList.remove("flight-active-tab");
                document.querySelector("#lblHotel_hotel").classList.add("text-primary");
            }

            if (document.querySelector("#lblFlightPlusHotel_hotel") !== null) {
                document.querySelector("#lblFlightPlusHotel_hotel").classList.add("text-primary");
                document.querySelector("#lblFlightPlusHotel_hotel").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblSightseeing_sightseeing") !== null) {
                document.querySelector("#lblSightseeing_sightseeing").classList.remove("text-primary");
                document.querySelector("#lblSightseeing_sightseeing").classList.add("flight-active-tab");
            }

            if (document.querySelector("#lblSightseeing_hotel") !== null) {
                document.querySelector("#lblSightseeing_hotel").classList.add("text-primary");
                document.querySelector("#lblSightseeing_hotel").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblHolidays_hotel") !== null) {
                document.querySelector("#lblHolidays_hotel").classList.add("text-primary");
                document.querySelector("#lblHolidays_hotel").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblFlights_sightseeing") !== null) {
                document.querySelector("#lblFlights_sightseeing").classList.add("text-primary");
                document.querySelector("#lblFlights_sightseeing").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblHotel_sightseeing") !== null) {
                document.querySelector("#lblHotel_sightseeing").classList.add("text-primary");
                document.querySelector("#lblHotel_sightseeing").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblFlightPlusHotel_sightseeing") !== null) {
                document.querySelector("#lblFlightPlusHotel_sightseeing").classList.add("text-primary");
                document.querySelector("#lblFlightPlusHotel_sightseeing").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblHolidays_sightseeing") !== null) {
                document.querySelector("#lblHolidays_sightseeing").classList.add("text-primary");
                document.querySelector("#lblHolidays_sightseeing").classList.remove("flight-active-tab");
            }

            if (document.querySelector('.show_flight') !== null) {
                document.querySelector('.show_flight').classList.add('d-none');
            }
            if (document.querySelector('.show_hotel') !== null) {
                document.querySelector('.show_hotel').classList.add('d-none');
            }
            if (document.querySelector('.show_sightseeing') !== null) {
                document.querySelector('.show_sightseeing').classList.remove('d-none');
            }
            if (document.querySelector('.show_holidays') !== null) {
                document.querySelector('.show_holidays').classList.add('d-none');
            }
        }
        else if (event.target.id === 'lblHolidays_hotel') {
            // sessionStorage.setItem("serviceType", "lblHolidays");
            // setLoadComponent('Holidays');

            // if (document.querySelector("#lblFlights_hotel") !== null) {
            //     document.querySelector("#lblFlights_hotel").classList.add("text-primary");
            //     document.querySelector("#lblFlights_hotel").classList.remove("flight-active-tab");
            // }

            // if (document.querySelector("#lblHotel_hotel") !== null) {
            //     document.querySelector("#lblHotel_hotel").classList.remove("flight-active-tab");
            //     document.querySelector("#lblHotel_hotel").classList.add("text-primary");
            // }

            // if (document.querySelector("#lblFlightPlusHotel_hotel") !== null) {
            //     document.querySelector("#lblFlightPlusHotel_hotel").classList.add("text-primary");
            //     document.querySelector("#lblFlightPlusHotel_hotel").classList.remove("flight-active-tab");
            // }

            // if (document.querySelector("#lblSightseeing_sightseeing") !== null) {
            //     document.querySelector("#lblSightseeing_sightseeing").classList.add("text-primary");
            //     document.querySelector("#lblSightseeing_sightseeing").classList.remove("flight-active-tab");
            // }

            // if (document.querySelector("#lblSightseeing_hotel") !== null) {
            //     document.querySelector("#lblSightseeing_hotel").classList.add("text-primary");
            //     document.querySelector("#lblSightseeing_hotel").classList.remove("flight-active-tab");
            // }            

            // if (document.querySelector("#lblFlights_sightseeing") !== null) {
            //     document.querySelector("#lblFlights_sightseeing").classList.add("text-primary");
            //     document.querySelector("#lblFlights_sightseeing").classList.remove("flight-active-tab");
            // }

            // if (document.querySelector("#lblHotel_sightseeing") !== null) {
            //     document.querySelector("#lblHotel_sightseeing").classList.add("text-primary");
            //     document.querySelector("#lblHotel_sightseeing").classList.remove("flight-active-tab");
            // }

            // if (document.querySelector("#lblFlightPlusHotel_sightseeing") !== null) {
            //     document.querySelector("#lblFlightPlusHotel_sightseeing").classList.add("text-primary");
            //     document.querySelector("#lblFlightPlusHotel_sightseeing").classList.remove("flight-active-tab");
            // }

            // if (document.querySelector("#lblHolidays_hotel") !== null) {
            //     document.querySelector("#lblHolidays_hotel").classList.remove("text-primary");
            //     document.querySelector("#lblHolidays_hotel").classList.add("flight-active-tab");
            // }

            // if (document.querySelector("#lblHolidays_sightseeing") !== null) {
            //     document.querySelector("#lblHolidays_sightseeing").classList.remove("text-primary");
            //     document.querySelector("#lblHolidays_sightseeing").classList.add("flight-active-tab");
            // }

            // if (document.querySelector('.show_flight') !== null) {
            //     document.querySelector('.show_flight').classList.add('d-none');
            // }
            // if (document.querySelector('.show_hotel') !== null) {
            //     document.querySelector('.show_hotel').classList.add('d-none');
            // }
            // if (document.querySelector('.show_sightseeing') !== null) {
            //     document.querySelector('.show_sightseeing').classList.add('d-none');
            // }
            // if (document.querySelector('.show_holidays') !== null) {
            //     document.querySelector('.show_holidays').classList.remove('d-none');
            // }

            window.open(getConfigurationByValue('HOLIDAYS_URL'))
        }
    }
    const advanceChangeHandler = () => {
        if (sessionStorage.getItem("hotelSessionDataJson") !== null) {
            let hotelJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
            if (Object.keys(hotelJson.HotelName).length !== 0) {
                setSearchString(hotelJson.HotelName)
            }
            if (Object.keys(hotelJson.HotelName).length == 0) {
                setSearchString('')
            }
        }
        let show;
        if (isadvanced) {
            setIsAdvanced(false)
            show = "false"
        } else {
            setIsAdvanced(true)
            show = "true"
        }
        // User Story 3395: New search criteria - "Hotel Name"
        //setHotelNameDisable(false);

        sessionStorage.setItem("showAdanced", show);
    }
    // User Story 3395: New search criteria - "Hotel Name"
    // function onHotelNameSelection(event) {
    //     setSelectedHotel(event.HotelName)
    //     let hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
    //     hotelSessionJson.HotelName = event.HotelName;
    //     sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));


    // }

    // function handleOnHotelNameSearch(input) {
    //     setHotelNameDisable(true);
    //     setSelectedHotel(input);
    //     let hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
    //     hotelSessionJson.HotelName = input
    //     sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));

    // }
    // function onHotelNameClear(event) {
    //     setSearchString('');
    //     let hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
    //     hotelSessionJson.HotelName = ''
    //     sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
    // }

    if (sessionStorage.getItem('serviceType') === 'lblFlights') {
        if (document.querySelector('.show_flight') !== null) {
            document.querySelector('.show_flight').classList.remove('d-none');
        }
        if (document.querySelector('.show_hotel') !== null) {
            document.querySelector('.show_hotel').classList.add('d-none');
        }
        if (document.querySelector('.show_sightseeing') !== null) {
            document.querySelector('.show_sightseeing').classList.add('d-none');
        }
        if (document.querySelector('.show_holidays') !== null) {
            document.querySelector('.show_holidays').classList.add('d-none');
        }
    }
    else if (sessionStorage.getItem('serviceType') === 'lblFlightPlusHotel') {
        if (document.querySelector('.show_flight') !== null) {
            document.querySelector('.show_flight').classList.remove('d-none');
        }
        if (document.querySelector('.show_hotel') !== null) {
            document.querySelector('.show_hotel').classList.add('d-none');
        }
        if (document.querySelector('.show_sightseeing') !== null) {
            document.querySelector('.show_sightseeing').classList.add('d-none');
        }
        if (document.querySelector('.show_holidays') !== null) {
            document.querySelector('.show_holidays').classList.add('d-none');
        }
    }
    else if (sessionStorage.getItem('serviceType') === 'lblHotel') {
        if (document.querySelector('.show_flight') !== null) {
            document.querySelector('.show_flight').classList.add('d-none');
        }
        if (document.querySelector('.show_hotel') !== null) {
            document.querySelector('.show_hotel').classList.remove('d-none');
        }
        if (document.querySelector('.show_sightseeing') !== null) {
            document.querySelector('.show_sightseeing').classList.add('d-none');
        }
        if (document.querySelector('.show_holidays') !== null) {
            document.querySelector('.show_holidays').classList.add('d-none');
        }
    }
    else if (sessionStorage.getItem('serviceType') === 'lblSightseeing') {
        if (document.querySelector('.show_flight') !== null) {
            document.querySelector('.show_flight').classList.add('d-none');
        }
        if (document.querySelector('.show_hotel') !== null) {
            document.querySelector('.show_hotel').classList.add('d-none');
        }
        if (document.querySelector('.show_sightseeing') !== null) {
            document.querySelector('.show_sightseeing').classList.remove('d-none');
        }
        if (document.querySelector('.show_holidays') !== null) {
            document.querySelector('.show_holidays').classList.add('d-none');
        }
    }
    else if (sessionStorage.getItem('serviceType') === 'lblHolidays') {
        if (document.querySelector('.show_flight') !== null) {
            document.querySelector('.show_flight').classList.add('d-none');
        }
        if (document.querySelector('.show_hotel') !== null) {
            document.querySelector('.show_hotel').classList.add('d-none');
        }
        if (document.querySelector('.show_sightseeing') !== null) {
            document.querySelector('.show_sightseeing').classList.add('d-none');
        }
        if (document.querySelector('.show_holidays') !== null) {
            document.querySelector('.show_holidays').classList.remove('d-none');
        }
    }

    if (document.querySelector("#lblHotel_hotel") !== null) {
        document.querySelector("#lblHotel_hotel").classList.remove("text-primary");
        document.querySelector("#lblHotel_hotel").classList.add("flight-active-tab");
    }

    const ratingChangeHandler = (event) => {
        hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
        hotelSessionJson.rating = event.value;
        sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
        setRatingValue(event.value);
    }

    if (document.querySelector('#dvGuests') !== null) {
        let tripReasonList = [];
        let tripReasonAndPolicyDataJson = "";
        let travelRequestFieldsDataJson = "";

        if (localStorage.getItem("loginData") !== null && sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
            tripReasonAndPolicyDataJson = JSON.parse(localStorage.getItem("loginData"));
            travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));

            if (tripReasonAndPolicyDataJson.tripReason.length > 0) {
                tripReasonList = tripReasonAndPolicyDataJson.tripReason.filter(a => a.id === travelRequestFieldsDataJson.tripReason);
            }
        }

        if ((localStorage.getItem('isCorporate') !== null && localStorage.getItem('isCorporate') === 'true')) {
            if (tripReasonList.length > 0 && tripReasonList[0] !== undefined && tripReasonList[0].isFamilyBooking !== undefined && tripReasonList[0].isFamilyBooking === false) {
                if (document.querySelector('#dvGuests') !== null && document.querySelector('#dvDateRange') !== null) {
                    document.querySelector('#dvGuests').classList.add('d-none');
                    document.querySelector('#dvDateRange').classList.remove('col-lg-8');
                    document.querySelector('#dvDateRange').classList.add('col-lg-12');

                    let hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
                    //setGuestsValue('1');
                    hotelSessionJson.guests = '1';
                    sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
                }
            }
            else {
                if (document.querySelector('#dvGuests') !== null && document.querySelector('#dvDateRange') !== null) {
                    document.querySelector('#dvGuests').classList.remove('d-none');
                    document.querySelector('#dvDateRange').classList.remove('col-lg-12');
                    document.querySelector('#dvDateRange').classList.add('col-lg-8');

                    let hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
                    hotelSessionJson.guests = guestsValue !== undefined && guestsValue !== '' ? guestsValue.toString() : "1";
                    sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
                }
            }
        }
        else {
            if (document.querySelector('#dvGuests') !== null && document.querySelector('#dvDateRange') !== null) {
                document.querySelector('#dvGuests').classList.remove('d-none');
                document.querySelector('#dvDateRange').classList.remove('col-lg-12');
                document.querySelector('#dvDateRange').classList.add('col-lg-8');

                let hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
                hotelSessionJson.guests = guestsValue !== undefined && guestsValue !== '' ? guestsValue.toString() : "1";
                sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
            }
        }
    }

    return <Fragment>
        {
            error1 && <ErrorModal
                title={error1.title}
                message={t(error1.message)}
                onConfirm={errorHandler}
            ></ErrorModal>
        }
        <div className="row position-relative">
            <div className="col-lg-12 tour_search_form">
                <form action="!#">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="flight_Search_boxed py-2">
                                <div className='pb-1 h5 mb-0' id='dvFrom5'>
                                    <div ref={refDestination} className="position-relative" >
                                        <p onClick={handleOnClickDestination}>{t('Destination')} </p>
                                        <div className=''>
                                            <input id={'txtDestination_hotel'} type="text" onClick={handleOnClickDestination} />
                                            <div id='spnDestinationAirportDetails_hotel' className='h6 text-muted w-100 mt-2 text-overflow-manage'></div>
                                        </div>
                                        <div id={'dvDestinationSpinner_hotel'} className="input-spineer-from"><i className="fa fa-spinner fa-spin"></i></div>
                                        <div id={'dvDestination_hotel'} >
                                            <ReactSearchAutocomplete
                                                onSearch={handleOnDestinationSearch}
                                                items={items}
                                                formatResult={formatResult}
                                                autoFocus
                                                onSelect={handleOnSelectDestination}
                                                showNoResults={false}
                                                maxResults={15}
                                                fuseOptions={{ keys: ["cityCode", "airportCode", "airportName", "cityName"] }}
                                                resultStringKeyName="cityName"

                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-12 col-sm-12 col-12'>
                            <div className='row'>
                                <div id='dvDateRange' className="col-lg-12 col-md-6 col-sm-12 col-12">
                                    <div className="form_search_date">
                                        <div className="flight_Search_boxed date_flex_area padding-2y">
                                            <div className="Journey_date">
                                                <p>Check-in</p>
                                                <DateRangePicker key={sessionStorage.getItem("calFrom1")}
                                                    onHide={calGetDateCheckIn}
                                                    initialSettings={{
                                                        startDate: sessionStorage.getItem("HotelCBT") !== null && sessionStorage.getItem("calFrom1") !== null && sessionStorage.getItem("calFrom1") !== '' ? new Date(sessionStorage.getItem("calFrom1").replace("'", "")) : Object.keys(hotelSessionJson.calCheckIn).length > 0 ? new Date(hotelSessionJson.calCheckIn.replace("'", "")) : sessionStorage.getItem("calFrom1") !== null && sessionStorage.getItem("calFrom1") !== '' ? new Date(sessionStorage.getItem("calFrom1").replace("'", "")) : fromTodayDate, endDate: '', singleDatePicker: true,
                                                        showDropdowns: true, autoApply: true, minDate: new Date()
                                                    }} >
                                                    <input id={'calCheckIn'} type="text" autoComplete='off' />
                                                </DateRangePicker>
                                            </div>
                                            <div className="Journey_date">
                                                <p>Check-out</p>
                                                <DateRangePicker key={minToDateChange}
                                                    onHide={calGetDateCheckOut}
                                                    initialSettings={{
                                                        startDate: sessionStorage.getItem("HotelCBT") !== null && sessionStorage.getItem("calTo1") !== null && sessionStorage.getItem("calTo1") !== '' ? new Date(sessionStorage.getItem("calTo1").replace("'", "")) : Object.keys(hotelSessionJson.calCheckOut).length > 0 ? new Date(hotelSessionJson.calCheckOut.replace("'", "")) : sessionStorage.getItem("calFrom1") !== null && sessionStorage.getItem("calFrom1") !== '' ? new Date(sessionStorage.getItem("calFrom1").replace("'", "")) : toTodayDate, endDate: '', singleDatePicker: true,
                                                        showDropdowns: true, autoApply: true, minDate: minToDateChange !== undefined && minToDateChange !== '' ? new Date(minToDateChange) : Object.keys(hotelSessionJson.calCheckOut).length > 0 ? new Date(hotelSessionJson.calCheckOut.replace("'", "")) : new Date(toTodayDate)
                                                    }} >
                                                    <input id={'calCheckOut'} type="text" autoComplete='off' />
                                                </DateRangePicker>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4  col-md-6 col-sm-12 col-12">
                                    <div id='dvGuests' className="flight_Search_boxed dropdown_passenger_area py-1">
                                        <div className='row'>
                                            <div className='col-md-12  dropdown-search-text'>
                                                <p>Guests</p>
                                                <div className="dropdown pb-2 col-md-11">
                                                    <Dropdown onChange={guestsChangeHandler}
                                                        value={guestsOptions.filter(option => option.value === guestsValue)}
                                                        options={guestsOptions}
                                                        textField="value"
                                                        dataItemKey="label"
                                                        arrowClosed={<span className="arrow-closed" />}
                                                        arrowOpen={<span className="arrow-open" />} controlClassName='mt-1 form-control control dropdown-search form-select bg_input mb-0'
                                                    />
                                                </div>
                                                <span className='per-text text-black d-none'><p className='text-black'>Per</p></span>
                                            </div>
                                            <div className='col-md-6  dropdown-search-text d-none'>
                                                <p>Rooms</p>
                                                <div className="dropdown pb-2 col-md-9">
                                                    <Dropdown onChange={roomsChangeHandler}
                                                        value={roomsOptions.filter(option => option.value === roomsValue)}
                                                        options={roomsOptions}
                                                        textField="value"
                                                        dataItemKey="label"
                                                        arrowClosed={<span className="arrow-closed" />}
                                                        arrowOpen={<span className="arrow-open" />} controlClassName='mt-1 form-control control dropdown-search form-select bg_input mb-0'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={localStorage.getItem('isCorporate') !== null && localStorage.getItem('isCorporate') === 'false' ? "row" : "row d-none"}>
                                <div className='d-lg-flex d-md-flex  search-flight-checkbox my-2'>

                                    <span className='d-flex  exclusive-hotel-search'>
                                        <span className="text-16px text-black me-2">
                                            <div className='form-check'>
                                                <input className="form-check-input preference-options" type="radio" name="hotelType" id="allHotels"
                                                    onClick={(e) => onAllHotelsChangeHandler(e)}></input>
                                                <label className="form-check-label mb-0" for="allHotels">
                                                    {t('All Hotels')}
                                                </label>
                                            </div>
                                        </span>
                                        <span className="text-16px text-black">
                                            <div className='form-check'>
                                                <input className="form-check-input preference-options" type="radio" name="hotelType" id="luxuryHotelsOnly"
                                                    onClick={(e) => onLuxuryHotelsOnlyChangeHandler(e)}></input>
                                                <label className="form-check-label mb-0" for="luxuryHotelsOnly">
                                                    {t('Luxury Hotels Only')}
                                                </label>
                                            </div>
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        {
                            localStorage.getItem('isCorporate') !== null && localStorage.getItem('isCorporate') === 'false' &&
                            <div className='col-lg-12'>
                                <div className="row">
                                    <div className="control col-md-3 mb-0 mt-2">
                                        <div className="form-switch form-switch-sm padding-left-0">
                                            <label for="advanced" className="mb-2 h6 text-primary me-2">Advanced</label>
                                            <input id="advance" className="form-check-input ms-1" type="checkbox" checked={isadvanced} onChange={advanceChangeHandler} />
                                        </div>
                                    </div>
                                </div>
                                {isadvanced && <div className='row'>
                                    <div className='d-lg-flex d-md-flex my-2'>
                                        {
                                            (getConfigurationByBoolen("ALLOW_REFUNDABLE_ONLY") === null || getConfigurationByBoolen("ALLOW_REFUNDABLE_ONLY") === false) &&
                                            <span className="ms-3 text-16px text-black">
                                                <div className='form-check'>
                                                    <input className="form-check-input preference-options" type="checkbox" id="refundableOnly"
                                                        onChange={(e) => onRefundableOnlyChangeHandler(e)}></input>
                                                    <label className="form-check-label mb-0 fw-bold text-primary" for="refundableOnly">
                                                        {t('Refundable Only')}
                                                    </label>
                                                </div>
                                            </span>
                                        }
                                    </div>
                                    <div className={getConfigurationByBoolen('ALLOW_GTB') === true ? 'col-lg-5 col-md-6 col-sm-12 dropdown-search-text dropdown-mt-5px margin-top-15 mt-2' : 'col-lg-5 col-md-6 col-sm-12 dropdown-search-text dropdown-mt-5px margin-top-15 mt-2'}>
                                        <div className=''>
                                            <label className="text-16px fw-500 text-primary col-lg-12">Brands</label>
                                            <Dropdown onChange={brandsChangeHandler}
                                                value={chainCode}
                                                options={chainCodeOptions}
                                                textField="label"
                                                dataItemKey="value"
                                                arrowClosed={<span className="arrow-closed" />}
                                                arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                                isMulti={true}
                                                isDisabled={branDisabled}
                                            />
                                        </div>
                                    </div>
                                    <div className={'col-lg-2 col-md-4 col-sm-12 margin-top-15 mt-2'}>
                                        <label className="text-16px fw-500 text-primary col-lg-12">Radius (Miles)</label>
                                        <input id="radius"
                                            ref={radiusInputRef}
                                            value={radius}
                                            onChange={radiusChangeHandler}
                                            onInput={allowOnlyNumbers}
                                            type="number"
                                            min={'1'}
                                            max={'200'}
                                            minLength={'1'}
                                            maxLength="3"

                                            className="form-control form-control-sm search-input-hotel"
                                        />
                                    </div>
                                    <div className={getConfigurationByBoolen('ALLOW_GTB') === true ? 'col-lg-5 col-md-4 col-sm-12 dropdown-search-text dropdown-mt-5px margin-top-15 mt-2 hotelNamedropdownHeight' : ''}>
                                        <label className="text-16px fw-500 text-primary ">Hotel Name</label>
                                        <input id="hotelNameInput" className="form-control form-control-sm search-input-hotel" type='text' onChange={hotelNameChangeHandler} value={hotelName} maxLength={100}></input>
                                    </div>
                                </div>}
                            </div>
                        }
                        {
                            localStorage.getItem('isCorporate') !== null && localStorage.getItem('isCorporate') === 'true' &&
                            <div className={getConfigurationByBoolen('ALLOW_GTB') === true || getConfigurationByBoolen('ALLOW_TAAP') ? 'col-lg-5 col-md-6 col-sm-12 dropdown-search-text dropdown-mt-5px margin-top-15 mt-2' : 'col-lg-5 col-md-6 col-sm-12 dropdown-search-text dropdown-mt-5px margin-top-15 mt-2'}>
                                <div className=''>
                                    <label className="text-16px fw-500 text-primary col-lg-12">Rating</label>
                                    <Dropdown onChange={ratingChangeHandler}
                                        value={ratingList.filter(option => option.value === ratingValue)}
                                        options={ratingList}
                                        textField="value"
                                        dataItemKey="label"
                                        arrowClosed={<span className="arrow-closed" />}
                                        arrowOpen={<span className="arrow-open" />} controlClassName='mt-1 form-control control dropdown-search form-select bg_input mb-0'
                                    />
                                </div>
                            </div>
                        }

                        <div className="top_form_search_button my-3">
                            <button className="btn btn_theme btn_md mt-2" type="button" onClick={onSearchHandler}>Search</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </Fragment>

};

export default SearchHotel;