import { getFormatDate } from "../../../utils/CommonFunction";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { RETRIEVESHOPPINGCART, RETRIEVEGDSTRIP } from "../../../navigation/CONSTANTS";
import { getRetrieveTrip, getFlightPlusHotelRetrieveTrip, getGdsRetrieveTrip, getRetrieveTravelRequest } from '../../../services/Booking-API'
import { getRetrieveHotel } from '../../../services/Hotel-API';
import { Fragment, useState, useEffect, useMemo } from 'react';
import useHttp from '../../../services/use-http';
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import MdModal from '../../Flights/MdModal';
import { getConfigurationByBoolen } from '../../../utils/ConfigurationsFunction';
import { checkSecurityDetails } from '../../../utils/RoleUtils';
import PopupMedium from "../../../components/Common/Flight/PopupMedium";
import RetrieveSabreGDSPNR from "./RetrieveSabreGDSPNR";
import { getCrypticCommandWithCloseConnection } from "../../../services/CrypticCommand-API";
import { getApprovalHistory } from '../../../services/Approver-API';
import ApprovalHistory from './SubComponents/ApprovalHistory';
import ApproverDetails from './SubComponents/ApproverDetails';

const MyTripsItem = (props) => {
    const history = useHistory();
    const { sendRequest, data: loadedRetrieveTripDetails, error, status } = useHttp(getRetrieveTrip);
    const { sendRequest: sendRequestRetrieveHotel, status: statusRetrieveHotel, data: loadedRetrieveHotel } = useHttp(getRetrieveHotel);
    const { sendRequest: sendRequestRetrieveFlightPlusHotel, status: statusRetrieveFlightPlusHotel, data: loadedRetrieveFlightPlusHotel } = useHttp(getFlightPlusHotelRetrieveTrip);
    const { sendRequest: gdsRetrieveTrip, status: gdsRetrieveTripStatus, data: loadedGdsRetrieveTripDetails } = useHttp(getGdsRetrieveTrip);
    const { sendRequest: sendSabrePNR, data: loadedOutput, status: sabrePnrStatus, } = useHttp(getCrypticCommandWithCloseConnection);
    const { sendRequest: sendTravelRequest, data: loadedTravelRequestDetails, status: travelRequestStatus } = useHttp(getRetrieveTravelRequest);
    const [sabrePnrPopup, setSabrePnrPopup] = useState();

    const [error1, setError] = useState();
    const isCorporate = JSON.parse(localStorage.getItem("isCorporate"));

    const { sendRequest: sendGetApprovalHistory, data: approvalHistoryResponse, status: approvalHistoryRequestStatus } = useHttp(getApprovalHistory);
    const [approvalHistoryModal, setApprovalHistoryModal] = useState(null);

    const [approveRejectModal, setApproveRejectModal] = useState(null);

    const userID = JSON.parse(localStorage.getItem('userID'));

    const moduleName = useMemo(() => {
        let module = 'My Trips';
        if (props.isMyQueue) {
            module = 'My Queue';
        }
        return module;
    }, [props.isMyQueue]);

    const moduleNameWithoutSpace = useMemo(() => {
        return moduleName.replace(/ /g, "");
    }, [moduleName]);

    function openRetrieveBooking(id, serviceCode) {
        if (serviceCode === "FL")
            sendRequest(id);
        else if (serviceCode === "FLHT") {
            sendRequestRetrieveFlightPlusHotel(id)
        } else {
            sendRequestRetrieveHotel(id);
        }
        //window.location.href = '/RetrieveTrip'
    }
    function openTravelRequest(id) {
        sendTravelRequest(id);
        //window.location.href = '/RetrieveTravelRequest'
    }

    function openSabreRetrieveBooking(pnrNumber) {
        localStorage.setItem('traceID', JSON.stringify(uuidv4()));
        gdsRetrieveTrip(
            {
                officeID: JSON.parse(localStorage.getItem('officeID')),
                data: {
                    TraceId: JSON.parse(localStorage.getItem('traceID')),
                    UserID: userID,
                    bookingReferenceID: {
                        type: "PNR",
                        id: pnrNumber
                    }
                }
            }
        );
    }
    function openSabreGDSPNR(pnr) {
        const inputJson = ({

            officeID: JSON.parse(localStorage.getItem('officeID')),
            data: {
                UserID: userID,
                TraceId: JSON.parse(localStorage.getItem('traceID')),
                requestCommand: [
                    {
                        text: '*' + pnr,
                    }
                ],
                echoToken: (sessionStorage.getItem("SabreSessionToken") !== undefined && sessionStorage.getItem("SabreSessionToken") !== "") ? sessionStorage.getItem("SabreSessionToken") : null
            }
        });

        sendSabrePNR(inputJson);
    }
    function handleViewApprovalHistoryClick() {
        const request = {
            officeID: props.data.officeID,
            approvalMasterID: props.data.approvalMasterID,
            shoppingID: props.data.id
        };

        sendGetApprovalHistory(request);
    }

    function handleApproveRejectClick() {
        setApproveRejectModal(() => {
            return {
                title: "Status Update",
                message: <ApproverDetails
                    shoppingID={props.data.id}
                    approvalTypeID={props.data.approvalTypeID}
                    approvalMasterID={props.data.approvalMasterID}
                    onApprovalStatusUpdate={onApprovalStatusUpdate}
                    errorHandler={() => errorHandler(false)}>
                </ApproverDetails>
            }
        });
    }

    const errorHandler = (reloadPage) => {
        setError(null);
        setSabrePnrPopup(null);
        setApprovalHistoryModal(null);
        setApproveRejectModal(null);
        if (reloadPage) {
            window.location.reload();
        }
    };

    const onApprovalStatusUpdate = (isSuccessful) => {
        setApproveRejectModal(null);
        if (isSuccessful) {
            setError({
                title: "Message",
                message: "Status updated successfully.",
                reloadPage: true
            });
        }
        else {
            setError({
                title: "Message",
                message: "There was a problem updating status.",
                reloadPage: false
            });
        }
    };

    function approvalStatusHtml() {
        let statusHtml = "";
        let statusCode = props.data.approvalStatusCode;

        //uncomment below codes if status is to be shown as per the current logged-in user
        //if (statusCode !== 'AP') {
        //    if (props.data.historyApprovalStatusCode &&
        //        props.data.historyApprovalStatusCode === 'AP' &&
        //        props.data.historyApprovalUserID === userID) {
        //        statusCode = 'AP';
        //    }
        //}

        switch (statusCode) {
            case "PD":
                statusHtml = (
                    <span className="badge h6 rounded-pill mb-0 bg-warning">
                        <i class="fa-solid fa-pause"></i> Pending
                    </span>
                );
                break;
            case "AP":
                statusHtml = (
                    <span className="badge h6 rounded-pill mb-0 bg-success">
                        <i class="fa-solid fa-circle-check"></i> Approved
                    </span>
                );
                break;
            case "RJ":
                statusHtml = (
                    <span className="badge h6 rounded-pill mb-0 bg-danger">
                        <i class="fa-solid fa-circle-xmark"></i> Rejected
                    </span>
                );
                break;
            default:
                statusHtml = props.data.approvalStatusCode;
                break;
        }
        return statusHtml;
    };

    function approvalActionByLabel() {
        let actionByLabel = "";
        switch (props.data.approvalStatusCode) {
            case "AP":
                actionByLabel = "Approved by";
                break;
            case "RJ":
                actionByLabel = "Rejected by";
                break;
            default:
                actionByLabel = "Waiting at";
                break;
        }
        return actionByLabel;
    }

    function getTripPaxName() {
        let paxName = '';
        if (tripHasService('FL')) {
            paxName = props.product.flightDetails.paxTitle +
                ' ' + props.product.flightDetails.paxFname +
                ' ' + props.product.flightDetails.paxMname +
                ' ' + props.product.flightDetails.paxLname;
        }
        else if (tripHasService('HT')) {
            paxName = props.product.hotelDetails.paxTitle +
                ' ' + props.product.hotelDetails.paxFname +
                ' ' + props.product.hotelDetails.paxMname +
                ' ' + props.product.hotelDetails.paxLname;
        }
        else {
            paxName = props.product.serviceRequest.paxTitle +
                ' ' + props.product.serviceRequest.paxFirstName +
                ' ' + props.product.serviceRequest.paxMiddleName +
                ' ' + props.product.serviceRequest.paxLastName;
        }

        return paxName;
    }

    //services: comma separated service codes
    function tripHasService(serviceCodes) {
        let serviceFound = false;
        const services = serviceCodes.split(',');
        services.forEach((service) => {
            if (props.serviceCode.indexOf(service) !== -1) {
                switch (service) {
                    case 'FL':
                        if (props.product.flightDetails) {
                            serviceFound = true;
                        }
                        break;
                    case 'HT':
                        if (props.product.hotelDetails) {
                            serviceFound = true;
                        }
                        break;
                    default:
                        if (props.product.serviceRequest) {
                            serviceFound = true;
                        }
                        break;
                }
            }
        });

        return serviceFound;
    }

    function getPaxCountSuffix() {
        let suffix = '';
        if (tripHasService('FL')) {
            if (props.product.flightDetails.paxCount && props.product.flightDetails.paxCount - 1 > 0) {
                suffix = `+${props.product.flightDetails.paxCount - 1}`;
            }
        }
        else {
            if (props.product.hotelDetails.paxCount && props.product.hotelDetails.paxCount - 1 > 0) {
                suffix = `+${props.product.hotelDetails.paxCount - 1}`;
            }
        }

        return suffix;
    }

    function showApproveRejectLink() {
        let showLink = false;
        if (isCorporate &&
            props.isMyQueue &&
            props.data.approvalStatusCode &&
            checkSecurityDetails("My Queue", "MyQueueApproveRejectRequest", "My Queue_MyQueueApproveRejectRequest_Allow Approve/Reject Request")) {

            // Show Conditions
            //  1. If approvalStatusCode doesn't equal 'AP', and ... (2, 3 or 4)
            //  2. If historyApprovalStatusCode is null/empty, show link (first approval stage)
            //  3. If approvalStatusCode is 'PD', if historyApprovalStatusCode is not null/empty and value is 'PD', show link.
            //  4. If approvalStatusCode is 'PD', if historyApprovalStatusCode is not null/empty and value is 'AP', compare
            //    logged-in userID and historyApprovalUserID. If they're not equal, show link.

            if (props.data.approvalStatusCode !== 'AP') {
                if (props.data.approvalStatusCode === 'PD') {

                    if (props.data.historyApprovalStatusCode && props.data.historyApprovalStatusCode.length) {
                        if (props.data.historyApprovalStatusCode === 'PD') {
                            showLink = true;
                        }
                        else if (props.data.historyApprovalStatusCode === 'AP' &&
                            props.data.historyApprovalUserID !== userID) {
                            showLink = true;
                        }
                    }
                    else {
                        //first approval stage
                        showLink = true;
                    }

                }
            }
        }

        return showLink;
    }

    useEffect(() => {
        if (status === 'completed') {
            //let bookingJSON = [];

            if (loadedRetrieveTripDetails.data.errors.status === 'FALSE') {
                //bookingJSON = loadedRetrieveTripDetails;
                localStorage.setItem("BookingData", JSON.stringify(loadedRetrieveTripDetails));
                sessionStorage.setItem("myTripIsCanceled", props.product.flightDetails?.isCanceled !== undefined ? props.product.flightDetails?.isCanceled : false);
                sessionStorage.setItem('isHotelFailed', 'true');
                sessionStorage.removeItem("sourcePNR");
                history.push({
                    pathname: '/RetrieveTrip',
                    mytrip: { isCanceled: props.product.flightDetails?.isCanceled !== undefined ? props.product.flightDetails?.isCanceled : false }
                });
            }



        }
    }, [status, loadedRetrieveTripDetails])

    useEffect(() => {
        if (statusRetrieveHotel === 'completed') {
            let bookingJSON = [];

            if (loadedRetrieveHotel.data.errors.status === 'FALSE') {
                bookingJSON = loadedRetrieveHotel;
                sessionStorage.setItem("HotelBookingData", JSON.stringify(loadedRetrieveHotel));
                sessionStorage.setItem("myTripIsHotelCancelled", props.product.hotelDetails.isCanceled !== undefined ? props.product.hotelDetails.isCanceled : false);
                sessionStorage.removeItem("sourcePNR");
                history.push({
                    pathname: '/RetrieveHotel',
                    hotelTrip: { isCanceled: props.product.hotelDetails.isCanceled !== undefined ? props.product.hotelDetails.isCanceled : false }
                });
            }

        }
    }, [statusRetrieveHotel, loadedRetrieveHotel])

    useEffect(() => {
        if (statusRetrieveFlightPlusHotel === 'completed') {
            //let bookingJSON = [];
            if (props.serviceCode === 'FLHT' && props.product.hotelDetails !== undefined && (props.product.hotelDetails.status !== 'Failed' && props.product.flightDetails.status !== 'Failed')) {
                if (loadedRetrieveFlightPlusHotel.data.errors.status === 'FALSE') {
                    //bookingJSON = loadedRetrieveFlightPlusHotel;
                    sessionStorage.setItem("BookingDataShoppingCart", JSON.stringify(loadedRetrieveFlightPlusHotel));
                    sessionStorage.setItem("myTripIsCanceled", props.product.flightDetails.isCanceled !== undefined ? props.product.flightDetails.isCanceled : false);
                    sessionStorage.setItem("myTripIsHotelCancelled", props.product.hotelDetails.isCanceled !== undefined ? props.product.hotelDetails.isCanceled : false);
                    sessionStorage.setItem('isHotelFailed', 'false');
                    sessionStorage.removeItem("sourcePNR");
                    history.push({
                        pathname: RETRIEVESHOPPINGCART,
                        mytrip: { isCanceled: props.product.flightDetails.isCanceled !== undefined ? props.product.flightDetails.isCanceled : false },
                        hotelTrip: { isCanceled: props.product.hotelDetails.isCanceled !== undefined ? props.product.hotelDetails.isCanceled : false }
                    });
                }

            } else if (props.serviceCode === 'FLHT' && props.product.hotelDetails !== undefined && props.product.hotelDetails.status === 'Failed' && props.product.flightDetails.status !== 'Failed') {
                if (loadedRetrieveFlightPlusHotel.data.errors.status === 'FALSE') {
                    //bookingJSON = loadedRetrieveFlightPlusHotel;
                    localStorage.setItem("BookingData", JSON.stringify(loadedRetrieveFlightPlusHotel));
                    sessionStorage.setItem("myTripIsCanceled", props.product.flightDetails.isCanceled !== undefined ? props.product.flightDetails.isCanceled : false);
                    sessionStorage.setItem('isHotelFailed', 'true');
                    sessionStorage.removeItem("sourcePNR");
                    history.push({
                        pathname: '/RetrieveTrip',
                        mytrip: { isCanceled: props.product.flightDetails.isCanceled !== undefined ? props.product.flightDetails.isCanceled : false }
                    });
                }

            } else {
                if (loadedRetrieveFlightPlusHotel.data.errors.status === 'FALSE') {
                    //bookingJSON = loadedRetrieveFlightPlusHotel;
                    sessionStorage.setItem("BookingDataShoppingCart", JSON.stringify(loadedRetrieveFlightPlusHotel));
                    sessionStorage.setItem("myTripIsCanceled", props.product.flightDetails.isCanceled !== undefined ? props.product.flightDetails.isCanceled : false);
                    sessionStorage.setItem("myTripIsHotelCancelled", props.product.hotelDetails.isCanceled !== undefined ? props.product.hotelDetails.isCanceled : false);
                    sessionStorage.setItem('isHotelFailed', 'true');
                    sessionStorage.removeItem("sourcePNR");
                    history.push({
                        pathname: RETRIEVESHOPPINGCART,
                        mytrip: { isCanceled: props.product.flightDetails.isCanceled !== undefined ? props.product.flightDetails.isCanceled : false },
                        hotelTrip: { isCanceled: props.product.hotelDetails.isCanceled !== undefined ? props.product.hotelDetails.isCanceled : false }
                    });
                }
            }
        }
    }, [statusRetrieveFlightPlusHotel, loadedRetrieveFlightPlusHotel])

    useEffect(() => {
        if (gdsRetrieveTripStatus === 'completed') {
            if (loadedGdsRetrieveTripDetails !== null) {
                if (loadedGdsRetrieveTripDetails.data.errors.status === 'FALSE') {
                    loadedGdsRetrieveTripDetails.data.TraceId = JSON.parse(localStorage.getItem('traceID'));
                    loadedGdsRetrieveTripDetails.data.UserID = JSON.parse(localStorage.getItem('userID'));

                    localStorage.setItem("BookingData", JSON.stringify(loadedGdsRetrieveTripDetails));
                    history.push(RETRIEVEGDSTRIP);
                    history.go();

                    window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                    });
                }
                else {
                    setError({
                        title: "Message",
                        message: loadedGdsRetrieveTripDetails.data.errors.error.description,
                    });
                    return;
                }
            }
            else {
                setError({
                    title: "Message",
                    message: "Something went wrong.",
                });
                return;
            }
        }
    }, [gdsRetrieveTripStatus, loadedGdsRetrieveTripDetails])

    useEffect(() => {
        if (sabrePnrStatus === 'completed') {
            if (loadedOutput !== null) {
                if (loadedOutput.data.errors.status === 'FALSE') {
                    setSabrePnrPopup({
                        title: "Sabre GDS PNR",
                        message: <RetrieveSabreGDSPNR output={loadedOutput.data.commandResponse[0].text}></RetrieveSabreGDSPNR>

                    });

                }
                else {
                    setError({
                        title: "Message",
                        message: loadedOutput.data.errors.error.description,
                    });
                    return;
                }
            } else {
                setError({
                    title: "Message",
                    message: "Something went wrong.",
                });
            }


        }

    }, [sabrePnrStatus])

    useEffect(() => {
        if (travelRequestStatus === 'completed') {
            console.log(loadedTravelRequestDetails)
            if (loadedTravelRequestDetails.data.errors.status === 'FALSE') {
                console.log(loadedTravelRequestDetails)
                sessionStorage.setItem("travelRequestData", JSON.stringify(loadedTravelRequestDetails));
                history.push({
                    pathname: '/RetrieveTravelRequest',
                });
            }



        }
    }, [travelRequestStatus, loadedTravelRequestDetails])

    useEffect(() => {
        if (approvalHistoryResponse) {
            if (approvalHistoryResponse.errors.status === 'TRUE') {
                setError(() => {
                    return {
                        title: "Message",
                        message: "Something went wrong.",
                    }
                });
            }
            else {
                setApprovalHistoryModal(() => {
                    return {
                        title: "Approval History",
                        message: <ApprovalHistory
                            approvalTypeName={approvalHistoryResponse.approvalTypeName}
                            data={approvalHistoryResponse.data}>
                        </ApprovalHistory>
                    }
                });
            }
        }
    }, [approvalHistoryResponse])

    return <Fragment>
        {error1 && (
            <MdModal
                title={error1.title}
                message={error1.message}
                onConfirm={() => errorHandler(error1.reloadPage)}
            ></MdModal>
        )}
        {sabrePnrPopup && (
            <PopupMedium
                title={sabrePnrPopup.title}
                message={sabrePnrPopup.message}
                onConfirm={() => errorHandler(false)}
            ></PopupMedium>
        )}
        {approvalHistoryModal && (
            <PopupMedium
                title={approvalHistoryModal.title}
                message={approvalHistoryModal.message}
                onConfirm={() => errorHandler(false)}
            ></PopupMedium>
        )}
        {approveRejectModal && (
            <MdModal
                title={approveRejectModal.title}
                message={approveRejectModal.message}
                onConfirm={() => errorHandler(false)}
                showOKButton={false}
            ></MdModal>
        )}

        <div className="col-lg-12 my-4">
            <div className="row mt-3">
                <div className="col-lg-12 mb-1">
                    <div className="card text-dark card-bg-blue mb-2">
                        <div className="card-body">
                            <div className="">
                                <div className="col-md-12">
                                    <div className='row'>

                                        <div className='col-md-4 my-1'>
                                            <h6 className="Yyy fw-bold text-primary mt-1">
                                                {getTripPaxName()}
                                            </h6>

                                        </div>
                                        <div className="col-md-3">
                                            <p className="card-text mb-0 mt-1">
                                                <strong>Confirmation No.  </strong>
                                                {props.referenceNumber}
                                            </p>
                                        </div>
                                        <div className='col-md-5 my-1'>
                                            <div className="float-md-end float-lg-end">
                                                <span className='fw-bold h6'>{isCorporate ? 'Request Date' : 'Booking Date'} </span>
                                                {props.bookDate}

                                                {!isCorporate && checkSecurityDetails("My Trips", "ViewTrip", "My Trips_ViewTrip_Allow View Trip") &&
                                                    <span className=''>
                                                        <button id={"ViewDetails" + props.id}
                                                            className="btn btn-sm btn-warning text-white ms-1 gt-border-radius-50 gt-yellow-btn gt-yellow-btn-spinner"
                                                            data bs-toggle="tooltip" data-bs-placement="top"
                                                            onClick={() => openRetrieveBooking(props.referenceNumber, props.serviceCode)}>
                                                            {(status === 'pending' || statusRetrieveHotel === 'pending' || statusRetrieveFlightPlusHotel === 'pending') ?
                                                                <i className="fa fa-spinner fa-spin" ></i> :
                                                                <i className="fa-sharp fa-solid fa-tv"></i>}
                                                        </button>
                                                        <ReactTooltip
                                                            anchorId={"ViewDetails" + props.id}
                                                            place="bottom"
                                                            variant="info"
                                                            multiline={true}
                                                            className="tooltip"
                                                            html={"View Trip"}
                                                        />
                                                    </span>
                                                }

                                                {isCorporate && checkSecurityDetails(moduleName, `${moduleNameWithoutSpace}ViewTravelRequest`, `${moduleName}_${moduleNameWithoutSpace}ViewTravelRequest_Allow View Travel Request`)
                                                    && !getConfigurationByBoolen("ALLOW_TRAVEL_REQUEST") &&
                                                    <span className=''>
                                                        <button id={"ViiewTravelRequest" + props.id}
                                                            className="btn btn-sm btn-warning text-white ms-1 gt-border-radius-50 gt-yellow-btn gt-yellow-btn-spinner"
                                                            data bs-toggle="tooltip" data-bs-placement="top"
                                                            onClick={() => openTravelRequest(props.referenceNumber)} >
                                                            {travelRequestStatus === 'pending' ?
                                                                <i className="fa fa-spinner fa-spin" ></i> :
                                                                <i className="fa-sharp fa-solid fa-tv"></i>}
                                                        </button>
                                                        <ReactTooltip
                                                            anchorId={"ViiewTravelRequest" + props.id}
                                                            place="bottom"
                                                            variant="info"
                                                            multiline={true}
                                                            className="tooltip"
                                                            html={"View Travel Request"}
                                                        />
                                                    </span>
                                                }

                                                {!isCorporate && checkSecurityDetails("My Trips", "ViewSabrePNR", "My Trips_ViewSabrePNR_Allow View Sabre PNR")
                                                    && getConfigurationByBoolen("ALLOW_VIEW_SABRE_PNR") === true && props.serviceCode === 'FL'
                                                    && props.serviceType === 'ON' && props.product.flightDetails.isCanceled === false
                                                    && props.product.flightDetails.status !== 'Failed' && props.product.flightDetails.pnrNumber
                                                    && props.product.flightDetails.pnrNumber !== ''
                                                    && (props.data.importPNR === undefined || props.data.ykSegment === undefined
                                                        || props.data.importPNR !== "M" ? true : props.data.importPNR === 'M' && props.data.ykSegment ? true : false) &&
                                                    <span className=''>
                                                        <button id={"ViewSabrePnr" + props.id}
                                                            className="btn btn-sm  text-white ms-1 gt-border-radius-50  class-purple-bg  gt-yellow-btn-spinner"
                                                            data bs-toggle="tooltip" data-bs-placement="top"
                                                            onClick={() => openSabreRetrieveBooking(props.product.flightDetails.pnrNumber)}>
                                                            {(gdsRetrieveTripStatus === 'pending') ?
                                                                <i className="fa fa-spinner fa-spin" ></i> :
                                                                <i className="fa-sharp fa-solid fa-laptop-code"></i>}
                                                        </button>
                                                        <ReactTooltip
                                                            anchorId={"ViewSabrePnr" + props.id}
                                                            place="bottom"
                                                            variant="info"
                                                            multiline={true}
                                                            className="tooltip"
                                                            html={"View Sabre PNR"}
                                                        />
                                                    </span>
                                                }

                                                {!isCorporate && checkSecurityDetails("My Trips", "ViewSabreGDSPNR", "My Trips_ViewSabreGDSPNR_Allow View Sabre GDS PNR")
                                                    && getConfigurationByBoolen("ALLOW_VIEW_SABRE_GDS_PNR") === true
                                                    && (props.serviceCode === 'FL' || props.serviceCode === 'FLHT') && props.serviceType === 'ON'
                                                    && props.product.flightDetails.isCanceled === false && props.product.flightDetails.status !== 'Failed'
                                                    && props.product.flightDetails.pnrNumber && props.product.flightDetails.pnrNumber !== ''
                                                    && (props.data.importPNR === undefined || props.data.ykSegment === undefined
                                                        || props.data.importPNR !== "M" ? true : props.data.importPNR === 'M' && props.data.ykSegment ? true : false) &&
                                                    < span className=''>
                                                        <button id={"ViewSabreGDSPNR" + props.id}
                                                            className="btn btn-sm  text-white ms-1 gt-border-radius-50  btn-primary  gt-yellow-btn-spinner"
                                                            data bs-toggle="tooltip" data-bs-placement="top"
                                                            onClick={() => openSabreGDSPNR(props.product.flightDetails.pnrNumber)} >
                                                            {(sabrePnrStatus === 'pending') ?
                                                                <i className="fa fa-spinner fa-spin" ></i> :
                                                                <i class="fa-solid fa-laptop"></i>}
                                                        </button>
                                                        <ReactTooltip
                                                            anchorId={"ViewSabreGDSPNR" + props.id}
                                                            place="bottom"
                                                            variant="info"
                                                            multiline={true}
                                                            className="tooltip"
                                                            html={"View Sabre GDS PNR"}
                                                        />
                                                    </span>
                                                }

                                                {!isCorporate && checkSecurityDetails("My Trips", "ViewSabreGDSPNR", "My Trips_ViewSabreGDSPNR_Allow View Sabre GDS PNR")
                                                    && getConfigurationByBoolen("ALLOW_VIEW_SABRE_GDS_PNR") === true && props.serviceCode === 'HT'
                                                    && props.serviceType === 'ON' && props.product.hotelDetails.isCanceled === false
                                                    && props.product.hotelDetails.status !== 'Failed' &&
                                                    <span className=''>
                                                        <button id={"ViewSabreGDSPNR" + props.id}
                                                            className="btn btn-sm  text-white ms-1 gt-border-radius-50  btn-primary  gt-yellow-btn-spinner"
                                                            data bs-toggle="tooltip" data-bs-placement="top"
                                                            onClick={() => openSabreGDSPNR(props.product.hotelDetails.pnrNumber)} >
                                                            {(sabrePnrStatus === 'pending') ?
                                                                <i className="fa fa-spinner fa-spin" ></i> :
                                                                <i class="fa-solid fa-laptop"></i>}
                                                        </button>
                                                        <ReactTooltip
                                                            anchorId={"ViewSabreGDSPNR" + props.id}
                                                            place="bottom"
                                                            variant="info"
                                                            multiline={true}
                                                            className="tooltip"
                                                            html={"View Sabre GDS PNR"}
                                                        />
                                                    </span>
                                                }

                                                {isCorporate && props.data.approvalStatusCode && props.data.approvalStatusCode.length > 0 &&
                                                    checkSecurityDetails(moduleName, `${moduleNameWithoutSpace}ViewApprovalHistory`, `${moduleName}_${moduleNameWithoutSpace}ViewApprovalHistory_Allow View Approval History`) &&
                                                    <span className=''>
                                                        <button id={"viewApprovalHistory" + props.id}
                                                            className="btn btn-sm  text-white ms-1 gt-border-radius-50  btn-secondary"
                                                            data bs-toggle="tooltip"
                                                            data-bs-placement="top"
                                                            onClick={handleViewApprovalHistoryClick}>
                                                            {
                                                                approvalHistoryRequestStatus === 'pending' ?
                                                                    <i className="fa fa-spinner fa-spin" ></i> :
                                                                    <i class="fas fa-history"></i>
                                                            }
                                                        </button>
                                                        <ReactTooltip
                                                            anchorId={"viewApprovalHistory" + props.id}
                                                            place="bottom"
                                                            variant="info"
                                                            multiline={true}
                                                            className="tooltip"
                                                            html={"View Approval History"}
                                                        />
                                                    </span>
                                                }

                                                {showApproveRejectLink() &&
                                                    <span className=''>
                                                        <button id={"updateApprovalStatus" + props.id}
                                                            className="btn btn-sm  text-white ms-1 gt-border-radius-50  gt-btn-primary  gt-yellow-btn-spinner"
                                                            data bs-toggle="tooltip"
                                                            data-bs-placement="top"
                                                            onClick={handleApproveRejectClick}>
                                                            <i className="fa-sharp fa-solid fa-user-plus"></i>
                                                        </button>
                                                        <ReactTooltip
                                                            anchorId={"updateApprovalStatus" + props.id}
                                                            place="bottom"
                                                            variant="info"
                                                            multiline={true}
                                                            className="tooltip"
                                                            html={"Approve | Reject"}
                                                        />
                                                    </span>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    {isCorporate &&
                                        <div className="row">
                                            <div className='col-md-4 my-1'>
                                                <p className="card-text mb-0 mt-1"><strong>Employee Code </strong>   {props.data.employeeCode}  </p>

                                            </div>

                                            {props.data.approvalUserName && props.data.approvalUserName.length > 0 &&
                                                <div className='col-md-3'>
                                                    <p className="card-text mb-0 mt-1">
                                                        <strong>{approvalActionByLabel()} </strong>   {props.data.approvalUserName}
                                                    </p>
                                                </div>
                                            }

                                            {props.data.approvalStatusCode && props.data.approvalStatusCode.length > 0 &&
                                                <div className='col-md-5 '>
                                                    <div className="float-md-end float-lg-end" >
                                                        <p className="card-text mb-0 mt-1"><strong>Status  </strong>
                                                            <label for="" className="mt-2 h5 text-primary">
                                                                {approvalStatusHtml()}
                                                            </label>
                                                        </p>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    }
                                    <hr className="my-1" />
                                </div>
                                <div className='row'>
                                    <div className='col-md-12'>

                                        {/*Serive-wise rendering*/}

                                        {tripHasService('FL') &&
                                            <Fragment>

                                                <div className="col-md-12">
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <label for="" className="mt-2 h5 text-primary">
                                                                <strong className="h6 me-2">
                                                                    <i className="fas fa-plane-departure"></i> Flight
                                                                </strong>
                                                            </label>
                                                        </div>

                                                        {props.serviceType !== 'TR' &&
                                                            <div className="col-md-4">
                                                                <label for="" className="mt-2 h5 text-primary">
                                                                    <span class={props.product.flightDetails.status === "Modified" ?
                                                                        "badge h6 rounded-pill mb-0 bg-orange" : props.serviceType === "OF" ?
                                                                            "badge h6 rounded-pill mb-0 bg-primary" :
                                                                            props.product.flightDetails.isCanceled !== undefined &&
                                                                                props.product.flightDetails.isCanceled !== false ?
                                                                                'badge h6 rounded-pill mb-0 bg-Failed' :
                                                                                (props.product.flightDetails !== undefined &&
                                                                                    props.product.flightDetails.pnrNumber !== undefined &&
                                                                                    props.product.flightDetails.pnrNumber !== '') ?
                                                                                    props.product.flightDetails.paxFname !== undefined &&
                                                                                        props.product.flightDetails.ticketNumber !== undefined &&
                                                                                        props.product.flightDetails.ticketNumber.length > 0 ?
                                                                                        'badge h6 rounded-pill mb-0 bg-success' :
                                                                                        "badge h6 rounded-pill mb-0 bg-warning" :
                                                                                    "badge h6 rounded-pill mb-0 bg-Failed"}>
                                                                        <i class={props.product.flightDetails.status === "Modified" ?
                                                                            "fa-solid fa-pen-square" : props.serviceType === "OF" ?
                                                                                "fa-solid fa-circle-check" : props.product.flightDetails.isCanceled !== undefined &&
                                                                                    props.product.flightDetails.isCanceled !== false ?
                                                                                    'fa-solid fa-circle-xmark' : (props.product.flightDetails !== undefined &&
                                                                                        props.product.flightDetails.pnrNumber !== undefined &&
                                                                                        props.product.flightDetails.pnrNumber !== '') ?
                                                                                        props.product.flightDetails.paxFname !== undefined &&
                                                                                            props.product.flightDetails.ticketNumber !== undefined &&
                                                                                            props.product.flightDetails.ticketNumber.length > 0 &&
                                                                                            props.product.flightDetails !== '' ? 'fa-solid fa-check-circle' :
                                                                                            "fa-solid fa-pause" : "fa-solid fa-circle-xmark"}>
                                                                        </i>
                                                                        <span className='ms-1'></span>
                                                                        {props.product.flightDetails.status === "Modified" ?
                                                                            props.product.flightDetails.status : props.serviceType === "OF" ? "Booking Request" :
                                                                                props.product.flightDetails.isCanceled !== undefined &&
                                                                                    props.product.flightDetails.isCanceled !== false ? 'Canceled' :
                                                                                    (props.product.flightDetails !== undefined &&
                                                                                        props.product.flightDetails.pnrNumber !== undefined &&
                                                                                        props.product.flightDetails.pnrNumber !== '') ?
                                                                                        props.product.flightDetails.ticketNumber !== undefined &&
                                                                                            props.product.flightDetails.ticketNumber.length > 0 ?
                                                                                            'Booked' : "On Hold" : "Failed"}
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className="col-md-6">
                                                        <p className="card-text mb-0 mt-1"><strong>Flight Date </strong>
                                                            {props.product.flightDetails.departureDate !== undefined ?
                                                                getFormatDate(props.product.flightDetails.departureDate) : ''}
                                                        </p>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <p className="card-text mb-0 mt-1">
                                                            <strong>Flight Type </strong>
                                                            {props.product.flightDetails.tripType}
                                                        </p>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <p className="card-text mb-0 mt-1">
                                                            <strong>Price </strong>
                                                            {new Intl.NumberFormat('en-US', {
                                                                style: 'currency',
                                                                currency: props.product.flightDetails.currency !== "" ? props.product.flightDetails.currency : "USD"
                                                            }).format(props.product.flightDetails.totalAmount)}
                                                        </p>
                                                    </div>

                                                    <div className='col-md-6'>
                                                        <p className="card-text mb-0 mt-1 pb-1">
                                                            <strong>
                                                                {(props.product.flightDetails.pnrNumber !== ""
                                                                    && props.product.flightDetails.pnrNumber !== undefined) ? "Source PNR " : ''}
                                                            </strong>
                                                            {props.product.flightDetails.pnrNumber}
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    {/*<div className="col-md-12">*/}
                                                    {/*</div>*/}
                                                    <div className="col-md-6">
                                                        <p className="card-text mb-0"><strong>From </strong>
                                                            {props.product.flightDetails.departureLocationName +
                                                                "(" + props.product.flightDetails.departureLocationCode +
                                                                "), " + props.product.flightDetails.departureCountryName +
                                                                "(" + props.product.flightDetails.departureCountryCode + ")"
                                                            }
                                                        </p>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <p className="card-text mb-0"><strong>To </strong>
                                                            {props.product.flightDetails.arrivalLocationName +
                                                                "(" + props.product.flightDetails.arrivalLocationCode +
                                                                "), " + props.product.flightDetails.arrivalCountryName +
                                                                "(" + props.product.flightDetails.arrivalCountryCode + ")"
                                                            }
                                                        </p>
                                                    </div>

                                                </div>

                                                <div className="col-md-12">
                                                    <hr className="my-2"></hr>
                                                </div>

                                            </Fragment>
                                        }

                                        {tripHasService('HT') &&
                                            <Fragment>

                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <label for="" className="mt-2 h5 text-primary">
                                                            <strong className="h6 me-2"><i className="fas fa-hotel"></i> Hotel </strong>
                                                        </label>
                                                    </div>
                                                    {props.serviceType !== 'TR' &&
                                                        <div className="col-md-4">
                                                            <label for="" className="mt-2 h5 text-primary">
                                                                <span class={props.serviceType === "OF" ? "badge h6 rounded-pill mb-0 bg-primary" :
                                                                    props.product.hotelDetails.isCanceled !== undefined &&
                                                                        props.product.hotelDetails.isCanceled !== false ? 'badge h6 rounded-pill mb-0 bg-Failed' :
                                                                        (props.product.hotelDetails !== undefined && props.product.hotelDetails.pnrNumber !== undefined &&
                                                                            props.product.hotelDetails.pnrNumber !== '' && props.product.hotelDetails.bookingID !== undefined) ?
                                                                            "badge h6 rounded-pill mb-0 bg-success" : "badge h6 rounded-pill mb-0 bg-Failed"}>
                                                                    <i class={props.serviceType === "OF" ? "fa-solid fa-circle-check" :
                                                                        props.product.hotelDetails.isCanceled !== undefined &&
                                                                            props.product.hotelDetails.isCanceled !== false ? 'fa-solid fa-circle-xmark' :
                                                                            (props.product.hotelDetails !== undefined &&
                                                                                props.product.hotelDetails.pnrNumber !== undefined &&
                                                                                props.product.hotelDetails.pnrNumber !== '' &&
                                                                                props.product.hotelDetails.bookingID !== undefined) ? "fa-solid fa-check-circle" :
                                                                                "fa-solid fa-circle-xmark"}>
                                                                    </i>
                                                                    <span className='ms-1'></span>
                                                                    {props.serviceType === "OF" ? "Booking Request" :
                                                                        props.product.hotelDetails.isCanceled !== undefined &&
                                                                            props.product.hotelDetails.isCanceled !== false ? 'Canceled' :
                                                                            (props.product.hotelDetails !== undefined &&
                                                                                props.product.hotelDetails.pnrNumber !== undefined &&
                                                                                props.product.hotelDetails.pnrNumber.length > 0 &&
                                                                                props.product.hotelDetails.bookingID !== undefined) ? "Booked" : "Failed"
                                                                    }
                                                                </span>
                                                            </label>
                                                        </div>
                                                    }

                                                </div>

                                                <div className='row'>

                                                    <div className="col-md-6">
                                                        <p className="card-text mb-0 mt-1"><strong>Check-in </strong>
                                                            {props.product.hotelDetails.checkInDate !== undefined ?
                                                                getFormatDate(props.product.hotelDetails.checkInDate) : ''}
                                                        </p>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <p className="card-text mb-0 mt-1"><strong>Check-out </strong>
                                                            {props.product.hotelDetails.checkOutDate !== undefined ?
                                                                getFormatDate(props.product.hotelDetails.checkOutDate) : ''}
                                                        </p>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <p className="card-text mb-0 mt-1"><strong>Price </strong>
                                                            {new Intl.NumberFormat('en-US', {
                                                                style: 'currency',
                                                                currency: props.product.hotelDetails.currency !== undefined ? props.product.hotelDetails.currency : "USD"
                                                            }).format(props.product.hotelDetails.totalAmount)}
                                                        </p>
                                                    </div>

                                                    {props.product.hotelDetails.pnrNumber !== '' &&
                                                        <div className='col-md-6'>
                                                            <p className="card-text mb-0 mt-1 pb-1"><strong>
                                                                {props.product.hotelDetails.pnrNumber !== undefined &&
                                                                    props.product.hotelDetails.pnrNumber !== undefined ? "Source PNR " : ''} </strong>
                                                                {props.product.hotelDetails.pnrNumber}
                                                            </p>
                                                        </div>
                                                    }
                                                </div>

                                                <div className='row'>
                                                    <div className="col-md-6">
                                                        <p className="card-text mb-0 mt-1">
                                                            <strong>Hotel Name </strong>
                                                            {props.product.hotelDetails.hotelName}
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6">
                                                        {(props.product.hotelDetails.address !== undefined ||
                                                            props.product.hotelDetails.cityName !== undefined ||
                                                            props.product.hotelDetails.addressCountry !== undefined) &&
                                                            <p className="card-text mb-0 mt-1">
                                                                <strong>Address </strong>
                                                                {props.product.hotelDetails.address !== undefined ? props.product.hotelDetails.address.trim() : ""}
                                                                {props.product.hotelDetails.address !== undefined && props.product.hotelDetails.cityName !== undefined ? ", " : ""}
                                                                {props.product.hotelDetails.cityName !== undefined ? props.product.hotelDetails.cityName.trim() : ""}
                                                                {props.product.hotelDetails.cityName !== undefined && props.product.hotelDetails.addressCountry !== undefined ? ", " : ""}
                                                                {props.product.hotelDetails.addressCountry !== undefined ? props.product.hotelDetails.addressCountry.trim() : ""}
                                                            </p>
                                                        }
                                                    </div>

                                                </div>

                                                <div className="col-md-12">
                                                    <hr className="my-2"></hr>
                                                </div>

                                            </Fragment>
                                        }

                                        {tripHasService('SR') &&
                                            <Fragment>

                                                <div className="row">
                                                    <div className="col-md-8">
                                                        <label for="" className="mt-2 h5 text-primary">
                                                            <strong className="h6 me-2">
                                                                <i className="fas fa-cog"></i> Service Request
                                                            </strong>
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className="col-md-4">
                                                        <p className="card-text mb-0 mt-1">
                                                            <strong>Service Name </strong>
                                                            {props.product.serviceRequest.serviceName}
                                                        </p>
                                                    </div>

                                                    {props.product.serviceRequest.existingConfirmationNumber !== "" &&
                                                        <div className="col-md-4">
                                                            <p className="card-text mb-0 mt-1">
                                                                <strong>Existing Confirmation Number </strong>
                                                                {props.product.serviceRequest.existingConfirmationNumber}
                                                            </p>
                                                        </div>
                                                    }

                                                    {props.product.serviceRequest.totalRateOfService !== "" &&
                                                        <div className="col-md-4">
                                                            <p className="card-text mb-0 mt-1">
                                                                <strong>Rate of Service </strong>
                                                                {props.product.serviceRequest.totalRateOfService}
                                                            </p>
                                                        </div>
                                                    }

                                                    <div className="col-md-12">
                                                        <p className="card-text mb-0 mt-1">
                                                            <strong>Remarks </strong>
                                                            <p dangerouslySetInnerHTML={{ __html: props.product.serviceRequest.remarks }} />
                                                        </p>
                                                    </div>
                                                </div>

                                            </Fragment>
                                        }

                                        {/*Other Details*/}
                                        {props.serviceCode !== 'SR' &&
                                            <div className='row'>
                                                <div className="tour_details_boxed_inner">
                                                    <div className="accordion" id={"accordionExample" + props.id}>

                                                        <div className="accordion_flex_area mt-2  Accordion-toggle-icon">

                                                            <div className="accordion-item pb-0">
                                                                <h2 className="accordion-header" id={"headingThree" + props.id} >
                                                                    <button className="accordion-button text-primary collapsed" type="button"
                                                                        data-bs-toggle="collapse" data-bs-target={"#collapseFive" + props.id}
                                                                        aria-expanded="false" aria-controls={"collapseFive" + props.id}>
                                                                        Other Details
                                                                    </button>
                                                                </h2>
                                                                <div id={"collapseFive" + props.id} className="accordion-collapse collapse"
                                                                    aria-labelledby="headingfive" data-bs-parent={"#accordionExample" + props.id}>
                                                                    <div className="row">
                                                                        {tripHasService('FL,HT') &&
                                                                            <label for="" className="h6 text-primary mt-2">
                                                                                <i className="fas fa-scroll"></i> Service Details
                                                                            </label>
                                                                        }
                                                                    </div>

                                                                    {tripHasService('FL') &&
                                                                        <Fragment>
                                                                            <p className="card-text pb-0 mt-2 text-primary">
                                                                                <strong><i className="fas fa-plane-departure"></i> Flight </strong>
                                                                            </p>

                                                                            <div className='row'>
                                                                                <div className="col-md-12">
                                                                                    <hr className="my-2"></hr>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <p className="card-text mb-0 mt-1 pb-1"><strong>From </strong>
                                                                                        {props.product.flightDetails.departureLocationName +
                                                                                            "(" + props.product.flightDetails.departureLocationCode +
                                                                                            "), " + props.product.flightDetails.departureCountryName +
                                                                                            "(" + props.product.flightDetails.departureCountryCode + ")"}
                                                                                    </p>
                                                                                </div>

                                                                                <div className="col-md-6">
                                                                                    <p className="card-text mb-0 mt-1 pb-1"><strong>To </strong>
                                                                                        {props.product.flightDetails.arrivalLocationName +
                                                                                            "(" + props.product.flightDetails.arrivalLocationCode +
                                                                                            "), " + props.product.flightDetails.arrivalCountryName +
                                                                                            "(" + props.product.flightDetails.arrivalCountryCode + ")"}
                                                                                    </p>
                                                                                </div>
                                                                            </div>

                                                                            <div className='row'>

                                                                                <div className='col-md-6'>
                                                                                    <p className="card-text mb-0 mt-1 pb-1"><strong>Class </strong>
                                                                                        {props.product.flightDetails.cabin} </p>
                                                                                </div>
                                                                                <div className='col-md-6'>
                                                                                    <p className="card-text mb-0 mt-1 pb-1"><strong>Flight Date </strong>
                                                                                        {(props.product.flightDetails.departureDate !== undefined ?
                                                                                            getFormatDate(props.product.flightDetails.departureDate) : '')}
                                                                                    </p>
                                                                                </div>
                                                                                <div className='col-md-6'>
                                                                                    <p className="card-text mb-0 mt-1 pb-1"><strong>Flight No. </strong>
                                                                                        {props.product.flightDetails.marketingAirlineCode + " " +
                                                                                            props.product.flightDetails.flightNo}
                                                                                    </p>
                                                                                </div>

                                                                                <div className='col-md-6'>
                                                                                    {props.product.flightDetails.segmentCount !== "0" &&
                                                                                        <p className="card-text mb-0 mt-1 pb-1">
                                                                                            <strong>
                                                                                                Segments
                                                                                            </strong>
                                                                                            {props.product.flightDetails.segmentCount !== "0" ?
                                                                                                props.product.flightDetails.segmentCount : ''}
                                                                                        </p>
                                                                                    }
                                                                                </div>

                                                                            </div>

                                                                            <div className='row'>
                                                                                {getConfigurationByBoolen("ALLOW_SOURCE_PCC_FARE") &&
                                                                                    <div className='col-md-6'>
                                                                                        <p className="card-text mb-0 mt-1 pb-1">
                                                                                            <strong>Source </strong>
                                                                                            {props.product.flightDetails.source}
                                                                                        </p>
                                                                                    </div>
                                                                                }

                                                                                {getConfigurationByBoolen("ALLOW_SOURCE_PCC_FARE") &&
                                                                                    <div className='col-md-6'>
                                                                                        <p className="card-text mb-0 mt-1 pb-1">
                                                                                            <strong>PCC </strong>
                                                                                            {props.product.flightDetails.pcc}
                                                                                        </p>
                                                                                    </div>
                                                                                }

                                                                                <div className='col-md-6'>
                                                                                    <p className="card-text mb-0 mt-1 pb-1"><strong>
                                                                                        {(props.product.flightDetails.pnrNumber !== "" &&
                                                                                            props.product.flightDetails.pnrNumber !== undefined) ? "Source PNR " : ''}
                                                                                    </strong>
                                                                                        {props.product.flightDetails.pnrNumber}
                                                                                    </p>
                                                                                </div>

                                                                                <div className='col-md-6'>
                                                                                    <p className="card-text mb-0 mt-1 pb-1"><strong>
                                                                                        {props.product.flightDetails.airlinePNRNumber !== '' &&
                                                                                            props.product.flightDetails.airlinePNRNumber !== undefined ? "Airline PNR " : ""}
                                                                                    </strong>{props.product.flightDetails.airlinePNRNumber}
                                                                                    </p>
                                                                                </div>

                                                                            </div>

                                                                        </Fragment>
                                                                    }

                                                                    {tripHasService('HT') &&
                                                                        <Fragment>

                                                                            {tripHasService('FL') &&
                                                                                <hr className='my-2'></hr>
                                                                            }

                                                                            <p className="card-text pb-0 mt-2 text-primary">
                                                                                <strong><i className="fas fa-hotel"></i> Hotel </strong>
                                                                            </p>

                                                                            <div className='row'>
                                                                                <div className="col-md-12">
                                                                                    <hr className="my-2"></hr>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <p className="card-text mb-0 mt-1 pb-0">
                                                                                        <strong>Hotel Name </strong>
                                                                                        {props.product.hotelDetails.hotelName}
                                                                                    </p>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    {(props.product.hotelDetails.address !== undefined ||
                                                                                        props.product.hotelDetails.cityName !== undefined ||
                                                                                        props.product.hotelDetails.addressCountry !== undefined) &&
                                                                                        <p className="card-text mb-0 mt-1 pb-0"><strong>Address </strong>
                                                                                            {props.product.hotelDetails.address !== undefined ? props.product.hotelDetails.address.trim() : ""}
                                                                                            {props.product.hotelDetails.address !== undefined && props.product.hotelDetails.cityName !== undefined ? ", " : ""}
                                                                                            {props.product.hotelDetails.cityName !== undefined ? props.product.hotelDetails.cityName.trim() : ""}
                                                                                            {props.product.hotelDetails.cityName !== undefined && props.product.hotelDetails.addressCountry !== undefined ? ", " : ""}
                                                                                            {props.product.hotelDetails.addressCountry !== undefined ? props.product.hotelDetails.addressCountry.trim() : ""}
                                                                                        </p>
                                                                                    }
                                                                                </div>

                                                                            </div>

                                                                            <div className='row'>

                                                                                {getConfigurationByBoolen("ALLOW_SOURCE_PCC_FARE") &&
                                                                                    <div className='col-md-6'>
                                                                                        <p className="card-text mb-0 mt-1 pb-1">
                                                                                            <strong>Source </strong>
                                                                                            {props.product.hotelDetails.source}
                                                                                        </p>
                                                                                    </div>
                                                                                }

                                                                                {getConfigurationByBoolen("ALLOW_SOURCE_PCC_FARE") &&
                                                                                    <div className='col-md-6'>
                                                                                        <p className="card-text mb-0 mt-1 pb-1">
                                                                                            <strong>PCC </strong>
                                                                                            {props.product.hotelDetails.pcc}
                                                                                        </p>
                                                                                    </div>
                                                                                }


                                                                                {props.product.hotelDetails.pnrNumber !== '' &&
                                                                                    <div className='col-md-6'>
                                                                                        <p className="card-text mb-0 mt-1 pb-1">
                                                                                            <strong>
                                                                                                {props.product.hotelDetails.pnrNumber !== undefined &&
                                                                                                    props.product.hotelDetails.pnrNumber !== undefined ? "Source PNR " : ''}
                                                                                            </strong>{props.product.hotelDetails.pnrNumber}
                                                                                        </p>
                                                                                    </div>
                                                                                }

                                                                                {props.product.hotelDetails.bookingID !== '' &&
                                                                                    <div className='col-md-6'>
                                                                                        <p className="card-text mb-0 mt-1 pb-1">
                                                                                            <strong>
                                                                                                {props.product.hotelDetails.bookingID !== undefined &&
                                                                                                    props.product.hotelDetails.bookingID !== undefined ? "Hotel Confirmation No. " : ""}
                                                                                            </strong>
                                                                                            {props.product.hotelDetails.bookingID}
                                                                                        </p>
                                                                                    </div>
                                                                                }

                                                                            </div>

                                                                        </Fragment>
                                                                    }

                                                                    <hr className='my-2'>
                                                                    </hr>

                                                                    <div className="row">
                                                                        <label for="" className="h6 text-primary mt-2">
                                                                            <i className="fa fa-user "></i>
                                                                            {tripHasService('FL,HT,SR') ? 'Traveler Details' :
                                                                                'Guest Details'}
                                                                        </label>
                                                                    </div>

                                                                    <div className='row'>
                                                                        <div className="col-md-12">
                                                                            <p className="card-text mb-0 mt-1 pb-1 fw-bold">
                                                                                {getTripPaxName()}
                                                                                <strong className='card-text'>
                                                                                    {getPaxCountSuffix()}
                                                                                </strong>
                                                                            </p>
                                                                        </div>
                                                                    </div>

                                                                    {tripHasService('FL') &&
                                                                        <div className='row'>
                                                                            <div className="col-md-4">
                                                                                <p className="card-text mb-0 mt-1 pb-1"><strong>Ticket No. </strong>
                                                                                    {props.product.flightDetails.ticketNumber.length > 0 ?
                                                                                        props.product.flightDetails.ticketNumber : 'N/A'}
                                                                                </p>
                                                                            </div>

                                                                            <div className="col-md-8">
                                                                                <p className="card-text mb-0 mt-1 pb-1">
                                                                                    <strong>Ticketed On </strong>
                                                                                    {props.product.flightDetails.ticketNumber.length > 0 ?
                                                                                        props.product.flightDetails.marketingAirlineName + "(" +
                                                                                        props.product.flightDetails.marketingAirlineCode + ")" : 'N/A'}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    }

                                                                    <hr className='my-2'></hr>

                                                                    <div className="row">
                                                                        <label for="" className="h6 text-primary mt-2">
                                                                            <i className="fa-solid fa-money-bill"></i> Price Details
                                                                        </label>
                                                                    </div>

                                                                    {tripHasService('FL') &&
                                                                        <Fragment>
                                                                            <p className="card-text pb-0 mt-2 text-primary">
                                                                                <strong>
                                                                                    <i className="fas fa-plane-departure"></i> Flight
                                                                                </strong>
                                                                            </p>

                                                                            <hr className='my-2'></hr>

                                                                            <div className='row'>
                                                                                <div className="col-md-4">
                                                                                    <p className="card-text mb-0 mt-1 pb-1">
                                                                                        <strong>Fare Type </strong>
                                                                                        {props.product.flightDetails.fareType}
                                                                                    </p>
                                                                                </div>

                                                                                <div className="col-md-4">
                                                                                    <p className="card-text mb-0 mt-1 pb-1">
                                                                                        <strong>Price </strong>
                                                                                        {new Intl.NumberFormat('en-US', {
                                                                                            style: 'currency',
                                                                                            currency: props.product.flightDetails.currency !== "" ?
                                                                                                props.product.flightDetails.currency : "USD"
                                                                                        }).format(props.product.flightDetails.totalAmount)}
                                                                                    </p>
                                                                                </div>

                                                                                <div className="col-md-4">
                                                                                    <p className="card-text mb-0 mt-1 pb-1"><strong>Base </strong>
                                                                                        {new Intl.NumberFormat('en-US', {
                                                                                            style: 'currency',
                                                                                            currency: props.product.flightDetails.currency !== "" ?
                                                                                                props.product.flightDetails.currency : "USD"
                                                                                        }).format(props.product.flightDetails.baseFare)}
                                                                                    </p>
                                                                                </div>

                                                                                <div className="col-md-4">
                                                                                    <p className="card-text mb-0 mt-1 pb-1">
                                                                                        <strong>Tax </strong>{new Intl.NumberFormat('en-US', {
                                                                                            style: 'currency',
                                                                                            currency: props.product.flightDetails.currency !== "" ?
                                                                                                props.product.flightDetails.currency : "USD"
                                                                                        }).format(props.product.flightDetails.taxes)}
                                                                                    </p>
                                                                                </div>

                                                                                {getConfigurationByBoolen("ALLOW_COMISSION") &&
                                                                                    <div className="col-md-4">
                                                                                        <p className="card-text mb-0 mt-1 pb-1">
                                                                                            <strong>Comm. </strong>
                                                                                            {props.product.flightDetails.commission !== undefined &&
                                                                                                props.product.flightDetails.commission !== '' ?
                                                                                                new Intl.NumberFormat('en-US', {
                                                                                                    style: 'currency',
                                                                                                    currency: props.product.flightDetails.currency !== "" ?
                                                                                                        props.product.flightDetails.currency : "USD"
                                                                                                }).format(props.product.flightDetails.commission) : 'N/A'
                                                                                            }
                                                                                        </p>
                                                                                    </div>
                                                                                }

                                                                                {getConfigurationByBoolen("ALLOW_SERVICE_FEE") &&
                                                                                    <div className="col-md-4">
                                                                                        <p className="card-text mb-0 mt-1 pb-1"><strong>Service Fee </strong>{
                                                                                            props.product.flightDetails.markup !== undefined &&
                                                                                                props.product.flightDetails.markup !== '' ?
                                                                                                new Intl.NumberFormat('en-US', {
                                                                                                    style: 'currency',
                                                                                                    currency: props.product.flightDetails.currency !== "" ?
                                                                                                        props.product.flightDetails.currency : "USD"
                                                                                                }).format(props.product.flightDetails.markup) : 'N/A'}
                                                                                        </p>
                                                                                    </div>
                                                                                }

                                                                            </div>

                                                                        </Fragment>
                                                                    }

                                                                    {tripHasService('HT') &&
                                                                        <Fragment>

                                                                            {tripHasService('FL') &&
                                                                                <hr className='my-2'></hr>
                                                                            }

                                                                            <p className="card-text pb-0 mt-2 text-primary">
                                                                                <strong><i className="fas fa-hotel"></i> Hotel </strong>
                                                                            </p>

                                                                            <hr className='my-2'>
                                                                            </hr>

                                                                            <div className='row'>

                                                                                <div className="col-md-4">
                                                                                    <p className="card-text mb-0 mt-1 pb-1">
                                                                                        <strong>Price </strong>
                                                                                        {new Intl.NumberFormat('en-US', {
                                                                                            style: 'currency',
                                                                                            currency: props.product.hotelDetails.currency !== undefined ?
                                                                                                props.product.hotelDetails.currency : "USD"
                                                                                        }).format(props.product.hotelDetails.totalAmount)}
                                                                                    </p>
                                                                                </div>

                                                                                <div className="col-md-4">
                                                                                    <p className="card-text mb-0 mt-1 pb-1">
                                                                                        <strong>Base </strong>
                                                                                        {new Intl.NumberFormat('en-US', {
                                                                                            style: 'currency',
                                                                                            currency: props.product.hotelDetails.currency !== undefined ?
                                                                                                props.product.hotelDetails.currency : "USD"
                                                                                        }).format(props.product.hotelDetails.baseFare)}
                                                                                    </p>
                                                                                </div>

                                                                                <div className="col-md-4">
                                                                                    <p className="card-text mb-0 mt-1 pb-1">
                                                                                        <strong>Tax </strong>
                                                                                        {new Intl.NumberFormat('en-US', {
                                                                                            style: 'currency',
                                                                                            currency: props.product.hotelDetails.currency !== undefined ?
                                                                                                props.product.hotelDetails.currency : "USD"
                                                                                        }).format(props.product.hotelDetails.taxes)}
                                                                                    </p>
                                                                                </div>

                                                                                {props.product.hotelDetails.commissionType !== undefined &&
                                                                                    getConfigurationByBoolen("ALLOW_COMISSION") &&
                                                                                    <div className="col-md-4">
                                                                                        <p className="card-text mb-0 mt-1 pb-1">
                                                                                            <strong>Comm. </strong>
                                                                                            {props.product.hotelDetails.commissionType !== undefined ?
                                                                                                props.product.hotelDetails.commissionType ===
                                                                                                    'Percentage' ? props.product.hotelDetails.commission !== '' &&
                                                                                                        props.product.hotelDetails.commission !==
                                                                                                        undefined ? Math.abs(props.product.hotelDetails.commission) + "%" : 'N/A' :
                                                                                                    props.product.hotelDetails.commissionType === 'Variable' ? 'Variable' :
                                                                                                        new Intl.NumberFormat('en-US', {
                                                                                                            style: 'currency',
                                                                                                            currency: props.product.hotelDetails.currency !== undefined ?
                                                                                                                props.product.hotelDetails.currency : "USD"
                                                                                                        }).format(props.product.hotelDetails.commission) : 'N/A'}
                                                                                        </p>
                                                                                    </div>
                                                                                }

                                                                                {getConfigurationByBoolen("ALLOW_SERVICE_FEE") &&
                                                                                    <div className="col-md-4">
                                                                                        <p className="card-text mb-0 mt-1 pb-1">
                                                                                            <strong>Service Fee </strong>
                                                                                            {props.product.hotelDetails.markup !== undefined &&
                                                                                                props.product.hotelDetails.markup !== '' ?
                                                                                                new Intl.NumberFormat('en-US', {
                                                                                                    style: 'currency',
                                                                                                    currency: props.product.hotelDetails.currency !== undefined ?
                                                                                                        props.product.hotelDetails.currency : "USD"
                                                                                                }).format(props.product.hotelDetails.markup) : 'N/A'}
                                                                                        </p>
                                                                                    </div>
                                                                                }
                                                                            </div>

                                                                        </Fragment>
                                                                    }

                                                                    <hr className='my-2'>
                                                                    </hr>

                                                                    <div className="row">
                                                                        <label for="" className="h6 text-primary mt-2">
                                                                            <i className="fa-solid fa-tag"></i> Booking Details
                                                                        </label>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className="col-md-4">
                                                                            <p className="card-text mb-0 mt-1 pb-1">
                                                                                <strong>Date </strong>
                                                                                {props.bookDate}
                                                                            </p>
                                                                        </div>

                                                                        <div className="col-md-4">
                                                                            {getConfigurationByBoolen("ALLOW_B2B") &&
                                                                                <p className="card-text mb-0 mt-1 pb-1">
                                                                                    <strong>Booked By </strong>
                                                                                    {props.bookedBy}
                                                                                </p>
                                                                            }
                                                                        </div>

                                                                        <div className="col-md-4">
                                                                            {getConfigurationByBoolen("ALLOW_B2B") &&
                                                                                <p className="card-text mb-0 mt-1 pb-1">
                                                                                    <strong>Office </strong>
                                                                                    {props.officeName}
                                                                                </p>
                                                                            }
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </Fragment >
};

export default MyTripsItem;
