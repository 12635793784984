import { Fragment, useCallback, useState, useEffect, useRef } from 'react';
import useDocumentTitle from '../../components/Common/layout/useDocumentTitle'
import Dropdown from 'react-select';
import { v4 as uuidv4 } from 'uuid';
import { searchHotel } from '../../services/Hotel-API';
import useHttp from '../../services/use-http';
import HotelList from './Components/HotelList';
import ErrorModal from "../../components/Common/Error/ErrorModal";
import { useTranslation } from 'react-i18next';
import LoadingHotel from './Components/LoadingHotel';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { SearchHotelChain, SearchHotelExclusiveDeals, SearchHotelName } from '../../services/Fee-API';
import { getPccList, getRatePlanList, getRatePlanPCCWiseList } from '../../services/RatePlanAllowed-API';
import { GET_ALL_AIRPORT_LIST, GET_BLOCK_COUNTRY_CITY_LIST } from '../../services/CONSTANTS';
import { getConfigurationByValue, getConfigurationByBoolen } from '../../utils/ConfigurationsFunction';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import { checkSecurityDetails } from '../../utils/RoleUtils';

//User Story 1070: Change in Search for Single PNR.
import queryString from 'query-string';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import sorter from "sort-nested-json";
import { getPolicyID } from '../../utils/OBTFunction';
import secureLocalStorage from 'react-secure-storage';
import { searchRoe } from '../../services/ROE-API';


//User Story 5108: Show Expedia content
let SourceOptionsWithAll = [{ value: '100', label: 'Show Sabre Only' },
{ value: '113', label: 'Show CGN Only' }, { value: '110', label: 'Show TAAP Only' }, { value: '000', label: 'Show All' }];
let SourceOptionsWithCGN = [{ value: '100', label: 'Show Sabre Only' },
{ value: '113', label: 'Show CGN Only' }, { value: '000', label: 'Show All' }];
let SourceOptionsWithTaap = [{ value: '100', label: 'Show Sabre Only' },
{ value: '110', label: 'Show TAAP Only' }, { value: '000', label: 'Show All' }];

const SearchHotel = () => {
    useDocumentTitle('Hotel Search');
    const [SourceOptions, setSourceOptions] = useState([]);
    const { sendRequest, status, data: loadedHotelSearch, error } = useHttp(searchHotel);
    const { sendRequest: sendRequestMoveDown, status: statusMoveDown, data: loadedHotelSearchMoveDown, error: errorMoveDown } = useHttp(searchHotel);
    const { sendRequest: sendRequestRoe, status: statusRoe, data: loadedRoe, error: errorRoe } = useHttp(searchRoe);

    const { t } = useTranslation(['translation.SearchHotel']);
    const [branDisabled, setBranDisabled] = useState(false);
    const radiusInputRef = useRef('');
    const [cityCode, setCityCode] = useState();
    const [selectedHotel, setSelectedHotel] = useState();
    const [searchString, setSearchString] = useState('');
    const [hotelNameList, setHotelNameList] = useState([]);
    const [hotelName, setHotelName] = useState();
    const [inputErrorHotelName, setinputHotelNameError] = useState(false);
    const [radius, setRadius] = useState();

    const history = useHistory();
    const [statusMoveDownClick, setStatusMoveDownClick] = useState(false);
    const [isadvanced, setIsAdvanced] = useState(false);
    const [userChangedExclDeals, setUserChangedExclDeals] = useState(false);
    const [hoteNameDisable, setHotelNameDisable] = useState(true);
    const [minToDateChange, setMinToDateChange] = useState('');
    const [defaultSource, setDefaultSource] = useState(null);

    const [isFirtTime, setFirstTime] = useState("");
    const [error1, setError] = useState('');
    const [hotelNameError, setHotelNameError] = useState();
    const [pageFocus, setPageFocus] = useState(true);
    const [isSubmitClicked, setIsSubmitClicked] = useState(false);
    const [items, setItems] = useState([]);
    const [guestsValue, setGuestsValue] = useState('');
    const [roomsValue, setRoomsValue] = useState('');
    const [chainCode, setChainCode] = useState('');
    const [providerCode, setProviderCode] = useState('');
    const [sourceValue, setSourceValue] = useState('000');
    const [officeWisePCCCode, setOfficeWisePCCCode] = useState('');
    const [ratePlanCode, setRatePlanCode] = useState('');
    const [exclusiveDealsChecked, setExclusiveDealsChecked] = useState(false)
    const [exclusiveDealsDisable, setExclusiveDealsDisable] = useState(true);
    const [isexclusiveDealsDisable, setIsExclusiveDealsDisable] = useState(false);
    const [ratingValue, setRatingValue] = useState('');

    const location = useLocation();
    const valueParam = queryString.parse(location.search);

    let ratingList = [];
    let defaultRating = "";
    let travelRequestFieldsDataJson = "";

    if (localStorage.getItem("loginData") !== null && localStorage.getItem('isCorporate') !== null && localStorage.getItem('isCorporate') === 'true') {
        if (localStorage.getItem("loginData") !== null && sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
            let searchPolicyResponseTemp = JSON.parse(localStorage.getItem("loginData"));
            travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));

            let searchPolicyResponse = searchPolicyResponseTemp.policy === null ? null : searchPolicyResponseTemp.policy.filter(a => a.tripReasonID === travelRequestFieldsDataJson.tripReason && a.isHotel === true);

            if (searchPolicyResponseTemp?.policy !== null && searchPolicyResponse[0] !== undefined && searchPolicyResponse[0].rating !== undefined && searchPolicyResponse[0].rating !== null && searchPolicyResponse[0].rating !== '') {
                let ratingListTemp = [];
                ratingListTemp.push(
                    { value: 6, label: 'All' },
                    { value: 5, label: '5' },
                    { value: 4, label: '4' },
                    { value: 3, label: '3' },
                    { value: 2, label: '2' },
                    { value: 1, label: '1' }
                );

                let ratingTemp = searchPolicyResponse[0].rating === "All" ? 6 : parseInt(searchPolicyResponse[0].rating);
                let highestRatingText = ratingListTemp.filter(a => a.value === ratingTemp)[0].value;
                let highestRatingId = ratingTemp;

                for (let index = highestRatingId; index > 0; index--) {
                    ratingList.push(
                        { value: ratingListTemp.filter(a => a.value === index)[0].value, label: index === 6 ? 'All' : index.toString() }
                    );
                }

                if (valueParam.return !== undefined && valueParam.return !== '' && valueParam.return === '1' && sessionStorage.getItem("hotelSessionDataJson") !== null) {
                    let hotelSessionDataJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
                    defaultRating = hotelSessionDataJson.rating;
                }
                else {
                    let hotelSessionDataJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
                    defaultRating = sessionStorage.getItem('hotelSessionDataJson') !== null && sessionStorage.getItem('hotelSessionDataJson') !== '' && hotelSessionDataJson.rating !== '' ? hotelSessionDataJson.rating : highestRatingText;
                }
            }
            else {
                ratingList.push(
                    { value: 6, label: 'All' },
                    { value: 5, label: '5' },
                    { value: 4, label: '4' },
                    { value: 3, label: '3' },
                    { value: 2, label: '2' },
                    { value: 1, label: '1' }
                );

                if (valueParam.return !== undefined && valueParam.return !== '' && valueParam.return === '1' && sessionStorage.getItem("hotelSessionDataJson") !== null) {
                    let hotelSessionDataJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
                    defaultRating = hotelSessionDataJson.rating;
                }
                else {
                    let hotelSessionDataJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
                    defaultRating = sessionStorage.getItem('hotelSessionDataJson') !== null && sessionStorage.getItem('hotelSessionDataJson') !== '' && hotelSessionDataJson.rating !== '' ? hotelSessionDataJson.rating : 6;
                }
            }
        }
        else {
            ratingList.push(
                { value: 6, label: 'All' },
                { value: 5, label: '5' },
                { value: 4, label: '4' },
                { value: 3, label: '3' },
                { value: 2, label: '2' },
                { value: 1, label: '1' }
            );

            if (valueParam.return !== undefined && valueParam.return !== '' && valueParam.return === '1' && sessionStorage.getItem("hotelSessionDataJson") !== null) {
                let hotelSessionDataJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
                defaultRating = hotelSessionDataJson.rating;
            }
            else {
                let hotelSessionDataJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
                defaultRating = sessionStorage.getItem('hotelSessionDataJson') !== null && sessionStorage.getItem('hotelSessionDataJson') !== '' && hotelSessionDataJson.rating !== '' ? hotelSessionDataJson.rating : 6;
            }
        }
    }

    const refDestination = useRef(null);
    useOutsideAlerter(refDestination);

    var fromTodayDate = new Date();
    fromTodayDate.setDate(fromTodayDate.getDate() + 14);

    var toTodayDate = new Date();
    toTodayDate.setDate(toTodayDate.getDate() + 15);

    const { sendRequest: sendRequestChainCode, statusChainCode, data: loadedChainCode } = useHttp(SearchHotelChain);
    const { sendRequest: sendRequestOfficeWisePCC, status: statusOfficeWisePCC, data: loadedOfficeWisePCC } = useHttp(getPccList);
    const { sendRequest: sendRequestGetRatePlan, statusRequestGetRatePlan, data: loadedRequestGetRatePlan } = useHttp(getRatePlanList);
    const { sendRequest: sendRequestRequestGetRatePlanPCCWise, statusGetRatePlanPCCWise, data: loadedGetRatePlanPCCWise } = useHttp(getRatePlanPCCWiseList);
    const { sendRequest: sendRequestExclusiveDeals, statusExclusiveDeals, data: loadedExclusiveDeals } = useHttp(SearchHotelExclusiveDeals);
    const { sendRequest: sendHotelName, status: statusHotelName, data: loadedHotelName } = useHttp(SearchHotelName);

    let sortOptions = [];
    sortOptions = [
        { value: '1', label: 'Price' },
        { value: '2', label: 'Hotel Name' }
    ];

    if (sessionStorage.getItem("hotelSessionDataJson") === null) {
        const hotelSessionDataJson = ({
            "txtDestination": {},
            "spnDestinationAirportDetails": {},
            "calCheckInDateDB": {},
            "calCheckOutDateDB": {},
            "calCheckIn": {},
            "calCheckOut": {},
            "guests": {},
            "rooms": {},
            "brands": {},
            "preferenceOptions": {},
            "roomGuests": {},
            "brandsData": {},
            "providerCode": {},
            "HotelName": {},
            "providerCodeData": {},
            "Radius": {},
            "Source": {},
            "ratePlanCode": {},
            "ratePlanCodeData": {},
            "pccOfficeWiseCodeData": {},
            "CityCode": {},
            "rating": {}
        });
        sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionDataJson));
    }

    if (sessionStorage.getItem("hotelSessionDataJson") !== null) {
        let hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));

        if (Object.keys(hotelSessionJson.calCheckIn).length > 0) {
            let minToDateChangeTemp = new Date(hotelSessionJson.calCheckIn);
            minToDateChangeTemp.setDate(minToDateChangeTemp.getDate() + 1);
            sessionStorage.setItem("InitialLoadCheckOutMinvalue", moment(new Date(minToDateChangeTemp)).format("ddd, MMM DD, 'YY"));
        }
        else {
            let minToDateChangeTemp = new Date(fromTodayDate);
            minToDateChangeTemp.setDate(minToDateChangeTemp.getDate() + 1);
            sessionStorage.setItem("InitialLoadCheckOutMinvalue", moment(new Date(minToDateChangeTemp)).format("ddd, MMM DD, 'YY"));
        }

        if (valueParam.addhotel !== "1") {
            sessionStorage.removeItem("singlePnrCheckOutMinvalue");
        }
    }

    useEffect(() => {
        // User Story 5108: Show Expedia content
        if (getConfigurationByBoolen('ALLOW_SOURCE_PCC_FARE')) {
            if (getConfigurationByBoolen('ALLOW_TAAP') && getConfigurationByBoolen('ALLOW_GTB')) {
                setSourceOptions(SourceOptionsWithAll);
                setDefaultSource(['100', '110', '113'])

            } else if (getConfigurationByBoolen('ALLOW_TAAP') && !getConfigurationByBoolen('ALLOW_GTB')) {
                setSourceOptions(SourceOptionsWithTaap);
                setDefaultSource(['100', '110'])

            } else if (!getConfigurationByBoolen('ALLOW_TAAP') && getConfigurationByBoolen('ALLOW_GTB')) {
                setSourceOptions(SourceOptionsWithCGN);
                setDefaultSource(['100', '113'])
            }
        }

        if (secureLocalStorage.getItem("searchRoeResult") === null) {
            const onSearchRoe = ({
                fromCurrency: "",
                toCurrency: ""
            });

            sendRequestRoe(onSearchRoe);
        }

    }, [])

    if (SourceOptions.length > 0) {
        SourceOptions.sort((a, b) => {
            if (a.label < b.label) return -1;
            if (a.label > b.label) return 1;
            return 0;
        });
    }

    useEffect(() => {
        if (localStorage.getItem("airportSuggestion") === null || localStorage.getItem("airportSuggestion").length < 3) {
            let airportSuggestionResutTemp = [];
            fetch(GET_ALL_AIRPORT_LIST(), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('idToken'),
                },
            }).then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson['data'] !== null) {
                        responseJson['data'].forEach(element2 => {
                            airportSuggestionResutTemp.push(element2);
                        });
                    }
                    localStorage.setItem("airportSuggestion", JSON.stringify(airportSuggestionResutTemp));
                });
        }

        if (localStorage.getItem("blockedCountryCity") === null) {
            let blockedCountryCityListTemp = [];
            fetch(GET_BLOCK_COUNTRY_CITY_LIST(), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('idToken'),
                },
            }).then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson['data'] !== null) {
                        responseJson['data'].forEach(element2 => {
                            blockedCountryCityListTemp.push(element2);
                        });
                    }
                    localStorage.setItem("blockedCountryCity", JSON.stringify(blockedCountryCityListTemp));
                });
        }

        if (sessionStorage.getItem('showAdanced') !== null) {
            if (sessionStorage.getItem('showAdanced') === 'true') {
                setIsAdvanced(true);
            } else {
                setIsAdvanced(false);
            }
        }

        if (localStorage.getItem('isCorporate') !== null && localStorage.getItem('isCorporate') === 'true' && (valueParam.travelreq === undefined || valueParam.travelreq === null)) {
            history.push('/TravelRequest')
        }

        setRatingValue(defaultRating);

    }, [])

    //put another condition to avoid rehit for search on page reload for CBT
    useEffect(() => {
        if (valueParam.hotel === "partial" && sessionStorage.getItem("isHotelPartialBlockExecuted") === null) {
            sessionStorage.setItem("isHotelPartialBlockExecuted", 1);
            //User Story 1070: Change in Search for Single PNR
            if (localStorage.getItem("AddHotel") !== null && localStorage.getItem("AddHotel") === "1") { }
            else {
                localStorage.setItem('traceID', JSON.stringify(uuidv4()));
            }

            let blockedCountryCity = [];
            blockedCountryCity = localStorage.getItem("blockedCountryCity") !== null ? JSON.parse(localStorage.getItem("blockedCountryCity")) : '';

            window.scrollTo({
                top: 50,
                behavior: "smooth",
            });

            setFirstTime(true);
            setIsSubmitClicked(true);
            setPageFocus(true);

            let hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));


            let checkInDate = hotelSessionJson.calCheckInDateDB;
            let checkOutDate = hotelSessionJson.calCheckOutDateDB;


            if (parseInt(checkOutDate) < parseInt(checkInDate)) {
                setError({
                    title: "Message",
                    message: `${t('Check-out cannot be less than Check-in.')}`,
                });
                return;
            }

            if (blockedCountryCity.length !== 0) {
                let selectedDestinationCity = hotelSessionJson.spnDestinationAirportDetails.split('-')[0].trim();
                let selectedDestinationCountry = hotelSessionJson.spnDestinationAirportDetails.split(',')[2].trim();

                const found = blockedCountryCity.find(el => el.code === selectedDestinationCity || el.code === selectedDestinationCountry);
                if (found) {
                    if (found.service === 'HT' || found.service === 'BOTH') {
                        setError({
                            title: "Message",
                            message: `${t('Sorry, search for this destination is not allowed. Please contact the Support desk.')}`,
                        });
                        return;
                    }
                }
            }

            let roomGuests = [];
            for (let index = 1; index <= parseInt(roomsValue !== undefined && roomsValue !== '' ? roomsValue : 1); index++) {
                roomGuests.push({
                    "RoomIndex": parseInt(index),
                    "NoOfAdults": parseInt(document.querySelector('#dvGuests') !== null && !document.querySelector('#dvGuests').classList.contains('d-none') ? guestsValue !== undefined && guestsValue !== '' ? guestsValue : hotelSessionJson.guests !== undefined && hotelSessionJson.guests !== null && hotelSessionJson.guests !== '' ? hotelSessionJson.guests : 1 : 1),
                    "NoOfChild": 0,
                    "ChildAge": [
                        0
                    ]
                });
            }
            let enteredRadius = parseInt(hotelSessionJson.Radius);
            if (enteredRadius === 0 || enteredRadius > 200) {
                setHotelNameError({
                    title: "Message",
                    message: "Please enter Radius (Miles) between 1 and 200."
                });
                return;

            }
            let tempSource = [];
            if (Object.keys(hotelSessionJson.Source).length > 0) {
                if (hotelSessionJson.Source === '000') {
                    if (getConfigurationByBoolen('ALLOW_TAAP') && getConfigurationByBoolen('ALLOW_GTB')) {
                        tempSource.push('100', '113', '110');
                    } else if (getConfigurationByBoolen('ALLOW_TAAP') && !getConfigurationByBoolen('ALLOW_GTB')) {
                        tempSource.push('100', '110');
                    } else {
                        tempSource.push('100', '113');
                    }
                }
                else {
                    tempSource.push(hotelSessionJson.Source)
                }
            }
            let enteredHotelName = hotelSessionJson.HotelName;
            // Regular expression for alphanumeric characters, comma, space, /, and -
            const regex = /^[a-zA-Z0-9,\/ \-]*$/;
            if (enteredHotelName !== '' && Object.keys(enteredHotelName).length !== 0 && !regex.test(enteredHotelName)) {
                setHotelNameError({
                    title: "Message",
                    message: "Please enter valid Hotel Name."
                });
                return;
            }

            //changes for Exclusive Deals to get only exclusive deals
            const onSearchHotelInitilal = (
                {
                    "OfficeID": JSON.parse(localStorage.getItem('officeID')),
                    "Data": {
                        "RequestDetails": {
                            "TraceId": JSON.parse(localStorage.getItem('traceID')),
                            "PrimaryLanguageId": "EN"

                        },
                        "POS": null,
                        "CheckInDate": {
                            "Date": hotelSessionJson.calCheckInDateDB
                        },
                        "CheckOutDate": {
                            "Date": hotelSessionJson.calCheckOutDateDB

                        },
                        "NoOfNights": 0,
                        "Country": {
                            "Name": "India",
                            "Code": "IN",
                            "Id": 0
                        },
                        "City": {
                            "Code": hotelSessionJson.spnDestinationAirportDetails.split('-')[0].trim().toUpperCase(),
                            "CityCode": hotelSessionJson.CityCode,
                            "Type": "Airport"
                        },
                        "NoOfRooms": parseInt(roomsValue !== undefined && roomsValue !== '' ? roomsValue : 1),
                        "RoomGuests": roomGuests,
                        "Filters": {
                            "ChainCode": Object.keys(hotelSessionJson.brands).length > 0 ? hotelSessionJson.brands : null,
                            "ProviderCode": Object.keys(hotelSessionJson.ratePlanCode).length > 0 ? hotelSessionJson.ratePlanCode : null,
                            "HotelName": Object.keys(hotelSessionJson.HotelName).length > 0 ? hotelSessionJson.HotelName : null,
                            'ProviderSourceCode': Object.keys(hotelSessionJson.Source).length > 0 ? tempSource : defaultSource,
                            "OnlyProviderCodeResult": document.querySelector('#exclusiveDeals1') !== null ? document.querySelector('#exclusiveDeals1').checked : false,

                        },
                        "Radius": Object.keys(hotelSessionJson.Radius).length > 0 ? hotelSessionJson.Radius : null,
                        "IsPagingEnabled": false,
                        "IsNearBySearchAllowed": false,
                        "selectedPCC": Object.keys(hotelSessionJson.pccOfficeWiseCodeData).length > 0 ? hotelSessionJson.pccOfficeWiseCodeData : "",
                        "RefundableOnly": getConfigurationByBoolen("ALLOW_REFUNDABLE_ONLY") !== null && getConfigurationByBoolen("ALLOW_REFUNDABLE_ONLY") === true ? true : Object.keys(hotelSessionJson.preferenceOptions).length > 0 ? hotelSessionJson.preferenceOptions.filter(a => a.preferenceOptionId === 'refundableOnly').length > 0 ? true : false : false,
                        "LuxuryHotels": Object.keys(hotelSessionJson.preferenceOptions).length > 0 ? hotelSessionJson.preferenceOptions.filter(a => a.preferenceOptionId === 'luxuryHotelsOnly').length > 0 ? true : false : false,
                        "ExclusiveDeals": Object.keys(hotelSessionJson.preferenceOptions).length > 0 ? hotelSessionJson.preferenceOptions.filter(a => a.preferenceOptionId === 'exclusiveDeals').length > 0 ? true : false : false,
                        //"PageSize": 20,
                        "ImageSize": "LARGE",
                        "PrepaidQualifier": "IncludePrepaid",
                        "IsApplyFilters": false,
                        "getMoreResult": [
                            {
                                "sourceId": "1S",
                                "moreResult": {
                                    "token": "",
                                    "pageNumber": 1
                                }
                            }
                        ],
                        "policyApplyID": getPolicyID(),
                        "tripReason": travelRequestFieldsDataJson.tripReason !== null ? travelRequestFieldsDataJson.tripReason : '',
                    }
                }
            );
            sessionStorage.removeItem("HotelIntialTotalAmount");
            sessionStorage.setItem("hotelSearchRequestJson", JSON.stringify(onSearchHotelInitilal));

            if (document.querySelector('#calCheckIn').value !== document.querySelector('#calCheckOut').value) {
                sendRequest(onSearchHotelInitilal);
            }
            else {

                setError({
                    title: "Message",
                    message: `${t('Check-in and Check-out cannot be same.')}`,
                });
                return;
            }
        }
    }, [])

    useEffect(() => {
        if (sessionStorage.getItem("hotelSessionDataJson") !== null) {
            let hotelJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
            if (Object.keys(hotelJson.HotelName).length !== 0) {
                setSearchString(hotelJson.HotelName)
            }
            if (Object.keys(hotelJson.HotelName).length == 0) {
                setSearchString('')
            }
        }
    }, [sendRequest])

    //changes for Exclusive Deals to get only exclusive deals
    useEffect(() => {
        if (ratePlanCode.length === 0 && userChangedExclDeals === false && ratePlan.length !== 0) {
            setExclusiveDealsDisable(false)

            //removed "XGZ,ZGK" in case of "TQV" selection
            let temp = [];
            ratePlan.forEach(element => {
                temp.push(element.value);
            });

            hotelSessionJson.ratePlanCode = temp;
            hotelSessionJson.ratePlanCodeData = ratePlan;
            sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
            setRatePlanCode(ratePlan);
        }
    })

    function useOutsideAlerter(ref) {
        useEffect(() => {
            if (document.querySelector('[class="rmdp-input"]') !== null) {
                var controlCount = document.querySelectorAll('[class="rmdp-input"]');
                controlCount.forEach(element => {
                    element.style.display = "none";
                });
            }

            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    if (ref.current.innerText === "Destination") {
                        document.querySelector('#dvDestination').style.display = "none";
                        document.querySelector('#txtDestination').style.display = "";
                        document.querySelector('#spnDestinationAirportDetails').style.display = "";
                    }
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }


    const handleOnClickDestination = () => {
        document.querySelector("#txtDestination").style.display = "none";
        document.querySelector("#dvDestination").style.display = "";
        document.querySelector('#spnDestinationAirportDetails').style.display = "none";
        if (document.querySelector('[data-test="search-input"]') !== null) {
            var controlCount = document.querySelectorAll('[data-test="search-input"]');
            if (controlCount[0] !== undefined) {
                controlCount[0].focus();
            }
        }
    }

    const handleOnDestinationSearch = (input) => {
        if (input.length > 0) {
            if (localStorage.getItem("airportSuggestion") != null) {
                setItems(JSON.parse(localStorage.getItem("airportSuggestion")));
            }
        }
    }

    const formatResult = (item) => {
        return (
            <>
                <span>{item.cityName}, {item.countryName} <br /> {item.airportCode} - {item.airportName}</span>
            </>
        )
    }

    function allowOnlyNumbers(event) {
        var input = event.target.value;
        var pattern = /^[1-9]\d{0,2}$/;
        if (!pattern.test(input)) {
            event.target.value = input.slice(0, 3);
        }

    }

    const handleOnSelectDestination = (item) => {
        // User Story 3395: New search criteria - "Hotel Name"
        // setCityCode(item.airportCode);
        //hotelSessionJson.HotelName = {};
        let hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
        hotelSessionJson.txtDestination = item.cityName;
        hotelSessionJson.spnDestinationAirportDetails = item.airportCode + " - " + item.airportName + ", " + item.countryName + ", " + item.countryCode;
        hotelSessionJson.CityCode = item.cityCode;

        sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));


        document.querySelector("#txtDestination").style.display = "";
        document.querySelector('#txtDestination').value = item.cityName;
        document.querySelector("#dvDestination").style.display = "none";
        document.querySelector('#spnDestinationAirportDetails').style.display = "";
        document.querySelector('#spnDestinationAirportDetails').innerHTML = item.airportCode + " - " + item.airportName + ", " + item.countryName + ", " + item.countryCode;
        // User Story 3395: New search criteria - "Hotel Name"
        // if (document.querySelector('.clear-icon') !== null) {
        //     var controlCount = document.querySelectorAll('.clear-icon');
        //     if (controlCount[1] !== undefined) {
        //         controlCount[1].click();
        //         // ✅ Remove focus from the currently active element
        //         document.activeElement.blur();

        //     }

        // }

    }
    // User Story 3395: New search criteria - "Hotel Name"
    //     useEffect(() => {
    //         if (sessionStorage.getItem('hotelSessionDataJson') !== null) {
    //             let hotelJson = JSON.parse(sessionStorage.getItem('hotelSessionDataJson'));
    //             if (Object.keys(hotelJson.spnDestinationAirportDetails).length !== 0) {
    //                 let cityCode = hotelJson.spnDestinationAirportDetails.split('-')[0];
    //                 if (cityCode.trim !== '') {
    //                     setCityCode(cityCode.trim())
    //                     sendHotelName({
    //                         "code": cityCode.trim(),
    //                     })
    //                 }
    //             }
    //         }



    //     }, [cityCode])
    //  // User Story 3395: New search criteria - "Hotel Name"
    //     useEffect(() => {
    //         let hotelName = []
    //         if (statusHotelName === 'completed') {
    //             for (let i in loadedHotelName) {
    //                 if (loadedHotelName['errors'].status === 'FALSE') {
    //                     if (i === 'data') {
    //                         for (let index = 0; index < loadedHotelName[i].length; index++) {
    //                             hotelName = [...hotelName, { HotelName: loadedHotelName[i][index].hotelName }]
    //                         }
    //                     }
    //                 }
    //             }
    //             setHotelNameList(hotelName)
    //         }
    //     }, [statusHotelName])




    //let noOfRooms = 0;
    const onSearchHandler = useCallback((event) => {
        //User Story 1070: Change in Search for Single PNR
        if (localStorage.getItem("AddHotel") !== null && localStorage.getItem("AddHotel") === "1") { }
        else {
            localStorage.setItem('traceID', JSON.stringify(uuidv4()));
        }

        let blockedCountryCity = [];
        blockedCountryCity = localStorage.getItem("blockedCountryCity") !== null ? JSON.parse(localStorage.getItem("blockedCountryCity")) : '';

        window.scrollTo({
            top: 50,
            behavior: "smooth",
        });

        event.preventDefault();
        setFirstTime(true);
        setIsSubmitClicked(true);
        setPageFocus(true);

        let hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));


        let checkInDate = hotelSessionJson.calCheckInDateDB;
        let checkOutDate = hotelSessionJson.calCheckOutDateDB;


        if (parseInt(checkOutDate) < parseInt(checkInDate)) {
            setError({
                title: "Message",
                message: `${t('Check-out cannot be less than Check-in.')}`,
            });
            return;
        }

        if (blockedCountryCity.length !== 0) {
            let selectedDestinationCity = hotelSessionJson.spnDestinationAirportDetails.split('-')[0].trim();
            let selectedDestinationCountry = hotelSessionJson.spnDestinationAirportDetails.split(',')[2].trim();

            const found = blockedCountryCity.find(el => el.code === selectedDestinationCity || el.code === selectedDestinationCountry);
            if (found) {
                if (found.service === 'HT' || found.service === 'BOTH') {
                    setError({
                        title: "Message",
                        message: `${t('Sorry, search for this destination is not allowed. Please contact the Support desk.')}`,
                    });
                    return;
                }
            }
        }

        let roomGuests = [];
        for (let index = 1; index <= parseInt(roomsValue); index++) {
            roomGuests.push({
                "RoomIndex": parseInt(index),
                "NoOfAdults": parseInt(document.querySelector('#dvGuests') !== null && !document.querySelector('#dvGuests').classList.contains('d-none') ? guestsValue !== undefined && guestsValue !== '' ? guestsValue : hotelSessionJson.guests !== undefined && hotelSessionJson.guests !== null && hotelSessionJson.guests !== '' ? hotelSessionJson.guests : 1 : 1),
                "NoOfChild": 0,
                "ChildAge": [
                    0
                ]
            });
        }
        let enteredRadius = parseInt(hotelSessionJson.Radius);
        if (enteredRadius === 0 || enteredRadius > 200) {
            setHotelNameError({
                title: "Message",
                message: "Please enter Radius (Miles) between 1 and 200."
            });
            return;

        }
        let tempSource = [];
        if (Object.keys(hotelSessionJson.Source).length > 0) {
            if (hotelSessionJson.Source === '000') {
                if (getConfigurationByBoolen('ALLOW_TAAP') && getConfigurationByBoolen('ALLOW_GTB')) {
                    tempSource.push('100', '113', '110');
                } else if (getConfigurationByBoolen('ALLOW_TAAP') && !getConfigurationByBoolen('ALLOW_GTB')) {
                    tempSource.push('100', '110');
                } else {
                    tempSource.push('100', '113');
                }
            }
            else {
                tempSource.push(hotelSessionJson.Source)
            }
        }
        let enteredHotelName = hotelSessionJson.HotelName;
        // Regular expression for alphanumeric characters, comma, space, /, and -
        const regex = /^[a-zA-Z0-9,\/ \-]*$/;
        if (enteredHotelName !== '' && Object.keys(enteredHotelName).length !== 0 && !regex.test(enteredHotelName)) {
            setHotelNameError({
                title: "Message",
                message: "Please enter valid Hotel Name."
            });
            return;
        }

        let travelRequestFieldsDataJson = "";
        if (sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
            travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
        }

        //changes for Exclusive Deals to get only exclusive deals
        const onSearchHotelInitilal = (
            {
                "OfficeID": JSON.parse(localStorage.getItem('officeID')),
                "Data": {
                    "RequestDetails": {
                        "TraceId": JSON.parse(localStorage.getItem('traceID')),
                        "PrimaryLanguageId": "EN"

                    },
                    "POS": null,
                    "CheckInDate": {
                        "Date": hotelSessionJson.calCheckInDateDB
                    },
                    "CheckOutDate": {
                        "Date": hotelSessionJson.calCheckOutDateDB

                    },
                    "NoOfNights": 0,
                    "Country": {
                        "Name": "India",
                        "Code": "IN",
                        "Id": 0
                    },
                    "City": {
                        "Code": hotelSessionJson.spnDestinationAirportDetails.split('-')[0].trim().toUpperCase(),
                        "CityCode": hotelSessionJson.CityCode,
                        "Type": "Airport"
                    },
                    "NoOfRooms": parseInt(roomsValue),
                    "RoomGuests": roomGuests,
                    "Filters": {
                        "ChainCode": Object.keys(hotelSessionJson.brands).length > 0 ? hotelSessionJson.brands : null,
                        "ProviderCode": Object.keys(hotelSessionJson.ratePlanCode).length > 0 ? hotelSessionJson.ratePlanCode : null,
                        "HotelName": Object.keys(hotelSessionJson.HotelName).length > 0 ? hotelSessionJson.HotelName : null,
                        'ProviderSourceCode': Object.keys(hotelSessionJson.Source).length > 0 ? tempSource : defaultSource,
                        "OnlyProviderCodeResult": document.querySelector('#exclusiveDeals1') !== null ? document.querySelector('#exclusiveDeals1').checked : false,

                    },
                    "Radius": Object.keys(hotelSessionJson.Radius).length > 0 ? hotelSessionJson.Radius : null,
                    "IsPagingEnabled": false,
                    "IsNearBySearchAllowed": false,
                    "selectedPCC": Object.keys(hotelSessionJson.pccOfficeWiseCodeData).length > 0 ? hotelSessionJson.pccOfficeWiseCodeData : "",
                    "RefundableOnly": getConfigurationByBoolen("ALLOW_REFUNDABLE_ONLY") !== null && getConfigurationByBoolen("ALLOW_REFUNDABLE_ONLY") === true ? true : Object.keys(hotelSessionJson.preferenceOptions).length > 0 ? hotelSessionJson.preferenceOptions.filter(a => a.preferenceOptionId === 'refundableOnly').length > 0 ? true : false : false,
                    "LuxuryHotels": Object.keys(hotelSessionJson.preferenceOptions).length > 0 ? hotelSessionJson.preferenceOptions.filter(a => a.preferenceOptionId === 'luxuryHotelsOnly').length > 0 ? true : false : false,
                    "ExclusiveDeals": Object.keys(hotelSessionJson.preferenceOptions).length > 0 ? hotelSessionJson.preferenceOptions.filter(a => a.preferenceOptionId === 'exclusiveDeals').length > 0 ? true : false : false,
                    //"PageSize": 20,
                    "ImageSize": "LARGE",
                    "PrepaidQualifier": "IncludePrepaid",
                    "IsApplyFilters": false,
                    "getMoreResult": [
                        {
                            "sourceId": "1S",
                            "moreResult": {
                                "token": "",
                                "pageNumber": 1
                            }
                        }
                    ],
                    "policyApplyID": getPolicyID(),
                    "tripReason": travelRequestFieldsDataJson.tripReason !== null ? travelRequestFieldsDataJson.tripReason : '',
                }
            }
        );
        sessionStorage.removeItem("HotelIntialTotalAmount");
        sessionStorage.setItem("hotelSearchRequestJson", JSON.stringify(onSearchHotelInitilal));

        if (document.querySelector('#calCheckIn').value !== document.querySelector('#calCheckOut').value) {
            sendRequest(onSearchHotelInitilal);
        }
        else {

            setError({
                title: "Message",
                message: `${t('Check-in and Check-out cannot be same.')}`,
            });
            return;
        }

    }, [sendRequest, roomsValue, guestsValue])

    const errorHandler = () => {
        if (valueParam.travelreq !== undefined && valueParam.travelreq !== null && valueParam.travelreq === 'true') {
            // setError(false);
            // setHotelNameError(null);
            // setPageFocus(false);
            // setloadedData([]);
            // //history.push('/TravelRequest?return=1&service=hotel');
            // //history.push('/TravelRequest');
            setError(false);
            setHotelNameError(null);
            setPageFocus(false);
            setloadedData([]);
            window.scrollTo({
                top: 50,
                behavior: "smooth",
            });
        }
        else {
            setError(false);
            setHotelNameError(null);
            setPageFocus(false);
            setloadedData([]);
            window.scrollTo({
                top: 50,
                behavior: "smooth",
            });
        }
    };

    //let loadedData = [];
    const [loadedData, setloadedData] = useState([]);
    useEffect(() => {
        for (let i in loadedHotelSearch) {
            if (loadedHotelSearch.data.errors.status === 'FALSE') {
                var arrayTemp = [];
                loadedHotelSearch.data.hotelResults.forEach(element1 => {
                    const found = arrayTemp.find(el => el.sequenceId === element1.sequenceId);
                    if (!found) {
                        arrayTemp.push({
                            roomPrice: parseFloat(element1.price[0].offeredPrice.perNight),
                            sequenceId: element1.sequenceId
                        });
                    }
                });
                let list = sorter.sort(arrayTemp).asc("roomPrice");

                let hotelResultsTemp = [];
                list.forEach(element => {
                    hotelResultsTemp.push(
                        loadedHotelSearch.data.hotelResults.filter(x => x.sequenceId === element.sequenceId)[0]
                    );
                });
                const hotelResponse = (
                    {
                        "data": {
                            "requestDetails": loadedHotelSearch.data.requestDetails,
                            "hotelResults": hotelResultsTemp,
                            "getMoreResult": loadedHotelSearch.data.getMoreResult
                        }
                    });

                setloadedData(hotelResponse);
                sessionStorage.setItem("hotelSearchResponse", JSON.stringify(hotelResponse));
            }
            else {
                setloadedData([]);
            }
        }
    }, [loadedHotelSearch])

    if (status === 'completed') {
        let hotelSearchRequestJson = JSON.parse(sessionStorage.getItem('hotelSearchRequestJson'));

        if (loadedHotelSearch !== null && loadedHotelSearch.data !== null && loadedHotelSearch.data.errors.status === 'FALSE' && Object.keys(loadedData).length > 0) {
            let hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
            loadedData.data.hotelResults.forEach(hotelResultsElement => {
                if (hotelResultsElement.hotelCheckInPolicy !== undefined && hotelResultsElement.hotelCheckInPolicy !== null) {
                    hotelResultsElement.hotelCheckInPolicy[1].date = hotelSessionJson.calCheckInDateDB;
                    hotelResultsElement.hotelCheckInPolicy[0].date = hotelSessionJson.calCheckOutDateDB;
                }
                else if (hotelResultsElement.hotelCheckInPolicy === undefined || hotelResultsElement.hotelCheckInPolicy === null) {
                    let hotelCheckInPolicy = [];
                    hotelCheckInPolicy.push({
                        type: "CheckOut",
                        date: hotelSessionJson.calCheckOutDateDB
                    });
                    hotelCheckInPolicy.push({
                        type: "CheckIn",
                        date: hotelSessionJson.calCheckInDateDB
                    });
                    hotelResultsElement.hotelCheckInPolicy = hotelCheckInPolicy;
                }
                hotelResultsElement.noOfRooms = hotelSearchRequestJson.Data.NoOfRooms.toString();
                hotelResultsElement.NoOfAdults = hotelSearchRequestJson.Data.RoomGuests[0].NoOfAdults.toString();
            });
        }
    }

    let hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
    //Code for find List PCC, Rate Plan or Rate Plan code PCC wise
    //Call PCC
    useEffect(() => {
        const onSearchPCC = ({
            "id": "",
            "userID": "",
            "serviceID": "21",
            "officeID": JSON.parse(localStorage.getItem('officeID')),
            "status": 2
        });

        getConfigurationByBoolen("ALLOW_EXCLUSIVE_DEALS_PCC_WISE") && sendRequestOfficeWisePCC(onSearchPCC);
    }, [sendRequestOfficeWisePCC])


    let officeWisePCC = [];
    for (let i in loadedOfficeWisePCC) {
        if (loadedOfficeWisePCC['errors'].status === 'FALSE') {
            if (i === 'data') {
                for (let index = 0; index < loadedOfficeWisePCC[i].length; index++) {
                    officeWisePCC = [...officeWisePCC, { value: loadedOfficeWisePCC[i][index].pcc, label: loadedOfficeWisePCC[i][index].pcc }]
                }
                hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
            }
        }
    }

    useEffect(() => {
        if (isFirtTime === "" && officeWisePCC.length > 0) {
            setOfficeWisePCCCode(officeWisePCC[0].value)
            searchRatePlanPCCWise(officeWisePCC[0].value);
            setFirstTime(true);
        }
    }, [officeWisePCC, isFirtTime])


    //Call RatePlan
    useEffect(() => {
        const onSearchRatePlan = ({
            "default": true
        });

        !getConfigurationByBoolen("ALLOW_EXCLUSIVE_DEALS_PCC_WISE") && sendRequestGetRatePlan(onSearchRatePlan);
    }, [statusRequestGetRatePlan])

    let ratePlan = [];
    for (let i in loadedRequestGetRatePlan) {
        if (loadedRequestGetRatePlan['errors'].status === 'FALSE') {
            if (i === 'data') {
                for (let index = 0; index < loadedRequestGetRatePlan[i].length; index++) {
                    ratePlan = [...ratePlan, { value: loadedRequestGetRatePlan[i][index].gtCode, label: loadedRequestGetRatePlan[i][index].gtName }]
                }
            }
        }
    }

    //Call RatePlan PCC Wise
    const searchRatePlanPCCWise = (id) => {
        const onSearchRatePlanPCCWise = ({
            "officeID": JSON.parse(localStorage.getItem('officeID')),
            "pcc": id
        });

        sendRequestRequestGetRatePlanPCCWise(onSearchRatePlanPCCWise);
    }


    for (let i in loadedGetRatePlanPCCWise) {
        if (loadedGetRatePlanPCCWise['errors'].status === 'FALSE') {
            if (i === 'data') {
                for (let index = 0; index < loadedGetRatePlanPCCWise[i].length; index++) {
                    ratePlan = [...ratePlan, { value: loadedGetRatePlanPCCWise[i][index].gtCode, label: loadedGetRatePlanPCCWise[i][index].gtName }]
                }
            }
        }
    }

    useEffect(() => {
        if (exclusiveDealsChecked === true && loadedGetRatePlanPCCWise !== null && loadedGetRatePlanPCCWise['errors'] !== null && loadedGetRatePlanPCCWise['errors'].status === 'FALSE') {
            hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
            // hotelSessionJson.ratePlanCodeData = ratePlanCode;            
            hotelSessionJson.ratePlanCodeData = ratePlan;

            //removed "XGZ,ZGK" in case of "TQV" selection
            let temp = [];
            ratePlan.forEach(element => {
                temp.push(element.value);
            });

            hotelSessionJson.ratePlanCode = temp;
            sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
            setRatePlanCode(ratePlan);
        }
    }, [loadedGetRatePlanPCCWise, statusGetRatePlanPCCWise])

    let bookingJSON = JSON.parse(localStorage.getItem("BookingData"));

    useEffect(() => {
        sessionStorage.setItem("myTripIsCanceled", '');
        sessionStorage.setItem("myTripIsHotelCancelled", '');
        window.scrollTo({
            top: 50,
            behavior: "smooth",
        });

        if (document.querySelector('#allHotels') !== null) {
            document.querySelector('#allHotels').checked = true;
            setBranDisabled(false);
        }

        if (document.querySelector("#lblFlights") !== null) {
            document.querySelector("#lblFlights").classList.remove("flight-active-tab");
            document.querySelector("#lblFlights").classList.add("text-primary");
        }

        document.querySelector("#lblHotel").classList.remove("text-primary");
        document.querySelector("#lblHotel").classList.add("flight-active-tab");

        if (document.querySelector("#lblFlightPlusHotel") !== null) {
            document.querySelector("#lblFlightPlusHotel").classList.remove("flight-active-tab");
            document.querySelector("#lblFlightPlusHotel").classList.add("text-primary");
        }
        else {
            if (document.querySelector("#lblFlights") !== null) {
                document.querySelector("#lblFlights").classList.remove("flight-active-tab");
                document.querySelector("#lblFlights").classList.add("text-primary");
            }

            document.querySelector("#lblHotel").classList.remove("text-primary");
            document.querySelector("#lblHotel").classList.add("flight-active-tab");
        }

        if (document.querySelector("#lblSightseeing") !== null) {
            document.querySelector("#lblSightseeing").classList.remove("flight-active-tab");
            document.querySelector("#lblSightseeing").classList.add("text-primary");
        }

        if (document.querySelector("#lblHolidays") !== null) {
            document.querySelector("#lblHolidays").classList.remove("flight-active-tab");
            document.querySelector("#lblHolidays").classList.add("text-primary");
        }
        if (document.querySelector("#lblVisa") !== null) {
            document.querySelector("#lblVisa").classList.add("text-primary");
            document.querySelector("#lblVisa").classList.remove("flight-active-tab");
        }

        if (valueParam.addhotel !== "1" || valueParam.addhotel === undefined) {
            localStorage.removeItem("AddHotel");
            sessionStorage.removeItem("singlePnrCheckOutMinvalue");
        }

        //User Story 1070: Change in Search for Single PNR
        if (localStorage.getItem("AddHotel") !== null && localStorage.getItem("AddHotel") === "1") {

            if (document.querySelector('#lblFlights') !== null) {
                document.querySelector('#lblFlights').classList.add("d-none");
            }
            if (document.querySelector('#lblFlightPlusHotel') !== null) {
                document.querySelector('#lblFlightPlusHotel').classList.add("d-none");
            }
            if (document.querySelector('#lblSightseeing') !== null) {
                document.querySelector('#lblSightseeing').classList.add("d-none");
            }
            if (document.querySelector('#lblHolidays') !== null) {
                document.querySelector('#lblHolidays').classList.add("d-none");
            }

            hotelSessionJson.calCheckIn = moment(new Date(sessionStorage.getItem("calFrom1").replace("'", ""))).format("ddd, MMM DD, 'YY");
            document.querySelector('#calCheckIn').value = moment(new Date(sessionStorage.getItem("calFrom1").replace("'", ""))).format("ddd, MMM DD, 'YY");
            const checkInDate = new Date(sessionStorage.getItem("calFrom1").replace("'", ""));
            var checkInDateDB = checkInDate.toLocaleDateString('en-gb').split('/').reverse().join('');
            hotelSessionJson.calCheckInDateDB = checkInDateDB;
            sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));

            hotelSessionJson.calCheckOut = moment(new Date(sessionStorage.getItem("calTo1").replace("'", ""))).format("ddd, MMM DD, 'YY");
            document.querySelector('#calCheckOut').value = moment(new Date(sessionStorage.getItem("calTo1").replace("'", ""))).format("ddd, MMM DD, 'YY");
            const checkOutDate = new Date(sessionStorage.getItem("calTo1").replace("'", ""));
            var checkOutDateDB = checkOutDate.toLocaleDateString('en-gb').split('/').reverse().join('');
            hotelSessionJson.calCheckOutDateDB = checkOutDateDB;
            sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));

            // let minToDateChangeTemp = new Date(sessionStorage.getItem("calFrom1").replace("'", ""));
            // minToDateChangeTemp.setDate(minToDateChangeTemp.getDate() + 1);
            // setMinToDateChange(new Date(minToDateChangeTemp));

            // // User Story 3395: New search criteria - "Hotel Name"
            // if (Object.keys(hotelSessionJson.spnDestinationAirportDetails).length !== 0) {
            //     let cityCode = hotelSessionJson.spnDestinationAirportDetails.split('-')[0];
            //     if (cityCode.trim !== '') {
            //         sendHotelName({
            //             "code": cityCode.trim(),
            //         })
            //     }

            // }
            setFirstTime(true);
            setIsSubmitClicked(true);
            setPageFocus(true);

            let guestCount = parseInt(bookingJSON.data.travelerInfo.traveler.filter(a => a.PassengerTypeCode === 'ADT' || a.PassengerTypeCode === 'CNN').length);
            let roomGuests = [];
            roomGuests.push({
                "RoomIndex": parseInt(1),
                "NoOfAdults": guestCount,
                "NoOfChild": 0,
                "ChildAge": [
                    0
                ]
            });

            let tempSource = [];
            tempSource.push(bookingJSON.data.airItinerary[0].SourceId === '1W' ? '100' : '113');
            if (!getConfigurationByBoolen('ALLOW_EXCLUSIVE_DEALS_PCC_WISE') || (statusOfficeWisePCC === 'completed' && loadedOfficeWisePCC !== null)) {
                let tempSource = [];
                if (Object.keys(hotelSessionJson.Source).length > 0) {
                    if (hotelSessionJson.Source === '000') {
                        if (getConfigurationByBoolen('ALLOW_TAAP') && getConfigurationByBoolen('ALLOW_GTB')) {
                            tempSource.push('100', '113', '110');
                        } else if (getConfigurationByBoolen('ALLOW_TAAP') && !getConfigurationByBoolen('ALLOW_GTB')) {
                            tempSource.push('100', '110');
                        } else {
                            tempSource.push('100', '113');
                        }
                    }
                    else {
                        tempSource.push(hotelSessionJson.Source)
                    }
                }
                let enteredHotelName = hotelSessionJson.HotelName;
                // Regular expression for alphanumeric characters, comma, space, /, and -
                const regex = /^[a-zA-Z0-9,\/ \-]*$/;
                if (enteredHotelName !== '' && Object.keys(enteredHotelName).length !== 0 && !regex.test(enteredHotelName)) {
                    setHotelNameError({
                        title: "Message",
                        message: "Please enter valid Hotel Name."
                    });
                    return;
                }

                let travelRequestFieldsDataJson = "";
                if (sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
                    travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
                }

                //changes for Exclusive Deals to get only exclusive deals
                const onSearchHotelInitilal = (
                    {
                        "OfficeID": JSON.parse(localStorage.getItem('officeID')),
                        "Data": {
                            "RequestDetails": {
                                "TraceId": JSON.parse(localStorage.getItem('traceID'))
                            },
                            "POS": null,
                            "CheckInDate": {
                                "Date": sessionStorage.getItem("calFromDateDB1")
                            },
                            "CheckOutDate": {
                                "Date": sessionStorage.getItem("calToDateDB1")

                            },
                            "NoOfNights": 0,
                            "Country": {
                                "Name": "India",
                                "Code": "IN",
                                "Id": 0
                            },
                            "City": {
                                "Code": sessionStorage.getItem("spnToAirportDetails1") !== null ? sessionStorage.getItem("spnToAirportDetails1").split('-')[0].trim().toUpperCase() : '',
                                "CityCode": sessionStorage.getItem("toCityCode1") !== null ? sessionStorage.getItem("toCityCode1").trim().toUpperCase() : '',
                                "Type": "Airport"
                            },
                            "NoOfRooms": parseInt(1),
                            "RoomGuests": roomGuests,
                            "Filters": {
                                "ChainCode": null,
                                "ProviderCode": Object.keys(hotelSessionJson.ratePlanCode).length > 0 ? hotelSessionJson.ratePlanCode : null,
                                //'ProviderSourceCode': ["100", "113"],
                                "HotelName": Object.keys(hotelSessionJson.HotelName).length > 0 ? hotelSessionJson.HotelName : null,
                                'ProviderSourceCode': Object.keys(hotelSessionJson.Source).length > 0 ? tempSource : defaultSource,
                                "OnlyProviderCodeResult": document.querySelector('#exclusiveDeals1') !== null ? document.querySelector('#exclusiveDeals1').checked : false,
                            },
                            "Radius": getConfigurationByValue('SABRE_HOTEL_RADIUS') !== null ? getConfigurationByValue('SABRE_HOTEL_RADIUS') : 30,
                            "IsPagingEnabled": false,
                            "IsNearBySearchAllowed": false,
                            "selectedPCC": Object.keys(hotelSessionJson.pccOfficeWiseCodeData).length > 0 ? hotelSessionJson.pccOfficeWiseCodeData : "",
                            "RefundableOnly": getConfigurationByBoolen("ALLOW_REFUNDABLE_ONLY") !== null && getConfigurationByBoolen("ALLOW_REFUNDABLE_ONLY") === true ? true : false,
                            "LuxuryHotels": false,
                            "ExclusiveDeals": false,
                            //"PageSize": 20,
                            "ImageSize": "LARGE",
                            "PrepaidQualifier": "IncludePrepaid",
                            "IsApplyFilters": false,
                            "getMoreResult": [
                                {
                                    "sourceId": "1S",
                                    "moreResult": {
                                        "token": "",
                                        "pageNumber": 1
                                    }
                                }
                            ],
                            "policyApplyID": getPolicyID(),
                            "tripReason": travelRequestFieldsDataJson.tripReason !== null ? travelRequestFieldsDataJson.tripReason : '',
                        }
                    }
                );

                sessionStorage.removeItem("HotelIntialTotalAmount");
                sessionStorage.setItem("hotelSearchRequestJson", JSON.stringify(onSearchHotelInitilal));
                sendRequest(onSearchHotelInitilal);
            }

        }

    }, [statusOfficeWisePCC, loadedOfficeWisePCC])

    useEffect(() => {
        if (status === 'completed') {
            if (loadedHotelSearch !== null && loadedHotelSearch.data !== null && loadedHotelSearch.data.errors.status === 'TRUE') {
                setError({
                    title: "Message",
                    message: `${t('ErrorMessage')}`,
                });
            }
        }

    }, [status, loadedHotelSearch])


    let lastPage = true;
    //Create New Method for movedown
    function onSearchMoveDownHandler() {
        setFirstTime(true);
        const onSearchHotelInitilal = JSON.parse(sessionStorage.getItem("hotelSearchRequestJson"));
        const getHotelSearchResponse = JSON.parse(sessionStorage.getItem("hotelSearchResponse"));
        if (getHotelSearchResponse.data.getMoreResult[0].moreResult.token !== "") {
            onSearchHotelInitilal.Data.getMoreResult[0].moreResult.token = getHotelSearchResponse.data.getMoreResult[0].moreResult.token;
            onSearchHotelInitilal.Data.getMoreResult[0].moreResult.pageNumber = onSearchHotelInitilal.Data.getMoreResult[0].moreResult.pageNumber + 1;
            sessionStorage.setItem("hotelSearchRequestJson", JSON.stringify(onSearchHotelInitilal));
            sendRequestMoveDown(onSearchHotelInitilal);
            setStatusMoveDownClick(true);
        }
    }

    let moveDownLoadData = [];
    useEffect(() => {
        if (statusMoveDown === 'completed' && statusMoveDownClick) {
            moveDownLoadData = loadedData;
            let count = loadedData.data.hotelResults.length;
            if (loadedHotelSearchMoveDown !== null && loadedHotelSearchMoveDown.data !== null && loadedHotelSearchMoveDown.data.errors.status === 'FALSE') {
                loadedHotelSearchMoveDown.data.hotelResults.forEach(hotelResultsElement => {
                    count = count + 1;
                    hotelResultsElement.sequenceId = count.toString();
                    moveDownLoadData.data.hotelResults.push(hotelResultsElement);
                })

                var arrayTemp = [];
                moveDownLoadData.data.hotelResults.forEach(element1 => {
                    const found = arrayTemp.find(el => el.sequenceId === element1.sequenceId);
                    if (!found) {
                        arrayTemp.push({
                            roomPrice: parseFloat(element1.price[0].offeredPrice.perNight),
                            sequenceId: element1.sequenceId
                        });
                    }
                });
                let list = sorter.sort(arrayTemp).asc("roomPrice");

                let hotelResultsTemp = [];
                list.forEach(element => {
                    hotelResultsTemp.push(
                        moveDownLoadData.data.hotelResults.filter(x => x.sequenceId === element.sequenceId)[0]
                    );
                });
                const hotelResponse = (
                    {
                        "data": {
                            "requestDetails": moveDownLoadData.data.requestDetails,
                            "hotelResults": hotelResultsTemp,
                            "getMoreResult": moveDownLoadData.data.getMoreResult
                        }
                    });

                setloadedData(hotelResponse)
                sessionStorage.setItem("hotelSearchResponse", JSON.stringify(hotelResponse));

                setStatusMoveDownClick(false);
            } else {

                lastPage = false;
            }
        }

    }, [statusMoveDown])

    function disableFilters() {
        document.querySelector('#applyPerPersonPrice').style.display = "none";
        if (document.querySelector('[data-test="search-input"]') !== null) {
            var controlCount = document.querySelectorAll('[data-test="search-input"]');
            controlCount.forEach(element => {
                element.disabled = true;
            });
        }
        var nodes = document.getElementById("brandsFilter").getElementsByTagName('*');
        for (var i = 0; i < nodes.length; i++) {
            nodes[i].disabled = true;
        }
        var inputs = document.querySelectorAll('.css-1hb7zxy-IndicatorsContainer');
        inputs.forEach(element => {
            element.style.display = "none";
        });
        var inputs = document.querySelectorAll('.css-tj5bde-Svg');
        inputs.forEach(element => {
            element.style.display = "none";
        });
        var inputs = document.querySelectorAll('.propertytypeoption');
        inputs.forEach(element => {
            element.disabled = true;
        });
        if (document.getElementById("exclusiveDealsFilter") !== null) {
            var nodes = document.getElementById("exclusiveDealsFilter").getElementsByTagName('*');
            for (var i = 0; i < nodes.length; i++) {
                nodes[i].disabled = true;
            }
        }
        var inputs = document.querySelectorAll('.css-1hb7zxy-IndicatorsContainer');
        inputs.forEach(element => {
            element.style.display = "none";
        });
        var inputs = document.querySelectorAll('.css-tj5bde-Svg');
        inputs.forEach(element => {
            element.style.display = "none";
        });
        var nodes = document.getElementById("contentFilter").getElementsByTagName('*');
        for (var i = 0; i < nodes.length; i++) {
            nodes[i].disabled = true;
        }
        var inputs = document.querySelectorAll('.css-1hb7zxy-IndicatorsContainer');
        inputs.forEach(element => {
            element.style.display = "none";
        });
        var inputs = document.querySelectorAll('.css-tj5bde-Svg');
        inputs.forEach(element => {
            element.style.display = "none";
        });
        var inputs = document.querySelectorAll('.refundabletypeoption');
        inputs.forEach(element => {
            element.disabled = true;
        });
        var inputs = document.querySelectorAll('.commissionoption');
        inputs.forEach(element => {
            element.disabled = true;
        });
    }

    function enableFilters() {
        if (document.querySelector('#applyPerPersonPrice') !== null) {
            document.querySelector('#applyPerPersonPrice').style.display = "";
        }
        if (document.querySelector('[data-test="search-input"]') !== null) {
            var controlCount = document.querySelectorAll('[data-test="search-input"]');
            controlCount.forEach(element => {
                element.disabled = false;
            });
        }
        if (document.getElementById("brandsFilter") !== null) {
            var nodes = document.getElementById("brandsFilter").getElementsByTagName('*');
            for (var i = 0; i < nodes.length; i++) {
                nodes[i].disabled = false;
            }
        }
        var inputs = document.querySelectorAll('.css-1hb7zxy-IndicatorsContainer');
        inputs.forEach(element => {
            element.style.display = "";
        });
        var inputs = document.querySelectorAll('.css-tj5bde-Svg');
        inputs.forEach(element => {
            element.style.display = "";
        });
        var inputs = document.querySelectorAll('.propertytypeoption');
        inputs.forEach(element => {
            element.disabled = false;
        });
        if (document.getElementById("exclusiveDealsFilter") !== null) {
            var nodes = document.getElementById("exclusiveDealsFilter").getElementsByTagName('*');
            for (var i = 0; i < nodes.length; i++) {
                nodes[i].disabled = false;
            }
        }
        var inputs = document.querySelectorAll('.css-1hb7zxy-IndicatorsContainer');
        inputs.forEach(element => {
            element.style.display = "";
        });
        var inputs = document.querySelectorAll('.css-tj5bde-Svg');
        inputs.forEach(element => {
            element.style.display = "";
        });
        if (document.getElementById("contentFilter") !== null) {
            var nodes = document.getElementById("contentFilter").getElementsByTagName('*');
            for (var i = 0; i < nodes.length; i++) {
                nodes[i].disabled = false;
            }
        }
        var inputs = document.querySelectorAll('.css-1hb7zxy-IndicatorsContainer');
        inputs.forEach(element => {
            element.style.display = "";
        });
        var inputs = document.querySelectorAll('.css-tj5bde-Svg');
        inputs.forEach(element => {
            element.style.display = "";
        });
        var inputs = document.querySelectorAll('.refundabletypeoption');
        inputs.forEach(element => {
            element.disabled = false;
        });
        var inputs = document.querySelectorAll('.commissionoption');
        inputs.forEach(element => {
            element.disabled = false;
        });
    }

    const ErrorShow = (error1) ?
        <ErrorModal
            title={error1.title}
            message={t(error1.message)}
            onConfirm={errorHandler}
        ></ErrorModal> : ''

    const [searchHotelList, setsearchHotelList] = useState();

    useEffect(() => {
        const getHotelSearchResponse = sessionStorage.getItem("hotelSearchResponse") !== null ? JSON.parse(sessionStorage.getItem("hotelSearchResponse")) : '';

        setsearchHotelList(((status === 'pending') ? '' : loadedData && loadedData.length !== 0) ?
            <Fragment>
                <HotelList searchHotel={loadedData} pageFocus={pageFocus} loadMore={Math.random()} travelReq={valueParam.travelreq} />
                {lastPage && <div className='col-md-12'>
                    {
                        getConfigurationByBoolen("ALLOW_MOVE_DOWN") === true && getHotelSearchResponse.data !== undefined && getHotelSearchResponse.data.getMoreResult !== undefined &&
                        <div className="top_form_search_button my-3">
                            {
                                statusMoveDown === "pending" &&
                                <span className="my-2 btn btn_theme btn_md mt-2"><i className="fas fa-spinner fa-spin"></i></span>
                            }
                            {
                                statusMoveDown !== "pending" &&
                                <button id='btnLoadMore' className="my-2 btn btn_theme btn_md mt-2" onClick={onSearchMoveDownHandler}> Load more</button>
                            }
                        </div>
                    }
                </div>}
            </Fragment> : '')
        if (statusMoveDown === "pending") {
            disableFilters();
        }
        else {
            enableFilters();
        }
    }, [loadedData, statusMoveDown, status])


    useEffect(() => {
        //User Story 1070: Change in Search for Single PNR
        if (localStorage.getItem("AddHotel") === null) {
            hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
            if (guestsValue === '' && hotelSessionJson.guests.length === undefined) {
                setGuestsValue('2');
                hotelSessionJson.guests = '2';
                sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
            }
            else {
                setGuestsValue(hotelSessionJson.guests);
            }
        }
        else {
            let guestCount = bookingJSON.data.travelerInfo.traveler.filter(a => a.PassengerTypeCode === 'ADT' || a.PassengerTypeCode === 'CNN').length.toString();
            setGuestsValue(guestsValue === '' ? guestCount.toString() : guestsValue.toString());
            hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
            hotelSessionJson.guests = guestsValue === '' ? guestCount.toString() : guestsValue.toString();
            sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
        }

        if (roomsValue === '') {
            setRoomsValue('1');
            hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
            hotelSessionJson.rooms = '1';
            sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
        }

        if (radius === undefined || radius === '') {
            hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"))
            if (Object.keys(hotelSessionJson.Radius).length > 0) {
                let r = hotelSessionJson.Radius;
                setRadius(r);
            } else {
                if (getConfigurationByValue('SABRE_HOTEL_RADIUS') !== null) {
                    setRadius(getConfigurationByValue('SABRE_HOTEL_RADIUS'))
                    hotelSessionJson.Radius = getConfigurationByValue('SABRE_HOTEL_RADIUS');
                    sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
                }
            }
        }
        if (hotelName === undefined || hotelName === '') {
            hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"))
            if (Object.keys(hotelSessionJson.HotelName).length > 0) {
                let hotelName = hotelSessionJson.HotelName;
                setHotelName(hotelName);
            }
        }

        document.querySelector('#dvDestinationSpinner').style.display = "none";

        if (localStorage.getItem("airportSuggestion") != null) {
            setItems(JSON.parse(localStorage.getItem("airportSuggestion")));
        }

        document.querySelector("#dvDestination").style.display = "none";

        //User Story 1070: Change in Search for Single PNR
        if (localStorage.getItem("AddHotel") === null) {
            if (Object.keys(hotelSessionJson.txtDestination).length > 0) {
                document.querySelector("#txtDestination").value = hotelSessionJson.txtDestination;
            }
            else {
                var strSight = JSON.parse(localStorage.getItem("fromToListResponse"));
                var strSightData = strSight.fromToListResponse.find(x => x.service == "HT");
                document.querySelector("#txtDestination").value = strSightData.cityName;//"New York";
                hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
                hotelSessionJson.txtDestination = strSightData.cityName;//"New York";
                sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
            }

            if (Object.keys(hotelSessionJson.spnDestinationAirportDetails).length > 0) {
                document.querySelector('#spnDestinationAirportDetails').innerHTML = hotelSessionJson.spnDestinationAirportDetails;
            }
            else {
                var strSight = JSON.parse(localStorage.getItem("fromToListResponse"));
                var strSightData = strSight.fromToListResponse.find(x => x.service == "HT");
                document.querySelector('#spnDestinationAirportDetails').innerHTML = strSightData.airportCode + " - " + strSightData.airportName + ", " + strSightData.countryName + ", " + strSightData.countryCode;// "NYC - All Airports, United States of America, US";
                hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
                hotelSessionJson.spnDestinationAirportDetails = strSightData.airportCode + " - " + strSightData.airportName + ", " + strSightData.countryName + ", " + strSightData.countryCode;//"NYC - All Airports, United States of America, US";
                hotelSessionJson.CityCode = strSightData.cityCode;
                sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
                // User Story 3395: New search criteria - "Hotel Name"
                // sendHotelName({
                //     "code": 'NYC',
                // })
            }

            if (Object.keys(hotelSessionJson.calCheckIn).length > 0) {
                document.querySelector('#calCheckIn').value = hotelSessionJson.calCheckIn;
            }
            else {
                hotelSessionJson.calCheckIn = moment(new Date(fromTodayDate)).format("ddd, MMM DD, 'YY");
                document.querySelector('#calCheckIn').value = moment(new Date(fromTodayDate)).format("ddd, MMM DD, 'YY");
                const checkInDate = new Date(fromTodayDate.toLocaleDateString('en-us', { weekday: "short", year: "numeric", month: "short", day: "numeric" }));
                var checkInDateDB = checkInDate.toLocaleDateString('en-gb').split('/').reverse().join('');
                hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
                hotelSessionJson.calCheckInDateDB = checkInDateDB;
                sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
            }

            if (Object.keys(hotelSessionJson.calCheckOut).length > 0) {
                document.querySelector('#calCheckOut').value = hotelSessionJson.calCheckOut;

                if ((minToDateChange === undefined || minToDateChange == '') && Object.keys(hotelSessionJson.calCheckIn).length > 0) {
                    let minToDateChangeTemp = new Date(hotelSessionJson.calCheckIn.replace("'", ""));
                    minToDateChangeTemp.setDate(minToDateChangeTemp.getDate() + 1);
                    sessionStorage.setItem("InitialLoadCheckOutMinvalue", moment(new Date(minToDateChangeTemp)).format("ddd, MMM DD, 'YY"));
                }
            }
            else {
                hotelSessionJson.calCheckOut = moment(new Date(toTodayDate)).format("ddd, MMM DD, 'YY");
                document.querySelector('#calCheckOut').value = moment(new Date(toTodayDate)).format("ddd, MMM DD, 'YY");
                const checkOutDate = new Date(toTodayDate.toLocaleDateString('en-us', { weekday: "short", year: "numeric", month: "short", day: "numeric" }));
                var checkOutDateDB = checkOutDate.toLocaleDateString('en-gb').split('/').reverse().join('');
                hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
                hotelSessionJson.calCheckOutDateDB = checkOutDateDB;
                sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
            }
        }
        else {
            document.querySelector("#txtDestination").value = sessionStorage.getItem("txtTo1");
            hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
            hotelSessionJson.txtDestination = sessionStorage.getItem("txtTo1");
            sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));

            document.querySelector('#spnDestinationAirportDetails').innerHTML = sessionStorage.getItem("spnToAirportDetails1");
            hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
            hotelSessionJson.spnDestinationAirportDetails = sessionStorage.getItem("spnToAirportDetails1");
            sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));

            var todayDate = new Date(sessionStorage.getItem("calFrom1").replace("'", ""));
            if (Object.keys(hotelSessionJson.calCheckIn).length > 0) {
                todayDate = new Date(hotelSessionJson.calCheckIn.replace("'", ""));
            }

            hotelSessionJson.calCheckIn = moment(new Date(todayDate)).format("ddd, MMM DD, 'YY");
            document.querySelector('#calCheckIn').value = moment(new Date(todayDate)).format("ddd, MMM DD, 'YY");
            const checkInDate = new Date(todayDate.toLocaleDateString('en-us', { weekday: "short", year: "numeric", month: "short", day: "numeric" }));
            var checkInDateDB = checkInDate.toLocaleDateString('en-gb').split('/').reverse().join('');
            hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
            hotelSessionJson.calCheckInDateDB = checkInDateDB;
            sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));

            var todayDate = new Date(sessionStorage.getItem("calTo1").replace("'", ""));
            if (Object.keys(hotelSessionJson.calCheckOut).length > 0) {
                todayDate = new Date(hotelSessionJson.calCheckOut.replace("'", ""));
            }

            hotelSessionJson.calCheckOut = moment(new Date(todayDate)).format("ddd, MMM DD, 'YY");
            document.querySelector('#calCheckOut').value = moment(new Date(todayDate)).format("ddd, MMM DD, 'YY");
            const checkOutDate = new Date(todayDate.toLocaleDateString('en-us', { weekday: "short", year: "numeric", month: "short", day: "numeric" }));
            var checkOutDateDB = checkOutDate.toLocaleDateString('en-gb').split('/').reverse().join('');
            hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
            hotelSessionJson.calCheckOutDateDB = checkOutDateDB;
            hotelSessionJson.CityCode = sessionStorage.getItem("toCityCode1") !== null ? sessionStorage.getItem("toCityCode1").trim().toUpperCase() : '';
            sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
        }

        if (Object.keys(hotelSessionJson.brandsData).length > 0) {
            setChainCode(hotelSessionJson.brandsData);
        }
        else {
            hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
            hotelSessionJson.brandsData = chainCode;
            sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
        }

        if (exclusiveDealsChecked) {
            if (Object.keys(hotelSessionJson.ratePlanCodeData).length > 0) {
                setRatePlanCode(hotelSessionJson.ratePlanCodeData);
            }
            else {
                hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
                // hotelSessionJson.ratePlanCodeData = ratePlanCode;
                hotelSessionJson.ratePlanCodeData = ratePlan;

                //removed "XGZ,ZGK" in case of "TQV" selection
                let temp = [];
                ratePlan.forEach(element => {
                    temp.push(element.value);
                });

                hotelSessionJson.ratePlanCode = temp;
                sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
                setRatePlanCode(ratePlan);
            }
        } else {
            hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
            hotelSessionJson.ratePlanCodeData = {};
            hotelSessionJson.ratePlanCode = {};
            setRatePlanCode('')
            sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
        }

        if (Object.keys(hotelSessionJson.Source).length > 0) {
            setSourceValue(hotelSessionJson.Source);
            if (hotelSessionJson.Source === '113' || hotelSessionJson.Source === '110') {

                setExclusiveDealsDisable(true);
                setIsExclusiveDealsDisable(true)
            } else {
                if (exclusiveDealsChecked) {
                    setExclusiveDealsDisable(false)
                } else {
                    setExclusiveDealsDisable(true)
                }
                setIsExclusiveDealsDisable(false)
            }

        }
        // else {
        //     if (getConfigurationByBoolen('ALLOW_GTB') === true) {
        //         hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
        //         setSourceValue(SourceOptions[2].value);
        //         hotelSessionJson.Source = SourceOptions[2].value;
        //         sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
        //     }
        // }

        //set PCC
        if (Object.keys(hotelSessionJson.pccOfficeWiseCodeData).length > 0) {
            setOfficeWisePCCCode(hotelSessionJson.pccOfficeWiseCodeData);
            searchRatePlanPCCWise(hotelSessionJson.pccOfficeWiseCodeData);
        }
        else {

            hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
            if (officeWisePCC.length > 0) {
                setOfficeWisePCCCode(officeWisePCC[0].value);
                searchRatePlanPCCWise(officeWisePCC[0].value);
                hotelSessionJson.pccOfficeWiseCodeData = officeWisePCC[0].value;
                sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
            }

        }

        if (Object.keys(hotelSessionJson.preferenceOptions).length > 0 && hotelSessionJson.preferenceOptions.filter(a => a.preferenceOptionId === 'refundableOnly').length > 0) {
            if (document.querySelector('#refundableOnly') !== null) {
                document.querySelector('#refundableOnly').checked = true;
            }
        }
        else {
            if (document.querySelector('#refundableOnly') !== null) {
                document.querySelector('#refundableOnly').checked = false;
            }
        }

        if (Object.keys(hotelSessionJson.preferenceOptions).length > 0 && hotelSessionJson.preferenceOptions.filter(a => a.preferenceOptionId === 'allHotels').length > 0) {
            document.querySelector('#allHotels').checked = true;
            setBranDisabled(false);
        }
        else if (Object.keys(hotelSessionJson.preferenceOptions).length > 0 && hotelSessionJson.preferenceOptions.filter(a => a.preferenceOptionId === 'luxuryHotelsOnly').length > 0) {
            document.querySelector('#luxuryHotelsOnly').checked = true;
            setBranDisabled(true);
        }
        else {
            document.querySelector('#allHotels').checked = true;
            setBranDisabled(false);
        }

        const onSearchChainCode = ({
            hotelCode: "",
            hotelName: ""
        });

        sendRequestChainCode(onSearchChainCode);


        if (minToDateChange !== undefined && minToDateChange !== '' && document.querySelector('#calCheckOut') !== null) {
            document.querySelector('#calCheckOut').focus();
        }

        if (ratingValue === '') {
            setRatingValue(defaultRating);
            hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
            hotelSessionJson.rating = defaultRating;
            sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
        }

    }, [statusChainCode, guestsValue, roomsValue, statusOfficeWisePCC, officeWisePCCCode, loadedRequestGetRatePlan, minToDateChange])

    useEffect(() => {
        const onSearchExclusiveDeals = ({
            officeID: JSON.parse(localStorage.getItem('officeID')),
            parentOfficeID: JSON.parse(localStorage.getItem('parentOfficeID'))
        });

        sendRequestExclusiveDeals(onSearchExclusiveDeals);
    }, [statusExclusiveDeals])


    const calGetDateCheckIn = (inputDate) => {
        let sourceDate = inputDate.target.value.trim();
        let destinationDate = inputDate.target.value.trim();
        let hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));

        if (Object.keys(hotelSessionJson.calCheckOut).length > 0) {
            let destinationDateTemp = new Date(inputDate.target.value.trim());
            destinationDateTemp.setDate(destinationDateTemp.getDate() + 1);
            destinationDate = new Date(hotelSessionJson.calCheckOut.replace("'", "")) > new Date(inputDate.target.value.trim()) ? new Date(hotelSessionJson.calCheckOut.replace("'", "")) : new Date(destinationDateTemp)
        }
        else {
            let destinationDateTemp = new Date(inputDate.target.value.trim());
            destinationDateTemp.setDate(destinationDateTemp.getDate() + 1);
            destinationDate = destinationDateTemp;
        }

        hotelSessionJson.calCheckIn = moment(new Date(sourceDate)).format("ddd, MMM DD, 'YY");
        document.querySelector('#calCheckIn').value = moment(new Date(sourceDate)).format("ddd, MMM DD, 'YY");
        const checkInDate = new Date(sourceDate);
        var checkInDateDB = checkInDate.toLocaleDateString('en-gb').split('/').reverse().join('');
        hotelSessionJson.calCheckInDateDB = checkInDateDB;
        sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));

        hotelSessionJson.calCheckOut = moment(new Date(destinationDate)).format("ddd, MMM DD, 'YY");
        document.querySelector('#calCheckOut').value = moment(new Date(destinationDate)).format("ddd, MMM DD, 'YY");
        const checkOutDate = new Date(destinationDate);
        var checkOutDateDB = checkOutDate.toLocaleDateString('en-gb').split('/').reverse().join('');
        hotelSessionJson.calCheckOutDateDB = checkOutDateDB;
        sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));

        let minToDateChangeTemp = new Date(hotelSessionJson.calCheckIn.replace("'", ""));
        minToDateChangeTemp.setDate(minToDateChangeTemp.getDate() + 1);
        setMinToDateChange(new Date(minToDateChangeTemp));
    }

    const calGetDateCheckOut = (inputDate) => {
        let sourceDate = inputDate.target.value.trim();
        let destinationDate = inputDate.target.value.trim();

        let hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));

        // hotelSessionJson.calCheckIn = moment(new Date(sourceDate)).format("ddd, MMM DD, 'YY");
        // document.querySelector('#calCheckIn').value = moment(new Date(sourceDate)).format("ddd, MMM DD, 'YY");
        // const checkInDate = new Date(sourceDate);
        // var checkInDateDB = checkInDate.toLocaleDateString('en-gb').split('/').reverse().join('');
        // hotelSessionJson.calCheckInDateDB = checkInDateDB;
        // sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));

        hotelSessionJson.calCheckOut = moment(new Date(destinationDate)).format("ddd, MMM DD, 'YY");
        document.querySelector('#calCheckOut').value = moment(new Date(destinationDate)).format("ddd, MMM DD, 'YY");
        const checkOutDate = new Date(destinationDate);
        var checkOutDateDB = checkOutDate.toLocaleDateString('en-gb').split('/').reverse().join('');
        hotelSessionJson.calCheckOutDateDB = checkOutDateDB;
        sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
    }

    let guestsOptions = [
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5' },
        { value: '6', label: '6' },
        { value: '7', label: '7' },
        { value: '8', label: '8' },
        { value: '9', label: '9' }
    ];

    let roomsOptions = [
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' }
    ];

    const guestsChangeHandler = (event) => {
        let hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
        hotelSessionJson.guests = event.value;
        sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
        setGuestsValue(event.value);
    };

    const roomsChangeHandler = (event) => {
        let hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
        hotelSessionJson.rooms = event.value;
        sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
        setRoomsValue(event.value);
    };
    const radiusChangeHandler = (event) => {
        let hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
        hotelSessionJson.Radius = event.target.value.trim();
        sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
        setRadius(event.target.value);
    }
    const hotelNameChangeHandler = (event) => {
        const newInputValue = event.target.value.trim();
        let hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
        hotelSessionJson.HotelName = newInputValue
        sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
        setHotelName(event.target.value)
    }

    let chainCodeOptions = [];
    let chainCodeArrayData = [];
    for (let i in loadedChainCode) {
        if (loadedChainCode['errors'].status === 'FALSE') {
            if (i === 'data') {
                chainCodeArrayData = loadedChainCode[i];
            }
        }
    }

    for (let index = 0; index < chainCodeArrayData.length; index++) {
        chainCodeOptions = [...chainCodeOptions, { value: chainCodeArrayData[index].hotelCode, label: chainCodeArrayData[index].hotelName + " (" + chainCodeArrayData[index].hotelCode + ")" }]
    }

    let providerCodeOptions = [];

    for (let i in loadedExclusiveDeals) {
        if (loadedExclusiveDeals['errors'].status === 'FALSE') {
            if (i === 'data') {
                for (let index = 0; index < loadedExclusiveDeals[i].length; index++) {
                    providerCodeOptions = [...providerCodeOptions, { value: loadedExclusiveDeals[i][index].value, label: loadedExclusiveDeals[i][index].name }]
                }
            }
        }
    }

    /* let providerCodeOptions = [
        { value: 'API', label: 'Virtuoso' },
        { value: 'OTG', label: 'Ovation' },
        { value: 'TQV', label: 'Amex GBT' }
    ]; */



    const brandsChangeHandler = (event) => {
        if (event.length > 0) {
            if (document.querySelector('#luxuryHotelsOnly') !== null) {
                document.querySelector('#luxuryHotelsOnly').checked = false;
                document.querySelector('#luxuryHotelsOnly').disabled = true;
            }
        }
        else {
            if (document.querySelector('#luxuryHotelsOnly') !== null) {
                document.querySelector('#luxuryHotelsOnly').disabled = false;
            }
        }

        let hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
        let brandsTemp = [];
        event.forEach(element => {
            brandsTemp.push(element.value);
        });
        hotelSessionJson.brands = brandsTemp;
        hotelSessionJson.brandsData = event;
        sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
        setChainCode(event);
    };
    function sourceChangeHandler(event) {
        let hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
        // let tempSource = [];
        // event.forEach(element => {
        //     tempSource.push(element);

        // })
        if (event.value === '113' || event.value === '110') {
            setIsExclusiveDealsDisable(true)
            setExclusiveDealsDisable(true);
            hotelSessionJson.ratePlanCode = {};
            hotelSessionJson.ratePlanCodeData = {};
            setRatePlanCode('');
            setExclusiveDealsChecked(false)
        } else {

            setIsExclusiveDealsDisable(false)
            if (exclusiveDealsChecked) {
                setExclusiveDealsDisable(false);

                //removed "XGZ,ZGK" in case of "TQV" selection
                let temp = [];
                ratePlan.forEach(element => {
                    temp.push(element.value);
                });

                hotelSessionJson.ratePlanCode = temp;
                hotelSessionJson.ratePlanCodeData = ratePlan;
                setRatePlanCode(ratePlan);

            } else {
                setExclusiveDealsDisable(true);
                hotelSessionJson.ratePlanCode = {};
                hotelSessionJson.ratePlanCodeData = {};
                setRatePlanCode('');
            }
        }
        hotelSessionJson.Source = event.value;
        sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
        setSourceValue(event.value);

    }
    const providerCodeChangeHandler = (event) => {
        let hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
        let providerCodeTemp = [];

        //removed "XGZ,ZGK" in case of "TQV" selection
        event.forEach(element => {
            providerCodeTemp.push(element.value);
        });

        hotelSessionJson.providerCode = providerCodeTemp;
        hotelSessionJson.providerCodeData = event;
        sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
        setProviderCode(event);
    };
    const ratePlanCodeChangeHandler = (event) => {
        setUserChangedExclDeals(true);
        let hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));

        //removed "XGZ,ZGK" in case of "TQV" selection
        let providerCodeTemp = [];
        event.forEach(element => {
            providerCodeTemp.push(element.value);
        });

        hotelSessionJson.ratePlanCode = providerCodeTemp;
        hotelSessionJson.ratePlanCodeData = event;
        sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
        setRatePlanCode(event);

    };
    const officeWisePCCChangeHandler = (event) => {
        setUserChangedExclDeals(false);
        let hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));

        hotelSessionJson.pccOfficeWiseCodeData = event.value;
        hotelSessionJson.ratePlanCode = {};
        hotelSessionJson.ratePlanCodeData = {};

        setRatePlanCode('');
        sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));

        setOfficeWisePCCCode(event.value);
        searchRatePlanPCCWise(event.value);
    };

    let preferenceOptionsTemp = [];

    const onAllHotelsChangeHandler = (event) => {
        if (event.target.checked) {
            setBranDisabled(false);
        }

        var inputs = document.querySelectorAll('.preference-options');
        preferenceOptionsTemp = [];
        for (var i = 0; i < inputs.length; i++) {
            if (inputs[i].checked) {
                preferenceOptionsTemp.push({
                    preferenceOptionId: inputs[i].getAttribute("id"),
                    preferenceOptionValue: true
                });
            }
        }

        let hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
        hotelSessionJson.preferenceOptions = [];
        hotelSessionJson.preferenceOptions = preferenceOptionsTemp;
        sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
    }

    const onLuxuryHotelsOnlyChangeHandler = (event) => {
        if (event.target.checked) {
            setBranDisabled(true);
        }

        var inputs = document.querySelectorAll('.preference-options');
        preferenceOptionsTemp = [];
        for (var i = 0; i < inputs.length; i++) {
            if (inputs[i].checked) {
                preferenceOptionsTemp.push({
                    preferenceOptionId: inputs[i].getAttribute("id"),
                    preferenceOptionValue: true
                });
            }
        }

        let hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
        hotelSessionJson.preferenceOptions = [];
        hotelSessionJson.preferenceOptions = preferenceOptionsTemp;
        sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
    }

    const onRefundableOnlyChangeHandler = (event) => {
        var inputs = document.querySelectorAll('.preference-options');
        preferenceOptionsTemp = [];
        for (var i = 0; i < inputs.length; i++) {
            if (inputs[i].checked) {
                preferenceOptionsTemp.push({
                    preferenceOptionId: inputs[i].getAttribute("id"),
                    preferenceOptionValue: true
                });
            }
        }

        let hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
        hotelSessionJson.preferenceOptions = [];
        hotelSessionJson.preferenceOptions = preferenceOptionsTemp;
        sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
    }

    const onExclusiveDealsChangeHandler = (event) => {
        var inputs = document.querySelectorAll('.preference-options');
        preferenceOptionsTemp = [];
        for (var i = 0; i < inputs.length; i++) {
            if (inputs[i].checked) {
                preferenceOptionsTemp.push({
                    preferenceOptionId: inputs[i].getAttribute("id"),
                    preferenceOptionValue: true
                });
            }
        }

        let hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
        hotelSessionJson.preferenceOptions = [];
        hotelSessionJson.preferenceOptions = preferenceOptionsTemp;
        sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
    }
    function onShowExclusiveDealsChangeHandler(event) {
        let hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
        setExclusiveDealsChecked(event.target.checked)
        if (event.target.checked) {
            setExclusiveDealsDisable(false)

            //removed "XGZ,ZGK" in case of "TQV" selection
            let temp = [];
            ratePlan.forEach(element => {
                temp.push(element.value);
            });

            hotelSessionJson.ratePlanCode = temp;
            hotelSessionJson.ratePlanCodeData = ratePlan;
            sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
            setRatePlanCode(ratePlan);
        } else {
            setExclusiveDealsDisable(true)
            hotelSessionJson.ratePlanCode = {};
            hotelSessionJson.ratePlanCodeData = {};
            sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
            setRatePlanCode('');
        }
    }

    window.addEventListener('click', function (e) {
        if (document.querySelector('#dvPassengers1') !== null) {
            if (document.querySelector('#dvPassengers1').contains(e.target)) {
                if (e.target.id === "ancHidePassengerDropDown") {
                    document.querySelector('#btnPassengerDropDown1').className = "dropdown-toggle final-count";
                    document.querySelector('#btnPassengerDropDown1').setAttribute("aria-expanded", "false");
                    document.querySelector('#dvPassengers1').className = "dropdown-menu dropdown_passenger_info";
                }
                else {
                    document.querySelector('#btnPassengerDropDown1').className = "dropdown-toggle final-count show";
                    document.querySelector('#btnPassengerDropDown1').setAttribute("aria-expanded", "true");
                    document.querySelector('#dvPassengers1').className = "dropdown-menu dropdown_passenger_info show";
                    document.querySelector('#dvPassengers1').style = "position: absolute; inset: 0px auto auto 0px; margin: 0px;";
                }
            } else {
                if (e.target.parentNode.id === "btnPassengerDropDown1" || e.target.id === "btnPassengerDropDown1") {
                    document.querySelector('#btnPassengerDropDown1').className = "dropdown-toggle final-count show";
                    document.querySelector('#btnPassengerDropDown1').setAttribute("aria-expanded", "true");
                    document.querySelector('#dvPassengers1').className = "dropdown-menu dropdown_passenger_info show";
                    document.querySelector('#dvPassengers1').style = "position: absolute; inset: 0px auto auto 0px; margin: 0px;";
                }
                else {
                    document.querySelector('#btnPassengerDropDown1').className = "dropdown-toggle final-count";
                    document.querySelector('#btnPassengerDropDown1').setAttribute("aria-expanded", "false");
                    document.querySelector('#dvPassengers1').className = "dropdown-menu dropdown_passenger_info";
                }
            }
        }
    });


    const controlHandlerFightSearch = (event) => {
        if (event.target.id === 'lblFlights') {
            history.push('/FlightSearch?Flights=1');
        }
        else if (event.target.id === 'lblHotel') {
            localStorage.removeItem("AddHotel");
            sessionStorage.removeItem("singlePnrCheckOutMinvalue");

            if (document.querySelector("#lblFlights") !== null) {
                document.querySelector("#lblFlights").classList.add("text-primary");
                document.querySelector("#lblFlights").classList.remove("flight-active-tab");
            }

            if (document.querySelector("#lblHotel") !== null) {
                document.querySelector("#lblHotel").classList.add("flight-active-tab");
                document.querySelector("#lblHotel").classList.remove("text-primary");
            }

            if (document.querySelector("#lblFlightPlusHotel") !== null) {
                document.querySelector("#lblFlightPlusHotel").classList.add("text-primary");
                document.querySelector("#lblFlightPlusHotel").classList.remove("flight-active-tab");
            }
        }
        else if (event.target.id === 'lblFlightPlusHotel') {
            history.push('/FlightSearch?FlightPlusHotel=1');
        }
        else if (event.target.id === 'lblSightseeing') {
            history.push('/SearchSightseeing');
        }
        else if (event.target.id === 'lblHolidays') {
            //history.push('/SearchHolidays');
            window.open(getConfigurationByValue('HOLIDAYS_URL'))
        }
        else if (event.target.id === 'lblVisa') {
            history.push('/SearchVisa')
        }
    }
    const advanceChangeHandler = () => {
        if (sessionStorage.getItem("hotelSessionDataJson") !== null) {
            let hotelJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
            if (Object.keys(hotelJson.HotelName).length !== 0) {
                setSearchString(hotelJson.HotelName)
            }
            if (Object.keys(hotelJson.HotelName).length == 0) {
                setSearchString('')
            }
        }
        let show;
        if (isadvanced) {
            setIsAdvanced(false)
            show = "false"
        } else {
            setIsAdvanced(true)
            show = "true"
        }
        // User Story 3395: New search criteria - "Hotel Name"
        //setHotelNameDisable(false);

        sessionStorage.setItem("showAdanced", show);
    }
    // User Story 3395: New search criteria - "Hotel Name"
    // function onHotelNameSelection(event) {
    //     setSelectedHotel(event.HotelName)
    //     let hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
    //     hotelSessionJson.HotelName = event.HotelName;
    //     sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));


    // }

    // function handleOnHotelNameSearch(input) {
    //     setHotelNameDisable(true);
    //     setSelectedHotel(input);
    //     let hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
    //     hotelSessionJson.HotelName = input
    //     sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));

    // }
    // function onHotelNameClear(event) {
    //     setSearchString('');
    //     let hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
    //     hotelSessionJson.HotelName = ''
    //     sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
    // }

    const ratingChangeHandler = (event) => {
        hotelSessionJson = JSON.parse(sessionStorage.getItem("hotelSessionDataJson"));
        hotelSessionJson.rating = event.value;
        sessionStorage.setItem("hotelSessionDataJson", JSON.stringify(hotelSessionJson));
        setRatingValue(event.value);
    }

    for (let index in loadedRoe) {
        if (loadedRoe['errors'].status === 'FALSE') {
            if (index === 'data') {
                secureLocalStorage.setItem('searchRoeResult', JSON.stringify(loadedRoe[index]));
            }
        }
    }

    if (document.querySelector('#dvGuests') !== null) {
        let tripReasonList = [];
        let tripReasonAndPolicyDataJson = "";
        let travelRequestFieldsDataJson = "";

        if (localStorage.getItem("loginData") !== null && sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
            tripReasonAndPolicyDataJson = JSON.parse(localStorage.getItem("loginData"));
            travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));

            if (tripReasonAndPolicyDataJson.tripReason.length > 0) {
                tripReasonList = tripReasonAndPolicyDataJson.tripReason.filter(a => a.id === travelRequestFieldsDataJson.tripReason);
            }
        }

        if ((localStorage.getItem('isCorporate') !== null && localStorage.getItem('isCorporate') === 'true')) {
            if (tripReasonList.length > 0 && tripReasonList[0] !== undefined && tripReasonList[0].isFamilyBooking !== undefined && tripReasonList[0].isFamilyBooking === false) {
                if (document.querySelector('#dvGuests') !== null && document.querySelector('#dvDateRange') !== null) {
                    document.querySelector('#dvGuests').classList.add('d-none');
                    document.querySelector('#dvDateRange').classList.remove('col-lg-8');
                    document.querySelector('#dvDateRange').classList.add('col-lg-12');
                }
            }
            else {
                if (document.querySelector('#dvGuests') !== null && document.querySelector('#dvDateRange') !== null) {
                    document.querySelector('#dvGuests').classList.remove('d-none');
                    document.querySelector('#dvDateRange').classList.remove('col-lg-12');
                    document.querySelector('#dvDateRange').classList.add('col-lg-8');
                }
            }
        }
        else {
            if (document.querySelector('#dvGuests') !== null && document.querySelector('#dvDateRange') !== null) {
                document.querySelector('#dvGuests').classList.remove('d-none');
                document.querySelector('#dvDateRange').classList.remove('col-lg-12');
                document.querySelector('#dvDateRange').classList.add('col-lg-8');
            }
        }
    }

    return <Fragment>
        {
            hotelNameError && <ErrorModal
                title={hotelNameError.title}
                message={t(hotelNameError.message)}
                onConfirm={errorHandler}
            ></ErrorModal>
        }
        {
            error1 && <ErrorModal
                title={error1.title}
                message={t(error1.message)}
                onConfirm={errorHandler}
            ></ErrorModal>
        }
        <section className="sub-banner">
            <div className="bg-parallax bg-1"></div>
            <div className="logo-banner text-center">

            </div>
        </section>

        <section id="theme_search_form_tour" className='fligth_top_search_main_form_wrapper'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="theme_search_form_area position-relative my-2">
                            <div className="row position-relative">
                                {status === 'pending' &&
                                    <LoadingHotel action='search' ></LoadingHotel>
                                }

                                <div className="col-lg-12">
                                    <div className="tour_search_form">
                                        <form action="!#">
                                            <div className="row">
                                                <label for="" className="h5 text-primary mb-3 me-3" ><i className="fas fa-globe-asia"></i> Search</label>
                                                <div className='text-sm-center1 mb-2'>
                                                    <span>
                                                        {
                                                            (checkSecurityDetails("Book", "Flight") && checkSecurityDetails("Book", "Flight", "Book_Flight_Search") && getConfigurationByBoolen("ALLOW_FLIGHT") === true) && (valueParam.src === undefined || valueParam.src === '' || valueParam.src === 'flight') &&
                                                            <label for="" className="h6 mb-2 me-3" role="button" id='lblFlights' onClick={controlHandlerFightSearch}><i className="fas fa-plane-departure"></i> {t('flights')}</label>
                                                        }
                                                        {
                                                            (getConfigurationByBoolen('ALLOW_SINGLE_PNR') !== null &&
                                                                getConfigurationByBoolen("ALLOW_HOTEL") && checkSecurityDetails("Book", "Hotel") && checkSecurityDetails("Book", "Hotel", "Book_Hotel_Search") && getConfigurationByBoolen('ALLOW_SINGLE_PNR')
                                                                && getConfigurationByBoolen("ALLOW_FLIGHT")) && checkSecurityDetails("Book", "Flight", "Book_Flight_Search") ?
                                                                <label for="" className="h6 mb-2 mx-1" role="button" id='lblFlightPlusHotel' onClick={controlHandlerFightSearch}><i className="fas fa-globe-asia"></i>  {t('Flights and Hotels')}</label> :
                                                                <label id='lblFlightPlusHotel' ></label>
                                                        }
                                                        {
                                                            (checkSecurityDetails("Book", "Hotel") && checkSecurityDetails("Book", "Hotel", "Book_Hotel_Search") && getConfigurationByBoolen("ALLOW_HOTEL") === true) &&
                                                            <label for="" className="h6 mb-2 ms-3" role="button" id='lblHotel' onClick={controlHandlerFightSearch}><i className="fas fa-hotel"></i> Hotels</label>
                                                        }
                                                        {
                                                            (checkSecurityDetails("Book", "Sightseeing") && getConfigurationByBoolen("ALLOW_SIGHTSEEING") === true) &&
                                                            <label for="" className="h6 mb-2 ms-3" role="button" id='lblSightseeing' onClick={controlHandlerFightSearch}><i className="fas fa-hiking"></i> Sightseeing</label>
                                                        }
                                                        {
                                                            getConfigurationByBoolen("ALLOW_HOLIDAYS") === true && getConfigurationByValue('HOLIDAYS_URL') !== null && getConfigurationByValue('HOLIDAYS_URL') !== '' &&
                                                            <label for="" className="h6 mb-2 ms-3" role="button" id='lblHolidays' onClick={controlHandlerFightSearch}><i className="fas fa-umbrella-beach"></i> Holidays</label>
                                                        }
                                                        {getConfigurationByBoolen("ALLOW_VISA") === true &&
                                                            <label for="" className="h6 mb-2 ms-3" role="button" id='lblVisa' onClick={controlHandlerFightSearch}><i class="fas fa-passport"></i> Visa</label>
                                                        }
                                                    </span>
                                                </div>
                                                <div className={localStorage.getItem('isCorporate') !== null && localStorage.getItem('isCorporate') === 'false' ? "col-lg-5 col-md-12 col-sm-12 col-12" : "col-lg-6 col-md-12 col-sm-12 col-12"}>
                                                    <div className="flight_Search_boxed py-2">
                                                        <div className='pb-1 h5 mb-0' id='dvFrom5'>
                                                            <div ref={refDestination} className="position-relative" >
                                                                <p onClick={handleOnClickDestination}>{t('Destination')} </p>
                                                                <div className=''>
                                                                    <input id={'txtDestination'} type="text" onClick={handleOnClickDestination} />
                                                                    <div id='spnDestinationAirportDetails' className='h6 text-muted w-100 mt-2 text-overflow-manage'></div>
                                                                </div>
                                                                <div id={'dvDestinationSpinner'} className="input-spineer-from"><i className="fa fa-spinner fa-spin"></i></div>
                                                                <div id={'dvDestination'} >
                                                                    <ReactSearchAutocomplete
                                                                        onSearch={handleOnDestinationSearch}
                                                                        items={items}
                                                                        formatResult={formatResult}
                                                                        autoFocus
                                                                        onSelect={handleOnSelectDestination}
                                                                        showNoResults={false}
                                                                        maxResults={15}
                                                                        fuseOptions={{ keys: ["cityCode", "airportCode", "airportName", "cityName"] }}
                                                                        resultStringKeyName="cityName"

                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-6 col-md-12 col-sm-12 col-12'>
                                                    <div className='row'>
                                                        <div id='dvDateRange' className="col-lg-12 col-md-6 col-sm-12 col-12">
                                                            <div className="form_search_date">
                                                                <div className="flight_Search_boxed date_flex_area padding-2y">
                                                                    <div className="Journey_date">
                                                                        <p>Check-in</p>
                                                                        <DateRangePicker
                                                                            onHide={calGetDateCheckIn}
                                                                            initialSettings={{
                                                                                startDate: Object.keys(hotelSessionJson.calCheckIn).length > 0 ? new Date(hotelSessionJson.calCheckIn.replace("'", "")) : valueParam.addhotel === "1" ? sessionStorage.getItem("calFrom1") !== null && sessionStorage.getItem("calFrom1") !== '' ? new Date(sessionStorage.getItem("calFrom1").replace("'", "")) : fromTodayDate : fromTodayDate, endDate: '', singleDatePicker: true,
                                                                                showDropdowns: true, autoApply: true, minDate: new Date()
                                                                            }} >
                                                                            <input id={'calCheckIn'} type="text" autoComplete='off' />
                                                                        </DateRangePicker>
                                                                    </div>
                                                                    <div className="Journey_date">
                                                                        <p>Check-out</p>
                                                                        <DateRangePicker key={minToDateChange}
                                                                            onHide={calGetDateCheckOut}
                                                                            initialSettings={{
                                                                                startDate: Object.keys(hotelSessionJson.calCheckOut).length > 0 ? new Date(hotelSessionJson.calCheckOut.replace("'", "")) : valueParam.addhotel === "1" ? sessionStorage.getItem("calTo1") !== null && sessionStorage.getItem("calTo1") !== '' ? new Date(sessionStorage.getItem("calTo1").replace("'", "")) : toTodayDate : toTodayDate, endDate: '', singleDatePicker: true,
                                                                                showDropdowns: true, autoApply: true, minDate: minToDateChange !== undefined && minToDateChange !== '' ? new Date(minToDateChange) : Object.keys(hotelSessionJson.calCheckIn).length > 0 ? sessionStorage.getItem("InitialLoadCheckOutMinvalue") !== null && sessionStorage.getItem("InitialLoadCheckOutMinvalue") !== '' ? new Date(sessionStorage.getItem("InitialLoadCheckOutMinvalue").replace("'", "")) : new Date(hotelSessionJson.calCheckIn.replace("'", "")) : valueParam.addhotel === "1" ? sessionStorage.getItem("singlePnrCheckOutMinvalue") !== null && sessionStorage.getItem("singlePnrCheckOutMinvalue") !== '' ? new Date(sessionStorage.getItem("singlePnrCheckOutMinvalue").replace("'", "")) : new Date(toTodayDate) : new Date(toTodayDate)
                                                                            }} >
                                                                            <input id={'calCheckOut'} type="text" autoComplete='off' />
                                                                        </DateRangePicker>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4  col-md-6 col-sm-12 col-12">
                                                            <div id='dvGuests' className='flight_Search_boxed dropdown_passenger_area py-1'>
                                                                <div className='row'>
                                                                    <div className='col-md-12  dropdown-search-text'>
                                                                        <p>Guests</p>
                                                                        <div className="dropdown pb-2 col-md-11">
                                                                            <Dropdown onChange={guestsChangeHandler}
                                                                                value={guestsOptions.filter(option => option.value === guestsValue)}
                                                                                options={guestsOptions}
                                                                                textField="value"
                                                                                dataItemKey="label"
                                                                                arrowClosed={<span className="arrow-closed" />}
                                                                                arrowOpen={<span className="arrow-open" />} controlClassName='mt-1 form-control control dropdown-search form-select bg_input mb-0'
                                                                            />
                                                                        </div>
                                                                        <span className='per-text text-black d-none'><p className='text-black'>Per</p></span>
                                                                    </div>
                                                                    <div className='col-md-6  dropdown-search-text d-none'>
                                                                        <p>Rooms</p>
                                                                        <div className="dropdown pb-2 col-md-9">
                                                                            <Dropdown onChange={roomsChangeHandler}
                                                                                value={roomsOptions.filter(option => option.value === roomsValue)}
                                                                                options={roomsOptions}
                                                                                textField="value"
                                                                                dataItemKey="label"
                                                                                arrowClosed={<span className="arrow-closed" />}
                                                                                arrowOpen={<span className="arrow-open" />} controlClassName='mt-1 form-control control dropdown-search form-select bg_input mb-0'
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className={localStorage.getItem('isCorporate') !== null && localStorage.getItem('isCorporate') === 'false' ? "row" : "row d-none"}>
                                                        <div className='d-lg-flex d-md-flex  search-flight-checkbox my-2'>

                                                            <span className='d-flex  exclusive-hotel-search'>
                                                                <span className="text-16px text-black me-2">
                                                                    <div className='form-check'>
                                                                        <input className="form-check-input preference-options" type="radio" name="hotelType" id="allHotels"
                                                                            onClick={(e) => onAllHotelsChangeHandler(e)}></input>
                                                                        <label className="form-check-label mb-0" for="allHotels">
                                                                            {t('All Hotels')}
                                                                        </label>
                                                                    </div>
                                                                </span>
                                                                <span className="text-16px text-black">
                                                                    <div className='form-check'>
                                                                        <input className="form-check-input preference-options" type="radio" name="hotelType" id="luxuryHotelsOnly"
                                                                            onClick={(e) => onLuxuryHotelsOnlyChangeHandler(e)}></input>
                                                                        <label className="form-check-label mb-0" for="luxuryHotelsOnly">
                                                                            {t('Luxury Hotels Only')}
                                                                        </label>
                                                                    </div>
                                                                </span>
                                                            </span>

                                                            <span className="ms-1 text-16px text-black d-none">
                                                                <div className='form-check'>
                                                                    <input className="form-check-input preference-options" type="checkbox" id="exclusiveDeals"
                                                                        onChange={(e) => onExclusiveDealsChangeHandler(e)}></input>
                                                                    <label className="form-check-label mb-0" for="exclusiveDeals">
                                                                        Exclusive Deals
                                                                    </label>
                                                                </div>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    localStorage.getItem('isCorporate') !== null && localStorage.getItem('isCorporate') === 'false' &&
                                                    <div className='col-lg-12'>
                                                        <div className="row">
                                                            <div className="control col-md-3 mb-0 mt-2">
                                                                <div className="form-switch form-switch-sm padding-left-0">
                                                                    <label for="advanced" className="mb-2 h6 text-primary me-2">Advanced</label>
                                                                    <input id="advance" className="form-check-input ms-1" type="checkbox" checked={isadvanced} onChange={advanceChangeHandler} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {isadvanced && <div className='row'>
                                                            <div className='d-lg-flex d-md-flex my-2'>
                                                                <span className="text-16px text-black">
                                                                    <div className='form-check'>
                                                                        <input className="form-check-input preference-options" type="checkbox" id="exclusiveDeals1"
                                                                            checked={exclusiveDealsChecked}
                                                                            disabled={isexclusiveDealsDisable}
                                                                            onChange={(e) => onShowExclusiveDealsChangeHandler(e)}>
                                                                        </input>
                                                                        <label className="form-check-label mb-0 fw-bold text-primary" for="exclusiveDeals1">
                                                                            Exclusive Deals Only
                                                                        </label>
                                                                    </div>
                                                                </span>
                                                                {
                                                                    (getConfigurationByBoolen("ALLOW_REFUNDABLE_ONLY") === null || getConfigurationByBoolen("ALLOW_REFUNDABLE_ONLY") === false) &&
                                                                    <span className="ms-3 text-16px text-black">
                                                                        <div className='form-check'>
                                                                            <input className="form-check-input preference-options" type="checkbox" id="refundableOnly"
                                                                                onChange={(e) => onRefundableOnlyChangeHandler(e)}></input>
                                                                            <label className="form-check-label mb-0 fw-bold text-primary" for="refundableOnly">
                                                                                {t('Refundable Only')}
                                                                            </label>
                                                                        </div>
                                                                    </span>
                                                                }
                                                            </div>
                                                            {officeWisePCC.length > 1 && getConfigurationByBoolen('ALLOW_EXCLUSIVE_DEALS_PCC_WISE') && <div className={getConfigurationByBoolen('ALLOW_GTB') === true ? 'mt-2 col-lg-2 col-md-6 col-sm-12 dropdown-search-text dropdown-mt-5px' : 'col-lg-2 col-md-6 col-sm-12 dropdown-search-text dropdown-mt-5px mt-2'}>
                                                                <label className="text-16px fw-500 text-primary col-lg-12">PCC</label>
                                                                <Dropdown onChange={officeWisePCCChangeHandler}
                                                                    //value={officeWisePCCCode}
                                                                    value={officeWisePCC.filter(option => option.value === officeWisePCCCode)}
                                                                    options={officeWisePCC}
                                                                    textField="label"
                                                                    dataItemKey="value"
                                                                    arrowClosed={<span className="arrow-closed" />}
                                                                    arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                                                    isMulti={false}
                                                                //isDisabled={branDisabled}
                                                                />
                                                            </div>}
                                                            <div className={getConfigurationByBoolen('ALLOW_GTB') === true ? 'col-lg-5 col-md-6 col-sm-12 dropdown-search-text dropdown-mt-5px mt-2' : 'col-lg-3 col-md-6 col-sm-12 dropdown-search-text dropdown-mt-5px mt-2'}>
                                                                <label className="text-16px fw-500 text-primary col-lg-12">Exclusive Deals </label>
                                                                <Dropdown onChange={ratePlanCodeChangeHandler}
                                                                    value={ratePlanCode}
                                                                    options={ratePlan}
                                                                    textField="label"
                                                                    dataItemKey="value"
                                                                    arrowClosed={<span className="arrow-closed" />}
                                                                    arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                                                    isMulti={true}
                                                                    isDisabled={exclusiveDealsDisable}
                                                                />
                                                            </div>
                                                            <div className={getConfigurationByBoolen('ALLOW_GTB') === true ? 'col-lg-5 col-md-6 col-sm-12 dropdown-search-text dropdown-mt-5px margin-top-15 mt-2' : 'col-lg-5 col-md-6 col-sm-12 dropdown-search-text dropdown-mt-5px margin-top-15 mt-2'}>
                                                                <div className=''>
                                                                    <label className="text-16px fw-500 text-primary col-lg-12">Brands</label>
                                                                    <Dropdown onChange={brandsChangeHandler}
                                                                        value={chainCode}
                                                                        options={chainCodeOptions}
                                                                        textField="label"
                                                                        dataItemKey="value"
                                                                        arrowClosed={<span className="arrow-closed" />}
                                                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                                                        isMulti={true}
                                                                        isDisabled={branDisabled}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className={'col-lg-2 col-md-4 col-sm-12 margin-top-15 mt-2'}>
                                                                <label className="text-16px fw-500 text-primary col-lg-12">Radius (Miles)</label>
                                                                <input id="radius"
                                                                    ref={radiusInputRef}
                                                                    value={radius}
                                                                    onChange={radiusChangeHandler}
                                                                    onInput={allowOnlyNumbers}
                                                                    type="number"
                                                                    min={'1'}
                                                                    max={'200'}
                                                                    minLength={'1'}
                                                                    maxLength="3"

                                                                    className="form-control form-control-sm search-input-hotel"
                                                                />
                                                            </div>
                                                            {(getConfigurationByBoolen('ALLOW_GTB') === true || getConfigurationByBoolen('ALLOW_TAAP') === true) && getConfigurationByBoolen('ALLOW_SOURCE_PCC_FARE') === true ?
                                                                <div className={(getConfigurationByBoolen('ALLOW_GTB') === true || getConfigurationByBoolen('ALLOW_TAAP') === true) && getConfigurationByBoolen('ALLOW_SOURCE_PCC_FARE') === true ? 'col-lg-5 col-md-4 col-sm-12 dropdown-search-text dropdown-mt-5px margin-top-15 mt-2' : ''}>
                                                                    <label className="text-16px fw-500 text-primary ">Content</label>
                                                                    <Dropdown onChange={sourceChangeHandler}
                                                                        value={SourceOptions.filter(option => option.value === sourceValue)}
                                                                        // value={sourceValue}
                                                                        options={SourceOptions}
                                                                        textField="label"
                                                                        dataItemKey="value"
                                                                        arrowClosed={<span className="arrow-closed" />}
                                                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Select"
                                                                        isMulti={false}

                                                                    />
                                                                </div> : ""}


                                                            {/* User Story 3395: New search criteria - "Hotel Name" */}
                                                            <div className={(getConfigurationByBoolen('ALLOW_GTB') === true || getConfigurationByBoolen('ALLOW_TAAP')) ? 'col-lg-5 col-md-4 col-sm-12 dropdown-search-text dropdown-mt-5px margin-top-15 mt-2 hotelNamedropdownHeight' : ''}>
                                                                <label className="text-16px fw-500 text-primary ">Hotel Name</label>
                                                                <input id="hotelNameInput" className="form-control form-control-sm search-input-hotel" type='text' onChange={hotelNameChangeHandler} value={hotelName} maxLength={100}></input>
                                                            </div>
                                                        </div>}
                                                    </div>
                                                }
                                                {
                                                    localStorage.getItem('isCorporate') !== null && localStorage.getItem('isCorporate') === 'true' &&
                                                    <div className={getConfigurationByBoolen('ALLOW_GTB') === true || getConfigurationByBoolen('ALLOW_TAAP') ? 'col-lg-5 col-md-6 col-sm-12 dropdown-search-text dropdown-mt-5px margin-top-15 mt-2' : 'col-lg-5 col-md-6 col-sm-12 dropdown-search-text dropdown-mt-5px margin-top-15 mt-2'}>
                                                        <div className=''>
                                                            <label className="text-16px fw-500 text-primary col-lg-12">Rating</label>
                                                            <Dropdown onChange={ratingChangeHandler}
                                                                value={ratingList.filter(option => option.value === ratingValue)}
                                                                options={ratingList}
                                                                textField="value"
                                                                dataItemKey="label"
                                                                arrowClosed={<span className="arrow-closed" />}
                                                                arrowOpen={<span className="arrow-open" />} controlClassName='mt-1 form-control control dropdown-search form-select bg_input mb-0'
                                                            />
                                                        </div>
                                                    </div>
                                                }

                                                <div className="top_form_search_button my-3">
                                                    <button className="btn btn_theme btn_md mt-2" type="button" onClick={onSearchHandler}>Search</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {searchHotelList}
        {/*    <!-- End Main --> */}

    </Fragment>

};

export default SearchHotel;