import { Fragment, useState, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';
import Dropdown from 'react-select';
import { useHistory } from 'react-router-dom';
import useDocumentTitle from "../../../../components/Common/layout/useDocumentTitle";
import useHttp from "../../../../services/use-http";
import { SearchRegionApi } from "../../../../services/Region-API";
import { searchCommonList, SearchHotelChain } from "../../../../services/Fee-API";
import { searchAllAirline } from "../../../../services/Airline-API";
import { searchCorporateMaster } from "../../../../services/CorporateMaster-API";
import { searchTripReason } from "../../../../services/TripReason-API";
import { objectToArray } from "../../../../utils/CommonFunction";
import { getConfigurationByValue, getConfigurationByBoolen } from "../../../../utils/ConfigurationsFunction";
import { checkSecurityDetails } from "../../../../utils/RoleUtils";
import LoadingSpinner from "../../../../components/Common/Loading/LoadingSpinner";
import ErrorModal from "../../../../components/Common/Error/ErrorModal";
const recommendationOptions = [
    { value: "1", label: "Both In Policy and Out of Policy" },
    { value: "2", label: "In Policy " }
];
const cabinClassOptions = [
    { value: "4", label: "First" },
    { value: "3", label: "Business" },
    { value: "2", label: "Premium Economy" },
    { value: "1", label: "Economy" },
];
const lowestFareRuleOptions = [
    { value: "00", label: "Please Select" },
    { value: "3", label: "Allow Layovers" },
    { value: "1", label: "Allow lowest available fare only" },
    { value: "4", label: "Allow Lowest Refundable Fares Only" },
    { value: "2", label: "Allow lowest with maximum 1 connecting flight" },
];
const lowestRateRuleOptions = [
    { value: "00", label: "Please Select" },
    { value: "1", label: "Allow lowest available rate only" },
    { value: "2", label: "Allow Lowest Refundable Fares Only" },
];
const approvalOptions = [
    { value: "1", label: "Always need approval" },
    { value: "2", label: "Need approval when out of policy" },
    { value: "3", label: "Never need approval" }
];

const upgradeClassOptions = [
    { value: "00", label: "Please Select" },
    { value: "1", label: "Business Class" },
    { value: "2", label: "Premium Economy and if not available, to Business Class" },

];

const ratingOptions = [
    { value: 'All', label: 'All' },
    { value: '5', label: '5' },
    { value: '4', label: '4' },
    { value: '3', label: '3' },
    { value: '2', label: '2' },
    { value: '1', label: '1' }
];



const RulesForm = (props) => {
    useDocumentTitle("New Policy")
    const { t } = useTranslation(['translation.Rules']);
    const [pageTitle, setPageTitle] = useState(" New Policy");
    const [error, setError] = useState();


    //Ref Variables
    const maxEmployeesAllowedFlightinputRef = useRef("");
    const cheapestFlightWithLowestFareInputRef = useRef("");
    const allowTravelerToUpgradeClassInputRef = useRef("");
    const allowFastFlightWithMarginInputRef = useRef("");
    const timeBeforeMeetingStartTimeInputRef = useRef("");
    const timeRangeInputRef = useRef("");
    const cheapestHotelWithLowestRateInputRef = useRef("");



    //UseStates Variable
    const [postion, setPostion] = useState('');
    const [tripReason, setTripReason] = useState('');
    const [isTripReasonTouched, setIsTripReasonTouched] = useState(false);
    const [CabinClass, setCabinClass] = useState('');
    const [isCabinclassTouched, setIsCabinclassTouched] = useState(false);
    const [rbd, setRbd] = useState('');
    const [rbdValue, setRbdValue] = useState([]);
    const [isRbdTouched, setRbdTouched] = useState(false);
    const [regionFromCode, setRegionFromCode] = useState("-000");
    const [isFromRegionTouched, setIsFromRegionTouched] = useState(false);
    const [regionToCode, setRegionToCode] = useState("-000");
    const [isToRegionTouched, setIsToRegionTouched] = useState(false);
    const [lowestFareRule, setLowestFareRule] = useState('');
    const [isLowestFareRuleTouched, setIsLowestFareRuleTouched] = useState(false);
    const [allowTravelerToUpgradeClass, setAllowTravelerToUpgradeClass] = useState('');
    const [isAllowTravelerToUpgradeClassTouched, setIsAllowTravelerToUpgradeClassTouched] = useState(false);
    const [UpgradeClass, setUpgradeClass] = useState('');
    const [isUpgradeClassTouched, setIsUpgradeClassTouched] = useState(false);
    const [rating, setRating] = useState('');
    const [isRatingTouched, setIsRatingTouched] = useState(false);
    const [hotelRegion, setHotelRegion] = useState('-000');
    const [isHotelRegionTouched, setIsHotelRegionTouched] = useState(false);
    const [preferredAirlineOneValue, setPreferredAirlineOneValue] = useState('');
    const [airlinesAllowedTouched, setAirlinesAllowedTouched] = useState(false)
    const [preferredAirline, setPreferredAirline] = useState([]);
    const [lowestRateRule, setLowestRateRule] = useState('');
    const [isLowestRateRuleTouched, setIsLowestRateRuleTouched] = useState(false)
    const [timeRange, setTimeRange] = useState('');
    const [isTimeRangeTouched, setIsTimeRangeTouched] = useState(false);
    const [chainCode, setChainCode] = useState('');
    const [preferredHotel, setPreferredHotel] = useState([]);
    const [isPreferredHotelTouched, setIsPreferredHotelTouched] = useState(false);
    const [isFlightChecked, setFlightChecked] = useState(false);
    const [isHotelChecked, setHotelChecked] = useState(false);
    const [isServiceRequestChecked, setServiceRequestChecked] = useState(false);
    const [isServiceTouched, setIsServiceTouched] = useState(false);
    const [recommendationId, setRecommendationID] = useState("1");
    const [marginRule, setMarginRule] = useState(false);
    const [isMarginRuleTouched, setIsMarginRuleTouched] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [isFlightMarginTouched, setIsFlightMarginTouched] = useState(false);
    const [segmentOption, setSegmentOption] = useState('forEachSegment');
    const [isSegmentTouched, setIsSegmentTouched] = useState(false);
    const [hotelMarginRuleType, SetHotelMarginRuleType] = useState(null);
    const [hotelMarginRule, setHotelMarginRule] = useState(false);
    const [isHotelMarginRuleTouched, setIsHotelMarginRuleTouched] = useState(false);
    const [tripReasonApprovalProcess, setTripReasonApprovalProcess] = useState('')
    const [isApprovalTouched, setIsApprovalTouched] = useState(false);
    const [postionValue, setPostionValue] = useState([]);
    const [isPostionTouched, setPostionTouched] = useState(false);
    const [isDisable, setIsDisable] = useState(false);
    const [cheapestFlightWithLowestFareInput, setCheapestFlightWithLowestFareInput] = useState('');
    const [timeBeforeMeetingStartTime, setTimeBeforeMeetingStartTime] = useState('');
    const [isTimeBeforeMeetingStartTimeTouched, setIsTimeBeforeMeetingStartTimeTouched] = useState(false);
    const [cheapestHotelWithLowestRate, setCheapestHotelWithLowestRate] = useState('');
    const [isCheapestHotelWithLowestRate, setIsCheapestHotelWithLowestRateTouched] = useState(false);
    const [maxEmployeesAllowedFlight, setMaxEmployeesAllowedFlight] = useState('');
    const [isExecutiveTouched, setIsExecutiveTouched] = useState(false);
    const [isTouched, setIsTouched] = useState(false);
    const [recommendationTouched, setRecommendationTouched] = useState(false);
    const [flightCheckedAction, setFlightCheckedAction] = useState(false);
    const [hotelCheckedAction, setHotelCheckedAction] = useState(false);
    const [allowFastFlightWithMargin, setAllowFastFlightWithMargin] = useState('');
    const [isAllowFastFlightWithMarginTouched, setIsAllowFastFlightWithMarginTouched] = useState(false);

    //error state
    const [postionInputHasError, setPostionInputHasError] = useState(false);
    const [tripReasonInputHasError, setTripReasonInputHasError] = useState(false);
    const [fromRegionInputHasError, setfromRegionInputHasError] = useState(false);
    const [toRegionInputHasError, settoRegionInputHasError] = useState(false);
    const [maxAllowedEmpinvalidInput, setMaxAllowedEmpinvalidInput] = useState(false);
    const [lowestFareAmountInputError, setLowestAmountFareInputError] = useState(false);
    const [cabinClassInputHasError, setCabinClassInputHasError] = useState(false);
    const [allowedServiceError, setAllowedServiceError] = useState(false);
    const [cheapestFlightWithLowestFareInputError, setCheapestFlightWithLowestFareInputError] = useState(false);
    const [allowTravellerToUpgradeClassInvalidInput, setAllowTravellerToUpgradeClassInvalidInput] = useState(false);
    const [allowTravellerToUpgradeClassBlankInput, setAllowTravellerToUpgradeClassBlankInput] = useState(false);
    const [allowFastFlightWithMarginInvalidInput, setAllowFastFlightWithMarginInvalidInput] = useState(false);
    const [timeBeforeMeetingStartTimeInvalidInput, setTimeBeforeMeetingStartTimeInvalidInput] = useState(false);
    const [timeBeforeMeetingStartInputHasError, setTimeBeforeMeetingStartInputHasError] = useState(false);
    const [cheapestHotelWithLowestRateInvalidInput, setCheapestHotelWithLowestRateInvalidInput] = useState(false);
    const [cheapestHotelWithLowestRateInputHasError, setCheapestHotelWithLowestRateInputHasError] = useState(false);
    const [timeRangeInvalidInput, setTimeRangeInvalidInput] = useState(false);
    const [timeRangeInputHasError, setTimeRangeInputHasError] = useState(false);
    const [upgradeClassInputHasError, setUpgradeClassInputHasError] = useState(false);
    const [ischeapestFlightWithLowestFareTouched, setIscheapestFlightWithLowestFareTouched] = useState(false);


    //API Calls
    const { sendRequest: sendRequestRegion, data: loadedRegion } = useHttp(SearchRegionApi);
    const { sendRequest: sendRequestCommonListRbd, data: loadedRbd } = useHttp(searchCommonList);
    const { sendRequest: sendRequestAirline, statusAirline, data: loadedAirline } = useHttp(searchAllAirline);
    const { sendRequest: sendRequestHotelRegion, data: loadedHotelRegion } = useHttp(SearchRegionApi);
    const { sendRequest: sendRequestChainCode, statusChainCode, data: loadedChainCode } = useHttp(SearchHotelChain);
    const { sendRequest: sendRequestPostionList, data: loadedPostion } = useHttp(searchCorporateMaster);
    const { sendRequest: sendRequestTripReasonList, data: loadedTripReason, } = useHttp(searchTripReason);


    useEffect(() => {

        let isEditMode = parseInt(sessionStorage.getItem('isEditable'));
        setIsDisable(false);
        if (props.loadedPolicyDetails.length !== 0) {
            if (isPostionTouched === false && props.loadedPolicyDetails.position !== undefined) {
                setPostionValue(props.loadedPolicyDetails.position)
            }

            if (isTripReasonTouched === false && props.loadedPolicyDetails.tripReasonID !== undefined) {
                setTripReason(props.loadedPolicyDetails.tripReasonID)
            }
            if (flightCheckedAction === false) {
                setFlightChecked(props.loadedPolicyDetails.isFlight)
            }
            if (hotelCheckedAction === false) {
                setHotelChecked(props.loadedPolicyDetails.isHotel)
            }
            if (isServiceTouched === false) {
                setServiceRequestChecked(props.loadedPolicyDetails.isServiceRequest)
            }
            if (recommendationTouched === false) {
                setRecommendationID(props.loadedPolicyDetails.recommendations)
            }
            if (isCabinclassTouched === false && props.loadedPolicyDetails.highestClassCabin !== undefined) {
                setCabinClass(props.loadedPolicyDetails.highestClassCabin)
            }
            if (isFromRegionTouched === false && props.loadedPolicyDetails.fromRegion !== undefined) {
                setRegionFromCode(props.loadedPolicyDetails.fromRegion)
            }
            if (isToRegionTouched === false && props.loadedPolicyDetails.toRegion !== undefined) {
                setRegionToCode(props.loadedPolicyDetails.toRegion)
            }
            if (isRbdTouched === false && props.loadedPolicyDetails.rbdDetails !== undefined) {
                setRbdValue(props.loadedPolicyDetails.rbdDetails)
            }
            if (airlinesAllowedTouched === false && props.loadedPolicyDetails.preferredAirline !== undefined) {
                setPreferredAirline(props.loadedPolicyDetails.preferredAirline)
            }
            if (isMarginRuleTouched === false && props.loadedPolicyDetails.marginRule !== undefined) {
                setMarginRule(props.loadedPolicyDetails.marginRule)
            }
            if (isFlightMarginTouched === false) {
                setSelectedOption(props.loadedPolicyDetails.marginRuleType)
            }
            if (isLowestFareRuleTouched === false && props.loadedPolicyDetails.lowestFareRule !== undefined) {
                setLowestFareRule(props.loadedPolicyDetails.lowestFareRule);

            }
            if (isFlightChecked || props.loadedPolicyDetails.isFlight) {
                if (isExecutiveTouched === false) {
                    setMaxEmployeesAllowedFlight(props.loadedPolicyDetails.maxEmployeesAllowedFlight);
                }
                if (isAllowTravelerToUpgradeClassTouched === false) {
                    setAllowTravelerToUpgradeClass(props.loadedPolicyDetails.allowTravelerToUpgradeClass)
                }
                if (isAllowFastFlightWithMarginTouched === false) {
                    setAllowFastFlightWithMargin(props.loadedPolicyDetails.allowFastFlightWithMargin)
                }
                if (isTimeBeforeMeetingStartTimeTouched === false) {
                    setTimeBeforeMeetingStartTime(props.loadedPolicyDetails.timeBeforeMeetingStartTime);

                }
                if (isTimeRangeTouched === false) {
                    setTimeRange(props.loadedPolicyDetails.timeRange)
                }

            }
            if (ischeapestFlightWithLowestFareTouched === false) {
                setCheapestFlightWithLowestFareInput(props.loadedPolicyDetails.cheapestFlightWithLowestFare)
            }
            if (isUpgradeClassTouched === false && props.loadedPolicyDetails.allowToUpgradeClass !== undefined) {
                setUpgradeClass(props.loadedPolicyDetails.allowToUpgradeClass);
            }
            if (isSegmentTouched == false) {
                setSegmentOption(props.loadedPolicyDetails.fastestFilghtType);
            }


            if (isHotelRegionTouched === false) {
                setHotelRegion(props.loadedPolicyDetails.hotelRegion)
            }
            if (isRatingTouched === false && props.loadedPolicyDetails.rating !== undefined) {
                setRating(props.loadedPolicyDetails.rating)
            }
            if (isTouched === false && props.loadedPolicyDetails.hotelMarginRuleType !== undefined) {
                SetHotelMarginRuleType(props.loadedPolicyDetails.hotelMarginRuleType);
            }
            if (isCheapestHotelWithLowestRate === false) {
                setCheapestHotelWithLowestRate(props.loadedPolicyDetails.cheapestHotelWithLowestRate)
            }

            if (isLowestRateRuleTouched === false && props.loadedPolicyDetails.lowestRateRule !== undefined) {
                setLowestRateRule(props.loadedPolicyDetails.lowestRateRule);

            }
            if (isHotelMarginRuleTouched === false && props.loadedPolicyDetails.hotelMarginRule !== undefined) {
                setHotelMarginRule(props.loadedPolicyDetails.hotelMarginRule)
            }
            if (isPreferredHotelTouched === false && props.loadedPolicyDetails.preferredHotel !== undefined) {
                setPreferredHotel(props.loadedPolicyDetails.preferredHotel)
            }
            if (isApprovalTouched === false) {
                setTripReasonApprovalProcess(props.loadedPolicyDetails.tripReasonApprovalProcess);
            }

        }
        if (props.loadedPolicyDetails.length !== 0 && isEditMode === 100) {
            sessionStorage.setItem('isEditable', '1');
            isEditMode = parseInt(sessionStorage.getItem('isEditable'));
        }
        if (isEditMode === 0) {
            setPageTitle(" View Policy ")
            document.getElementById("btnSave").style.display = "none";
            document.getElementById("btnReset").style.display = "none";
            document.getElementById("btnNew").style.display = "none";
            setIsDisable(true);
            document.querySelector("#flightCheckBox").disabled = true;
            document.querySelector("#hotelCheckBox").disabled = true;
            document.querySelector("#serviceRequestCheckBox").disabled = true;
            if (document.querySelector("#marginRule") !== null) {
                document.querySelector("#marginRule").disabled = true;
            }
            if (document.getElementById("maxAllowedEmp") !== null) {
                document.getElementById("maxAllowedEmp").readOnly = true;
            }
            if (document.getElementById("amount") !== null) {
                document.getElementById("amount").disabled = true;
            }
            if (document.getElementById("percentage") !== null) {
                document.getElementById("percentage").disabled = true;
            }
            if (document.getElementById("cheapestFlightWithLowestFare") !== null) {
                document.getElementById("cheapestFlightWithLowestFare").readOnly = true;
            }
            if (document.getElementById("allowTravelerToUpgradeClass") !== null) {

                document.getElementById("allowTravelerToUpgradeClass").readOnly = true;
            }
            if (document.getElementById("forEachSegment") !== null) {
                document.getElementById("forEachSegment").disabled = true;
            }
            if (document.getElementById("completeItinerary") !== null) {
                document.getElementById("completeItinerary").disabled = true;
            }
            if (document.getElementById("allowFastFlightWithMargin") !== null) {
                document.getElementById("allowFastFlightWithMargin").readOnly = true;
            }
            if (document.getElementById("timeRange") !== null) {
                document.getElementById("timeRange").readOnly = true;
            }
            if (document.getElementById("hotelMarginRule") !== null) {
                document.getElementById("hotelMarginRule").disabled = true;
            }
            if (document.getElementById("hotelPercentage") !== null) {
                document.getElementById("hotelPercentage").disabled = true;
            }
            if (document.getElementById("hotelAmount") !== null) {
                document.getElementById("hotelAmount").disabled = true;
            }
            if (document.getElementById("timeBeforeMeetingStartTime") !== null) {
                document.getElementById("timeBeforeMeetingStartTime").readOnly = true;
            }
            if (document.getElementById("cheapestHotelWithLowestRate") !== null) {
                document.getElementById("cheapestHotelWithLowestRate").readOnly = true;
            }

            [].forEach.call(document.querySelectorAll('.error-icon'), function (el) {
                el.style.visibility = 'hidden';
            });
            document.getElementById("requiredIndicator").style.display = "none";

        }
        else if (isEditMode === 1) {
            setPageTitle(" Edit Policy ")
            setIsDisable(false);
            document.getElementById("btnNew").style.display = "";
            document.getElementById("btnSave").style.display = "";
            document.getElementById("btnReset").style.display = "";
            document.getElementById("requiredIndicator").style.display = "";

        }

        if (props.loadedPolicyDetails.length === 0) {
            setPageTitle(" New Policy ")
            document.getElementById("btnNew").style.display = "none";
            sessionStorage.setItem('isEditable', '100');

        }


    }, [props.loadedPolicyDetails.length, props.loadedPolicyDetails.position, props.loadedPolicyDetails.tripReasonID, props.loadedPolicyDetails.isFlight, props.loadedPolicyDetails.isHotel, props.loadedPolicyDetails.isServiceRequest, props.loadedPolicyDetails.recommendations, props.loadedPolicyDetails.highestClassCabin, props.loadedPolicyDetails.tripReasonApprovalProcess, props.loadedPolicyDetails.preferredHotel, props.loadedPolicyDetails.hotelMarginRule, props.loadedPolicyDetails.lowestRateRule, props.loadedPolicyDetails.cheapestHotelWithLowestRate, props.loadedPolicyDetails.hotelMarginRuleType, props.loadedPolicyDetails.fromRegion, props.loadedPolicyDetails.toRegion, props.loadedPolicyDetails.rbdDetails, props.loadedPolicyDetails.preferredAirline, isCheapestHotelWithLowestRate, isAllowTravelerToUpgradeClassTouched, isAllowFastFlightWithMarginTouched, isFromRegionTouched, isTimeBeforeMeetingStartTimeTouched, isTimeRangeTouched, isExecutiveTouched, isSegmentTouched, isToRegionTouched,
    props.loadedPolicyDetails.marginRule, props.loadedPolicyDetails.marginRuleType, props.loadedPolicyDetails.lowestFareRule, lowestFareRule, maxEmployeesAllowedFlight, allowTravelerToUpgradeClass, allowFastFlightWithMargin, timeBeforeMeetingStartTime, timeRange, UpgradeClass, segmentOption, hotelRegion, rating, hotelMarginRuleType, props.loadedPolicyDetails.maxEmployeesAllowedFlight, props.loadedPolicyDetails.allowTravelerToUpgradeClass, props.loadedPolicyDetails.allowFastFlightWithMargin, props.loadedPolicyDetails.timeBeforeMeetingStartTime, props.loadedPolicyDetails.timeRange, props.loadedPolicyDetails.cheapestFlightWithLowestFare, props.loadedPolicyDetails.allowToUpgradeClass, props.loadedPolicyDetails.fastestFilghtType, props.loadedPolicyDetails.hotelRegion, props.loadedPolicyDetails.rating, lowestRateRule, flightCheckedAction, hotelCheckedAction, isFlightChecked, isHotelChecked, recommendationTouched, airlinesAllowedTouched, tripReasonApprovalProcess, isMarginRuleTouched, isHotelMarginRuleTouched, ischeapestFlightWithLowestFareTouched, isFlightMarginTouched, isHotelRegionTouched, isServiceTouched, isPostionTouched, isTripReasonTouched, isCabinclassTouched, isRbdTouched, isUpgradeClassTouched, isLowestFareRuleTouched, isRatingTouched, isLowestRateRuleTouched, isApprovalTouched, isTouched]);


    useEffect(() => {
        if (postionOptions.length !== 0 && postionValue.length !== 0) {
            let temp = postionValue;
            let temp1 = [];
            temp.forEach(element => {
                let match = postionOptions.filter(item => item.value === element.positionID)
                if (match.length > 0) {
                    temp1.push(match[0]);
                }

            });
            if (temp1.length > 0) {
                setPostion(temp1);
            }

        }

    }, [loadedPostion, postionValue])
    useEffect(() => {

        if (rbdOptions.length !== 0 && rbdValue.length !== 0) {
            let temp = rbdValue;
            let temp1 = [];
            temp.forEach(element => {
                let match = rbdOptions.filter(item => item.value === element.rbdName)
                if (match.length > 0) {
                    temp1.push(match[0]);
                }

            });
            if (temp1.length > 0) {
                setRbd(temp1);
            }

        }

    }, [loadedRbd, rbdValue,])
    useEffect(() => {

        if (airlineOptions.length !== 0 && preferredAirline.length !== 0) {
            let temp = preferredAirline;
            let temp1 = [];
            temp.forEach(element => {
                let match = airlineOptions.filter(item => item.value === element.preferredAirlineCode)
                if (match.length > 0) {
                    temp1.push(match[0]);
                }

            });
            if (temp1.length > 0) {
                setPreferredAirlineOneValue(temp1);
            }

        }

    }, [loadedAirline, preferredAirline])

    useEffect(() => {

        if (chainCodeOptions.length !== 0 && preferredHotel.length !== 0) {
            let temp = preferredHotel;
            let temp1 = [];
            temp.forEach(element => {
                let match = chainCodeOptions.filter(item => item.value === element.preferredHotelCode)
                if (match.length > 0) {
                    temp1.push(match[0]);
                }

            });
            if (temp1.length > 0) {
                setChainCode(temp1);
            }

        }

    }, [loadedChainCode, preferredHotel])

    const history = useHistory();
    useEffect(() => {
        // window.scrollTo({
        //     top: 100,
        //     behavior: "smooth",
        // });
        const onSearchChainCode = ({
            hotelCode: "",
            hotelName: ""
        });

        sendRequestChainCode(onSearchChainCode);
        const onSearchHotelRegion = ({
            regionID: "",
            serviceID: '21',
            officeID: JSON.parse(localStorage.getItem('officeID')),
            getAllRegion: true
        });

        sendRequestHotelRegion(onSearchHotelRegion);
        const onSearchAirline = ({
            airlineCode: "",
            airlineName: ""
        });

        sendRequestAirline(onSearchAirline);

        const onSearchRegion = ({
            regionID: "",
            serviceID: '20',
            officeID: JSON.parse(localStorage.getItem('officeID')),
            getAllRegion: true
        });

        sendRequestRegion(onSearchRegion);
        const onSearchCommonListRbd = ({
            methodName: "RBD",
            methodCode: "RBD",
            listID: "",
            listValue: "",
            subListID: ""
        });
        const PostionInput = (
            {
                "id": '',
                userID: JSON.parse(localStorage.getItem('userID')),
                "name": "",
                "code": "",
                "keyCode": getConfigurationByValue('POLICY_TYPE'),
                "status": "2",
                "officeID": JSON.parse(localStorage.getItem("officeID"))
            }

        );
        const onSerachTripReason = ({
            id: "",
            traceID: JSON.parse(localStorage.getItem('traceID')),
            userID: JSON.parse(localStorage.getItem('userID')),
            iPAddress: localStorage.getItem("IPAddress"),
            name: "",
            code: "",
            meetingID: "",
            isFamilyBooking: true,
            officeID: JSON.parse(localStorage.getItem("officeID")),

        });

        sendRequestCommonListRbd(onSearchCommonListRbd);
        sendRequestPostionList(PostionInput);
        sendRequestTripReasonList(onSerachTripReason)

    }, [
        sendRequestRegion, sendRequestCommonListRbd, sendRequestAirline, sendRequestHotelRegion, sendRequestChainCode, sendRequestPostionList, sendRequestTripReasonList
    ])

    let chainCodeOptions = [];
    let chainCodeArrayData = [];
    for (let i in loadedChainCode) {
        if (loadedChainCode['errors'].status === 'FALSE') {
            if (i === 'data') {
                chainCodeArrayData = loadedChainCode[i];
            }
        }
    }

    for (let index = 0; index < chainCodeArrayData.length; index++) {
        chainCodeOptions = [...chainCodeOptions, { value: chainCodeArrayData[index].hotelCode, label: chainCodeArrayData[index].hotelName + " (" + chainCodeArrayData[index].hotelCode + ")" }]
    }

    let airlineArrayData = [];
    for (let i in loadedAirline) {
        if (loadedAirline['errors'].status === 'FALSE') {
            if (i === 'data') {
                airlineArrayData = loadedAirline[i];
            }
        }
    }

    let airlineOptions = [];
    for (let index = 0; index < airlineArrayData.length; index++) {
        airlineOptions = [...airlineOptions, { value: airlineArrayData[index].airlineCode, label: airlineArrayData[index].airlineName + " (" + airlineArrayData[index].airlineCode + ")" }]
    }
    let rbdArrayData = [];
    for (let i in loadedRbd) {
        if (loadedRbd['errors'].status === 'FALSE') {
            if (i === 'data') {
                rbdArrayData = loadedRbd[i];
            }
        }
    }

    let rbdOptions = [];
    for (let index = 0; index < rbdArrayData.length; index++) {
        rbdOptions = [...rbdOptions, { value: rbdArrayData[index].listValue, label: rbdArrayData[index].listValue }]
    }
    let regionOptions = []
    let regionArrayData = [];
    for (let i in loadedRegion) {
        if (loadedRegion['errors'].status === 'FALSE') {
            if (i === 'data') {
                regionArrayData = loadedRegion[i];
            }
        }
    }


    for (let index = 0; index < regionArrayData.length; index++) {
        regionOptions = [...regionOptions, { value: regionArrayData[index].id, label: regionArrayData[index].regionName }]
    }
    let hotelRegionOtions = []
    let hotelRegionArrayData = [];
    for (let i in loadedHotelRegion) {
        if (loadedHotelRegion['errors'].status === 'FALSE') {
            if (i === 'data') {
                hotelRegionArrayData = loadedHotelRegion[i];
            }
        }
    }


    for (let index = 0; index < hotelRegionArrayData.length; index++) {
        hotelRegionOtions = [...hotelRegionOtions, { value: hotelRegionArrayData[index].id, label: hotelRegionArrayData[index].regionName }]
    }
    let postionOptions = objectToArray(loadedPostion);
    let tripReasonOptions = objectToArray(loadedTripReason);

    //Change Handlers
    const maxEmployeesAllowedFlightChangeHandler = (event) => {
        setIsExecutiveTouched(true);
        setMaxEmployeesAllowedFlight(event.target.value);
    }
    const timeRangeChangeHandler = (event) => {
        setIsTimeRangeTouched(true);
        setTimeRange(event.target.value);
    }
    const allowTravelerToUpgradeClassChangeHandler = (event) => {
        setIsAllowTravelerToUpgradeClassTouched(true);
        setAllowTravelerToUpgradeClass(event.target.value);

        if (event.target.value.trim() === '' && UpgradeClass !== '' && UpgradeClass !== '00') {
            setAllowTravellerToUpgradeClassBlankInput(true);
        } else {
            setAllowTravellerToUpgradeClassBlankInput(false)
            if (!event.target.value.trim().match('^[0-9]+$') && UpgradeClass !== '' && UpgradeClass !== '00') {
                setAllowTravellerToUpgradeClassInvalidInput(true);
                return;
            } else {
                setAllowTravellerToUpgradeClassInvalidInput(false);
            }
        }
        if (event.target.value.trim() !== '' && (UpgradeClass === '' || UpgradeClass === '00')) {
            setUpgradeClassInputHasError(true);
            return;
        } else {
            setUpgradeClassInputHasError(false);
        }

    }
    const allowFastFlightWithMarginChangeHandler = (event) => {
        setIsAllowFastFlightWithMarginTouched(true);
        setAllowFastFlightWithMargin(event.target.value);
    }

    const cheapestHotelWithLowestRateChangeHandler = (event) => {
        setIsCheapestHotelWithLowestRateTouched(true);
        setCheapestHotelWithLowestRate(event.target.value);
        if (event.target.value === '') {
            setCheapestHotelWithLowestRateInvalidInput(true);
            setCheapestHotelWithLowestRateInputHasError(false);

        } else {
            setCheapestHotelWithLowestRateInvalidInput(false);
        }
        if (event.target.value !== '') {
            if (!event.target.value.match('^[0-9.]+$')) {
                setCheapestHotelWithLowestRateInvalidInput(false);
                setCheapestHotelWithLowestRateInputHasError(true);
                return;
            } else {
                setCheapestHotelWithLowestRateInputHasError(false);
            }

        }
    }
    const cheapestFlightWithLowestFareChangeHandler = (event) => {
        setIscheapestFlightWithLowestFareTouched(true);
        setCheapestFlightWithLowestFareInput(event.target.value);
        if (event.target.value === '') {
            setCheapestFlightWithLowestFareInputError(true);
            setLowestAmountFareInputError(false);
            return;
        } else {
            setCheapestFlightWithLowestFareInputError(false);
        }
        if (event.target.value !== null && !event.target.value.match('^[0-9]+(\.[0-9]{1,3})?$')) {
            setLowestAmountFareInputError(true);
            setCheapestFlightWithLowestFareInputError(false);
            return;
        }
        else {
            setLowestAmountFareInputError(false);
        }
    }
    const timeBeforeMeetingStartTimeChangeHandler = (event) => {
        setIsTimeBeforeMeetingStartTimeTouched(true);
        setTimeBeforeMeetingStartTime(event.target.value);
    }
    const flightChangeHandler = () => {
        setFlightCheckedAction(true);
        if (document.querySelector('#flightCheckBox').checked === true) {
            setFlightChecked(true);
            setAllowedServiceError(false);

        }
        else {
            setFlightChecked(false);
        }
    }
    const hotelChangeHandler = () => {
        setHotelCheckedAction(true);
        if (document.querySelector('#hotelCheckBox').checked === true) {
            setHotelChecked(true);
            setAllowedServiceError(false);
        }
        else {
            setHotelChecked(false);
        }
    }
    const serviceRequestChangeHandler = () => {
        setIsServiceTouched(true);
        if (document.querySelector('#serviceRequestCheckBox').checked === true) {
            setServiceRequestChecked(true);
            setAllowedServiceError(false);
        }
        else {
            setServiceRequestChecked(false);
        }
    }
    const positionChangeChangeHandler = (event) => {
        setPostionTouched(true);
        setPostion(event);
        setPostionInputHasError(false);
    };
    const lowestFareRuleChangeChangeHandler = (event) => {
        setIsLowestFareRuleTouched(true);
        setLowestFareRule(event.value);
        if (event.value === '00') {
            setMarginRule(false);
            setCheapestFlightWithLowestFareInput('');
        }

    };
    const cabinClassChangeChangeHandler = (event) => {
        setIsCabinclassTouched(true);
        setCabinClass(event.value);
        setCabinClassInputHasError(false);
    };

    const tripReasonChangeHandler = (event) => {
        setIsTripReasonTouched(true);
        setTripReason(event.value);
        setTripReasonInputHasError(false);
    };
    const regionFromChangeHandler = (event) => {
        setIsFromRegionTouched(true);
        setRegionFromCode(event.value);
    };

    const regionToChangeHandler = (event) => {
        setIsToRegionTouched(true);
        setRegionToCode(event.value);
    };
    const hotelRegionChangeHandler = (event) => {
        setIsHotelRegionTouched(true);
        setHotelRegion(event.value);
    }
    const rbdChangeHandler = (event) => {
        setRbdTouched(true);
        setRbd(event);
    };
    const preferredAirlineOneChangeHandler = (event) => {
        setAirlinesAllowedTouched(true);
        setPreferredAirlineOneValue(event)
    };
    const upgradeClassChangeChangeHandler = (event) => {
        setIsUpgradeClassTouched(true);
        setUpgradeClass(event.value);
        if (event.value.trim() !== '' && event.value !== '00' && allowTravelerToUpgradeClass === '') {
            setAllowTravellerToUpgradeClassBlankInput(true);
            return;
        } else {
            setAllowTravellerToUpgradeClassBlankInput(false);
        }
        if ((event.value.trim() === '' || event.value === '00') && allowTravelerToUpgradeClass !== '') {
            setUpgradeClassInputHasError(true);
            return;
        } else {
            setUpgradeClassInputHasError(false);
        }


    };
    const ratingChangeHandler = (event) => {
        setIsRatingTouched(true);
        setRating(event.value);
    };
    const lowestRateRuleChangeChangeHandler = (event) => {
        setIsLowestRateRuleTouched(true);
        setLowestRateRule(event.value);
        if (event.value === '00') {
            setCheapestHotelWithLowestRate('')
            setHotelMarginRule(false);
        }
    };
    const brandsChangeHandler = (event) => {
        setIsPreferredHotelTouched(true);
        setChainCode(event);
    };
    const recommendationChangeHandler = (event) => {
        setRecommendationTouched(true);
        setRecommendationID(event.value);
    };
    const marginRuleChangeHandler = () => {
        setIsMarginRuleTouched(true);
        setIscheapestFlightWithLowestFareTouched(true);
        setIsFlightMarginTouched(true);
        if (document.querySelector('#marginRule').checked === true) {
            setMarginRule(true);
            setSelectedOption('percentage');
            setCheapestFlightWithLowestFareInputError(true);
        }
        else {
            setMarginRule(false);
            setSelectedOption(null);
            setCheapestFlightWithLowestFareInputError(false);
            setLowestAmountFareInputError(false);
            setCheapestFlightWithLowestFareInput('');
        }
    }
    const handleRadioChange = (option) => {
        setIsFlightMarginTouched(true);
        setSelectedOption(option);
    };
    const handleSegmentChange = (option) => {
        setIsSegmentTouched(true);
        setSegmentOption(option);
    };
    const handleHotelMarginRuleTypeChange = (option) => {
        setIsTouched(true);
        SetHotelMarginRuleType(option);
    };
    const hotelMarginRuleChangeHandler = () => {
        setIsHotelMarginRuleTouched(true);
        setIsCheapestHotelWithLowestRateTouched(true);
        if (document.querySelector('#hotelMarginRule').checked === true) {
            setHotelMarginRule(true);
            SetHotelMarginRuleType('percentage');
            setCheapestHotelWithLowestRateInvalidInput(true);
        }
        else {
            setHotelMarginRule(false);
            SetHotelMarginRuleType(null);
            setCheapestHotelWithLowestRate('')
            setCheapestHotelWithLowestRateInputHasError(false);
            setCheapestHotelWithLowestRateInvalidInput(false);
        }
    }
    const handleWorkflowProcessChange = (event) => {
        setIsApprovalTouched(true);
        setTripReasonApprovalProcess(event.value)
    }
    function allowOnlyNumbers(event) {
        var input = event.target.value;
        var pattern = /^[1-9]\d{0,2}$/;
        if (!pattern.test(input)) {
            event.target.value = input.slice(0, 3);
        }

    }
    function allowOnlyNumbers1(event) {
        var input = event.target.value;
        var pattern = /^[1-9]\d{0,1}$/;
        if (!pattern.test(input)) {
            event.target.value = input.slice(0, 2);
        }
    }

    function onBlurCheck(event) {
        event.preventDefault();
        if (postion.length === 0) {
            setPostionInputHasError(true);
            return;
        } else {
            setPostionInputHasError(false);
        }
        if (tripReason.length === 0) {
            setTripReasonInputHasError(true);
            return;
        } else {
            setTripReasonInputHasError(false);
        }
        if (!isFlightChecked && !isHotelChecked && !isServiceRequestChecked) {
            setAllowedServiceError(true);
            return;
        } else {
            setAllowedServiceError(false);
        }

        if (isFlightChecked) {
            const enterMaxEmpAllowed = maxEmployeesAllowedFlight.trim();
            if (CabinClass === '') {
                setCabinClassInputHasError(true);
                return;
            } else {
                setCabinClassInputHasError(false);
            }
            if (enterMaxEmpAllowed !== '') {
                if (!enterMaxEmpAllowed.match('^[0-9]+$')) {
                    setMaxAllowedEmpinvalidInput(true);
                    return;
                }
                else {
                    setMaxAllowedEmpinvalidInput(false);
                }

            } else {
                setMaxAllowedEmpinvalidInput(false);
            }
            if (marginRule) {
                if (cheapestFlightWithLowestFareInput === '') {

                    setCheapestFlightWithLowestFareInputError(true);
                    setLowestAmountFareInputError(false);
                    return;
                } else {
                    setCheapestFlightWithLowestFareInputError(false);
                }
                if (cheapestFlightWithLowestFareInput !== null && !cheapestFlightWithLowestFareInput.match('^[0-9]+(\.[0-9]{1,3})?$')) {
                    setLowestAmountFareInputError(true);
                    setCheapestFlightWithLowestFareInputError(false);
                    return;
                }
                else {
                    setLowestAmountFareInputError(false);
                }

            } else {
                setCheapestFlightWithLowestFareInputError(false);
                setLowestAmountFareInputError(false);
            }
            const enteredAllowTravelellerToupgradeClass = allowTravelerToUpgradeClass.trim();
            const enteredAllowFastFlightWithMargin = allowFastFlightWithMargin.trim();
            const enteredTimeBeforeMeetingStartTime = timeBeforeMeetingStartTime.trim();
            if (enteredAllowTravelellerToupgradeClass !== '') {
                if (!enteredAllowTravelellerToupgradeClass.match('^[0-9]+$')) {
                    setAllowTravellerToUpgradeClassInvalidInput(true);
                    return;
                } else {
                    setAllowTravellerToUpgradeClassInvalidInput(false);
                }

            } else {
                setAllowTravellerToUpgradeClassInvalidInput(false);
            }
            if (segmentOption !== null && enteredAllowFastFlightWithMargin !== '') {
                if (!enteredAllowFastFlightWithMargin.match('^[0-9]+$')) {
                    setAllowFastFlightWithMarginInvalidInput(true);
                    return;
                } else {
                    setAllowFastFlightWithMarginInvalidInput(false);
                }

            } else {
                setAllowFastFlightWithMarginInvalidInput(false);
            }
            const enteredTimeRange = timeRange.trim();
            if (enteredTimeBeforeMeetingStartTime !== '') {
                if (!enteredTimeBeforeMeetingStartTime.match('^[0-9]+$')) {
                    setTimeBeforeMeetingStartTimeInvalidInput(true);
                    return;
                } else {
                    setTimeBeforeMeetingStartTimeInvalidInput(false);
                }
                if (enteredTimeRange === '') {
                    setTimeRangeInputHasError(true);
                    return;
                } else {
                    setTimeRangeInputHasError(false);
                }
            } else {
                setTimeBeforeMeetingStartTimeInvalidInput(false);
                setTimeRangeInputHasError(false);
            }
            if (enteredTimeRange !== '') {
                if (!enteredTimeRange.match('^[0-9]+$')) {
                    setTimeRangeInvalidInput(true);
                    return;
                } else {
                    setTimeRangeInvalidInput(false);
                }
                if (enteredTimeBeforeMeetingStartTime === '') {
                    setTimeBeforeMeetingStartInputHasError(true);
                    return;
                } else {
                    setTimeBeforeMeetingStartInputHasError(false);
                }

            } else {
                setTimeRangeInvalidInput(false);
                setTimeBeforeMeetingStartInputHasError(false);
            }

        }
        if (isHotelChecked) {
            const enteredCheapestHotelWithLowestRateInput = cheapestHotelWithLowestRate.trim();
            if (hotelMarginRule) {
                if (enteredCheapestHotelWithLowestRateInput === '') {
                    setCheapestHotelWithLowestRateInvalidInput(true);
                    setCheapestHotelWithLowestRateInputHasError(false);
                    return;

                } else {
                    setCheapestHotelWithLowestRateInvalidInput(false);
                }
                if (enteredCheapestHotelWithLowestRateInput !== '') {
                    if (!enteredCheapestHotelWithLowestRateInput.match('^[0-9.]+$')) {
                        setCheapestHotelWithLowestRateInvalidInput(false);
                        setCheapestHotelWithLowestRateInputHasError(true);
                        return;
                    } else {
                        setCheapestHotelWithLowestRateInputHasError(false);
                    }

                }

            }

        }

    }

    const submitFormHandler = (event) => {
        event.preventDefault();
        const tempPosition = [];
        const tempRbd = [];
        const preferredAirline = [];
        const tempLowestFareRule = [];
        const tempLowestRateRule = [];
        const preferredHotel = [];
        if (postion.length === 0) {
            setPostionInputHasError(true);
            const yOffset = -250;
            const element2 = document.getElementById("position");
            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
            return;
        } else {
            setPostionInputHasError(false);
        }
        if (tripReason.length === 0) {
            setTripReasonInputHasError(true);
            const yOffset = -250;
            const element2 = document.getElementById("tripReason");
            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
            return;
        } else {
            setTripReasonInputHasError(false);
        }
        if (!isFlightChecked && !isHotelChecked && !isServiceRequestChecked) {
            setAllowedServiceError(true);
            const yOffset = -250;
            const element2 = document.getElementById("flightCheckBox");
            const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
            return;
        } else {
            setAllowedServiceError(false);
        }
        if (isFlightChecked) {
            const enterMaxEmpAllowed = maxEmployeesAllowedFlight.trim();
            const enterdLowestFare = cheapestFlightWithLowestFareInput !== null ? cheapestFlightWithLowestFareInput.trim() : "";
            if (CabinClass === '') {
                setCabinClassInputHasError(true);
                const yOffset = -250;
                const element2 = document.getElementById("cabinClass");
                const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({ top: y, behavior: 'smooth' });
                return;
            } else {
                setCabinClassInputHasError(false);
            }
            if (enterMaxEmpAllowed !== '' && enterMaxEmpAllowed !== undefined) {
                if (!enterMaxEmpAllowed.match('^[0-9]+$')) {
                    setMaxAllowedEmpinvalidInput(true);
                    const yOffset = -250;
                    const element2 = document.getElementById("maxAllowedEmp");
                    const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({ top: y, behavior: 'smooth' });
                    return;
                }
                else {
                    setMaxAllowedEmpinvalidInput(false);
                }

            }

            if (lowestFareRule !== '' && lowestFareRule !== '00' && marginRule) {
                var element = document.getElementById("collapseTwo");
                if (enterdLowestFare === '') {
                    element.classList.add("show");
                    setCheapestFlightWithLowestFareInputError(true);
                    setLowestAmountFareInputError(false);
                    const yOffset = -250;
                    const element2 = document.getElementById("cheapestFlightWithLowestFare");
                    const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({ top: y, behavior: 'smooth' });
                    return;
                } else {
                    setCheapestFlightWithLowestFareInputError(false);
                }
                if (!enterdLowestFare.match('^[0-9]+(\.[0-9]{1,3})?$')) {
                    setLowestAmountFareInputError(true);
                    element.classList.add("show");
                    const yOffset = -250;
                    const element2 = document.getElementById("cheapestFlightWithLowestFare");
                    const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({ top: y, behavior: 'smooth' });
                    setCheapestFlightWithLowestFareInputError(false);
                    return;
                }
                else {
                    setLowestAmountFareInputError(false);
                }

            } else {
                setCheapestFlightWithLowestFareInputError(false);
                setLowestAmountFareInputError(false);
            }
            const enteredAllowTravelellerToupgradeClass = allowTravelerToUpgradeClass.trim();
            const enteredAllowFastFlightWithMargin = allowFastFlightWithMargin.trim();
            const enteredTimeBeforeMeetingStartTime = timeBeforeMeetingStartTime.trim();
            const enteredTimeRange = timeRange.trim();
            if (enteredAllowTravelellerToupgradeClass !== '') {
                var element = document.getElementById("collapseThree");

                if (UpgradeClass === '' || UpgradeClass === '00') {
                    element.classList.add("show");
                    setUpgradeClassInputHasError(true);
                    const yOffset = -250;
                    const element2 = document.getElementById("upgradeClass");
                    const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({ top: y, behavior: 'smooth' });
                    return;
                } else {
                    setUpgradeClassInputHasError(false);
                }
                if (!enteredAllowTravelellerToupgradeClass.match('^[0-9]+$')) {
                    element.classList.add("show");
                    allowTravelerToUpgradeClassInputRef.current.focus();
                    setAllowTravellerToUpgradeClassInvalidInput(true);
                    const yOffset = -250;
                    const element2 = document.getElementById("allowTravelerToUpgradeClass");
                    const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({ top: y, behavior: 'smooth' });

                    return;
                } else {
                    setAllowTravellerToUpgradeClassInvalidInput(false);
                }

            } else {
                setAllowTravellerToUpgradeClassInvalidInput(false);
            }
            if (UpgradeClass !== '' && UpgradeClass !== '00') {
                var element = document.getElementById("collapseThree");
                if (enteredAllowTravelellerToupgradeClass === '') {
                    setAllowTravellerToUpgradeClassBlankInput(true);
                    allowTravelerToUpgradeClassInputRef.current.focus();
                    const yOffset = -250;
                    const element2 = document.getElementById("allowTravelerToUpgradeClass");
                    const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({ top: y, behavior: 'smooth' });
                    element.classList.add("show");
                    return;
                } else {
                    setAllowTravellerToUpgradeClassBlankInput(false);
                }
            }


            if (segmentOption !== null && enteredAllowFastFlightWithMargin !== '') {
                var element = document.getElementById("collapseFour");
                if (!enteredAllowFastFlightWithMargin.match('^[0-9]+$')) {
                    element.classList.add("show");
                    allowFastFlightWithMarginInputRef.current.focus();
                    setAllowFastFlightWithMarginInvalidInput(true);
                    return;
                } else {
                    setAllowFastFlightWithMarginInvalidInput(false);
                }

            } else {
                setAllowFastFlightWithMarginInvalidInput(false);
            }
            if (enteredTimeBeforeMeetingStartTime !== '') {
                var element = document.getElementById("collapseEight");
                if (!enteredTimeBeforeMeetingStartTime.match('^[0-9]+$')) {
                    element.classList.add("show");
                    timeBeforeMeetingStartTimeInputRef.current.focus();
                    setTimeBeforeMeetingStartTimeInvalidInput(true);
                    return;
                } else {
                    setTimeBeforeMeetingStartTimeInvalidInput(false);
                }
                if (enteredTimeRange === '') {
                    element.classList.add("show");
                    timeRangeInputRef.current.focus();
                    setTimeRangeInputHasError(true);
                    return;
                } else {
                    setTimeRangeInputHasError(false);
                }
            } else {
                setTimeBeforeMeetingStartTimeInvalidInput(false);
                setTimeRangeInputHasError(false);
            }

            if (enteredTimeRange !== '') {
                var element = document.getElementById("collapseEight");
                if (!enteredTimeRange.match('^[0-9]+$')) {
                    element.classList.add("show");
                    timeRangeInputRef.current.focus();
                    setTimeRangeInvalidInput(true);
                    return;
                } else {
                    setTimeRangeInvalidInput(false);
                }
                if (enteredTimeBeforeMeetingStartTime === '') {
                    setTimeBeforeMeetingStartInputHasError(true);
                    element.classList.add("show");
                    timeBeforeMeetingStartTimeInputRef.current.focus();
                    return;
                } else {
                    setTimeBeforeMeetingStartInputHasError(false);
                }

            } else {
                setTimeRangeInvalidInput(false);
            }


        }
        if (isHotelChecked) {
            const enteredCheapestHotelWithLowestRateInput = cheapestHotelWithLowestRate.trim();
            let element;
            if (hotelMarginRule) {
                if (document.getElementById("collapseFive") !== null) {
                    element = document.getElementById("collapseFive");
                }
                if (enteredCheapestHotelWithLowestRateInput === '') {
                    setCheapestHotelWithLowestRateInvalidInput(true);
                    setCheapestHotelWithLowestRateInputHasError(false);
                    element !== undefined && element.classList.add("show");
                    const yOffset = -250;
                    const element2 = document.getElementById("cheapestHotelWithLowestRate");
                    const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({ top: y, behavior: 'smooth' });
                    return;

                } else {
                    setCheapestHotelWithLowestRateInvalidInput(false);
                }


                if (enteredCheapestHotelWithLowestRateInput !== "" && !enteredCheapestHotelWithLowestRateInput.match('^[0-9.]+$')) {
                    cheapestHotelWithLowestRateInputRef.current.focus();
                    element !== undefined && element.classList.add("show");
                    setCheapestHotelWithLowestRateInputHasError(true);
                    setCheapestHotelWithLowestRateInvalidInput(false);
                    const yOffset = -250;
                    const element2 = document.getElementById("cheapestHotelWithLowestRate");
                    const y = element2.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({ top: y, behavior: 'smooth' });
                    return;
                } else {
                    setCheapestHotelWithLowestRateInputHasError(false);
                }

            } else {
                setCheapestHotelWithLowestRateInvalidInput(false);
            }
        }
        if (isFlightChecked && isHotelChecked) {
            if (rbd.length === 0 && preferredAirlineOneValue.length === 0 && (lowestFareRule === '' || lowestFareRule === '00') && allowFastFlightWithMargin.trim() === '' && (timeBeforeMeetingStartTime.trim() === '' || timeRange === '') && (rating === '' && (lowestRateRule === "" || lowestRateRule === '00') && cheapestHotelWithLowestRate === '' && chainCode.length === 0)) {
                setError({
                    title: "Message",
                    message: `${t('flightAndHotelPolicyError')}`,
                });
                return;
            } else if (rbd.length === 0 && (preferredAirlineOneValue.length === 0) && (lowestFareRule === '' || lowestFareRule === '00') && allowFastFlightWithMargin.trim() === '' && (timeBeforeMeetingStartTime.trim() === '' || timeRange === '')) {
                setError({
                    title: "Message",
                    message: `${t('flightPolicyError')}`,
                });
                return;
            } else if (rating === '' && (lowestRateRule === "" || lowestRateRule === '00') && cheapestHotelWithLowestRate === '' && chainCode.length === 0) {
                setError({
                    title: "Message",
                    message: `${t('HotelPolicyError')}`,
                });
                return;
            } else {
                setError(null);
            }

        } else if (isFlightChecked && !isHotelChecked) {
            if (rbd.length === 0 && preferredAirlineOneValue.length === 0 && (lowestFareRule === '' || lowestFareRule === '00') && allowFastFlightWithMargin.trim() === '' && (timeBeforeMeetingStartTime.trim() === '' || timeRange === '')) {
                setError({
                    title: "Message",
                    message: `${t('flightPolicyError')}`,
                });
                return;
            } else {
                setError(null);
            }

        } else if (isHotelChecked && !isFlightChecked) {
            if (rating === '' && (lowestRateRule === "" || lowestRateRule === '00') && cheapestHotelWithLowestRate === '' && chainCode.length === 0) {
                setError({
                    title: "Message",
                    message: `${t('HotelPolicyError')}`,
                });
                return;
            } else {
                setError(null);
            }
        }

        let ID = '';
        if (props.loadedPolicyDetails.length !== 0) {
            ID = props.loadedPolicyDetails.id
        }
        if (postion !== '') {
            postion.forEach(element => {
                tempPosition.push({
                    "positionID": element.value,
                })
            });
        }
        if (rbd !== '') {
            rbd.forEach(element => {
                tempRbd.push({
                    "rbdName": element.value,
                })
            });
        }


        if (preferredAirlineOneValue !== '') {
            preferredAirlineOneValue.forEach(element => {
                preferredAirline.push({
                    "preferredAirlineCode": element.value,
                })
            });
        }

        if (chainCode !== '') {
            chainCode.forEach(element => {
                preferredHotel.push({
                    "preferredHotelCode": element.value,
                })
            });

        }

        const policy_input = ({
            "id": ID,
            traceID: JSON.parse(localStorage.getItem('traceID')),
            userID: JSON.parse(localStorage.getItem('userID')),
            iPAddress: localStorage.getItem("IPAddress"),
            "position": tempPosition,
            "tripReasonID": tripReason,
            "isFlight": isFlightChecked,
            "isHotel": isHotelChecked,
            "isServiceRequest": isServiceRequestChecked,
            "recommendations": recommendationId,
            "highestClassCabin": isFlightChecked ? CabinClass : "",
            "fromRegion": regionFromCode,
            "toRegion": regionToCode,
            "rbdDetails": isFlightChecked ? tempRbd : [],
            "preferredAirline": isFlightChecked ? preferredAirline : [],
            "maxEmployeesAllowedFlight": isFlightChecked ? maxEmployeesAllowedFlight : "",
            "lowestFareRule": isFlightChecked ? lowestFareRule : "",
            "marginRule": isFlightChecked ? marginRule : false,
            "marginRuleType": isFlightChecked ? selectedOption : "",
            "cheapestFlightWithLowestFare": isFlightChecked && cheapestFlightWithLowestFareInput !== null ? cheapestFlightWithLowestFareInput.trim() : "",
            "allowTravelerToUpgradeClass": isFlightChecked ? allowTravelerToUpgradeClass : "",
            "allowToUpgradeClass": isFlightChecked ? UpgradeClass : "",
            "fastestFilghtType": isFlightChecked ? segmentOption : "",
            "allowFastFlightWithMargin": isFlightChecked ? allowFastFlightWithMargin : "",
            "timeBeforeMeetingStartTime": isFlightChecked && timeBeforeMeetingStartTimeInputRef.current !== undefined ? timeBeforeMeetingStartTimeInputRef.current.value : "",
            "timeRange": isFlightChecked ? timeRange : "",
            "hotelRegion": isHotelChecked ? hotelRegion : "",
            "rating": isHotelChecked ? rating : "",
            "lowestRateRule": isHotelChecked ? lowestRateRule : "",
            "hotelMarginRule": isHotelChecked ? hotelMarginRule : false,
            "hotelMarginRuleType": isHotelChecked ? hotelMarginRuleType : "",
            "cheapestHotelWithLowestRate": isHotelChecked ? cheapestHotelWithLowestRate : "",
            "preferredHotel": isHotelChecked ? preferredHotel : [],
            "tripReasonApprovalProcess": tripReasonApprovalProcess,
            officeID: JSON.parse(localStorage.getItem("officeID")),
        });
        props.onAddPolicy(policy_input);

    }

    function changeLocation(event) {
        if (event.target.id === 'btnNew') {
            history.push('/UpdatePolicy')
            history.go()
        }

        if (event.target.id === 'btnReset') {
            if (props.loadedPolicyDetails.length === 0) {
                history.go('/UpdatePolicy')
            }
            else {
                history.go(`/UpdatePolicy/${props.loadedPolicyDetails.id}`)
            }
        }
    }
    const errorHandler = () => {
        setError(null);
    };
    useEffect(() => {
        if (props.loadedPolicyDetails.length === 0) {
            document.getElementById("btnSave").click();

        }
        window.scrollTo({
            top: 120,
            behavior: "smooth",
        });
    }, [props.loadedPolicyDetails.length]);
    return <Fragment>
        {error && (
            <ErrorModal
                title={error.title}
                message={error.message}
                onConfirm={errorHandler}
            ></ErrorModal>
        )}
        <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-2"></div>
                    <div className="col-lg-8 mb-30">
                        <div className="card card-primary card-outline">
                            {props.isLoading && (
                                <div className='loading'>
                                    <LoadingSpinner />
                                </div>
                            )}

                            <div className="card-body">
                                <div className="col-md-12 ">
                                    <div className="form-group col-md-12">
                                        <label htmlFor="" className="mb-2 h5 text-primary mb-3 col-lg-12"><i className="fas fa-globe-asia"></i><span id='pageTitle'> {pageTitle}</span></label>
                                        <form className='form'>
                                            <div className="col-md-12">
                                                <div className='row'>
                                                    <div className='col-md-12 col-sm-12 control'>
                                                        <label className="h6 text-primary mb-2 col-lg-12" id="position">{getConfigurationByValue(getConfigurationByValue('POLICY_TYPE'))} <span class="error-icon"> *</span></label>
                                                        <Dropdown
                                                            onChange={positionChangeChangeHandler}
                                                            value={postion}
                                                            options={postionOptions}
                                                            textField="label"
                                                            dataItemKey="value"
                                                            arrowClosed={<span className="arrow-closed" />}
                                                            arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                            isDisabled={isDisable}
                                                            isMulti={true}
                                                            autoFocus
                                                            onBlur={onBlurCheck}

                                                        />
                                                        {getConfigurationByValue(getConfigurationByValue('POLICY_TYPE')) === "Position" && postionInputHasError && <p className="error-text"> {t('positionValidation')}</p>}
                                                        {getConfigurationByValue(getConfigurationByValue('POLICY_TYPE')) === "Cost Center" && postionInputHasError && <p className="error-text"> {t('costCenterValidation')}</p>}
                                                        {getConfigurationByValue(getConfigurationByValue('POLICY_TYPE')) === "Department" && postionInputHasError && <p className="error-text"> {t('departmentValidation')}</p>}
                                                    </div>
                                                    <div className='col-md-12 col-sm-12 control'>
                                                        <label className="h6 text-primary mb-2 col-lg-12" id='tripReason'>{t('tripReason')} <span class="error-icon"> *</span></label>
                                                        <Dropdown onChange={tripReasonChangeHandler}
                                                            value={tripReasonOptions.filter(option => option.value === tripReason)}
                                                            options={tripReasonOptions}
                                                            textField="listValue"
                                                            dataItemKey="listID"
                                                            arrowClosed={<span className="arrow-closed" />}
                                                            arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                            isDisabled={isDisable}
                                                            onBlur={onBlurCheck}
                                                        />
                                                        {tripReasonInputHasError && <p className="error-text">{t('TripReasonValidation')}</p>}
                                                    </div>



                                                    <div>
                                                        <label className="h6 text-primary mb-2 col-lg-12" >Allowed Services</label>
                                                        <div className="row ">
                                                            <div className='col-md-3 col-sm-12 mb-2 '>
                                                                <span className="text-primary fw-bold"> <input id="flightCheckBox" className="form-check-input me-1" checked={isFlightChecked} type="checkbox" name="Flight" onChange={flightChangeHandler} value={isFlightChecked}></input>  {t('flight')}</span>
                                                            </div>
                                                            <div className='col-md-3 col-sm-12 mb-2'>
                                                                <span className="text-primary fw-bold"> <input id="hotelCheckBox" className="form-check-input me-1" type="checkbox" name="Hotel" checked={isHotelChecked} onChange={hotelChangeHandler} value={isHotelChecked}></input> {t('hotel')}</span>
                                                            </div>
                                                            <div className='col-md-4 col-sm-12 mb-2'>
                                                                <span className="text-primary fw-bold"> <input id="serviceRequestCheckBox" className="form-check-input me-1" type="checkbox" name="" checked={isServiceRequestChecked} onChange={serviceRequestChangeHandler} value={isServiceRequestChecked}></input> {t('serviceRequest')}</span>
                                                            </div>
                                                            {allowedServiceError && <p className="error-text pb-3">{t('serviceValidation')}</p>}
                                                        </div>
                                                    </div>

                                                    {isFlightChecked && <div class="tour_details_boxed_inner mobile-toggle-btn ">
                                                        <div className="accordion" id="accordionExample">
                                                            <div className="accordion_flex_area  mt-2  Accordion-toggle-icon">

                                                                <div className="accordion-item col-md-12">
                                                                    <h2 className="accordion-header" id="headingOne">
                                                                        <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                                                            data-bs-target="#collapseOne" aria-expanded="true"
                                                                            aria-controls="collapseOne"> <span> <i class="fas fa-plane-departure me-2"></i>  {t('flightRules')}</span>
                                                                        </button>
                                                                    </h2>
                                                                    <div id="collapseOne" className="accordion-collapse collapse show"
                                                                        aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                                        <div className="col-md-12">
                                                                            <div className="row py-2">
                                                                                <div className='col-md-6 col-sm-12 control'>
                                                                                    <label className="h6 text-primary mb-2 col-lg-12" htmlFor='fromRegion'>{t('fromRegion')}<span class="error-icon"> *</span></label>
                                                                                    <Dropdown onChange={regionFromChangeHandler}
                                                                                        value={regionOptions.filter(option => option.value === regionFromCode)}
                                                                                        options={regionOptions}
                                                                                        textField="regionName"
                                                                                        dataItemKey="id"
                                                                                        arrowClosed={<span className="arrow-closed" />}
                                                                                        arrowOpen={<span className="arrow-open" />}
                                                                                        controlClassName='form-control control form-select bg_input'
                                                                                        placeholder="All"
                                                                                        onBlur={onBlurCheck}
                                                                                        isDisabled={isDisable}

                                                                                    />
                                                                                    {fromRegionInputHasError && <p className="error-text">{t('fromRegionValidation')}</p>}
                                                                                </div>
                                                                                <div className='col-md-6 col-sm-12 control'>
                                                                                    <label className="h6 text-primary mb-2 col-lg-12" htmlFor='toRegion'>{t('toRegion')}<span class="error-icon"> *</span></label>
                                                                                    <Dropdown onChange={regionToChangeHandler}
                                                                                        value={regionOptions.filter(option => option.value === regionToCode)}
                                                                                        options={regionOptions}
                                                                                        textField="regionName"
                                                                                        dataItemKey="id"
                                                                                        arrowClosed={<span className="arrow-closed" />}
                                                                                        arrowOpen={<span className="arrow-open" />}
                                                                                        controlClassName='form-control control form-select bg_input'
                                                                                        onBlur={onBlurCheck}
                                                                                        isDisabled={isDisable}
                                                                                        placeholder="All" />
                                                                                    {toRegionInputHasError && <p className="error-text">{t('toRegionValidation')}</p>}
                                                                                </div>
                                                                            </div>
                                                                            <div className="row ">
                                                                                <div className='col-md-12 col-sm-12 control'>
                                                                                    <label className="h6 text-primary mb-2 col-lg-12" id='cabinClass'>{t('cabinClass')} <span class="error-icon"> *</span></label>
                                                                                    <Dropdown
                                                                                        onChange={cabinClassChangeChangeHandler}
                                                                                        value={cabinClassOptions.filter(option => option.value === CabinClass)}
                                                                                        options={cabinClassOptions}
                                                                                        textField="label"
                                                                                        dataItemKey="value"
                                                                                        arrowClosed={<span className="arrow-closed" />}
                                                                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                                                        isDisabled={isDisable}
                                                                                        onBlur={onBlurCheck}

                                                                                    />
                                                                                    {cabinClassInputHasError && <p className="error-text">{t('cabinClassValidation')}</p>}
                                                                                </div>

                                                                                <div className='col-md-6 col-sm-12 control'>
                                                                                    <label className="h6 text-primary mb-2 col-lg-12" htmlFor=''>{t('rbd')}</label>
                                                                                    <Dropdown onChange={rbdChangeHandler}
                                                                                        value={rbd}
                                                                                        options={rbdOptions}
                                                                                        arrowClosed={<span className="arrow-closed" />}
                                                                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0'
                                                                                        placeholder="Please Select"
                                                                                        isMulti={true}
                                                                                        isDisabled={isDisable}
                                                                                    />
                                                                                </div>
                                                                                <div className='col-md-6 col-sm-12 control'>
                                                                                    <label className="h6 text-primary mb-2 col-lg-12" htmlFor=''>{t('airlinePreference')}</label>
                                                                                    <Dropdown onChange={preferredAirlineOneChangeHandler}
                                                                                        value={preferredAirlineOneValue}
                                                                                        options={airlineOptions}
                                                                                        textField="label"
                                                                                        dataItemKey="value"
                                                                                        arrowClosed={<span className="arrow-closed" />}
                                                                                        arrowOpen={<span className="arrow-open" />} controlClassName='mt-1 form-control control dropdown-search form-select bg_input mb-0'
                                                                                        placeholder="Please Select"
                                                                                        isMulti={true}
                                                                                        isDisabled={isDisable}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>}
                                                </div>
                                            </div>


                                            {isFlightChecked && <div>
                                                <div class="tour_details_boxed_inner mobile-toggle-btn">
                                                    <div className="accordion" id="accordionExampleTwo">
                                                        <div className="accordion_flex_area  mt-2  Accordion-toggle-icon">

                                                            <div className="accordion-item col-md-12">
                                                                <h2 className="accordion-header" id="headingTwo">
                                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                                        data-bs-target="#collapseTwo" aria-expanded="true"
                                                                        aria-controls="collapseTwo">
                                                                        <span>
                                                                            <i class="fas fa-arrow-down me-2"></i>  {t('lowestFareandMarginRule')}
                                                                        </span>
                                                                    </button>
                                                                </h2>
                                                                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExampleTwo">
                                                                    <div className="col-md-12 rule-box">
                                                                        <div className='row '>
                                                                            <div className='col-md-12 col-sm-12 control'>
                                                                                <label className="h6 text-primary mb-2 col-lg-12" htmlFor=''>{t('lowestFareRule')}</label>
                                                                                <Dropdown
                                                                                    onChange={lowestFareRuleChangeChangeHandler}
                                                                                    value={lowestFareRuleOptions.filter(option => option.value === lowestFareRule)}
                                                                                    options={lowestFareRuleOptions}
                                                                                    textField="label"
                                                                                    dataItemKey="value"
                                                                                    arrowClosed={<span className="arrow-closed" />}
                                                                                    arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                                                    isDisabled={isDisable}
                                                                                    isMulti={false}

                                                                                />

                                                                            </div>
                                                                            {lowestFareRule !== '' && lowestFareRule !== '00' && < div class="row">
                                                                                <div className='col-md-4 col-sm-12 control '>
                                                                                    <div className="form-switch form-switch-sm padding-left-0">
                                                                                        <label htmlFor="" className="mb-2 h6 text-primary">   {t('marginRule')}</label>
                                                                                        <input id="marginRule" className="form-check-input me-1 ms-3" type="checkbox" checked={marginRule} value={marginRule} onChange={marginRuleChangeHandler} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-md-4 col-sm-12 control'>
                                                                                    <span class="text-primary"> <input id="percentage" className="form-check-input me-1" type="radio" name="fav_language" disabled={!marginRule} checked={selectedOption === 'percentage'}
                                                                                        onChange={() => handleRadioChange('percentage')} />  {t('percentage')}</span>
                                                                                </div>
                                                                                <div className='col-md-4 col-sm-12 control'>
                                                                                    <span class="text-primary"> <input id="percentage"
                                                                                        className="form-check-input me-1" type="radio" name="fav_language"
                                                                                        disabled={!marginRule}
                                                                                        checked={selectedOption === 'amount'}
                                                                                        onChange={() => handleRadioChange('amount')}
                                                                                    />  {t('amount')}</span>
                                                                                </div>
                                                                            </div>}
                                                                        </div>

                                                                        {lowestFareRule !== '' && lowestFareRule !== '00' && <div className='col-md-12 col-sm-12 mb-2'>
                                                                            <label className="h6 text-primary mb-2 col-lg-12" htmlFor=''>{t('cheapestFlight')}</label>
                                                                            <input className="mb-2 form-control form-control-sm col-lg-12"
                                                                                type='text' disabled={!marginRule} useRef={cheapestFlightWithLowestFareInputRef} onBlur={onBlurCheck} maxLength={20}
                                                                                value={cheapestFlightWithLowestFareInput} onChange={cheapestFlightWithLowestFareChangeHandler}
                                                                                id="cheapestFlightWithLowestFare"
                                                                            ></input>
                                                                            {lowestFareAmountInputError && <p className="error-text">{t('cheapestFlightWithLowestFareInputValidation')}</p>}
                                                                            {cheapestFlightWithLowestFareInputError && <p className="error-text">{t('cheapestFlightWithLowestFareValidation')}</p>}
                                                                        </div>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}
                                            {isFlightChecked && <div>
                                                <div class="tour_details_boxed_inner mobile-toggle-btn">
                                                    <div className="accordion" id="accordionExampleThree">
                                                        <div className="accordion_flex_area  mt-2  Accordion-toggle-icon">

                                                            <div className="accordion-item col-md-12">
                                                                <h2 className="accordion-header" id="headingTwo">
                                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                                        data-bs-target="#collapseThree" aria-expanded="true"
                                                                        aria-controls="collapseThree"> <span>
                                                                            <i class="fas fa-file-alt me-2"></i>  {t('upgradeRulePolicy')}
                                                                        </span>
                                                                    </button>
                                                                </h2>
                                                                <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExampleThree">
                                                                    <div className="col-md-12 rule-box control mt-1">
                                                                        <div className='col-md-12 col-sm-12 mb-2'>
                                                                            <label className="h6 text-primary col-lg-12" htmlFor=''>{t('nextHigher')}</label>
                                                                            <div class="input-group policy-input-div">
                                                                                <input type="number" class="form-control mb-2" aria-describedby="basic-addon2"
                                                                                    ref={allowTravelerToUpgradeClassInputRef} id="allowTravelerToUpgradeClass"
                                                                                    value={allowTravelerToUpgradeClass} onChange={allowTravelerToUpgradeClassChangeHandler}
                                                                                    onBlur={onBlurCheck} maxLength={3} min={'1'} onInput={allowOnlyNumbers}
                                                                                />
                                                                                <span class="input-group-text" id="basic-addon2">{t('minute')}</span>
                                                                            </div>
                                                                            {allowTravellerToUpgradeClassInvalidInput && <p className="error-text">{t('allowTravellerToUpgradeClassValidation')}</p>}
                                                                            {allowTravellerToUpgradeClassBlankInput && <p className="error-text">{t('allowTravellerToUpgradeBlankValidation')}</p>}
                                                                        </div>

                                                                        <div className='col-md-12 col-sm-12 control'>
                                                                            <label className="h6 text-primary mb-2 col-lg-12" id="upgradeClass">{t('economyClass')}</label>
                                                                            <Dropdown onChange={upgradeClassChangeChangeHandler}
                                                                                value={upgradeClassOptions.filter(option => option.value === UpgradeClass)}
                                                                                options={upgradeClassOptions}
                                                                                textField="label"
                                                                                dataItemKey="value"
                                                                                arrowClosed={<span className="arrow-closed" />}
                                                                                arrowOpen={<span className="arrow-open" />} controlClassName='mt-1 form-control control dropdown-search form-select bg_input mb-0'
                                                                                placeholder="Please Select"
                                                                                isDisabled={isDisable}

                                                                            />
                                                                            {upgradeClassInputHasError && <p className="error-text">{t('upgradeClassInputValidation')}</p>}
                                                                        </div>


                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}
                                            {isFlightChecked && <div>
                                                <div class="tour_details_boxed_inner mobile-toggle-btn">
                                                    <div className="accordion" id="accordionExampleFour">
                                                        <div className="accordion_flex_area  mt-2  Accordion-toggle-icon">

                                                            <div className="accordion-item col-md-12">
                                                                <h2 className="accordion-header" id="headingTwo">
                                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                                        data-bs-target="#collapseFour" aria-expanded="true"
                                                                        aria-controls="collapseFour"> <span>
                                                                            <i class="fas fa-clock me-1"></i> {t('timeWindow')}</span> </button>
                                                                </h2>
                                                                <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExampleFour">
                                                                    <div className="col-md-12 rule-box">
                                                                        <div className='row mt-2'>
                                                                            <div className='col-md-4 col-sm-12 control '>
                                                                                <span className="text-primary"> <input id="forEachSegment" className="form-check-input me-1" type="radio" name="segment"
                                                                                    checked={segmentOption === 'forEachSegment'}
                                                                                    onChange={() => handleSegmentChange('forEachSegment')}

                                                                                ></input>	 {t('forEachSegment')}</span>
                                                                            </div>
                                                                            <div className='col-md-4 col-sm-12 control'>
                                                                                <span className="text-primary"> <input id="completeItinerary" className="form-check-input me-1" type="radio" name="segment"
                                                                                    checked={segmentOption === 'completeItinerary'}
                                                                                    onChange={() => handleSegmentChange('completeItinerary')}></input> {t('completeItinerary')}</span>
                                                                            </div>

                                                                        </div>
                                                                        <div className='row'>
                                                                            <div className='col-md-12 col-sm-12 control'>
                                                                                <label className="h6 text-primary mb-2 col-lg-12"
                                                                                    htmlFor=''>
                                                                                    {t('allowFastFlightsWithinMargin')}
                                                                                </label>
                                                                                <div class="input-group policy-input-div">
                                                                                    <input type="number" class="form-control mb-2" aria-describedby="basic-addon3"
                                                                                        ref={allowFastFlightWithMarginInputRef} id="allowFastFlightWithMargin"
                                                                                        onBlur={onBlurCheck} maxLength={3} min={'1'} onInput={allowOnlyNumbers}
                                                                                        value={allowFastFlightWithMargin} onChange={allowFastFlightWithMarginChangeHandler}
                                                                                    />
                                                                                    <span class="input-group-text" id="basic-addon3">{t('minute')}</span>
                                                                                </div>
                                                                                {allowFastFlightWithMarginInvalidInput && <p className="error-text pb-0">{t('allowFastFlightWithMarginValidation')}</p>}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}
                                            {getConfigurationByBoolen('ALLOW_TIME_WiNDOW_POLICY') && isFlightChecked && <div className="">
                                                <div class="tour_details_boxed_inner mobile-toggle-btn">
                                                    <div className="accordion" id="accordionExampleEight">
                                                        <div className="accordion_flex_area  mt-2  Accordion-toggle-icon">

                                                            <div className="accordion-item col-md-12">
                                                                <h2 className="accordion-header" id="headingEight">
                                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                                        data-bs-target="#collapseEight" aria-expanded="true"
                                                                        aria-controls="collapseEight"> <span><i class="fas fa-clock me-1"></i> {t('timeWindowForMeetingStartTime')}
                                                                        </span>
                                                                    </button>
                                                                </h2>
                                                                <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExampleEight">

                                                                    <div className="col-md-12 rule-box">
                                                                        <div className="row">
                                                                            <div className='col-md-12 col-sm-12 mb-2'>
                                                                                <label className="h6 text-primary col-lg-12" >
                                                                                    {t('timebeforeMeetingStart')}
                                                                                </label>
                                                                                <div class="input-group policy-input-div">
                                                                                    <input type="number" class="form-control mb-2" aria-describedby="basic-addon2"
                                                                                        ref={timeBeforeMeetingStartTimeInputRef} id="timeBeforeMeetingStartTime" value={timeBeforeMeetingStartTime} onChange={timeBeforeMeetingStartTimeChangeHandler}
                                                                                        onBlur={onBlurCheck} maxLength={3} min={'1'} onInput={allowOnlyNumbers} />
                                                                                    <span class="input-group-text" id="basic-addon2">
                                                                                        {t('minute')}
                                                                                    </span>
                                                                                </div>
                                                                                {timeBeforeMeetingStartTimeInvalidInput && <p className="error-text">{t('timeBeforeMeetingStartTimeValidation')}</p>}
                                                                                {timeBeforeMeetingStartInputHasError && <p className="error-text">{t('timeBeforeMeetingStartTimeInputValidation')}</p>}

                                                                            </div>
                                                                            <div className="col-md-12 control">
                                                                                <label className="h6 text-primary col-lg-12" > {t('and')} </label>

                                                                                <div class="input-group policy-input-div">
                                                                                    <input type="number" class="form-control mb-2" aria-describedby="basic-addon2" ref={timeRangeInputRef} id="timeRange" onBlur={onBlurCheck} maxLength={3} onInput={allowOnlyNumbers} min={'1'} value={timeRange} onChange={timeRangeChangeHandler} />
                                                                                    <span class="input-group-text" id="basic-addon2">{t('minute')}</span>
                                                                                </div>
                                                                                <label className="h6 text-primary col-lg-12" > {t('timeRange')}</label>
                                                                                {timeRangeInvalidInput && <p className="error-text">{t('timeRangeValidation')}</p>}
                                                                                {timeRangeInputHasError && <p className="error-text">{t('timeRangeInputValidation')}</p>}
                                                                            </div>

                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}

                                            {isFlightChecked && <div class="tour_details_boxed_inner mobile-toggle-btn">
                                                <div className="accordion" id="accordionExampleNine">
                                                    <div className="accordion_flex_area  mt-2  Accordion-toggle-icon">

                                                        <div className="accordion-item col-md-12">
                                                            <h2 className="accordion-header" id="headingNine">
                                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                                    data-bs-target="#collapseNine" aria-expanded="true"
                                                                    aria-controls="collapseNine"> <span><i class="fas fa-user-tie me-2"></i>  {t('executiveAllowed')}</span>
                                                                </button>
                                                            </h2>
                                                            <div id="collapseNine" className="accordion-collapse collapse "
                                                                aria-labelledby="headingNine" data-bs-parent="#accordionExampleNine">
                                                                <div className="col-md-12">
                                                                    <div className="row py-2">
                                                                        <div className='col-md-12 col-sm-12 control'>
                                                                            <label className="h6 text-primary mb-2 col-lg-12" htmlFor='maxAllowedEmp'>{t('executives')}</label>
                                                                            <input className="mb-2 form-control form-control-sm col-lg-12" type={"number"} ref={maxEmployeesAllowedFlightinputRef} onBlur={onBlurCheck} id="maxAllowedEmp" value={maxEmployeesAllowedFlight} onChange={maxEmployeesAllowedFlightChangeHandler} maxLength={2} min={'1'} onInput={allowOnlyNumbers1}></input>
                                                                            {maxAllowedEmpinvalidInput && <p className="error-text">{t('maxAllowedEmpValidation')}</p>}

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}
                                            {isHotelChecked && <label className="h5 text-black mt-3 mb-3 col-lg-12" ><i class="fas fa-hotel"></i>  {t('hotel')}</label>}
                                            {isHotelChecked && <div className="col-md-12 rule-box">
                                                <div className='row'>
                                                    <div className='col-md-6 col-sm-12 control'>
                                                        <label className="h6 text-primary mb-2 col-lg-12" htmlFor='hotelRegion'>{t('hotelRegion')}<span class="error-icon"> *</span></label>
                                                        <Dropdown onChange={hotelRegionChangeHandler}
                                                            value={hotelRegionOtions.filter(option => option.value === hotelRegion)}
                                                            options={hotelRegionOtions}
                                                            textField="regionName"
                                                            dataItemKey="id"
                                                            arrowClosed={<span className="arrow-closed" />}
                                                            arrowOpen={<span className="arrow-open" />}
                                                            controlClassName='form-control control form-select bg_input'
                                                            onBlur={onBlurCheck}
                                                            isDisabled={isDisable}
                                                            placeholder="All" />
                                                        {toRegionInputHasError && <p className="error-text">{t('toRegionValidation')}</p>}
                                                    </div>
                                                    <div className='col-md-6 col-sm-12 control'>
                                                        <label className="h6 text-primary mb-2 col-lg-12" >  {t('rating')}</label>
                                                        <Dropdown onChange={ratingChangeHandler}
                                                            value={ratingOptions.filter(option => option.value === rating)}
                                                            options={ratingOptions}
                                                            textField="label"
                                                            dataItemKey="value"
                                                            arrowClosed={<span className="arrow-closed" />}
                                                            arrowOpen={<span className="arrow-open" />}
                                                            controlClassName='form-control control form-select bg_input'
                                                            placeholder="Please Select" isDisabled={isDisable} />
                                                    </div>
                                                </div>
                                            </div>}
                                            {isHotelChecked && <div>
                                                <div class="tour_details_boxed_inner mobile-toggle-btn">
                                                    <div className="accordion" id="accordionExampleFive">
                                                        <div className="accordion_flex_area  mt-2  Accordion-toggle-icon">

                                                            <div className="accordion-item col-md-12">
                                                                <h2 className="accordion-header" id="headingfive">
                                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                                        data-bs-target="#collapseFive" aria-expanded="true"
                                                                        aria-controls="collapseFive"> <span> <i class="fas fa-arrow-down me-2"></i> {t('lowestRateRulesAndMargin')} </span> </button>
                                                                </h2>
                                                                <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingfive" data-bs-parent="#accordionExampleFive">
                                                                    <div className="col-md-12 rule-box">
                                                                        <div className='row'>
                                                                            <div className='col-md-12 col-sm-12 control'>
                                                                                <label className="h6 text-primary mb-2 col-lg-12" >
                                                                                    {t('lowestRateRules')}
                                                                                </label>
                                                                                <Dropdown
                                                                                    onChange={lowestRateRuleChangeChangeHandler}
                                                                                    value={lowestRateRuleOptions.filter(option => option.value === lowestRateRule)}
                                                                                    options={lowestRateRuleOptions}
                                                                                    textField="label"
                                                                                    dataItemKey="value"
                                                                                    arrowClosed={<span className="arrow-closed" />}
                                                                                    arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                                                    isDisabled={isDisable}
                                                                                    isMulti={false}
                                                                                />
                                                                            </div>
                                                                            {lowestRateRule !== '' && lowestRateRule !== '00' && <div className="row">
                                                                                <div className='col-md-4 col-sm-12 control '>
                                                                                    <div className="form-switch form-switch-sm padding-left-0">
                                                                                        <label htmlFor="" className="mb-2 h6 text-primary">   {t('marginRule')}</label>
                                                                                        <input id="hotelMarginRule" className="form-check-input me-1 ms-3" type="checkbox" checked={hotelMarginRule} value={hotelMarginRule} onChange={hotelMarginRuleChangeHandler} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-md-4 col-sm-12 control'>
                                                                                    <span class="text-primary"> <input id="hotelPercentage" className="form-check-input me-1 ms-3" type="radio" disabled={!hotelMarginRule} name="fav_language1"
                                                                                        checked={hotelMarginRuleType === 'percentage'}
                                                                                        onChange={() => handleHotelMarginRuleTypeChange('percentage')}
                                                                                    />  {t('percentage')}</span>
                                                                                </div>
                                                                                <div className='col-md-4 col-sm-12 control'>
                                                                                    <span class="text-primary"> <input id="hotelAmount" className="form-check-input me-1 ms-3" type="radio" name="fav_language1" disabled={!hotelMarginRule}
                                                                                        checked={hotelMarginRuleType === 'amount'}
                                                                                        onChange={() => handleHotelMarginRuleTypeChange('amount')}
                                                                                    />  {t('amount')}</span>
                                                                                </div>
                                                                                <div className='col-md-12 col-sm-12 control'>
                                                                                    <label className="h6 text-primary mb-2 col-lg-12" >
                                                                                        {t('allowCheapestHotelRooms')}
                                                                                    </label>
                                                                                    <input className="mb-2 form-control form-control-sm col-lg-12" type='text' disabled={!hotelMarginRule} ref={cheapestHotelWithLowestRateInputRef} id="cheapestHotelWithLowestRate" maxLength={20} onBlur={onBlurCheck} value={cheapestHotelWithLowestRate} onChange={cheapestHotelWithLowestRateChangeHandler}></input>
                                                                                    {cheapestHotelWithLowestRateInvalidInput && <p className="error-text">{t('cheapestHotelWithLowestRateValidation')}</p>}
                                                                                    {cheapestHotelWithLowestRateInputHasError && <p className="error-text">{t('cheapestHotelWithLowestRateInputValidation')}</p>}
                                                                                </div></div>}

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}
                                            {isHotelChecked && <div className='col-md-12 col-sm-12 control'>
                                                <div class="tour_details_boxed_inner mobile-toggle-btn">
                                                    <div className="accordion" id="accordionExampleTen">
                                                        <div className="accordion_flex_area  mt-2  Accordion-toggle-icon">

                                                            <div className="accordion-item col-md-12 pb-0">
                                                                <h2 className="accordion-header" id="headingTen">
                                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                                        data-bs-target="#collapseTen" aria-expanded="true"
                                                                        aria-controls="collapseTen"><span> <i class="fas fa-tag me-1"></i>   {t('preferredHotel')}</span> </button>
                                                                </h2>
                                                                <div id="collapseTen" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExampleTen">
                                                                    <div className="col-md-12 rule-box">
                                                                        <label className="h6 text-primary mb-2 col-lg-12" >
                                                                            {t('preferredHotelBrands')}
                                                                        </label>
                                                                        <Dropdown onChange={brandsChangeHandler}
                                                                            value={chainCode}
                                                                            options={chainCodeOptions}
                                                                            textField="label"
                                                                            dataItemKey="value"
                                                                            arrowClosed={<span className="arrow-closed" />}
                                                                            arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                                            isMulti={true}
                                                                            isDisabled={isDisable}
                                                                        />
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}
                                            <div>
                                                <div class="tour_details_boxed_inner mobile-toggle-btn mobile-toggle-btn">
                                                    <div className="accordion" id="accordionExampleEleven">
                                                        <div className="accordion_flex_area  mt-2  Accordion-toggle-icon">

                                                            <div className="accordion-item col-md-12">
                                                                <h2 className="accordion-header" id="headingSix">
                                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                                        data-bs-target="#collapseEleven" aria-expanded="true"
                                                                        aria-controls="collapseEleven"> <span><i class="fas fa-file-contract me-1"></i>   {t('tripReasonWiseworkflowProcess')}
                                                                        </span> </button>
                                                                </h2>
                                                                <div id="collapseEleven" className="accordion-collapse collapse" aria-labelledby="headingEleven" data-bs-parent="#accordionExampleEleven">
                                                                    <div className="col-md-12 rule-box">
                                                                        <div className='row'>
                                                                            <div className='col-md-12 col-sm-12 control'>
                                                                                <label className="h6 text-primary mb-2 col-lg-12" >
                                                                                    {t('workflowProcess')}
                                                                                </label>
                                                                                <Dropdown
                                                                                    value={approvalOptions.filter(option => option.value === tripReasonApprovalProcess)}
                                                                                    onChange={handleWorkflowProcessChange}
                                                                                    options={approvalOptions}
                                                                                    textField="label"
                                                                                    dataItemKey="value"
                                                                                    arrowClosed={<span className="arrow-closed" />}
                                                                                    arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                                                    isDisabled={isDisable}
                                                                                    isMulti={false}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {(isFlightChecked || isHotelChecked) && <div className='col-md-12 col-sm-12 control'>
                                                <label className="h6 text-primary mb-2 col-lg-12" htmlFor=''>
                                                    {t('displaySearchResult')} </label>
                                                <Dropdown onChange={recommendationChangeHandler}
                                                    options={recommendationOptions}
                                                    value={recommendationOptions.filter(option => option.value === recommendationId)}
                                                    textField="listValue"
                                                    dataItemKey="listID"
                                                    arrowClosed={<span className="arrow-closed" />}
                                                    arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                    isDisabled={isDisable}
                                                />
                                            </div>}
                                            <p className='mt-3'><span className="error-icon">*</span><span id='requiredIndicator'>
                                                {t('requiredFieldsIndicator')}</span></p>
                                        </form>
                                        <div className='mt-3 actions row'>
                                            <div className="col-lg-3 col-md-3 col-sm-3 my-auto">
                                                <a href="/SearchPolicy" className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1 d-none d-sm-block d-sm-none d-md-block'><span><i className="fa fa-arrow-left"></i></span></a>
                                            </div>
                                            <div className="col-lg-9 col-md-9 col-sm-9">
                                                <div className="float-md-end float-lg-end">
                                                    <a href="/SearchPolicy" className='btn back-btn-sm text-white mt-1 gt-main-btn ms-1 d-md-none d-lg-none d-xl-none'><span><i className="fa fa-arrow-left"></i></span></a>
                                                    {(checkSecurityDetails("Settings", "Policy", "Settings_Policy_Insert")
                                                        || checkSecurityDetails("Settings", "Policy", "Settings_Policy_Update")

                                                    ) ?
                                                        <button id='btnSave' onClick={submitFormHandler} className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1'>{t('save')}</button>
                                                        : <span id="btnSave"></span>
                                                    }

                                                    {checkSecurityDetails("Settings", "Policy", "Settings_Policy_Insert")
                                                        ?
                                                        <button className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1' id="btnNew" onClick={changeLocation}>
                                                            {t('new')}
                                                        </button>
                                                        : <span id="btnNew"></span>}
                                                    <button id='btnReset' onClick={changeLocation} className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1'>{t('reset')}</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2"></div>
                </div>
            </div>
        </section >
    </Fragment >
}

export default RulesForm;
