import { Fragment, useEffect, useState, React } from 'react';
import Dropdown from 'react-select';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useTranslation } from 'react-i18next';
import useHttp from '../../../../services/use-http';
import { searchCorporateMaster } from '../../../../services/CorporateMaster-API';
import { searchCurrency } from '../../../../services/Currency-API';
import { Tooltip as ReactTooltip } from "react-tooltip";
import ConfirmationModal from "../../../../components/Common/Confirmation/ConfirmationModal";

const ServiceRequest = () => {
    const { t } = useTranslation(['translation.TravelRequest']);
    const [serviceID, setServiceID] = useState('');
    const [serviceName, setServiceName] = useState('');
    const [remarks, setRemarks] = useState('');
    const [existingConfirmationNo, setExistingConfirmationNo] = useState('');
    const [selectedCurrency, setSelectedCurrency] = useState('');
    const [totalRateOfService, setTotalRateOfService] = useState('');

    const [serviceSelectionErrorMsg, setServiceSelectionErrorMsg] = useState('');
    const [remarksErrorMsg, setRemarksErrorMsg] = useState('');
    const [existingConfirmationNoErrorMsg, setExistingConfirmationNoErrorMsg] = useState('');
    const [currencyErrorMsg, setCurrencyErrorMsg] = useState('');
    const [totalRateofServiceErrorMsg, setTotalRateofServiceErrorMsg] = useState('');

    const { sendRequest: sendRequestCorproateMaster, status: statusCorproateMaster, data: loadedCorproateMaster, error } = useHttp(searchCorporateMaster);

    const { sendRequest: sendSearchCurrency, data: currencyResponse, status: searchCurrencyStatus } = useHttp(searchCurrency);
    const [currencyOptions, setCurrencyOptions] = useState([]);

    const [isAddMode, setIsAddMode] = useState(true);

    const [serviceRequests, setServiceRequests] = useState([]);

    const [isServiceSelectionDisabled, setIsServiceSelectionDisabled] = useState(false);

    const [deleteConfirmModal, setDeleteConfirmModal] = useState();

    let serviceRequestOptions = [];

    const isFlightRequestPresent = JSON.parse(sessionStorage.getItem('travelreqflightsearch')) || false;
    const isHotelRequestPresent = JSON.parse(sessionStorage.getItem('travelreqhotelsearch')) || false;

    let loadedData = [];
    for (let i in loadedCorproateMaster) {
        if (loadedCorproateMaster['errors'].status === 'FALSE') {
            if (i === 'data') {
                loadedData = loadedCorproateMaster[i];
            }
        }
    }

    loadedData.forEach(element => {
        serviceRequestOptions.push({
            value: element.id,
            label: element.name
        });
    });

    function handleServiceRequestChange(selectedOption) {
        setServiceID(selectedOption.value);
        setServiceName(selectedOption.label);

        setServiceSelectionErrorMsg('');
    }

    function handleRemarksChange(e) {
        let tempRemarks = e.trim();
        tempRemarks = (tempRemarks === '<p><br></p>' || tempRemarks === '<p></p>') ? '' : tempRemarks;
        setRemarks(tempRemarks);
    }

    function handleRemarksBlur(e) {
        setRemarksErrorMsg('');
        if (remarks === '') {
            setRemarksErrorMsg('Remarks is required.');
        }
        else {
            if (remarks !== '') {
                if (!remarks.match('^[a-zA-Z.()!0-9/ ,-/<>\n]+$')) {
                    setRemarksErrorMsg('Please specify valid data.');
                    return;
                }
            }
        }
    }

    function handleExistingConfirmationNoChange(e) {
        setExistingConfirmationNo(e.target.value.trim());
    }

    function handleExistingConfirmationNoBlur() {
        setExistingConfirmationNoErrorMsg('');
        if (existingConfirmationNo !== '') {
            if (!existingConfirmationNo.match('^[a-zA-Z0-9]+$')) {
                setExistingConfirmationNoErrorMsg('Please specify valid data.');
            }
        }
    }

    function handleCurrencyChange(selectedOption) {
        if (selectedOption) {
            setSelectedCurrency(selectedOption.value);
        }
        else {
            setSelectedCurrency(null);
        }

        setCurrencyErrorMsg('');
    }

    function handleTotalRateOfServiceChange(e) {
        setTotalRateOfService(e.target.value.trim());        
    }

    function handleTotalRateOfServiceBlur() {
        setTotalRateofServiceErrorMsg('');
        if (totalRateOfService !== '') {
            if (isNaN(totalRateOfService)) {
                setTotalRateofServiceErrorMsg('Please specify valid data.');
            }
        }
        else {
            setCurrencyErrorMsg('');
        }
    }

    function handleAddServiceRequest(event) {
        event.preventDefault();

        if (!validateServiceRequest()) {
            return;
        }

        let currentRequest = {
            serviceID: serviceID,
            serviceName: serviceName,
            remarks: remarks,
            existingConfirmationNo: existingConfirmationNo,
            currencyCode: totalRateOfService.length ? selectedCurrency : '',
            totalRateOfService: totalRateOfService
        };

        let tempServiceRequests = [...serviceRequests];
        if (isAddMode) {
            tempServiceRequests.push(currentRequest);
        }
        else {
            tempServiceRequests.forEach(request => {
                if (request.serviceID === currentRequest.serviceID) {
                    request.remarks = currentRequest.remarks;
                    request.existingConfirmationNo = currentRequest.existingConfirmationNo;
                    request.currencyCode = currentRequest.currencyCode;
                    request.totalRateOfService = currentRequest.totalRateOfService;
                }
            });

            setIsAddMode(true);
        }

        updateTravelRequestFieldsDataJson(tempServiceRequests);
        setServiceRequests(tempServiceRequests);
        resetServiceRequestForm();
        toggleSubmitTripReason();
    }

    function handleServiceRequestEdit(event, request) {
        event.preventDefault();

        setServiceID(request.serviceID);
        setRemarks(request.remarks);
        setExistingConfirmationNo(request.existingConfirmationNo);
        setSelectedCurrency(request.currencyCode);
        setTotalRateOfService(request.totalRateOfService);

        setIsServiceSelectionDisabled(true);

        setIsAddMode(false);
    }

    function handleServiceRequestDelete(event, serviceID) {
        event.preventDefault();

        setDeleteConfirmModal({
            title: "Confirmation Message",
            message: "Are you sure you want to delete this record?",
            serviceID: serviceID
        });
    }

    function handleOkDeleteConfirm(serviceID) {
        let tempServiceRequests = [...serviceRequests];
        tempServiceRequests = tempServiceRequests.filter(request => request.serviceID !== serviceID);

        updateTravelRequestFieldsDataJson(tempServiceRequests);
        setServiceRequests(tempServiceRequests);
        setDeleteConfirmModal(null);
        toggleSubmitTripReason();
    }

    function handleCancelDelete() {
        setDeleteConfirmModal(null);
    }

    function resetServiceRequestForm() {
        setIsServiceSelectionDisabled(false);
        setServiceID('');
        setRemarks('');
        setSelectedCurrency('');
        setExistingConfirmationNo('');
        setTotalRateOfService('');
    }

    function validateServiceRequest() {

        setServiceSelectionErrorMsg('');
        if (!serviceID || serviceID === '') {
            setServiceSelectionErrorMsg('Service is required.');
            return false;
        }

        if (isAddMode && serviceRequests && serviceRequests.length) {
            if (serviceRequests.some(request => request.serviceID === serviceID)) {
                setServiceSelectionErrorMsg('Details for this Service is already added.');
                return false;
            }
        }

        setRemarksErrorMsg('');
        if (remarks === '') {
            setRemarksErrorMsg('Remarks is required.');
            return false;
        }
        else {
            if (!remarks.match('^[a-zA-Z.()!0-9/ ,-/<>\n]+$')) {
                setRemarksErrorMsg('Please specify valid data.');
                return false;
            }
        }

        setExistingConfirmationNoErrorMsg('');
        if (existingConfirmationNo !== '') {
            if (!existingConfirmationNo.match('^[a-zA-Z0-9]+$')) {
                setExistingConfirmationNoErrorMsg('Please specify valid data.');
                return false;
            }
        }

        setCurrencyErrorMsg('');
        setTotalRateofServiceErrorMsg('');
        if (totalRateOfService !== '') {
            if (!selectedCurrency || selectedCurrency.length === 0) {
                setCurrencyErrorMsg('Currency is required.')
                return false;
            }

            if (isNaN(totalRateOfService)) {
                setTotalRateofServiceErrorMsg('Please specify valid data.');
                return false;
            }
        }

        return true;
    }

    function disableTripReason() {
        document.querySelector('input[role="combobox"]').disabled = true;
        document.querySelector('.css-1u9des2-indicatorSeparator').classList.add('d-none');
        document.querySelector('.css-1xc3v61-indicatorContainer').classList.add('d-none');
        document.querySelector('.css-13cymwt-control').classList.add('gt-disabled');
    }

    function enableTripReason() {
        document.querySelector('input[role="combobox"]').disabled = false;
        document.querySelector('.css-1u9des2-indicatorSeparator').classList.remove('d-none');
        document.querySelector('.css-1xc3v61-indicatorContainer').classList.remove('d-none');
        document.querySelector('.css-13cymwt-control').classList.remove('gt-disabled');
    }

    function showSubmitButton() {
        if (document.querySelector('#btnSubmit') !== null) {
            document.querySelector('#btnSubmit').classList.remove("d-none");
        }
        if (document.querySelector('#btnReset') !== null) {
            document.querySelector('#btnReset').classList.remove("d-none");
        }
    }

    function hideSubmitButton() {
        if (document.querySelector('#btnSubmit') !== null) {
            document.querySelector('#btnSubmit').classList.add("d-none");
        }
        if (document.querySelector('#btnReset') !== null) {
            document.querySelector('#btnReset').classList.add("d-none");
        }
    }

    function updateTravelRequestFieldsDataJson(serviceRequests) {
        if (sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
            let travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
            travelRequestFieldsDataJson.serviceRequests = serviceRequests;
            sessionStorage.setItem("travelRequestFieldsDataJson", JSON.stringify(travelRequestFieldsDataJson));
        }
    }

    function toggleSubmitTripReason() {

        let isServiceRequestPresent = false;

        if (sessionStorage.getItem("travelRequestFieldsDataJson")) {
            let travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
            if (travelRequestFieldsDataJson.serviceRequests && travelRequestFieldsDataJson.serviceRequests.length) {
                isServiceRequestPresent = true;
            }
        }

        if (isFlightRequestPresent || isHotelRequestPresent || isServiceRequestPresent) {
            disableTripReason();
            showSubmitButton();
        }
        else {
            enableTripReason();
            hideSubmitButton();
        }
    }

    useEffect(() => {
        if (currencyResponse) {
            if (currencyResponse.errors.status === 'FALSE' && currencyResponse.data.length) {
                setCurrencyOptions(() =>
                    currencyResponse.data.map((currency) => {
                        return {
                            label: `${currency.currName} (${currency.currCode})`,
                            value: currency.currCode
                        }
                    })
                );
            }
        }
    }, [currencyResponse]);

    useEffect(() => {

        if (sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
            let travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
            if (travelRequestFieldsDataJson.serviceRequests) {
                setServiceRequests(() => travelRequestFieldsDataJson.serviceRequests);
            }
        }

        const onSearchCorporateMaster = {
            id: '',
            userID: JSON.parse(localStorage.getItem('userID')),
            name: '',
            code: '',
            keyCode: 'SIX',
            status: '2',
            officeID: JSON.parse(localStorage.getItem('officeID'))
        };
        sendRequestCorproateMaster(onSearchCorporateMaster);

        sendSearchCurrency({
            currCode: '',
            currName: ''
        });

    }, []);

    return (
        <Fragment>

            {deleteConfirmModal && (
                <ConfirmationModal
                    title={deleteConfirmModal.title}
                    message={deleteConfirmModal.message}
                    onOk={() => handleOkDeleteConfirm(deleteConfirmModal.serviceID)}
                    onCancel={handleCancelDelete}
                ></ConfirmationModal>
            )}

            <div className="row position-relative">
                <div className="col-lg-12">
                    <div className="tour_search_form">
                        <div className='row mb-4'>
                            <div className='col-md-12'>
                                <label class="h6 text-primary mb-2 col-lg-12" for="Service">Service<span className="error-icon"> *</span></label>
                                <div className="dropdown pb-2 col-md-12">
                                    <Dropdown onChange={handleServiceRequestChange}
                                        value={serviceRequestOptions.filter(option => option.value === serviceID)}
                                        options={serviceRequestOptions}
                                        textField="value"
                                        dataItemKey="label"
                                        arrowClosed={<span className="arrow-closed" />}
                                        arrowOpen={<span className="arrow-open" />}
                                        controlClassName='mt-1 form-control control dropdown-search form-select bg_input mb-0'
                                        placeholder="Please Select"
                                        isDisabled={isServiceSelectionDisabled}
                                    />
                                    {serviceSelectionErrorMsg && serviceSelectionErrorMsg.length > 0 &&
                                        <p className="error-text">{serviceSelectionErrorMsg}</p>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='row mb-4'>
                            <div className='col-md-12 dropdown-search-text'>
                                <label class="h6 text-primary mb-2 col-lg-12" for="Remarks">Remarks<span className="error-icon"> *</span></label>
                                <div className="dropdown pb-2 col-md-12">
                                    {/* <textarea id='remarks'                                            
                                            className="form-control form-control-sm"                                            
                                            rows={2} cols={100} maxLength={300}
                                            onBlur={onBlurCheck}
                                        /> */}
                                    <div style={{ width: 1000, height: 120 }}>
                                        <ReactQuill
                                            theme="snow"
                                            value={remarks}
                                            onChange={handleRemarksChange}
                                            onBlur={handleRemarksBlur}
                                            style={{ width: 1000, height: 70 }} />
                                    </div>
                                    {remarksErrorMsg && remarksErrorMsg.length > 0 &&
                                        <p className="error-text">{remarksErrorMsg}</p>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-4 dropdown-search-text'>
                                <label class="h6 text-primary mb-2 col-lg-12"
                                    for="existingConfirmationNumber">
                                    Existing Confirmation No.
                                </label>
                                <input maxLength={6}
                                    value={existingConfirmationNo}
                                    className="form-control bg_input"
                                    type='text'
                                    id='existingConfirmationNumber'
                                    onChange={handleExistingConfirmationNoChange}
                                    onBlur={handleExistingConfirmationNoBlur}
                                />

                                {existingConfirmationNoErrorMsg && existingConfirmationNoErrorMsg.length > 0 &&
                                    <p className="error-text">{existingConfirmationNoErrorMsg}</p>
                                }
                            </div>
                            <div className='col-md-4'>
                                <label class="h6 text-primary mb-2 col-lg-12" for="serviceCurrency">
                                    Currency
                                    {totalRateOfService && totalRateOfService.length > 0 &&
                                        <span className="error-icon"> *</span>
                                    }
                                </label>
                                <div className="dropdown pb-2 col-md-12">
                                    <Dropdown id="serviceCurrency"
                                        onChange={handleCurrencyChange}
                                        value={currencyOptions.filter(option => option.value === selectedCurrency)}
                                        options={currencyOptions}
                                        textField="value"
                                        dataItemKey="label"
                                        isClearable
                                        arrowClosed={<span className="arrow-closed" />}
                                        arrowOpen={<span className="arrow-open" />}
                                        controlClassName='mt-1 form-control control dropdown-search form-select bg_input mb-0'
                                        placeholder="Please Select"
                                    />
                                </div>
                                {currencyErrorMsg && currencyErrorMsg.length > 0 &&
                                    <p className="error-text">{currencyErrorMsg}</p>
                                }
                            </div>
                            <div className='col-md-4 dropdown-search-text'>
                                <label class="h6 text-primary mb-2 col-lg-12"
                                    for="totalRateOfService">
                                    Total Rate of Service (if available)
                                </label>
                                <input maxLength={10}
                                    value={totalRateOfService}
                                    className="form-control bg_input"
                                    type='text'
                                    id='totalRateOfService'
                                    onChange={handleTotalRateOfServiceChange}
                                    onBlur={handleTotalRateOfServiceBlur}
                                />

                                {totalRateofServiceErrorMsg && totalRateofServiceErrorMsg.length > 0 &&
                                    <p className="error-text">{totalRateofServiceErrorMsg}</p>
                                }
                            </div>
                        </div>
                        <div className="col-md-12 accordion-header text-black fw-bold mb-4">
                            Note: <span className="text-primary fw-500" >Existing Confirmation No. needs to be specified if the service needs to be done on an existing travel request.</span>
                        </div>
                        <div class="mx-auto row">
                            <div className=''>
                            <a href="/#"
                                className="btn text-white gt-main-btn btn_md float-end"
                                onClick={handleAddServiceRequest}>
                                {isAddMode ? 'Add' : 'Update'}
                            </a>
                            </div>
                        </div>
                        <div className='col-lg-12 mt-4 d-none'>
                        <label class="h6 text-primary mb-2 col-lg-12" for="Service">Services </label> 
                            <div className="card text-dark card-bg-blue mb-2">
                                <div className="card-body">
                                    <div className="row">

                                        <div className='col-md-12'>
                                            <div className='row'>
                                                <div className="col-md-4">
                                                    <p className="card-text mb-0 mt-1"><strong>Service </strong>Forex </p>
                                                </div>
                                                
                                                <div className="col-md-4">
                                                    <p className="card-text mb-0 mt-1"><strong>Confirmation No. </strong>02DW5</p>
                                                </div>
                                                <div className="col-md-4">
                                                    <p className="card-text mb-0 mt-1"><strong>Price: </strong>$5,000</p>
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div className='row'>
                                                <div className="col-md-3">
                                                    <label class="h6 text-primary mb-2 col-lg-12" for=""> Currency <span class="error-icon"> *</span></label>
                                                    <Dropdown 
                                                   
                                                    options={{}}
                                                    textField="value"
                                                    dataItemKey="label"
                                                    arrowClosed={<span className="arrow-closed" />}
                                                    arrowOpen={<span className="arrow-open" />}
                                                    controlClassName='mt-1 form-control control dropdown-search form-select bg_input mb-0'
                                                    placeholder="Please Select"
                                                    isDisabled={{} }/>
                                                </div>
                                                <div className="col-md-3">
                                                    <label class="h6 text-primary mb-2 col-lg-12" for="Service"> Amount <span class="error-icon"> *</span></label>
                                                    <input maxLength={100} className="form-control bg_input col-md-6" type='text'
                                                            id='documentName' />
                                                </div>
                                                <div className="col-md-3">
                                                    <label class="h6 text-primary mb-2 col-lg-12" for="Service"> Document Name</label>
                                                        <input maxLength={100} className="form-control bg_input col-md-6" type='text'
                                                            id='documentName' /> 
                                                </div>
                                                <div className="col-md-3">
                                                    <label class="h6 text-primary mb-2 col-lg-12" for="Service">     Select Document</label>
                                                    <div className="dropdown pb-2 col-md-12">
                                                        <div className="files position-relative">
                                                            <div
                                                                className='files-dropzone text-center gt-file-dropzone-doc col-md-12 col-sm-12 col-12'>
                                                                <span id='Upload' className='file-upload text-primary'><i class="fas fa-plus-circle"></i><span>  Click here to upload file</span></span>
                                                            
                                                            </div> 
                                                        </div>
                                                        <span class="note"> Only PNG, JPG, JPEG and PDF file is supported.</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row my-2'>
                                                <span><strong>Attached Documents: </strong> <span><span class="btn h6 text-white gt-yellow-btn ms-1 btn-sm">Mr. barun Roy<span class="text-white"><i class="fas fa-times ms-3"></i></span></span></span></span>
                                            </div>
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                    {serviceRequests && serviceRequests.length > 0 &&
                        <div className="table-responsive mt-4 table-head-bg">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col">Service</th>
                                        <th scope="col">Remarks</th>
                                        <th scope="col">Existing Confirmation No.</th>
                                        <th scope="col">Total Rate of Service</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {serviceRequests.map(request => (
                                        <tr key={request.serviceID}>
                                            <td>
                                                {request.serviceName}
                                            </td>
                                            <td>
                                                <div dangerouslySetInnerHTML={{ __html: request.remarks }} />
                                            </td>
                                            <td>
                                                {request.existingConfirmationNo}
                                            </td>
                                            <td className="text-end">
                                                {request.totalRateOfService &&
                                                    request.totalRateOfService.length > 0 &&
                                                    <Fragment>
                                                        {
                                                            new Intl.NumberFormat('en-US', {
                                                                style: 'currency',
                                                                currency: request.currencyCode,
                                                            }).format(request.totalRateOfService)
                                                        }
                                                    </Fragment>
                                                }
                                                {/*{request.currency + ' ' + request.totalRateOfService}*/}
                                            </td>
                                            <td>
                                                <span>
                                                    <a href="/#"
                                                        id={"edit" + request.serviceID}
                                                        onClick={(event) => { handleServiceRequestEdit(event, request) }}
                                                        title="Edit"
                                                        className="btn btn-sm text-white ms-1 gt-border-radius-50 gt-green-btn">
                                                        <i className="fa-solid fa-pen"></i>
                                                    </a>
                                                    <ReactTooltip
                                                        anchorId={"edit" + request.serviceID}
                                                        place="bottom"
                                                        variant="info"
                                                        className="tooltip"
                                                        html={"Edit"}
                                                    />
                                                </span>
                                                <span>
                                                    <a href="/#"
                                                        id={"delete" + request.serviceID}
                                                        onClick={(event) => { handleServiceRequestDelete(event, request.serviceID) }}
                                                        title="Edit"
                                                        className="btn btn-sm btn-danger gt-border-radius-50 gt-red-btn text-white">
                                                        <i className="fas fa-trash-alt"></i>
                                                    </a>
                                                    <ReactTooltip
                                                        anchorId={"delete" + request.serviceID}
                                                        place="bottom"
                                                        variant="info"
                                                        className="tooltip"
                                                        html={"Delete"}
                                                    />
                                                </span>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </div>
        </Fragment>)

};

export default ServiceRequest;

