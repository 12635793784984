import { Fragment } from 'react';
import * as React from "react";
import { getMinutetoHrsMin, getFormatDate, getFormatTime } from './../../../utils/CommonFunction';

const FlightItemGds = (props) => {
    const airlineLogoLink = process.env.REACT_APP_FLIGHT_LOG_URL;

    let printClass = props.printclass !== undefined ? "flight_show_down_wrapper d-flex w-100 props.printclass" : "d-lg-flex flight_show_down_wrapper w-100 ";
    let result = props.airItinerary.originDestinationOptions.originDestinationOption;
    let markupValue = 0;
    let markupUnit = "";

    props.searchFlight.airItineraryPricingInfo.passengerTypeQuantity.forEach(elementPassengerTypeQuantity => {
        if (elementPassengerTypeQuantity.commisionInfo !== undefined) {
            elementPassengerTypeQuantity.commisionInfo.forEach(elementCommisionInfo => {
                if (elementCommisionInfo.earnedCommissionAmount !== undefined && elementCommisionInfo.earnedCommissionAmount !== '' && elementCommisionInfo.earnedCommissionAmount !== null) {
                    markupValue = markupValue + (parseFloat(elementCommisionInfo.earnedCommissionAmount) * parseInt(elementPassengerTypeQuantity.quantity))
                    markupUnit = elementCommisionInfo.currency
                }
            });
        }
    });

    let rbdValue;
    let arrival_details = [];
    let operating_details = [];
    let departure_details = [];
    let classNameUpdated = "flight_mytrip_items";
    let classNameUpdatedList = "multi_trip_flight_lists w-100 px-3";

    let baggageAllowance = "";
    let baggageAllowanceUnit = "";

    for (let i in result) {
        baggageAllowance = result[i].segmentRefDetail[0].pax[0] === undefined ? "" : result[i].segmentRefDetail[0].pax[0].baggageAllowance === undefined || result[i].segmentRefDetail[0].pax[0].baggageAllowance[0] === undefined ? "" : result[i].segmentRefDetail[0].pax[0].baggageAllowance[0].maxWeight === undefined ? result[i].segmentRefDetail[0].pax[0].baggageAllowance[0].numberOfPieces === undefined ? "" : result[i].segmentRefDetail[0].pax[0].baggageAllowance[0].numberOfPieces === "1" ? result[i].segmentRefDetail[0].pax[0].baggageAllowance[0].numberOfPieces + " piece" : result[i].segmentRefDetail[0].pax[0].baggageAllowance[0].numberOfPieces + " pieces" : result[i].segmentRefDetail[0].pax[0].baggageAllowance[0].maxWeight;
        baggageAllowanceUnit = result[i].segmentRefDetail[0].pax[0] === undefined ? "" : result[i].segmentRefDetail[0].pax[0].baggageAllowance === undefined || result[i].segmentRefDetail[0].pax[0].baggageAllowance[0] === undefined ? "" : result[i].segmentRefDetail[0].pax[0].baggageAllowance[0].unit === undefined ? "" : result[i].segmentRefDetail[0].pax[0].baggageAllowance[0].unit;
        rbdValue = result[i].flightSegment[0].resBookDesigCode;

        departure_details.push({
            departure: result[i].flightSegment[0].departureAirport, date: result[i].flightSegment[0].departureDate, time: result[i].flightSegment[0].departureTime, rph: result[i].rph, cabinName: result[i].flightSegment[0].bookingClassAvails[0].cabinName,
            flownMileageQty: result[i].flightSegment[0].flownMileageQty, duration: result[i].flightSegment[0].duration, dateChangeNbr: result[i].flightSegment[0].dateChangeNbr,
            equipmentName: result[i].flightSegment[0].equipment !== undefined ? result[i].flightSegment[0].equipment[0].name : '', elapsedLayoverTime: result[i].flightSegment[0].elapsedLayoverTime !== undefined ? result[i].flightSegment[0].elapsedLayoverTime : '',
            noOfStops: result[i].flightSegment[0].technicalStops !== undefined && result[i].flightSegment[0].technicalStops !== null ? result[i].flightSegment[0].technicalStops.noOfStops : 0,
            bag: result[i].segmentRefDetail[0].pax !== undefined ? result[i].segmentRefDetail[0].pax : "",
            flightRefNumber: result[i].flightSegment[0].flightPnrNumber,
            equipment: result[i].flightSegment[0].equipment !== undefined ? result[i].flightSegment[0].equipment[0] : '',
            resBookDesigCode: result[i].flightSegment[0].resBookDesigCode,
            marketingAirline: result[i].flightSegment[0].marketingAirline,
            operatingAirline: result[i].flightSegment[0].operatingAirline,
            flightID: result[i].flightSegment[0].flightID,
            technicalStops: result[i].flightSegment[0].technicalStops !== undefined && result[i].flightSegment[0].technicalStops !== null ? result[i].flightSegment[0].technicalStops : "",
        })
        arrival_details.push({ arrival: result[i].flightSegment[0].arrivalAirport, date: result[i].flightSegment[0].arrivalDate, time: result[i].flightSegment[0].arrivalTime })
        operating_details.push(result[i].flightSegment[0].marketingAirline);
    }

    function isMultiAirlines(Airline, Ismulticity) {
        let multiAirline = false;
        for (let i in Airline) {
            if (Ismulticity) {
                if (Airline[0].marketingAirline.code !== Airline[i].marketingAirline.code) {
                    multiAirline = true;
                    break;
                }
            } else {
                if (Airline[0].code !== Airline[i].code) {
                    multiAirline = true;
                    break;
                }
            }
        }
        return multiAirline;
    }

    const getBaggageUnitTextData = (baggageUnit, item) => {
        //let data = (baggageUnit.toLowerCase().includes('p') || baggageUnit.toLowerCase().includes('k')) && (!baggageUnit.includes('(')) ? '' : item.unit === undefined ? item.maxWeight === undefined ? baggageUnit === '1' ? "piece" : 'pieces' : baggageUnit === '1' ? "kg" : 'kg' : item.unit;
        let data = baggageUnit.includes('(') ? '' : baggageUnit.toLowerCase().includes('p') || baggageUnit.toLowerCase().includes('k') ? '' : item.unit === undefined ? item.maxWeight === undefined ? baggageUnit === '1' ? "piece" : 'pieces' : baggageUnit === '1' ? "kg" : 'kg' : item.unit;
        return data;
    }

    function showFlights() {
        var flightData = [];

        for (let i in departure_details) {
            let depTime = departure_details[i].time;
            let depDate = departure_details[i].date;
            let arrTime = arrival_details[i].time;
            let arrDate = arrival_details[i].date;
            let depCityCode = departure_details[i].departure.cityCode;
            let depCityName = departure_details[i].departure.cityName;
            let depCountryName = departure_details[i].departure.countryName;
            let depCountryCode = departure_details[i].departure.countryCode;
            let depAirportName = departure_details[i].departure.locationName === undefined ? "" : departure_details[i].departure.locationName;
            let depAirportCode = departure_details[i].departure.locationCode === undefined ? "" : departure_details[i].departure.locationCode;
            let depTerminal = departure_details[i].departure.terminal === undefined ? "" : departure_details[i].departure.terminal.split(" ").length > 1 ? "Terminal " + departure_details[i].departure.terminal.split(" ")[1] : "Terminal " + departure_details[i].departure.terminal;
            let flightArrive = departure_details[i].dateChangeNbr === "1" && departure_details[i].dateChangeNbr !== undefined ? "This flight arrives the next day." : "";
            let arrCityCode = arrival_details[i].arrival.cityCode;
            let arrCityName = arrival_details[i].arrival.cityName;
            let arrCountryName = arrival_details[i].arrival.countryName;
            let arrCountryCode = arrival_details[i].arrival.countryCode;
            let arrTerminal = arrival_details[i].arrival.terminal === undefined ? "" : arrival_details[i].arrival.terminal.split(" ").length > 1 ? "Terminal " + arrival_details[i].arrival.terminal.split(" ")[1] : "Terminal " + arrival_details[i].arrival.terminal;
            let NoOftechStop = parseInt(departure_details[i].noOfStops);
            let stop = parseInt(NoOftechStop) === 0 || parseInt(NoOftechStop) > 1 ? "stops" : "stop";
            let equipmentName = departure_details[i].equipmentName === undefined ? "" : departure_details[i].equipmentName;
            let arrAirportName = arrival_details[i].arrival.locationName === undefined ? "" : arrival_details[i].arrival.locationName;
            let arrAirportCode = arrival_details[i].arrival.locationCode === undefined ? "" : arrival_details[i].arrival.locationCode;
            let logo = airlineLogoLink + operating_details[i].airlineLogo;
            let timeBetweenFlight = departure_details[i].elapsedLayoverTime !== undefined ? departure_details[i].elapsedLayoverTime : "";
            let rph = departure_details[i].rph;
            let depAirPortInfo = depAirportName + "(" + depAirportCode + ")" + ", " + depCountryName + "(" + depCountryCode + ")";
            let arrAirPortInfo = arrAirportName + "(" + arrAirportCode + ")" + ", " + arrCountryName + "(" + arrCountryCode + ")";
            //User Story 2450: Cabin Bag Changes
            let adultBag; let childBag; let lapInfantBag; let adultBagUnit; let childBagUnit; let lapInfantBagUnit; let seatInfantBag; let seatInfantBagUnit;
            let cabinAdultBag; let cabinAdultBagUnit; let cabinChildBag; let cabinChildBagUnit; let cabinLapInfantBag; let cabinLapInfantBagUnit; let cabinSeatInfantBag; let cabinSeatInfantBagUnit;

            if (departure_details[i].bag !== '' || departure_details[i].bag !== undefined) {
                departure_details[i].bag.forEach(element => {
                    if (element.baggageAllowance !== undefined) {
                        if (element.type === 'ADT') {
                            element.baggageAllowance.forEach(item => {
                                //check for check-in bag
                                if (item.type === 'Check-in Allowance') {
                                    adultBag = item.maxWeight === undefined ? item.numberOfPieces : item.maxWeight
                                    adultBagUnit = getBaggageUnitTextData(adultBag, item);

                                }
                                //check for cabin bag
                                if (item.type === "for Carry-On Allowances") {
                                    cabinAdultBag = item.maxWeight === undefined ? item.numberOfPieces : item.maxWeight
                                    cabinAdultBagUnit = getBaggageUnitTextData(cabinAdultBag, item);
                                }
                            });

                        }
                        else if (element.type === 'CNN') {
                            element.baggageAllowance.forEach(item => {
                                //check for check-in bag
                                if (item.type === 'Check-in Allowance') {
                                    childBag = item.maxWeight === undefined ? item.numberOfPieces : item.maxWeight
                                    childBagUnit = getBaggageUnitTextData(childBag, item);
                                }
                                //check for cabin bag
                                if (item.type === "for Carry-On Allowances") {
                                    cabinChildBag = item.maxWeight === undefined ? item.numberOfPieces : item.maxWeight
                                    cabinChildBagUnit = getBaggageUnitTextData(cabinChildBag, item);
                                }
                            });
                        }
                        else if (element.type === 'INF') {
                            element.baggageAllowance.forEach(item => {
                                //check for check-in bag
                                if (item.type === 'Check-in Allowance') {
                                    lapInfantBag = item.maxWeight === undefined ? item.numberOfPieces : item.maxWeight
                                    lapInfantBagUnit = getBaggageUnitTextData(lapInfantBag, item);
                                }
                                //check for cabin bag
                                if (item.type === "for Carry-On Allowances") {
                                    cabinLapInfantBag = item.maxWeight === undefined ? item.numberOfPieces : item.maxWeight
                                    cabinLapInfantBagUnit = getBaggageUnitTextData(cabinLapInfantBag, item);
                                }
                            });
                        }
                        else if (element.type === 'INS') {
                            element.baggageAllowance.forEach(item => {
                                //check for check-in bag
                                if (item.type === 'Check-in Allowance') {
                                    seatInfantBag = item.maxWeight === undefined ? item.numberOfPieces : item.maxWeight
                                    seatInfantBagUnit = getBaggageUnitTextData(seatInfantBag, item);
                                }
                                //check for cabin bag
                                if (item.type === "for Carry-On Allowances") {
                                    cabinSeatInfantBag = item.maxWeight === undefined ? item.numberOfPieces : item.maxWeight
                                    cabinSeatInfantBagUnit = getBaggageUnitTextData(cabinSeatInfantBag, item);
                                }
                            });
                        }
                    }
                });
            }

            flightData.push(<>
                <div>
                    {timeBetweenFlight === '' && i !== '0' && i !== 0 && <hr className='my-1'></hr>}
                    {timeBetweenFlight !== "" &&
                        <div className='row'>
                            <div className='col-md-12 text-center gt-time-btn-flight'>Time between flights:{" " + getMinutetoHrsMin(timeBetweenFlight)}</div>
                        </div>
                    }
                    <div className='row'>
                        <div className='col-md-4'>
                            <div className='departure-text'>
                                <span><i className="fas fa-plane-departure"></i></span> {departure_details[i].flightRefNumber !== undefined && <span className='text-primary airlineName fw-500'> Airline PNR:  {departure_details[i].flightRefNumber}</span>}
                            </div>
                        </div>
                        <div className='col-md-8'>
                        </div>
                    </div>
                    <div>
                        <div className={printClass}>
                            <div className="flight-shoe_dow_item col-md-9 ccc">
                                <div className="airline-details ">
                                    <div className="img"><img src={logo} alt="img" className='details-flight-img' /></div>
                                    <span className="airlineName fw-500">{operating_details[i].name} &nbsp;
                                        {operating_details[i].code + " " + operating_details[i].flightNumber}
                                    </span> <span className=' airlineName fw-500 text-white badge rounded-pill class-purple-bg'> {departure_details[i].cabinName}</span>
                                    <span className="flightNumber">{equipmentName + " "}</span>
                                </div>
                                <div className="flight_inner_show_component">
                                    <div className="flight_det_wrapper">
                                        <div className="flight_det">
                                            <div className="code_time">
                                                <span className="code">{depCityName}</span>
                                            </div>
                                            <div className="code_time">
                                                <span className="time">{getFormatTime(depTime)}</span>
                                            </div>
                                            <p className="date">{getFormatDate(departure_details[i].date)}</p>
                                            <p className="airport">{depAirPortInfo}
                                            </p>
                                            <p className="airport mb-2">{depTerminal}</p>
                                        </div>
                                    </div>
                                    <div className="flight_duration">
                                        <div className="arrow_right"></div>
                                        <span>{getMinutetoHrsMin(departure_details[i].duration)}</span>
                                        <p> {NoOftechStop + " "} {stop}</p>
                                    </div>
                                    <div className="flight_det_wrapper">
                                        <div className="flight_det">
                                            <div className="code_time">
                                                <span className="code">{arrCityName}</span>
                                            </div>
                                            <div className="code_time">
                                                <span className="time">{getFormatTime(arrTime)}</span>
                                            </div>
                                            <p className="date">{getFormatDate(arrival_details[i].date)}</p>
                                            <p className="airport">{arrAirPortInfo}
                                            </p>
                                            <p className="airport mb-2">{arrTerminal}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    {flightArrive !== '' && <div className='col-md-6'>
                                        <div className='text-primary fw-bold text-14px'><i className="fas fa-info-circle"></i>{" " + flightArrive} </div>
                                    </div>}
                                    {
                                        departure_details[i].flownMileageQty !== undefined && departure_details[i].flownMileageQty !== '' && departure_details[i].flownMileageQty !== '0' &&
                                        <div className='col-md-6'>
                                            <div className='text-primary text-14px fw-bold'><i className="fas fa-tachometer-alt"></i> {departure_details[i].flownMileageQty} Air miles</div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="flight_refund_policy col-md-3">
                                <div className="TabPanelInner col-sm-12">
                                    <h4 className='col-md-12'><span className='gt-border-bottom'>Check-in Baggage </span></h4>
                                    <div className="flight_info_taable">
                                        {adultBag !== undefined && <p>Per Adult &nbsp;  &nbsp;<span>{adultBag + " " + adultBagUnit} &nbsp; </span></p>}
                                        {childBag !== undefined && <p>Per Child &nbsp;  &nbsp;<span>{childBag + " " + childBagUnit} &nbsp; </span></p>}
                                        {lapInfantBag !== undefined && <p>Per Lap Infant &nbsp; <span>{lapInfantBag + " " + lapInfantBagUnit} &nbsp; </span></p>}
                                        {seatInfantBag !== undefined && <p>Per Seat Infant &nbsp; <span>{seatInfantBag + " " + seatInfantBagUnit} &nbsp; </span></p>}
                                        {adultBag === undefined && childBag === undefined && lapInfantBag ===
                                            undefined && seatInfantBag === undefined && <p>Information
                                                unavailable for this flight.</p>}
                                    </div>
                                    <h4 className='col-md-12'><span className='gt-border-bottom'> Cabin Baggage</span></h4>
                                    <div className="flight_info_taable">
                                        {cabinAdultBag !== undefined && <p>Per Adult &nbsp;  &nbsp;<span>{cabinAdultBag + " " + cabinAdultBagUnit} &nbsp; </span></p>}
                                        {cabinChildBag !== undefined && <p>Per Child &nbsp;  &nbsp;<span>{cabinChildBag + " " + cabinChildBagUnit} &nbsp; </span></p>}
                                        {cabinLapInfantBag !== undefined && <p>Per Lap Infant &nbsp; <span>{cabinLapInfantBag + " " + cabinLapInfantBagUnit} &nbsp; </span></p>}
                                        {cabinSeatInfantBag !== undefined && <p>Per Seat Infant &nbsp; <span>{cabinSeatInfantBag + " " + cabinSeatInfantBagUnit} &nbsp; </span></p>}
                                        {cabinAdultBag === undefined &&
                                            cabinLapInfantBag === undefined && cabinSeatInfantBag === undefined
                                            && <p>Information unavailable for this flight.</p>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mb-2'>
                            {NoOftechStop >= 1 && <div className='col-md-12 text-center gt-time-btn-flight'>Technical Stop: {getMinutetoHrsMin(departure_details[i].technicalStops.stopDetail[0].elapsedLayoverTime)} Layover in {departure_details[i].technicalStops.stopDetail[0].locationName}, Deboarding may not be allowed</div>}
                        </div>
                    </div>
                </div>
            </>)
        }
        return flightData;
    }

    function showAirlineDetails() {
        var show = [];
        show.push(
            <div className={classNameUpdated}>
                <div className={classNameUpdatedList}>
                    {showFlights()}
                </div>
            </div>
        );

        return show;
    }

    return (
        <Fragment>
            {<div className="flight_search_item_wrappper">
                {showAirlineDetails()}
            </div>
            }
        </Fragment>
    );
};

export default FlightItemGds
