import React, { useEffect } from "react";
import { Fragment, Redirect } from "react";
import { useHistory } from 'react-router-dom';
import LoadingSpinner from '../components/Common/Loading/LoadingSpinner';
import { FLIGHTSEARCH, SEARCHPNR, TRAVELREQUEST, SEARCHHOTEL, SEARCHSIGHTSEEING, SEARCHHOLIDAYS, IMPORTPNR, CRYPTIC } from './CONSTANTS';
import { checkSecurityDetails } from "../utils/RoleUtils";
import { getConfigurationByBoolen, getConfigurationByValue } from "../utils/ConfigurationsFunction";
const Loading = () => {
    const history = useHistory();
    console.log(checkSecurityDetails("Book", "Flight", "Book_Flight_Search"));
    useEffect(() => {

        setTimeout(() => {
            sessionStorage.setItem("LoaderHeader", "0");
            if (localStorage.getItem('isCorporate') === 'true')
                history.push(TRAVELREQUEST);
            else if (JSON.parse(localStorage.getItem("isTravelAgent")) === "1")
                history.push(SEARCHPNR);
            else if (checkSecurityDetails("Book", "Flight") && checkSecurityDetails("Book", "Flight", "Book_Flight_Search")) {
                history.push(FLIGHTSEARCH);
            } else if (checkSecurityDetails("Book", "Hotel") && checkSecurityDetails("Book", "Hotel", "Book_Hotel_Search")) {
                history.push(SEARCHHOTEL);
            } else if (checkSecurityDetails("Book", "Sightseeing") && getConfigurationByBoolen("ALLOW_SIGHTSEEING") === true) {
                history.push(SEARCHSIGHTSEEING)
            }
            else if (getConfigurationByBoolen("ALLOW_HOLIDAYS") === true && getConfigurationByValue('HOLIDAYS_URL') !== null && getConfigurationByValue('HOLIDAYS_URL') !== '') {
                history.push(SEARCHHOLIDAYS)
            } else if ((checkSecurityDetails("Book", "ImportPNR") && getConfigurationByBoolen("ALLOW_IMPORT_PNR") === true)) {
                history.push(SEARCHPNR)
            } else if ((checkSecurityDetails("Book", "SabreHost") && getConfigurationByBoolen("ALLOW_SABRE_HOST") === true)) {
                history.push(CRYPTIC)
            }

            //history.go();
            //return <Redirect to={FLIGHTSEARCH}></Redirect>
        }, 1000);

    }, []);
    return (
        <>
            {/* Page Not Found! */}
            <Fragment>
                {/*     <!-- Common Banner Area --> */}
                <section id="common_banner">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 full-page-loader">
                                <LoadingSpinner></LoadingSpinner>
                            </div>
                        </div>
                    </div>
                </section>


                {/* <div className="preloader">
                    <div className="d-table">
                        <div >
                            <div >
                                
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </div>

                </div> */}


            </Fragment>
        </>
    );
};
export default Loading;
