import { Fragment, useEffect, useState } from "react";
import useHttp from '../../../services/use-http';
import { getFareRule } from '../../../services/Flight-API';
import Dropdown from 'react-select';


const FareRule = (props) => {
    const [fareRuleList, setFareRuleList] = useState([]);
    const [fareRuleResult, setFareRuleResult] = useState([]);
    const [flag, setFlag] = useState(true);
    // const [fareRuleOption, setFareRuleOption] = useState([]);
    // const [fareRuleArrayData, setFareRuleArrayData] = useState([]);

    let fareRuleError = "";
    let fareRuleOptions = []
    let seleted_option = []
    let fareRuleArrayData = [];

    let selectValue = "";
    let ID = "";
    const { sendRequest, status, data: loadedFareRules, error } = useHttp(getFareRule);
    const [selectOption, setSelectionOption] = useState();
    const [selectId, setSelectId] = useState();
    const [isFirst, setIsFirst] = useState(false);

    function fareRuleChangeHandler(event) {
        selectValue = '';
        ID = ""
        setFlag(false);
        setSelectionOption(event.id);
        setSelectId(event.value);
        document.querySelector("#fareRulesDetails").innerHTML = event.id;
        document.querySelector("#fareRulesDetailsFirst").innerHTML = "";
    }

    useEffect(() => {
        let fareRuleInput;
        if (props.type !== 'multicity') {
            sendRequest(props.inputData[0]);
            setFareRuleList(props.inputData);
        } else {
            if (props.sourceId !== 'TJ') {
                setFareRuleList(props.fareInfo.passengerTypeQuantity[0].fareInfos.fareInfo)
                props.fareInfo.passengerTypeQuantity[0].fareInfos.fareInfo[0].segments.segment.forEach(item => {
                    props.inputData.originDestinationOptions.originDestinationOption.forEach(element => {
                        if (item.id === parseInt(element.flightSegment[0].flightID)) {
                            fareRuleInput = (
                                {

                                    "officeID": JSON.parse(localStorage.getItem('officeID')),
                                    "Data": {
                                        "FareRuleSourceInfo": [
                                            {
                                                "FlightSegmentRuleInfo": [
                                                    {
                                                        "DepartureAirport": {
                                                            "LocationCode": props.fareInfo.passengerTypeQuantity[0].fareInfos.fareInfo[0].operation === "FROM" ? props.fareInfo.passengerTypeQuantity[0].fareInfos.fareInfo[0].segments.beginAirport : props.fareInfo.passengerTypeQuantity[0].fareInfos.fareInfo[0].segments.endAirport,

                                                        },
                                                        "ArrivalAirport": {
                                                            "LocationCode": props.fareInfo.passengerTypeQuantity[0].fareInfos.fareInfo[0].operation === "FROM" ? props.fareInfo.passengerTypeQuantity[0].fareInfos.fareInfo[0].segments.endAirport : props.fareInfo.passengerTypeQuantity[0].fareInfos.fareInfo[0].segments.beginAirport,

                                                        },
                                                        "MarketingAirline": {
                                                            "Code": element.flightSegment[0].marketingAirline.code,
                                                            "FlightNumber": element.flightSegment[0].marketingAirline.flightNumber,
                                                        },

                                                        "FareRules": {
                                                            "FareRule": [{

                                                                "FareBasisCode": props.fareInfo.passengerTypeQuantity[0].fareInfos.fareInfo[0].fareBasisCode,
                                                                //"FareRuleKey": props.searchFlight.airItineraryPricingInfo.key !== undefined ? props.searchFlight.airItineraryPricingInfo.key : "",
                                                            }],
                                                        },
                                                        "DepartureDate": element.flightSegment[0].departureDate,
                                                        "DepartureTime": element.flightSegment[0].departureTime,
                                                        "ArrivalDate": element.flightSegment[0].arrivalDate,
                                                        "ArrivalTime": element.flightSegment[0].arrivalTime,


                                                    }
                                                ],
                                                "SourceId": props.sourceId, //dynamic source id in fare rule request
                                                "SourceName": props.sourceName, //dynamic source name in fare rule request
                                                "SectorInd": props.inputData.sectorInd
                                            }
                                        ],
                                        "TraceId": JSON.parse(localStorage.getItem('traceID')),

                                    }
                                }
                            );



                        }


                    })

                })
                sendRequest(fareRuleInput);
            }
            else if (props.sourceId === 'TJ') {
                setFareRuleList(props.inputData);
                props.inputData.originDestinationOptions.originDestinationOption.forEach(element => {
                    if (props.flightID === element.flightSegment[0].flightID) {
                        fareRuleInput = (
                            {

                                "officeID": JSON.parse(localStorage.getItem('officeID')),
                                "Data": {
                                    "FareRuleSourceInfo": [
                                        {
                                            "FlightSegmentRuleInfo": [
                                                {
                                                    "DepartureAirport": {
                                                        "LocationCode": element.flightSegment[0].departureAirport.locationCode,

                                                    },
                                                    "ArrivalAirport": {
                                                        "LocationCode": element.flightSegment[0].arrivalAirport.locationCode,

                                                    },
                                                    "MarketingAirline": {
                                                        "Code": element.flightSegment[0].marketingAirline.code,
                                                        "FlightNumber": element.flightSegment[0].marketingAirline.flightNumber,
                                                    },

                                                    "FareRules": {
                                                        "FareRule": [{

                                                            "FareBasisCode": props.fareInfo.passengerTypeQuantity[0].fareInfos.fareInfo[0].fareBasisCode,
                                                            "FareRuleKey": props.fareInfo.key !== undefined ? props.fareInfo.key : "",
                                                        }],
                                                    },
                                                    "DepartureDate": element.flightSegment[0].departureDate,
                                                    "DepartureTime": element.flightSegment[0].departureTime,
                                                    "ArrivalDate": element.flightSegment[0].arrivalDate,
                                                    "ArrivalTime": element.flightSegment[0].arrivalTime,


                                                }
                                            ],
                                            "SourceId": props.sourceId, //dynamic source id in fare rule request
                                            "SourceName": props.sourceName, //dynamic source name in fare rule request
                                            "SectorInd": props.inputData.sectorInd
                                        }
                                    ],
                                    "TraceId": JSON.parse(localStorage.getItem('traceID')),

                                }
                            }
                        );
                    }







                })

                sendRequest(fareRuleInput);
            }

        }

    }, [sendRequest, props.inputData, props.type, props.fareInfo])


    if (status === 'completed') {
        //  setFareRuleResult(loadedFareRules.data.fareRuleSourceInfo);
        if (loadedFareRules !== null) {
            if (props.sourceId !== 'TJ' && loadedFareRules.data.errors.status === "FALSE") {
                const fareRuleInfoArray = loadedFareRules.data.fareRuleSourceInfo.map(
                    (fareRuleSourceInfo) =>
                        fareRuleSourceInfo.flightSegmentRuleInfo.map((flightSegmentRuleInfo) =>
                            flightSegmentRuleInfo.fareRules.fareRule.map((fareRule) => fareRule.fareRuleInfo)
                        )
                );

                fareRuleArrayData = fareRuleInfoArray[0][0][0];
            }
            else if (loadedFareRules.data.errors.status === 'TRUE') {
                if (props.sourceId !== 'TJ') {
                    fareRuleError = loadedFareRules.data.errors.error.description;
                } else {
                    fareRuleError = 'Airline has not provided the fare rules for this flight.';
                }

            }
            else {
                fareRuleError = 'Airline has not provided the fare rules for this flight.';
            }
        }
        else {
            fareRuleError = 'Airline has not provided the fare rules for this flight.';
        }
        if (props.sourceId !== 'TJ') {
            for (let index = 0; index < fareRuleArrayData.length; index++) {
                fareRuleOptions = [...fareRuleOptions, { value: fareRuleArrayData[index].id, label: fareRuleArrayData[index].title, id: fareRuleArrayData[index].text }]
                if (index === 0) {
                    selectValue = fareRuleArrayData[index].text;
                    ID = fareRuleArrayData[index].id;
                    if (!isFirst) {
                        if (document.querySelector("#fareRulesDetails") !== null) {
                            document.querySelector("#fareRulesDetails").innerHTML = fareRuleArrayData[index].text;
                        }
                        setIsFirst(true)
                    }

                }
            }
        }

        //

    }



    useEffect(() => {

        let loadedData = [];
        if (status === 'completed') {
            if (props.sourceId !== 'TJ' && loadedFareRules.data.errors.status === 'FALSE') {
                loadedData = loadedFareRules.data.fareRuleSourceInfo;
            } else if (props.sourceId === 'TJ' && loadedFareRules.data.errors.status === 'FALSE') {
                loadedData = loadedFareRules.data.fareRuleSourceInfo[0].flightSegmentRuleInfo[0].fareRules.fareRule[0].miniRules.miniRule;
            }
            else if (loadedFareRules.data.errors.status === 'TRUE') {
                fareRuleError = loadedFareRules.data.errors.error.description;
            }
            else {
                fareRuleError = 'Airline has not provided the fare rules for this flight.';
            }
            setFareRuleResult(loadedData);
        }



    }, [status])
    useEffect(() => {

        setSelectionOption(selectValue);
        setSelectId(ID);

    }, [selectValue, ID])

    const tabChangeHandler = (data, sectorType) => {
        let fareRuleInput;
        setFareRuleResult([]);
        fareRuleOptions = [];
        if (sectorType !== 'multicity') {
            sendRequest(data);
        }
        else {

            if (props.sourceId !== 'TJ') {
                data.segments.segment.forEach(item => {
                    props.inputData.originDestinationOptions.originDestinationOption.forEach(element => {
                        if (item.id === parseInt(element.flightSegment[0].flightID)) {
                            fareRuleInput = (
                                {

                                    "officeID": JSON.parse(localStorage.getItem('officeID')),
                                    "Data": {
                                        "FareRuleSourceInfo": [
                                            {
                                                "FlightSegmentRuleInfo": [
                                                    {
                                                        "DepartureAirport": {
                                                            "LocationCode": data.operation === "FROM" ? data.segments.beginAirport : data.segments.endAirport,

                                                        },
                                                        "ArrivalAirport": {
                                                            "LocationCode": data.operation === "FROM" ? data.segments.endAirport : data.segments.beginAirport,

                                                        },
                                                        "MarketingAirline": {
                                                            "Code": element.flightSegment[0].marketingAirline.code,
                                                            "FlightNumber": element.flightSegment[0].marketingAirline.flightNumber,
                                                        },

                                                        "FareRules": {
                                                            "FareRule": [{

                                                                "FareBasisCode": data.fareBasisCode
                                                            }],
                                                        },
                                                        "DepartureDate": element.flightSegment[0].departureDate,
                                                        "DepartureTime": element.flightSegment[0].departureTime,
                                                        "ArrivalDate": element.flightSegment[0].arrivalDate,
                                                        "ArrivalTime": element.flightSegment[0].arrivalTime,


                                                    }
                                                ],
                                                "SourceId": props.sourceId,
                                                "SourceName": props.sourceName,
                                                "SectorInd": props.inputData.sectorInd
                                            }
                                        ],
                                        "TraceId": JSON.parse(localStorage.getItem('traceID')),

                                    }
                                }
                            );
                        }

                    })

                })

            }
            else if (props.sourceId === 'TJ') {
                props.inputData.originDestinationOptions.originDestinationOption.forEach(element => {
                    if (props.flightID === element.flightSegment[0].flightID) {
                        fareRuleInput = (
                            {

                                "officeID": JSON.parse(localStorage.getItem('officeID')),
                                "Data": {
                                    "FareRuleSourceInfo": [
                                        {
                                            "FlightSegmentRuleInfo": [
                                                {
                                                    "DepartureAirport": {
                                                        "LocationCode": element.flightSegment[0].departureAirport.locationCode,

                                                    },
                                                    "ArrivalAirport": {
                                                        "LocationCode": element.flightSegment[0].arrivalAirport.locationCode,

                                                    },
                                                    "MarketingAirline": {
                                                        "Code": element.flightSegment[0].marketingAirline.code,
                                                        "FlightNumber": element.flightSegment[0].marketingAirline.flightNumber,
                                                    },

                                                    "FareRules": {
                                                        "FareRule": [{

                                                            "FareBasisCode": props.fareInfo.passengerTypeQuantity[0].fareInfos.fareInfo[0].fareBasisCode,
                                                            "FareRuleKey": props.fareInfo.key !== undefined ? props.fareInfo.key : "",
                                                        }],
                                                    },
                                                    "DepartureDate": element.flightSegment[0].departureDate,
                                                    "DepartureTime": element.flightSegment[0].departureTime,
                                                    "ArrivalDate": element.flightSegment[0].arrivalDate,
                                                    "ArrivalTime": element.flightSegment[0].arrivalTime,


                                                }
                                            ],
                                            "SourceId": props.sourceId, //dynamic source id in fare rule request
                                            "SourceName": props.sourceName, //dynamic source name in fare rule request
                                            "SectorInd": props.inputData.sectorInd
                                        }
                                    ],
                                    "TraceId": JSON.parse(localStorage.getItem('traceID')),

                                }
                            }
                        );
                    }
                })
            }
            sendRequest(fareRuleInput);
        }

        setFlag(true);


    }
    function tabList() {
        let tab = [];
        //RoundTrip
        if (fareRuleList.length !== 0 && fareRuleList.length === 2 && props.type !== 'multicity') {
            let id1 = fareRuleList[0].Data.FareRuleSourceInfo[0].FlightSegmentRuleInfo[0].DepartureAirport.LocationCode1 + "-" +
                fareRuleList[0].Data.FareRuleSourceInfo[0].FlightSegmentRuleInfo[0].ArrivalAirport.LocationCode1;
            let id2 = fareRuleList[1].Data.FareRuleSourceInfo[0].FlightSegmentRuleInfo[0].DepartureAirport.LocationCode1 + "-" +
                fareRuleList[1].Data.FareRuleSourceInfo[0].FlightSegmentRuleInfo[0].ArrivalAirport.LocationCode1;

            tab.push(id1 === "-" ? '' : <li className="nav-item pb-0" role="presentation">
                <button class='tabs-btn nav-link active' text="bcd" id={`departure_${id1}`} data-bs-toggle="tab"
                    data-bs-target={`#departure-tb${id1}`} type="button" role="tab" aria-controls={`departure-tb${id1}`}
                    aria-selected="true" onClick={(e) => tabChangeHandler(fareRuleList[0], 'roundTrip')}>{id1}</button>

            </li>);
            tab.push(id2 === "-" ? '' : <li className="nav-item pb-0" role="presentation">
                <button class='tabs-btn nav-link' text="bcd" id={`departure_${id2}`} data-bs-toggle="tab"
                    data-bs-target={`#departure-tb${id2}`} type="button" role="tab" aria-controls={`departure-tb${id2}`}
                    aria-selected="true" onClick={(e) => tabChangeHandler(fareRuleList[1], 'roundTrip')}>{id2}</button>

            </li>);
            return tab;

        }
        //multicity
        else if (props.type === 'multicity' && fareRuleList.length !== 0) {
            if (props.sourceId !== 'TJ') {
                fareRuleList.forEach((element, index) => {
                    let tab1 = element.segments.beginAirport + "-" + element.segments.endAirport;
                    let activetab = "tabs-btn nav-link";
                    if (index === 0) {
                        activetab = "tabs-btn nav-link active"
                    }
                    tab.push(tab1 === "-" ? '' : <li className="nav-item pb-0" role="presentation">
                        <button class={activetab} text="bcd" id={`departure_${tab1}`} data-bs-toggle="tab"
                            data-bs-target={`#departure-tb${tab1}`} type="button" role="tab" aria-controls={`departure-tb${tab1}`}
                            aria-selected="true" onClick={(e) => tabChangeHandler(element, 'multicity')}>{tab1}</button>

                    </li>);
                })
            } else {
                fareRuleList.originDestinationOptions.originDestinationOption.length !== 0 && fareRuleList.originDestinationOptions.originDestinationOption.forEach((element, index) => {
                    let tab1 = element.flightSegment[0].departureAirport.locationCode + "-" + element.flightSegment[0].arrivalAirport.locationCode;
                    let activetab = "tabs-btn nav-link";
                    if (index === 0) {
                        activetab = "tabs-btn nav-link active"
                    }
                    tab.push(tab1 === "-" ? '' : <li className="nav-item pb-0" role="presentation">
                        <button class={activetab} text="bcd" id={`departure_${tab1}`} data-bs-toggle="tab"
                            data-bs-target={`#departure-tb${tab1}`} type="button" role="tab" aria-controls={`departure-tb${tab1}`}
                            aria-selected="true" onClick={(e) => tabChangeHandler(element, 'multicity')}>{tab1}</button>

                    </li>);
                })
            }


        }
        //oneway
        else if (fareRuleList.length === 1) {
            {
                fareRuleList.length !== 0 && fareRuleList.forEach((element, index) => {
                    let id1 = element.Data.FareRuleSourceInfo[0].FlightSegmentRuleInfo[0].DepartureAirport.LocationCode + "-" + element.Data.FareRuleSourceInfo[0].FlightSegmentRuleInfo[0].ArrivalAirport.LocationCode;
                    let activetab = "tabs-btn nav-link";
                    if (index === 0) {
                        activetab = "tabs-btn nav-link active"
                    }
                    tab.push(id1 === "-" ? '' : <li className="nav-item pb-0" role="presentation">
                        <button class={activetab} text="bcd" id={`departure_${id1}`} data-bs-toggle="tab"
                            data-bs-target={`#departure-tb${id1}`} type="button" role="tab" aria-controls={`departure-tb${id1}`}
                            aria-selected="true" onClick={(e) => tabChangeHandler(element, 'oneway')}>{id1}</button>

                    </li>);
                })
            }

        }



        return tab;
    }
    const showLccFareRule = () => {
        let show = [];
        if (fareRuleResult !== undefined && fareRuleResult.length !== 0) {
            fareRuleResult.forEach(element => {
                if (element.dateChange !== undefined && element.dateChange[0].policyInfo !== undefined && element.dateChange[0].policyInfo !== '') {
                    let amount = element.dateChange[0].amount !== undefined ? element.dateChange[0].amount : 0;
                    let fee = element.dateChange[0].additionalFee !== undefined ? element.dateChange[0].additionalFee : 0;
                    let totalAmount = amount + fee;
                    let inputString = element.dateChange[0].policyInfo !== undefined ? element.dateChange[0].policyInfo : '';
                    inputString = inputString.startsWith('+') ? inputString.replace('+', '') : inputString;
                    inputString = inputString.replace(/__nls__/g, '');
                    let policy = totalAmount !== undefined && totalAmount !== 0 ? totalAmount + ", " + inputString : inputString;
                    if (policy !== "") {
                        show.push(<div class="h6 text-primary mb-3"> Date Change Fee <br />
                            <span id="spnTotalAmountTop" class="text-black"> {policy} </span>
                        </div>);
                    }

                }
                if (element.cancellation !== undefined && element.cancellation[0].policyInfo !== undefined && element.cancellation[0].policyInfo !== '') {
                    let amount = element.cancellation[0].amount !== undefined ? element.cancellation[0].amount : 0;
                    let fee = element.cancellation[0].additionalFee !== undefined ? element.cancellation[0].additionalFee : 0;
                    let totalAmount = amount + fee;
                    let inputString = element.cancellation[0].policyInfo !== undefined ? element.cancellation[0].policyInfo : '';
                    inputString = inputString.startsWith('+') ? inputString.replace('+', '') : inputString;
                    inputString = inputString.replace(/__nls__/g, '');
                    let policy = totalAmount !== undefined && totalAmount !== 0 ? totalAmount + ", " + inputString : inputString;
                    show.push(<div class="h6 text-primary mb-3"> Cancellation Fee <br />
                        <span id="spnTotalAmountTop" class="text-black">{policy}</span>
                    </div>)
                }
                if (element.noShow !== undefined && element.noShow[0].policyInfo !== undefined && element.noShow[0].policyInfo !== "") {
                    let amount = element.noShow[0].amount !== undefined ? element.noShow[0].amount : 0;
                    let fee = element.noShow[0].additionalFee !== undefined ? element.noShow[0].additionalFee : 0;
                    let totalAmount = amount + fee;
                    let inputString = element.noShow[0].policyInfo !== undefined ? element.noShow[0].policyInfo : '';
                    inputString = inputString.startsWith('+') ? inputString.replace('+', '') : inputString;
                    inputString = inputString.replace(/__nls__/g, '');
                    let policy = totalAmount !== undefined && totalAmount !== 0 ? totalAmount + ", " + inputString : inputString;
                    show.push(<div class="h6 text-primary mb-3"> No Show <br />
                        <span id="spnTotalAmountTop" class="text-black"> {policy}</span>
                    </div>);
                }
                if (element.seatChargable !== undefined && element.seatChargable[0].policyInfo !== undefined && element.seatChargable[0].policyInfo !== "") {
                    let amount = element.seatChargable[0].amount !== undefined ? element.seatChargable[0].amount : 0;
                    let fee = element.seatChargable[0].additionalFee !== undefined ? element.seatChargable[0].additionalFee : 0;
                    let totalAmount = amount + fee;
                    let inputString = element.seatChargable[0].policyInfo !== undefined ? element.seatChargable[0].policyInfo : '';
                    inputString = inputString.startsWith('+') ? inputString.replace('+', '') : inputString;
                    inputString = inputString.replace(/__nls__/g, '');
                    let policy = totalAmount !== undefined && totalAmount !== 0 ? totalAmount + ", " + inputString : inputString;
                    show.push(<div class="h6 text-primary mb-2"> Seat Chargeable <br />
                        <span id="spnTotalAmountTop" class="text-black"> {policy}</span>
                    </div>);
                }

            });
        }
        return show;
    }
    return <Fragment>
        {props.sourceId !== 'TJ' && <div className='col-md-12'>
            <div className="row">
                <div className="col-md-12 pop-ups-tab-border-parent">
                    <ul className="nav nav-pills pop-ups-tab-border" id="myTab" role="tablist">
                        {tabList()}
                    </ul>
                </div>
                <div className="col-md-12 ">

                    <div className="px-3 tab-content include-exclude-tab" id="myTabContent">
                        {status === 'pending' &&
                            <div className='py-2 text-center'>
                                <i className="fa fa-spinner fa-spin"></i>
                            </div>
                        }

                        {status === 'completed' && fareRuleOptions.length === 0 && <div className='text-center py-2'>{fareRuleError}</div>}
                        {status === 'completed' && fareRuleResult !== undefined && fareRuleResult.length > 0 && fareRuleOptions.length > 0 && <div className="tab-pane fade show active" id={`#departure-tb${fareRuleResult[0].flightSegmentRuleInfo[0].departureAirport.locationCode}-${fareRuleResult[0].flightSegmentRuleInfo[0].departureAirport.locationCode}`} role="tabpanel" aria-labelledby="departure">
                            <div className=''>
                                <div className=' position-relative'>
                                    <div className='row mt-3'>

                                        <div className='col-md-12 gt-h-340px'>
                                            <div className='gt-mh-150px' >

                                                <div className='row'>
                                                    <div className='col-md-12  my-auto postion-relative'>
                                                        <Dropdown
                                                            options={fareRuleOptions}
                                                            value={fareRuleOptions.filter(option => option.value === selectId)}
                                                            onChange={fareRuleChangeHandler}
                                                            textField="label"
                                                            dataItemKey="value"
                                                            arrowClosed={<span className="arrow-closed" />}
                                                            isDisabled={status === 'pending' ? true : false}
                                                            arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' />
                                                    </div>
                                                    <i></i>
                                                    {/* <hr className='my-3'></hr> */}
                                                    <div className='text-16px  mt-3 display-line' id="fareRulesDetails">
                                                    </div>
                                                    <div className='text-16px mt-3 display-line' id="fareRulesDetailsFirst">
                                                        {flag && selectValue}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </div>}
        {props.sourceId === 'TJ' && <div class="row gt-h-340px">
            {status === 'pending' &&
                <div className='py-4 text-center'>
                    <i className="fa fa-spinner fa-spin "></i>
                </div>
            }
            {status === 'completed' && fareRuleResult.length === 0 && <div className='text-center py-2'>{fareRuleError}</div>}
            {status === 'completed' && showLccFareRule()}
            {status === 'completed' && fareRuleResult.length !== 0 && < span className="h6 text-primary mb-2 col-lg-12" > <span className="text-black fw-bold"><i className="fas fa-info-circle"></i> Note : </span> All amounts are in INR.</span>}
            <div class="text-16px  display-line" id="fareRulesDetails"></div>
        </div>}

    </Fragment >
};
export default FareRule;