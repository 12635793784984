import { Fragment } from "react";

const ApprovalHistory = (props) => {
    function approvalStatusLabel(history) {
        let statusHtml = "";
        switch (history.approvalStatusCode.toUpperCase()) {
            case "PD":
                statusHtml = <span class="badge rounded-pill text-16px bg-warning">
                    <i class="fa-solid fa-pause"></i> <span class="ms-1"></span> Pending</span>;
                break;
            case "AP":
                statusHtml = <span class="badge rounded-pill text-16px bg-success">
                    <i class="fa-solid fa-check"></i> <span class="ms-1"></span> Approved</span>;
                break;
            case "RJ":
                statusHtml = <span class="badge rounded-pill text-16px bg-danger">
                    <i class="fa-solid fa-times"></i> <span class="ms-1"></span> Rejected</span>;
                break;
            case "SUBMITTED":
                statusHtml = <span class="badge rounded-pill text-16px bg-primary">
                    <i class="fa-solid fa-pen"></i> <span class="ms-1"></span> Submitted</span>;
                break;
            default:
                statusHtml = history.approvalStatusCode;
                break;
        }
        return statusHtml;
    }

    return <Fragment>
        <div className="row">
            <div className="col-md-12">
                <div className="col-md-12 table-responsive mt-4 table-head-bg">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">Workflow</th>
                                <th scope="col" className="text-center">Status</th>
                                <th scope="col">Done By</th>
                                <th scope="col">Done On</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                props.data.map((history) => (
                                    <tr key={`${history.approvalLevel}${history.approvalUserID}`}>
                                        <td>
                                            {history.approvalTypeName}
                                        </td>
                                        <td className="text-center">
                                            {approvalStatusLabel(history)}
                                        </td>
                                        <td>
                                            {history.approvalUserName}
                                        </td>                                        
                                        <td>
                                            {history.approvalStatusCode === 'PD' ? '' : history.serverUpdatedDate}
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </Fragment>
};

export default ApprovalHistory;