import { Fragment, useEffect, useState } from 'react';
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle';
import Dropdown from 'react-select';
import SearchFlight from '../TravelRequest/Components/SearchFlight';
import SearchHotel from '../TravelRequest/Components/SearchHotel';
import ServiceRequest from '../TravelRequest/Components/ServiceRequest';
import Approver from '../TravelRequest/Components/Approver';
import MeetingDetails from '../TravelRequest/Components/MeetingDetails';
import Document from '../TravelRequest/Components/Document';
import ErrorModal from "../../../components/Common/Error/ErrorModal";
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import HotelDetails from '../TravelRequest/Components/HotelDetails';
import Booking from '../TravelRequest/Components/Booking';
import RateDetails from '../TravelRequest/Components/RateDetails';
import FlightItem from '../../../components/Common/Flight/FlightItem';
import FareSummary from '../../../components/Common/Flight/FareSummary';
import BookingLoadingSpinner from '../../Booking/Components/BookingLoadingSpinner';
import { decompress, compress } from '@amoutonbrady/lz-string';
import { getStops } from '../../../utils/SendRecommendation';
import useHttp from '../../../services/use-http';
import { sortFlightResult } from '../../../utils';
import { travelRequest } from '../../../services/Booking-API';
import { getConfigurationByBoolen } from '../../../utils/ConfigurationsFunction';
//import secureLocalStorage from 'react-secure-storage';
import { getApprovalID, getPolicyID } from '../../../utils/OBTFunction';
//import { CustomToFixed, getDateDiffDays } from '../../../../src/utils/CommonFunction';
import { CustomToFixed } from '../../../../src/utils/CommonFunction';
import { BOOKING } from '../../../navigation/CONSTANTS';
import { priceCheck } from '../../../services/Flight-API';
import ConfirmationModal from "../../../components/Common/Confirmation/ConfirmationModal";
import { checkSecurityDetails } from '../../../utils/RoleUtils';

const TravelRequest = () => {
    useDocumentTitle('Travel Request');
    const [tripReasonValue, setTripReasonValue] = useState('');
    const [error1, setError] = useState();
    const history = useHistory();
    const location = useLocation();
    const [tripReasonInputHasError, setTripReasonInputHasError] = useState();
    const valueParam = queryString.parse(location.search);
    const [selectedTab, setSelectedTab] = useState('');
    //const airlineLogoLink = process.env.REACT_APP_FLIGHT_LOG_URL;
    const [confirm, setConfirm] = useState('');
    const [tripTypeDisabled, setTripTypeDisabled] = useState(false);

    const { sendRequest: sendRequestTravelRequest, status: statusTravelRequest, data: loadedTravelRequest } = useHttp(travelRequest);
    const { sendRequest, status, data: loadedPriceCheckResponse } = useHttp(priceCheck);
    const [serviceRequestKey, setServiceRequestKey] = useState(1);

    let bookingJSON = JSON.parse(localStorage.getItem("BookingDataTravelRequest"));
    let travelRequestJSON = JSON.parse(localStorage.getItem("TravelRequestInput"));
    let tripReasonOptions = [];
    let tripReasonList = [];

    if (sessionStorage.getItem("travelRequestFieldsDataJson") === null) {
        const travelRequestFieldsDataJson = ({
            "justificationCodeFlight": {},
            "justificationDetailsFlight": {},
            "justificationCodeHotel": {},
            "justificationDetailsHotel": {},
            "serviceRequestId": {},
            "serviceRequestName": {},
            "remarks": {},
            "existingConfirmationNumber": {},
            "totalRateOfService": {},
            "travelRequestDocuments": {},
            "travelRequestDocumentsRemarks": {},
            "meetingCountry": {},
            "meetingCity": {},
            "meetingStartDate": {},
            "meetingEndDate": {},
            "meetingStartTime": {},
            "meetingEndTime": {},
            "meetingPurposeOfTravel": {},
            "meetingPurposeOfTravelText": {},
            "tripReason": {},
            "tripPurposeRemarks": ""
        });
        sessionStorage.setItem("travelRequestFieldsDataJson", JSON.stringify(travelRequestFieldsDataJson));
    }

    if (localStorage.getItem("TravelRequestInput") === null) {

        const loginData = localStorage.getItem("loginData") === null ? null : JSON.parse(localStorage.getItem("loginData"));
        const travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));

        let departmentID = "";
        let costCentreID = "";
        let positionID = "";
        let isFamilyBooking = false;

        let employeeCode = "";
        let employeeName = "";
        let approverEmployeeCode = "";
        let approverEmployeeName = "";

        let isAllowOutPolicyApprover = false;
        let isAllowHighRiskApprover = false;

        if (loginData) {
            departmentID = loginData.departmentID;
            costCentreID = loginData.costCentreID;
            positionID = loginData.positionID;

            employeeCode = loginData.employeeCode;
            employeeName = loginData.displayName;
            approverEmployeeCode = loginData.firstApproverEmployeeCode;
            approverEmployeeName = loginData.firstApprover;

            if (travelRequestFieldsDataJson) {
                const matchedTripType = loginData.tripReason.find(a => a.id === travelRequestFieldsDataJson.tripReason);
                if (matchedTripType) {
                    isFamilyBooking = matchedTripType.isFamilyBooking;
                }
            }

            loginData.configurationsDetails.configurationsMenuDetails.forEach((menu) => {
                if (menu.keyCode === 'ALLOW_OUT_OF_POLICY_WORKFLOW') {
                    isAllowOutPolicyApprover = menu.defaultBoolenValue;
                }
                else if (menu.keyCode === 'ALLOW_HIGH_RISK_WORKFLOWK') {
                    isAllowHighRiskApprover = menu.defaultBoolenValue;
                }
            });
        }

        const travelRequestInput = (
            {
                "officeID": JSON.parse(localStorage.getItem('officeID')),
                "data": {
                    "travellerDetails": {
                        "shoppingID": 0,
                        "rqeuestType": "TR",
                        "switchLoginID": localStorage.getItem('RootLoginEmailID') !== undefined ? localStorage.getItem('RootLoginEmailID') : '',
                        "totalCost": CustomToFixed(0, 2),
                        "userID": JSON.parse(localStorage.getItem('userID')),
                        "traceId": JSON.parse(localStorage.getItem('traceID')),
                        "approvalMasterID": getApprovalID(),
                        "approvalRequiredID": localStorage.getItem("loginData") === null ? null : JSON.parse(localStorage.getItem("loginData")).approvalRequiredID,
                        "policyMasterID": getPolicyID(),
                        "arrangerUserID": JSON.parse(localStorage.getItem('userID')),
                        "officeID": JSON.parse(localStorage.getItem('officeID')),
                        departmentID: departmentID,
                        costCentreID: costCentreID,
                        positionID: positionID,
                        isFamilyBooking: isFamilyBooking,
                    },
                    approverDetails: {
                        employeeCode: employeeCode,
                        employeeName: employeeName,
                        apporverEmployeeCode: approverEmployeeCode,
                        apporverEmployeeName: approverEmployeeName
                    },
                    "meetingDetails": null,
                    "onlineFlightDetails": null,
                    "onlineFlightFareDetails": null,
                    "onlineFlightLowestDetails": null,
                    "onlineFlightLowestFareDetails": null,
                    "onlineHotelDetails": null,
                    "onlineHotelLowestDetails": null,
                    "serviceRequest": null,
                    "travelRequestDocuments": [],
                    "bookingData": [],
                    isAllowOutPolicyApprover: isAllowOutPolicyApprover,
                    isAllowHighRiskApprover: isAllowHighRiskApprover,

                }
            }
        );
        localStorage.setItem("TravelRequestInput", JSON.stringify(travelRequestInput));
    }

    function disableTripReason() {
        // document.querySelectorAll('input[role="combobox"]').disabled = true;
        // document.querySelector('.css-1u9des2-indicatorSeparator').classList.add('d-none');
        // document.querySelector('.css-1xc3v61-indicatorContainer').classList.add('d-none');
        // document.querySelector('.css-13cymwt-control').classList.add('gt-disabled');
    }

    let loadedData = [];
    if (localStorage.getItem("loginData") !== null) {

        let tripReasonAndPolicyDataJson = JSON.parse(localStorage.getItem("loginData"));

        loadedData = tripReasonAndPolicyDataJson.tripReason;
        if (loadedData !== null) {
            loadedData.forEach(element => {
                tripReasonOptions.push({ value: element.id, label: element.name });
            });
        }
    }

    useEffect(() => {
        if (valueParam.return === undefined || valueParam.return === '' || valueParam.return === '0') {
            sessionStorage.removeItem("travelRequestFieldsDataJson");
            sessionStorage.removeItem("travelreqflightsearch");
            sessionStorage.removeItem("travelreqhotelsearch");
            localStorage.removeItem("BookingDataTravelRequest");
            localStorage.removeItem("TravelRequestInput");
            const travelRequestFieldsDataJson = ({
                "justificationCodeFlight": {},
                "justificationDetailsFlight": {},
                "justificationCodeHotel": {},
                "justificationDetailsHotel": {},
                "serviceRequestId": {},
                "serviceRequestName": {},
                "remarks": {},
                "existingConfirmationNumber": {},
                "totalRateOfService": {},
                "travelRequestDocuments": {},
                "travelRequestDocumentsRemarks": {},
                "meetingCountry": {},
                "meetingCity": {},
                "meetingStartDate": {},
                "meetingEndDate": {},
                "meetingStartTime": {},
                "meetingEndTime": {},
                "meetingPurposeOfTravel": {},
                "meetingPurposeOfTravelText": {},
                "tripReason": {},
                "tripPurposeRemarks": ""
            });
            sessionStorage.setItem("travelRequestFieldsDataJson", JSON.stringify(travelRequestFieldsDataJson));
            toggleTripReason();
            //refresh service component
            setServiceRequestKey(key => key + 1);
        }

        if (valueParam.return !== undefined && valueParam.return !== '' && valueParam.return === '1') {
            if (localStorage.getItem('BookingDataTravelRequest') !== null || localStorage.getItem('TravelRequestInput') !== null) {
                disableTripReason();
            }

            let travelRequestFieldsDataJson = "";
            if (sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
                travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
                if (travelRequestFieldsDataJson.tripReason !== null && travelRequestFieldsDataJson.tripReason !== '') {
                    setTripReasonValue(travelRequestFieldsDataJson.tripReason);
                    sessionStorage.removeItem("spnClass1");
                }
            }

            let searchPolicyResponse = "";
            let tripReasonAndPolicyDataJson = "";

            if (localStorage.getItem("loginData") !== null) {
                tripReasonAndPolicyDataJson = JSON.parse(localStorage.getItem("loginData"));
                tripReasonList = tripReasonAndPolicyDataJson.tripReason.filter(a => a.id === travelRequestFieldsDataJson.tripReason);
            }

            if (localStorage.getItem("loginData") !== null && tripReasonAndPolicyDataJson.isTravelPolicy === false) {
                let searchPolicyResponseTemp = JSON.parse(localStorage.getItem("loginData"));
                searchPolicyResponse = searchPolicyResponseTemp.policy.filter(a => a.tripReasonID === travelRequestFieldsDataJson.tripReason);
            }

            let isFlight = false;
            let isHotel = false;
            let isServiceRequest = false;

            searchPolicyResponse.length > 0 && searchPolicyResponse.forEach(elementPolicy => {
                if (checkSecurityDetails("Book", "Flight") && checkSecurityDetails("Book", "Flight", "Book_Flight_Search") && getConfigurationByBoolen("ALLOW_FLIGHT") === true && elementPolicy.isFlight === true) {
                    isFlight = true;
                }
                if (checkSecurityDetails("Book", "Hotel") && checkSecurityDetails("Book", "Hotel", "Book_Hotel_Search") && getConfigurationByBoolen("ALLOW_HOTEL") && elementPolicy.isHotel === true) {
                    isHotel = true;
                }
                if (getConfigurationByBoolen("ALLOW_SERVICE_REQUEST") && elementPolicy.isServiceRequest === true) {
                    isServiceRequest = true;
                }
            });

            if (searchPolicyResponse.length > 0) {
                if (isFlight === false) {
                    if (document.querySelector('#tabButton_Flight') !== null) {
                        document.querySelector('#tabButton_Flight').classList.add("d-none");
                        document.querySelector('#tabButton_Flight_parent').classList.add("d-none");
                    }

                    if (document.querySelector('#tabArea_Flight') !== null) {
                        document.querySelector('#tabArea_Flight').classList.add("d-none");
                    }
                }

                if (isHotel === false) {
                    if (document.querySelector('#tabButton_Hotel') !== null) {
                        document.querySelector('#tabButton_Hotel').classList.add("d-none");
                        document.querySelector('#tabButton_Hotel_parent').classList.add("d-none");
                    }

                    if (document.querySelector('#tabArea_Hotel') !== null) {
                        document.querySelector('#tabArea_Hotel').classList.add("d-none");
                    }
                }

                if (isServiceRequest === false) {
                    if (document.querySelector('#tabButton_ServiceRequest') !== null) {
                        document.querySelector('#tabButton_ServiceRequest').classList.add("d-none");
                        document.querySelector('#tabButton_ServiceRequest_parent').classList.add("d-none");
                    }

                    if (document.querySelector('#tabArea_ServiceRequest') !== null) {
                        document.querySelector('#tabArea_ServiceRequest').classList.add("d-none");
                    }
                }
            }
            else {
                if (getConfigurationByBoolen("ALLOW_FLIGHT") === false && isFlight === false) {
                    if (document.querySelector('#tabButton_Flight') !== null) {
                        document.querySelector('#tabButton_Flight').classList.add("d-none");
                        document.querySelector('#tabButton_Flight_parent').classList.add("d-none");
                    }

                    if (document.querySelector('#tabArea_Flight') !== null) {
                        document.querySelector('#tabArea_Flight').classList.add("d-none");
                    }
                }

                if (getConfigurationByBoolen("ALLOW_HOTEL") === false && isHotel === false) {
                    if (document.querySelector('#tabButton_Hotel') !== null) {
                        document.querySelector('#tabButton_Hotel').classList.add("d-none");
                        document.querySelector('#tabButton_Hotel_parent').classList.add("d-none");
                    }

                    if (document.querySelector('#tabArea_Hotel') !== null) {
                        document.querySelector('#tabArea_Hotel').classList.add("d-none");
                    }
                }

                if (getConfigurationByBoolen("ALLOW_SERVICE_REQUEST") === false && isServiceRequest === false) {
                    if (document.querySelector('#tabButton_ServiceRequest') !== null) {
                        document.querySelector('#tabButton_ServiceRequest').classList.add("d-none");
                        document.querySelector('#tabButton_ServiceRequest_parent').classList.add("d-none");
                    }

                    if (document.querySelector('#tabArea_ServiceRequest') !== null) {
                        document.querySelector('#tabArea_ServiceRequest').classList.add("d-none");
                    }
                }
            }

            if (tripReasonList.length > 0) {
                if (tripReasonList[0].meetingID === "1") {
                    if (document.querySelector('#tabButton_MeetingDetails') !== null) {
                        document.querySelector('#tabButton_MeetingDetails').classList.add("d-none");
                    }

                    if (document.querySelector('#tabArea_MeetingDetails') !== null) {
                        document.querySelector('#tabArea_MeetingDetails').classList.add("d-none");
                    }
                }

                if (tripReasonList.length > 0 && (tripReasonList[0].meetingID === "1" || tripReasonList[0].meetingID === "3")) {
                    let inputs = document.querySelectorAll('.meeting');
                    for (let i = 0; i < inputs.length; i++) {
                        if (document.querySelector('#' + inputs[i].getAttribute("id")) !== null) {
                            document.querySelector('#' + inputs[i].getAttribute("id")).innerHTML = "";
                        }
                    }
                }
                else {
                    let inputs = document.querySelectorAll('.meeting');
                    for (let i = 0; i < inputs.length; i++) {
                        if (document.querySelector('#' + inputs[i].getAttribute("id")) !== null) {
                            document.querySelector('#' + inputs[i].getAttribute("id")).innerHTML = " *";
                        }
                    }
                }
            }

            if (valueParam.service !== undefined && valueParam.service !== '' && valueParam.service === 'flight') {
                onTabChangeHandler('Flight');

                if (document.querySelector('#tabButton_Flight') !== null) {
                    document.querySelector('#tabButton_Flight').classList.add("active");
                }
                if (document.querySelector('#tabArea_Flight') !== null) {
                    document.querySelector('#tabArea_Flight').classList.add("active");
                }
                if (document.querySelector('#tabArea_Flight') !== null) {
                    if (!document.querySelector('#tabArea_Flight').classList.toString().includes('show')) {
                        document.querySelector('#tabArea_Flight').classList.add("show");
                    }
                }
                if (document.querySelector('#tabButton_Hotel') !== null) {
                    document.querySelector('#tabButton_Hotel').classList.remove("active");
                }
                if (document.querySelector('#tabArea_Hotel') !== null) {
                    document.querySelector('#tabArea_Hotel').classList.remove("active");
                }
                if (document.querySelector('#tabButton_ServiceRequest') !== null) {
                    document.querySelector('#tabButton_ServiceRequest').classList.remove("active");
                }
                if (document.querySelector('#tabArea_ServiceRequest') !== null) {
                    document.querySelector('#tabArea_ServiceRequest').classList.remove("active");
                }
                if (document.querySelector('#tabButton_Approver') !== null) {
                    document.querySelector('#tabButton_Approver').classList.remove("active");
                }
                if (document.querySelector('#tabArea_Approver') !== null) {
                    document.querySelector('#tabArea_Approver').classList.remove("active");
                }
                if (document.querySelector('#tabButton_Document') !== null) {
                    document.querySelector('#tabButton_Document').classList.remove("active");
                }
                if (document.querySelector('#tabArea_Document') !== null) {
                    document.querySelector('#tabArea_Document').classList.remove("active");
                }
                if (document.querySelector('#tabButton_MeetingDetails') !== null) {
                    document.querySelector('#tabButton_MeetingDetails').classList.remove("active");
                }
                if (document.querySelector('#tabArea_MeetingDetails') !== null) {
                    document.querySelector('#tabArea_MeetingDetails').classList.remove("active");
                }
            }
            else if (valueParam.service !== undefined && valueParam.service !== '' && valueParam.service === 'hotel') {
                onTabChangeHandler('Hotel');

                if (document.querySelector('#tabButton_Flight') !== null) {
                    document.querySelector('#tabButton_Flight').classList.remove("active");
                }
                if (document.querySelector('#tabArea_Flight') !== null) {
                    document.querySelector('#tabArea_Flight').classList.remove("active");
                }
                if (document.querySelector('#tabButton_Hotel') !== null) {
                    document.querySelector('#tabButton_Hotel').classList.add("active");
                }
                if (document.querySelector('#tabArea_Hotel') !== null) {
                    document.querySelector('#tabArea_Hotel').classList.add("active");
                }
                if (document.querySelector('#tabArea_Hotel') !== null) {
                    if (!document.querySelector('#tabArea_Hotel').classList.toString().includes('show')) {
                        document.querySelector('#tabArea_Hotel').classList.add("show");
                    }
                }
                if (document.querySelector('#tabButton_ServiceRequest') !== null) {
                    document.querySelector('#tabButton_ServiceRequest').classList.remove("active");
                }
                if (document.querySelector('#tabArea_ServiceRequest') !== null) {
                    document.querySelector('#tabArea_ServiceRequest').classList.remove("active");
                }
                if (document.querySelector('#tabButton_Approver') !== null) {
                    document.querySelector('#tabButton_Approver').classList.remove("active");
                }
                if (document.querySelector('#tabArea_Approver') !== null) {
                    document.querySelector('#tabArea_Approver').classList.remove("active");
                }
                if (document.querySelector('#tabButton_Document') !== null) {
                    document.querySelector('#tabButton_Document').classList.remove("active");
                }
                if (document.querySelector('#tabArea_Document') !== null) {
                    document.querySelector('#tabArea_Document').classList.remove("active");
                }
                if (document.querySelector('#tabButton_MeetingDetails') !== null) {
                    document.querySelector('#tabButton_MeetingDetails').classList.remove("active");
                }
                if (document.querySelector('#tabArea_MeetingDetails') !== null) {
                    document.querySelector('#tabArea_MeetingDetails').classList.remove("active");
                }
            }
            else if (valueParam.service !== undefined && valueParam.service !== '' && valueParam.service === 'servicerequest') {
                onTabChangeHandler('ServiceRequest');

                if (document.querySelector('#tabButton_Flight') !== null) {
                    document.querySelector('#tabButton_Flight').classList.remove("active");
                }
                if (document.querySelector('#tabArea_Flight') !== null) {
                    document.querySelector('#tabArea_Flight').classList.remove("active");
                }
                if (document.querySelector('#tabButton_Hotel') !== null) {
                    document.querySelector('#tabButton_Hotel').classList.remove("active");
                }
                if (document.querySelector('#tabArea_Hotel') !== null) {
                    document.querySelector('#tabArea_Hotel').classList.remove("active");
                }
                if (document.querySelector('#tabButton_ServiceRequest') !== null) {
                    document.querySelector('#tabButton_ServiceRequest').classList.add("active");
                }
                if (document.querySelector('#tabArea_ServiceRequest') !== null) {
                    document.querySelector('#tabArea_ServiceRequest').classList.add("active");
                }
                if (document.querySelector('#tabArea_ServiceRequest') !== null) {
                    if (!document.querySelector('#tabArea_ServiceRequest').classList.toString().includes('show')) {
                        document.querySelector('#tabArea_ServiceRequest').classList.add("show");
                    }
                }
                if (document.querySelector('#tabButton_Approver') !== null) {
                    document.querySelector('#tabButton_Approver').classList.remove("active");
                }
                if (document.querySelector('#tabArea_Approver') !== null) {
                    document.querySelector('#tabArea_Approver').classList.remove("active");
                }
                if (document.querySelector('#tabButton_Document') !== null) {
                    document.querySelector('#tabButton_Document').classList.remove("active");
                }
                if (document.querySelector('#tabArea_Document') !== null) {
                    document.querySelector('#tabArea_Document').classList.remove("active");
                }
                if (document.querySelector('#tabButton_MeetingDetails') !== null) {
                    document.querySelector('#tabButton_MeetingDetails').classList.remove("active");
                }
                if (document.querySelector('#tabArea_MeetingDetails') !== null) {
                    document.querySelector('#tabArea_MeetingDetails').classList.remove("active");
                }
            }
            else {
                if (selectedTab !== undefined && selectedTab !== '') {
                    if (selectedTab === 'Flight') {
                        onTabChangeHandler('Approver');

                        if (document.querySelector('#tabButton_Flight') !== null) {
                            document.querySelector('#tabButton_Flight').classList.remove("active");
                        }
                        if (document.querySelector('#tabArea_Flight') !== null) {
                            document.querySelector('#tabArea_Flight').classList.remove("active");
                        }
                        if (document.querySelector('#tabButton_Hotel') !== null) {
                            document.querySelector('#tabButton_Hotel').classList.remove("active");
                        }
                        if (document.querySelector('#tabArea_Hotel') !== null) {
                            document.querySelector('#tabArea_Hotel').classList.remove("active");
                        }
                        if (document.querySelector('#tabButton_ServiceRequest') !== null) {
                            document.querySelector('#tabButton_ServiceRequest').classList.remove("active");
                        }
                        if (document.querySelector('#tabArea_ServiceRequest') !== null) {
                            document.querySelector('#tabArea_ServiceRequest').classList.remove("active");
                        }
                        if (document.querySelector('#tabButton_Approver') !== null) {
                            document.querySelector('#tabButton_Approver').classList.add("active");
                        }
                        if (document.querySelector('#tabArea_Approver') !== null) {
                            document.querySelector('#tabArea_Approver').classList.add("active");
                        }
                        if (document.querySelector('#tabArea_Approver') !== null) {
                            if (!document.querySelector('#tabArea_Approver').classList.toString().includes('show')) {
                                document.querySelector('#tabArea_Approver').classList.add("show");
                            }
                        }
                        if (document.querySelector('#tabButton_Document') !== null) {
                            document.querySelector('#tabButton_Document').classList.remove("active");
                        }
                        if (document.querySelector('#tabArea_Document') !== null) {
                            document.querySelector('#tabArea_Document').classList.remove("active");
                        }
                        if (document.querySelector('#tabButton_MeetingDetails') !== null) {
                            document.querySelector('#tabButton_MeetingDetails').classList.remove("active");
                        }
                        if (document.querySelector('#tabArea_MeetingDetails') !== null) {
                            document.querySelector('#tabArea_MeetingDetails').classList.remove("active");
                        }
                    }
                    else {
                        onTabChangeHandler(selectedTab);

                        if (document.querySelector('#tabButton_Flight') !== null) {
                            document.querySelector('#tabButton_Flight').classList.remove("active");
                        }
                        if (document.querySelector('#tabArea_Flight') !== null) {
                            document.querySelector('#tabArea_Flight').classList.remove("active");
                        }
                        if (document.querySelector('#tabButton_Hotel') !== null) {
                            document.querySelector('#tabButton_Hotel').classList.remove("active");
                        }
                        if (document.querySelector('#tabArea_Hotel') !== null) {
                            document.querySelector('#tabArea_Hotel').classList.remove("active");
                        }
                        if (document.querySelector('#tabButton_ServiceRequest') !== null) {
                            document.querySelector('#tabButton_ServiceRequest').classList.remove("active");
                        }
                        if (document.querySelector('#tabArea_ServiceRequest') !== null) {
                            document.querySelector('#tabArea_ServiceRequest').classList.remove("active");
                        }
                        if (document.querySelector('#tabButton_Document') !== null) {
                            document.querySelector('#tabButton_Document').classList.remove("active");
                        }
                        if (document.querySelector('#tabArea_Document') !== null) {
                            document.querySelector('#tabArea_Document').classList.remove("active");
                        }
                        if (document.querySelector('#tabButton_MeetingDetails') !== null) {
                            document.querySelector('#tabButton_MeetingDetails').classList.remove("active");
                        }
                        if (document.querySelector('#tabArea_MeetingDetails') !== null) {
                            document.querySelector('#tabArea_MeetingDetails').classList.remove("active");
                        }
                        if (document.querySelector('#tabButton_' + selectedTab) !== null) {
                            document.querySelector('#tabButton_' + selectedTab).classList.add("active");
                        }
                        if (document.querySelector('#tabArea_' + selectedTab) !== null) {
                            document.querySelector('#tabArea_' + selectedTab).classList.add("active");
                        }
                        if (document.querySelector('#tabArea_' + selectedTab) !== null) {
                            if (!document.querySelector('#tabArea_' + selectedTab).classList.toString().includes('show')) {
                                document.querySelector('#tabArea_' + selectedTab).classList.add("show");
                            }
                        }
                    }
                }
                else {
                    onTabChangeHandler('Approver');

                    if (document.querySelector('#tabButton_Flight') !== null) {
                        document.querySelector('#tabButton_Flight').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_Flight') !== null) {
                        document.querySelector('#tabArea_Flight').classList.remove("active");
                    }
                    if (document.querySelector('#tabButton_Hotel') !== null) {
                        document.querySelector('#tabButton_Hotel').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_Hotel') !== null) {
                        document.querySelector('#tabArea_Hotel').classList.remove("active");
                    }
                    if (document.querySelector('#tabButton_ServiceRequest') !== null) {
                        document.querySelector('#tabButton_ServiceRequest').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_ServiceRequest') !== null) {
                        document.querySelector('#tabArea_ServiceRequest').classList.remove("active");
                    }
                    if (document.querySelector('#tabButton_Approver') !== null) {
                        document.querySelector('#tabButton_Approver').classList.add("active");
                    }
                    if (document.querySelector('#tabArea_Approver') !== null) {
                        document.querySelector('#tabArea_Approver').classList.add("active");
                    }
                    if (document.querySelector('#tabArea_Approver') !== null) {
                        if (!document.querySelector('#tabArea_Approver').classList.toString().includes('show')) {
                            document.querySelector('#tabArea_Approver').classList.add("show");
                        }
                    }
                    if (document.querySelector('#tabButton_Document') !== null) {
                        document.querySelector('#tabButton_Document').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_Document') !== null) {
                        document.querySelector('#tabArea_Document').classList.remove("active");
                    }
                    if (document.querySelector('#tabButton_MeetingDetails') !== null) {
                        document.querySelector('#tabButton_MeetingDetails').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_MeetingDetails') !== null) {
                        document.querySelector('#tabArea_MeetingDetails').classList.remove("active");
                    }
                }
            }
        }
        else {
            let travelRequestFieldsDataJson = "";
            if (tripReasonOptions !== undefined && tripReasonOptions[0] !== undefined) {
                setTripReasonValue(tripReasonValue !== '' ? tripReasonValue : tripReasonOptions[0].value);
                sessionStorage.removeItem("spnClass1");

                if (sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
                    travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
                    travelRequestFieldsDataJson.tripReason = tripReasonValue !== '' ? tripReasonValue : tripReasonOptions[0].value;
                    sessionStorage.setItem("travelRequestFieldsDataJson", JSON.stringify(travelRequestFieldsDataJson));
                }
            }

            let searchPolicyResponse = "";
            let tripReasonAndPolicyDataJson = "";

            if (localStorage.getItem("loginData") !== null) {
                tripReasonAndPolicyDataJson = JSON.parse(localStorage.getItem("loginData"));
                tripReasonList = tripReasonAndPolicyDataJson.tripReason === null ? [] : tripReasonAndPolicyDataJson.tripReason.filter(a => a.id === travelRequestFieldsDataJson.tripReason);
            }

            let searchPolicyResponseTemp = JSON.parse(localStorage.getItem("loginData"));
            if (searchPolicyResponseTemp?.policy !== null) {
                searchPolicyResponse = searchPolicyResponseTemp?.policy.filter(a => a.tripReasonID === travelRequestFieldsDataJson.tripReason);
            }

            let isFlight = false;
            let isHotel = false;
            let isServiceRequest = false;

            searchPolicyResponse.length > 0 && searchPolicyResponse.forEach(elementPolicy => {
                if (checkSecurityDetails("Book", "Flight") && checkSecurityDetails("Book", "Flight", "Book_Flight_Search") && getConfigurationByBoolen("ALLOW_FLIGHT") === true && elementPolicy.isFlight === true) {
                    isFlight = true;
                }
                if (checkSecurityDetails("Book", "Hotel") && checkSecurityDetails("Book", "Hotel", "Book_Hotel_Search") && getConfigurationByBoolen("ALLOW_HOTEL") && elementPolicy.isHotel === true) {
                    isHotel = true;
                }
                if (getConfigurationByBoolen("ALLOW_SERVICE_REQUEST") && elementPolicy.isServiceRequest === true) {
                    isServiceRequest = true;
                }
            });

            if (searchPolicyResponse.length > 0) {
                if (isFlight === false) {
                    if (document.querySelector('#tabButton_Flight') !== null) {
                        document.querySelector('#tabButton_Flight').classList.add("d-none");
                        document.querySelector('#tabButton_Flight_parent').classList.add("d-none");
                    }

                    if (document.querySelector('#tabArea_Flight') !== null) {
                        document.querySelector('#tabArea_Flight').classList.add("d-none");
                    }
                }

                if (isHotel === false) {
                    if (document.querySelector('#tabButton_Hotel') !== null) {
                        document.querySelector('#tabButton_Hotel').classList.add("d-none");
                        document.querySelector('#tabButton_Hotel_parent').classList.add("d-none");
                    }

                    if (document.querySelector('#tabArea_Hotel') !== null) {
                        document.querySelector('#tabArea_Hotel').classList.add("d-none");
                    }
                }

                if (isServiceRequest === false) {
                    if (document.querySelector('#tabButton_ServiceRequest') !== null) {
                        document.querySelector('#tabButton_ServiceRequest').classList.add("d-none");
                        document.querySelector('#tabButton_ServiceRequest_parent').classList.add("d-none");
                    }

                    if (document.querySelector('#tabArea_ServiceRequest') !== null) {
                        document.querySelector('#tabArea_ServiceRequest').classList.add("d-none");
                    }
                }
            }
            else {
                if (getConfigurationByBoolen("ALLOW_FLIGHT") === false && isFlight === false) {
                    if (document.querySelector('#tabButton_Flight') !== null) {
                        document.querySelector('#tabButton_Flight').classList.add("d-none");
                        document.querySelector('#tabButton_Flight_parent').classList.add("d-none");
                    }

                    if (document.querySelector('#tabArea_Flight') !== null) {
                        document.querySelector('#tabArea_Flight').classList.add("d-none");
                    }
                }

                if (getConfigurationByBoolen("ALLOW_HOTEL") === false && isHotel === false) {
                    if (document.querySelector('#tabButton_Hotel') !== null) {
                        document.querySelector('#tabButton_Hotel').classList.add("d-none");
                        document.querySelector('#tabButton_Hotel_parent').classList.add("d-none");
                    }

                    if (document.querySelector('#tabArea_Hotel') !== null) {
                        document.querySelector('#tabArea_Hotel').classList.add("d-none");
                    }
                }

                if (getConfigurationByBoolen("ALLOW_SERVICE_REQUEST") === false && isServiceRequest === false) {
                    if (document.querySelector('#tabButton_ServiceRequest') !== null) {
                        document.querySelector('#tabButton_ServiceRequest').classList.add("d-none");
                        document.querySelector('#tabButton_ServiceRequest_parent').classList.add("d-none");
                    }

                    if (document.querySelector('#tabArea_ServiceRequest') !== null) {
                        document.querySelector('#tabArea_ServiceRequest').classList.add("d-none");
                    }
                }
            }

            if (tripReasonList.length > 0) {
                if (tripReasonList[0].meetingID === "1") {
                    if (document.querySelector('#tabButton_MeetingDetails') !== null) {
                        document.querySelector('#tabButton_MeetingDetails').classList.add("d-none");
                    }

                    if (document.querySelector('#tabArea_MeetingDetails') !== null) {
                        document.querySelector('#tabArea_MeetingDetails').classList.add("d-none");
                    }
                }

                if (tripReasonList.length > 0 && (tripReasonList[0].meetingID === "1" || tripReasonList[0].meetingID === "3")) {
                    let inputs = document.querySelectorAll('.meeting');
                    for (let i = 0; i < inputs.length; i++) {
                        if (document.querySelector('#' + inputs[i].getAttribute("id")) !== null) {
                            document.querySelector('#' + inputs[i].getAttribute("id")).innerHTML = "";
                        }
                    }
                }
                else {
                    let inputs = document.querySelectorAll('.meeting');
                    for (let i = 0; i < inputs.length; i++) {
                        if (document.querySelector('#' + inputs[i].getAttribute("id")) !== null) {
                            document.querySelector('#' + inputs[i].getAttribute("id")).innerHTML = " *";
                        }
                    }
                }
            }

            if (searchPolicyResponse.length > 0) {
                if (getConfigurationByBoolen("ALLOW_FLIGHT") === true && isFlight) {
                    onTabChangeHandler('Flight');

                    if (document.querySelector('#tabButton_Flight') !== null) {
                        document.querySelector('#tabButton_Flight').classList.add("active");
                    }
                    if (document.querySelector('#tabArea_Flight') !== null) {
                        document.querySelector('#tabArea_Flight').classList.add("active");
                    }
                    if (document.querySelector('#tabArea_Flight') !== null) {
                        if (!document.querySelector('#tabArea_Flight').classList.toString().includes('show')) {
                            document.querySelector('#tabArea_Flight').classList.add("show");
                        }
                    }
                    if (document.querySelector('#tabButton_Hotel') !== null) {
                        document.querySelector('#tabButton_Hotel').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_Hotel') !== null) {
                        document.querySelector('#tabArea_Hotel').classList.remove("active");
                    }
                    if (document.querySelector('#tabButton_ServiceRequest') !== null) {
                        document.querySelector('#tabButton_ServiceRequest').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_ServiceRequest') !== null) {
                        document.querySelector('#tabArea_ServiceRequest').classList.remove("active");
                    }
                    if (document.querySelector('#tabButton_Approver') !== null) {
                        document.querySelector('#tabButton_Approver').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_Approver') !== null) {
                        document.querySelector('#tabArea_Approver').classList.remove("active");
                    }
                    if (document.querySelector('#tabButton_Document') !== null) {
                        document.querySelector('#tabButton_Document').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_Document') !== null) {
                        document.querySelector('#tabArea_Document').classList.remove("active");
                    }
                    if (document.querySelector('#tabButton_MeetingDetails') !== null) {
                        document.querySelector('#tabButton_MeetingDetails').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_MeetingDetails') !== null) {
                        document.querySelector('#tabArea_MeetingDetails').classList.remove("active");
                    }
                } else if (getConfigurationByBoolen("ALLOW_HOTEL") && isHotel) {
                    onTabChangeHandler('Hotel');

                    if (document.querySelector('#tabButton_Flight') !== null) {
                        document.querySelector('#tabButton_Flight').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_Flight') !== null) {
                        document.querySelector('#tabArea_Flight').classList.remove("active");
                    }
                    if (document.querySelector('#tabButton_Hotel') !== null) {
                        document.querySelector('#tabButton_Hotel').classList.add("active");
                    }
                    if (document.querySelector('#tabArea_Hotel') !== null) {
                        document.querySelector('#tabArea_Hotel').classList.add("active");
                    }
                    if (document.querySelector('#tabArea_Hotel') !== null) {
                        if (!document.querySelector('#tabArea_Hotel').classList.toString().includes('show')) {
                            document.querySelector('#tabArea_Hotel').classList.add("show");
                        }
                    }
                    if (document.querySelector('#tabButton_ServiceRequest') !== null) {
                        document.querySelector('#tabButton_ServiceRequest').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_ServiceRequest') !== null) {
                        document.querySelector('#tabArea_ServiceRequest').classList.remove("active");
                    }
                    if (document.querySelector('#tabButton_Approver') !== null) {
                        document.querySelector('#tabButton_Approver').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_Approver') !== null) {
                        document.querySelector('#tabArea_Approver').classList.remove("active");
                    }
                    if (document.querySelector('#tabButton_Document') !== null) {
                        document.querySelector('#tabButton_Document').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_Document') !== null) {
                        document.querySelector('#tabArea_Document').classList.remove("active");
                    }
                    if (document.querySelector('#tabButton_MeetingDetails') !== null) {
                        document.querySelector('#tabButton_MeetingDetails').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_MeetingDetails') !== null) {
                        document.querySelector('#tabArea_MeetingDetails').classList.remove("active");
                    }
                } else if (getConfigurationByBoolen("ALLOW_SERVICE_REQUEST") && isServiceRequest) {
                    onTabChangeHandler('ServiceRequest');

                    if (document.querySelector('#tabButton_Flight') !== null) {
                        document.querySelector('#tabButton_Flight').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_Flight') !== null) {
                        document.querySelector('#tabArea_Flight').classList.remove("active");
                    }
                    if (document.querySelector('#tabButton_Hotel') !== null) {
                        document.querySelector('#tabButton_Hotel').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_Hotel') !== null) {
                        document.querySelector('#tabArea_Hotel').classList.remove("active");
                    }
                    if (document.querySelector('#tabButton_ServiceRequest') !== null) {
                        document.querySelector('#tabButton_ServiceRequest').classList.add("active");
                    }
                    if (document.querySelector('#tabArea_ServiceRequest') !== null) {
                        document.querySelector('#tabArea_ServiceRequest').classList.add("active");
                    }
                    if (document.querySelector('#tabArea_ServiceRequest') !== null) {
                        if (!document.querySelector('#tabArea_ServiceRequest').classList.toString().includes('show')) {
                            document.querySelector('#tabArea_ServiceRequest').classList.add("show");
                        }
                    }
                    if (document.querySelector('#tabButton_Approver') !== null) {
                        document.querySelector('#tabButton_Approver').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_Approver') !== null) {
                        document.querySelector('#tabArea_Approver').classList.remove("active");
                    }
                    if (document.querySelector('#tabButton_Document') !== null) {
                        document.querySelector('#tabButton_Document').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_Document') !== null) {
                        document.querySelector('#tabArea_Document').classList.remove("active");
                    }
                    if (document.querySelector('#tabButton_MeetingDetails') !== null) {
                        document.querySelector('#tabButton_MeetingDetails').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_MeetingDetails') !== null) {
                        document.querySelector('#tabArea_MeetingDetails').classList.remove("active");
                    }
                }
                else {
                    onTabChangeHandler('Flight');

                    if (document.querySelector('#tabButton_Flight') !== null) {
                        document.querySelector('#tabButton_Flight').classList.add("active");
                    }
                    if (document.querySelector('#tabArea_Flight') !== null) {
                        document.querySelector('#tabArea_Flight').classList.add("active");
                    }
                    if (document.querySelector('#tabArea_Flight') !== null) {
                        if (!document.querySelector('#tabArea_Flight').classList.toString().includes('show')) {
                            document.querySelector('#tabArea_Flight').classList.add("show");
                        }
                    }
                    if (document.querySelector('#tabButton_Hotel') !== null) {
                        document.querySelector('#tabButton_Hotel').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_Hotel') !== null) {
                        document.querySelector('#tabArea_Hotel').classList.remove("active");
                    }
                    if (document.querySelector('#tabButton_ServiceRequest') !== null) {
                        document.querySelector('#tabButton_ServiceRequest').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_ServiceRequest') !== null) {
                        document.querySelector('#tabArea_ServiceRequest').classList.remove("active");
                    }
                    if (document.querySelector('#tabButton_Approver') !== null) {
                        document.querySelector('#tabButton_Approver').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_Approver') !== null) {
                        document.querySelector('#tabArea_Approver').classList.remove("active");
                    }
                    if (document.querySelector('#tabButton_Document') !== null) {
                        document.querySelector('#tabButton_Document').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_Document') !== null) {
                        document.querySelector('#tabArea_Document').classList.remove("active");
                    }
                    if (document.querySelector('#tabButton_MeetingDetails') !== null) {
                        document.querySelector('#tabButton_MeetingDetails').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_MeetingDetails') !== null) {
                        document.querySelector('#tabArea_MeetingDetails').classList.remove("active");
                    }
                }
            }
            else {
                onTabChangeHandler('Flight');

                if (document.querySelector('#tabButton_Flight') !== null) {
                    document.querySelector('#tabButton_Flight').classList.add("active");
                }
                if (document.querySelector('#tabArea_Flight') !== null) {
                    document.querySelector('#tabArea_Flight').classList.add("active");
                }
                if (document.querySelector('#tabArea_Flight') !== null) {
                    if (!document.querySelector('#tabArea_Flight').classList.toString().includes('show')) {
                        document.querySelector('#tabArea_Flight').classList.add("show");
                    }
                }
                if (document.querySelector('#tabButton_Hotel') !== null) {
                    document.querySelector('#tabButton_Hotel').classList.remove("active");
                }
                if (document.querySelector('#tabArea_Hotel') !== null) {
                    document.querySelector('#tabArea_Hotel').classList.remove("active");
                }
                if (document.querySelector('#tabButton_ServiceRequest') !== null) {
                    document.querySelector('#tabButton_ServiceRequest').classList.remove("active");
                }
                if (document.querySelector('#tabArea_ServiceRequest') !== null) {
                    document.querySelector('#tabArea_ServiceRequest').classList.remove("active");
                }
                if (document.querySelector('#tabButton_Approver') !== null) {
                    document.querySelector('#tabButton_Approver').classList.remove("active");
                }
                if (document.querySelector('#tabArea_Approver') !== null) {
                    document.querySelector('#tabArea_Approver').classList.remove("active");
                }
                if (document.querySelector('#tabButton_Document') !== null) {
                    document.querySelector('#tabButton_Document').classList.remove("active");
                }
                if (document.querySelector('#tabArea_Document') !== null) {
                    document.querySelector('#tabArea_Document').classList.remove("active");
                }
                if (document.querySelector('#tabButton_MeetingDetails') !== null) {
                    document.querySelector('#tabButton_MeetingDetails').classList.remove("active");
                }
                if (document.querySelector('#tabArea_MeetingDetails') !== null) {
                    document.querySelector('#tabArea_MeetingDetails').classList.remove("active");
                }
            }
        }

    }, [valueParam.return])

    const onTabChangeHandler = (tabOption) => {
        setSelectedTab(tabOption);

        if (document.querySelector('#tabButton_' + tabOption) !== null) {
            document.querySelector('#tabButton_' + tabOption).classList.add("active");
        }

        //tabOption === 'ServiceRequest' || tabOption === 'Approver' || tabOption === 'Document'
        if (document.querySelector('#tabButton_MeetingDetails') !== null && document.querySelector('#tabButton_MeetingDetails').classList.toString().includes('d-none')) {

            let travelRequestFieldsDataJson = "";
            if (sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
                travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
            }

            let TravelRequestInputJson = "";
            if (localStorage.getItem("TravelRequestInput") !== null) {
                TravelRequestInputJson = JSON.parse(localStorage.getItem("TravelRequestInput"));
            }

            if ((localStorage.getItem("BookingDataTravelRequest") !== null || localStorage.getItem("TravelRequestInput") !== null || travelRequestFieldsDataJson.serviceRequestId.length !== undefined) && (tabOption === 'Flight' || tabOption === 'Hotel' || tabOption === 'ServiceRequest' || tabOption === 'Document')) {
                if (localStorage.getItem("BookingDataTravelRequest") !== null || TravelRequestInputJson.data.onlineFlightDetails !== null || TravelRequestInputJson.data.onlineHotelDetails !== null || TravelRequestInputJson.data.serviceRequest !== null || (travelRequestFieldsDataJson.serviceRequestId.length !== undefined && travelRequestFieldsDataJson.serviceRequestId.length !== 0)) {
                    if (document.querySelector('#btnSubmit') !== null) {
                        document.querySelector('#btnSubmit').classList.remove("d-none");
                    }
                    if (document.querySelector('#btnReset') !== null) {
                        document.querySelector('#btnReset').classList.remove("d-none");
                    }
                }
                else {
                    if (document.querySelector('#btnSubmit') !== null) {
                        document.querySelector('#btnSubmit').classList.add("d-none");
                    }
                    if (document.querySelector('#btnReset') !== null) {
                        document.querySelector('#btnReset').classList.add("d-none");
                    }
                }
            }
            else {
                if (document.querySelector('#btnSubmit') !== null) {
                    document.querySelector('#btnSubmit').classList.add("d-none");
                }
                if (document.querySelector('#btnReset') !== null) {
                    document.querySelector('#btnReset').classList.add("d-none");
                }
            }
        }
        else {
            let travelRequestFieldsDataJson = "";
            if (sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
                travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
            }

            let TravelRequestInputJson = "";
            if (localStorage.getItem("TravelRequestInput") !== null) {
                TravelRequestInputJson = JSON.parse(localStorage.getItem("TravelRequestInput"));
            }

            if ((localStorage.getItem("BookingDataTravelRequest") !== null || localStorage.getItem("TravelRequestInput") !== null
                || (travelRequestFieldsDataJson.serviceRequests && travelRequestFieldsDataJson.serviceRequests.length)) &&
                (tabOption === 'Flight' || tabOption === 'Hotel' || tabOption === 'ServiceRequest' || tabOption === 'MeetingDetails' || tabOption === 'Document')) {
                if (localStorage.getItem("BookingDataTravelRequest") !== null || TravelRequestInputJson.data.onlineFlightDetails !== null
                    || TravelRequestInputJson.data.onlineHotelDetails !== null || TravelRequestInputJson.data.serviceRequest !== null
                    || (travelRequestFieldsDataJson.serviceRequests && travelRequestFieldsDataJson.serviceRequests.length)) {
                    if (document.querySelector('#btnSubmit') !== null) {
                        document.querySelector('#btnSubmit').classList.remove("d-none");
                    }
                    if (document.querySelector('#btnReset') !== null) {
                        document.querySelector('#btnReset').classList.remove("d-none");
                    }
                }
                else {
                    if (document.querySelector('#btnSubmit') !== null) {
                        document.querySelector('#btnSubmit').classList.add("d-none");
                    }
                    if (document.querySelector('#btnReset') !== null) {
                        document.querySelector('#btnReset').classList.add("d-none");
                    }
                }
            }
            else {
                if (document.querySelector('#btnSubmit') !== null) {
                    document.querySelector('#btnSubmit').classList.add("d-none");
                }
                if (document.querySelector('#btnReset') !== null) {
                    document.querySelector('#btnReset').classList.add("d-none");
                }
            }
        }

        if (tabOption === 'ServiceRequest' || tabOption === 'MeetingDetails') {
            if (document.querySelector('#requiredIndicator') !== null) {
                document.querySelector('#requiredIndicator').classList.remove("d-none");
            }
        }
        else {
            if (document.querySelector('#requiredIndicator') !== null) {
                document.querySelector('#requiredIndicator').classList.add("d-none");
            }
        }

        if (tabOption === 'Hotel') {
            sessionStorage.setItem("HotelCBT", "1")
        }
        else {
            sessionStorage.removeItem("HotelCBT");
        }
    }

    const tripReasonChangeHandler = (event) => {
        setTripReasonValue(event.value);
        sessionStorage.removeItem("spnClass1");

        if (document.querySelector('#tabButton_Flight') !== null) {
            document.querySelector('#tabButton_Flight').classList.remove("d-none");
            document.querySelector('#tabButton_Flight_parent').classList.remove("d-none");
        }

        if (document.querySelector('#tabArea_Flight') !== null) {
            document.querySelector('#tabArea_Flight').classList.remove("d-none");
        }

        if (document.querySelector('#tabButton_Hotel') !== null) {
            document.querySelector('#tabButton_Hotel').classList.remove("d-none");
            document.querySelector('#tabButton_Hotel_parent').classList.remove("d-none");
        }

        if (document.querySelector('#tabArea_Hotel') !== null) {
            document.querySelector('#tabArea_Hotel').classList.remove("d-none");
        }

        if (document.querySelector('#tabButton_ServiceRequest') !== null) {
            document.querySelector('#tabButton_ServiceRequest').classList.remove("d-none");
            document.querySelector('#tabButton_ServiceRequest_parent').classList.remove("d-none");
        }

        if (document.querySelector('#tabArea_ServiceRequest') !== null) {
            document.querySelector('#tabArea_ServiceRequest').classList.remove("d-none");
        }

        if (document.querySelector('#tabButton_Approver') !== null) {
            document.querySelector('#tabButton_Approver').classList.remove("d-none");
        }

        if (document.querySelector('#tabArea_Approver') !== null) {
            document.querySelector('#tabArea_Approver').classList.remove("d-none");
        }

        if (document.querySelector('#tabButton_Document') !== null) {
            document.querySelector('#tabButton_Document').classList.remove("d-none");
        }

        if (document.querySelector('#tabArea_Document') !== null) {
            document.querySelector('#tabArea_Document').classList.remove("d-none");
        }

        if (document.querySelector('#tabButton_MeetingDetails') !== null) {
            document.querySelector('#tabButton_MeetingDetails').classList.remove("d-none");
        }

        if (document.querySelector('#tabArea_MeetingDetails') !== null) {
            document.querySelector('#tabArea_MeetingDetails').classList.remove("d-none");
        }

        if (sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
            let travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
            travelRequestFieldsDataJson.tripReason = event.value;
            sessionStorage.setItem("travelRequestFieldsDataJson", JSON.stringify(travelRequestFieldsDataJson));
        }

        let searchPolicyResponse = "";
        let tripReasonAndPolicyDataJson = "";

        if (localStorage.getItem("loginData") !== null) {
            tripReasonAndPolicyDataJson = JSON.parse(localStorage.getItem("loginData"));
            tripReasonList = tripReasonAndPolicyDataJson.tripReason.filter(a => a.id === event.value);
        }

        if (localStorage.getItem("loginData") !== null && tripReasonAndPolicyDataJson.isTravelPolicy === false) {
            let searchPolicyResponseTemp = JSON.parse(localStorage.getItem("loginData"));
            searchPolicyResponse = searchPolicyResponseTemp.policy.filter(a => a.tripReasonID === event.value);
        }

        let isFlight = false;
        let isHotel = false;
        let isServiceRequest = false;

        searchPolicyResponse.length > 0 && searchPolicyResponse.forEach(elementPolicy => {
            if (checkSecurityDetails("Book", "Flight") && checkSecurityDetails("Book", "Flight", "Book_Flight_Search") && getConfigurationByBoolen("ALLOW_FLIGHT") === true && elementPolicy.isFlight === true) {
                isFlight = true;
            }
            if (checkSecurityDetails("Book", "Hotel") && checkSecurityDetails("Book", "Hotel", "Book_Hotel_Search") && getConfigurationByBoolen("ALLOW_HOTEL") && elementPolicy.isHotel === true) {
                isHotel = true;
            }
            if (getConfigurationByBoolen("ALLOW_SERVICE_REQUEST") && elementPolicy.isServiceRequest === true) {
                isServiceRequest = true;
            }
        });

        if (searchPolicyResponse.length > 0) {
            if (isFlight === false) {
                if (document.querySelector('#tabButton_Flight') !== null) {
                    document.querySelector('#tabButton_Flight').classList.add("d-none");
                    document.querySelector('#tabButton_Flight_parent').classList.add("d-none");
                }

                if (document.querySelector('#tabArea_Flight') !== null) {
                    document.querySelector('#tabArea_Flight').classList.add("d-none");
                }
            }

            if (isHotel === false) {
                if (document.querySelector('#tabButton_Hotel') !== null) {
                    document.querySelector('#tabButton_Hotel').classList.add("d-none");
                    document.querySelector('#tabButton_Hotel_parent').classList.add("d-none");
                }

                if (document.querySelector('#tabArea_Hotel') !== null) {
                    document.querySelector('#tabArea_Hotel').classList.add("d-none");
                }
            }

            if (isServiceRequest === false) {
                if (document.querySelector('#tabButton_ServiceRequest') !== null) {
                    document.querySelector('#tabButton_ServiceRequest').classList.add("d-none");
                    document.querySelector('#tabButton_ServiceRequest_parent').classList.add("d-none");
                }

                if (document.querySelector('#tabArea_ServiceRequest') !== null) {
                    document.querySelector('#tabArea_ServiceRequest').classList.add("d-none");
                }
            }
        }
        else {
            if (getConfigurationByBoolen("ALLOW_FLIGHT") === false && isFlight === false) {
                if (document.querySelector('#tabButton_Flight') !== null) {
                    document.querySelector('#tabButton_Flight').classList.add("d-none");
                    document.querySelector('#tabButton_Flight_parent').classList.add("d-none");
                }

                if (document.querySelector('#tabArea_Flight') !== null) {
                    document.querySelector('#tabArea_Flight').classList.add("d-none");
                }
            }

            if (getConfigurationByBoolen("ALLOW_HOTEL") === false && isHotel === false) {
                if (document.querySelector('#tabButton_Hotel') !== null) {
                    document.querySelector('#tabButton_Hotel').classList.add("d-none");
                    document.querySelector('#tabButton_Hotel_parent').classList.add("d-none");
                }

                if (document.querySelector('#tabArea_Hotel') !== null) {
                    document.querySelector('#tabArea_Hotel').classList.add("d-none");
                }
            }

            if (getConfigurationByBoolen("ALLOW_SERVICE_REQUEST") === false && isServiceRequest === false) {
                if (document.querySelector('#tabButton_ServiceRequest') !== null) {
                    document.querySelector('#tabButton_ServiceRequest').classList.add("d-none");
                    document.querySelector('#tabButton_ServiceRequest_parent').classList.add("d-none");
                }

                if (document.querySelector('#tabArea_ServiceRequest') !== null) {
                    document.querySelector('#tabArea_ServiceRequest').classList.add("d-none");
                }
            }
        }

        if (tripReasonList.length > 0) {
            if (tripReasonList[0].meetingID === "1") {
                if (document.querySelector('#tabButton_MeetingDetails') !== null) {
                    document.querySelector('#tabButton_MeetingDetails').classList.add("d-none");
                }

                if (document.querySelector('#tabArea_MeetingDetails') !== null) {
                    document.querySelector('#tabArea_MeetingDetails').classList.add("d-none");
                }
            }

            if (tripReasonList.length > 0 && (tripReasonList[0].meetingID === "1" || tripReasonList[0].meetingID === "3")) {
                let inputs = document.querySelectorAll('.meeting');
                for (let i = 0; i < inputs.length; i++) {
                    if (document.querySelector('#' + inputs[i].getAttribute("id")) !== null) {
                        document.querySelector('#' + inputs[i].getAttribute("id")).innerHTML = "";
                    }
                }
            }
            else {
                let inputs = document.querySelectorAll('.meeting');
                for (let i = 0; i < inputs.length; i++) {
                    if (document.querySelector('#' + inputs[i].getAttribute("id")) !== null) {
                        document.querySelector('#' + inputs[i].getAttribute("id")).innerHTML = " *";
                    }
                }
            }
        }

        if (valueParam.return !== undefined && valueParam.return !== '' && valueParam.return === '1') {
            let serviceTemp = valueParam.service === 'flight' ? 'Flight' : valueParam.service === 'hotel' ? 'Hotel' : 'Flight';
            onTabChangeHandler(serviceTemp);

            if (document.querySelector('#tabButton_Flight') !== null) {
                document.querySelector('#tabButton_Flight').classList.remove("active");
            }
            if (document.querySelector('#tabArea_Flight') !== null) {
                document.querySelector('#tabArea_Flight').classList.remove("active");
            }
            if (document.querySelector('#tabButton_Hotel') !== null) {
                document.querySelector('#tabButton_Hotel').classList.remove("active");
            }
            if (document.querySelector('#tabArea_Hotel') !== null) {
                document.querySelector('#tabArea_Hotel').classList.remove("active");
            }
            if (document.querySelector('#tabButton_ServiceRequest') !== null) {
                document.querySelector('#tabButton_ServiceRequest').classList.remove("active");
            }
            if (document.querySelector('#tabArea_ServiceRequest') !== null) {
                document.querySelector('#tabArea_ServiceRequest').classList.remove("active");
            }
            if (document.querySelector('#tabButton_Document') !== null) {
                document.querySelector('#tabButton_Document').classList.remove("active");
            }
            if (document.querySelector('#tabArea_Document') !== null) {
                document.querySelector('#tabArea_Document').classList.remove("active");
            }
            if (document.querySelector('#tabButton_MeetingDetails') !== null) {
                document.querySelector('#tabButton_MeetingDetails').classList.remove("active");
            }
            if (document.querySelector('#tabArea_MeetingDetails') !== null) {
                document.querySelector('#tabArea_MeetingDetails').classList.remove("active");
            }
            if (document.querySelector('#tabButton_' + serviceTemp) !== null) {
                document.querySelector('#tabButton_' + serviceTemp).classList.add("active");
            }
            if (document.querySelector('#tabArea_' + serviceTemp) !== null) {
                document.querySelector('#tabArea_' + serviceTemp).classList.add("active");
            }
            if (document.querySelector('#tabArea_' + serviceTemp) !== null) {
                if (!document.querySelector('#tabArea_' + serviceTemp).classList.toString().includes('show')) {
                    document.querySelector('#tabArea_' + serviceTemp).classList.add("show");
                }
            }
        }
        else {
            if (searchPolicyResponse.length > 0) {
                if (getConfigurationByBoolen("ALLOW_FLIGHT") === true && isFlight === true) {
                    onTabChangeHandler('Flight');

                    if (document.querySelector('#tabButton_Flight') !== null) {
                        document.querySelector('#tabButton_Flight').classList.add("active");
                    }
                    if (document.querySelector('#tabArea_Flight') !== null) {
                        document.querySelector('#tabArea_Flight').classList.add("active");
                    }
                    if (document.querySelector('#tabArea_Flight') !== null) {
                        if (!document.querySelector('#tabArea_Flight').classList.toString().includes('show')) {
                            document.querySelector('#tabArea_Flight').classList.add("show");
                        }
                    }
                    if (document.querySelector('#tabButton_Hotel') !== null) {
                        document.querySelector('#tabButton_Hotel').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_Hotel') !== null) {
                        document.querySelector('#tabArea_Hotel').classList.remove("active");
                    }
                    if (document.querySelector('#tabButton_ServiceRequest') !== null) {
                        document.querySelector('#tabButton_ServiceRequest').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_ServiceRequest') !== null) {
                        document.querySelector('#tabArea_ServiceRequest').classList.remove("active");
                    }
                    if (document.querySelector('#tabButton_Approver') !== null) {
                        document.querySelector('#tabButton_Approver').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_Approver') !== null) {
                        document.querySelector('#tabArea_Approver').classList.remove("active");
                    }
                    if (document.querySelector('#tabButton_Document') !== null) {
                        document.querySelector('#tabButton_Document').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_Document') !== null) {
                        document.querySelector('#tabArea_Document').classList.remove("active");
                    }
                    if (document.querySelector('#tabButton_MeetingDetails') !== null) {
                        document.querySelector('#tabButton_MeetingDetails').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_MeetingDetails') !== null) {
                        document.querySelector('#tabArea_MeetingDetails').classList.remove("active");
                    }
                }
                else if (getConfigurationByBoolen("ALLOW_HOTEL") && isHotel === true) {
                    onTabChangeHandler('Hotel');

                    if (document.querySelector('#tabButton_Flight') !== null) {
                        document.querySelector('#tabButton_Flight').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_Flight') !== null) {
                        document.querySelector('#tabArea_Flight').classList.remove("active");
                    }
                    if (document.querySelector('#tabButton_Hotel') !== null) {
                        document.querySelector('#tabButton_Hotel').classList.add("active");
                    }
                    if (document.querySelector('#tabArea_Hotel') !== null) {
                        document.querySelector('#tabArea_Hotel').classList.add("active");
                    }
                    if (document.querySelector('#tabArea_Hotel') !== null) {
                        if (!document.querySelector('#tabArea_Hotel').classList.toString().includes('show')) {
                            document.querySelector('#tabArea_Hotel').classList.add("show");
                        }
                    }
                    if (document.querySelector('#tabButton_ServiceRequest') !== null) {
                        document.querySelector('#tabButton_ServiceRequest').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_ServiceRequest') !== null) {
                        document.querySelector('#tabArea_ServiceRequest').classList.remove("active");
                    }
                    if (document.querySelector('#tabButton_Approver') !== null) {
                        document.querySelector('#tabButton_Approver').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_Approver') !== null) {
                        document.querySelector('#tabArea_Approver').classList.remove("active");
                    }
                    if (document.querySelector('#tabButton_Document') !== null) {
                        document.querySelector('#tabButton_Document').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_Document') !== null) {
                        document.querySelector('#tabArea_Document').classList.remove("active");
                    }
                    if (document.querySelector('#tabButton_MeetingDetails') !== null) {
                        document.querySelector('#tabButton_MeetingDetails').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_MeetingDetails') !== null) {
                        document.querySelector('#tabArea_MeetingDetails').classList.remove("active");
                    }
                }
                else if (getConfigurationByBoolen("ALLOW_SERVICE_REQUEST") && isServiceRequest === true) {
                    onTabChangeHandler('ServiceRequest');

                    if (document.querySelector('#tabButton_Flight') !== null) {
                        document.querySelector('#tabButton_Flight').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_Flight') !== null) {
                        document.querySelector('#tabArea_Flight').classList.remove("active");
                    }
                    if (document.querySelector('#tabButton_Hotel') !== null) {
                        document.querySelector('#tabButton_Hotel').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_Hotel') !== null) {
                        document.querySelector('#tabArea_Hotel').classList.remove("active");
                    }
                    if (document.querySelector('#tabButton_ServiceRequest') !== null) {
                        document.querySelector('#tabButton_ServiceRequest').classList.add("active");
                    }
                    if (document.querySelector('#tabArea_ServiceRequest') !== null) {
                        document.querySelector('#tabArea_ServiceRequest').classList.add("active");
                    }
                    if (document.querySelector('#tabArea_ServiceRequest') !== null) {
                        if (!document.querySelector('#tabArea_ServiceRequest').classList.toString().includes('show')) {
                            document.querySelector('#tabArea_ServiceRequest').classList.add("show");
                        }
                    }
                    if (document.querySelector('#tabButton_Approver') !== null) {
                        document.querySelector('#tabButton_Approver').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_Approver') !== null) {
                        document.querySelector('#tabArea_Approver').classList.remove("active");
                    }
                    if (document.querySelector('#tabButton_Document') !== null) {
                        document.querySelector('#tabButton_Document').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_Document') !== null) {
                        document.querySelector('#tabArea_Document').classList.remove("active");
                    }
                    if (document.querySelector('#tabButton_MeetingDetails') !== null) {
                        document.querySelector('#tabButton_MeetingDetails').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_MeetingDetails') !== null) {
                        document.querySelector('#tabArea_MeetingDetails').classList.remove("active");
                    }
                }
                else {
                    if (selectedTab !== undefined && selectedTab !== '') {
                        if (selectedTab === 'Flight') {
                            onTabChangeHandler('Approver');

                            if (document.querySelector('#tabButton_Flight') !== null) {
                                document.querySelector('#tabButton_Flight').classList.remove("active");
                            }
                            if (document.querySelector('#tabArea_Flight') !== null) {
                                document.querySelector('#tabArea_Flight').classList.remove("active");
                            }
                            if (document.querySelector('#tabButton_Hotel') !== null) {
                                document.querySelector('#tabButton_Hotel').classList.remove("active");
                            }
                            if (document.querySelector('#tabArea_Hotel') !== null) {
                                document.querySelector('#tabArea_Hotel').classList.remove("active");
                            }
                            if (document.querySelector('#tabButton_ServiceRequest') !== null) {
                                document.querySelector('#tabButton_ServiceRequest').classList.remove("active");
                            }
                            if (document.querySelector('#tabArea_ServiceRequest') !== null) {
                                document.querySelector('#tabArea_ServiceRequest').classList.remove("active");
                            }
                            if (document.querySelector('#tabButton_Approver') !== null) {
                                document.querySelector('#tabButton_Approver').classList.add("active");
                            }
                            if (document.querySelector('#tabArea_Approver') !== null) {
                                document.querySelector('#tabArea_Approver').classList.add("active");
                            }
                            if (document.querySelector('#tabArea_Approver') !== null) {
                                if (!document.querySelector('#tabArea_Approver').classList.toString().includes('show')) {
                                    document.querySelector('#tabArea_Approver').classList.add("show");
                                }
                            }
                            if (document.querySelector('#tabButton_Document') !== null) {
                                document.querySelector('#tabButton_Document').classList.remove("active");
                            }
                            if (document.querySelector('#tabArea_Document') !== null) {
                                document.querySelector('#tabArea_Document').classList.remove("active");
                            }
                            if (document.querySelector('#tabButton_MeetingDetails') !== null) {
                                document.querySelector('#tabButton_MeetingDetails').classList.remove("active");
                            }
                            if (document.querySelector('#tabArea_MeetingDetails') !== null) {
                                document.querySelector('#tabArea_MeetingDetails').classList.remove("active");
                            }
                        }
                        else {
                            onTabChangeHandler(selectedTab);

                            if (document.querySelector('#tabButton_Flight') !== null) {
                                document.querySelector('#tabButton_Flight').classList.remove("active");
                            }
                            if (document.querySelector('#tabArea_Flight') !== null) {
                                document.querySelector('#tabArea_Flight').classList.remove("active");
                            }
                            if (document.querySelector('#tabButton_Hotel') !== null) {
                                document.querySelector('#tabButton_Hotel').classList.remove("active");
                            }
                            if (document.querySelector('#tabArea_Hotel') !== null) {
                                document.querySelector('#tabArea_Hotel').classList.remove("active");
                            }
                            if (document.querySelector('#tabButton_ServiceRequest') !== null) {
                                document.querySelector('#tabButton_ServiceRequest').classList.remove("active");
                            }
                            if (document.querySelector('#tabArea_ServiceRequest') !== null) {
                                document.querySelector('#tabArea_ServiceRequest').classList.remove("active");
                            }
                            if (document.querySelector('#tabButton_Document') !== null) {
                                document.querySelector('#tabButton_Document').classList.remove("active");
                            }
                            if (document.querySelector('#tabArea_Document') !== null) {
                                document.querySelector('#tabArea_Document').classList.remove("active");
                            }
                            if (document.querySelector('#tabButton_MeetingDetails') !== null) {
                                document.querySelector('#tabButton_MeetingDetails').classList.remove("active");
                            }
                            if (document.querySelector('#tabArea_MeetingDetails') !== null) {
                                document.querySelector('#tabArea_MeetingDetails').classList.remove("active");
                            }
                            if (document.querySelector('#tabButton_' + selectedTab) !== null) {
                                document.querySelector('#tabButton_' + selectedTab).classList.add("active");
                            }
                            if (document.querySelector('#tabArea_' + selectedTab) !== null) {
                                document.querySelector('#tabArea_' + selectedTab).classList.add("active");
                            }
                            if (document.querySelector('#tabArea_' + selectedTab) !== null) {
                                if (!document.querySelector('#tabArea_' + selectedTab).classList.toString().includes('show')) {
                                    document.querySelector('#tabArea_' + selectedTab).classList.add("show");
                                }
                            }
                        }
                    }
                    else {
                        onTabChangeHandler('Approver');

                        if (document.querySelector('#tabButton_Flight') !== null) {
                            document.querySelector('#tabButton_Flight').classList.remove("active");
                        }
                        if (document.querySelector('#tabArea_Flight') !== null) {
                            document.querySelector('#tabArea_Flight').classList.remove("active");
                        }
                        if (document.querySelector('#tabButton_Hotel') !== null) {
                            document.querySelector('#tabButton_Hotel').classList.remove("active");
                        }
                        if (document.querySelector('#tabArea_Hotel') !== null) {
                            document.querySelector('#tabArea_Hotel').classList.remove("active");
                        }
                        if (document.querySelector('#tabButton_ServiceRequest') !== null) {
                            document.querySelector('#tabButton_ServiceRequest').classList.remove("active");
                        }
                        if (document.querySelector('#tabArea_ServiceRequest') !== null) {
                            document.querySelector('#tabArea_ServiceRequest').classList.remove("active");
                        }
                        if (document.querySelector('#tabButton_Approver') !== null) {
                            document.querySelector('#tabButton_Approver').classList.add("active");
                        }
                        if (document.querySelector('#tabArea_Approver') !== null) {
                            document.querySelector('#tabArea_Approver').classList.add("active");
                        }
                        if (document.querySelector('#tabArea_Approver') !== null) {
                            if (!document.querySelector('#tabArea_Approver').classList.toString().includes('show')) {
                                document.querySelector('#tabArea_Approver').classList.add("show");
                            }
                        }
                        if (document.querySelector('#tabButton_Document') !== null) {
                            document.querySelector('#tabButton_Document').classList.remove("active");
                        }
                        if (document.querySelector('#tabArea_Document') !== null) {
                            document.querySelector('#tabArea_Document').classList.remove("active");
                        }
                        if (document.querySelector('#tabButton_MeetingDetails') !== null) {
                            document.querySelector('#tabButton_MeetingDetails').classList.remove("active");
                        }
                        if (document.querySelector('#tabArea_MeetingDetails') !== null) {
                            document.querySelector('#tabArea_MeetingDetails').classList.remove("active");
                        }
                    }
                }
            }
            else {
                if (getConfigurationByBoolen("ALLOW_FLIGHT") === false && isFlight === false) {
                    if (document.querySelector('#tabButton_Flight') !== null) {
                        document.querySelector('#tabButton_Flight').classList.add("d-none");
                        document.querySelector('#tabButton_Flight_parent').classList.add("d-none");
                    }

                    if (document.querySelector('#tabArea_Flight') !== null) {
                        document.querySelector('#tabArea_Flight').classList.add("d-none");
                    }
                }

                if (getConfigurationByBoolen("ALLOW_HOTEL") === false && isHotel === false) {
                    if (document.querySelector('#tabButton_Hotel') !== null) {
                        document.querySelector('#tabButton_Hotel').classList.add("d-none");
                        document.querySelector('#tabButton_Hotel_parent').classList.add("d-none");
                    }

                    if (document.querySelector('#tabArea_Hotel') !== null) {
                        document.querySelector('#tabArea_Hotel').classList.add("d-none");
                    }
                }

                if (getConfigurationByBoolen("ALLOW_SERVICE_REQUEST") === false && isServiceRequest === false) {
                    if (document.querySelector('#tabButton_ServiceRequest') !== null) {
                        document.querySelector('#tabButton_ServiceRequest').classList.add("d-none");
                        document.querySelector('#tabButton_ServiceRequest_parent').classList.add("d-none");
                    }

                    if (document.querySelector('#tabArea_ServiceRequest') !== null) {
                        document.querySelector('#tabArea_ServiceRequest').classList.add("d-none");
                    }
                }
            }
        }

        if (valueParam.return !== undefined && valueParam.return !== '' && valueParam.return === '1') {
            history.push({
                pathname: location.pathname,
                search: '?return=1&service=' + valueParam.service
            })
        }
        else {
            history.push({
                pathname: location.pathname
            })
        }
    };

    function onSubmit() {
        let validInputData = true;
        let travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
        let tripReasonAndPolicyDataJson = JSON.parse(localStorage.getItem("loginData"));

        tripReasonList = tripReasonAndPolicyDataJson.tripReason.filter(a => a.id === tripReasonValue);

        if (tripReasonValue === '') {
            setTripReasonInputHasError(true);
            window.scrollTo({
                top: 50,
                behavior: "smooth",
            });
            validInputData = false;
            return;
        }
        else {
            setTripReasonInputHasError(false);
        }

        if (selectedTab === 'Document') {
            if (validInputData && (travelRequestFieldsDataJson.travelRequestDocuments.length === undefined || travelRequestFieldsDataJson.travelRequestDocuments.length === 0)) {
                if (document.querySelector('#tabButton_Document') !== null) {
                    //document.querySelector('#tabButton_Document').click();
                    document.querySelector('#documentErrorMsg').innerHTML = "Document is required.";
                    // window.scrollTo({
                    //     top: 50,
                    //     behavior: "smooth",
                    // });
                }
                validInputData = false;
                return;
            }
            else {
                document.querySelector('#documentErrorMsg').innerHTML = "";
            }

            if (travelRequestFieldsDataJson.travelRequestDocuments.length !== undefined && travelRequestFieldsDataJson.travelRequestDocuments.length !== 0) {
                travelRequestFieldsDataJson.travelRequestDocuments.forEach(element => {
                    if (validInputData && (element.remarks.length === undefined || element.remarks.length === 0)) {
                        if (document.querySelector('#tabButton_Document') !== null) {
                            //document.querySelector('#tabButton_Document').click();
                            document.querySelector('#docRemarksErrorMsg').innerHTML = "Remarks is required.";
                            // window.scrollTo({
                            //     top: 50,
                            //     behavior: "smooth",
                            // });
                        }
                        validInputData = false;
                        return;
                    }
                    else {
                        document.querySelector('#docRemarksErrorMsg').innerHTML = "";
                    }
                });
            }
        }

        const meetingDetailsTab = document.querySelector('#tabButton_MeetingDetails');
        if (tripReasonList.length > 0 && tripReasonList[0].meetingID !== "1" && tripReasonList[0].meetingID !== "3") {
            if (validInputData && (travelRequestFieldsDataJson.meetingCountry.length === undefined || travelRequestFieldsDataJson.meetingCountry.length === 0)) {
                if (meetingDetailsTab !== null) {
                    meetingDetailsTab.click();
                    document.querySelector('#countryErrorMsg').innerHTML = "Country is required.";
                    window.scrollTo({
                        top: 100,
                        behavior: "smooth",
                    });
                }
                validInputData = false;
                return;
            }
            else {
                document.querySelector('#countryErrorMsg').innerHTML = "";
            }

            if (validInputData && (travelRequestFieldsDataJson.meetingCity.length === undefined || travelRequestFieldsDataJson.meetingCity.length === 0)) {
                if (meetingDetailsTab !== null) {
                    meetingDetailsTab.click();
                    document.querySelector('#cityErrorMsg').innerHTML = "City is required.";
                    window.scrollTo({
                        top: 100,
                        behavior: "smooth",
                    });
                }
                validInputData = false;
                return;
            }
            else {
                document.querySelector('#cityErrorMsg').innerHTML = "";
            }

            if (validInputData && (travelRequestFieldsDataJson.meetingStartDate.length === undefined || travelRequestFieldsDataJson.meetingStartDate.length === 0)) {
                if (meetingDetailsTab !== null) {
                    meetingDetailsTab.click();
                    document.querySelector('#startDateErrorMsg').innerHTML = "Start Date is required.";
                    window.scrollTo({
                        top: 100,
                        behavior: "smooth",
                    });
                }
                validInputData = false;
                return;
            }
            else {
                document.querySelector('#startDateErrorMsg').innerHTML = "";
            }

            if (validInputData && (travelRequestFieldsDataJson.meetingEndDate.length === undefined || travelRequestFieldsDataJson.meetingEndDate.length === 0)) {
                if (meetingDetailsTab !== null) {
                    meetingDetailsTab.click();
                    document.querySelector('#endDateErrorMsg').innerHTML = "End Date is required.";
                    window.scrollTo({
                        top: 100,
                        behavior: "smooth",
                    });
                }
                validInputData = false;
                return;
            }
            else {
                document.querySelector('#endDateErrorMsg').innerHTML = "";
            }

            if (validInputData && (travelRequestFieldsDataJson.meetingStartTime.length === undefined || travelRequestFieldsDataJson.meetingStartTime.length === 0)) {
                if (meetingDetailsTab !== null) {
                    meetingDetailsTab.click();
                    document.querySelector('#startTimeErrorMsg').innerHTML = "Start Time is required.";
                    window.scrollTo({
                        top: 100,
                        behavior: "smooth",
                    });
                }
                validInputData = false;
                return;
            }
            else {
                document.querySelector('#startTimeErrorMsg').innerHTML = "";
            }

            if (validInputData && (travelRequestFieldsDataJson.meetingEndTime.length === undefined || travelRequestFieldsDataJson.meetingEndTime.length === 0)) {
                if (meetingDetailsTab !== null) {
                    meetingDetailsTab.click();
                    document.querySelector('#endTimeErrorMsg').innerHTML = "End Time is required.";
                    window.scrollTo({
                        top: 100,
                        behavior: "smooth",
                    });
                }
                validInputData = false;
                return;
            }
            else {
                document.querySelector('#endTimeErrorMsg').innerHTML = "";
            }

            if (validInputData && (travelRequestFieldsDataJson.meetingPurposeOfTravel.length === undefined || travelRequestFieldsDataJson.meetingPurposeOfTravel.length === 0)) {
                if (meetingDetailsTab !== null) {
                    meetingDetailsTab.click();
                    document.querySelector('#purposeOfTravelErrorMsg').innerHTML = "Trip Purpose is required.";
                    window.scrollTo({
                        top: 100,
                        behavior: "smooth",
                    });
                }
                validInputData = false;
                return;
            }
            else {
                document.querySelector('#purposeOfTravelErrorMsg').innerHTML = "";
            }

            if (validInputData && travelRequestFieldsDataJson.tripPurposeRemarksHasInvalidData) {
                if (meetingDetailsTab !== null) {
                    meetingDetailsTab.click();
                    window.scrollTo({
                        top: 100,
                        behavior: "smooth",
                    });
                }
                validInputData = false;
                return;
            }
        }
        else {
            if (validInputData && (travelRequestFieldsDataJson.meetingCountry.length === undefined || travelRequestFieldsDataJson.meetingCountry.length === 0)) {
                if (meetingDetailsTab !== null) {
                    meetingDetailsTab.click();
                    document.querySelector('#countryErrorMsg').innerHTML = "Country is required.";
                    window.scrollTo({
                        top: 100,
                        behavior: "smooth",
                    });
                }
                validInputData = false;
                return;
            }
            else {
                document.querySelector('#countryErrorMsg').innerHTML = "";
            }

            if (validInputData && (travelRequestFieldsDataJson.meetingCity.length === undefined || travelRequestFieldsDataJson.meetingCity.length === 0)) {
                if (meetingDetailsTab !== null) {
                    meetingDetailsTab.click();
                    document.querySelector('#cityErrorMsg').innerHTML = "City is required.";
                    window.scrollTo({
                        top: 100,
                        behavior: "smooth",
                    });
                }
                validInputData = false;
                return;
            }
            else {
                document.querySelector('#cityErrorMsg').innerHTML = "";
            }

            if (validInputData && (travelRequestFieldsDataJson.meetingStartDate.length === undefined || travelRequestFieldsDataJson.meetingStartDate.length === 0)) {
                if (meetingDetailsTab !== null) {
                    meetingDetailsTab.click();
                    document.querySelector('#startDateErrorMsg').innerHTML = "Start Date is required.";
                    window.scrollTo({
                        top: 100,
                        behavior: "smooth",
                    });
                }
                validInputData = false;
                return;
            }
            else {
                document.querySelector('#startDateErrorMsg').innerHTML = "";
            }

            if (validInputData && (travelRequestFieldsDataJson.meetingEndDate.length === undefined || travelRequestFieldsDataJson.meetingEndDate.length === 0)) {
                if (meetingDetailsTab !== null) {
                    meetingDetailsTab.click();
                    document.querySelector('#endDateErrorMsg').innerHTML = "End Date is required.";
                    window.scrollTo({
                        top: 100,
                        behavior: "smooth",
                    });
                }
                validInputData = false;
                return;
            }
            else {
                document.querySelector('#endDateErrorMsg').innerHTML = "";
            }

            if (validInputData && (travelRequestFieldsDataJson.meetingStartTime.length === undefined || travelRequestFieldsDataJson.meetingStartTime.length === 0)) {
                if (meetingDetailsTab !== null) {
                    meetingDetailsTab.click();
                    document.querySelector('#startTimeErrorMsg').innerHTML = "Start Time is required.";
                    window.scrollTo({
                        top: 100,
                        behavior: "smooth",
                    });
                }
                validInputData = false;
                return;
            }
            else {
                document.querySelector('#startTimeErrorMsg').innerHTML = "";
            }

            if (validInputData && (travelRequestFieldsDataJson.meetingEndTime.length === undefined || travelRequestFieldsDataJson.meetingEndTime.length === 0)) {
                if (meetingDetailsTab !== null) {
                    meetingDetailsTab.click();
                    document.querySelector('#endTimeErrorMsg').innerHTML = "End Time is required.";
                    window.scrollTo({
                        top: 100,
                        behavior: "smooth",
                    });
                }
                validInputData = false;
                return;
            }
            else {
                document.querySelector('#endTimeErrorMsg').innerHTML = "";
            }

            if (validInputData && (travelRequestFieldsDataJson.meetingPurposeOfTravel.length === undefined || travelRequestFieldsDataJson.meetingPurposeOfTravel.length === 0)) {
                if (meetingDetailsTab !== null) {
                    meetingDetailsTab.click();
                    document.querySelector('#purposeOfTravelErrorMsg').innerHTML = "Trip Purpose is required.";
                    window.scrollTo({
                        top: 100,
                        behavior: "smooth",
                    });
                }
                validInputData = false;
                return;
            }
            else {
                document.querySelector('#purposeOfTravelErrorMsg').innerHTML = "";
            }

            if (validInputData && travelRequestFieldsDataJson.tripPurposeRemarksHasInvalidData) {
                if (meetingDetailsTab !== null) {
                    meetingDetailsTab.click();
                    window.scrollTo({
                        top: 100,
                        behavior: "smooth",
                    });
                }
                validInputData = false;
                return;
            }
        }

        if (validInputData === true) {
            let onlineFlightDetailsTemp = [];
            let onlineFlightLowestDetailsTemp = [];
            let onlineFlightFareDetailsTemp = [];
            let onlineFlightLowestFareDetailsTemp = [];

            let justificationCodeFlight = "";
            let justificationDetailsFlight = "";

            let travelRequestDocuments = [];

            let meetingCountry = "";
            let meetingCity = "";
            let meetingStartDate = "";
            let meetingEndDate = "";
            let meetingStartTime = "";
            let meetingEndTime = "";
            let meetingPurposeOfTravel = "";
            let meetingPurposeOfTravelText = "";
            let tripPurposeRemarks = "";

            let totalCommoission = 0.0;

            let serviceRequests = [];

            if (sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
                let travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
                justificationCodeFlight = travelRequestFieldsDataJson.justificationCodeFlight;
                justificationDetailsFlight = travelRequestFieldsDataJson.justificationDetailsFlight;

                serviceRequests = travelRequestFieldsDataJson.serviceRequests;
                travelRequestDocuments = travelRequestFieldsDataJson.travelRequestDocuments;

                meetingCountry = travelRequestFieldsDataJson.meetingCountry;
                meetingCity = travelRequestFieldsDataJson.meetingCity;
                meetingStartDate = travelRequestFieldsDataJson.meetingStartDate;
                meetingEndDate = travelRequestFieldsDataJson.meetingEndDate;
                meetingStartTime = travelRequestFieldsDataJson.meetingStartTime;
                meetingEndTime = travelRequestFieldsDataJson.meetingEndTime;
                meetingPurposeOfTravel = travelRequestFieldsDataJson.meetingPurposeOfTravel;
                meetingPurposeOfTravelText = travelRequestFieldsDataJson.meetingPurposeOfTravelText;
                tripPurposeRemarks = travelRequestFieldsDataJson.tripPurposeRemarks;
            }

            if (sessionStorage.getItem('initialFlightResults') !== null && sessionStorage.getItem('initialFlightResults') !== '' && localStorage.getItem("BookingDataTravelRequest") !== null && localStorage.getItem("BookingDataTravelRequest") !== '') {
                let pricedItinerary1 = JSON.parse(decompress(sessionStorage.getItem('initialFlightResults')));
                let pricedItineraryTempDesc = sortFlightResult("1", pricedItinerary1, 'desc');

                let pricedItinerary2 = JSON.parse(decompress(sessionStorage.getItem('initialFlightResults')));
                let pricedItineraryTempInPolicy = pricedItinerary2.filter(a => a.inPolicy === true);
                let pricedItineraryTempInPolicyDesc = sortFlightResult("1", pricedItineraryTempInPolicy, 'desc');

                if (bookingJSON !== undefined && bookingJSON !== null) {
                    bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity.forEach(element => {
                        if (element.commisionInfo[0] !== undefined && element.commisionInfo[0].earnedCommissionAmount !== undefined)
                            totalCommoission = totalCommoission + parseFloat(element.commisionInfo[0].earnedCommissionAmount);
                    })
                }

                if (bookingJSON !== undefined && bookingJSON !== null) {
                    bookingJSON.data.airItinerary[0].originDestinationOptions.originDestinationOption.forEach(element => {
                        onlineFlightDetailsTemp.push(
                            {
                                "sequenceNumber": 1, //bookingJSON.data.airItinerary[0].SequenceNumber,
                                "segmentID": parseInt(element.flightSegment[0].flightID),
                                "segmentGroupID": parseInt(element.group),
                                "departureLocationCode": element.flightSegment[0].departureAirport.locationCode,
                                "departureLocationName": element.flightSegment[0].departureAirport.locationName,
                                "arrivalLocationCode": element.flightSegment[0].arrivalAirport.locationCode,
                                "arrivalLocationName": element.flightSegment[0].arrivalAirport.locationName,
                                "departureCityCode": element.flightSegment[0].departureAirport.cityCode,
                                "departureCityName": element.flightSegment[0].departureAirport.cityName,
                                "arrivalCityCode": element.flightSegment[0].arrivalAirport.cityCode,
                                "arrivalCityName": element.flightSegment[0].arrivalAirport.cityName,
                                "departureCountryCode": element.flightSegment[0].departureAirport.countryCode,
                                "departureCountryName": element.flightSegment[0].departureAirport.countryName,
                                "arrivalCountryCode": element.flightSegment[0].arrivalAirport.countryCode,
                                "arrivalCountryName": element.flightSegment[0].arrivalAirport.countryName,
                                "departureDate": element.flightSegment[0].departureDate,
                                "arrivalDate": element.flightSegment[0].arrivalDate,
                                "departureTime": element.flightSegment[0].departureTime,
                                "arrivalTime": element.flightSegment[0].arrivalTime,
                                "flightNumber": element.flightSegment[0].flightNumber,
                                "marketingAirlineCode": element.flightSegment[0].marketingAirline.code,
                                "operatingAirlineCode": element.flightSegment[0].operatingAirline.code,
                                "directionIndicator": element.directionInd,
                                "sectorInd": bookingJSON.data.airItinerary[0].SectorInd,
                                "bookingType": sessionStorage.getItem("tripType") === "oneway" ? "O" : sessionStorage.getItem("tripType") === "roundtrip" ? "R" : "M",
                                "fareType": bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo[0] !== undefined && bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo[0].fareType !== undefined ? bookingJSON.data.airItinerary[0].airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo[0].fareType : '',
                                "international": bookingJSON.data.airItinerary[0].SectorInd === "I" ? true : false,
                                "cabin": element.flightSegment[0].bookingClassAvails[0].cabinName,
                                "departureTerminal": element.flightSegment[0].departureAirport.terminal,
                                "arrivalTerminal": element.flightSegment[0].arrivalAirport.terminal,
                                "checkInBaggage": element.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance !== undefined && element.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance.filter(a => a.type === 'Check-in Allowance')[0] !== undefined ? element.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance.filter(a => a.type === 'Check-in Allowance')[0].maxWeight !== undefined ? element.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance.filter(a => a.type === 'Check-in Allowance')[0].maxWeight : element.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance.filter(a => a.type === 'Check-in Allowance')[0].numberOfPieces : '',
                                "cabinBaggage": element.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance !== undefined && element.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance.filter(a => a.type === 'for Carry-On Allowances')[0] !== undefined ? element.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance.filter(a => a.type === 'for Carry-On Allowances')[0].maxWeight !== undefined ? element.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance.filter(a => a.type === 'for Carry-On Allowances')[0].maxWeight : element.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance.filter(a => a.type === 'for Carry-On Allowances')[0].numberOfPieces : '',
                                "airCraftName": element.flightSegment[0].equipment !== undefined && element.flightSegment[0].equipment[0].name !== undefined ? element.flightSegment[0].equipment[0].name : '',
                                "airMiles": element.flightSegment[0].flownMileageQty,
                                "stops": getStops(bookingJSON.data.airItinerary[0].originDestinationOptions.originDestinationOption).split(" ")[0],
                                "duration": element.flightSegment[0].duration !== undefined ? element.flightSegment[0].duration : '',
                                "layoverTime": element.flightSegment[0].elapsedLayoverTime !== undefined ? element.flightSegment[0].elapsedLayoverTime : '',
                                "dayChange": element.flightSegment[0].dateChangeNbr !== undefined ? element.flightSegment[0].dateChangeNbr === "1" ? true : false : false,
                                "bookingSourceCode": bookingJSON.data.airItinerary[0].SourceId,
                                "bookingSourceName": bookingJSON.data.airItinerary[0].SourceName
                            }
                        );
                    });
                }

                onlineFlightFareDetailsTemp.push(
                    {
                        "sequenceNumber": 1, //bookingJSON.data.airItinerary[0].SequenceNumber,
                        "currencyCode": bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.currencyCode,
                        "commission": totalCommoission,
                        "baseFare": bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalBaseFare.amount,
                        "taxes": bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalTax.amount,
                        "totalFare": bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.amount,
                        "highestFare": pricedItineraryTempDesc[0].airItineraryPricingInfo.totalFare.amount,
                        "highestFareInPolicy": pricedItineraryTempInPolicyDesc.length > 0 ? pricedItineraryTempInPolicyDesc[0].airItineraryPricingInfo.totalFare.amount : "0",
                        "inPolicy": bookingJSON.data.inPolicy,
                        "inPolicyReason": bookingJSON.data.inPolicyReason,
                        totalOutBoundDuration: bookingJSON.data.airItinerary[0].totalOutBoundDuration,
                        totalInBoundDuration: bookingJSON.data.airItinerary[0].totalInBoundDuration,
                        totalItineraryDuration: bookingJSON.data.airItinerary[0].totalItineraryDuration,
                        fareRuleType: ""
                    }
                );

                let pricedItinerary3 = JSON.parse(decompress(sessionStorage.getItem('initialFlightResults')));
                let pricedItineraryTempAsc = sortFlightResult("1", pricedItinerary3, 'asc');

                for (let index = 0; index < 3; index++) {
                    pricedItineraryTempAsc[index] !== undefined && pricedItineraryTempAsc[index].airItinerary !== undefined && pricedItineraryTempAsc[index].airItinerary.originDestinationOptions.originDestinationOption.forEach(elementOriginDestinationOption => {
                        onlineFlightLowestDetailsTemp.push(
                            {
                                "lowestID": index + 1,
                                "sequenceNumber": 1, //pricedItineraryTempAsc[index].sequenceNumber,
                                "segmentID": parseInt(elementOriginDestinationOption.flightSegment[0].flightID),
                                "segmentGroupID": parseInt(elementOriginDestinationOption.group),
                                "departureLocationCode": elementOriginDestinationOption.flightSegment[0].departureAirport.locationCode,
                                "departureLocationName": elementOriginDestinationOption.flightSegment[0].departureAirport.locationName,
                                "arrivalLocationCode": elementOriginDestinationOption.flightSegment[0].arrivalAirport.locationCode,
                                "arrivalLocationName": elementOriginDestinationOption.flightSegment[0].arrivalAirport.locationName,
                                "departureCityCode": elementOriginDestinationOption.flightSegment[0].departureAirport.cityCode,
                                "departureCityName": elementOriginDestinationOption.flightSegment[0].departureAirport.cityName,
                                "arrivalCityCode": elementOriginDestinationOption.flightSegment[0].arrivalAirport.cityCode,
                                "arrivalCityName": elementOriginDestinationOption.flightSegment[0].arrivalAirport.cityName,
                                "departureCountryCode": elementOriginDestinationOption.flightSegment[0].departureAirport.countryCode,
                                "departureCountryName": elementOriginDestinationOption.flightSegment[0].departureAirport.countryName,
                                "arrivalCountryCode": elementOriginDestinationOption.flightSegment[0].arrivalAirport.countryCode,
                                "arrivalCountryName": elementOriginDestinationOption.flightSegment[0].arrivalAirport.countryName,
                                "departureDate": elementOriginDestinationOption.flightSegment[0].departureDate,
                                "arrivalDate": elementOriginDestinationOption.flightSegment[0].arrivalDate,
                                "departureTime": elementOriginDestinationOption.flightSegment[0].departureTime,
                                "arrivalTime": elementOriginDestinationOption.flightSegment[0].arrivalTime,
                                "flightNumber": elementOriginDestinationOption.flightSegment[0].flightNumber,
                                "marketingAirlineCode": elementOriginDestinationOption.flightSegment[0].marketingAirline.code,
                                "operatingAirlineCode": elementOriginDestinationOption.flightSegment[0].operatingAirline.code,
                                "directionIndicator": elementOriginDestinationOption.directionInd,
                                "sectorInd": pricedItineraryTempAsc[index].sectorInd,
                                "bookingType": sessionStorage.getItem("tripType") === "oneway" ? "O" : sessionStorage.getItem("tripType") === "roundtrip" ? "R" : "M",
                                "fareType": pricedItineraryTempAsc[index].airItinerary?.airItineraryPricingInfo?.passengerTypeQuantity[0]?.fareInfos !== undefined && pricedItineraryTempAsc[index].airItinerary.airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo !== undefined && pricedItineraryTempAsc[index].airItinerary.airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo[0] !== undefined && pricedItineraryTempAsc[index].airItinerary.airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo[0].fareType !== undefined ? pricedItineraryTempAsc[index].airItinerary.airItineraryPricingInfo.passengerTypeQuantity[0].fareInfos.fareInfo[0].fareType : '',
                                "international": true,
                                "cabin": elementOriginDestinationOption.flightSegment[0].bookingClassAvails[0].cabinName,
                                "departureTerminal": elementOriginDestinationOption.flightSegment[0].departureAirport.terminal,
                                "arrivalTerminal": elementOriginDestinationOption.flightSegment[0].arrivalAirport.terminal,
                                "checkInBaggage": elementOriginDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance !== undefined && elementOriginDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance.filter(a => a.type === 'Check-in Allowance')[0] !== undefined ? elementOriginDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance.filter(a => a.type === 'Check-in Allowance')[0].maxWeight !== undefined ? elementOriginDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance.filter(a => a.type === 'Check-in Allowance')[0].maxWeight : elementOriginDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance.filter(a => a.type === 'Check-in Allowance')[0].numberOfPieces : '',
                                "cabinBaggage": elementOriginDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance !== undefined && elementOriginDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance.filter(a => a.type === 'for Carry-On Allowances')[0] !== undefined ? elementOriginDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance.filter(a => a.type === 'for Carry-On Allowances')[0].maxWeight !== undefined ? elementOriginDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance.filter(a => a.type === 'for Carry-On Allowances')[0].maxWeight : elementOriginDestinationOption.segmentRefDetail[0].pax.filter(a => a.type === 'ADT')[0].baggageAllowance.filter(a => a.type === 'for Carry-On Allowances')[0].numberOfPieces : '',
                                "airCraftName": elementOriginDestinationOption.flightSegment[0].equipment !== undefined && elementOriginDestinationOption.flightSegment[0].equipment[0].name !== undefined ? elementOriginDestinationOption.flightSegment[0].equipment[0].name : '',
                                "airMiles": elementOriginDestinationOption.flightSegment[0].flownMileageQty,
                                "stops": getStops(pricedItineraryTempAsc[index].airItinerary.originDestinationOptions.originDestinationOption).split(" ")[0],
                                "duration": elementOriginDestinationOption.flightSegment[0].duration !== undefined ? elementOriginDestinationOption.flightSegment[0].duration : '',
                                "layoverTime": elementOriginDestinationOption.flightSegment[0].elapsedLayoverTime !== undefined ? elementOriginDestinationOption.flightSegment[0].elapsedLayoverTime : '',
                                "dayChange": elementOriginDestinationOption.flightSegment[0].dateChangeNbr !== undefined ? elementOriginDestinationOption.flightSegment[0].dateChangeNbr === "1" ? true : false : false,
                                "bookingSourceCode": pricedItineraryTempAsc[index].airItinerary.SourceId,
                                "bookingSourceName": pricedItineraryTempAsc[index].airItinerary.SourceName
                            }
                        );
                    });
                }

                for (let index = 0; index < 3; index++) {

                    let totalCommoission = 0;
                    pricedItineraryTempAsc[index] !== undefined && pricedItineraryTempAsc[index].airItineraryPricingInfo !== undefined && pricedItineraryTempAsc[index].airItineraryPricingInfo.passengerTypeQuantity.forEach(element => {
                        if (element.commisionInfo[0] !== undefined && element.commisionInfo[0].earnedCommissionAmount !== undefined)
                            totalCommoission = totalCommoission + parseFloat(element.commisionInfo[0].earnedCommissionAmount);
                    })

                    pricedItineraryTempAsc[index] !== undefined && pricedItineraryTempAsc[index].airItineraryPricingInfo !== undefined && onlineFlightLowestFareDetailsTemp.push(
                        {
                            "lowestID": index + 1,
                            "sequenceNumber": 1, //pricedItineraryTempAsc[index].sequenceNumber,
                            "currencyCode": pricedItineraryTempAsc[index].airItineraryPricingInfo.totalFare.currencyCode,
                            "commission": totalCommoission,
                            "baseFare": pricedItineraryTempAsc[index].airItineraryPricingInfo.totalBaseFare.amount,
                            "taxes": pricedItineraryTempAsc[index].airItineraryPricingInfo.totalTax.amount,
                            "totalFare": pricedItineraryTempAsc[index].airItineraryPricingInfo.totalFare.amount,
                            "highestFare": pricedItineraryTempAsc[index].airItineraryPricingInfo.totalFare.amount,
                            "highestFareInPolicy": pricedItineraryTempAsc.length > 0 ? pricedItineraryTempAsc[index].airItineraryPricingInfo.totalFare.amount : "0",
                            "inPolicy": pricedItineraryTempAsc[index].inPolicy,
                            "inPolicyReason": pricedItineraryTempAsc[index].inPolicyReason !== undefined && pricedItineraryTempAsc[index].inPolicyReason !== null && pricedItineraryTempAsc[index].inPolicyReason !== '' ? pricedItineraryTempAsc[index].inPolicyReason : '',
                            "reasonID": justificationCodeFlight.length !== undefined ? justificationCodeFlight : '',
                            "remarks": justificationDetailsFlight.length !== undefined ? justificationDetailsFlight : '',
                            totalOutBoundDuration: pricedItineraryTempAsc[index].airItinerary.totalOutBoundDuration,
                            totalInBoundDuration: pricedItineraryTempAsc[index].airItinerary.totalInBoundDuration,
                            totalItineraryDuration: pricedItineraryTempAsc[index].airItinerary.totalItineraryDuration,
                            fareRuleType: ""

                        }
                    );
                }
            }

            let totalCost = 0;
            if (bookingJSON !== undefined && bookingJSON !== null && bookingJSON.data !== undefined && bookingJSON.data !== null) {
                totalCost = parseFloat(bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.amount);
            }

            if (travelRequestJSON.data.onlineHotelDetails !== undefined && travelRequestJSON.data.onlineHotelDetails !== null && travelRequestJSON.data.onlineHotelDetails[0].totalRate !== undefined && travelRequestJSON.data.onlineHotelDetails[0].totalRate !== null) {
                totalCost = parseFloat(totalCost) + parseFloat(travelRequestJSON.data.onlineHotelDetails[0].totalRate !== '' ? travelRequestJSON.data.onlineHotelDetails[0].totalRate : 0);
            }

            travelRequestJSON.data.travellerDetails.totalCost = CustomToFixed(totalCost, 2);
            travelRequestJSON.data.meetingDetails = meetingCountry.length === undefined ? null : {
                "shoppingID": 0,
                "countryCode": meetingCountry,
                "cityCode": meetingCity,
                "meetingStartDate": meetingStartDate,
                "meetingEndDate": meetingEndDate,
                "meetingStartTime": meetingStartTime,
                "meetingEndTime": meetingEndTime,
                "purposeID": meetingPurposeOfTravel,
                "purposeDetail": meetingPurposeOfTravelText,
                "purposeDetails": tripPurposeRemarks,
            }
            travelRequestJSON.data.onlineFlightDetails = onlineFlightDetailsTemp.length !== undefined && onlineFlightDetailsTemp.length !== 0 ? onlineFlightDetailsTemp : null;
            travelRequestJSON.data.onlineFlightFareDetails = onlineFlightFareDetailsTemp.length !== undefined && onlineFlightFareDetailsTemp.length !== 0 ? onlineFlightFareDetailsTemp : null;
            travelRequestJSON.data.onlineFlightLowestDetails = onlineFlightLowestDetailsTemp.length !== undefined && onlineFlightLowestDetailsTemp.length !== 0 ? onlineFlightLowestDetailsTemp : null;
            travelRequestJSON.data.onlineFlightLowestFareDetails = onlineFlightLowestFareDetailsTemp && onlineFlightLowestFareDetailsTemp.length ? onlineFlightLowestFareDetailsTemp : null;
            travelRequestJSON.data.serviceRequest = (serviceRequests && serviceRequests.length) ? serviceRequests : null;

            travelRequestJSON.data.travelRequestDocuments = travelRequestDocuments.length !== undefined ? travelRequestDocuments : null
            if (bookingJSON !== undefined && bookingJSON !== null && bookingJSON.data !== undefined && bookingJSON.data !== null) {
                travelRequestJSON.data.bookingData.push(
                    {
                        "serviceCode": "FL",
                        "bookingJSON": JSON.stringify(bookingJSON.data)
                    }
                );
            }

            //set the sceen location on almost page top when travel request will submit
            //console.log(travelRequestJSON)            
            window.scrollTo({
                top: 50,
                behavior: "smooth",
            });
            sendRequestTravelRequest(travelRequestJSON);
            localStorage.setItem("TravelRequestInput", JSON.stringify(travelRequestJSON));
        }
    }

    useEffect(() => {
        if (statusTravelRequest === 'completed') {
            window.scrollTo({
                top: 50,
                behavior: "smooth",
            });

            if (loadedTravelRequest !== null && loadedTravelRequest.data !== null) {

                let searchPolicyResponse = "";
                let tripReasonAndPolicyDataJson = "";

                if (localStorage.getItem("loginData") !== null) {
                    tripReasonAndPolicyDataJson = JSON.parse(localStorage.getItem("loginData"));
                    tripReasonList = tripReasonAndPolicyDataJson.tripReason === null ? [] : tripReasonAndPolicyDataJson.tripReason.filter(a => a.id === tripReasonValue !== undefined && tripReasonValue !== null && tripReasonValue !== '' ? tripReasonValue : tripReasonOptions[0].value);
                }

                if (localStorage.getItem("loginData") !== null && tripReasonAndPolicyDataJson.isTravelPolicy === false) {
                    let searchPolicyResponseTemp = JSON.parse(localStorage.getItem("loginData"));
                    let travelRequestFieldsDataJson = "";
                    let tripReasonToFilter = "";

                    //in case tripReason field value exist in request json then, that will be used to filter policy
                    if (sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
                        travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
                        if (travelRequestFieldsDataJson.tripReason !== null && travelRequestFieldsDataJson.tripReason !== '') {
                            tripReasonToFilter = travelRequestFieldsDataJson.tripReason;
                        }
                        else {
                            tripReasonToFilter = tripReasonValue !== undefined && tripReasonValue !== null && tripReasonValue !== '' ? tripReasonValue : tripReasonOptions[0].value;
                        }
                    }
                    searchPolicyResponse = searchPolicyResponseTemp.policy.filter(a => a.tripReasonID === tripReasonToFilter);
                }

                let isFlight = false;
                let isHotel = false;
                let isServiceRequest = false;

                searchPolicyResponse.length > 0 && searchPolicyResponse.forEach(elementPolicy => {
                    if (checkSecurityDetails("Book", "Flight") && checkSecurityDetails("Book", "Flight", "Book_Flight_Search") && getConfigurationByBoolen("ALLOW_FLIGHT") === true && elementPolicy.isFlight === true) {
                        isFlight = true;
                    }
                    if (checkSecurityDetails("Book", "Hotel") && checkSecurityDetails("Book", "Hotel", "Book_Hotel_Search") && getConfigurationByBoolen("ALLOW_HOTEL") && elementPolicy.isHotel === true) {
                        isHotel = true;
                    }
                    if (getConfigurationByBoolen("ALLOW_SERVICE_REQUEST") && elementPolicy.isServiceRequest === true) {
                        isServiceRequest = true;
                    }
                });

                if (searchPolicyResponse.length > 0) {
                    if (isFlight === false) {
                        if (document.querySelector('#tabButton_Flight') !== null) {
                            document.querySelector('#tabButton_Flight').classList.add("d-none");
                            document.querySelector('#tabButton_Flight_parent').classList.add("d-none");
                        }

                        if (document.querySelector('#tabArea_Flight') !== null) {
                            document.querySelector('#tabArea_Flight').classList.add("d-none");
                        }
                    }

                    if (isHotel === false) {
                        if (document.querySelector('#tabButton_Hotel') !== null) {
                            document.querySelector('#tabButton_Hotel').classList.add("d-none");
                            document.querySelector('#tabButton_Hotel_parent').classList.add("d-none");
                        }

                        if (document.querySelector('#tabArea_Hotel') !== null) {
                            document.querySelector('#tabArea_Hotel').classList.add("d-none");
                        }
                    }

                    if (isServiceRequest === false) {
                        if (document.querySelector('#tabButton_ServiceRequest') !== null) {
                            document.querySelector('#tabButton_ServiceRequest').classList.add("d-none");
                            document.querySelector('#tabButton_ServiceRequest_parent').classList.add("d-none");
                        }

                        if (document.querySelector('#tabArea_ServiceRequest') !== null) {
                            document.querySelector('#tabArea_ServiceRequest').classList.add("d-none");
                        }
                    }

                    if (isFlight === true) {
                        if (document.querySelector('#tabButton_Flight') !== null) {
                            document.querySelector('#tabButton_Flight').classList.add("active");
                            document.querySelector('#tabButton_Flight_parent').classList.remove("d-none");
                        }
                        if (document.querySelector('#tabArea_Flight') !== null) {
                            document.querySelector('#tabArea_Flight').classList.add("active");
                        }
                        if (document.querySelector('#tabArea_Flight') !== null) {
                            if (!document.querySelector('#tabArea_Flight').classList.toString().includes('show')) {
                                document.querySelector('#tabArea_Flight').classList.add("show");
                            }
                        }
                    }
                    else if (isHotel === true) {
                        if (document.querySelector('#tabButton_Hotel') !== null) {
                            document.querySelector('#tabButton_Hotel').classList.add("active");
                            document.querySelector('#tabButton_Hotel_parent').classList.remove("d-none");
                        }
                        if (document.querySelector('#tabArea_Hotel') !== null) {
                            document.querySelector('#tabArea_Hotel').classList.add("active");
                        }
                        if (document.querySelector('#tabArea_Hotel') !== null) {
                            if (!document.querySelector('#tabArea_Hotel').classList.toString().includes('show')) {
                                document.querySelector('#tabArea_Hotel').classList.add("show");
                            }
                        }
                    }
                    else if (getConfigurationByBoolen("ALLOW_SERVICE_REQUEST") && isServiceRequest === true) {
                        if (document.querySelector('#tabButton_ServiceRequest') !== null) {
                            document.querySelector('#tabButton_ServiceRequest').classList.add("active");
                            document.querySelector('#tabButton_ServiceRequest_parent').classList.remove("d-none");
                        }
                        if (document.querySelector('#tabArea_ServiceRequest') !== null) {
                            document.querySelector('#tabArea_ServiceRequest').classList.add("active");
                        }
                        if (document.querySelector('#tabArea_ServiceRequest') !== null) {
                            if (!document.querySelector('#tabArea_ServiceRequest').classList.toString().includes('show')) {
                                document.querySelector('#tabArea_ServiceRequest').classList.add("show");
                            }
                        }
                    }
                }
                else {
                    if (getConfigurationByBoolen("ALLOW_FLIGHT") === false && isFlight === false) {
                        if (document.querySelector('#tabButton_Flight') !== null) {
                            document.querySelector('#tabButton_Flight').classList.add("d-none");
                            document.querySelector('#tabButton_Flight_parent').classList.add("d-none");
                        }

                        if (document.querySelector('#tabArea_Flight') !== null) {
                            document.querySelector('#tabArea_Flight').classList.add("d-none");
                        }
                    }

                    if (getConfigurationByBoolen("ALLOW_HOTEL") === false && isHotel === false) {
                        if (document.querySelector('#tabButton_Hotel') !== null) {
                            document.querySelector('#tabButton_Hotel').classList.add("d-none");
                            document.querySelector('#tabButton_Hotel_parent').classList.add("d-none");
                        }

                        if (document.querySelector('#tabArea_Hotel') !== null) {
                            document.querySelector('#tabArea_Hotel').classList.add("d-none");
                        }
                    }

                    if (getConfigurationByBoolen("ALLOW_SERVICE_REQUEST") === false && isServiceRequest === false) {
                        if (document.querySelector('#tabButton_ServiceRequest') !== null) {
                            document.querySelector('#tabButton_ServiceRequest').classList.add("d-none");
                            document.querySelector('#tabButton_ServiceRequest_parent').classList.add("d-none");
                        }

                        if (document.querySelector('#tabArea_ServiceRequest') !== null) {
                            document.querySelector('#tabArea_ServiceRequest').classList.add("d-none");
                        }
                    }
                }

                if (loadedTravelRequest.data.errors.status === 'TRUE') {
                    if (document.querySelector('#btnSubmit') !== null) {
                        document.querySelector('#btnSubmit').style.display = "";
                    }
                    if (document.querySelector('#btnReset') !== null) {
                        document.querySelector('#btnReset').style.display = "";
                    }

                    setError({
                        title: "Message",
                        message: loadedTravelRequest.data.errors.error.description
                    });
                }
                else {
                    // made travelRequestDocuments node is null in travel request out put json to reduce json weight
                    if (loadedTravelRequest.data.travelRequestDocuments !== undefined && loadedTravelRequest.data.travelRequestDocuments !== null) {
                        loadedTravelRequest.data.travelRequestDocuments = null;
                    }
                    localStorage.setItem("TravelRequestOutput", JSON.stringify(loadedTravelRequest));

                    if (localStorage.getItem("BookingDataTravelRequest") !== null) {
                        localStorage.removeItem("SeatMapData");
                        localStorage.removeItem("AdditionalServicesData");
                        sessionStorage.removeItem("hotelSessionDataJson");
                        sessionStorage.removeItem("singlePnrCheckOutMinvalue");

                        localStorage.setItem("BookingData", JSON.stringify(bookingJSON))
                        let soureceForPriceCheck = false;
                        let source = bookingJSON.data.airItinerary[0].SourceName !== undefined ? bookingJSON.data.airItinerary[0].SourceName : '';

                        if (getConfigurationByBoolen("ALLOW_NDC_PRICE_REVALIDATE") && source.trim().toUpperCase() === "NDC") {
                            soureceForPriceCheck = true;
                        } else if (getConfigurationByBoolen("ALLOW_SABRE_PRICE_REVALIDATE") && source.trim().toUpperCase() === "SABRE") {
                            soureceForPriceCheck = true;
                        }

                        if (soureceForPriceCheck) {
                            sendRequest(bookingJSON);
                        }
                        else {
                            history.push(BOOKING);
                        }
                    }
                    else {
                        setError({
                            title: "Message",
                            message: 'Success! Your Travel Request has been submitted with Confirmation Number ' + loadedTravelRequest.data.referenceNumber + '.',
                        });
                    }
                }
            }
            else {
                if (document.querySelector('#btnSubmit') !== null) {
                    document.querySelector('#btnSubmit').style.display = "";
                }
                if (document.querySelector('#btnReset') !== null) {
                    document.querySelector('#btnReset').style.display = "";
                }

                setError({
                    title: "Message",
                    message: `Some thing went wrong.`,
                });
            }
            return;
        }

    }, [statusTravelRequest, loadedTravelRequest])

    useEffect(() => {
        if (localStorage.getItem("loginData") !== null) {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });

            let searchPolicyResponse = "";
            let tripReasonAndPolicyDataJson = "";

            if (localStorage.getItem("loginData") !== null) {
                tripReasonAndPolicyDataJson = JSON.parse(localStorage.getItem("loginData"));
                tripReasonList = tripReasonAndPolicyDataJson.tripReason === null ? [] : tripReasonAndPolicyDataJson.tripReason.filter(a => a.id === tripReasonValue !== undefined && tripReasonValue !== null && tripReasonValue !== '' ? tripReasonValue : tripReasonOptions[0].value);
            }

            if (localStorage.getItem("loginData") !== null && tripReasonAndPolicyDataJson.isTravelPolicy === false) {
                let searchPolicyResponseTemp = JSON.parse(localStorage.getItem("loginData"));

                let travelRequestFieldsDataJson = "";
                let tripReasonToFilter = "";
                if (sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
                    travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
                    if (travelRequestFieldsDataJson.tripReason !== null && travelRequestFieldsDataJson.tripReason !== '') {
                        tripReasonToFilter = travelRequestFieldsDataJson.tripReason;
                    }
                    else {
                        tripReasonToFilter = tripReasonValue !== undefined && tripReasonValue !== null && tripReasonValue !== '' ? tripReasonValue : tripReasonOptions[0].value;
                    }
                }
                searchPolicyResponse = searchPolicyResponseTemp.policy.filter(a => a.tripReasonID === tripReasonToFilter);
            }

            let isFlight = false;
            let isHotel = false;
            let isServiceRequest = false;

            searchPolicyResponse.length > 0 && searchPolicyResponse.forEach(elementPolicy => {
                if (checkSecurityDetails("Book", "Flight") && checkSecurityDetails("Book", "Flight", "Book_Flight_Search") && getConfigurationByBoolen("ALLOW_FLIGHT") === true && elementPolicy.isFlight === true) {
                    isFlight = true;
                }
                if (checkSecurityDetails("Book", "Hotel") && checkSecurityDetails("Book", "Hotel", "Book_Hotel_Search") && getConfigurationByBoolen("ALLOW_HOTEL") && elementPolicy.isHotel === true) {
                    isHotel = true;
                }
                if (getConfigurationByBoolen("ALLOW_SERVICE_REQUEST") && elementPolicy.isServiceRequest === true) {
                    isServiceRequest = true;
                }
            });

            if (searchPolicyResponse.length > 0) {
                if (isFlight === false) {
                    if (document.querySelector('#tabButton_Flight') !== null) {
                        document.querySelector('#tabButton_Flight').classList.add("d-none");
                        document.querySelector('#tabButton_Flight_parent').classList.add("d-none");
                    }

                    if (document.querySelector('#tabArea_Flight') !== null) {
                        document.querySelector('#tabArea_Flight').classList.add("d-none");
                    }
                }

                if (isHotel === false) {
                    if (document.querySelector('#tabButton_Hotel') !== null) {
                        document.querySelector('#tabButton_Hotel').classList.add("d-none");
                        document.querySelector('#tabButton_Hotel_parent').classList.add("d-none");
                    }

                    if (document.querySelector('#tabArea_Hotel') !== null) {
                        document.querySelector('#tabArea_Hotel').classList.add("d-none");
                    }
                }

                if (isServiceRequest === false) {
                    if (document.querySelector('#tabButton_ServiceRequest') !== null) {
                        document.querySelector('#tabButton_ServiceRequest').classList.add("d-none");
                        document.querySelector('#tabButton_ServiceRequest_parent').classList.add("d-none");
                    }

                    if (document.querySelector('#tabArea_ServiceRequest') !== null) {
                        document.querySelector('#tabArea_ServiceRequest').classList.add("d-none");
                    }
                }
            }
            else {
                if (getConfigurationByBoolen("ALLOW_FLIGHT") === false && isFlight === false) {
                    if (document.querySelector('#tabButton_Flight') !== null) {
                        document.querySelector('#tabButton_Flight').classList.add("d-none");
                        document.querySelector('#tabButton_Flight_parent').classList.add("d-none");
                    }

                    if (document.querySelector('#tabArea_Flight') !== null) {
                        document.querySelector('#tabArea_Flight').classList.add("d-none");
                    }
                }

                if (getConfigurationByBoolen("ALLOW_HOTEL") === false && isHotel === false) {
                    if (document.querySelector('#tabButton_Hotel') !== null) {
                        document.querySelector('#tabButton_Hotel').classList.add("d-none");
                        document.querySelector('#tabButton_Hotel_parent').classList.add("d-none");
                    }

                    if (document.querySelector('#tabArea_Hotel') !== null) {
                        document.querySelector('#tabArea_Hotel').classList.add("d-none");
                    }
                }

                if (getConfigurationByBoolen("ALLOW_SERVICE_REQUEST") === false && isServiceRequest === false) {
                    if (document.querySelector('#tabButton_ServiceRequest') !== null) {
                        document.querySelector('#tabButton_ServiceRequest').classList.add("d-none");
                        document.querySelector('#tabButton_ServiceRequest_parent').classList.add("d-none");
                    }

                    if (document.querySelector('#tabArea_ServiceRequest') !== null) {
                        document.querySelector('#tabArea_ServiceRequest').classList.add("d-none");
                    }
                }
            }

            if (tripReasonList.length > 0) {
                if (tripReasonList[0].meetingID === "1") {
                    if (document.querySelector('#tabButton_MeetingDetails') !== null) {
                        document.querySelector('#tabButton_MeetingDetails').classList.add("d-none");
                    }

                    if (document.querySelector('#tabArea_MeetingDetails') !== null) {
                        document.querySelector('#tabArea_MeetingDetails').classList.add("d-none");
                    }
                }

                if (tripReasonList.length > 0 && (tripReasonList[0].meetingID === "1" || tripReasonList[0].meetingID === "3")) {
                    let inputs = document.querySelectorAll('.meeting');
                    for (let i = 0; i < inputs.length; i++) {
                        if (document.querySelector('#' + inputs[i].getAttribute("id")) !== null) {
                            document.querySelector('#' + inputs[i].getAttribute("id")).innerHTML = "";
                        }
                    }
                }
                else {
                    let inputs = document.querySelectorAll('.meeting');
                    for (let i = 0; i < inputs.length; i++) {
                        if (document.querySelector('#' + inputs[i].getAttribute("id")) !== null) {
                            document.querySelector('#' + inputs[i].getAttribute("id")).innerHTML = " *";
                        }
                    }
                }
            }

            if (valueParam.return !== undefined && valueParam.return !== '' && valueParam.return === '1') {
                let serviceTemp = valueParam.service === 'flight' ? 'Flight' : valueParam.service === 'hotel' ? 'Hotel' : 'Flight';
                onTabChangeHandler(serviceTemp);

                if (document.querySelector('#tabButton_Flight') !== null) {
                    document.querySelector('#tabButton_Flight').classList.remove("active");
                }
                if (document.querySelector('#tabArea_Flight') !== null) {
                    document.querySelector('#tabArea_Flight').classList.remove("active");
                }
                if (document.querySelector('#tabButton_Hotel') !== null) {
                    document.querySelector('#tabButton_Hotel').classList.remove("active");
                }
                if (document.querySelector('#tabArea_Hotel') !== null) {
                    document.querySelector('#tabArea_Hotel').classList.remove("active");
                }
                if (document.querySelector('#tabButton_ServiceRequest') !== null) {
                    document.querySelector('#tabButton_ServiceRequest').classList.remove("active");
                }
                if (document.querySelector('#tabArea_ServiceRequest') !== null) {
                    document.querySelector('#tabArea_ServiceRequest').classList.remove("active");
                }
                if (document.querySelector('#tabButton_Document') !== null) {
                    document.querySelector('#tabButton_Document').classList.remove("active");
                }
                if (document.querySelector('#tabArea_Document') !== null) {
                    document.querySelector('#tabArea_Document').classList.remove("active");
                }
                if (document.querySelector('#tabButton_MeetingDetails') !== null) {
                    document.querySelector('#tabButton_MeetingDetails').classList.remove("active");
                }
                if (document.querySelector('#tabArea_MeetingDetails') !== null) {
                    document.querySelector('#tabArea_MeetingDetails').classList.remove("active");
                }
                if (document.querySelector('#tabButton_' + serviceTemp) !== null) {
                    document.querySelector('#tabButton_' + serviceTemp).classList.add("active");
                }
                if (document.querySelector('#tabArea_' + serviceTemp) !== null) {
                    document.querySelector('#tabArea_' + serviceTemp).classList.add("active");
                }
                if (document.querySelector('#tabArea_' + serviceTemp) !== null) {
                    if (!document.querySelector('#tabArea_' + serviceTemp).classList.toString().includes('show')) {
                        document.querySelector('#tabArea_' + serviceTemp).classList.add("show");
                    }
                }
            }
            else {
                if (searchPolicyResponse.length > 0) {
                    if (getConfigurationByBoolen("ALLOW_FLIGHT") === true && isFlight === true) {
                        onTabChangeHandler('Flight');

                        if (document.querySelector('#tabButton_Flight') !== null) {
                            document.querySelector('#tabButton_Flight').classList.add("active");
                        }
                        if (document.querySelector('#tabArea_Flight') !== null) {
                            document.querySelector('#tabArea_Flight').classList.add("active");
                        }
                        if (document.querySelector('#tabArea_Flight') !== null) {
                            if (!document.querySelector('#tabArea_Flight').classList.toString().includes('show')) {
                                document.querySelector('#tabArea_Flight').classList.add("show");
                            }
                        }
                        if (document.querySelector('#tabButton_Hotel') !== null) {
                            document.querySelector('#tabButton_Hotel').classList.remove("active");
                        }
                        if (document.querySelector('#tabArea_Hotel') !== null) {
                            document.querySelector('#tabArea_Hotel').classList.remove("active");
                        }
                        if (document.querySelector('#tabButton_ServiceRequest') !== null) {
                            document.querySelector('#tabButton_ServiceRequest').classList.remove("active");
                        }
                        if (document.querySelector('#tabArea_ServiceRequest') !== null) {
                            document.querySelector('#tabArea_ServiceRequest').classList.remove("active");
                        }
                        if (document.querySelector('#tabButton_Approver') !== null) {
                            document.querySelector('#tabButton_Approver').classList.remove("active");
                        }
                        if (document.querySelector('#tabArea_Approver') !== null) {
                            document.querySelector('#tabArea_Approver').classList.remove("active");
                        }
                        if (document.querySelector('#tabButton_Document') !== null) {
                            document.querySelector('#tabButton_Document').classList.remove("active");
                        }
                        if (document.querySelector('#tabArea_Document') !== null) {
                            document.querySelector('#tabArea_Document').classList.remove("active");
                        }
                        if (document.querySelector('#tabButton_MeetingDetails') !== null) {
                            document.querySelector('#tabButton_MeetingDetails').classList.remove("active");
                        }
                        if (document.querySelector('#tabArea_MeetingDetails') !== null) {
                            document.querySelector('#tabArea_MeetingDetails').classList.remove("active");
                        }
                    }
                    else if (getConfigurationByBoolen("ALLOW_HOTEL") && isHotel === true) {
                        onTabChangeHandler('Hotel');

                        if (document.querySelector('#tabButton_Flight') !== null) {
                            document.querySelector('#tabButton_Flight').classList.remove("active");
                        }
                        if (document.querySelector('#tabArea_Flight') !== null) {
                            document.querySelector('#tabArea_Flight').classList.remove("active");
                        }
                        if (document.querySelector('#tabButton_Hotel') !== null) {
                            document.querySelector('#tabButton_Hotel').classList.add("active");
                        }
                        if (document.querySelector('#tabArea_Hotel') !== null) {
                            document.querySelector('#tabArea_Hotel').classList.add("active");
                        }
                        if (document.querySelector('#tabArea_Hotel') !== null) {
                            if (!document.querySelector('#tabArea_Hotel').classList.toString().includes('show')) {
                                document.querySelector('#tabArea_Hotel').classList.add("show");
                            }
                        }
                        if (document.querySelector('#tabButton_ServiceRequest') !== null) {
                            document.querySelector('#tabButton_ServiceRequest').classList.remove("active");
                        }
                        if (document.querySelector('#tabArea_ServiceRequest') !== null) {
                            document.querySelector('#tabArea_ServiceRequest').classList.remove("active");
                        }
                        if (document.querySelector('#tabButton_Approver') !== null) {
                            document.querySelector('#tabButton_Approver').classList.remove("active");
                        }
                        if (document.querySelector('#tabArea_Approver') !== null) {
                            document.querySelector('#tabArea_Approver').classList.remove("active");
                        }
                        if (document.querySelector('#tabButton_Document') !== null) {
                            document.querySelector('#tabButton_Document').classList.remove("active");
                        }
                        if (document.querySelector('#tabArea_Document') !== null) {
                            document.querySelector('#tabArea_Document').classList.remove("active");
                        }
                        if (document.querySelector('#tabButton_MeetingDetails') !== null) {
                            document.querySelector('#tabButton_MeetingDetails').classList.remove("active");
                        }
                        if (document.querySelector('#tabArea_MeetingDetails') !== null) {
                            document.querySelector('#tabArea_MeetingDetails').classList.remove("active");
                        }
                    }
                    else if (getConfigurationByBoolen("ALLOW_SERVICE_REQUEST") && isServiceRequest === true) {
                        onTabChangeHandler('ServiceRequest');

                        if (document.querySelector('#tabButton_Flight') !== null) {
                            document.querySelector('#tabButton_Flight').classList.remove("active");
                        }
                        if (document.querySelector('#tabArea_Flight') !== null) {
                            document.querySelector('#tabArea_Flight').classList.remove("active");
                        }
                        if (document.querySelector('#tabButton_Hotel') !== null) {
                            document.querySelector('#tabButton_Hotel').classList.remove("active");
                        }
                        if (document.querySelector('#tabArea_Hotel') !== null) {
                            document.querySelector('#tabArea_Hotel').classList.remove("active");
                        }
                        if (document.querySelector('#tabButton_ServiceRequest') !== null) {
                            document.querySelector('#tabButton_ServiceRequest').classList.add("active");
                        }
                        if (document.querySelector('#tabArea_ServiceRequest') !== null) {
                            document.querySelector('#tabArea_ServiceRequest').classList.add("active");
                        }
                        if (document.querySelector('#tabArea_ServiceRequest') !== null) {
                            if (!document.querySelector('#tabArea_ServiceRequest').classList.toString().includes('show')) {
                                document.querySelector('#tabArea_ServiceRequest').classList.add("show");
                            }
                        }
                        if (document.querySelector('#tabButton_Approver') !== null) {
                            document.querySelector('#tabButton_Approver').classList.remove("active");
                        }
                        if (document.querySelector('#tabArea_Approver') !== null) {
                            document.querySelector('#tabArea_Approver').classList.remove("active");
                        }
                        if (document.querySelector('#tabButton_Document') !== null) {
                            document.querySelector('#tabButton_Document').classList.remove("active");
                        }
                        if (document.querySelector('#tabArea_Document') !== null) {
                            document.querySelector('#tabArea_Document').classList.remove("active");
                        }
                        if (document.querySelector('#tabButton_MeetingDetails') !== null) {
                            document.querySelector('#tabButton_MeetingDetails').classList.remove("active");
                        }
                        if (document.querySelector('#tabArea_MeetingDetails') !== null) {
                            document.querySelector('#tabArea_MeetingDetails').classList.remove("active");
                        }
                    }
                    else {
                        if (selectedTab !== undefined && selectedTab !== '') {
                            if (selectedTab === 'Flight') {
                                onTabChangeHandler('Approver');

                                if (document.querySelector('#tabButton_Flight') !== null) {
                                    document.querySelector('#tabButton_Flight').classList.remove("active");
                                }
                                if (document.querySelector('#tabArea_Flight') !== null) {
                                    document.querySelector('#tabArea_Flight').classList.remove("active");
                                }
                                if (document.querySelector('#tabButton_Hotel') !== null) {
                                    document.querySelector('#tabButton_Hotel').classList.remove("active");
                                }
                                if (document.querySelector('#tabArea_Hotel') !== null) {
                                    document.querySelector('#tabArea_Hotel').classList.remove("active");
                                }
                                if (document.querySelector('#tabButton_ServiceRequest') !== null) {
                                    document.querySelector('#tabButton_ServiceRequest').classList.remove("active");
                                }
                                if (document.querySelector('#tabArea_ServiceRequest') !== null) {
                                    document.querySelector('#tabArea_ServiceRequest').classList.remove("active");
                                }
                                if (document.querySelector('#tabButton_Approver') !== null) {
                                    document.querySelector('#tabButton_Approver').classList.add("active");
                                }
                                if (document.querySelector('#tabArea_Approver') !== null) {
                                    document.querySelector('#tabArea_Approver').classList.add("active");
                                }
                                if (document.querySelector('#tabArea_Approver') !== null) {
                                    if (!document.querySelector('#tabArea_Approver').classList.toString().includes('show')) {
                                        document.querySelector('#tabArea_Approver').classList.add("show");
                                    }
                                }
                                if (document.querySelector('#tabButton_Document') !== null) {
                                    document.querySelector('#tabButton_Document').classList.remove("active");
                                }
                                if (document.querySelector('#tabArea_Document') !== null) {
                                    document.querySelector('#tabArea_Document').classList.remove("active");
                                }
                                if (document.querySelector('#tabButton_MeetingDetails') !== null) {
                                    document.querySelector('#tabButton_MeetingDetails').classList.remove("active");
                                }
                                if (document.querySelector('#tabArea_MeetingDetails') !== null) {
                                    document.querySelector('#tabArea_MeetingDetails').classList.remove("active");
                                }
                            }
                            else {
                                onTabChangeHandler(selectedTab);

                                if (document.querySelector('#tabButton_Flight') !== null) {
                                    document.querySelector('#tabButton_Flight').classList.remove("active");
                                }
                                if (document.querySelector('#tabArea_Flight') !== null) {
                                    document.querySelector('#tabArea_Flight').classList.remove("active");
                                }
                                if (document.querySelector('#tabButton_Hotel') !== null) {
                                    document.querySelector('#tabButton_Hotel').classList.remove("active");
                                }
                                if (document.querySelector('#tabArea_Hotel') !== null) {
                                    document.querySelector('#tabArea_Hotel').classList.remove("active");
                                }
                                if (document.querySelector('#tabButton_ServiceRequest') !== null) {
                                    document.querySelector('#tabButton_ServiceRequest').classList.remove("active");
                                }
                                if (document.querySelector('#tabArea_ServiceRequest') !== null) {
                                    document.querySelector('#tabArea_ServiceRequest').classList.remove("active");
                                }
                                if (document.querySelector('#tabButton_Document') !== null) {
                                    document.querySelector('#tabButton_Document').classList.remove("active");
                                }
                                if (document.querySelector('#tabArea_Document') !== null) {
                                    document.querySelector('#tabArea_Document').classList.remove("active");
                                }
                                if (document.querySelector('#tabButton_MeetingDetails') !== null) {
                                    document.querySelector('#tabButton_MeetingDetails').classList.remove("active");
                                }
                                if (document.querySelector('#tabArea_MeetingDetails') !== null) {
                                    document.querySelector('#tabArea_MeetingDetails').classList.remove("active");
                                }
                                if (document.querySelector('#tabButton_' + selectedTab) !== null) {
                                    document.querySelector('#tabButton_' + selectedTab).classList.add("active");
                                }
                                if (document.querySelector('#tabArea_' + selectedTab) !== null) {
                                    document.querySelector('#tabArea_' + selectedTab).classList.add("active");
                                }
                                if (document.querySelector('#tabArea_' + selectedTab) !== null) {
                                    if (!document.querySelector('#tabArea_' + selectedTab).classList.toString().includes('show')) {
                                        document.querySelector('#tabArea_' + selectedTab).classList.add("show");
                                    }
                                }
                            }
                        }
                        else {
                            onTabChangeHandler('Approver');

                            if (document.querySelector('#tabButton_Flight') !== null) {
                                document.querySelector('#tabButton_Flight').classList.remove("active");
                            }
                            if (document.querySelector('#tabArea_Flight') !== null) {
                                document.querySelector('#tabArea_Flight').classList.remove("active");
                            }
                            if (document.querySelector('#tabButton_Hotel') !== null) {
                                document.querySelector('#tabButton_Hotel').classList.remove("active");
                            }
                            if (document.querySelector('#tabArea_Hotel') !== null) {
                                document.querySelector('#tabArea_Hotel').classList.remove("active");
                            }
                            if (document.querySelector('#tabButton_ServiceRequest') !== null) {
                                document.querySelector('#tabButton_ServiceRequest').classList.remove("active");
                            }
                            if (document.querySelector('#tabArea_ServiceRequest') !== null) {
                                document.querySelector('#tabArea_ServiceRequest').classList.remove("active");
                            }
                            if (document.querySelector('#tabButton_Approver') !== null) {
                                document.querySelector('#tabButton_Approver').classList.add("active");
                            }
                            if (document.querySelector('#tabArea_Approver') !== null) {
                                document.querySelector('#tabArea_Approver').classList.add("active");
                            }
                            if (document.querySelector('#tabArea_Approver') !== null) {
                                if (!document.querySelector('#tabArea_Approver').classList.toString().includes('show')) {
                                    document.querySelector('#tabArea_Approver').classList.add("show");
                                }
                            }
                            if (document.querySelector('#tabButton_Document') !== null) {
                                document.querySelector('#tabButton_Document').classList.remove("active");
                            }
                            if (document.querySelector('#tabArea_Document') !== null) {
                                document.querySelector('#tabArea_Document').classList.remove("active");
                            }
                            if (document.querySelector('#tabButton_MeetingDetails') !== null) {
                                document.querySelector('#tabButton_MeetingDetails').classList.remove("active");
                            }
                            if (document.querySelector('#tabArea_MeetingDetails') !== null) {
                                document.querySelector('#tabArea_MeetingDetails').classList.remove("active");
                            }
                        }
                    }
                }
                else {
                    if (getConfigurationByBoolen("ALLOW_FLIGHT") === false && isFlight === false) {
                        if (document.querySelector('#tabButton_Flight') !== null) {
                            document.querySelector('#tabButton_Flight').classList.add("d-none");
                            document.querySelector('#tabButton_Flight_parent').classList.add("d-none");
                        }

                        if (document.querySelector('#tabArea_Flight') !== null) {
                            document.querySelector('#tabArea_Flight').classList.add("d-none");
                        }
                    }

                    if (getConfigurationByBoolen("ALLOW_HOTEL") === false && isHotel === false) {
                        if (document.querySelector('#tabButton_Hotel') !== null) {
                            document.querySelector('#tabButton_Hotel').classList.add("d-none");
                            document.querySelector('#tabButton_Hotel_parent').classList.add("d-none");
                        }

                        if (document.querySelector('#tabArea_Hotel') !== null) {
                            document.querySelector('#tabArea_Hotel').classList.add("d-none");
                        }
                    }

                    if (getConfigurationByBoolen("ALLOW_SERVICE_REQUEST") === false && isServiceRequest === false) {
                        if (document.querySelector('#tabButton_ServiceRequest') !== null) {
                            document.querySelector('#tabButton_ServiceRequest').classList.add("d-none");
                            document.querySelector('#tabButton_ServiceRequest_parent').classList.add("d-none");
                        }

                        if (document.querySelector('#tabArea_ServiceRequest') !== null) {
                            document.querySelector('#tabArea_ServiceRequest').classList.add("d-none");
                        }
                    }
                }
            }

            if (valueParam.return !== undefined && valueParam.return !== '' && valueParam.return === '1') {
                history.push({
                    pathname: location.pathname,
                    search: '?return=1&service=' + valueParam.service
                })
            }
            else {
                history.push({
                    pathname: location.pathname
                })
            }
        }
    }, [])

    useEffect(() => {
        if (status === 'completed') {
            if (loadedPriceCheckResponse !== null && loadedPriceCheckResponse.data !== null) {

                if (loadedPriceCheckResponse.data.errors.status === 'TRUE') {
                    setError({
                        title: "Message",
                        message: loadedPriceCheckResponse.data.errors.error.description,
                    });
                }
                else {
                    if (loadedPriceCheckResponse.data.airItinerary[0].airItineraryPricingInfo.priceChange) {
                        let bookingJSON = JSON.parse(localStorage.getItem("BookingData"));
                        setConfirm({
                            title: "Message",
                            message: `Airline has changed the price from ${new Intl.NumberFormat('en-US', { style: 'currency', currency: bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.currencyCode }).format(bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.amount)} to ${new Intl.NumberFormat('en-US', { style: 'currency', currency: bookingJSON.data.airItinerary[0].airItineraryPricingInfo.totalFare.currencyCode }).format(loadedPriceCheckResponse.data.airItinerary[0].airItineraryPricingInfo.totalFare.amount)} Do you want to proceed with the new price?`,
                        });
                    } else {
                        let bookingJSON = JSON.parse(localStorage.getItem("BookingData"));
                        bookingJSON.data.airItinerary[0].airItineraryPricingInfo = loadedPriceCheckResponse.data.airItinerary[0].airItineraryPricingInfo;
                        localStorage.setItem("BookingData", JSON.stringify(bookingJSON))
                        history.push(BOOKING);
                    }
                }
            }
            else {
                setError({
                    title: "Message",
                    message: `Sorry, we have an encountered an issue. Please try again.`,
                });
            }
            return;
        }
    }, [status, history, loadedPriceCheckResponse])

    useEffect(() => {
        let searchPolicyResponse = "";
        let tripReasonAndPolicyDataJson = "";

        if (localStorage.getItem("loginData") !== null) {
            tripReasonAndPolicyDataJson = JSON.parse(localStorage.getItem("loginData"));
            tripReasonList = tripReasonAndPolicyDataJson.tripReason === null ? [] : tripReasonAndPolicyDataJson.tripReason.filter(a => a.id === tripReasonValue !== undefined && tripReasonValue !== null && tripReasonValue !== '' ? tripReasonValue : tripReasonOptions[0].value);
        }

        if (localStorage.getItem("loginData") !== null && tripReasonAndPolicyDataJson.isTravelPolicy === false) {
            let searchPolicyResponseTemp = JSON.parse(localStorage.getItem("loginData"));
            let travelRequestFieldsDataJson = "";
            let tripReasonToFilter = "";

            if (sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
                travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
                if (travelRequestFieldsDataJson.tripReason !== null && travelRequestFieldsDataJson.tripReason !== '') {
                    tripReasonToFilter = travelRequestFieldsDataJson.tripReason;
                }
                else {
                    tripReasonToFilter = tripReasonValue !== undefined && tripReasonValue !== null && tripReasonValue !== '' ? tripReasonValue : tripReasonOptions[0].value;
                }
            }
            searchPolicyResponse = searchPolicyResponseTemp.policy.filter(a => a.tripReasonID === tripReasonToFilter);
        }

        let isFlight = false;
        let isHotel = false;
        let isServiceRequest = false;

        searchPolicyResponse.length > 0 && searchPolicyResponse.forEach(elementPolicy => {
            if (checkSecurityDetails("Book", "Flight") && checkSecurityDetails("Book", "Flight", "Book_Flight_Search") && getConfigurationByBoolen("ALLOW_FLIGHT") === true && elementPolicy.isFlight === true) {
                isFlight = true;
            }
            if (checkSecurityDetails("Book", "Hotel") && checkSecurityDetails("Book", "Hotel", "Book_Hotel_Search") && getConfigurationByBoolen("ALLOW_HOTEL") && elementPolicy.isHotel === true) {
                isHotel = true;
            }
            if (getConfigurationByBoolen("ALLOW_SERVICE_REQUEST") && elementPolicy.isServiceRequest === true) {
                isServiceRequest = true;
            }
        });

        if (searchPolicyResponse.length > 0) {
            if (getConfigurationByBoolen("ALLOW_FLIGHT") === true && isFlight === true) {
                if (document.querySelector('#dvTravellers') === null) {
                    if (valueParam.return !== undefined && valueParam.return !== '' && valueParam.return === '1') {
                        history.push({
                            pathname: location.pathname,
                            search: '?return=1&service=' + valueParam.service
                        })
                    }
                    else {
                        history.push({
                            pathname: location.pathname
                        })
                    }
                }
            }
        }
        else {
            if (getConfigurationByBoolen("ALLOW_FLIGHT") === false && isFlight === false) {
                if (document.querySelector('#tabButton_Flight') !== null) {
                    document.querySelector('#tabButton_Flight').classList.add("d-none");
                    document.querySelector('#tabButton_Flight_parent').classList.add("d-none");
                }

                if (document.querySelector('#tabArea_Flight') !== null) {
                    document.querySelector('#tabArea_Flight').classList.add("d-none");
                }
            }

            if (getConfigurationByBoolen("ALLOW_HOTEL") === false && isHotel === false) {
                if (document.querySelector('#tabButton_Hotel') !== null) {
                    document.querySelector('#tabButton_Hotel').classList.add("d-none");
                    document.querySelector('#tabButton_Hotel_parent').classList.add("d-none");
                }

                if (document.querySelector('#tabArea_Hotel') !== null) {
                    document.querySelector('#tabArea_Hotel').classList.add("d-none");
                }
            }

            if (getConfigurationByBoolen("ALLOW_SERVICE_REQUEST") === false && isServiceRequest === false) {
                if (document.querySelector('#tabButton_ServiceRequest') !== null) {
                    document.querySelector('#tabButton_ServiceRequest').classList.add("d-none");
                    document.querySelector('#tabButton_ServiceRequest_parent').classList.add("d-none");
                }

                if (document.querySelector('#tabArea_ServiceRequest') !== null) {
                    document.querySelector('#tabArea_ServiceRequest').classList.add("d-none");
                }
            }
        }

    }, [tripReasonValue])

    function onReset() {
        sessionStorage.removeItem("travelRequestFieldsDataJson");
        sessionStorage.removeItem("travelreqflightsearch");
        sessionStorage.removeItem("travelreqhotelsearch");
        localStorage.removeItem("BookingDataTravelRequest");
        localStorage.removeItem("TravelRequestInput");
        //window.location.reload(true);
        history.push('/TravelRequest');
        history.go();
    }

    const errorHandler = () => {
        setError(null);
        if (localStorage.getItem("BookingDataTravelRequest") !== null) {
            if (loadedTravelRequest !== null && loadedTravelRequest.data !== null && loadedTravelRequest.data.errors.status !== 'TRUE') {
                sessionStorage.removeItem("travelRequestFieldsDataJson");
                history.push('/mytrips');
            }
            else if (loadedPriceCheckResponse !== null && loadedPriceCheckResponse.data !== null && loadedPriceCheckResponse.data.errors.status !== 'TRUE') {
                sessionStorage.removeItem("travelRequestFieldsDataJson");
                history.push('/mytrips');
            }
            else {
                if (document.querySelector('#btnSubmit') !== null) {
                    document.querySelector('#btnSubmit').classList.remove("d-none");
                }
                if (document.querySelector('#btnReset') !== null) {
                    document.querySelector('#btnReset').classList.remove("d-none");
                }
            }
        }
        else {
            if (loadedTravelRequest !== null && loadedTravelRequest.data !== null && loadedTravelRequest.data.errors.status !== 'TRUE') {
                sessionStorage.removeItem("travelRequestFieldsDataJson");
                history.push('/mytrips');
            }
            else if (loadedPriceCheckResponse !== null && loadedPriceCheckResponse.data !== null && loadedPriceCheckResponse.data.errors.status !== 'TRUE') {
                sessionStorage.removeItem("travelRequestFieldsDataJson");
                history.push('/mytrips');
            }
            else {
                if (document.querySelector('#btnSubmit') !== null) {
                    document.querySelector('#btnSubmit').classList.remove("d-none");
                }
                if (document.querySelector('#btnReset') !== null) {
                    document.querySelector('#btnReset').classList.remove("d-none");
                }
            }
        }
    };

    function okHandler() {
        setConfirm(false);

        let travelRequestFieldsDataJson = "";
        if (sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
            travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
        }

        let TravelRequestInputJson = "";
        if (localStorage.getItem("TravelRequestInput") !== null) {
            TravelRequestInputJson = JSON.parse(localStorage.getItem("TravelRequestInput"));
        }

        // ((localStorage.getItem("BookingDataTravelRequest") !== null || localStorage.getItem("TravelRequestInput") !== null || selectedTab === 'Flight' || selectedTab === 'Hotel' || selectedTab === 'ServiceRequest' || selectedTab === 'Document'))
        if ((localStorage.getItem("BookingDataTravelRequest") !== null || localStorage.getItem("TravelRequestInput") !== null || travelRequestFieldsDataJson.serviceRequestId.length !== undefined) && (selectedTab === 'Flight' || selectedTab === 'Hotel' || selectedTab === 'ServiceRequest' || selectedTab === 'MeetingDetails' || selectedTab === 'Document')) {
            if (localStorage.getItem("BookingDataTravelRequest") !== null || TravelRequestInputJson.data.onlineFlightDetails !== null || TravelRequestInputJson.data.onlineHotelDetails !== null || TravelRequestInputJson.data.serviceRequest !== null || (travelRequestFieldsDataJson.serviceRequestId.length !== undefined && travelRequestFieldsDataJson.serviceRequestId.length !== 0)) {
                if (document.querySelector('#btnSubmit') !== null) {
                    document.querySelector('#btnSubmit').classList.remove("d-none");
                }
                if (document.querySelector('#btnReset') !== null) {
                    document.querySelector('#btnReset').classList.remove("d-none");
                }
            }
            else {
                if (document.querySelector('#btnSubmit') !== null) {
                    document.querySelector('#btnSubmit').classList.add("d-none");
                }
                if (document.querySelector('#btnReset') !== null) {
                    document.querySelector('#btnReset').classList.add("d-none");
                }
            }
        }
        else {
            if (document.querySelector('#btnSubmit') !== null) {
                document.querySelector('#btnSubmit').classList.add("d-none");
            }
            if (document.querySelector('#btnReset') !== null) {
                document.querySelector('#btnReset').classList.add("d-none");
            }
        }

        if (valueParam.return !== undefined && valueParam.return !== '' && valueParam.return === '1') {
            if (localStorage.getItem('BookingDataTravelRequest') !== null || localStorage.getItem('TravelRequestInput') !== null) {
                disableTripReason();
            }

            let travelRequestFieldsDataJson = "";
            if (sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
                travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
                if (travelRequestFieldsDataJson.tripReason !== null && travelRequestFieldsDataJson.tripReason !== '') {
                    setTripReasonValue(travelRequestFieldsDataJson.tripReason);
                    sessionStorage.removeItem("spnClass1");
                }
            }

            let searchPolicyResponse = "";
            let tripReasonAndPolicyDataJson = "";

            if (localStorage.getItem("loginData") !== null) {
                tripReasonAndPolicyDataJson = JSON.parse(localStorage.getItem("loginData"));
                tripReasonList = tripReasonAndPolicyDataJson.tripReason.filter(a => a.id === travelRequestFieldsDataJson.tripReason);
            }

            if (localStorage.getItem("loginData") !== null && tripReasonAndPolicyDataJson.isTravelPolicy === false) {
                let searchPolicyResponseTemp = JSON.parse(localStorage.getItem("loginData"));
                searchPolicyResponse = searchPolicyResponseTemp.policy.filter(a => a.tripReasonID === travelRequestFieldsDataJson.tripReason);
            }

            let isFlight = false;
            let isHotel = false;
            let isServiceRequest = false;

            searchPolicyResponse.length > 0 && searchPolicyResponse.forEach(elementPolicy => {
                if (checkSecurityDetails("Book", "Flight") && checkSecurityDetails("Book", "Flight", "Book_Flight_Search") && getConfigurationByBoolen("ALLOW_FLIGHT") === true && elementPolicy.isFlight === true) {
                    isFlight = true;
                }
                if (checkSecurityDetails("Book", "Hotel") && checkSecurityDetails("Book", "Hotel", "Book_Hotel_Search") && getConfigurationByBoolen("ALLOW_HOTEL") && elementPolicy.isHotel === true) {
                    isHotel = true;
                }
                if (getConfigurationByBoolen("ALLOW_SERVICE_REQUEST") && elementPolicy.isServiceRequest === true) {
                    isServiceRequest = true;
                }
            });

            if (searchPolicyResponse.length > 0) {
                if (isFlight === false) {
                    if (document.querySelector('#tabButton_Flight') !== null) {
                        document.querySelector('#tabButton_Flight').classList.add("d-none");
                        document.querySelector('#tabButton_Flight_parent').classList.add("d-none");
                    }

                    if (document.querySelector('#tabArea_Flight') !== null) {
                        document.querySelector('#tabArea_Flight').classList.add("d-none");
                    }
                }

                if (isHotel === false) {
                    if (document.querySelector('#tabButton_Hotel') !== null) {
                        document.querySelector('#tabButton_Hotel').classList.add("d-none");
                        document.querySelector('#tabButton_Hotel_parent').classList.add("d-none");
                    }

                    if (document.querySelector('#tabArea_Hotel') !== null) {
                        document.querySelector('#tabArea_Hotel').classList.add("d-none");
                    }
                }

                if (isServiceRequest === false) {
                    if (document.querySelector('#tabButton_ServiceRequest') !== null) {
                        document.querySelector('#tabButton_ServiceRequest').classList.add("d-none");
                        document.querySelector('#tabButton_ServiceRequest_parent').classList.add("d-none");
                    }

                    if (document.querySelector('#tabArea_ServiceRequest') !== null) {
                        document.querySelector('#tabArea_ServiceRequest').classList.add("d-none");
                    }
                }
            }
            else {
                if (getConfigurationByBoolen("ALLOW_FLIGHT") === false && isFlight === false) {
                    if (document.querySelector('#tabButton_Flight') !== null) {
                        document.querySelector('#tabButton_Flight').classList.add("d-none");
                        document.querySelector('#tabButton_Flight_parent').classList.add("d-none");
                    }

                    if (document.querySelector('#tabArea_Flight') !== null) {
                        document.querySelector('#tabArea_Flight').classList.add("d-none");
                    }
                }

                if (getConfigurationByBoolen("ALLOW_HOTEL") === false && isHotel === false) {
                    if (document.querySelector('#tabButton_Hotel') !== null) {
                        document.querySelector('#tabButton_Hotel').classList.add("d-none");
                        document.querySelector('#tabButton_Hotel_parent').classList.add("d-none");
                    }

                    if (document.querySelector('#tabArea_Hotel') !== null) {
                        document.querySelector('#tabArea_Hotel').classList.add("d-none");
                    }
                }

                if (getConfigurationByBoolen("ALLOW_SERVICE_REQUEST") === false && isServiceRequest === false) {
                    if (document.querySelector('#tabButton_ServiceRequest') !== null) {
                        document.querySelector('#tabButton_ServiceRequest').classList.add("d-none");
                        document.querySelector('#tabButton_ServiceRequest_parent').classList.add("d-none");
                    }

                    if (document.querySelector('#tabArea_ServiceRequest') !== null) {
                        document.querySelector('#tabArea_ServiceRequest').classList.add("d-none");
                    }
                }
            }

            if (tripReasonList.length > 0) {
                if (tripReasonList[0].meetingID === "1") {
                    if (document.querySelector('#tabButton_MeetingDetails') !== null) {
                        document.querySelector('#tabButton_MeetingDetails').classList.add("d-none");
                    }

                    if (document.querySelector('#tabArea_MeetingDetails') !== null) {
                        document.querySelector('#tabArea_MeetingDetails').classList.add("d-none");
                    }
                }

                if (tripReasonList.length > 0 && (tripReasonList[0].meetingID === "1" || tripReasonList[0].meetingID === "3")) {
                    let inputs = document.querySelectorAll('.meeting');
                    for (let i = 0; i < inputs.length; i++) {
                        if (document.querySelector('#' + inputs[i].getAttribute("id")) !== null) {
                            document.querySelector('#' + inputs[i].getAttribute("id")).innerHTML = "";
                        }
                    }
                }
                else {
                    let inputs = document.querySelectorAll('.meeting');
                    for (let i = 0; i < inputs.length; i++) {
                        if (document.querySelector('#' + inputs[i].getAttribute("id")) !== null) {
                            document.querySelector('#' + inputs[i].getAttribute("id")).innerHTML = " *";
                        }
                    }
                }
            }

            if (valueParam.service !== undefined && valueParam.service !== '' && valueParam.service === 'flight') {
                onTabChangeHandler('Flight');

                if (document.querySelector('#tabButton_Flight') !== null) {
                    document.querySelector('#tabButton_Flight').classList.add("active");
                }
                if (document.querySelector('#tabArea_Flight') !== null) {
                    document.querySelector('#tabArea_Flight').classList.add("active");
                }
                if (document.querySelector('#tabArea_Flight') !== null) {
                    if (!document.querySelector('#tabArea_Flight').classList.toString().includes('show')) {
                        document.querySelector('#tabArea_Flight').classList.add("show");
                    }
                }
                if (document.querySelector('#tabButton_Hotel') !== null) {
                    document.querySelector('#tabButton_Hotel').classList.remove("active");
                }
                if (document.querySelector('#tabArea_Hotel') !== null) {
                    document.querySelector('#tabArea_Hotel').classList.remove("active");
                }
                if (document.querySelector('#tabButton_ServiceRequest') !== null) {
                    document.querySelector('#tabButton_ServiceRequest').classList.remove("active");
                }
                if (document.querySelector('#tabArea_ServiceRequest') !== null) {
                    document.querySelector('#tabArea_ServiceRequest').classList.remove("active");
                }
                if (document.querySelector('#tabButton_Approver') !== null) {
                    document.querySelector('#tabButton_Approver').classList.remove("active");
                }
                if (document.querySelector('#tabArea_Approver') !== null) {
                    document.querySelector('#tabArea_Approver').classList.remove("active");
                }
                if (document.querySelector('#tabButton_Document') !== null) {
                    document.querySelector('#tabButton_Document').classList.remove("active");
                }
                if (document.querySelector('#tabArea_Document') !== null) {
                    document.querySelector('#tabArea_Document').classList.remove("active");
                }
                if (document.querySelector('#tabButton_MeetingDetails') !== null) {
                    document.querySelector('#tabButton_MeetingDetails').classList.remove("active");
                }
                if (document.querySelector('#tabArea_MeetingDetails') !== null) {
                    document.querySelector('#tabArea_MeetingDetails').classList.remove("active");
                }
            }
            else if (valueParam.service !== undefined && valueParam.service !== '' && valueParam.service === 'hotel') {
                onTabChangeHandler('Hotel');

                if (document.querySelector('#tabButton_Flight') !== null) {
                    document.querySelector('#tabButton_Flight').classList.remove("active");
                }
                if (document.querySelector('#tabArea_Flight') !== null) {
                    document.querySelector('#tabArea_Flight').classList.remove("active");
                }
                if (document.querySelector('#tabButton_Hotel') !== null) {
                    document.querySelector('#tabButton_Hotel').classList.add("active");
                }
                if (document.querySelector('#tabArea_Hotel') !== null) {
                    document.querySelector('#tabArea_Hotel').classList.add("active");
                }
                if (document.querySelector('#tabArea_Hotel') !== null) {
                    if (!document.querySelector('#tabArea_Hotel').classList.toString().includes('show')) {
                        document.querySelector('#tabArea_Hotel').classList.add("show");
                    }
                }
                if (document.querySelector('#tabButton_ServiceRequest') !== null) {
                    document.querySelector('#tabButton_ServiceRequest').classList.remove("active");
                }
                if (document.querySelector('#tabArea_ServiceRequest') !== null) {
                    document.querySelector('#tabArea_ServiceRequest').classList.remove("active");
                }
                if (document.querySelector('#tabButton_Approver') !== null) {
                    document.querySelector('#tabButton_Approver').classList.remove("active");
                }
                if (document.querySelector('#tabArea_Approver') !== null) {
                    document.querySelector('#tabArea_Approver').classList.remove("active");
                }
                if (document.querySelector('#tabButton_Document') !== null) {
                    document.querySelector('#tabButton_Document').classList.remove("active");
                }
                if (document.querySelector('#tabArea_Document') !== null) {
                    document.querySelector('#tabArea_Document').classList.remove("active");
                }
                if (document.querySelector('#tabButton_MeetingDetails') !== null) {
                    document.querySelector('#tabButton_MeetingDetails').classList.remove("active");
                }
                if (document.querySelector('#tabArea_MeetingDetails') !== null) {
                    document.querySelector('#tabArea_MeetingDetails').classList.remove("active");
                }
            }
            else if (valueParam.service !== undefined && valueParam.service !== '' && valueParam.service === 'servicerequest') {
                onTabChangeHandler('ServiceRequest');

                if (document.querySelector('#tabButton_Flight') !== null) {
                    document.querySelector('#tabButton_Flight').classList.remove("active");
                }
                if (document.querySelector('#tabArea_Flight') !== null) {
                    document.querySelector('#tabArea_Flight').classList.remove("active");
                }
                if (document.querySelector('#tabButton_Hotel') !== null) {
                    document.querySelector('#tabButton_Hotel').classList.remove("active");
                }
                if (document.querySelector('#tabArea_Hotel') !== null) {
                    document.querySelector('#tabArea_Hotel').classList.remove("active");
                }
                if (document.querySelector('#tabButton_ServiceRequest') !== null) {
                    document.querySelector('#tabButton_ServiceRequest').classList.add("active");
                }
                if (document.querySelector('#tabArea_ServiceRequest') !== null) {
                    document.querySelector('#tabArea_ServiceRequest').classList.add("active");
                }
                if (document.querySelector('#tabArea_ServiceRequest') !== null) {
                    if (!document.querySelector('#tabArea_ServiceRequest').classList.toString().includes('show')) {
                        document.querySelector('#tabArea_ServiceRequest').classList.add("show");
                    }
                }
                if (document.querySelector('#tabButton_Approver') !== null) {
                    document.querySelector('#tabButton_Approver').classList.remove("active");
                }
                if (document.querySelector('#tabArea_Approver') !== null) {
                    document.querySelector('#tabArea_Approver').classList.remove("active");
                }
                if (document.querySelector('#tabButton_Document') !== null) {
                    document.querySelector('#tabButton_Document').classList.remove("active");
                }
                if (document.querySelector('#tabArea_Document') !== null) {
                    document.querySelector('#tabArea_Document').classList.remove("active");
                }
                if (document.querySelector('#tabButton_MeetingDetails') !== null) {
                    document.querySelector('#tabButton_MeetingDetails').classList.remove("active");
                }
                if (document.querySelector('#tabArea_MeetingDetails') !== null) {
                    document.querySelector('#tabArea_MeetingDetails').classList.remove("active");
                }
            }
            else {
                if (selectedTab !== undefined && selectedTab !== '') {
                    if (selectedTab === 'Flight') {
                        onTabChangeHandler('Approver');

                        if (document.querySelector('#tabButton_Flight') !== null) {
                            document.querySelector('#tabButton_Flight').classList.remove("active");
                        }
                        if (document.querySelector('#tabArea_Flight') !== null) {
                            document.querySelector('#tabArea_Flight').classList.remove("active");
                        }
                        if (document.querySelector('#tabButton_Hotel') !== null) {
                            document.querySelector('#tabButton_Hotel').classList.remove("active");
                        }
                        if (document.querySelector('#tabArea_Hotel') !== null) {
                            document.querySelector('#tabArea_Hotel').classList.remove("active");
                        }
                        if (document.querySelector('#tabButton_ServiceRequest') !== null) {
                            document.querySelector('#tabButton_ServiceRequest').classList.remove("active");
                        }
                        if (document.querySelector('#tabArea_ServiceRequest') !== null) {
                            document.querySelector('#tabArea_ServiceRequest').classList.remove("active");
                        }
                        if (document.querySelector('#tabButton_Approver') !== null) {
                            document.querySelector('#tabButton_Approver').classList.add("active");
                        }
                        if (document.querySelector('#tabArea_Approver') !== null) {
                            document.querySelector('#tabArea_Approver').classList.add("active");
                        }
                        if (document.querySelector('#tabArea_Approver') !== null) {
                            if (!document.querySelector('#tabArea_Approver').classList.toString().includes('show')) {
                                document.querySelector('#tabArea_Approver').classList.add("show");
                            }
                        }
                        if (document.querySelector('#tabButton_Document') !== null) {
                            document.querySelector('#tabButton_Document').classList.remove("active");
                        }
                        if (document.querySelector('#tabArea_Document') !== null) {
                            document.querySelector('#tabArea_Document').classList.remove("active");
                        }
                        if (document.querySelector('#tabButton_MeetingDetails') !== null) {
                            document.querySelector('#tabButton_MeetingDetails').classList.remove("active");
                        }
                        if (document.querySelector('#tabArea_MeetingDetails') !== null) {
                            document.querySelector('#tabArea_MeetingDetails').classList.remove("active");
                        }
                    }
                    else {
                        onTabChangeHandler(selectedTab);

                        if (document.querySelector('#tabButton_Flight') !== null) {
                            document.querySelector('#tabButton_Flight').classList.remove("active");
                        }
                        if (document.querySelector('#tabArea_Flight') !== null) {
                            document.querySelector('#tabArea_Flight').classList.remove("active");
                        }
                        if (document.querySelector('#tabButton_Hotel') !== null) {
                            document.querySelector('#tabButton_Hotel').classList.remove("active");
                        }
                        if (document.querySelector('#tabArea_Hotel') !== null) {
                            document.querySelector('#tabArea_Hotel').classList.remove("active");
                        }
                        if (document.querySelector('#tabButton_ServiceRequest') !== null) {
                            document.querySelector('#tabButton_ServiceRequest').classList.remove("active");
                        }
                        if (document.querySelector('#tabArea_ServiceRequest') !== null) {
                            document.querySelector('#tabArea_ServiceRequest').classList.remove("active");
                        }
                        if (document.querySelector('#tabButton_Document') !== null) {
                            document.querySelector('#tabButton_Document').classList.remove("active");
                        }
                        if (document.querySelector('#tabArea_Document') !== null) {
                            document.querySelector('#tabArea_Document').classList.remove("active");
                        }
                        if (document.querySelector('#tabButton_MeetingDetails') !== null) {
                            document.querySelector('#tabButton_MeetingDetails').classList.remove("active");
                        }
                        if (document.querySelector('#tabArea_MeetingDetails') !== null) {
                            document.querySelector('#tabArea_MeetingDetails').classList.remove("active");
                        }
                        if (document.querySelector('#tabButton_' + selectedTab) !== null) {
                            document.querySelector('#tabButton_' + selectedTab).classList.add("active");
                        }
                        if (document.querySelector('#tabArea_' + selectedTab) !== null) {
                            document.querySelector('#tabArea_' + selectedTab).classList.add("active");
                        }
                        if (document.querySelector('#tabArea_' + selectedTab) !== null) {
                            if (!document.querySelector('#tabArea_' + selectedTab).classList.toString().includes('show')) {
                                document.querySelector('#tabArea_' + selectedTab).classList.add("show");
                            }
                        }
                    }
                }
                else {
                    onTabChangeHandler('Approver');

                    if (document.querySelector('#tabButton_Flight') !== null) {
                        document.querySelector('#tabButton_Flight').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_Flight') !== null) {
                        document.querySelector('#tabArea_Flight').classList.remove("active");
                    }
                    if (document.querySelector('#tabButton_Hotel') !== null) {
                        document.querySelector('#tabButton_Hotel').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_Hotel') !== null) {
                        document.querySelector('#tabArea_Hotel').classList.remove("active");
                    }
                    if (document.querySelector('#tabButton_ServiceRequest') !== null) {
                        document.querySelector('#tabButton_ServiceRequest').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_ServiceRequest') !== null) {
                        document.querySelector('#tabArea_ServiceRequest').classList.remove("active");
                    }
                    if (document.querySelector('#tabButton_Approver') !== null) {
                        document.querySelector('#tabButton_Approver').classList.add("active");
                    }
                    if (document.querySelector('#tabArea_Approver') !== null) {
                        document.querySelector('#tabArea_Approver').classList.add("active");
                    }
                    if (document.querySelector('#tabArea_Approver') !== null) {
                        if (!document.querySelector('#tabArea_Approver').classList.toString().includes('show')) {
                            document.querySelector('#tabArea_Approver').classList.add("show");
                        }
                    }
                    if (document.querySelector('#tabButton_Document') !== null) {
                        document.querySelector('#tabButton_Document').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_Document') !== null) {
                        document.querySelector('#tabArea_Document').classList.remove("active");
                    }
                    if (document.querySelector('#tabButton_MeetingDetails') !== null) {
                        document.querySelector('#tabButton_MeetingDetails').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_MeetingDetails') !== null) {
                        document.querySelector('#tabArea_MeetingDetails').classList.remove("active");
                    }
                }
            }
        }
        else {
            let travelRequestFieldsDataJson = "";
            if (tripReasonOptions !== undefined && tripReasonOptions[0] !== undefined) {
                setTripReasonValue(tripReasonValue !== '' ? tripReasonValue : tripReasonOptions[0].value);
                sessionStorage.removeItem("spnClass1");

                if (sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
                    travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
                    travelRequestFieldsDataJson.tripReason = tripReasonValue !== '' ? tripReasonValue : tripReasonOptions[0].value;
                    sessionStorage.setItem("travelRequestFieldsDataJson", JSON.stringify(travelRequestFieldsDataJson));
                }
            }

            let searchPolicyResponse = "";
            let tripReasonAndPolicyDataJson = "";

            if (localStorage.getItem("loginData") !== null) {
                tripReasonAndPolicyDataJson = JSON.parse(localStorage.getItem("loginData"));
                tripReasonList = tripReasonAndPolicyDataJson.tripReason.filter(a => a.id === travelRequestFieldsDataJson.tripReason);
            }

            if (localStorage.getItem("loginData") !== null && tripReasonAndPolicyDataJson.isTravelPolicy === false) {
                let searchPolicyResponseTemp = JSON.parse(localStorage.getItem("loginData"));
                searchPolicyResponse = searchPolicyResponseTemp.policy.filter(a => a.tripReasonID === travelRequestFieldsDataJson.tripReason);
            }

            let isFlight = false;
            let isHotel = false;
            let isServiceRequest = false;

            searchPolicyResponse.length > 0 && searchPolicyResponse.forEach(elementPolicy => {
                if (checkSecurityDetails("Book", "Flight") && checkSecurityDetails("Book", "Flight", "Book_Flight_Search") && getConfigurationByBoolen("ALLOW_FLIGHT") === true && elementPolicy.isFlight === true) {
                    isFlight = true;
                }
                if (checkSecurityDetails("Book", "Hotel") && checkSecurityDetails("Book", "Hotel", "Book_Hotel_Search") && getConfigurationByBoolen("ALLOW_HOTEL") && elementPolicy.isHotel === true) {
                    isHotel = true;
                }
                if (getConfigurationByBoolen("ALLOW_SERVICE_REQUEST") && elementPolicy.isServiceRequest === true) {
                    isServiceRequest = true;
                }
            });

            if (searchPolicyResponse.length > 0) {
                if (isFlight === false) {
                    if (document.querySelector('#tabButton_Flight') !== null) {
                        document.querySelector('#tabButton_Flight').classList.add("d-none");
                        document.querySelector('#tabButton_Flight_parent').classList.add("d-none");
                    }

                    if (document.querySelector('#tabArea_Flight') !== null) {
                        document.querySelector('#tabArea_Flight').classList.add("d-none");
                    }
                }

                if (isHotel === false) {
                    if (document.querySelector('#tabButton_Hotel') !== null) {
                        document.querySelector('#tabButton_Hotel').classList.add("d-none");
                        document.querySelector('#tabButton_Hotel_parent').classList.add("d-none");
                    }

                    if (document.querySelector('#tabArea_Hotel') !== null) {
                        document.querySelector('#tabArea_Hotel').classList.add("d-none");
                    }
                }

                if (isServiceRequest === false) {
                    if (document.querySelector('#tabButton_ServiceRequest') !== null) {
                        document.querySelector('#tabButton_ServiceRequest').classList.add("d-none");
                        document.querySelector('#tabButton_ServiceRequest_parent').classList.add("d-none");
                    }

                    if (document.querySelector('#tabArea_ServiceRequest') !== null) {
                        document.querySelector('#tabArea_ServiceRequest').classList.add("d-none");
                    }
                }
            }
            else {
                if (getConfigurationByBoolen("ALLOW_FLIGHT") === false && isFlight === false) {
                    if (document.querySelector('#tabButton_Flight') !== null) {
                        document.querySelector('#tabButton_Flight').classList.add("d-none");
                        document.querySelector('#tabButton_Flight_parent').classList.add("d-none");
                    }

                    if (document.querySelector('#tabArea_Flight') !== null) {
                        document.querySelector('#tabArea_Flight').classList.add("d-none");
                    }
                }

                if (getConfigurationByBoolen("ALLOW_HOTEL") === false && isHotel === false) {
                    if (document.querySelector('#tabButton_Hotel') !== null) {
                        document.querySelector('#tabButton_Hotel').classList.add("d-none");
                        document.querySelector('#tabButton_Hotel_parent').classList.add("d-none");
                    }

                    if (document.querySelector('#tabArea_Hotel') !== null) {
                        document.querySelector('#tabArea_Hotel').classList.add("d-none");
                    }
                }

                if (getConfigurationByBoolen("ALLOW_SERVICE_REQUEST") === false && isServiceRequest === false) {
                    if (document.querySelector('#tabButton_ServiceRequest') !== null) {
                        document.querySelector('#tabButton_ServiceRequest').classList.add("d-none");
                        document.querySelector('#tabButton_ServiceRequest_parent').classList.add("d-none");
                    }

                    if (document.querySelector('#tabArea_ServiceRequest') !== null) {
                        document.querySelector('#tabArea_ServiceRequest').classList.add("d-none");
                    }
                }
            }

            if (tripReasonList.length > 0) {
                if (tripReasonList[0].meetingID === "1") {
                    if (document.querySelector('#tabButton_MeetingDetails') !== null) {
                        document.querySelector('#tabButton_MeetingDetails').classList.add("d-none");
                    }

                    if (document.querySelector('#tabArea_MeetingDetails') !== null) {
                        document.querySelector('#tabArea_MeetingDetails').classList.add("d-none");
                    }
                }

                if (tripReasonList.length > 0 && (tripReasonList[0].meetingID === "1" || tripReasonList[0].meetingID === "3")) {
                    let inputs = document.querySelectorAll('.meeting');
                    for (let i = 0; i < inputs.length; i++) {
                        if (document.querySelector('#' + inputs[i].getAttribute("id")) !== null) {
                            document.querySelector('#' + inputs[i].getAttribute("id")).innerHTML = "";
                        }
                    }
                }
                else {
                    let inputs = document.querySelectorAll('.meeting');
                    for (let i = 0; i < inputs.length; i++) {
                        if (document.querySelector('#' + inputs[i].getAttribute("id")) !== null) {
                            document.querySelector('#' + inputs[i].getAttribute("id")).innerHTML = " *";
                        }
                    }
                }
            }

            if (searchPolicyResponse.length > 0) {
                if (getConfigurationByBoolen("ALLOW_FLIGHT") === true && isFlight) {
                    onTabChangeHandler('Flight');

                    if (document.querySelector('#tabButton_Flight') !== null) {
                        document.querySelector('#tabButton_Flight').classList.add("active");
                    }
                    if (document.querySelector('#tabArea_Flight') !== null) {
                        document.querySelector('#tabArea_Flight').classList.add("active");
                    }
                    if (document.querySelector('#tabArea_Flight') !== null) {
                        if (!document.querySelector('#tabArea_Flight').classList.toString().includes('show')) {
                            document.querySelector('#tabArea_Flight').classList.add("show");
                        }
                    }
                    if (document.querySelector('#tabButton_Hotel') !== null) {
                        document.querySelector('#tabButton_Hotel').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_Hotel') !== null) {
                        document.querySelector('#tabArea_Hotel').classList.remove("active");
                    }
                    if (document.querySelector('#tabButton_ServiceRequest') !== null) {
                        document.querySelector('#tabButton_ServiceRequest').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_ServiceRequest') !== null) {
                        document.querySelector('#tabArea_ServiceRequest').classList.remove("active");
                    }
                    if (document.querySelector('#tabButton_Approver') !== null) {
                        document.querySelector('#tabButton_Approver').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_Approver') !== null) {
                        document.querySelector('#tabArea_Approver').classList.remove("active");
                    }
                    if (document.querySelector('#tabButton_Document') !== null) {
                        document.querySelector('#tabButton_Document').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_Document') !== null) {
                        document.querySelector('#tabArea_Document').classList.remove("active");
                    }
                    if (document.querySelector('#tabButton_MeetingDetails') !== null) {
                        document.querySelector('#tabButton_MeetingDetails').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_MeetingDetails') !== null) {
                        document.querySelector('#tabArea_MeetingDetails').classList.remove("active");
                    }
                } else if (getConfigurationByBoolen("ALLOW_HOTEL") && isHotel) {
                    onTabChangeHandler('Hotel');

                    if (document.querySelector('#tabButton_Flight') !== null) {
                        document.querySelector('#tabButton_Flight').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_Flight') !== null) {
                        document.querySelector('#tabArea_Flight').classList.remove("active");
                    }
                    if (document.querySelector('#tabButton_Hotel') !== null) {
                        document.querySelector('#tabButton_Hotel').classList.add("active");
                    }
                    if (document.querySelector('#tabArea_Hotel') !== null) {
                        document.querySelector('#tabArea_Hotel').classList.add("active");
                    }
                    if (document.querySelector('#tabArea_Hotel') !== null) {
                        if (!document.querySelector('#tabArea_Hotel').classList.toString().includes('show')) {
                            document.querySelector('#tabArea_Hotel').classList.add("show");
                        }
                    }
                    if (document.querySelector('#tabButton_ServiceRequest') !== null) {
                        document.querySelector('#tabButton_ServiceRequest').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_ServiceRequest') !== null) {
                        document.querySelector('#tabArea_ServiceRequest').classList.remove("active");
                    }
                    if (document.querySelector('#tabButton_Approver') !== null) {
                        document.querySelector('#tabButton_Approver').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_Approver') !== null) {
                        document.querySelector('#tabArea_Approver').classList.remove("active");
                    }
                    if (document.querySelector('#tabButton_Document') !== null) {
                        document.querySelector('#tabButton_Document').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_Document') !== null) {
                        document.querySelector('#tabArea_Document').classList.remove("active");
                    }
                    if (document.querySelector('#tabButton_MeetingDetails') !== null) {
                        document.querySelector('#tabButton_MeetingDetails').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_MeetingDetails') !== null) {
                        document.querySelector('#tabArea_MeetingDetails').classList.remove("active");
                    }
                } else if (getConfigurationByBoolen("ALLOW_SERVICE_REQUEST") && isServiceRequest) {
                    onTabChangeHandler('ServiceRequest');

                    if (document.querySelector('#tabButton_Flight') !== null) {
                        document.querySelector('#tabButton_Flight').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_Flight') !== null) {
                        document.querySelector('#tabArea_Flight').classList.remove("active");
                    }
                    if (document.querySelector('#tabButton_Hotel') !== null) {
                        document.querySelector('#tabButton_Hotel').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_Hotel') !== null) {
                        document.querySelector('#tabArea_Hotel').classList.remove("active");
                    }
                    if (document.querySelector('#tabButton_ServiceRequest') !== null) {
                        document.querySelector('#tabButton_ServiceRequest').classList.add("active");
                    }
                    if (document.querySelector('#tabArea_ServiceRequest') !== null) {
                        document.querySelector('#tabArea_ServiceRequest').classList.add("active");
                    }
                    if (document.querySelector('#tabArea_ServiceRequest') !== null) {
                        if (!document.querySelector('#tabArea_ServiceRequest').classList.toString().includes('show')) {
                            document.querySelector('#tabArea_ServiceRequest').classList.add("show");
                        }
                    }
                    if (document.querySelector('#tabButton_Approver') !== null) {
                        document.querySelector('#tabButton_Approver').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_Approver') !== null) {
                        document.querySelector('#tabArea_Approver').classList.remove("active");
                    }
                    if (document.querySelector('#tabButton_Document') !== null) {
                        document.querySelector('#tabButton_Document').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_Document') !== null) {
                        document.querySelector('#tabArea_Document').classList.remove("active");
                    }
                    if (document.querySelector('#tabButton_MeetingDetails') !== null) {
                        document.querySelector('#tabButton_MeetingDetails').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_MeetingDetails') !== null) {
                        document.querySelector('#tabArea_MeetingDetails').classList.remove("active");
                    }
                }
                else {
                    onTabChangeHandler('Flight');

                    if (document.querySelector('#tabButton_Flight') !== null) {
                        document.querySelector('#tabButton_Flight').classList.add("active");
                    }
                    if (document.querySelector('#tabArea_Flight') !== null) {
                        document.querySelector('#tabArea_Flight').classList.add("active");
                    }
                    if (document.querySelector('#tabArea_Flight') !== null) {
                        if (!document.querySelector('#tabArea_Flight').classList.toString().includes('show')) {
                            document.querySelector('#tabArea_Flight').classList.add("show");
                        }
                    }
                    if (document.querySelector('#tabButton_Hotel') !== null) {
                        document.querySelector('#tabButton_Hotel').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_Hotel') !== null) {
                        document.querySelector('#tabArea_Hotel').classList.remove("active");
                    }
                    if (document.querySelector('#tabButton_ServiceRequest') !== null) {
                        document.querySelector('#tabButton_ServiceRequest').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_ServiceRequest') !== null) {
                        document.querySelector('#tabArea_ServiceRequest').classList.remove("active");
                    }
                    if (document.querySelector('#tabButton_Approver') !== null) {
                        document.querySelector('#tabButton_Approver').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_Approver') !== null) {
                        document.querySelector('#tabArea_Approver').classList.remove("active");
                    }
                    if (document.querySelector('#tabButton_Document') !== null) {
                        document.querySelector('#tabButton_Document').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_Document') !== null) {
                        document.querySelector('#tabArea_Document').classList.remove("active");
                    }
                    if (document.querySelector('#tabButton_MeetingDetails') !== null) {
                        document.querySelector('#tabButton_MeetingDetails').classList.remove("active");
                    }
                    if (document.querySelector('#tabArea_MeetingDetails') !== null) {
                        document.querySelector('#tabArea_MeetingDetails').classList.remove("active");
                    }
                }
            }
            else {
                onTabChangeHandler('Flight');

                if (document.querySelector('#tabButton_Flight') !== null) {
                    document.querySelector('#tabButton_Flight').classList.add("active");
                }
                if (document.querySelector('#tabArea_Flight') !== null) {
                    document.querySelector('#tabArea_Flight').classList.add("active");
                }
                if (document.querySelector('#tabArea_Flight') !== null) {
                    if (!document.querySelector('#tabArea_Flight').classList.toString().includes('show')) {
                        document.querySelector('#tabArea_Flight').classList.add("show");
                    }
                }
                if (document.querySelector('#tabButton_Hotel') !== null) {
                    document.querySelector('#tabButton_Hotel').classList.remove("active");
                }
                if (document.querySelector('#tabArea_Hotel') !== null) {
                    document.querySelector('#tabArea_Hotel').classList.remove("active");
                }
                if (document.querySelector('#tabButton_ServiceRequest') !== null) {
                    document.querySelector('#tabButton_ServiceRequest').classList.remove("active");
                }
                if (document.querySelector('#tabArea_ServiceRequest') !== null) {
                    document.querySelector('#tabArea_ServiceRequest').classList.remove("active");
                }
                if (document.querySelector('#tabButton_Approver') !== null) {
                    document.querySelector('#tabButton_Approver').classList.remove("active");
                }
                if (document.querySelector('#tabArea_Approver') !== null) {
                    document.querySelector('#tabArea_Approver').classList.remove("active");
                }
                if (document.querySelector('#tabButton_Document') !== null) {
                    document.querySelector('#tabButton_Document').classList.remove("active");
                }
                if (document.querySelector('#tabArea_Document') !== null) {
                    document.querySelector('#tabArea_Document').classList.remove("active");
                }
                if (document.querySelector('#tabButton_MeetingDetails') !== null) {
                    document.querySelector('#tabButton_MeetingDetails').classList.remove("active");
                }
                if (document.querySelector('#tabArea_MeetingDetails') !== null) {
                    document.querySelector('#tabArea_MeetingDetails').classList.remove("active");
                }
            }
        }

        let bookingJSON = JSON.parse(localStorage.getItem("BookingData"));
        bookingJSON.data.airItinerary[0].airItineraryPricingInfo = loadedPriceCheckResponse.data.airItinerary[0].airItineraryPricingInfo;
        localStorage.setItem("BookingData", JSON.stringify(bookingJSON))

        if (localStorage.getItem("AddHotel") !== null && localStorage.getItem("AddHotel") === "1") {
            history.push('/SearchHotel?addhotel=1');
        }
        else {
            history.push(BOOKING);
        }
    };

    function cancelHandler() {
        setConfirm(false);
        if (valueParam.return !== undefined && valueParam.return !== '' && valueParam.return === '1') {
            history.push({
                pathname: location.pathname,
                search: '?return=1&service=' + valueParam.service
            })
        }
        else {
            history.push({
                pathname: location.pathname
            })
        }
        onTabChangeHandler('Flight');
    };

    function toggleTripReason() {
        let isFlightRequestPresent = false;
        let isHotelRequestPresent = false;
        let isServiceRequestPresent = false;

        if (sessionStorage.getItem("travelreqflightsearch")) {
            isFlightRequestPresent = true;
        }

        if (sessionStorage.getItem("travelreqhotelsearch")) {
            isHotelRequestPresent = true;
        }

        if (sessionStorage.getItem("travelRequestFieldsDataJson")) {
            let travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
            if (travelRequestFieldsDataJson.serviceRequests && travelRequestFieldsDataJson.serviceRequests.length) {
                isServiceRequestPresent = true;
            }
        }

        if (isFlightRequestPresent || isHotelRequestPresent || isServiceRequestPresent) {
            setTripTypeDisabled(true);
        }
        else {
            setTripTypeDisabled(false);
        }
    }

    useEffect(() => {
        setTimeout(() => {
            if (valueParam.return !== undefined && valueParam.return !== '' && valueParam.return === '1') {
                if (localStorage.getItem('BookingDataTravelRequest') !== null || localStorage.getItem('TravelRequestInput') !== null) {
                    toggleTripReason();
                    window.scrollTo({
                        top: 500,
                        behavior: "smooth",
                    });
                }
            }
        }, 1000);
    }, []);

    return (
        <Fragment>
            {error1 && (
                <ErrorModal
                    title={error1.title}
                    message={error1.message}
                    onConfirm={errorHandler}
                ></ErrorModal>
            )}
            {confirm && (
                <ConfirmationModal
                    title={confirm.title}
                    message={confirm.message}
                    onOk={okHandler}
                    onCancel={cancelHandler}
                ></ConfirmationModal>
            )}
            {/*     <!-- Common Banner Area --> */}
            <section id="common_banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">

                        </div>
                    </div>
                </div>
            </section>
            {(statusTravelRequest === 'pending' || status === 'pending') &&
                <section id="theme_search_form_tour1" className="fligth_top_search_main_form_wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="theme_search_form_area mt-5">
                                    <div className="row  position-relative gt-h-255 my-auto">
                                        <BookingLoadingSpinner action='import'></BookingLoadingSpinner>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }
            {/*   <!-- Form Area --> */}
            {
                (statusTravelRequest !== 'pending' && status !== 'pending') &&
                <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="theme_search_form_area position-relative my-2">
                                    <div className="row position-relative">
                                        <div className="col-lg-12">
                                            <label htmlFor="" className="h5 text-primary mb-3 col-lg-12"><i className="fas fa-globe-asia"></i> Travel Request</label>
                                            <div className="tour_search_form">
                                                <form action="!#">
                                                    <div className='row mb-4'>
                                                        <div className='col-md-4 col-sm-12 col-12'>
                                                            {
                                                                tripReasonValue !== undefined && tripReasonValue !== null && tripReasonValue !== '' &&
                                                                <>
                                                                    <label class="h6 text-primary mb-2 col-lg-12" for="tripReason">Trip Type<span className="error-icon"> *</span></label>
                                                                    <div className="dropdown pb-2 col-md-12 position-relative">
                                                                        <Dropdown onChange={tripReasonChangeHandler}
                                                                            value={tripReasonOptions.filter(option => option.value === tripReasonValue)}
                                                                            options={tripReasonOptions}
                                                                            textField="value"
                                                                            dataItemKey="label"
                                                                            arrowClosed={<span className="arrow-closed" />}
                                                                            arrowOpen={<span className="arrow-open" />} controlClassName='mt-1 form-control control dropdown-search form-select bg_input mb-0'
                                                                            isDisabled={tripTypeDisabled}
                                                                        />
                                                                        {tripReasonInputHasError &&
                                                                            <p className="error-text">{'Trip Type is required.'}</p>
                                                                        }
                                                                    </div>
                                                                </>
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-12 pop-ups-tab-border-parent">
                                                            <ul className="nav nav-pills pop-ups-tab-border" id="myTab" role="tablist">
                                                                <li className="nav-item pb-0" role="presentation" id='tabButton_Flight_parent'>
                                                                    <button className="tabs-btn nav-link tab-width-150 active text-center" id='tabButton_Flight' data-bs-toggle="tab"
                                                                        data-bs-target='#tabArea_Flight' type="button" role="tab" aria-controls='#tabArea_Flight'
                                                                        aria-selected="true" onClick={(e) => onTabChangeHandler('Flight')}><i class="fas fa-plane-departure"></i><br></br> Flight</button>
                                                                </li>
                                                                <li className="nav-item pb-0" role="presentation" id='tabButton_Hotel_parent'>
                                                                    <button className="tabs-btn nav-link text-center tab-width-150" id='tabButton_Hotel' data-bs-toggle="tab"
                                                                        data-bs-target='#tabArea_Hotel' type="button" role="tab" aria-controls='#tabArea_Hotel'
                                                                        aria-selected="true" onClick={(e) => onTabChangeHandler('Hotel')}><i class="fas fa-hotel"></i><br></br> Hotel</button>
                                                                </li>
                                                                <li className="nav-item pb-0" role="presentation" id='tabButton_ServiceRequest_parent'>
                                                                    <button className="tabs-btn nav-link text-center tab-width-150" id='tabButton_ServiceRequest' data-bs-toggle="tab"
                                                                        data-bs-target='#tabArea_ServiceRequest' type="button" role="tab" aria-controls='#tabArea_ServiceRequest'
                                                                        aria-selected="true" onClick={(e) => onTabChangeHandler('ServiceRequest')}><i class="fas fa-cog"></i><br></br> Service Request </button>
                                                                </li>
                                                                <li className="nav-item pb-0" role="presentation">
                                                                    <button className="tabs-btn nav-link text-center tab-width-150" id='tabButton_Approver' data-bs-toggle="tab"
                                                                        data-bs-target='#tabArea_Approver' type="button" role="tab" aria-controls='#tabArea_Approver'
                                                                        aria-selected="true" onClick={(e) => onTabChangeHandler('Approver')}><i class="fas fa-user"></i><br></br> Approver</button>
                                                                </li>
                                                                <li className="nav-item pb-0" role="presentation">
                                                                    <button className="tabs-btn nav-link text-center tab-width-150" id='tabButton_Document' data-bs-toggle="tab"
                                                                        data-bs-target='#tabArea_Document' type="button" role="tab" aria-controls='#tabArea_Document'
                                                                        aria-selected="true" onClick={(e) => onTabChangeHandler('Document')}><i class="fas fa-file"></i><br></br> Documents</button>
                                                                </li>
                                                                <li className="nav-item pb-0" role="presentation">
                                                                    <button className="tabs-btn nav-link text-center tab-width-150" id='tabButton_MeetingDetails' data-bs-toggle="tab"
                                                                        data-bs-target='#tabArea_MeetingDetails' type="button" role="tab" aria-controls='#tabArea_MeetingDetails'
                                                                        aria-selected="true" onClick={(e) => onTabChangeHandler('MeetingDetails')}><i class="fas fa-handshake"></i><br></br> Meeting Details</button>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="col-md-12 ">
                                                            <div className="px-3 tab-content include-exclude-tab">
                                                                <div className="tab-pane fade show active" id='tabArea_Flight' role="tabpanel" aria-labelledby="departure">
                                                                    <div className=''>
                                                                        <div className=' position-relative'>
                                                                            <div className='row mt-3'>
                                                                                <SearchFlight></SearchFlight>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="tab-pane fade show" id='tabArea_Hotel' role="tabpanel" aria-labelledby="departure">
                                                                    <div className=''>
                                                                        <div className=' position-relative'>
                                                                            <div className='row mt-3'>
                                                                                <SearchHotel></SearchHotel>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="tab-pane fade show" id='tabArea_ServiceRequest' role="tabpanel" aria-labelledby="departure">
                                                                    <div className=''>
                                                                        <div className=' position-relative'>
                                                                            <div className='row mt-3'>
                                                                                <ServiceRequest key={serviceRequestKey}></ServiceRequest>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="tab-pane fade show" id='tabArea_Approver' role="tabpanel" aria-labelledby="departure">
                                                                    <div className=''>
                                                                        <div className=' position-relative'>
                                                                            <div className='row mt-3'>
                                                                                <Approver></Approver>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="tab-pane fade show" id='tabArea_MeetingDetails' role="tabpanel" aria-labelledby="departure">
                                                                    <div className=''>
                                                                        <div className=' position-relative'>
                                                                            <div className='row mt-3'>
                                                                                <MeetingDetails prop={1}></MeetingDetails>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="tab-pane fade show" id='tabArea_Document' role="tabpanel" aria-labelledby="departure">
                                                                    <div className=''>
                                                                        <div className=' position-relative'>
                                                                            <div className='row mt-3'>
                                                                                <Document></Document>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p id="requiredIndicator" className='d-none'><span className="error-icon">*</span><span>Indicates required fields.</span></p>
                                                    <div className="top_form_search_button my-3">
                                                        <button id='btnReset' onClick={onReset} className="btn btn_theme btn_md mt-2 mx-2 d-none float-end" type="button" >Reset</button>
                                                        <button id='btnSubmit' onClick={onSubmit} className="btn btn_theme btn_md mt-2 mx-2 d-none float-end" type="button" >Submit</button>
                                                    </div>

                                                    {
                                                        selectedTab === 'Flight' && valueParam.return !== undefined && valueParam.return !== '' && valueParam.return === '1' && sessionStorage.getItem("travelreqflightsearch") !== null && sessionStorage.getItem("travelreqflightsearch") === 'true' && localStorage.getItem("BookingDataTravelRequest") !== null &&
                                                        <div><br></br>
                                                            <label for="" class="h5 text-primary mt-5"> <span className='gt-border-1px'> <i class="fab fa-avianex"></i> Selected Flight Details</span> </label>
                                                            <div class="text-muted h6 mb-2 mt-2 d-none"><b class="text-primary">PNR </b> <span class="text-black">MJCTDX</span> </div>
                                                            <div className='col-md-12 mt-1'>
                                                                <FlightItem
                                                                    key={bookingJSON.data.airItinerary[0].SequenceNumber}
                                                                    id={bookingJSON.data.airItinerary[0].SequenceNumber}
                                                                    airItinerary={bookingJSON.data.airItinerary[0]}
                                                                    searchFlight={bookingJSON.data.airItinerary[0]}
                                                                    bookingData={bookingJSON.data.travelerInfo.specialReqDetails}
                                                                    fareRuleData={bookingJSON.data.airItinerary[0]}
                                                                    showData="0"
                                                                    hideIcon="0"
                                                                    hideCommision="4"
                                                                />

                                                                <div className="tour_details_boxed_inner">
                                                                    <div className="accordion" id="accordionExample">
                                                                        <div className="accordion_flex_area">
                                                                            <div className="accordion-item xyz pb-0">
                                                                                <h5 className="accordion-header text-sky-blue fw-bold" id="headingOne">
                                                                                    Price
                                                                                </h5>

                                                                                <div id="collapseOne" className="accordion-collapse collapse show"
                                                                                    aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                                                    <div className='row my-2'>
                                                                                        <FareSummary FlightData={bookingJSON.data.airItinerary[0]} bookingData={bookingJSON} hideOtherServices="2" hideIcon="0" hideCommision="4"></FareSummary>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }

                                                    {
                                                        selectedTab === 'Hotel' && valueParam.return !== undefined && valueParam.return !== '' && valueParam.return === '1' && sessionStorage.getItem("travelreqhotelsearch") !== null && sessionStorage.getItem("travelreqhotelsearch") === 'true' && localStorage.getItem("TravelRequestInput") !== null &&
                                                        <div><br></br>
                                                            {/* <label for="" class="h5 text-primary mt-5"> Selected Hotel Details </label> */}
                                                            <label for="" class="h5 text-primary mt-5"> <span class="gt-border-1px"> <i class="fas fa-hotel"></i> Selected Hotel Details</span> </label>
                                                            <div>
                                                                <div className="hotel-list mt-1 row">
                                                                    <HotelDetails hotelBookingJSON={travelRequestJSON}></HotelDetails>
                                                                </div>
                                                                <div className="tour_details_boxed_inner">
                                                                    <div className="accordion" id="accordionExample">
                                                                        <div className="accordion_flex_area">
                                                                            <Booking hotelBookingJSON={travelRequestJSON}></Booking>
                                                                        </div>
                                                                        <hr></hr>
                                                                        <div className="accordion_flex_area">
                                                                            <RateDetails hotelBookingJSON={travelRequestJSON} show=""></RateDetails>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }

        </Fragment>)
};

export default TravelRequest;