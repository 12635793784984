const FareSummary = (props) => {
    return <> <div class="accordion_flex_area row mt-2">
        <div class=" pb-0">
            <h5 class="accordion-header text-sky-blue fw-bold mb-2" id="headingOne">Price</h5>
            <div class="mt-0"><label class="form-check-label text-14px " for="flexCheckDefaultf1"><span
                class="area_flex_one text-muted"><span class="fw-bold h6">1 X {props.fareDetails.roomType}</span></span></label>
                <div class="row">
                    <div class="col-lg-8 col-md-8 col-sm-8 col-8 h6">Base Rate</div>
                    <div class="col-lg-4 col-md-4 col-sm-4 col-4 h6 text-end word-break">
                        {new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: props.fareDetails.currencyCode
                        }).format(1 * props.fareDetails.baseFare)}</div>
                </div>
                <div class="row">
                    <div class="col-lg-8 col-md-8 col-sm-8 col-8 h6">Taxes &amp; Fees</div>
                    <div class="col-lg-4 col-md-4 col-sm-4 col-4 h6 text-end word-break">{new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: props.fareDetails.currencyCode
                    }).format(1 * props.fareDetails.taxes)}</div>
                </div>
                <hr class="my-2"></hr>
                <div class="row">
                    <div class="col-lg-3 col-md-3 col-sm-3 col-3">
                        <div class="h5 mb-1 fw-bold text-primary">Total Rate </div>
                    </div>
                    <div class="col-lg-9 col-md-9 col-sm-9 col-9">
                        <div class="h5 mb-2 fw-bold text-primary  float-lg-end text-end">   {new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: props.fareDetails.currencyCode
                        }).format(1 * props.fareDetails.totalRate)}</div>
                    </div>
                </div>
            </div>
        </div>
    </div></>
}

export default FareSummary;