import { Fragment, useCallback, useState, useEffect, useRef } from 'react';
import useDocumentTitle from '../../components/Common/layout/useDocumentTitle'
import { v4 as uuidv4 } from 'uuid';
import useHttp from '../../services/use-http';
import ErrorModal from "../../components/Common/Error/ErrorModal";
import { useTranslation } from 'react-i18next';
import LoadingHolidays from './Components/LoadingHolidays';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { GET_ALL_SIGHTSEEING_LIST } from '../../services/CONSTANTS';
import { getConfigurationByBoolen, getConfigurationByValue } from '../../utils/ConfigurationsFunction';
import { checkSecurityDetails } from '../../utils/RoleUtils';
import { getSingleSightseeing } from '../../services/Sightseeing-API';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { useLocation } from 'react-router';

const SearchHolidays = () => {

    const { sendRequest, status, data: loadedSightseeingSearch, error } = useHttp(getSingleSightseeing);
    const { t } = useTranslation(['translation.Holidays']);
    const history = useHistory();

    useDocumentTitle('Holidays Search');
    const [error1, setError] = useState('');
    const [items, setItems] = useState([]);
    const { detect } = require('detect-browser');
    const browser = detect();
    const location = useLocation();
    const valueParam = queryString.parse(location.search);

    useEffect(() => {
        sessionStorage.setItem("myTripIsCanceled", '');
        sessionStorage.setItem("myTripIsHotelCancelled", '');

        window.scrollTo({
            top: 50,
            behavior: "smooth",
        });

        if (document.querySelector("#lblFlights") !== null) {
            document.querySelector("#lblFlights").classList.remove("flight-active-tab");
            document.querySelector("#lblFlights").classList.add("text-primary");
        }

        if (document.querySelector("#lblFlightPlusHotel") !== null) {
            document.querySelector("#lblFlightPlusHotel").classList.remove("flight-active-tab");
            document.querySelector("#lblFlightPlusHotel").classList.add("text-primary");
        }
        else {
            if (document.querySelector("#lblFlights") !== null) {
                document.querySelector("#lblFlights").classList.remove("flight-active-tab");
                document.querySelector("#lblFlights").classList.add("text-primary");
            }

            document?.querySelector("#lblHotel")?.classList?.remove("text-primary");
            document?.querySelector("#lblHotel")?.classList?.add("flight-active-tab");
        }

        if (document.querySelector("#lblHotel") !== null) {
            document?.querySelector("#lblHotel")?.classList?.remove("flight-active-tab");
            document?.querySelector("#lblHotel")?.classList?.add("text-primary");
        }

        if (document.querySelector("#lblSightseeing") !== null) {
            document.querySelector("#lblSightseeing").classList.add("text-primary");
            document.querySelector("#lblSightseeing").classList.remove("flight-active-tab");
        }

        if (document.querySelector("#lblHolidays") !== null) {
            document.querySelector("#lblHolidays").classList.remove("flight-active-tab");
            document.querySelector("#lblHolidays").classList.add("text-primary");
        }
        if (document.querySelector("#lblVisa") !== null) {
            document.querySelector("#lblVisa").classList.add("text-primary");
            document.querySelector("#lblVisa").classList.remove("flight-active-tab");
        }

    }, [])

    const controlHandlerFightSearch = (event) => {
        if (event.target.id === 'lblFlights') {
            history.push('/FlightSearch?Flights=1');
        }
        else if (event.target.id === 'lblHotel') {
            history.push('/SearchHotel');
        }
        else if (event.target.id === 'lblFlightPlusHotel') {
            history.push('/FlightSearch?FlightPlusHotel=1');
        }
        else if (event.target.id === 'lblSightseeing') {
            history.push('/SearchSightseeing');
        }
        else if (event.target.id === 'lblHolidays') {
            // if (document.querySelector("#lblFlights") !== null) {
            //     document.querySelector("#lblFlights").classList.add("text-primary");
            //     document.querySelector("#lblFlights").classList.remove("flight-active-tab");
            // }

            // if (document.querySelector("#lblFlightPlusHotel") !== null) {
            //     document.querySelector("#lblFlightPlusHotel").classList.add("text-primary");
            //     document.querySelector("#lblFlightPlusHotel").classList.remove("flight-active-tab");
            // }

            // if (document.querySelector("#lblHotel") !== null) {
            //     document.querySelector("#lblHotel").classList.add("text-primary");
            //     document.querySelector("#lblHotel").classList.remove("flight-active-tab");
            // }

            // if (document.querySelector("#lblSightseeing") !== null) {
            //     document.querySelector("#lblSightseeing").classList.remove("flight-active-tab");
            //     document.querySelector("#lblSightseeing").classList.add("text-primary");
            // }

            // if (document.querySelector("#lblHolidays") !== null) {
            //     document.querySelector("#lblHolidays").classList.add("flight-active-tab");
            //     document.querySelector("#lblHolidays").classList.remove("text-primary");
            // }

            window.open(getConfigurationByValue('HOLIDAYS_URL'))
        }
        else if (event.target.id === 'lblVisa') {
            history.push('/SearchVisa')
        }
    }

    return <Fragment>
        <section className="sub-banner">
            <div className="bg-parallax bg-1"></div>
            <div className="logo-banner text-center">
            </div>
        </section>

        <section id="theme_search_form_tour" className='fligth_top_search_main_form_wrapper'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="theme_search_form_area position-relative my-2">
                            <div className="row position-relative">
                                {/* {status === 'pending' &&
                                    <LoadingSightseeing action='search' ></LoadingSightseeing>
                                } */}
                                <div className="col-lg-12">
                                    <div className="tour_search_form">
                                        <form action="!#">
                                            <div className="row">
                                                <label for="" className="h5 text-primary mb-3 me-3" ><i className="fas fa-globe-asia"></i> Search</label>
                                                <div className='text-sm-center1 mb-2'>
                                                    <span>
                                                        {
                                                            (checkSecurityDetails("Book", "Flight") && checkSecurityDetails("Book", "Flight", "Book_Flight_Search") && getConfigurationByBoolen("ALLOW_FLIGHT") === true) &&
                                                            <label for="" className="h6 mb-2 me-3" role="button" id='lblFlights' onClick={controlHandlerFightSearch}><i className="fas fa-plane-departure"></i> {t('flights')}</label>
                                                        }
                                                        {
                                                            (getConfigurationByBoolen('ALLOW_SINGLE_PNR') !== null &&
                                                                getConfigurationByBoolen("ALLOW_HOTEL") && getConfigurationByBoolen('ALLOW_SINGLE_PNR')
                                                                && getConfigurationByBoolen("ALLOW_FLIGHT") && checkSecurityDetails("Book", "Flight", "Book_Flight_Search")) ?
                                                                <label for="" className="h6 mb-2 mx-1" role="button" id='lblFlightPlusHotel' onClick={controlHandlerFightSearch}><i className="fas fa-globe-asia"></i>  {t('Flights and Hotels')}</label> :
                                                                <label id='lblFlightPlusHotel' ></label>
                                                        }
                                                        {
                                                            (checkSecurityDetails("Book", "Hotel") && checkSecurityDetails("Book", "Hotel", "Book_Hotel_Search") && getConfigurationByBoolen("ALLOW_HOTEL") === true) &&
                                                            <label for="" className="h6 mb-2 ms-3" role="button" id='lblHotel' onClick={controlHandlerFightSearch}><i className="fas fa-hotel"></i> Hotels</label>
                                                        }
                                                        {
                                                            (checkSecurityDetails("Book", "Sightseeing") && getConfigurationByBoolen("ALLOW_SIGHTSEEING") === true) &&
                                                            <label for="" className="h6 mb-2 ms-3" role="button" id='lblSightseeing' onClick={controlHandlerFightSearch}><i className="fas fa-hiking"></i> Sightseeing</label>
                                                        }
                                                        {
                                                            getConfigurationByBoolen("ALLOW_HOLIDAYS") === true && getConfigurationByValue('HOLIDAYS_URL') !== null && getConfigurationByValue('HOLIDAYS_URL') !== '' &&
                                                            <label for="" className="h6 mb-2 ms-3" role="button" id='lblHolidays' onClick={controlHandlerFightSearch}><i className="fas fa-umbrella-beach"></i> Holidays</label>
                                                        }
                                                        {getConfigurationByBoolen("ALLOW_VISA") === true &&
                                                            <label for="" className="h6 mb-2 ms-3" role="button" id='lblVisa' onClick={controlHandlerFightSearch}><i class="fas fa-passport"></i> Visa</label>
                                                        }
                                                    </span>
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Fragment>
};

export default SearchHolidays;