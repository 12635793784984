import { Fragment } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { checkSecurityDetails } from '../../../../utils/RoleUtils'
const ContentItem = (props) => {
    const isTravelAgent = JSON.parse(localStorage.getItem('isTravelAgent'));
    const { t } = useTranslation(['translation.Content']);

    let isUserActive;
    const isActive = props.isActive.toString();
    if (isActive === 'true') {
        isUserActive = 'Yes';
    }
    else if (isActive === 'false') {
        isUserActive = 'No';
    }
    return <Fragment>

        <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-8 mb-1">
                <div className="card text-dark card-bg-blue mb-2">
                    <div className="card-body">
                        <div className="row">
                            <div className={isTravelAgent === "1" ? "col-md-4" : "col-md-8"} >
                                <h6 className="card-title fw-bold text-primary mt-1">
                                    {props.providerName}
                                </h6>

                            </div>
                            {isTravelAgent === "1" && <div className="col-md-4">

                                <p className="card-text mb-0"><strong>{t('companyName')}:</strong> {props.companyName}</p>
                            </div>}
                            <div className="col-md-4">  </div>
                            <hr className="my-1" />
                            <div className="col-md-4">
                                <p className="card-text mb-0"><strong>{t('service')}</strong>{props.serviceName}</p>

                                {props.serviceName === 'Flight' && <p className="card-text mb-0"><strong>{t('fromRegionName')}</strong>{props.fromRegionName}</p>}

                                {props.serviceName === 'Hotel' && <p className="card-text mb-0"><strong>{t('regionName')}</strong>{props.fromRegionName}</p>}
                                {props.currency !== null && < p className="card-text mb-0"><strong>{t('currency')}: </strong>{props.currency}</p>}
                            </div>
                            <div className="col-md-4">

                                <p className="card-text mb-0"><strong>{t('active')}  </strong>{isUserActive}</p>

                                {props.serviceName === 'Flight' && <p className="card-text mb-0"><strong>{t('toRegionName')} </strong>{props.toRegionName}</p>}


                            </div>
                            <div className="col-md-4 my-auto">
                                <div className='float-lg-end float-md-end'>
                                    {checkSecurityDetails("Settings", "Content", "Settings_Content_View") &&
                                        <Link className="btn btn-sm btn-warning text-white ms-1 gt-border-radius-50 gt-yellow-btn gt-yellow-btn-adding" data-bs-toggle="tooltip" data-bs-placement="top" title="Show" to={{ pathname: `/UpdateContent/${props.id}`, edit: 0 }}><i className="fa-sharp fa-solid fa-tv"></i></Link>
                                    }
                                    {checkSecurityDetails("Settings", "Content", "Settings_Content_Update") &&
                                        <Link className="btn btn-sm text-white ms-1 gt-border-radius-50 gt-green-btn " title='Edit' to={{ pathname: `/UpdateContent/${props.id}`, edit: 1 }}>
                                            <i className="fa-solid fa-pen"></i>
                                        </Link>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Fragment >

};
export default ContentItem;