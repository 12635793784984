import { Fragment, useEffect, useState } from 'react';
import Dropdown from 'react-select';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import CountryDropDownList from '../../../../components/Common/DropDownList/CountryDropDownList';
import CityDropDownList from '../../../../components/Common/DropDownList/CityDropDownList';
import { getFormatDate } from '.././../../../utils/CommonFunction';
import useHttp from '../../../../services/use-http';
import { searchCorporateMaster } from '../../../../services/CorporateMaster-API';

const MeetingDetails = () => {
    const [meetingStartDate, setMeetingStartDate] = useState();
    const [meetingEndDate, setMeetingEndDate] = useState();
    const [meetingStartDateDb, setMeetingStartDateDb] = useState();
    const [meetingEndDateDb, setMeetingEndDateDb] = useState();
    const [purposeOfTravelValue, setPurposeOfTravelValue] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [cityCode, setCityCode] = useState('');
    const [meetingStartDate_, setMeetingStartDate_] = useState();
    const [meetingEndDate_, setMeetingEndDate_] = useState();
    const [purposeOfTravelText, setPurposeOfTravelText] = useState('');

    const { sendRequest: sendRequestCorproateMaster, status: statusCorproateMaster, data: loadedCorproateMaster, error } = useHttp(searchCorporateMaster);
    const [tripPurposeRemarks, setTripPurposeRemarks] = useState('');
    const [tripPurposeRemarksHasInvalidData, setTripPurposeRemarksHasInvalidData] = useState(false);

    let purposeOfTravelOptions = [];

    useEffect(() => {
        if (sessionStorage.getItem("spnToAirportDetails1") !== null) {
            let selectedToCountry = sessionStorage.getItem("spnToAirportDetails1") !== null ? sessionStorage.getItem("spnToAirportDetails1").split(',')[sessionStorage.getItem("spnToAirportDetails1").split(',').length - 1].trim() : '';
            let selectedToCity = sessionStorage.getItem("spnToAirportDetails1") !== null ? sessionStorage.getItem("spnToAirportDetails1").split('-')[0].trim() : '';

            if (localStorage.getItem("BookingDataTravelRequest") !== null && localStorage.getItem("BookingDataTravelRequest") !== '') {
                let bookingDataTravelRequestJson = JSON.parse(localStorage.getItem("BookingDataTravelRequest"));
                let originDestinationOptionLengthOutbound = bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption.filter(a => a.directionInd === 'O').length;
                let originDestinationOptionLengthInbound = bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption.filter(a => a.directionInd === 'I').length;
                let originDestinationOptionLengthMulticity = bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption.filter(a => a.directionInd === 'M').length;

                selectedToCountry = originDestinationOptionLengthMulticity <= 0 ? bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption[originDestinationOptionLengthOutbound - 1].flightSegment[0].arrivalAirport.countryCode : bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption[originDestinationOptionLengthMulticity - 1].flightSegment[0].arrivalAirport.countryCode;
                selectedToCity = originDestinationOptionLengthMulticity <= 0 ? bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption[originDestinationOptionLengthOutbound - 1].flightSegment[0].arrivalAirport.cityCode : bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption[originDestinationOptionLengthMulticity - 1].flightSegment[0].arrivalAirport.cityCode;

                setCountryCode(countryCode !== undefined && countryCode !== null && countryCode !== '' ? countryCode : selectedToCountry);
                setCityCode(cityCode !== undefined && cityCode !== null && cityCode !== '' ? cityCode : sessionStorage.getItem("toCityCode1") !== null ? sessionStorage.getItem("toCityCode1").trim().toUpperCase() : selectedToCity);

                let departureDate = getFormatDate(bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption[0].flightSegment[0].departureDate);
                let arrivalDate = getFormatDate(originDestinationOptionLengthInbound <= 0 ? originDestinationOptionLengthMulticity <= 0 ? bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption[originDestinationOptionLengthOutbound - 1].flightSegment[0].arrivalDate : bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption[originDestinationOptionLengthMulticity - 1].flightSegment[0].departureDate : bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption.filter(a => a.directionInd === 'I')[0].flightSegment[0].departureDate);

                setMeetingStartDate_(moment(new Date(departureDate)).format("D MMM YYYY"));
                var departureDate2 = new Date(getFormatDate(bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption[0].flightSegment[0].departureDate));
                document.querySelector('#meetingStartDate').value = meetingStartDate !== undefined && meetingStartDate !== '' ? moment(new Date(meetingStartDate)).format("D MMM YYYY") : moment(departureDate2).format("D MMM YYYY");

                setMeetingEndDate_(moment(new Date(arrivalDate)).format("D MMM YYYY"));
                var arrivalDate2 = new Date(getFormatDate(originDestinationOptionLengthInbound <= 0 ? originDestinationOptionLengthMulticity <= 0 ? bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption[originDestinationOptionLengthOutbound - 1].flightSegment[0].arrivalDate : bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption[originDestinationOptionLengthMulticity - 1].flightSegment[0].departureDate : bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption.filter(a => a.directionInd === 'I')[0].flightSegment[0].departureDate));
                document.querySelector('#meetingEndDate').value = meetingEndDate !== undefined && meetingEndDate !== '' ? moment(new Date(meetingEndDate)).format("D MMM YYYY") : moment(arrivalDate2).format("D MMM YYYY");

                if (sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
                    let travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));

                    document.querySelector('#meetingStartTime').value = travelRequestFieldsDataJson.meetingStartTime !== undefined && travelRequestFieldsDataJson.meetingStartTime !== null && travelRequestFieldsDataJson.meetingStartTime.length !== undefined && travelRequestFieldsDataJson.meetingStartTime.length !== 0 ? travelRequestFieldsDataJson.meetingStartTime.substring(0, 2) + ":" + travelRequestFieldsDataJson.meetingStartTime.substring(2, 4) : "09:00";
                    document.querySelector('#meetingEndTime').value = travelRequestFieldsDataJson.meetingEndTime !== undefined && travelRequestFieldsDataJson.meetingEndTime !== null && travelRequestFieldsDataJson.meetingEndTime.length !== undefined && travelRequestFieldsDataJson.meetingEndTime.length !== 0 ? travelRequestFieldsDataJson.meetingEndTime.substring(0, 2) + ":" + travelRequestFieldsDataJson.meetingEndTime.substring(2, 4) : "18:00";

                    travelRequestFieldsDataJson.meetingCountry = selectedToCountry;
                    travelRequestFieldsDataJson.meetingCity = selectedToCity;
                    travelRequestFieldsDataJson.meetingStartDate = bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption[0].flightSegment[0].departureDate;
                    travelRequestFieldsDataJson.meetingEndDate = originDestinationOptionLengthInbound <= 0 ? originDestinationOptionLengthMulticity <= 0 ? bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption[originDestinationOptionLengthOutbound - 1].flightSegment[0].arrivalDate : bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption[originDestinationOptionLengthMulticity - 1].flightSegment[0].departureDate : bookingDataTravelRequestJson.data.airItinerary[0].originDestinationOptions.originDestinationOption.filter(a => a.directionInd === 'I')[0].flightSegment[0].departureDate;
                    travelRequestFieldsDataJson.meetingStartTime = travelRequestFieldsDataJson.meetingStartTime !== undefined && travelRequestFieldsDataJson.meetingStartTime !== null && travelRequestFieldsDataJson.meetingStartTime.length !== undefined && travelRequestFieldsDataJson.meetingStartTime.length !== 0 ? travelRequestFieldsDataJson.meetingStartTime : "0900";
                    travelRequestFieldsDataJson.meetingEndTime = travelRequestFieldsDataJson.meetingEndTime !== undefined && travelRequestFieldsDataJson.meetingEndTime !== null && travelRequestFieldsDataJson.meetingEndTime.length !== undefined && travelRequestFieldsDataJson.meetingEndTime.length !== 0 ? travelRequestFieldsDataJson.meetingEndTime : "1800";
                    travelRequestFieldsDataJson.meetingPurposeOfTravel = purposeOfTravelValue;
                    travelRequestFieldsDataJson.meetingPurposeOfTravelText = purposeOfTravelText;
                    travelRequestFieldsDataJson.tripPurposeRemarks = tripPurposeRemarks;
                    travelRequestFieldsDataJson.tripPurposeRemarksHasInvalidData = tripPurposeRemarksHasInvalidData;
                    sessionStorage.setItem("travelRequestFieldsDataJson", JSON.stringify(travelRequestFieldsDataJson));
                }
            }
            else if (localStorage.getItem("TravelRequestInput") !== null && localStorage.getItem("TravelRequestInput") !== '') {
                let travelRequestJSON = JSON.parse(localStorage.getItem("TravelRequestInput"));
                if (travelRequestJSON.data.onlineHotelDetails !== undefined && travelRequestJSON.data.onlineHotelDetails !== null) {
                    setCountryCode(countryCode !== undefined && countryCode !== null && countryCode !== '' ? countryCode : selectedToCountry);
                    setCityCode(cityCode !== undefined && cityCode !== null && cityCode !== '' ? cityCode : sessionStorage.getItem("toCityCode1") !== null ? sessionStorage.getItem("toCityCode1").trim().toUpperCase() : selectedToCity);

                    let departureDate = getFormatDate(travelRequestJSON.data.onlineHotelDetails[0].checkInDate);
                    let arrivalDate = getFormatDate(travelRequestJSON.data.onlineHotelDetails[0].checkOutDate);

                    setMeetingStartDate_(moment(new Date(departureDate)).format("D MMM YYYY"));
                    var departureDate2 = new Date(getFormatDate(travelRequestJSON.data.onlineHotelDetails[0].checkInDate));
                    document.querySelector('#meetingStartDate').value = meetingStartDate !== undefined && meetingStartDate !== '' ? moment(new Date(meetingStartDate)).format("D MMM YYYY") : moment(departureDate2).format("D MMM YYYY");

                    setMeetingEndDate_(moment(new Date(arrivalDate)).format("D MMM YYYY"));
                    var arrivalDate2 = new Date(getFormatDate(travelRequestJSON.data.onlineHotelDetails[0].checkOutDate));
                    document.querySelector('#meetingEndDate').value = meetingEndDate !== undefined && meetingEndDate !== '' ? moment(new Date(meetingEndDate)).format("D MMM YYYY") : moment(arrivalDate2).format("D MMM YYYY");

                    if (sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
                        let travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));

                        document.querySelector('#meetingStartTime').value = travelRequestFieldsDataJson.meetingStartTime !== undefined && travelRequestFieldsDataJson.meetingStartTime !== null && travelRequestFieldsDataJson.meetingStartTime.length !== undefined && travelRequestFieldsDataJson.meetingStartTime.length !== 0 ? travelRequestFieldsDataJson.meetingStartTime.substring(0, 2) + ":" + travelRequestFieldsDataJson.meetingStartTime.substring(2, 4) : "09:00";
                        document.querySelector('#meetingEndTime').value = travelRequestFieldsDataJson.meetingEndTime !== undefined && travelRequestFieldsDataJson.meetingEndTime !== null && travelRequestFieldsDataJson.meetingEndTime.length !== undefined && travelRequestFieldsDataJson.meetingEndTime.length !== 0 ? travelRequestFieldsDataJson.meetingEndTime.substring(0, 2) + ":" + travelRequestFieldsDataJson.meetingEndTime.substring(2, 4) : "18:00";

                        travelRequestFieldsDataJson.meetingCountry = selectedToCountry;
                        travelRequestFieldsDataJson.meetingCity = selectedToCity;
                        travelRequestFieldsDataJson.meetingStartDate = travelRequestJSON.data.onlineHotelDetails[0].checkInDate;
                        travelRequestFieldsDataJson.meetingEndDate = travelRequestJSON.data.onlineHotelDetails[0].checkOutDate;
                        travelRequestFieldsDataJson.meetingStartTime = travelRequestFieldsDataJson.meetingStartTime !== undefined && travelRequestFieldsDataJson.meetingStartTime !== null && travelRequestFieldsDataJson.meetingStartTime.length !== undefined && travelRequestFieldsDataJson.meetingStartTime.length !== 0 ? travelRequestFieldsDataJson.meetingStartTime : "0900";
                        travelRequestFieldsDataJson.meetingEndTime = travelRequestFieldsDataJson.meetingEndTime !== undefined && travelRequestFieldsDataJson.meetingEndTime !== null && travelRequestFieldsDataJson.meetingEndTime.length !== undefined && travelRequestFieldsDataJson.meetingEndTime.length !== 0 ? travelRequestFieldsDataJson.meetingEndTime : "1800";
                        travelRequestFieldsDataJson.meetingPurposeOfTravel = purposeOfTravelValue;
                        travelRequestFieldsDataJson.meetingPurposeOfTravelText = purposeOfTravelText;
                        travelRequestFieldsDataJson.tripPurposeRemarks = tripPurposeRemarks;
                        travelRequestFieldsDataJson.tripPurposeRemarksHasInvalidData = tripPurposeRemarksHasInvalidData;
                        sessionStorage.setItem("travelRequestFieldsDataJson", JSON.stringify(travelRequestFieldsDataJson));
                    }
                }
                else {
                    setCountryCode(countryCode !== undefined && countryCode !== null && countryCode !== '' ? countryCode : selectedToCountry);
                    setCityCode(cityCode !== undefined && cityCode !== null && cityCode !== '' ? cityCode : sessionStorage.getItem("toCityCode1") !== null ? sessionStorage.getItem("toCityCode1").trim().toUpperCase() : selectedToCity);

                    setMeetingStartDate_(moment(new Date()).format("D MMM YYYY"));
                    var departureDate2 = moment(new Date()).format("D MMM YYYY");
                    document.querySelector('#meetingStartDate').value = meetingStartDate !== undefined && meetingStartDate !== '' ? moment(new Date(meetingStartDate)).format("D MMM YYYY") : moment(departureDate2).format("D MMM YYYY");

                    setMeetingEndDate_(moment(new Date()).format("D MMM YYYY"));
                    var arrivalDate2 = moment(new Date()).format("D MMM YYYY");
                    document.querySelector('#meetingEndDate').value = meetingEndDate !== undefined && meetingEndDate !== '' ? moment(new Date(meetingEndDate)).format("D MMM YYYY") : moment(arrivalDate2).format("D MMM YYYY");

                    if (sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
                        let travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));

                        document.querySelector('#meetingStartTime').value = travelRequestFieldsDataJson.meetingStartTime !== undefined && travelRequestFieldsDataJson.meetingStartTime !== null && travelRequestFieldsDataJson.meetingStartTime.length !== undefined && travelRequestFieldsDataJson.meetingStartTime.length !== 0 ? travelRequestFieldsDataJson.meetingStartTime.substring(0, 2) + ":" + travelRequestFieldsDataJson.meetingStartTime.substring(2, 4) : "09:00";
                        document.querySelector('#meetingEndTime').value = travelRequestFieldsDataJson.meetingEndTime !== undefined && travelRequestFieldsDataJson.meetingEndTime !== null && travelRequestFieldsDataJson.meetingEndTime.length !== undefined && travelRequestFieldsDataJson.meetingEndTime.length !== 0 ? travelRequestFieldsDataJson.meetingEndTime.substring(0, 2) + ":" + travelRequestFieldsDataJson.meetingEndTime.substring(2, 4) : "18:00";

                        travelRequestFieldsDataJson.meetingCountry = selectedToCountry;
                        travelRequestFieldsDataJson.meetingCity = selectedToCity;
                        travelRequestFieldsDataJson.meetingStartDate = new Date().toLocaleDateString('en-gb').split('/').reverse().join('');
                        travelRequestFieldsDataJson.meetingEndDate = new Date().toLocaleDateString('en-gb').split('/').reverse().join('');
                        travelRequestFieldsDataJson.meetingStartTime = travelRequestFieldsDataJson.meetingStartTime !== undefined && travelRequestFieldsDataJson.meetingStartTime !== null && travelRequestFieldsDataJson.meetingStartTime.length !== undefined && travelRequestFieldsDataJson.meetingStartTime.length !== 0 ? travelRequestFieldsDataJson.meetingStartTime : "0900";
                        travelRequestFieldsDataJson.meetingEndTime = travelRequestFieldsDataJson.meetingEndTime !== undefined && travelRequestFieldsDataJson.meetingEndTime !== null && travelRequestFieldsDataJson.meetingEndTime.length !== undefined && travelRequestFieldsDataJson.meetingEndTime.length !== 0 ? travelRequestFieldsDataJson.meetingEndTime : "1800";
                        travelRequestFieldsDataJson.meetingPurposeOfTravel = purposeOfTravelValue;
                        travelRequestFieldsDataJson.meetingPurposeOfTravelText = purposeOfTravelText;
                        travelRequestFieldsDataJson.tripPurposeRemarks = tripPurposeRemarks;
                        travelRequestFieldsDataJson.tripPurposeRemarksHasInvalidData = tripPurposeRemarksHasInvalidData;
                        sessionStorage.setItem("travelRequestFieldsDataJson", JSON.stringify(travelRequestFieldsDataJson));
                    }
                }
            }
            else {
                setCountryCode(countryCode !== undefined && countryCode !== null && countryCode !== '' ? countryCode : selectedToCountry);
                setCityCode(cityCode !== undefined && cityCode !== null && cityCode !== '' ? cityCode : sessionStorage.getItem("toCityCode1") !== null ? sessionStorage.getItem("toCityCode1").trim().toUpperCase() : selectedToCity);

                setMeetingStartDate_(moment(new Date()).format("D MMM YYYY"));
                var departureDate2 = moment(new Date()).format("D MMM YYYY");
                document.querySelector('#meetingStartDate').value = meetingStartDate !== undefined && meetingStartDate !== '' ? moment(new Date(meetingStartDate)).format("D MMM YYYY") : moment(departureDate2).format("D MMM YYYY");

                setMeetingEndDate_(moment(new Date()).format("D MMM YYYY"));
                var arrivalDate2 = moment(new Date()).format("D MMM YYYY");
                document.querySelector('#meetingEndDate').value = meetingEndDate !== undefined && meetingEndDate !== '' ? moment(new Date(meetingEndDate)).format("D MMM YYYY") : moment(arrivalDate2).format("D MMM YYYY");

                if (sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
                    let travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));

                    document.querySelector('#meetingStartTime').value = travelRequestFieldsDataJson.meetingStartTime !== undefined && travelRequestFieldsDataJson.meetingStartTime !== null && travelRequestFieldsDataJson.meetingStartTime.length !== undefined && travelRequestFieldsDataJson.meetingStartTime.length !== 0 ? travelRequestFieldsDataJson.meetingStartTime.substring(0, 2) + ":" + travelRequestFieldsDataJson.meetingStartTime.substring(2, 4) : "09:00";
                    document.querySelector('#meetingEndTime').value = travelRequestFieldsDataJson.meetingEndTime !== undefined && travelRequestFieldsDataJson.meetingEndTime !== null && travelRequestFieldsDataJson.meetingEndTime.length !== undefined && travelRequestFieldsDataJson.meetingEndTime.length !== 0 ? travelRequestFieldsDataJson.meetingEndTime.substring(0, 2) + ":" + travelRequestFieldsDataJson.meetingEndTime.substring(2, 4) : "18:00";

                    travelRequestFieldsDataJson.meetingCountry = selectedToCountry;
                    travelRequestFieldsDataJson.meetingCity = selectedToCity;
                    travelRequestFieldsDataJson.meetingStartDate = new Date().toLocaleDateString('en-gb').split('/').reverse().join('')
                    travelRequestFieldsDataJson.meetingEndDate = new Date().toLocaleDateString('en-gb').split('/').reverse().join('')
                    travelRequestFieldsDataJson.meetingStartTime = travelRequestFieldsDataJson.meetingStartTime !== undefined && travelRequestFieldsDataJson.meetingStartTime !== null && travelRequestFieldsDataJson.meetingStartTime.length !== undefined && travelRequestFieldsDataJson.meetingStartTime.length !== 0 ? travelRequestFieldsDataJson.meetingStartTime : "0900";
                    travelRequestFieldsDataJson.meetingEndTime = travelRequestFieldsDataJson.meetingEndTime !== undefined && travelRequestFieldsDataJson.meetingEndTime !== null && travelRequestFieldsDataJson.meetingEndTime.length !== undefined && travelRequestFieldsDataJson.meetingEndTime.length !== 0 ? travelRequestFieldsDataJson.meetingEndTime : "1800";
                    travelRequestFieldsDataJson.meetingPurposeOfTravel = purposeOfTravelValue;
                    travelRequestFieldsDataJson.meetingPurposeOfTravelText = purposeOfTravelText;
                    travelRequestFieldsDataJson.tripPurposeRemarks = tripPurposeRemarks;
                    travelRequestFieldsDataJson.tripPurposeRemarksHasInvalidData = tripPurposeRemarksHasInvalidData;
                    sessionStorage.setItem("travelRequestFieldsDataJson", JSON.stringify(travelRequestFieldsDataJson));
                }
            }
        }

    }, [sessionStorage.getItem("spnToAirportDetails1"), sessionStorage.getItem("calFrom1"),
    sessionStorage.getItem("calTo1"), meetingStartDate_, meetingEndDate_, countryCode,
        cityCode, meetingStartDate, meetingEndDate, purposeOfTravelValue])

    const calGetMeetingStartDate = (inputDate) => {
        sessionStorage.removeItem("fromDateChangedCBT");
        document.querySelector('#meetingStartDate').value = moment(new Date(inputDate.target.value)).format("D MMM YYYY");
        const fromDate = new Date(inputDate.target.value.replace("'", ""));
        var fromDateDB = fromDate.toLocaleDateString('en-gb').split('/').reverse().join('');
        setMeetingStartDate(fromDate);
        setMeetingStartDateDb(fromDateDB);

        let travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
        travelRequestFieldsDataJson.meetingStartDate = fromDateDB;
        sessionStorage.setItem("travelRequestFieldsDataJson", JSON.stringify(travelRequestFieldsDataJson));
    }

    const calGetMeetingEndDate = (inputDate) => {
        sessionStorage.removeItem("toDateChangedCBT");
        document.querySelector('#meetingEndDate').value = moment(new Date(inputDate.target.value)).format("D MMM YYYY");
        const toDate = new Date(inputDate.target.value.replace("'", ""));
        var toDateDB = toDate.toLocaleDateString('en-gb').split('/').reverse().join('');
        setMeetingEndDate(toDate);
        setMeetingEndDateDb(toDateDB);

        let travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
        travelRequestFieldsDataJson.meetingEndDate = toDateDB;
        sessionStorage.setItem("travelRequestFieldsDataJson", JSON.stringify(travelRequestFieldsDataJson));
    }

    useEffect(() => {
        if (sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
            let travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
            if (travelRequestFieldsDataJson.meetingCountry.length !== undefined && travelRequestFieldsDataJson.meetingCountry.length !== 0) {
                setCountryCode(countryCode !== undefined && countryCode !== null && countryCode !== '' ? countryCode : travelRequestFieldsDataJson.meetingCountry);
            }
            if (travelRequestFieldsDataJson.meetingCity.length !== undefined && travelRequestFieldsDataJson.meetingCity.length !== 0) {
                setCityCode(cityCode !== undefined && cityCode !== null && cityCode !== '' ? cityCode : travelRequestFieldsDataJson.meetingCity);
            }
            if (travelRequestFieldsDataJson.meetingStartDate.length !== undefined && travelRequestFieldsDataJson.meetingStartDate.length !== 0) {
                setMeetingStartDate_(moment(travelRequestFieldsDataJson.meetingStartDate, 'MM/DD/YYYY').format("D MMM YYYY"));
            }
            if (travelRequestFieldsDataJson.meetingEndDate.length !== undefined && travelRequestFieldsDataJson.meetingEndDate.length !== 0) {
                setMeetingEndDate_(moment(travelRequestFieldsDataJson.meetingEndDate, 'MM/DD/YYYY').format("D MMM YYYY"));
            }
            if (travelRequestFieldsDataJson.meetingStartTime.length !== undefined && travelRequestFieldsDataJson.meetingStartTime.length !== 0) {
                document.querySelector('#meetingStartTime').value = travelRequestFieldsDataJson.meetingStartTime !== undefined && travelRequestFieldsDataJson.meetingStartTime !== null && travelRequestFieldsDataJson.meetingStartTime !== '' ? travelRequestFieldsDataJson.meetingStartTime.substring(0, 2) + ":" + travelRequestFieldsDataJson.meetingStartTime.substring(2, 4) : "09:00";
            }
            if (travelRequestFieldsDataJson.meetingEndTime.length !== undefined && travelRequestFieldsDataJson.meetingEndTime.length !== 0) {
                document.querySelector('#meetingEndTime').value = travelRequestFieldsDataJson.meetingEndTime !== undefined && travelRequestFieldsDataJson.meetingEndTime !== null && travelRequestFieldsDataJson.meetingEndTime !== '' ? travelRequestFieldsDataJson.meetingEndTime.substring(0, 2) + ":" + travelRequestFieldsDataJson.meetingEndTime.substring(2, 4) : "18:00";
            }
            if (travelRequestFieldsDataJson.meetingPurposeOfTravel.length !== undefined && travelRequestFieldsDataJson.meetingPurposeOfTravel.length !== 0) {
                setPurposeOfTravelValue(travelRequestFieldsDataJson.meetingPurposeOfTravel);
            }
            if (travelRequestFieldsDataJson.tripPurposeRemarks
                && travelRequestFieldsDataJson.tripPurposeRemarks.length) {
                setTripPurposeRemarks(travelRequestFieldsDataJson.tripPurposeRemarks);
            }
        }

    }, [])

    useEffect(() => {
        const onSearchCorporateMaster = ({
            "id": '',
            userID: JSON.parse(localStorage.getItem('userID')),
            "name": "",
            "code": "",
            "keyCode": "FOURTH",
            "status": '2',
            "officeID": JSON.parse(localStorage.getItem("officeID"))
        });
        sendRequestCorproateMaster(onSearchCorporateMaster);
    }, [])

    if (document.querySelector('#meetingStartDate') !== null && (document.querySelector('#meetingStartDate').value.includes('/') || document.querySelector('#meetingStartDate').value.includes('-'))) {
        document.querySelector('#meetingStartDate').value = moment(new Date(document.querySelector('#meetingStartDate').value)).format('D MMM YYYY');
    }

    if (document.querySelector('#meetingEndDate') !== null && (document.querySelector('#meetingEndDate').value.includes('/') || document.querySelector('#meetingEndDate').value.includes('-'))) {
        document.querySelector('#meetingEndDate').value = moment(new Date(document.querySelector('#meetingEndDate').value)).format('D MMM YYYY');
    }

    let loadedData = [];
    for (let i in loadedCorproateMaster) {
        if (loadedCorproateMaster['errors'].status === 'FALSE') {
            if (i === 'data') {
                loadedData = loadedCorproateMaster[i];
            }
        }
    }

    loadedData.forEach(element => {
        purposeOfTravelOptions.push({ value: element.id, label: element.name });
    });

    const purposeOfTravelChangeHandler = (event) => {
        setPurposeOfTravelValue(event.value);
        setPurposeOfTravelText(event.label);

        document.querySelector('#purposeOfTravelErrorMsg').innerHTML = "";

        let travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
        travelRequestFieldsDataJson.meetingPurposeOfTravel = event.value;
        travelRequestFieldsDataJson.meetingPurposeOfTravelText = event.label;
        sessionStorage.setItem("travelRequestFieldsDataJson", JSON.stringify(travelRequestFieldsDataJson));
    };

    const countryCodeValue = (countryCode) => {
        setCountryCode(countryCode);

        let travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
        travelRequestFieldsDataJson.meetingCountry = countryCode;
        sessionStorage.setItem("travelRequestFieldsDataJson", JSON.stringify(travelRequestFieldsDataJson));
    };

    const countryNameText = (country_Name) => {
        //setCountry_Name(country_Name);
    };

    const cityCodeValue = (cityCode) => {
        setCityCode(cityCode);

        let travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
        travelRequestFieldsDataJson.meetingCity = cityCode;
        sessionStorage.setItem("travelRequestFieldsDataJson", JSON.stringify(travelRequestFieldsDataJson));
    };

    const cityNameText = (cityName) => {
        //setCityName(cityName);        
    };

    const onBlurCheck = (e) => {
        if (sessionStorage.getItem("travelRequestFieldsDataJson") !== null) {
            let travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
            travelRequestFieldsDataJson.meetingStartTime = document.querySelector('#meetingStartTime').value.replace(':', '');
            travelRequestFieldsDataJson.meetingEndTime = document.querySelector('#meetingEndTime').value.replace(':', '');
            sessionStorage.setItem("travelRequestFieldsDataJson", JSON.stringify(travelRequestFieldsDataJson));
        }
    }

    const onTripPurposeRemarksBlur = (event) => {
        const remarksText = event.target.value.trim();
        let isTripPurposeRemarksInvalid = false;
        if (remarksText.length && !remarksText.match("^[a-zA-Z .()0-9,\\'\\-\\n]+$")) {
            isTripPurposeRemarksInvalid = true;
        }

        setTripPurposeRemarksHasInvalidData(isTripPurposeRemarksInvalid);
        setTripPurposeRemarks(remarksText);

        let travelRequestFieldsDataJson = JSON.parse(sessionStorage.getItem("travelRequestFieldsDataJson"));
        travelRequestFieldsDataJson.tripPurposeRemarksHasInvalidData = isTripPurposeRemarksInvalid;
        travelRequestFieldsDataJson.tripPurposeRemarks = remarksText;
        sessionStorage.setItem("travelRequestFieldsDataJson", JSON.stringify(travelRequestFieldsDataJson));
    };

    return (
        <Fragment>
            <div className="row position-relative">
                <div className="col-lg-12">
                    <div className="tour_search_form">
                        <form action="!#">
                            <div className='row mb-2'>
                                <div className='col-lg-6'>
                                    <label class="h6 text-primary mb-2 col-lg-12" for="meetingCounty">Country<span className="error-icon meeting" id='spnMeetingCounty'> *</span></label>
                                    <div className="dropdown pb-2 col-md-12">
                                        <CountryDropDownList countryCodeValue={countryCodeValue} countryNameText={countryNameText} currentInputValue={countryCode} />
                                        <p className="error-text" id='countryErrorMsg'></p>
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <label class="h6 text-primary mb-2 col-lg-12" for="meetingCity">City<span className="error-icon meeting" id='spnMeetingCity'> *</span></label>
                                    <div className="dropdown pb-2 col-md-12">
                                        <CityDropDownList cityCodeValue={cityCodeValue} cityNameText={cityNameText} currentInputValue={cityCode} countryCode={countryCode} />
                                        <p className="error-text" id='cityErrorMsg'></p>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-3'>
                                <div className='col-md-6 dropdown-search-text'>
                                    <label class="h6 text-primary mb-2 col-lg-12" for="meetingStartDate">Start Date<span className="error-icon meeting" id='spnMeetingStartDate'> *</span></label>
                                    <DateRangePicker key={meetingStartDate_}
                                        onHide={calGetMeetingStartDate}
                                        initialSettings={{
                                            startDate: document.querySelector('#meetingStartDate') !== null ? document.querySelector('#meetingStartDate').value !== '' ? new Date(document.querySelector('#meetingStartDate').value.replace("'", "")) : meetingStartDate_ !== undefined && meetingStartDate_ !== '' ? new Date(meetingStartDate_) : new Date(sessionStorage.getItem("calFrom1").replace("'", "")) : meetingStartDate_ !== undefined && meetingStartDate_ !== '' ? new Date(meetingStartDate_) : sessionStorage.getItem("calFrom1") !== null && sessionStorage.getItem("calFrom1") !== '' ? new Date(sessionStorage.getItem("calFrom1").replace("'", "")) : new Date(), endDate: '', singleDatePicker: true,
                                            showDropdowns: true, autoApply: true, minDate: new Date()
                                        }} >
                                        <input id={'meetingStartDate'} type="text" autoComplete='off' className='form-control form-control-sm' />
                                    </DateRangePicker>
                                    <p className="error-text" id='startDateErrorMsg'></p>
                                </div>
                                <div className='col-md-6 dropdown-search-text'>
                                    <label class="h6 text-primary mb-2 col-lg-12" for="meetingEndDate">End Date<span className="error-icon meeting" id='spnMeetingEndDate'> *</span></label>
                                    <DateRangePicker key={meetingEndDate_}
                                        onHide={calGetMeetingEndDate}
                                        initialSettings={{
                                            startDate: document.querySelector('#meetingEndDate') !== null ? document.querySelector('#meetingEndDate').value !== '' ? new Date(document.querySelector('#meetingEndDate').value.replace("'", "")) : meetingEndDate_ !== undefined && meetingEndDate_ !== '' ? new Date(meetingEndDate_) : new Date(sessionStorage.getItem("calTo1").replace("'", "")) : meetingEndDate_ !== undefined && meetingEndDate_ !== '' ? new Date(meetingEndDate_) : sessionStorage.getItem("calTo1") !== null && sessionStorage.getItem("calTo1") !== '' ? new Date(sessionStorage.getItem("calTo1").replace("'", "")) : new Date(), endDate: '', singleDatePicker: true,
                                            showDropdowns: true, autoApply: true, minDate: new Date()
                                        }} >
                                        <input id={'meetingEndDate'} type="text" autoComplete='off' className='form-control form-control-sm' />
                                    </DateRangePicker>
                                    <p className="error-text" id='endDateErrorMsg'></p>
                                </div>
                            </div>
                            <div className='row mb-3'>
                                <div className="col-md-6 col-sm-4">
                                    <label htmlFor="meetingStartTime" className="mb-2 h6 text-primary col-lg-12">Start Time<span className="error-icon meeting" id='spnMeetingStartTime'> *</span></label>
                                    <input type="time" id='meetingStartTime' className="form-control form-control-sm" onBlur={onBlurCheck} />
                                    <p className="error-text" id='startTimeErrorMsg'></p>
                                </div>
                                <div className="col-md-6 col-sm-4">
                                    <label htmlFor="meetingEndTime" className="mb-2 h6 text-primary col-lg-12">End Time<span className="error-icon meeting" id='spnMeetingEndTime'> *</span></label>
                                    <input type="time" id='meetingEndTime' className="form-control form-control-sm" onBlur={onBlurCheck} />
                                    <p className="error-text" id='endTimeErrorMsg'></p>
                                </div>
                            </div>
                            <div className='row mb-3'>
                                <div className='col-lg-12'>
                                    <label class="h6 text-primary mb-2 col-lg-12" for="purposeOfTravel">Trip Purpose<span className="error-icon meeting" id='spnPurposeOfTravel'> *</span></label>
                                    <div className="dropdown pb-2 col-md-12">
                                        <Dropdown onChange={purposeOfTravelChangeHandler}
                                            value={purposeOfTravelOptions.filter(option => option.value === purposeOfTravelValue)}
                                            options={purposeOfTravelOptions}
                                            textField="value"
                                            dataItemKey="label"
                                            arrowClosed={<span className="arrow-closed" />}
                                            arrowOpen={<span className="arrow-open" />} controlClassName='mt-1 form-control control dropdown-search form-select bg_input mb-0'
                                        />
                                        <p className="error-text" id='purposeOfTravelErrorMsg'></p>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-3'>
                                <div className='col-lg-12'>
                                    <label class="h6 text-primary mb-2 col-lg-12" for="tripPurposeRemarks">
                                        Trip Purpose Remarks
                                    </label>
                                    <div className="dropdown pb-2 col-md-12">
                                        <textarea id='tripPurposeRemarks'
                                            className="form-control form-control-sm"
                                            rows={3} cols={100} maxLength={300}
                                            onBlur={onTripPurposeRemarksBlur}
                                        />
                                        {tripPurposeRemarksHasInvalidData &&
                                            <p className="error-text">
                                                Please specify valid data.
                                            </p>
                                        }
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Fragment>)
};

export default MeetingDetails;