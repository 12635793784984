import {
    Fragment,
    useState,
    useEffect,
    useCallback,
    useMemo
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation } from 'react-router-dom';
import Dropdown from 'react-select';

import { checkSecurityDetails } from '../../../utils/RoleUtils';

import { PAGE_MODE } from '../../../config/CONSTANTS';

import {
    HIGHRISKDESTINATION
} from '../../../navigation/CONSTANTS';
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle'
import LoadingSpinner from '../../../components/Common/Loading/LoadingSpinner';
import ErrorModal from "../../../components/Common/Error/ErrorModal";

import { getAllCountry } from '../../../services/Country-API';
import { searchCity } from '../../../services/City-API';

import HighRiskDestinationBanner from './Components/HighRiskDestinationBanner';
import useHttp from '../../../services/use-http';

import {
    getSingleHighRiskDestination,
    addHighRiskDestination,
    updateHighRiskDestination
} from '../../../services/HighRiskDestination-API';

function UpdateHighRiskDestinationContainer({ onReset }) {

    const [isAjaxInProgress, setIsAjaxInProgress] = useState(false);
    const [alertModal, setAlertModal] = useState(null);

    function handleAlertModalConfirm() {
        setAlertModal(null);
    }

    return (
        <Fragment>

            {isAjaxInProgress &&
                <LoadingSpinner></LoadingSpinner>}

            {alertModal &&
                <ErrorModal
                    title={alertModal.title}
                    message={alertModal.message}
                    onConfirm={handleAlertModalConfirm}>
                </ErrorModal>}

            <HighRiskDestinationBanner></HighRiskDestinationBanner>

            <UpdateHighRiskDestinationForm
                onReset={onReset}
                setIsAjaxInProgress={setIsAjaxInProgress}
            >
            </UpdateHighRiskDestinationForm>

        </Fragment>
    );
}

function UpdateHighRiskDestinationForm({ onReset }) {
    const { t } = useTranslation(['translation.HighRiskDestination']);
    const location = useLocation();

    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [showLoader, setShowLoader] = useState(false);
    const [mode] = useState(getMode());

    const params = useParams();
    const { Id } = params;

    let addUpdateHighRiskDestination = addHighRiskDestination;
    if (mode === PAGE_MODE.UPDATE) {
        addUpdateHighRiskDestination = updateHighRiskDestination;
    }

    const { sendRequest: sendGetHighRiskDestinationRequest, data: destinationResponse, status: getHighRiskDestinationStatus } = useHttp(getSingleHighRiskDestination);

    const [selectedCountryOption, setSelectedCountryOption] = useState(null);
    const [countryOptions, setCountryOptions] = useState([]);
    const { sendRequest: getCountryOptions, data: countryMaster, status: getCountryOptionsStatus } = useHttp(getAllCountry);
    const [showCountryRequiredError, setShowCountryRequiredError] = useState(false);

    const [selectedCityOptions, setSelectedCityOptions] = useState([]);
    const [cityOptions, setCityOptions] = useState([]);
    const { sendRequest: getCityOptions, data: cityMaster, status: getCityOptionsStatus } = useHttp(searchCity);

    const { sendRequest: sendAddUpdateHighRiskDestinationRequest, data: addUpdateResponse } = useHttp(addUpdateHighRiskDestination);
    const [isAddUpdateSuccessful, setIsAddUpdateSuccessful] = useState();

    const [alertModal, setAlertModal] = useState(null);

    const formTitle = useMemo(() => {
        let title = '';

        switch (mode) {
            case PAGE_MODE.ADD:
                title = t('addFormTitle');
                break;
            case PAGE_MODE.UPDATE:
                title = t('editFormTitle');
                break;
            default:
                title = t('viewFormTitle');
                break;
        }
        return title;
    }, [mode, t]);

    let showSaveButton = false;

    function getMode() {
        const locationPathParts = location.pathname.split('/');
        const mode = locationPathParts[2].toUpperCase();
        return mode;
    }

    function handleSave(event) {
        event.preventDefault();

        if (selectedCountryOption) {
            setShowCountryRequiredError(false);
            setShowLoader(true);

            let addUpdateRequestData = {
                id: Id,
                userID: JSON.parse(localStorage.getItem('userID')),
                officeID: JSON.parse(localStorage.getItem('officeID')),
                countryCode: selectedCountryOption.value,
                cityList: []
            };

            if (selectedCityOptions && selectedCityOptions.length) {
                selectedCityOptions.forEach((cityOption) => {
                    addUpdateRequestData.cityList.push({
                        cityCode: cityOption.value,
                        cityName: cityOption.label
                    });
                });
            }

            sendAddUpdateHighRiskDestinationRequest(addUpdateRequestData);
        }
        else {
            setShowCountryRequiredError(true);
        }
    }

    function handleReset(event) {
        event.preventDefault();
        if (mode === PAGE_MODE.UPDATE) {
            window.location.reload();
        }
        else {
            onReset();
        }
    }

    const initCountries = useCallback(() => {
        getCountryOptions();
    }, [getCountryOptions]);

    const initCities = useCallback((countryCode) => {
        getCityOptions({
            countryCode: countryCode,
            cityCode: '',
            cityName: ''
        });
    }, [getCityOptions]);

    function handleCountrySelect(selectedOption) {
        setSelectedCountryOption(selectedOption);
        setSelectedCityOptions([]);
        setCityOptions([]);
        initCities(selectedOption.value);
        setShowCountryRequiredError(false);
    };

    function handleCitySelect(selectedOptions) {
        setSelectedCityOptions(selectedOptions);
    }

    function handleAlertModalConfirm() {
        if (isAddUpdateSuccessful) {
            window.location.href = HIGHRISKDESTINATION;
        }
        else {
            setAlertModal(null);
        }
    }

    if ((mode === PAGE_MODE.ADD && checkSecurityDetails("Master", "HighRiskDestination", "Master_HighRiskDestination_Insert")) ||
        (mode === PAGE_MODE.UPDATE && checkSecurityDetails("Master", "HighRiskDestination", "Master_HighRiskDestination_Update"))) {
        showSaveButton = true;
    }

    useEffect(() => {

        if (mode !== PAGE_MODE.ADD) {
            setShowLoader(true);
            sendGetHighRiskDestinationRequest(Id);
        }

        if (mode === PAGE_MODE.ADD) {
            initCountries();
        }

        if (window.scrollY > 100) {
            window.scrollTo({
                top: 100,
                behavior: "smooth",
            });
        }

    }, []);

    //handle after getting country master data from api
    useEffect(() => {
        if (countryMaster
            && countryMaster.errors.status === 'FALSE'
            && countryMaster.data) {

            let selectedCountryOptionFromDB = null;

            const countryMasterOptions = countryMaster.data.map(country => {

                //Since we don't get country name from the get response, we need to extract it from the master list
                if (!selectedCountryOptionFromDB && destinationResponse && destinationResponse.data.countryCode) {
                    if (destinationResponse.data.countryCode === country.countryCode) {
                        selectedCountryOptionFromDB = {
                            value: country.countryCode,
                            label: country.countryName
                        };
                    }
                }

                return {
                    value: country.countryCode,
                    label: country.countryName,
                };
            });

            setCountryOptions(countryMasterOptions);

            if (selectedCountryOptionFromDB) {
                setSelectedCountryOption(selectedCountryOptionFromDB);
            }
        }
    }, [countryMaster, destinationResponse]);

    //handle after getting city data from api
    useEffect(() => {
        if (cityMaster
            && cityMaster.errors.status === 'FALSE'
            && cityMaster.data) {

            let tempSelectedCityOptions = cityMaster.data.map(city => {
                return {
                    value: city.cityCode,
                    label: `${city.cityName} (${city.cityCode})`
                };
            });

            setCityOptions(tempSelectedCityOptions);

            //Since we don't get city name from the get response, we need to extract it from the master list
            if (isInitialLoad) {
                if (destinationResponse && destinationResponse.data.cityList && destinationResponse.data.cityList.length) {
                    setSelectedCityOptions(destinationResponse.data.cityList.map(city => {
                        const matchingCityOption = tempSelectedCityOptions.find(option => option.value === city.cityCode);

                        return {
                            value: city.cityCode,
                            label: matchingCityOption ? matchingCityOption.label : ''
                        };
                    }));
                }
            }

        }
    }, [cityMaster, destinationResponse, isInitialLoad]);

    //handle after getting information regarding the edited destination
    useEffect(() => {

        if (destinationResponse
            && destinationResponse.errors.status === 'FALSE'
            && destinationResponse.data) {

            initCountries();

            initCities(destinationResponse.data.countryCode);
        }
    }, [destinationResponse, mode, initCountries, initCities]);

    //handle post add/update api request
    useEffect(() => {
        if (addUpdateResponse) {

            setShowLoader(false);

            if (addUpdateResponse.errors.status === 'FALSE') {
                setIsAddUpdateSuccessful(true);
                setAlertModal({
                    title: t('alertTitle'),
                    message: t('msgRecordAddedSuccessful')
                });
            }
            else {
                setIsAddUpdateSuccessful(false);
                let errorDescription = '';
                if (addUpdateResponse.errors.error.code === '1009') {
                    errorDescription = t('msgDuplicateEntry');
                }
                else {
                    errorDescription = t('msgRecordNotSaved');
                }

                setAlertModal({
                    title: t('alertTitle'),
                    message: errorDescription
                });
            }
        }
    }, [addUpdateResponse, t]);

    //handle loader toggle based on api progress status
    useEffect(() => {
        if (mode !== PAGE_MODE.ADD) {
            if (getHighRiskDestinationStatus === 'completed'
                && getCountryOptionsStatus === 'completed'
                && getCityOptionsStatus === 'completed') {
                setShowLoader(false);
                setIsInitialLoad(false);
            }
        }
    }, [mode, getHighRiskDestinationStatus, getCountryOptionsStatus, getCityOptionsStatus]);

    return (
        <Fragment>

            {alertModal &&
                <ErrorModal
                    title={alertModal.title}
                    message={alertModal.message}
                    onConfirm={handleAlertModalConfirm}>
                </ErrorModal>}

            <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="">
                                <div className="row">
                                    <div className="col-lg-2"></div>
                                    <div className="col-lg-8 mb-30">
                                        <div className="card card-primary card-outline">

                                            {showLoader
                                                &&
                                                <LoadingSpinner></LoadingSpinner>}

                                            <div className="card-body">
                                                <div className="col-md-12 ">
                                                    <div className="form-group col-md-12">
                                                        <label htmlFor="" className="h5 text-primary mb-3 col-lg-12">
                                                            <i className="fas fa-globe-asia"></i>
                                                            <span id='pageTitle'>
                                                                {' ' + formTitle}
                                                            </span>
                                                        </label>

                                                        <form className='form'>
                                                            <div className='control'>
                                                                <label className="mb-2 h6 text-primary col-lg-12" htmlFor='country'>
                                                                    {t('country')}<span className="error-icon"> *</span>
                                                                </label>

                                                                <Dropdown
                                                                    value={selectedCountryOption}
                                                                    options={countryOptions || []}
                                                                    onChange={handleCountrySelect}
                                                                    isDisabled={mode === PAGE_MODE.VIEW}
                                                                    isLoading={getCountryOptionsStatus === 'pending'}
                                                                    controlClassName="form-control control form-select bg_input mb-0"
                                                                    placeholder="Please Select"
                                                                />

                                                                {showCountryRequiredError &&
                                                                    <p className="error-text">
                                                                        {t('errCountryRequired')}
                                                                    </p>
                                                                }

                                                            </div>
                                                            <div className='control'>
                                                                <label className="mb-2 h6 text-primary col-lg-12" htmlFor='city'>City</label>

                                                                <Dropdown
                                                                    value={selectedCityOptions}
                                                                    options={cityOptions || []}
                                                                    onChange={handleCitySelect}
                                                                    isDisabled={mode === PAGE_MODE.VIEW}
                                                                    isLoading={getCityOptionsStatus === 'pending'}
                                                                    isMulti
                                                                    controlClassName="form-control control form-select bg_input mb-0"
                                                                    placeholder="Please Select"
                                                                />

                                                            </div>

                                                            <div className='text-primary my-3'>
                                                                <span className="text-black fw-bold">Note:{' '}</span>
                                                                {t('noteSpecifyCountryCity')}
                                                            </div>

                                                            <p className='mt-3'>
                                                                <span className="error-icon">*</span>
                                                                <span id='requiredIndicator'>
                                                                    {t('requiredFieldsIndicator')}
                                                                </span>
                                                            </p>

                                                            <div className='mt-3 actions row'>
                                                                <div className="col-lg-3 col-md-3 col-sm-3 my-auto">
                                                                    { /*This back button will be shown in large screen*/}
                                                                    <a href={HIGHRISKDESTINATION}
                                                                        className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1 d-none d-sm-block d-sm-none d-md-block'>
                                                                        <span>
                                                                            <i className="fa fa-arrow-left"></i>
                                                                        </span>
                                                                    </a>
                                                                </div>

                                                                <div className="col-lg-9 col-md-9 col-sm-9">
                                                                    <div className="float-md-end float-lg-end">
                                                                        { /*This back button will be shown in small/mobile screen*/}
                                                                        <a href={HIGHRISKDESTINATION}
                                                                            className='btn back-btn-sm text-white mt-1 gt-main-btn ms-1 d-md-none d-lg-none d-xl-none'>
                                                                            <span>
                                                                                <i className="fa fa-arrow-left"></i>
                                                                            </span>
                                                                        </a>

                                                                        {
                                                                            mode !== 'VIEW' && showSaveButton &&
                                                                            <button id="btnSave"
                                                                                onClick={handleSave}
                                                                                className="btn gt-btn_md text-white mt-1 gt-main-btn ms-1">
                                                                                {t('save')}
                                                                            </button>
                                                                        }

                                                                        {
                                                                            mode !== 'VIEW' &&
                                                                            <button id="btnReset"
                                                                                onClick={handleReset}
                                                                                className="btn gt-btn_md text-white mt-1 gt-main-btn ms-1">
                                                                                {t('reset')}
                                                                            </button>
                                                                        }

                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <div className="mt-3 col-md-12 actions">

                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-2"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </Fragment>
    );
}


const UpdateHighRiskDestination = () => {
    const { t } = useTranslation(['translation.HighRiskDestination']);
    useDocumentTitle(t('pageTitle'));

    const [allComponentsKey, setAllComponentsKey] = useState(1);
    function handleReset() {
        //updating key will reset the whole component
        setAllComponentsKey(s => s + 1);
    }

    return (
        <UpdateHighRiskDestinationContainer
            key={allComponentsKey}
            onReset={handleReset}>
        </UpdateHighRiskDestinationContainer>
    );
};


export default UpdateHighRiskDestination;