import { Fragment, useEffect, useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import PopupModal from "../../../components/Common/Flight/PrintPopupModal";
import useDocumentTitle from "../../../components/Common/layout/useDocumentTitle";
import ApproverDetails from "../Components/SubComponents/ApproverDetails"
import CountryDropDownList from "../../../components/Common/DropDownList/CountryDropDownList";
import CityDropDownList from "../../../components/Common/DropDownList/CityDropDownList";
import moment from 'moment';
import Flight from "../../OBT/RetreiveTravelRequest/Flight/Flight";
import HotelDetails from "../../OBT/RetreiveTravelRequest/Hotel/HotelDetails";
import ServiceRequest from "../../OBT/RetreiveTravelRequest/ServiceRequest";




const RetrieveTravelRequest = () => {
    useDocumentTitle("View Travel Request")
    const [errorMail, setErrorMail] = useState();
    const [purposeOfTravel, setpurposeOfTravel] = useState('');
    let travelRequestData = sessionStorage.getItem("travelRequestData") !== null ? JSON.parse(sessionStorage.getItem("travelRequestData")) : null;
    //State Variable
    const [countryCode, setCountryCode] = useState('');
    const [cityCode, setCityCode] = useState('');
    const [meetingStartDate, setMeetingStartDate] = useState('');
    const [meetingEndDate, setMeetingEndDate] = useState('');
    const [meetingStartTime, setMeetingStartTime] = useState('');
    const [meetingEndTime, setMeetingEndTime] = useState('');
    const [approverName, setApproverName] = useState('');
    const [employeeName, setEmployeeName] = useState('');
    const [approverCode, setApproverCode] = useState('');
    const [employeeCode, setEmployeeCode] = useState('');
    const [tripPurposeRemarks, setTripPurposeRemarks] = useState("");
    let onlineFlightLowestDetails = travelRequestData !== null && travelRequestData.data !== undefined && travelRequestData.data.onlineFlightLowestDetails !== undefined ? travelRequestData.data.onlineFlightLowestDetails : "";
    const lowestIdMap = {};
    if (onlineFlightLowestDetails !== undefined && onlineFlightLowestDetails.length !== 0) {
        onlineFlightLowestDetails.forEach(detail => {
            if (!lowestIdMap[detail.lowestID]) {
                lowestIdMap[detail.lowestID] = [];
            }
            lowestIdMap[detail.lowestID].push(detail);
        });
    }


    const onSendApproverDetails = () => {
        setErrorMail({
            title: "Status Update",
            message: <div className='col-md-12'>
                <div className='gt-h-400px'>
                    <ApproverDetails errorHandler={errorHandler}></ApproverDetails>
                </div></div>,
        })

    }


    useEffect(() => {
        window.scrollTo({
            top: 100,
            behavior: "smooth",
        });
        if (travelRequestData !== null && travelRequestData !== undefined && travelRequestData !== "" && travelRequestData.data !== null && travelRequestData.data !== undefined) {
            if (travelRequestData.data.meetingDetails.countryCode !== undefined) {
                setCountryCode(travelRequestData.data.meetingDetails.countryCode);
            }
            if (travelRequestData.data.meetingDetails.cityCode !== undefined) {
                setCityCode(travelRequestData.data.meetingDetails.cityCode)
            }
            if (travelRequestData.data.meetingDetails.meetingStartDate !== undefined && travelRequestData.data.meetingDetails.meetingStartDate !== '') {
                const formattedDate = moment(travelRequestData.data.meetingDetails.meetingStartDate, "YYYYMMDD").format("D MMM YYYY");
                setMeetingStartDate(formattedDate);

            }
            if (travelRequestData.data.meetingDetails.meetingEndDate !== undefined && travelRequestData.data.meetingDetails.meetingEndDate !== '') {
                const formattedDate = moment(travelRequestData.data.meetingDetails.meetingEndDate, "YYYYMMDD").format("D MMM YYYY");
                setMeetingEndDate(formattedDate);

            }
            if (travelRequestData.data.meetingDetails.meetingStartTime !== undefined && travelRequestData.data.meetingDetails.meetingStartTime !== '') {
                const formattedTime = travelRequestData.data.meetingDetails.meetingStartTime.substring(0, 2) + ":" + travelRequestData.data.meetingDetails.meetingStartTime.substring(2, 4);
                setMeetingStartTime(formattedTime);

            }
            if (travelRequestData.data.meetingDetails.meetingEndTime !== undefined && travelRequestData.data.meetingDetails.meetingEndTime !== '') {
                const formattedTime = travelRequestData.data.meetingDetails.meetingEndTime.substring(0, 2) + ":" + travelRequestData.data.meetingDetails.meetingEndTime.substring(2, 4);
                setMeetingEndTime(formattedTime);

            }
            if (travelRequestData.data.meetingDetails.purposeDetail !== undefined && travelRequestData.data.meetingDetails.purposeDetail !== '') {
                setpurposeOfTravel(travelRequestData.data.meetingDetails.purposeDetail);
            }
            if (travelRequestData.data.meetingDetails.purposeDetails !== undefined && travelRequestData.data.meetingDetails.purposeDetails !== '' && travelRequestData.data.meetingDetails.purposeDetails.trim().length !== 0) {
                setTripPurposeRemarks(travelRequestData.data.meetingDetails.purposeDetails);
            }
            if (travelRequestData.data.approverDetails.employeeName !== undefined) {
                setEmployeeName(travelRequestData.data.approverDetails.employeeName)
            }
            if (travelRequestData.data.approverDetails.employeeCode !== undefined) {
                setEmployeeCode(travelRequestData.data.approverDetails.employeeCode)
            }
            if (travelRequestData.data.approverDetails.apporverEmployeeCode !== undefined) {
                setApproverCode(travelRequestData.data.approverDetails.apporverEmployeeCode)
            }
            if (travelRequestData.data.approverDetails.apporverEmployeeName !== undefined) {
                setApproverName(travelRequestData.data.approverDetails.apporverEmployeeName)
            }


        }

    }, [])

    const errorHandler = () => {
        setErrorMail(null);
    };

    const purposeChangeHandler = (event) => {
        setpurposeOfTravel(event.value)
    };
    const countryCodeValue = (countryCode) => {
        setCountryCode(countryCode);
    };
    const countryNameText = (country_Name) => {
        //setCountry_Name(country_Name);
    };
    const cityCodeValue = (cityCode) => {
        setCityCode(cityCode);
    };
    const cityNameText = (cityName) => {
        //setCityName(cityName);        
    };
    return (<Fragment>
        {
            errorMail && (
                <PopupModal
                    title={errorMail.title}
                    message={errorMail.message}
                    onConfirm={errorHandler}
                >
                </PopupModal>
            )
        }
        <div >

            <section id="common_banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                        </div>
                    </div>
                </div>
            </section>
            <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">

                            <div className="theme_search_form_area mb-3">
                                {JSON.parse(localStorage.getItem('displayName')) === "Rahul Srinivasan" && <div className='col-md-12'>
                                    <div className='float-lg-end'>
                                        <span id="sendMail1" className='btn btn-sm text-white mt-1 ms-1 btn-primary float-end hidereportmenu'><i className="fas fa-envelope"></i>
                                        </span>
                                        <ReactTooltip
                                            anchorId={"sendMail1"}
                                            place="bottom"
                                            variant="info"
                                            multiline={true}
                                            className="tooltip"
                                            html={"Email"}
                                        />
                                    </div>
                                    <div className='float-lg-end'>
                                        <span id="print" className='btn btn-sm text-white mt-1 ms-1 gt-custom-btn-sky-blue float-end'><i className="fa-solid fa-print"></i>
                                        </span>
                                        <ReactTooltip
                                            anchorId={"print"}
                                            place="bottom"
                                            variant="info"
                                            multiline={true}
                                            className="tooltip"
                                            html={"Print"}
                                        />
                                    </div>
                                    <div className='float-lg-end'>
                                        <span id="approverDetails" className='btn btn-sm text-white mt-1 ms-1 gt-main-btn  float-end' onClick={() => onSendApproverDetails()}>
                                            <i class="fas fa-user-plus"></i>
                                        </span>
                                        <ReactTooltip
                                            anchorId={"approverDetails"}
                                            place="bottom"
                                            variant="info"
                                            multiline={true}
                                            className="tooltip"
                                            html={"Approve | Reject"}
                                        />
                                    </div>

                                    <div className='float-lg-end'>
                                        <span id="history" className='btn btn-sm text-white mt-1 ms-1 btn-secondary float-end hidemenu hidereportmenu' ><i className="fas fa-history"></i>
                                        </span>
                                        <ReactTooltip
                                            anchorId={"history"}
                                            place="bottom"
                                            variant="info"
                                            multiline={true}
                                            className="tooltip"
                                            html={"Approval History"}
                                        />
                                    </div>

                                </div>}
                                <div className="col-lg-12 ">
                                    <div className=''>
                                        <div className='col-md-6'>
                                            <label for="" className="h5 text-primary mb-3"><i className="fas fa-globe-asia"></i> Travel Request View</label>
                                        </div>
                                    </div>
                                    <p className="h5 mb-3 mt-2 text-black fw-bold">Meeting Details</p>
                                    <div className="row">
                                        <div className='control col-lg-6'>
                                            <label htmlFor='name' className="h6 text-primary mb-2 col-lg-12">Country</label>
                                            <CountryDropDownList countryCodeValue={countryCodeValue} countryNameText={countryNameText} currentInputValue={countryCode} iSDisabled={'true'} />
                                        </div>
                                        <div className='control col-lg-6'>
                                            <label htmlFor='code' className="h6 text-primary mb-2 col-lg-12">City</label>
                                            <CityDropDownList cityCodeValue={cityCodeValue} cityNameText={cityNameText} currentInputValue={cityCode} countryCode={countryCode} iSDisabled={'true'} />

                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className='control col-lg-6'>
                                            <label htmlFor='code' className="h6 text-primary mb-2 col-lg-12">Start Date</label>
                                            <input className="mb-2 form-control form-control-sm col-lg-12" type='text' readOnly value={meetingStartDate}
                                            />
                                        </div>
                                        <div className='control col-lg-6'>
                                            <label htmlFor='code' className="h6 text-primary mb-2 col-lg-12">End Date</label>
                                            <input className="mb-2 form-control form-control-sm col-lg-12" type='text' readOnly value={meetingEndDate}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className='control col-lg-6'>
                                            <label htmlFor='name' className="h6 text-primary mb-2 col-lg-12">Start Time </label>
                                            <input className="mb-2 form-control form-control-sm col-lg-12" type='text' id='name' readOnly value={meetingStartTime} />
                                        </div>
                                        <div className='control col-lg-6'>
                                            <label htmlFor='code' className="h6 text-primary mb-2 col-lg-12">End Time</label>
                                            <input className="mb-2 form-control form-control-sm col-lg-12" type='text' readOnly value={meetingEndTime}
                                            />

                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className='control col-lg-12'>
                                            <label htmlFor='code' className="h6 text-primary mb-2 col-lg-12">Trip Purpose</label>
                                            <input className="mb-2 form-control form-control-sm col-lg-12" type='text' readOnly value={purposeOfTravel}
                                            />

                                        </div>

                                    </div>
                                    {tripPurposeRemarks !== undefined && tripPurposeRemarks.length !== 0 && <div className='row mb-3'>
                                        <div className=' control col-lg-12'>
                                            <label class="h6 text-primary mb-2 col-lg-12" for="tripPurposeRemarks">
                                                Trip Purpose Remarks
                                            </label>
                                            <div className="dropdown pb-2 col-md-12">
                                                <textarea id='tripPurposeRemarks'
                                                    className="form-control form-control-sm tr-textbox-height"
                                                    rows={3} cols={100} maxLength={300}
                                                    value={tripPurposeRemarks}
                                                    readOnly

                                                />

                                            </div>
                                        </div>
                                    </div>}
                                    <hr></hr>
                                    <p className="h5 mb-3 text-black fw-bold">Approver</p>
                                    <div className="row">
                                        <div className='control col-lg-6'>
                                            <label htmlFor='name' className="h6 text-primary mb-2 col-lg-12">Employee Name </label>
                                            <input className="mb-2 form-control form-control-sm col-lg-12" type='text' id='name' readOnly value={employeeName} />
                                        </div>
                                        <div className='control col-lg-6'>
                                            <label htmlFor='code' className="h6 text-primary mb-2 col-lg-12">Employee Code</label>
                                            <input className="mb-2 form-control form-control-sm col-lg-12" type='text' id='code' readOnly value={employeeCode}
                                            />

                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className='control col-lg-6'>
                                            <label htmlFor='code' className="h6 text-primary mb-2 col-lg-12">First Approver Name  </label>
                                            <input className="mb-2 form-control form-control-sm col-lg-12" type='text' id='code' readOnly value={approverName}
                                            />

                                        </div>
                                        <div className='control col-lg-6'>
                                            <label htmlFor='name' className="h6 text-primary mb-2 col-lg-12">First Approver Code </label>
                                            <input className="mb-2 form-control form-control-sm col-lg-12" type='text' id='name' readOnly value={approverCode} />
                                        </div>


                                    </div>
                                    {travelRequestData.data.onlineFlightDetails !== undefined && <div>
                                        <label for="" class="h5 text-primary mt-5"> <span className='gt-border-1px'> <i class="fab fa-avianex"></i> Selected Flight Details</span> </label>
                                        <Flight flightDetails={travelRequestData.data.onlineFlightDetails} id={"onlineFlightDetails"} fareDetails={travelRequestData.data.onlineFlightFareDetails} id1={"onlineFlightDetails"} ></Flight>
                                    </div>}
                                    {travelRequestData.data.onlineFlightLowestDetails !== undefined && <div>
                                        <label for="" class="h5 text-primary mt-5"> <span className='gt-border-1px'> <i class="fab fa-avianex"></i> Lowest Flight Details</span> </label>
                                        {Object.keys(lowestIdMap).map(lowestId => (
                                            <Flight flightDetails={lowestIdMap[lowestId]} id={"onlineFlightLowestDetails" + lowestId} fareDetails={travelRequestData.data.onlineFlightLowestFareDetails} id1={lowestId}></Flight>
                                        )
                                        )}

                                    </div>}
                                    {travelRequestData.data.onlineHotelDetails !== undefined && <div>
                                        <label for="" class="h5 text-primary mt-5"> <span class="gt-border-1px"> <i class="fas fa-hotel"></i> Selected Hotel Details</span> </label>
                                        <HotelDetails hotelDetails={travelRequestData.data.onlineHotelDetails[0]} hotelType={"onlineHotelDetails"}></HotelDetails>
                                    </div>}
                                    {travelRequestData.data.onlineHotelLowestDetails !== undefined && <div>
                                        <label for="" class="h5 text-primary mt-5"> <span class="gt-border-1px"> <i class="fas fa-hotel"></i> Lowest Hotel Details</span> </label>
                                        {travelRequestData.data.onlineHotelLowestDetails.map(hotel => (
                                            <HotelDetails hotelDetails={hotel} key={"lowestHotel" + hotel.lowestID} hotelType={'onlineHotelLowestDetails'} id={hotel.lowestID}></HotelDetails>
                                        ))}

                                    </div>}

                                    {travelRequestData.data.serviceRequest !== undefined &&
                                        <ServiceRequest serviceRequest={travelRequestData.data.serviceRequest}></ServiceRequest>

                                    }

                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </section>

        </div >
    </Fragment >);
}

export default RetrieveTravelRequest;