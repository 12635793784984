import { Fragment, useState, useEffect, useContext } from "react";
import useHttp from '../../../services/use-http';
import ConfirmationModal from "../../../components/Common/Confirmation/ConfirmationModal";
import { useTranslation } from 'react-i18next'
import { checkSecurityDetails } from '../../../utils/RoleUtils'
import { AuthenticationWithLogin } from '../../../services/Authentication-API'
import { FLIGHTSEARCH, SEARCHHOTEL, SEARCHPNR, TRAVELREQUEST, SEARCHSIGHTSEEING, SEARCHHOLIDAYS, IMPORTPNR, CRYPTIC } from '../../../navigation/CONSTANTS';
import AuthContext from '../../../redux/contexts/auth-context'
import { useHistory } from 'react-router-dom';
import ErrorModal from "../../../components/Common/Error/ErrorModal";
import { getConfigurationByBoolen,getConfigurationByValue } from '../../../utils/ConfigurationsFunction'
import secureLocalStorage from 'react-secure-storage';
const TravelAgentItem = (props) => {
    const { t } = useTranslation(['translation.TravelAgent']);
    const [confirm, setConfirm] = useState(false);
    const [errors, setErrors] = useState();
    const history = useHistory();
    const { sendRequest, status, data: loadedAuthenticationDetails } = useHttp(AuthenticationWithLogin);


    function onAuthenticationWithLoginID(loginID) {
        sessionStorage.clear();
        const strInput = ({
            "userName": loginID,
            "password": "",
            "rootUserName": localStorage.getItem("RootLoginEmailID")
        });
        sendRequest(strInput);
    }
    const authCtx = useContext(AuthContext);
    useEffect(() => {
        window.scrollTo({
            top: 100,
            behavior: "smooth",
        });

        let loadedData = [];

        if (status === 'completed') {
            for (let i in loadedAuthenticationDetails) {
                if (loadedAuthenticationDetails['errors'].status === 'FALSE') {
                    if (i === 'data') {
                        loadedData = loadedAuthenticationDetails[i];

                        const expirationTime = new Date(
                            new Date().getTime() + +loadedData.expireIn * 1000
                        );

                        authCtx.login(loadedData.idToken, expirationTime.toISOString(),
                            loadedData.loginEmailID, loadedData.userID, loadedData.officeID,
                            loadedData.displayName, loadedData.roleDetails, loadedData.customizeDetails,
                            loadedData.companyName, loadedData.traceID, loadedData.profileLocaterNumber,
                            loadedData.configurationsDetails, loadedData.dkNumber, loadedData.parentOfficeID,
                            loadedData.defaultCity, loadedData.isCorporate, loadedData.isTravelAgent,
                            loadedData.email, loadedData.phoneCode, loadedData.phoneNumber, loadedData);



                        if (loadedData.isCorporate)
                            window.location.pathname = `${TRAVELREQUEST}`;
                        else if (loadedData.isTravelAgent === "1")
                            window.location.pathname = `${SEARCHPNR}`;
                        else if (getConfigurationByBoolen("ALLOW_FLIGHT") && checkSecurityDetails("Book", "Flight") && checkSecurityDetails("Book", "Flight", "Book_Flight_Search"))
                            window.location.pathname = `${FLIGHTSEARCH}`;
                        else if (getConfigurationByBoolen("ALLOW_HOTEL") && checkSecurityDetails("Book", "Hotel") && checkSecurityDetails("Book", "Hotel", "Book_Hotel_Search"))
                            window.location.pathname = `${SEARCHHOTEL}`;
                        else if (checkSecurityDetails("Book", "Sightseeing") && getConfigurationByBoolen("ALLOW_SIGHTSEEING") === true) {
                            window.location.pathname = `${SEARCHSIGHTSEEING}`;
                        }
                        else if (getConfigurationByBoolen("ALLOW_HOLIDAYS") === true && getConfigurationByValue('HOLIDAYS_URL') !== null && getConfigurationByValue('HOLIDAYS_URL') !== '') {
                            window.location.pathname = `${SEARCHHOLIDAYS}`;
                        } else if ((checkSecurityDetails("Book", "ImportPNR") && getConfigurationByBoolen("ALLOW_IMPORT_PNR") === true)) {
                            window.location.pathname = `${SEARCHPNR}`;
                        } else if ((checkSecurityDetails("Book", "SabreHost") && getConfigurationByBoolen("ALLOW_SABRE_HOST") === true)) {
                            window.location.pathname = `${CRYPTIC}`;
                        }
                        else
                            window.location.pathname = `${SEARCHPNR}`;



                    }
                }
                else if (loadedAuthenticationDetails['errors'].status === 'TRUE') {
                    setErrors({
                        title: "Message",
                        message: 'Please enter the correct authenticator code.',
                    });
                    if (document.getElementById('Inputsecuritycode') !== null) {
                        document.getElementById('Inputsecuritycode').value = '';
                    }

                }
            }
        }



    }, [status, loadedAuthenticationDetails, authCtx, history])

    function okHandler() {

        setConfirm(false);
    };
    const errorHandler = () => {
        setErrors(null);
    };
    function cancelHandler() {
        setConfirm(false);
    };
    let isUserActive;
    const isActive = props.isActive.toString();
    if (isActive === 'true') {
        isUserActive = 'Yes';
    }
    else if (isActive === 'false') {
        isUserActive = 'No';
    }
    const fullName = props.middleName === "" ? props.firstName + " " + props.lastName : props.firstName + " " + props.middleName + " " + props.lastName
    return <Fragment>
        {confirm && (
            <ConfirmationModal
                title={t('confirmationMessageTitle')}
                message={t('confirmationMessage')}
                onOk={okHandler}
                onCancel={cancelHandler}
            ></ConfirmationModal>
        )}
        {errors && (
            <ErrorModal
                title={errors.title}
                message={errors.message}
                onConfirm={errorHandler}
            ></ErrorModal>
        )}

        <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-8 mb-1">
                <div className="card text-dark card-bg-blue mb-2">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-12">
                                <h6 className="card-title fw-bold text-primary mt-1">
                                    {fullName}
                                </h6>
                                <hr className="my-1" />
                            </div>
                            <div className="col-md-8">
                                <p className="card-text mb-0"><strong>Login Email ID: </strong>{props.loginEmailID}</p>


                            </div>
                            <div className="col-md-4 my-auto">
                                <div className='float-lg-end float-md-end'>
                                    {checkSecurityDetails("Master", "Switch", "Master_Switch_Search") &&
                                        <a className='btn btn_theme btn_sm' onClick={() => onAuthenticationWithLoginID(props.loginEmailID)} data-bs-toggle="tooltip" data-bs-placement="top" title="Switch" to={{ pathname: `/NewUser/${props.id}`, edit: 0 }}>
                                            {(status === 'pending') ?
                                                <i className="fa fa-spinner fa-spin" ></i> : <span> Switch
                                                    <i className="fa-solid fa-arrow-right px-1"></i></span>}</a>
                                    }


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>

};

export default TravelAgentItem;