import { removeHtmlTags } from "../../../../utils/CommonFunction";
import Booking from "./Booking";
import FareSummary from "./FareSummary";
const HotelDetails = (props) => {
    let hotelDescription;
    if (props.hotelDetails.hotelDescription !== undefined) {
        // Remove '\n' from the string
        const removedNewlines = props.hotelDetails.hotelDescription.replace(/\n/g, ' ');
        // Split the string based on full stops
        const sentences = removedNewlines.split(/(?<!sq|ft|0)\.(?![a-z]| ft(?= [a-z]))/i);
        if (sentences.length !== 0) {
            let desc = sentences.map((item, index) => {
                if (item.trim() !== '') {
                    const sentenceWithFullStop = index !== sentences.length - 1 ? item + '.' : item;
                    if (index === sentences.length - 1) {
                        return <li key={index} style={{ whiteSpace: 'pre-line' }}>{removeHtmlTags(sentenceWithFullStop)}</li>;
                    }
                    return <li key={index} style={{ whiteSpace: 'pre-line' }}>{removeHtmlTags(sentenceWithFullStop)}<br /><br /></li>;
                }
            });
            hotelDescription = <ul>{desc}</ul>;
        }
        else {
            hotelDescription = removeHtmlTags(props.hotelDetails.hotelDescription);
        }
    }

    return <>
        {props.hotelType === "onlineHotelLowestDetails" && < div className="row mb-2">
            <div className="col-md-6 text-primary mt-2 fw-bold">
                Option {props.id}
            </div>
            <div className="col-md-6">

            </div>
        </div>}
        <div class="hotel-list row">
            <div class="sort-view clearfix"></div>
            <div class="hotel-list-cn clearfix shadow-none">
                <div class="hotel-list-item position-relative">
                    <div class="hotel-text m-0 pt-3">
                        <div class="mb-2"><a title="" class="text-primary fw-bold h3">{props.hotelDetails.hotelName}</a>
                            <div class="row">
                                <div class="col-md-12">
                                    <div className='text-16px my-3'>{props.hotelDetails.address
                                    }</div>
                                </div>
                                <div class="col-md-12 text-16px display-line">
                                    <ul>{hotelDescription}</ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr class="my-1"></hr>
                    <div class="row">
                        <div class="col-md-12 my-auto">
                            <div class="mb-1">
                                {!props.hotelDetails.refundable ? <div className="ms-2 text-14px text-danger mt-2"><i className="fas fa-money-check-alt"></i>  Non Refundable</div> : <div className="ms-2 text-14px text-sky-blue mt-2"><i className="fas fa-money-check-alt"></i>  Refundable</div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Booking hotelDetails={props.hotelDetails}></Booking>
        <hr className="my-2"></hr>
        <FareSummary fareDetails={props.hotelDetails}></FareSummary>
        <hr className="my-2"></hr>
    </>
}

export default HotelDetails;