import { Fragment } from "react";

const WorkflowTypeNote = (props) => {
    return <Fragment>
        {/*Approval - Standard*/}
        {props.approvalTypeID === "2" &&
            <div className="row">
                <div className="col-lg-12">
                    <span className="h6 text-black my-2">
                        <span className="text-black">
                            <i className="fas fa-info-circle"></i>
                            <strong> Note: </strong>
                        </span>
                    </span>
                    <span className="ms-1 text-primary">
                        This workflow will be triggered when an employee of <strong>{props.name} </strong> {props.labelName} submits a travel request.
                    </span>                    
                </div>
            </div>
        }
        {/*Approval - Cost Center*/}
        {props.approvalTypeID === "3" &&
            <div className="row">
                <div className="col-lg-12">
                    <div className="h6 text-black mb-2">
                        <span className="text-black">
                            <i className="fas fa-info-circle"></i>
                            <strong> Note: </strong>
                        </span>
                        <span className="ms-1 mb-2 text-primary">
                            This workflow will be triggered when:
                        </span>
                    </div>
                    <div className="ms-1 text-primary">
                        1. &nbsp;An employee submits a travel request with <strong>{props.name} </strong> as the {props.labelName}.
                    </div>
                    <div className="ms-1 text-primary">
                        2. <strong>After</strong> the travel request has been approved with the workflow "Approval - Standard" for the traveler's original cost center.
                    </div>
                </div>
            </div>
        }
        {/*Approval - High-Risk Destination*/}
        {props.approvalTypeID === "5" &&
            <div className="row">
                <div className="col-lg-12">
                    <div className="h6 text-black mb-2">
                        <span className="text-black">
                            <i className="fas fa-info-circle"></i>
                            <strong> Note: </strong>
                        </span>
                        <span className="ms-1 mb-2 text-primary">This workflow will be triggered when:
                        </span>
                    </div>
                    <div className="ms-1 text-primary">
                        1.&nbsp; An employee submits a travel request with a "High-Risk" destination.
                    </div>
                    <div className="ms-1 text-primary">
                        2. <strong>Before</strong> the travel request has been approved with the workflow "Approval - Standard".
                    </div>
                </div>
            </div>
        }
        {/*Approval - Travel Booker*/}
        {props.approvalTypeID === "6" &&
            <div className="row">
                <div className="col-lg-12" >
                    <div className="h6 text-black mb-2">
                        <span className="text-black">
                            <i className="fas fa-info-circle"></i>
                            <strong> Note: </strong>
                        </span>
                        <span className="ms-1 mb-2 text-primary">This workflow will be triggered when:
                        </span>
                    </div >

                    <div className="ms-1 text-primary">
                        1.&nbsp; An employee submits a travel request that contains an offline service whose price needs to be added by the Accounts or Finance team.
                    </div>
                    <div className="ms-1 text-primary">
                        2. <strong>Before</strong> the travel request has been approved with the workflow "Approval - Standard".
                    </div>
                </div>
            </div>
        }
        {/*Approval - Out of Policy*/}
        {props.approvalTypeID === "7" &&
            <div className="row">
                <div className="col-lg-12">
                    <span className="h6 text-black mb-2">
                        <span className="text-black">
                            <i className="fas fa-info-circle"></i>
                            <strong> Note: </strong>
                        </span>
                    </span >
                    <span className="ms-1 text-primary">
                        This workflow will be triggered when an employee of <strong>{props.name} </strong> {props.labelName} submits an "Out of Policy" travel request.
                    </span>
                </div>
            </div>
        }

    </Fragment>
};

export default WorkflowTypeNote;