// import { Fragment } from 'react';
import { Fragment } from 'react';
import HotelBookingItem from './HotelBookingItem';
import { getConfigurationByBoolen } from '../../../../utils/ConfigurationsFunction';

const HotelBookingList = (props) => {
    // if (Object.keys(props.currentItems).length > 0) {
    //     props.currentItems.push({
    //         "shoppingID": "",
    //         "sequenceNo": "0",
    //         "confirmationNumber": "",
    //         "bookingDate": "",
    //         "officeID": "",
    //         "companyName": "",
    //         "hotelName": "",
    //         "location": "",
    //         "passengerName": "",
    //         "passengerCount": "",
    //         "numberOfRoom": "",
    //         "checkInDate": "",
    //         "checkOutDate": "",
    //         "source": "",
    //         "pnr": "",
    //         "status": "",
    //         "currencyCode": "",
    //         "baseFare": "",
    //         "taxes": "",
    //         "userID": "",
    //         "serviceFee": "",
    //         "totalRate": "",
    //         "commission": "",
    //         "equivTotalFare": props.currentItems.equivTotalFare !== undefined && props.currentItems.equivTotalFare !== '' ? props.currentItems.map(a => parseFloat(a.equivTotalFare)).reduce((equivTotalFare1, equivTotalFare2) => equivTotalFare1 + equivTotalFare2).toFixed(2) : '',
    //         "formOfPayment": "",
    //         "bookedBy": ""
    //     })
    // }

    let reportJSON = JSON.parse(sessionStorage.getItem("reportsData"));

    return <Fragment>

        <div className="col-lg-12" >{/* className="accordion-item"> */}
            <div aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                <div className="">
                    <div className='row'>
                        <div className='col-md-6'>
                        </div>
                        <div className='col-md-12'>
                            <div className='table-responsive mt-4 table-wrapper'>
                                <table className="table table-bordered table-layout-design" border={1}>
                                    <thead>
                                        <tr>
                                            <th scope='col' className='text-end'>#</th>
                                            <th scope="col" className='min-width-125'>Conf. No.</th>
                                            <th scope="col" className='min-width-185'>Transaction Date</th>
                                            <th scope="col">Company Name</th>
                                            <th scope="col" className='min-width-210'>Hotel Name</th>
                                            <th scope="col" className='min-width-165'>Location</th>
                                            <th scope="col" className='min-width-210'>Guest</th>
                                            <th scope="col" className='text-center'>Guest Count</th>
                                            <th scope="col" className='text-center'>Rooms</th>
                                            <th scope="col" className='text-center'>Nights</th>
                                            <th scope="col" className='min-width-165'>Check-in</th>
                                            <th scope="col" className='min-width-165'>Check-out</th>
                                            {getConfigurationByBoolen("ALLOW_SOURCE_PCC_FARE") && <th scope="col">Source</th>}
                                            <th scope="col">Source PNR</th>
                                            <th scope="col">Hotel Conf. No.</th>
                                            <th scope="col">Rate Plan</th>
                                            <th scope="col" className='min-width-165'>Status</th>
                                            <th scope="col" className='text-center'>Currency</th>
                                            <th scope="col" className='text-end'>Base Rate</th>
                                            <th scope="col" className='text-end'>Taxes</th>
                                            {getConfigurationByBoolen("ALLOW_SERVICE_FEE") && <th scope="col" className='text-end'>Service Fee</th>}
                                            <th scope="col" className='text-end'>Markup</th>
                                            <th scope="col" className='text-end'>Txn. Charges</th>
                                            <th scope="col" className='text-end'>Total Rate</th>
                                            {getConfigurationByBoolen("ALLOW_COMISSION") && <th scope="col" className='text-end'>Commission</th>}
                                            {/* <th scope="col" className='text-end'>Equiv. Total Rate (USD)</th>                                        */}
                                            <th scope="col" className='text-center'>Eq. Currency</th>
                                            <th scope="col" className='text-end'>Conversion Rate</th>
                                            <th scope="col" className='text-end'>Eq. Total Rate</th>
                                            <th scope="col" className='min-width-185'>Form of Payment</th>
                                            <th scope="col" className='min-width-165'>Done By</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.currentItems.map((currentItems) =>
                                            <HotelBookingItem key={currentItems.sequenceNo}
                                                id={currentItems.sequenceNo}
                                                reportJSON={currentItems}></HotelBookingItem>)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>


    </Fragment>

};

export default HotelBookingList;