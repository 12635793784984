import { useState, useRef, useEffect } from "react";
import Wrapper from "../../../../components/Common/Helpers/Wrapper";
import LoadingSpinner from "../../../../components/Common/Loading/LoadingSpinner";
import Dropdown from 'react-select'
import { useTranslation } from "react-i18next";
import useHttp from "../../../../services/use-http";
import { geteCommonListtByName, serachRegion } from "../../../../services/Content-API";
import { useHistory } from "react-router-dom";
import { checkSecurityDetails } from '../../../../utils/RoleUtils'
import { searchCurrency } from "../../../../services/Currency-API";
import { searchOfficeList } from '../../../../services/Office-API';
import CountryDropDownList from '../../../../components/Common/DropDownList/CountryDropDownList';
import CityDropDownList from '../../../../components/Common/DropDownList/CityDropDownList';

const ContentForm = (props) => {

    //constant
    const { t } = useTranslation(['translation.Content']);
    const { sendRequest: sendServiceList, data: loadedServiceList } = useHttp(geteCommonListtByName)
    const { sendRequest: sendProviderList, data: loadedProviderList } = useHttp(geteCommonListtByName)
    const { sendRequest: sendRegionList, data: loadedRegionList } = useHttp(serachRegion)
    const { sendRequest: sendCurrenyList, data: loadedCurrencyList } = useHttp(searchCurrency)

    const history = useHistory();
    const isTravelAgent = JSON.parse(localStorage.getItem('isTravelAgent'));
    //State
    const [active, setActive] = useState(true);
    const [activeUserAction, setActiveUserAction] = useState(false);
    const [pageTitle, setPageTitle] = useState(" Content");
    const [providerValue, setProviderValue] = useState("");
    const [serviceValue, setServiceValue] = useState("");
    const [showRegion, setShowRegion] = useState("Flight")
    const [isDisable, setIsDisable] = useState(false);
    const [isDropdownDisable, setIsDropdownDisable] = useState(false);
    const [toRegionValue, setToRegionValue] = useState('-000');
    const [toRegionTouched, setToRegionTouched] = useState(false);
    const [fromRegionTouched, setFromRegionTouched] = useState(false);
    const [fromRegionValue, setFromRegionValue] = useState('-000');
    const [currencyValue, setCurrencyValue] = useState('');
    const [companyNameError, setCompanyNameError] = useState(false);
    const [currencyDisable, setCurrencyDisable] = useState(false);
    const [countryCode, setCountryCode] = useState('');
    const [countryName, setCountryName] = useState('');
    const [cityCode, setCityCode] = useState('');
    const [isDisabled, setIsDisabledDDL] = useState('false');

    //useRef
    const inputPasswordRef = useRef('');
    const activeInputRef = useRef('');
    const inputEprRef = useRef('');
    const inputPccRef = useRef('');
    const inputApiKeyRef = useRef('');
    const inputLniataNumberRef = useRef('');
    const inputLniataCountryRef = useRef('');

    //Error State
    // const [passwordHasError, setPasswordHasError] = useState(false);
    const [passwordBlankError, setPasswordBlankError] = useState(false);
    const [providerBlankError, setProviderBlankError] = useState(false);
    const [serviveBlankError, setServiceBlankError] = useState(false);
    const [pccBlankError, setPccBlankErr] = useState(false)
    const [pccInputError, setPccInputErr] = useState(false)
    const [pccLengthError, setPccLengthErr] = useState(false)
    const [eprBlankError, setEprBlankErr] = useState(false)
    const [eprInputError, setEprInputErr] = useState(false)
    const [eprLengthError, setEprLengthErr] = useState(false)
    const [fromRegionBlankError, setFromRegionBlankErr] = useState(false);
    const [toRegionBlankError, setToRegionBlankErr] = useState(false);
    const [currencyBlankError, setCurrencyBlankErr] = useState(false);
    const [apiKeyBlankError, setApiKeyBlankError] = useState(false);
    const [countryCodeInputHasError, setCountryCodeInputHasError] = useState(false);
    const [cityCodeInputHasError, setCityCodeInputHasError] = useState(false);
    const [lniataNumberBlankError, setLniataNumberBlankErr] = useState(false);
    const [lniataNumberInputError, setLniataNumberInputErr] = useState(false);
    const [lniataCountryBlankError, setLniataCountryBlankErr] = useState(false);
    const [lniataCountryInputError, setLniataCountryInputErr] = useState(false);

    useEffect(() => {
        if (serviceValue === '21') {
            setShowRegion("Hotel");
        } else {
            setShowRegion("Flight")
        }
    }, [serviceValue])

    //Code for get company List
    const [companyNameInput, setCompanyNameInput] = useState();
    const { sendRequest: sendCompanyList, data: loadedCompanyList } = useHttp(searchOfficeList);
    useEffect(() => {
        const onSearchCompanyName = ({
            companyName: "",
            categoryID: "",
            countryCode: "",
            cityCode: "",
            loginEmailID: "",
            email: "",
            parentOfficeID: JSON.parse(localStorage.getItem('officeID')),
            configration: true,
        });
        sendCompanyList(onSearchCompanyName);
    }, [])

    let companyOptions = []
    let companyArrayData = [];
    for (let i in loadedCompanyList) {
        if (loadedCompanyList['errors'].status === 'FALSE') {
            if (i === 'data') {
                companyArrayData = loadedCompanyList[i];
            }
        }
    }
    for (let index = 0; index < companyArrayData.length; index++) {
        companyOptions = [...companyOptions, { value: companyArrayData[index].id, label: companyArrayData[index].companyName }]
    }
    function comapnyNameChaneHandler(event) {
        setCompanyNameInput(event.value)
    }
    //End
    useEffect(() => {
        let isEditMode = parseInt(sessionStorage.getItem('isEditable'));

        if (props.loadedContentDetails.length !== 0 && isEditMode === 100) {
            sessionStorage.setItem('isEditable', '1');
            isEditMode = parseInt(sessionStorage.getItem('isEditable'));
        }

        if (props.loadedContentDetails.length !== 0) {
            if (providerValue !== 'TJ') {
                inputEprRef.current.value = props.loadedContentDetails.epr;
                inputPccRef.current.value = props.loadedContentDetails.pcc;
                inputPasswordRef.current.value = props.loadedContentDetails.password;
                inputLniataNumberRef.current.value = props.loadedContentDetails.lniataNumber;
                inputLniataCountryRef.current.value = props.loadedContentDetails.lniataCountryCode;
            }
            else {
                inputApiKeyRef.current.value = props.loadedContentDetails.password;
            }

            if (isTravelAgent === "1") {
                if (companyNameInput === '' || companyNameInput === undefined) {
                    setCompanyNameInput(props.loadedContentDetails.officeID);
                }
            }
            if (providerValue === '') {
                setProviderValue(props.loadedContentDetails.providerID)
            }
            if (serviceValue === '') {
                setServiceValue(props.loadedContentDetails.serviceID)
            }
            if (props.loadedContentDetails.serviceID === '21') {
                setShowRegion("Hotel");
            } else {
                setShowRegion("Flight")
            }
            if (activeUserAction === false) {
                setActive(props.loadedContentDetails.isActive);
            }
            if (toRegionTouched === false) {
                setToRegionValue(props.loadedContentDetails.toRegion);
            }
            if (fromRegionTouched === false) {
                setFromRegionValue(props.loadedContentDetails.fromRegion);
            }
            if (currencyValue === '') {
                setCurrencyValue(props.loadedContentDetails.currency)
            }
            if (providerValue !== 'TJ') {
                if (countryCode === '') {
                    setCountryCode(props.loadedContentDetails.countryCode)
                }
                if (cityCode === '') {
                    setCityCode(props.loadedContentDetails.cityCode)
                }
            }
            setIsDisable(false);
            setIsDropdownDisable(false);
            setIsDisabledDDL('false');
        }

        if (props.loadedContentDetails.length === 0) {
            setIsDisable(false);
            setIsDropdownDisable(false);
            setIsDisabledDDL('false');
            document.getElementById('btnNew').style.display = 'none';
            setPageTitle(" New Content");
            sessionStorage.setItem('isEditable', '100');
        }

        //View Page
        if (isEditMode === 0) {
            if (providerValue !== 'TJ') {
                document.getElementById("PCC").readOnly = true;
                document.getElementById("EPR").readOnly = true;
                document.getElementById("password").readOnly = true;
                document.getElementById("lniataNumber").readOnly = true;
                document.getElementById("lniataCountry").readOnly = true;
                [].forEach.call(document.querySelectorAll('password'), function (el) {
                    el.style.visibility = 'hidden';
                });
            }
            else {
                document.getElementById("apiKey").readOnly = true;
                setCurrencyValue('INR');
                setCurrencyDisable(true);
            }

            document.querySelector("#active").disabled = true;
            document.getElementById("btnSave").style.display = "none";
            document.getElementById("btnNew").style.display = "none";
            document.getElementById("btnReset").style.display = "none";
            [].forEach.call(document.querySelectorAll('.error-icon'), function (el) {
                el.style.visibility = 'hidden';
            });
            document.getElementById("requiredIndicator").style.display = "none";
            setPageTitle(" View Content");

            setIsDisable(true);
            setIsDropdownDisable(true);
            setIsDisabledDDL('true');

            //document.getElementById("dvPasswordArea").style.display = "none";
        }
        else if (isEditMode === 1) {
            if (providerValue !== 'TJ') {
                document.getElementById("PCC").readOnly = false;
                document.getElementById("EPR").readOnly = false;
                document.getElementById("password").readOnly = false;
                document.getElementById("lniataNumber").readOnly = false;
                document.getElementById("lniataCountry").readOnly = false;
            }
            else {
                document.getElementById("apiKey").readOnly = false;
                setCurrencyValue('INR');
                setCurrencyDisable(true);
            }

            document.getElementById("btnSave").style.display = "";
            document.getElementById("btnNew").style.display = "";
            document.getElementById("btnReset").style.display = "";
            document.getElementsByClassName('error-icon')[0].style.visibility = 'hidden';
            document.getElementById("requiredIndicator").style.display = "";
            setPageTitle(" Edit Content");
            //   document.getElementById("dvPasswordArea").style.display = "none";
            setIsDisable(true);
            setIsDropdownDisable(false);
            setIsDisabledDDL('false');
        }

    }, [props.loadedContentDetails.length,
    props.loadedContentDetails.pcc,
    props.loadedContentDetails.epr,
    props.loadedContentDetails.providerID,
    props.loadedContentDetails.serviceID,
    props.loadedContentDetails.isActive,
    props.loadedContentDetails.serviceName,
    props.loadedContentDetails.providerName,
    props.loadedContentDetails.password,
    props.loadedContentDetails.toRegion,
    props.loadedContentDetails.fromRegion,
    props.loadedContentDetails.currency,
        serviceValue,
        providerValue, currencyValue,
        isDisable, toRegionTouched, fromRegionTouched,
    ])


    //Change Handler
    const activeChangeHandler = () => {
        setActiveUserAction(true);
        if (document.querySelector('#active').checked === true) {
            setActive(true);
        }
        else {
            setActive(false);
        }
    }
    function providerChangeHandler(event) {
        setProviderValue(event.value);
        setProviderBlankError(false);

        if (event.value === 'TJ') {
            setCurrencyValue('INR');
            setCurrencyDisable(true);
        }
    }
    function serviceChangeHandler(event) {
        setServiceValue(event.value);
        setShowRegion(event.label);
        setServiceBlankError(false);
    }
    function toRegionChangeHandler(event) {
        setToRegionValue(event.value);
        setToRegionTouched(true);
    }
    function fromRegionChangeHandler(event) {
        setFromRegionValue(event.value);
        setFromRegionTouched(true);
    }
    //Common Function
    // function passwordValidation(password) {
    //     var pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,30}$/;
    //     if (password.match(pattern)) {
    //         return true;
    //     }
    //     return false;
    // }

    useEffect(() => {
        sendServiceList('service');
        sendProviderList('provider');
        const onRegionSearch = (
            {
                regionID: "",
                serviceID: serviceValue,
                officeID: JSON.parse(localStorage.getItem('officeID')),
                getAllRegion: true,
            }
        );

        sendRegionList(onRegionSearch);
        sendCurrenyList({
            currCode: "",
            currName: ""
        })
    }, [sendServiceList, sendProviderList, sendRegionList, serviceValue]);

    function objectToArray(data, resultArray) {
        let arrayData = [];
        for (let i in data) {
            if (data['errors'].status === 'FALSE') {
                if (i === 'data') {
                    arrayData = data[i];
                }
            }
        }
        for (let index = 0; index < arrayData.length; index++) {
            resultArray = [...resultArray, { value: arrayData[index].listID, label: arrayData[index].listValue }]
        }
        return resultArray;

    }

    let currencyOptions = [];
    let currecyArrayData = [];
    for (let i in loadedCurrencyList) {
        if (loadedCurrencyList['errors'].status === 'FALSE') {
            if (i === 'data') {
                currecyArrayData = loadedCurrencyList[i];
            }
        }
    }
    for (let index = 0; index < currecyArrayData.length; index++) {

        currencyOptions = [...currencyOptions, { value: currecyArrayData[index].currCode, label: currecyArrayData[index].currName + "( " + currecyArrayData[index].currCode + " )" }]
    }


    let serviceOption = [];
    serviceOption = objectToArray(loadedServiceList, serviceOption);

    let providerOption = [];
    providerOption = objectToArray(loadedProviderList, providerOption);

    let RegionOptions = [];
    let RegionArrayData = [];
    for (let i in loadedRegionList) {
        if (loadedRegionList['errors'].status === 'FALSE') {
            if (i === 'data') {
                RegionArrayData = loadedRegionList[i];
            }
        }
    }
    for (let index = 0; index < RegionArrayData.length; index++) {
        RegionOptions = [...RegionOptions, { value: RegionArrayData[index].id, label: RegionArrayData[index].regionName }]
    }

    function changeLocation(event) {
        if (event.target.id === 'btnNew') {
            if (props.loadedContentDetails.length === 0) {
                history.go('/UpdateContent')
            }
            else {
                history.push('/UpdateContent')
                history.go();
            }
        }

        if (event.target.id === 'btnReset') {
            if (props.loadedContentDetails.length === 0) {

                history.go('/UpdateContent');
            } else {

                history.go(`/UpdateContent/${props.loadedProfileDetails.id}`);
            }
        }

    }
    const currencyChangeHandler = (event) => {
        setCurrencyValue(event.value)
        setCurrencyBlankErr(false);
    }
    function onBlurCheck(event) {

        if (event.target.id !== 'btnReset') {
            if (providerValue !== 'TJ') {
                const enteredEprValue = inputEprRef.current.value;
                const enteredEpr = enteredEprValue.trim();
                const enterdPccValue = inputPccRef.current.value;
                const enteredPcc = enterdPccValue.trim();
                const enteredPasswordValue = inputPasswordRef.current.value;
                const enteredPassword = enteredPasswordValue.trim();

                if (serviceValue === '' || serviceValue === undefined) {
                    setServiceBlankError(true);
                    return;
                }
                else {
                    setServiceBlankError(false);
                }
                if (providerValue === '' || providerValue === undefined) {
                    setProviderBlankError(true);
                    return;
                }
                else {
                    setProviderBlankError(false);
                }
                if (enteredPcc === '') {
                    setPccBlankErr(true);
                    setPccInputErr(false);
                    setPccLengthErr(false);
                    return;
                }
                else {
                    setPccBlankErr(false);
                }
                if (!enteredPcc.match('^[a-zA-Z0-9]+$')) {
                    setPccInputErr(true);
                    setPccBlankErr(false);
                    setPccLengthErr(false);
                    return;
                } else {
                    setPccInputErr(false);
                }
                if (enteredPcc.length !== 4) {
                    setPccLengthErr(true);
                    setPccBlankErr(false);
                    setPccInputErr(false);
                    return;
                }
                else {
                    setPccLengthErr(false);
                }
                if (enteredEpr.trim() === '') {
                    setEprBlankErr(true);
                    setEprLengthErr(false)
                    setEprInputErr(false);
                    return;
                }
                else {
                    setEprBlankErr(false);
                }
                if (!enteredEpr.match('^[0-9]*$')) {
                    setEprInputErr(true);
                    setEprBlankErr(false);
                    setEprLengthErr(false);
                    return;
                }
                else {
                    setEprInputErr(false);
                }

                if (enteredEpr.length !== 6) {
                    setEprLengthErr(true);
                    setEprInputErr(false);
                    setEprBlankErr(false);
                    return;
                }
                else {
                    setEprLengthErr(false);
                }

                if (enteredPassword === '') {
                    setPasswordBlankError(true);
                    return;
                } else {
                    setPasswordBlankError(false);
                }
            }
            else {
                const enteredApiKeyValue = inputApiKeyRef.current.value;
                const enteredApiKey = enteredApiKeyValue.trim();

                if (enteredApiKey === '') {
                    setApiKeyBlankError(true);
                    return;
                } else {
                    setApiKeyBlankError(false);
                }
            }

            if (fromRegionValue === '' || fromRegionValue === undefined) {
                setFromRegionBlankErr(true);
                return;
            } else {
                setFromRegionBlankErr(false);
            }
            if (toRegionValue === '' || toRegionValue === undefined) {
                setToRegionBlankErr(true);
                return;
            } else {
                setToRegionBlankErr(false);
            }
            if (currencyValue === '') {
                setCurrencyBlankErr(true);
                return;
            } else {
                setCurrencyBlankErr(false);
            }

            if (providerValue !== 'TJ') {
                const enterdLniataNumberValue = inputLniataNumberRef.current.value;
                const enteredLniataNumber = enterdLniataNumberValue.trim();
                const enterdLniataCountryValue = inputLniataCountryRef.current.value;
                const enteredLniataCountry = enterdLniataCountryValue.trim();

                if (countryCode === "" || countryCode === null) {
                    setCountryCodeInputHasError(true);
                    return;
                }
                else {
                    setCountryCodeInputHasError(false);
                }
                if (cityCode === "" || cityCode === null) {
                    setCityCodeInputHasError(true);
                    return;
                }
                else {
                    setCityCodeInputHasError(false);
                }

                if (enteredLniataNumber === '' && enteredLniataCountry !== '') {
                    setLniataNumberBlankErr(true);
                    setLniataNumberInputErr(false);
                    return;
                }
                else {
                    setLniataNumberBlankErr(false);
                }

                if (enteredLniataNumber !== '' && !enteredLniataNumber.match('^[a-zA-Z0-9]+$')) {
                    setLniataNumberInputErr(true);
                    setLniataNumberBlankErr(false);
                    return;
                } else {
                    setLniataNumberInputErr(false);
                }

                if (enteredLniataCountry === '' && enteredLniataNumber !== '') {
                    setLniataCountryBlankErr(true);
                    setLniataCountryInputErr(false);
                    return;
                }
                else {
                    setLniataCountryBlankErr(false);
                }

                if (enteredLniataCountry !== '' && !enteredLniataCountry.match('^[a-zA-Z]+$')) {
                    setLniataCountryInputErr(true);
                    setLniataCountryBlankErr(false);
                    return;
                } else {
                    setLniataCountryInputErr(false);
                }
            }
        }
    };

    function submitFormHandler(event) {
        event.preventDefault();

        if (serviceValue === '' || serviceValue === undefined) {
            setServiceBlankError(true);

            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
            return;
        }
        else {
            setServiceBlankError(false);
        }
        if (providerValue === '' || providerValue === undefined) {
            setProviderBlankError(true);
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
            return;
        }
        else {
            setProviderBlankError(false);
        }

        if (providerValue !== 'TJ') {
            const enteredEprValue = inputEprRef.current.value;
            const enteredEpr = enteredEprValue.trim();
            const enterdPccValue = inputPccRef.current.value;
            const enteredPcc = enterdPccValue.trim();
            const enteredPasswordValue = inputPasswordRef.current.value;
            const enteredPassword = enteredPasswordValue.trim();

            if (enteredPcc === '') {
                inputPccRef.current.focus();
                setPccBlankErr(true);
                setPccInputErr(false);
                setPccLengthErr(false);
                window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                });
                return;
            }
            else {
                setPccBlankErr(false);
            }
            if (!enteredPcc.match('^[a-zA-Z0-9]+$')) {
                inputPccRef.current.focus();
                setPccInputErr(true);
                setPccBlankErr(false);
                setPccLengthErr(false);
                window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                });
                return;
            } else {
                setPccInputErr(false);
            }
            if (enteredPcc.length !== 4) {
                inputPccRef.current.focus();
                setPccLengthErr(true);
                setPccBlankErr(false);
                setPccInputErr(false);
                window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                });
                return;
            }
            else {
                setPccLengthErr(false);
            }
            if (enteredEpr.trim() === '') {
                setEprBlankErr(true);
                inputEprRef.current.focus();
                return;
            }
            else {
                setEprBlankErr(false);
            }
            if (!enteredEpr.match('^[0-9]*$')) {
                setEprInputErr(true);
                setEprBlankErr(false);
                setEprLengthErr(false);
                inputEprRef.current.focus();
                return;
            }
            else {
                setEprInputErr(false);
            }
            if (enteredEpr.length !== 6) {
                inputEprRef.current.focus();
                setEprLengthErr(true);
                setEprInputErr(false);
                setEprBlankErr(false);
                return;
            }
            else {
                setEprLengthErr(false);
            }

            if (enteredPassword === '') {
                inputPasswordRef.current.focus();
                setPasswordBlankError(true);
                return;
            } else {
                setPasswordBlankError(false);
            }
        }
        else {
            const enteredApiKeyValue = inputApiKeyRef.current.value;
            const enteredApiKey = enteredApiKeyValue.trim();

            if (enteredApiKey === '') {
                inputApiKeyRef.current.focus();
                setApiKeyBlankError(true);
                return;
            } else {
                setApiKeyBlankError(false);
            }
        }

        if (fromRegionValue === '' || fromRegionValue === undefined) {
            setFromRegionBlankErr(true);
            return;
        } else {
            setFromRegionBlankErr(false);
        }
        if (toRegionValue === '' || toRegionValue === undefined) {
            setToRegionBlankErr(true);
            return;
        } else {
            setToRegionBlankErr(false);
        }
        if (currencyValue === '' || currencyValue === null) {
            setCurrencyBlankErr(true);
            return;
        } else {
            setCurrencyBlankErr(false);
        }

        if (providerValue !== 'TJ') {
            const enterdLniataNumberValue = inputLniataNumberRef.current.value;
            const enteredLniataNumber = enterdLniataNumberValue.trim();
            const enterdLniataCountryValue = inputLniataCountryRef.current.value;
            const enteredLniataCountry = enterdLniataCountryValue.trim();

            if (countryCode === "" || countryCode === null) {
                setCountryCodeInputHasError(true);
                return;
            }
            else {
                setCountryCodeInputHasError(false);
            }
            if (cityCode === "" || cityCode === null) {
                setCityCodeInputHasError(true);
                return;
            }
            else {
                setCityCodeInputHasError(false);
            }

            if (enteredLniataNumber === '' && enteredLniataCountry !== '') {
                inputLniataNumberRef.current.focus();
                setLniataNumberBlankErr(true);
                setLniataNumberInputErr(false);
                return;
            }
            else {
                setLniataNumberBlankErr(false);
            }

            if (enteredLniataNumber !== '' && !enteredLniataNumber.match('^[a-zA-Z0-9]+$')) {
                setLniataNumberInputErr(true);
                setLniataNumberBlankErr(false);
                return;
            } else {
                setLniataNumberInputErr(false);
            }

            if (enteredLniataCountry === '' && enteredLniataNumber !== '') {
                inputLniataCountryRef.current.focus();
                setLniataCountryBlankErr(true);
                setLniataCountryInputErr(false);
                return;
            }
            else {
                setLniataCountryBlankErr(false);
            }

            if (enteredLniataCountry !== '' && !enteredLniataCountry.match('^[a-zA-Z]+$')) {
                setLniataCountryInputErr(true);
                setLniataCountryBlankErr(false);
                return;
            } else {
                setLniataCountryInputErr(false);
            }
        }

        let ID;
        if (props.loadedContentDetails === null || props.loadedContentDetails.length === 0) {
            ID = '';
        } else {
            ID = props.loadedContentDetails.id;
        }

        let statusFlag;
        if (active) {
            statusFlag = 2;
        } else if (active === false) {
            statusFlag = 3
        } else {
            statusFlag = 1;
        }
        const contentInput = ({
            id: ID,
            traceID: JSON.parse(localStorage.getItem('traceID')),
            userID: JSON.parse(localStorage.getItem('userID')),
            serviceID: serviceValue,
            serviceName: "",
            providerID: providerValue,
            providerName: "",
            epr: providerValue !== 'TJ' ? inputEprRef.current.value : '',
            pcc: providerValue === 'TJ' ? inputApiKeyRef.current.value : inputPccRef.current.value,
            currency: currencyValue,
            password: providerValue === 'TJ' ? inputApiKeyRef.current.value : inputPasswordRef.current.value,
            fromRegion: fromRegionValue,
            toRegion: showRegion === 'Flight' ? toRegionValue : null,
            isActive: active,
            officeID: isTravelAgent === "1" ? companyNameInput : JSON.parse(localStorage.getItem('officeID')),
            status: statusFlag,
            toRegionName: "",
            fromRegionName: "",
            iPAddress: localStorage.getItem("IPAddress"),
            countryCode: providerValue !== 'TJ' ? countryCode : '',
            cityCode: providerValue !== 'TJ' ? cityCode : '',
            lniataNumber: providerValue !== 'TJ' ? inputLniataNumberRef.current.value !== '' ? inputLniataNumberRef.current.value.toString().toUpperCase() : '' : '',
            lniataCountryCode: providerValue !== 'TJ' ? inputLniataCountryRef.current.value !== '' ? inputLniataCountryRef.current.value.toString().toUpperCase() : '' : '',
        });
        props.onAddContent(
            contentInput
        );

        window.scrollTo({
            top: 230,
            behavior: "smooth",
        });
    };
    useEffect(() => {
        if (props.loadedContentDetails.length === 0) {
            document.getElementById("btnSave").click();

        }
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, [props.loadedContentDetails.length]);

    const countryCodeValue = (country_Code) => {
        setCountryCode(country_Code);
        setCountryCodeInputHasError(false);
        setCityCode('');
    };
    const countryNameText = (country_Name) => {
        setCountryName(country_Name);
    };

    const cityCodeValue = (cityCode) => {
        setCityCode(cityCode);
        //setIsCountryChanged(false);
        setCityCodeInputHasError(false);
    };

    const cityNameText = (cityName) => {
        //setCityName(cityName);        
    };

    return <Wrapper>
        <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="">
                            <div className="row">
                                <div className="col-lg-2"></div>
                                <div className="col-lg-8 mb-30">
                                    <div className="card card-primary card-outline">
                                        <div className="card-body">
                                            <div className="col-md-12 ">
                                                <div className="form-group col-md-12">
                                                    <label htmlFor="" className="h5 text-primary mb-3 col-lg-12"><i className="fas fa-globe-asia"></i> {pageTitle}</label>

                                                    <form
                                                        className='form'>
                                                        {props.isLoading && (
                                                            <div className='loading'>
                                                                <LoadingSpinner />
                                                            </div>
                                                        )}
                                                        {isTravelAgent === "1" && <div className='row'>
                                                            <div className='col-md-12 col-sm-12 control'>
                                                                <label htmlFor='name' className="h6 text-primary mb-2 col-lg-12"> {t('companyName')}<span className="error-icon"> *</span></label>
                                                                <Dropdown onChange={comapnyNameChaneHandler}
                                                                    value={companyOptions.filter(option => option.value === companyNameInput)}
                                                                    options={companyOptions}
                                                                    textField="label"
                                                                    dataItemKey="value"
                                                                    arrowClosed={<span className="arrow-closed" />}
                                                                    arrowOpen={<span className="arrow-open" />}
                                                                    controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                                    autoFocus
                                                                    isDisabled={isDisable}
                                                                />
                                                                {companyNameError && <p className="error-text">{t('companyNameBlankValidation')}</p>}
                                                            </div>
                                                        </div>}
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className='control'>
                                                                    <label htmlFor="service" className="mb-2 h6 text-primary col-lg-12">{t('serviceLabel')}<span className="error-icon"> *</span></label>
                                                                    <Dropdown
                                                                        value={serviceOption.filter(option => option.value === serviceValue)}
                                                                        options={serviceOption}
                                                                        textField="label"
                                                                        dataItemKey="value"
                                                                        onBlur={onBlurCheck}
                                                                        arrowClosed={<span className="arrow-closed" />}
                                                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                                        onChange={serviceChangeHandler}
                                                                        isDisabled={isDisable}
                                                                    />
                                                                    {serviveBlankError && <p className="error-text">{t('serviceBlankValidation')}</p>}

                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className='control'>
                                                                    <label htmlFor="provider" className="mb-2 h6 text-primary col-lg-12">{t('provider')}<span className="error-icon"> *</span></label>
                                                                    <Dropdown
                                                                        onChange={providerChangeHandler}
                                                                        value={providerOption.filter(option => option.value === providerValue)}
                                                                        options={providerOption}
                                                                        textField="label"
                                                                        dataItemKey="value"
                                                                        onBlur={onBlurCheck}
                                                                        arrowClosed={<span className="arrow-closed" />}
                                                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                                        isDisabled={isDisable}
                                                                    />
                                                                    {providerBlankError && <p className="error-text">{t('providerBlankValidation')}</p>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {
                                                            providerValue !== 'TJ' &&
                                                            <>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <div className='control'>
                                                                            <label htmlFor="PCC" className="mb-2 h6 text-primary col-lg-12">{t('pcc')}<span className="error-icon"> *</span></label>
                                                                            <input type="textbox" className="mb-2 form-control form-control-sm col-lg-12"
                                                                                minLength={4} maxLength={4} ref={inputPccRef} id="PCC" onBlur={onBlurCheck} />
                                                                            {pccBlankError && <p className="error-text">{t('pccBlankValidation')}</p>}
                                                                            {pccLengthError && <p className="error-text">{t('pccLengthValidation')}</p>}
                                                                            {pccInputError && <p className="error-text">{t('pccInputValidation')}</p>}

                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className='control'>
                                                                            <label htmlFor="EPR" className="mb-2 h6 text-primary col-lg-12">{t('epr')}<span className="error-icon"> *</span></label>
                                                                            <input type="textbox" minLength={6} maxLength={6} className="mb-2 form-control form-control-sm col-lg-12"
                                                                                ref={inputEprRef} id="EPR"
                                                                                onBlur={onBlurCheck} />
                                                                            {eprBlankError && <p className="error-text">{t('eprBlankValidation')}</p>}
                                                                            {eprLengthError && <p className="error-text">{t('eprLengthValidation')}</p>}
                                                                            {eprInputError && <p className="error-text">{t('eprInputValidation')}</p>}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="control" id='dvPasswordArea'>
                                                                    <label htmlFor="password" className="mb-2 h6 text-primary col-lg-12">{t('password')}<span className="error-icon"> *</span></label>
                                                                    <input id="password"
                                                                        ref={inputPasswordRef}
                                                                        minLength="1"
                                                                        maxLength="100"
                                                                        type="password"
                                                                        onBlur={onBlurCheck}
                                                                        className="form-control form-control-sm"
                                                                    />
                                                                    {passwordBlankError && <p className="error-text">{t('blankPassword')}</p>}
                                                                </div>
                                                            </>
                                                        }

                                                        {
                                                            providerValue === 'TJ' &&
                                                            <div className="control" id='dvApiKeyArea'>
                                                                <label htmlFor="apiKey" className="mb-2 h6 text-primary col-lg-12">{t('apikey')}<span className="error-icon"> *</span></label>
                                                                <input id="apiKey"
                                                                    ref={inputApiKeyRef}
                                                                    minLength="1"
                                                                    maxLength="200"
                                                                    type="textbox"
                                                                    onBlur={onBlurCheck}
                                                                    className="form-control form-control-sm"
                                                                />
                                                                {apiKeyBlankError && <p className="error-text">{t('blankApiKey')}</p>}
                                                            </div>
                                                        }

                                                        {showRegion === 'Flight' && <div className="row">
                                                            <div className="col-md-6">
                                                                <div className='control'>
                                                                    <label htmlFor="service" className="mb-2 h6 text-primary col-lg-12">{t('fromRegion')}<span className="error-icon"> *</span></label>
                                                                    <Dropdown
                                                                        onChange={fromRegionChangeHandler}
                                                                        value={RegionOptions.filter(option => option.value === fromRegionValue)}
                                                                        options={RegionOptions}
                                                                        textField="label"
                                                                        dataItemKey="value"
                                                                        arrowClosed={<span className="arrow-closed" />}
                                                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                                        isDisabled={isDropdownDisable}
                                                                        onBlur={onBlurCheck} />
                                                                    {fromRegionBlankError && <p className="error-text">{t('fromRegionBlankValidation')}</p>}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className='control'>
                                                                    <label htmlFor="service" className="mb-2 h6 text-primary col-lg-12">{t('toRegion')}<span className="error-icon"> *</span></label>
                                                                    <Dropdown
                                                                        onChange={toRegionChangeHandler}
                                                                        value={RegionOptions.filter(option => option.value === toRegionValue)}
                                                                        options={RegionOptions}
                                                                        textField="label"
                                                                        dataItemKey="value"
                                                                        arrowClosed={<span className="arrow-closed" />}
                                                                        arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                                        isDisabled={isDropdownDisable}
                                                                        onBlur={onBlurCheck} />
                                                                    {toRegionBlankError && <p className="error-text">{t('toRegionBlankValidation')}</p>}
                                                                </div>
                                                            </div>

                                                        </div>}

                                                        {showRegion === 'Hotel' && <div className="col-md-12">
                                                            <div className='control'>
                                                                <label htmlFor="service" className="mb-2 h6 text-primary col-lg-12">{t('Region')}<span className="error-icon"> *</span></label>
                                                                <Dropdown
                                                                    onChange={fromRegionChangeHandler}
                                                                    value={RegionOptions.filter(option => option.value === fromRegionValue)}
                                                                    options={RegionOptions}
                                                                    textField="label"
                                                                    dataItemKey="value"
                                                                    arrowClosed={<span className="arrow-closed" />}
                                                                    arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                                    isDisabled={isDropdownDisable}
                                                                    onBlur={onBlurCheck} />
                                                                {fromRegionBlankError && <p className="error-text">{t('fromRegionBlankValidation')}</p>}
                                                            </div>
                                                        </div>}

                                                        <div className='control'>
                                                            <label htmlFor="currency" className="mb-2 h6 text-primary col-lg-12">{t('currency')}<span className="error-icon"> *</span></label>
                                                            <Dropdown
                                                                onChange={currencyChangeHandler}
                                                                value={currencyOptions.filter(option => option.value === currencyValue)}
                                                                options={currencyOptions}
                                                                textField="label"
                                                                dataItemKey="value"
                                                                onBlur={onBlurCheck}
                                                                arrowClosed={<span className="arrow-closed" />}
                                                                arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                                isDisabled={providerValue === 'TJ' ? currencyDisable : isDropdownDisable}
                                                            />
                                                            {currencyBlankError && <p className="error-text">{t('currencyBlankValidation')}</p>}
                                                        </div>
                                                        <div className='mb-2'>
                                                            <div className="form-switch form-switch-sm padding-left-0">
                                                                <label htmlFor="active" className="mb-2 h6 text-primary">Active</label>
                                                                <input id="active" className="form-check-input ms-3" type="checkbox" onChange={activeChangeHandler} checked={active} value={active} ref={activeInputRef} />
                                                            </div>
                                                        </div>
                                                        {
                                                            providerValue !== 'TJ' &&
                                                            <>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <div className='control'>
                                                                            <label className='mb-2 h6 text-primary col-lg-12' htmlFor='Country'>{t('pcccountry')}<span className="error-icon"> *</span></label>
                                                                            <CountryDropDownList countryCodeValue={countryCodeValue} countryNameText={countryNameText} currentInputValue={countryCode} iSDisabled={isDisabled} continentCode={''} />
                                                                            {countryCodeInputHasError &&
                                                                                <p className="error-text">{t('validationCountryCode')}</p>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className='control'>
                                                                            <label className="mb-2 h6 text-primary col-lg-12" htmlFor='city'>{t('pcccity')}<span className="error-icon"> *</span></label>
                                                                            <CityDropDownList cityCodeValue={cityCodeValue} cityNameText={cityNameText} currentInputValue={cityCode} iSDisabled={isDisabled} countryCode={countryCode === '' && props !== undefined && props.loadedContentDetails !== undefined && props.loadedContentDetails.length !== 0 && props.loadedContentDetails.countryCode !== undefined && props.loadedContentDetails.countryCode !== '' ? props.loadedContentDetails.countryCode : countryCode} isMulti={false} />
                                                                            {cityCodeInputHasError &&
                                                                                <p className="error-text">{t('validationCityCode')}</p>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <br></br><p><h4>{t('ticketprinter')}</h4></p><br></br>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <div className='control'>
                                                                            <label htmlFor="lniataNumber" className="mb-2 h6 text-primary col-lg-12">{t('lniatanumber')}</label>
                                                                            <input type="textbox" className="mb-2 form-control form-control-sm col-lg-12"
                                                                                maxLength={6} ref={inputLniataNumberRef} id="lniataNumber" onBlur={onBlurCheck} />
                                                                            {lniataNumberBlankError && <p className="error-text">{t('lniataNumberBlankValidation')}</p>}
                                                                            {lniataNumberInputError && <p className="error-text">{t('lniataNumberInputValidation')}</p>}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className='control'>
                                                                            <label htmlFor="lniataCountry" className="mb-2 h6 text-primary col-lg-12">{t('lniatacountry')}</label>
                                                                            <input type="textbox" className="mb-2 form-control form-control-sm col-lg-12"
                                                                                maxLength={2} ref={inputLniataCountryRef} id="lniataCountry" onBlur={onBlurCheck} />
                                                                            {lniataCountryBlankError && <p className="error-text">{t('lniataCountryBlankValidation')}</p>}
                                                                            {lniataCountryInputError && <p className="error-text">{t('lniataCountryInputValidation')}</p>}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }

                                                        <p><span className="error-icon">*</span><span id='requiredIndicator'>{t('requiredFieldsIndicator')}</span></p>

                                                        <div className='mt-3 actions row'>
                                                            <div className="col-lg-3 col-md-3 col-sm-3 my-auto">
                                                                <a href="/SearchContent" className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1 d-none d-sm-block d-sm-none d-md-block'><span><i className="fa fa-arrow-left"></i></span></a>
                                                            </div>
                                                            <div className="col-lg-9 col-md-9 col-sm-9">
                                                                <div className="float-md-end float-lg-end">
                                                                    <a href="/SearchContent" className='btn back-btn-sm text-white mt-1 gt-main-btn ms-1 d-md-none d-lg-none d-xl-none'><span><i className="fa fa-arrow-left"></i></span></a>
                                                                    {(checkSecurityDetails("Settings", "Content", "Settings_Content_Insert") || checkSecurityDetails("Master", "Content", "Master_Content_Update")) ?
                                                                        <button className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1' id='btnSave' onClick={submitFormHandler}>
                                                                            {t('save')}
                                                                        </button> : <span id="btnNew"></span>}
                                                                    {checkSecurityDetails("Settings", "Content", "Settings_Content_Insert") ?
                                                                        <button className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1' id="btnNew" onClick={changeLocation}>
                                                                            {t('new')}
                                                                        </button> : <span id="btnNew"></span>}
                                                                    <button className='btn gt-btn_md text-white mt-1 gt-main-btn ms-1' id='btnReset' onClick={changeLocation}>
                                                                        {t('reset')}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Wrapper>
};
export default ContentForm;