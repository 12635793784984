import {
    SEARCH_FLIGHT,
    GET_AIRPORT_LIST,
    GET_ALL_AIRPORT_LIST,
    GET_SEATMAP,
    GET_FARE_RULE,
    PRICE_CHECK
} from './CONSTANTS'
export async function searchFlight(searchData) {

    const response = await fetch(SEARCH_FLIGHT(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch credit Limit.');
    }

    return data;
}

export async function getAirportList(requestData) {
    const response = await fetch(GET_AIRPORT_LIST(requestData), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch airport.');
    }

    const data2 = {
        "data": [
            {
                "name": "DLV",
                "airportCode": "DLV",
                "airportName": "Delissaville",
                "cityCode": "DLV",
                "cityName": "Delissaville",
                "countryCode": "AU",
                "countryName": "Australia"
            }
        ],
        "errors": {
            "error": {
                "code": "1007",
                "description": "Record found."
            },
            "status": "FALSE"
        }
    }
    return data2;
}

export async function getAllAirportList() {
    const response = await fetch(GET_ALL_AIRPORT_LIST(), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch airport.');
    }


    return data;
}

export async function seatMap(requestData) {    
    const response = await fetch(GET_SEATMAP(), {
        method: 'POST',
        body: JSON.stringify(requestData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch seat map.');
    }
    
    return data;
}
export async function getFareRule(inputData) {

    const response = await fetch(GET_FARE_RULE(), {
        method: 'POST',
        body: JSON.stringify(inputData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch Fare rule.');
    }

    return data;
}
export async function priceCheck(searchData) {

    const response = await fetch(PRICE_CHECK(), {
        method: 'POST',
        body: JSON.stringify(searchData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Could not fetch PriceCheck.');
    }

    return data;
}