import { Fragment, useEffect, useState, useCallback } from 'react';
import { UPDATECONTENT } from '../../../navigation/CONSTANTS';
import { useTranslation } from 'react-i18next';
import { NavLink, useHistory } from 'react-router-dom';
import Dropdown from 'react-select';
import useHttp from "../../../services/use-http";
import { geteCommonListtByName, serachContent, serachRegion } from '../../../services/Content-API';
import useDocumentTitle from '../../../components/Common/layout/useDocumentTitle';
import ContentList from './Components/ContentList';
import ErrorModal from '../../../components/Common/Error/ErrorModal';
import LoadingSpinner from "../../../components/Common/Loading/LoadingSpinner";
import { checkSecurityDetails } from '../../../utils/RoleUtils'
import { searchOfficeList } from '../../../services/Office-API'
const statusOption = [
    { value: true, label: "Active" },
    { value: undefined, label: "Both" },
    { value: false, label: "Inactive" },
];

const SearchContent = () => {
    useDocumentTitle('Search Content');
    const [providerValue, setProviderValue] = useState("");
    const [serviceValue, setServiceValue] = useState("");
    const [providerLabel, setProviderLabel] = useState("");
    const [serviceLabel, setServiceLabel] = useState("");
    const [isFirstTime, setFirstTime] = useState("");
    const [toRegionValue, setToRegionValue] = useState('-000');
    const [fromRegionValue, setFromRegionValue] = useState('-000');
    const [showRegion, setShowRegion] = useState("Flight")
    const [UserStatus, setUserStatus] = useState(statusOption[0].value);
    const [error, setError] = useState(false);
    const { sendRequest: sendServiceList, data: loadedServiceList } = useHttp(geteCommonListtByName)
    const { sendRequest: sendProviderList, data: loadedProviderList } = useHttp(geteCommonListtByName)
    const { sendRequest: sendRegionList, data: loadedRegionList } = useHttp(serachRegion)
    const [companyNameError, setCompanyNameError] = useState(false);
    const [companyNameInput, setCompanyNameInput] = useState();
    const [companyNameLabel, setCompanyNameLabel] = useState();
    const { t } = useTranslation(['translation.Content']);
    const history = useHistory();
    const isTravelAgent = JSON.parse(localStorage.getItem('isTravelAgent'));
    function providerChangeHandler(event) {
        setProviderValue(event.value)
        setProviderLabel(event.label);
    }
    function serviceChangeHandler(event) {
        setServiceValue(event.value);
        setServiceLabel(event.label);
        setShowRegion(event.label)
    }
    const statusChangeHandler = (event) => {
        setUserStatus(event.value);
    }
    function toRegionChangeHandler(event) {
        setToRegionValue(event.value);


    }
    function fromRegionChangeHandler(event) {
        setFromRegionValue(event.value);

    }
    const { sendRequest, status, data: loadedContent, error: contentError } = useHttp(serachContent);
    //Code for get company List

    const { sendRequest: sendCompanyList, data: loadedCompanyList } = useHttp(searchOfficeList);
    useEffect(() => {
        const onSearchCompanyName = ({
            companyName: "",
            categoryID: "",
            countryCode: "",
            cityCode: "",
            loginEmailID: "",
            email: "",
            parentOfficeID: JSON.parse(localStorage.getItem('officeID')),
            configration: true,
        });
        sendCompanyList(onSearchCompanyName);
    }, [])

    let companyOptions = []
    let companyArrayData = [];
    for (let i in loadedCompanyList) {
        if (loadedCompanyList['errors'].status === 'FALSE') {
            if (i === 'data') {
                companyArrayData = loadedCompanyList[i];
            }
        }
    }
    for (let index = 0; index < companyArrayData.length; index++) {
        companyOptions = [...companyOptions, { value: companyArrayData[index].id, label: companyArrayData[index].companyName }]
    }
    function comapnyNameChaneHandler(event) {
        setCompanyNameInput(event.value)
        setCompanyNameLabel(event.label)
    }
    //End
    let statusFlag;
    if (UserStatus) {
        statusFlag = 2;
    } else if (UserStatus === false) {
        statusFlag = 3
    } else {
        statusFlag = 1;
    }



    useEffect(() => {

        if (status === 'completed') {

            if (loadedContent['errors'].error['code'] === '1008' || loadedContent['errors'].error['code'] === '9001') {
                setError(true);
                return;
            }
        }
    }, [status, loadedContent])

    function onResetHandler(event) {
        history.go('/serachContent');
    }


    const onSearchHandler = useCallback((event) => {
        event.preventDefault();

        if (!checkSecurityDetails("Settings", "Content", "Settings_Content_Search")) {
            return false;
        }

        if (isTravelAgent === "1") {
            if (companyNameInput === '' || companyNameInput === undefined) {
                setCompanyNameError(true);
                return;
            }
            else {
                setCompanyNameError(false);
            }
        }
        setFirstTime(true);

        const onSearchContent = (
            {
                id: "",
                traceID: "",
                userID: "",
                serviceID: serviceValue,
                serviceName: serviceLabel,
                providerID: providerValue,
                providerName: providerLabel,
                epr: "",
                pcc: "",
                password: "",
                fromRegion: fromRegionValue,
                toRegion: showRegion === 'Flight' ? toRegionValue : null,
                isActive: UserStatus,
                officeID: isTravelAgent === "1" ? companyNameInput : JSON.parse(localStorage.getItem('officeID')),
                status: statusFlag,
                toRegionName: "",
                fromRegionName: "",
            }
        );
        sendRequest(onSearchContent);

    }, [sendRequest, serviceValue, serviceLabel, providerLabel,
        providerValue, UserStatus, statusFlag, fromRegionValue, toRegionValue, companyNameInput])

    useEffect((sendRequest) => {
        window.scrollTo({
            top: 100,
            behavior: "smooth",
        });
        const keyDownHandler = event => {
            if (event.key === 'Enter') {
                event.preventDefault();
                // 👇️ call submit function here
                onSearchHandler(event);
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [onSearchHandler]);

    useEffect(() => {
        sendServiceList('service');
        sendProviderList('provider');
        const onRegionSearch = (
            {
                regionID: "",
                serviceID: serviceValue,
                officeID: JSON.parse(localStorage.getItem('officeID')),
                getAllRegion: true,
            }
        );

        sendRegionList(onRegionSearch);


    }, [sendServiceList, sendProviderList, sendRegionList, serviceValue]);


    if (contentError) {
        history.push('/Error')
        history.go();
        // return <div className='centered focused  col-lg-5 col-md-8 col-sm-12 col-12'>{t('ErrorMessage')}</div>
    }

    let loadedData = [];
    for (let i in loadedContent) {
        if (loadedContent['errors'].status === 'FALSE') {
            if (i === 'data') {
                loadedData = loadedContent[i];
            }
        }
    }
    function okayHandler() {
        setError(false);
        history.go('/serachContent');

    }


    const contentList = !isFirstTime ? '' : (loadedData && loadedData.length !== 0) ?
        <ContentList companyName={companyNameLabel} content={loadedData} /> : ""


    //function to covert Object into Array
    function objectToArray(data, resultArray) {
        let arrayData = [];
        for (let i in data) {
            if (data['errors'].status === 'FALSE') {
                if (i === 'data') {
                    arrayData = data[i];
                }
            }
        }
        for (let index = 0; index < arrayData.length; index++) {
            resultArray = [...resultArray, { value: arrayData[index].listID, label: arrayData[index].listValue }]
        }
        return resultArray;

    }

    let serviceOption = [];
    serviceOption = objectToArray(loadedServiceList, serviceOption);
    let providerOption = [];
    providerOption = objectToArray(loadedProviderList, providerOption);
    let RegionOptions = [];
    let RegionArrayData = [];
    for (let i in loadedRegionList) {
        if (loadedRegionList['errors'].status === 'FALSE') {
            if (i === 'data') {
                RegionArrayData = loadedRegionList[i];
            }
        }
    }
    for (let index = 0; index < RegionArrayData.length; index++) {
        RegionOptions = [...RegionOptions, { value: RegionArrayData[index].id, label: RegionArrayData[index].regionName }]
    }

    return (
        <Fragment>
            {error && <ErrorModal
                title="Message"
                message={`${t('ErrorMessage')}`}
                onConfirm={okayHandler}
            />}
            <section id="common_banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="common_bannner_text">
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="theme_search_form_tour" className="fligth_top_search_main_form_wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-8 mb-30">
                            <div className="card card-primary card-outline">
                                {status === 'pending' &&
                                    <div className=''>
                                        {/* <section id='common_banner'><div className='container'></div></section> */}
                                        <div className=''>
                                            <LoadingSpinner></LoadingSpinner>
                                        </div>
                                    </div>
                                }

                                <div className="card-body">
                                    <div className="col-md-12">
                                        <div className="form-group col-md-12">
                                            <label htmlFor='' className="h5 text-primary mb-3 col-lg-12"><i className="fas fa-globe-asia"></i> Search Content</label>
                                            <form>
                                                {isTravelAgent === "1" && <div className='row'>
                                                    <div className='col-md-12 col-sm-12 control'>
                                                        <label htmlFor='name' className="h6 text-primary mb-2 col-lg-12"> {t('companyName')}<span className="error-icon"> *</span></label>
                                                        <Dropdown onChange={comapnyNameChaneHandler}
                                                            value={companyOptions.filter(option => option.value === companyNameInput)}
                                                            options={companyOptions}
                                                            textField="label"
                                                            dataItemKey="value"
                                                            arrowClosed={<span className="arrow-closed" />}
                                                            arrowOpen={<span className="arrow-open" />}
                                                            controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                            autoFocus
                                                        />
                                                        {companyNameError && <p className="error-text">{t('companyNameBlankValidation')}</p>}
                                                    </div>
                                                </div>}
                                                <div className='row'>
                                                    <div className='col-md-4 col-sm-12'>
                                                        <div className='control'>
                                                            <label className="h6 text-primary mb-2 col-lg-12" htmlFor='loginemailid'>Service</label>
                                                            <Dropdown
                                                                value={serviceOption.filter(option => option.value === serviceValue)}
                                                                options={serviceOption}
                                                                textField="label"
                                                                dataItemKey="value"
                                                                arrowClosed={<span className="arrow-closed" />}
                                                                arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                                onChange={serviceChangeHandler}
                                                                autoFocus />

                                                        </div>
                                                    </div>
                                                    <div className='col-md-4 col-sm-12'>
                                                        <div className='control'>
                                                            <label className="h6 text-primary mb-2 col-lg-12" htmlFor='contactpersonemailid'>Provider</label>
                                                            <Dropdown
                                                                onChange={providerChangeHandler}
                                                                value={providerOption.filter(option => option.value === providerValue)}
                                                                options={providerOption}
                                                                textField="label"
                                                                dataItemKey="value"
                                                                arrowClosed={<span className="arrow-closed" />}
                                                                arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select" />

                                                        </div>
                                                    </div>
                                                    <div className='col-md-4 col-sm-12'>
                                                        <div className='control'>
                                                            <label htmlFor="status" className="mb-2 h6 text-primary col-lg-12">{t('status')}</label>
                                                            <Dropdown
                                                                arrowClosed={<span className="arrow-closed" />}
                                                                arrowOpen={<span className="arrow-open" />}
                                                                controlClassName='form-control control form-select bg_input mb-0'
                                                                placeholder="Please Select"
                                                                options={statusOption}

                                                                onChange={statusChangeHandler}
                                                                value={statusOption.filter(option => option.value === UserStatus)} />

                                                        </div>
                                                    </div>
                                                </div>

                                                {showRegion === 'Flight' && <div className="row">
                                                    <div className="col-md-6">
                                                        <div className='control'>
                                                            <label htmlFor="service" className="mb-2 h6 text-primary col-lg-12">{t('fromRegion')}</label>
                                                            <Dropdown
                                                                onChange={fromRegionChangeHandler}
                                                                value={RegionOptions.filter(option => option.value === fromRegionValue)}
                                                                options={RegionOptions}
                                                                textField="label"
                                                                dataItemKey="value"
                                                                arrowClosed={<span className="arrow-closed" />}
                                                                arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className='control'>
                                                            <label htmlFor="service" className="mb-2 h6 text-primary col-lg-12">{t('toRegion')}</label>
                                                            <Dropdown
                                                                onChange={toRegionChangeHandler}
                                                                value={RegionOptions.filter(option => option.value === toRegionValue)}
                                                                options={RegionOptions}
                                                                textField="label"
                                                                dataItemKey="value"
                                                                arrowClosed={<span className="arrow-closed" />}
                                                                arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                            />
                                                        </div>
                                                    </div>

                                                </div>}
                                                {showRegion === 'Hotel' && <div className="col-md-12">
                                                    <div className='control'>
                                                        <label htmlFor="service" className="mb-2 h6 text-primary col-lg-12">{t('Region')}</label>
                                                        <Dropdown
                                                            onChange={fromRegionChangeHandler}
                                                            value={RegionOptions.filter(option => option.value === fromRegionValue)}
                                                            options={RegionOptions}
                                                            textField="label"
                                                            dataItemKey="value"
                                                            arrowClosed={<span className="arrow-closed" />}
                                                            arrowOpen={<span className="arrow-open" />} controlClassName='form-control control form-select bg_input mb-0' placeholder="Please Select"
                                                        />
                                                    </div>
                                                </div>}
                                                <div className='mt-3 col-md-12 actions'>
                                                    <button className='btn gt-btn_md text-white mt-1 gt-main-btn float-end ms-1' onClick={onResetHandler}>
                                                        {t('reset')}
                                                    </button>
                                                    {checkSecurityDetails("Settings", "Content", "Settings_Content_Insert") &&
                                                        <NavLink className='btn gt-btn_md text-white mt-1 ms-1 gt-main-btn float-end' to={UPDATECONTENT}>
                                                            {t('new')}
                                                        </NavLink>}
                                                    {checkSecurityDetails("Settings", "Content", "Settings_Content_Search") &&
                                                        <button className='btn gt-btn_md text-white mt-1 ms-1 gt-main-btn float-end' onClick={onSearchHandler}>
                                                            {t('search')}
                                                        </button>}
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2"></div>
                    </div>
                </div>
            </section>
            {contentList}
        </Fragment>
    );

};
export default SearchContent;