import { useRef, useEffect } from 'react'
import { DEFAULT_TITLE } from '../../../config/CONSTANTS'
import { getConfigurationByValue } from '../../../utils/ConfigurationsFunction'
function useDocumentTitle(title, prevailOnUnmount = false) {
  const defaultTitle = useRef(document.title);
  const configTitle = useRef(getConfigurationByValue('Browser_Title'));

  useEffect(() => {
    if (configTitle.current !== undefined && configTitle.current !== '') {
      document.title = configTitle.current + " " + title;
    } else {
      document.title = DEFAULT_TITLE + title;
    }

  }, [title]);

  useEffect(() => () => {
    if (!prevailOnUnmount) {
      document.title = defaultTitle.current;
    }
  }, [prevailOnUnmount])
}

export default useDocumentTitle