import { Fragment } from 'react';
import { removeFirstAndLastSpecialChar, removeHtmlTags } from '../../../utils/CommonFunction';


const PropertyAmenities = (props) => {
    let foodAndDrink = "";
    let safetyInfo = "";
    let recreation = "";
    let service = '';
    let facilities;

    if (props.hotelDetails.data.hotelResults[0].descriptions !== undefined && props.hotelDetails.data.hotelResults[0].descriptions !== null) {
        props.hotelDetails.data.hotelResults[0].descriptions.filter(result => {
            if (result.text.type === 'Dining') {
                foodAndDrink = result.text.value;
            }
            if (result.text.type === 'SafetyInfo') {
                safetyInfo = result.text.value;
            }
            if (result.text.type === 'Recreation') {
                recreation = result.text.value.replace(/\n\n/g, '\n');
            }
            if (result.text.type === 'Services') {
                service = result.text.value;
            }
            if (result.text.type === 'Facilities') {
                facilities = result.text.value;
            }

        });
    }

    let foodAndDrinks = "";
    let showFoodAndDrink = [];
    if (foodAndDrink !== undefined && foodAndDrink !== '') {
        foodAndDrinks = foodAndDrink.split(/\r?\n/);
        if (foodAndDrinks !== '') {
            foodAndDrinks.forEach(element => {
                let item = removeHtmlTags(removeFirstAndLastSpecialChar(element));
                if (item !== '') {
                    showFoodAndDrink.push(<li className='py-1'>{item}</li>);

                }


            });
        } else {
            showFoodAndDrink.push(<li className='py-1'>{removeHtmlTags(removeFirstAndLastSpecialChar(foodAndDrink))}</li>)

        }

    }

    let services;
    if (service !== undefined) {
        services = service.split(/\r?\n/);
    }
    let facility = "";
    let firstArray = [];
    let secondArray = [];
    if (facilities !== undefined) {
        facility = facilities.split(/\r?\n/);

        if (facility.length > 0) {
            if (facility.length % 2 === 0) {
                firstArray = facility.slice(0, facility.length / 2);
                secondArray = facility.slice(facility.length / 2);

            } else {
                firstArray = facility.slice(0, Math.floor(facility.length / 2) + 1)
                secondArray = facility.slice(Math.floor(facility.length / 2) + 1);
            }
        }
    }
    let recreations = "";
    let ShowRecreation = [];
    if (recreation !== undefined && recreation !== '') {
        recreations = recreation.split(/\r?\n/);
        if (recreations !== '') {
            recreations.forEach(element => {
                let item = removeHtmlTags(removeFirstAndLastSpecialChar(element));
                if (item !== '') {
                    ShowRecreation.push(<div className='py-1'>{item}</div>);

                }
            });
        } else {
            ShowRecreation.push(<div className='py-1'>{removeHtmlTags(removeFirstAndLastSpecialChar(recreation))}</div>)

        }

    }
    let safetyInfos = "";
    let showSafetyInfo = [];
    if (safetyInfo !== undefined && safetyInfo !== '') {
        safetyInfos = safetyInfo.split(/\r?\n/);
        if (safetyInfos !== '') {
            safetyInfos.forEach(element => {
                let item = removeHtmlTags(removeFirstAndLastSpecialChar(element));
                if (item !== '') {
                    showSafetyInfo.push(<li className='py-1'><i className="fas fa-check py-1"></i>{item}</li>);
                }
            });
        } else {
            showSafetyInfo.push(<li className='py-1'><i className="fas fa-check py-1"></i>{removeHtmlTags(removeFirstAndLastSpecialChar(recreation))}</li>)

        }

    }


    // const showMoreFoodDrink = () => {


    //     var textElement = document.querySelector(".text-food-drink");
    //     var buttonElement = document.getElementById('showMoreFoodAndDrink');
    //     if (textElement.classList.contains("show-more-food-and-drink-height")) {
    //         buttonElement.innerHTML = '<span>Show Less <i class="fas fa-angle-double-left"></i></span>'; // Up double angle
    //     } else {
    //         buttonElement.innerHTML = '<span>Show More <i class="fas fa-angle-double-right"></i></span>'; // Down double angle
    //     }

    //     textElement.classList.toggle("show-more-food-and-drink-height");

    // }
    // const showMoreServices = () => {

    //     var textElement = document.querySelector(".text-services");
    //     var buttonElement = document.getElementById('showMoreServices11');
    //     if (textElement.classList.contains("show-more-services-height")) {
    //         buttonElement.innerHTML = '<span>Show Less <i class="fas fa-angle-double-left"></i></span>'; // Up double angle
    //     } else {
    //         buttonElement.innerHTML = '<span>Show More <i class="fas fa-angle-double-right"></i></span>'; // Down  double angle
    //     }




    //     textElement.classList.toggle("show-more-services-height");

    // }
    // const showMoreFacilities = () => {

    //     var textElement = document.querySelector(".text-facilities");
    //     var buttonElement = document.getElementById('showMoreFacilities11');
    //     if (textElement.classList.contains("show-more-facilities-height")) {
    //         buttonElement.innerHTML = '<span>Show Less <i class="fas fa-angle-double-left"></i></span>'; // Up double angle
    //     } else {
    //         buttonElement.innerHTML = '<span>Show More <i class="fas fa-angle-double-right"></i></span>'; // Down  
    //     }

    //     textElement.classList.toggle("show-more-facilities-height");

    // }
    // const showMoreRecreation = () => {

    //     var textElement = document.querySelector(".text-recreation");
    //     var buttonElement = document.getElementById('showMoreRecreation11');
    //     if (textElement.classList.contains("show-more-recreation-height")) {
    //         buttonElement.innerHTML = '<span>Show Less <i class="fas fa-angle-double-left"></i></span>'; // Up double angle
    //     } else {
    //         buttonElement.innerHTML = '<span>Show More <i class="fas fa-angle-double-right"></i></span>'; // Down  
    //     }

    //     textElement.classList.toggle("show-more-recreation-height");

    // }
    // const showMoreSafetyInfo = () => {


    //     var textElement = document.querySelector(".text-safety-info");
    //     var buttonElement = document.getElementById('showMoreSafetyInfo11');
    //     if (textElement.classList.contains("show-more-safety-info-height")) {
    //         buttonElement.innerHTML = '<span>Show Less <i class="fas fa-angle-double-left"></i></span>'; // Up double angle
    //     } else {
    //         buttonElement.innerHTML = '<span>Show More <i class="fas fa-angle-double-right"></i></span>'; // Down Down 
    //     }

    //     textElement.classList.toggle("show-more-safety-info-height");

    // }



    return (<Fragment>

        <div className="tour_details_boxed" >

            <h3 className="heading_theme">Hotel Details</h3>
            <div className='row'>
                {foodAndDrinks !== '' && <div className='col-md-6'>
                    <h5 className="h5 mt-1 text-primary"><i className="fas fa-hotel h6"></i> Food and Drink</h5>
                    <div className="tour_detail_right_sidebar">
                        <div className="">
                            <div className="tour_package_details_bar_list first_child_padding_none pt-2 text-food-drink show-more-food-and-drink-height">
                                <ul>
                                    {showFoodAndDrink}
                                </ul>
                                {/* {showFoodAndDrink.length >= 3 && 
                                    <div className=" text-primary " id='showMoreFoodAndDrink' onClick={() => { showMoreFoodDrink() }}><span>Show More  <i class="fas fa-angle-double-right"></i></span></div>
                                } */}
                            </div>
                        </div>
                    </div>
                </div>}
                {service !== '' && <div className='col-md-6'>
                    <h4 className="h5 mt-1 text-primary"><i className="fas fa-hotel h6"></i> Services</h4>

                    <div className="tour_detail_right_sidebar">
                        <div className="">
                            <div className="tour_package_details_bar_list first_child_padding_none pt-2 text-services show-more-services-height">
                                <ul>
                                    {services !== '' && services.map(item => {
                                        let service = removeHtmlTags(removeFirstAndLastSpecialChar(item))
                                        if (service !== '') {

                                            return <li className='py-1 gt-word-break'><i className="fas fa-check  py-1 "></i>{service}</li>
                                        }

                                    })}

                                </ul>
                            </div>
                            {/* {service.length >= 2 && 
                                <div className="text-white mt-3" id='showMoreServices11' onClick={() => { showMoreServices() }}><span> Show More  <i class="fas fa-angle-double-right"></i></span></div>
                            } */}
                        </div>
                    </div>
                </div>}

            </div>
            {facility !== '' && <div className='col-md-12'>
                <h4 className="h5 mt-1 text-primary"><i className="fas fa-hotel h6"></i> Facilities</h4>
                <div className='row  text-facilities show-more-facilities-height tour_detail_right_sidebar'>
                    <div className='col-md-6'>
                        <div className="tour_detail_right_sidebar">
                            <div className="">
                                <div className="tour_package_details_bar_list first_child_padding_none pt-2">
                                    <ul>
                                        {facility !== '' && firstArray.map(item => {
                                            let ele = removeHtmlTags(removeFirstAndLastSpecialChar(item));
                                            if (ele !== '') {
                                                return <li className='py-1'><i className="fas fa-check py-1"></i>{ele}</li>
                                            }

                                        })}
                                    </ul>
                                </div>
                            
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className="tour_detail_right_sidebar">
                            <div className="">
                                <div className="tour_package_details_bar_list first_child_padding_none pt-2">
                                    <ul>
                                        {facility !== '' && secondArray.map(item => {
                                            let ele = removeHtmlTags(removeFirstAndLastSpecialChar(item));
                                            if (ele !== '') {
                                                return <li className='py-1'><i className="fas fa-check py-1"></i>{ele}</li>
                                            }

                                        })}
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>   
                {/* {facility.length >= 3 &&  
                    <div className="text-white mb-5" id='showMoreFacilities11' onClick={() => { showMoreFacilities() }}><span>Show More <i class="fas fa-angle-double-right"></i></span></div>
                } */}
            </div>}
            <div className='row'>
                {recreation !== '' && <div className='col-md-6'>
                    <h4 className="h5 mt-1 text-primary"><i className="fas fa-theater-masks"></i> Recreation</h4>
                    <div className="tour_detail_right_sidebar">
                        <div className="tour_package_details_bar_list first_child_padding_none pt-2 text-recreation show-more-recreation-height">
                            <ul>
                                {ShowRecreation}
                            </ul>
                        </div>
                        {/* {ShowRecreation.length >= 3 && 
                            <div className="text-white " id='showMoreRecreation11' onClick={() => { showMoreRecreation() }}><span>Show More <i class="fas fa-angle-double-right"></i></span> </div>
                        } */}
                    </div>
                    
                </div>}

                {safetyInfo.length !== 0 && <div className='col-md-6 '>
                    <h4 className="h5 mt-1 text-primary"><i className="fas fa-shield-alt"></i> Safety Info</h4>
                    <div className="tour_package_details_bar_list first_child_padding_none pt-2 text-safety-info show-more-safety-info-height">
                        <ul>
                            {showSafetyInfo}
                        </ul>
                    </div>
                    {/* {showSafetyInfo.length >= 3 && 
                        <div className="text-white " id='showMoreSafetyInfo11' onClick={() => { showMoreSafetyInfo() }}><span>Show More  <i class="fas fa-angle-double-right"></i></span> </div>
                    } */}
                   
                </div>}
            </div>
            <span id='location_section'></span>

        </div>
        <div> </div>

    </Fragment>)
}

export default PropertyAmenities;